<template>
  <li class="flex flex-row items-center">
    <Icon
      :name="icon"
      :color="iconColor"
    />
    <div class="text-xs pl-2">
      {{ label }}
    </div>
  </li>
</template>

<script>
import Icon from '@/renderer/app/core/icon/Icon.vue'

export default {
  components: { Icon },
  props: {
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'check'
    },
    'icon-color': {
      type: String,
      default: undefined
    }
  }
}
</script>
