var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.discounts.length)?_c('div',{staticClass:"space-y-4 m-4"},_vm._l((_vm.discounts),function(discount){return _c('div',{key:discount.id,staticClass:"bg-white overflow-hidden shadow rounded-lg"},[_c('div',{staticClass:"px-4 py-5 sm:p-6 flex flex-row"},[_c('div',{staticClass:"basis-20 flex-shrink-0"},[_c('BaseImage',{staticClass:"w-48 h-48 rounded-xl border border-gray-200",attrs:{"rendered":"","text":discount.name,"path":discount.image.renderPath}})],1),_c('div',{staticClass:"ml-6"},[_c('h3',{staticClass:"text-lg leading-6 font-medium text-gray-900"},[_vm._v(" "+_vm._s(discount.name)+" ")]),_c('p',{staticClass:"mt-1 text-sm text-gray-500"},[_vm._v(" Valable jusqu’au "+_vm._s(discount.formatted.endDate)+" ")]),_c('div',{staticClass:"mt-4 flex-shrink-0 text-white"},[_c('router-link',{staticClass:"relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-dark mr-2",attrs:{"to":{
                name: 'customer.discount',

                params: {
                  discountId: discount.id
                }
              }}},[_vm._v(" Voir ")])],1)])])])}),0):_c('div',[_c('p',{staticClass:"text-sm font-medium text-gray-500"},[_vm._v(" Aucune promotion disponible ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }