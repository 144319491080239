<template>
  <base-container>
    <header-back-button title="Pharmacies partenaires" />
    <base-flex-spinner v-if="loading" />
    <div
      v-else
      class="p-6 flex flex-col gap-3"
    >
      <received-healthcenter-groups-invitations-card
        v-if="invitations?.length"
        :invitations="invitations"
        @invitation-handled="invitationHandled"
      />
      <healthcenter-group-grid
        :groups="groups"
      />
    </div>
  </base-container>
</template>

<script>
import BaseContainer from '@/renderer/components/base/BaseContainer.vue'
import ReceivedHealthcenterGroupsInvitationsCard
from '@/renderer/app/disruption-logistics/components/healthcenter-groups/ReceivedHealthcenterGroupsInvitationsCard.vue'
import { disruptionLogisticsApi } from '@/renderer/container'
import HeaderBackButton from '@/renderer/app/core/components/header-back-button/HeaderBackButton.vue'
import HealthcenterGroupGrid
from '@/renderer/app/disruption-logistics/components/healthcenter-groups/HealthcenterGroupGrid.vue'
import BaseFlexSpinner from '@/renderer/components/base/spinner/BaseFlexSpinner.vue'

export default {
  name: 'HealthcenterGroupsPage',
  components: {
    BaseFlexSpinner,
    HealthcenterGroupGrid,
    ReceivedHealthcenterGroupsInvitationsCard,
    BaseContainer,
    HeaderBackButton
  },
  data () {
    return {
      loading: false,
      invitations: null,
      groups: null
    }
  },
  /**
   * On mount, register store modules.
   */
  mounted () {
    this.loading = true

    Promise.all([
      this.getPendingInvitations(),
      this.getHealthcenterGroups()
    ])
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    /**
     * Get the pending invitations.
     *
     * @returns {Promise<void>}
     */
    getPendingInvitations () {
      return disruptionLogisticsApi()
        .receivedHealthcenterGroupInvitations('pending')
        .then((invitations) => {
          this.invitations = invitations
        })
    },
    /**
     * Remove the handled invitation from the state.
     *
     * @param invitation
     * @param accepted
     */
    invitationHandled (invitation, accepted) {
      this.invitations = this.invitations.filter((i) => i.id !== invitation.id)

      if (accepted) {
        this.loading = true
        this.getHealthcenterGroups()
          .finally(() => {
            this.loading = false
          })
      }
    },
    /**
     * Get the healthcenter groups.
     *
     * @returns {Promise<void>}
     */
    getHealthcenterGroups () {
      return disruptionLogisticsApi()
        .healthcenterGroups()
        .then((groups) => {
          this.groups = groups
        })
    }
  }
}
</script>
