<template>
  <div class="bg-pink-lighter pl-6 pt-3 pb-3">
    <h1 class="mb-4 text-gray-dark font-sans font-bold">
      Parlez-en à {{ consumer.information.first_name }}
    </h1>
    <div>
      <div class="flex flex-1 flex-row space-y-2 overflow-x-auto pb-3">
        <div
          v-if="programs.length > 0"
          class="flex flex-1 space-x-5"
        >
          <ConsumerProgramCard
            v-for="program in programs"
            :key="`program-${program.id}-${program.consumer.id}`"
            :program="program"
            :consumer="program.consumer"
            :customer="customer"
          />
        </div>
        <div
          v-else
          class="text-xs ml-6"
        >
          Aucune suggestion pour le moment.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConsumerProgramCard from '@/renderer/app/consumer/components/consumer-programs/ConsumerProgramCard.vue'

export default {
  components: { ConsumerProgramCard },
  props: {
    /**
     * The consumer that the programs belong to
     */
    consumer: {
      type: Object,
      required: true
    },
    /**
     * The customer that the consumer belongs to
     */
    customer: {
      type: Object,
      required: true
    },
    /**
     * The consumers that the programs belong to, in order to display merged programs
     */
    consumers: {
      type: Array,
      required: false
    },
    /**
     * Whether to display merged programs
     */
    displayMergedPrograms: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    /**
     * Get the programs to diplay.
     * @return {Array}
     */
    programs () {
      let _programs

      if (this.displayMergedPrograms) {
        _programs = this.mergePrograms()
      } else {
        _programs = this.consumer.programs.map(program => ({
          ...program,
          consumer: this.consumer
        }) ?? [])
      }

      return _programs.filter(program => program.programmable_type !== 'feature')
    }
  },
  methods: {
    /**
     * Merge the programs of the consumers in one array.
     * @return {FlatArray<unknown[], 1>[]}
     */
    mergePrograms () {
      return (this.consumers ?? []).map(_consumer => _consumer.programs.map(_program => ({
        ..._program,
        consumer: _consumer
      })) ?? [])
        .flat()
    }
  }
}
</script>
