/**
 * The event resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Read a event.
     * @param {number} id - The ID of the event.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    read (id) {
      return client
        .get(`/events/${id}`, {
          params: {
            include: 'custom'
          }
        })
        .then(response => response.data.event)
    }
  }
}
