<template>
  <div>
    <base-spinner v-if="isLoading" />
    <div v-else>
      <prescription-list
        :prescriptions="prescriptions"
        @on-press="onPress"
      />
      <div class="flex justify-center py-4">
        <base-button
          v-if="!!afterKey && !endReached"
          class="font-medium"
          :disabled="isLoadingMore"
          @click="loadMore"
        >
          <base-spinner
            v-if="isLoadingMore"
            size="button"
          />
          <span v-else>Voir plus</span>
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { prescriptionApi } from '@/renderer/container'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import PrescriptionList from '@/renderer/app/prescription/components/PrescriptionList.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'

export default {
  components: { BaseButton, PrescriptionList, BaseSpinner },
  data () {
    return {
      isLoading: false,
      isLoadingMore: false,
      prescriptions: [],
      afterKey: null,
      endReached: false
    }
  },
  mounted () {
    this.isLoading = true
    this.index()
      .finally(() => {
        this.isLoading = false
      })
  },
  methods: {
    /**
     * List prescriptions of a customer.
     *
     * @param [after] Optional after key, to get prescriptions beyond that key.
     *
     * @return {Promise<void>}
     */
    index (after = null) {
      return prescriptionApi().customerIndex(
        this.$route.params.customerId,
        after ? { limit: 10, after: JSON.stringify(after) } : { limit: 10 }
      )
        .then(response => {
          this.prescriptions = [...this.prescriptions, ...response.data]
          if (this.afterKey !== response.meta.after_key) {
            this.afterKey = response.meta.after_key
          } else {
            this.endReached = true
          }
        })
    },
    /**
     * Load more prescriptions with the after key.
     */
    loadMore () {
      this.isLoadingMore = true
      this.index(this.afterKey)
        .finally(() => {
          this.isLoadingMore = false
        })
    },
    /**
     * On press handle action with the prescription.
     *
     * TODO: Pour l'instant l'action par défaut est d'ouvrir le plan de renouvellement mais à terme on gérera plusieurs
     * actions en fonction du type d'action sélectionnée par l'utilisateur.
     *
     * @param prescription
     */
    onPress (prescription) {
      this.$router.push({ name: 'customer.prescriptions.renewal', params: { prescription } })
    }
  }
}
</script>
