<template>
  <div v-if="participation">
    <div class="md:flex md:items-center md:justify-between md:space-x-5 mb-8">
      <div class="flex items-center space-x-5">
        <div class="flex-shrink-0">
          <SelloutIcon :size="6" />
        </div>

        <div>
          <h1 class="text-2xl font-bold text-gray-900">
            {{ participation.sellout.name }}
          </h1>

          <p class="text-sm font-medium text-gray-500">
            Du

            <time :datetime="participation.sellout.startDate">
              {{ startDate }}
            </time>

            au

            <time :datetime="participation.sellout.endDate">
              {{ endDate }}
            </time>
          </p>
        </div>
      </div>

      <div class="mt-6">
        <div v-if="participation.status">
          <span
            v-if="participation.status === 1"
            class="inline-flex items-center px-3 py-0.5 rounded-full font-medium bg-green-100 text-green-800"
          >
            Accepté
          </span>

          <span
            v-if="participation.status === 2"
            class="inline-flex items-center px-3 py-0.5 rounded-full font-medium bg-red-100 text-red-800"
          >
            Refusé
          </span>
        </div>

        <div v-else>
          <div v-if="expired">
            <span class="inline-flex items-center px-3 py-0.5 rounded-full font-medium bg-yellow-100 text-yellow-800">
              Expiré
            </span>
          </div>

          <div
            v-else
            class="flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3"
          >
            <BaseButton @click="modal = 'accepting'">
              <svg
                fill="currentColor"
                viewBox="0 0 20 20"
                class="-ml-1 mr-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                />
              </svg>

              Accepter
            </BaseButton>

            <BaseButton @click="modal = 'refusing'">
              <svg
                viewBox="0 0 20 20"
                fill="currentColor"
                class="-ml-1 mr-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>

              Refuser
            </BaseButton>
          </div>
        </div>
      </div>
    </div>

    <BaseSection>
      <BaseCard>
        <BaseImage
          :text="participation.sellout.name"
          :path="participation.sellout.imagePath"
          class="w-full h-96 border-b border-gray-200"
        />

        <BaseCardBody>
          <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div class="sm:col-span-2">
              <dt class="text-sm font-medium text-gray-500">
                Promoteur
              </dt>

              <dd class="mt-1 text-sm text-gray-900">
                {{ participation.sellout.organization.morphable.name }}
              </dd>
            </div>

            <div class="sm:col-span-2">
              <dt class="text-sm font-medium text-gray-500">
                Description
              </dt>

              <dd class="mt-1 text-sm text-gray-900">
                {{ participation.sellout.description }}
              </dd>
            </div>
          </dl>
        </BaseCardBody>
      </BaseCard>

      <BaseCard>
        <BaseCardHeader>
          Récompenses
        </BaseCardHeader>

        <BaseCardBody>
          <ul class="list-disc list-inside">
            <li
              v-for="reward in unitRewards"
              :key="`unit-reward-${reward.id}`"
              class="text-sm"
            >
              {{ reward.teaser }}
            </li>
          </ul>

          <ul class="list-disc list-inside">
            <li
              v-for="reward in objectiveRewards"
              :key="`objective-reward-${reward.id}`"
              class="text-sm"
            >
              {{ reward.teaser }}
            </li>
          </ul>
        </BaseCardBody>
      </BaseCard>

      <BaseCard>
        <BaseCardHeader>
          Produits
        </BaseCardHeader>

        <ul
          role="list"
          class="divide-y divide-gray-200"
        >
          <li
            v-for="product in participation.sellout.products"
            :key="product.id"
          >
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="min-w-0 flex-1 flex items-center">
                <div class="flex-shrink-0">
                  <BaseImage
                    class="h-20 w-20 rounded-lg"
                    :text="product.presentation.name"
                    :path="product.presentation.photoPath"
                  />
                </div>

                <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-1 md:gap-4">
                  <div>
                    <p class="text-sm font-medium">
                      {{ product.presentation.name }}
                    </p>

                    <p class="mt-1 flex items-center text-sm text-gray-500">
                      {{ product.presentation.code }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </BaseCard>
    </BaseSection>

    <BaseModal
      v-show="modal === 'accepting'"
      title="Accepter le challenge équipe"
      @close="modal = null"
    >
      <fieldset>
        <legend class="sr-only">
          Type de distribution
        </legend>

        <div class="bg-white rounded-md -space-y-px">
          <label
            v-for="distribution in distributions"
            :key="distribution.id"
            class="rounded-tl-md rounded-tr-md relative border p-4 flex cursor-pointer focus:outline-none"
          >
            <input
              v-model="selectedDistribution"
              type="radio"
              :value="distribution"
              class="h-4 w-4 mt-0.5 cursor-pointer text-indigo-600 border-gray-300 focus:ring-indigo-500"
            >

            <div class="ml-3 flex flex-col">
              <span
                id="privacy-setting-0-label"
                class="block text-sm font-medium"
              >
                {{ distribution.name }}
              </span>

              <span
                id="privacy-setting-0-description"
                class="block text-sm text-gray-500"
              >
                {{ distribution.description }}
              </span>
            </div>
          </label>
        </div>
      </fieldset>

      <template v-slot:footer>
        <BaseButton @click="close">
          Fermer
        </BaseButton>

        <BaseButton
          primary
          :disabled="!selectedDistribution"
          @click="accept"
        >
          Valider
        </BaseButton>
      </template>
    </BaseModal>

    <BaseModal
      v-show="modal === 'refusing'"
      title="Refuser le challenge équipe"
    >
      <div class="sm:col-span-6">
        <label
          for="about"
          class="block text-sm font-medium text-gray-700"
        >
          Pour quelle raison refusez-vous ce challenge équipe ?
        </label>

        <div class="mt-1">
          <textarea
            v-model="comments"
            rows="3"
            class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
          />
        </div>
      </div>

      <template v-slot:footer>
        <BaseButton @click="close">
          Fermer
        </BaseButton>

        <BaseButton
          primary
          :disabled="!comments"
          @click="refuse"
        >
          Valider
        </BaseButton>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import {
  selloutTeaserService,
  rewardDistributionResource,
  selloutParticipationResource
} from '@/renderer/container'

import modalMixin from '@/renderer/mixins/modalMixin'

import BaseCard from '@/renderer/components/base/BaseCard.vue'
import BaseImage from '@/renderer/components/base/BaseImage.vue'
import BaseModal from '@/renderer/components/base/BaseModal.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import BaseSection from '@/renderer/components/base/BaseSection.vue'
import BaseCardBody from '@/renderer/components/base/BaseCardBody.vue'
import BaseCardHeader from '@/renderer/components/base/BaseCardHeader.vue'

import SelloutIcon from '@/renderer/components/icons/Sellout.vue'

export default {
  components: {
    BaseCard,
    BaseImage,
    BaseModal,
    BaseButton,
    SelloutIcon,
    BaseSection,
    BaseCardBody,
    BaseCardHeader
  },

  mixins: [
    modalMixin
  ],

  data () {
    return {
      modal: null,
      comments: null,
      participation: null,
      distributions: [],
      selectedDistribution: null,

      date: {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        weekday: 'long'
      }
    }
  },

  computed: {
    startDate () {
      const startDate = new Date(
        this.participation.sellout.startDate
      )

      return startDate.toLocaleDateString(undefined, this.date)
    },

    endDate () {
      const endDate = new Date(
        this.participation.sellout.endDate
      )

      return endDate.toLocaleDateString(undefined, this.date)
    },

    expired () {
      const endDate = new Date(
        this.participation.sellout.endDate
      )

      const now = new Date()

      return endDate.getTime() < now.getTime()
    },

    unitRewards () {
      return this.participation.sellout.unitRewards.map(reward => {
        reward.teaser = selloutTeaserService()
          .generateUnitTeaser(reward.gain, reward.remuneration)

        return reward
      })
    },

    objectiveRewards () {
      return this.participation.sellout.objectiveRewards.map(reward => {
        reward.teaser = selloutTeaserService()
          .generateObjectiveTeaser(
            reward.gain,
            reward.remuneration,
            reward.goal,
            reward.objective,
            reward.startDate,
            reward.endDate,
            reward.objectiveMode
          )

        return reward
      })
    }
  },

  mounted () {
    selloutParticipationResource()
      .read(this.$route.params.selloutId)
      .then(participation => (this.participation = participation))

    rewardDistributionResource()
      .get()
      .then(distributions => (this.distributions = distributions))
  },

  methods: {
    close () {
      this.modal = null
    },

    accept () {
      selloutParticipationResource()
        .accept(this.$route.params.selloutId, this.selectedDistribution.id)
        .then(participation => {
          this.participation = participation
          this.close()
        })
    },

    refuse () {
      selloutParticipationResource()
        .refuse(this.$route.params.selloutId, this.comments)
        .then(participation => {
          this.participation = participation
          this.close()
        })
    }
  }
}
</script>
