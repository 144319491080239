<template>
  <base-container>
    <header-back-button title="Création d'un groupe" />
    <div class="p-6">
      <p>
        Saisissez un nom pour votre groupe
      </p>
      <create-healthcenter-group-form
        class="mt-2"
        :loading="loading"
        @submit="handleSubmit"
      />
    </div>
  </base-container>
</template>

<script>
import BaseContainer from '@/renderer/components/base/BaseContainer.vue'
import HeaderBackButton from '@/renderer/app/core/components/header-back-button/HeaderBackButton.vue'
import CreateHealthcenterGroupForm
from '@/renderer/app/disruption-logistics/components/healthcenter-groups/CreateHealthcenterGroupForm.vue'
import { disruptionLogisticsApi } from '@/renderer/container'
import ToastService from '@/renderer/services/ToastService'

export default {
  name: 'CreateHealthcenterGroupPage',
  components: { CreateHealthcenterGroupForm, HeaderBackButton, BaseContainer },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    /**
     * On submit, create the healthcenter group, and redirect to the group details page.
     *
     * @param {{name: string; description: string;}} data
     */
    handleSubmit (data) {
      this.loading = true
      disruptionLogisticsApi()
        .createHealthcenterGroup(data)
        .then((data) => {
          ToastService().success('Le groupe a été créé avec succès')
          this.$router.replace({
            name: 'disruption-logistics.healthcenter-group',
            params: { healthcenterGroupId: data.id.toString() }
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
