import {
  storageService
} from '@/renderer/container'

export default {
  success (config) {
    const token = storageService().read('token')

    config.headers.Authorization = `Bearer ${token}`

    return config
  }
}
