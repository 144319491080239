<template>
  <div
    v-if="loading === false"
    class="flex flex-col container mx-auto p-2"
  >
    <div
      v-if="!!fileSettings.length"
      class="mb-2"
    >
      <h2 class="text-xl font-bold mt-2">
        Fichiers liés aux programmes
      </h2>
      <program-consumer-setting-file-card
        v-for="setting in fileSettings"
        :key="setting.id"
        :setting="setting"
        class="mb-1"
        @click="(_setting) => onFileSettingClick(_setting)"
      />
    </div>
    <div v-if="!!files.length">
      <h2 class="text-xl font-bold mt-2">
        Fichiers hospitaliers
      </h2>
      <customer-hospital-file-card
        v-for="file in files"
        :key="file.id"
        :file="file"
        @click="(_file) => onFileClick(_file)"
      />
    </div>
  </div>
  <div
    v-else
    class="mt-4"
  >
    <base-spinner />
  </div>
</template>

<script>
import { hospitalFileApi, programResource } from '@/renderer/container'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import CustomerHospitalFileCard from '@/renderer/components/views/customer/CustomerHospitalFileCard.vue'
import ProgramConsumerSettingFileCard
from '@/renderer/app/program/components/program-consumer-settings/ProgramConsumerSettingFileCard.vue'

export default {
  name: 'CustomerHospitalFiles',
  components: { ProgramConsumerSettingFileCard, CustomerHospitalFileCard, BaseSpinner },
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      files: [],
      fileSettings: [],
      loading: false
    }
  },
  mounted () {
    this.loading = true
    this.loadHospitalFiles(this.customer.consumer_key)
      .then(() => {
        return this.loadProgramFileSettings(this.customer.consumer_key)
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    /**
     * Download the file.
     *
     * @param {Object} file
     */
    onFileClick (file) {
      hospitalFileApi()
        .download(file)
        .then((response) => {
          const url = URL.createObjectURL(response)
          window.open(url)
        })
    },
    /**
     * Download the file behind the setting.
     *
     * @param setting
     */
    onFileSettingClick (setting) {
      programResource()
        .downloadProgramSettingFile(setting.id)
        .then((response) => {
          const url = URL.createObjectURL(response)
          window.open(url)
        })
    },
    /**
     * Load the hospital files.
     *
     * @param consumerKey
     * @return {Promise<unknown>}
     */
    loadHospitalFiles (consumerKey) {
      return hospitalFileApi()
        .index(consumerKey)
        .then((response) => {
          this.files = response
        })
    },
    /**
     * Load the consumer program settings.
     *
     * @param consumerKey
     * @return {Promise<Object>}
     */
    loadProgramFileSettings (consumerKey) {
      return programResource()
        .consumerSupportProgramSettings(consumerKey, {
          secure_file: true
        })
        .then((response) => {
          this.fileSettings = response
        })
    }
  }
}
</script>

<style scoped>

</style>
