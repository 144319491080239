<template>
  <div class="flex gap-6 items-center">
    <img
      :src="mapService.getMarkerImage(isAvailable, members)"
      alt="marker"
      class="w-15 h-15"
    >
    <div class="flex flex-col gap-3 shadow-md bg-white rounded-md max-w-sm">
      <distributor-availability-info-window
        :content="{
          warehouse: warehouse,
          available: isAvailable,
          message
        }"
        :members="members"
      />
    </div>
  </div>
</template>

<script>
import DistributorAvailabilityInfoWindow
from '@/renderer/app/disruption-logistics/components/maps/distributors/DistributorAvailabilityInfoWindow.vue'
import DistributorAvailabilityMapService
from '@/renderer/app/disruption-logistics/services/DistributorAvailabilityMapService'

export default {
  name: 'DistributorAvailabilityMapLegendItem',
  components: { DistributorAvailabilityInfoWindow },
  props: {
    warehouse: {
      type: Object,
      required: true
    },
    isAvailable: {
      type: Boolean,
      required: false,
      default: null
    },
    message: {
      type: String,
      required: false,
      default: null
    },
    members: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    /**
     * Get the map service.
     *
     * @return {{getMarkerImage: function(Boolean, Array): ({})}}
     */
    mapService () {
      return DistributorAvailabilityMapService
    }
  }
}
</script>

<style scoped>

</style>
