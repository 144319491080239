<template>
  <div class="flex flex-col gap-2">
    <h3 class="font-content-bold text-xl">
      Taux de disponibilité chez les grossistes
    </h3>
    <line-chart
      :data="lineChartData"
      :options="options"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import LineChart from '@/renderer/app/core/components/charts/LineChart.vue'

export default {
  name: 'WholesalersAvailabilityLineChart',
  components: {
    LineChart
  },
  props: {
    data: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    /**
     * Convert the data for line chart.
     *
     * @return {{ labels: string[], datasets: { label: string, data: number[] }[] }
     */
    lineChartData () {
      return this.data.reduce((prev, currentValue) => {
        prev.labels.push(dayjs(currentValue.date).format('MM/YYYY'))
        prev.datasets[0].data.push(currentValue.rate * 100)

        return prev
      }, { labels: [], datasets: [{ label: 'Taux de disponibilité', data: [] }] })
    },
    /**
     * The chart options.
     *
     * @return {Object}
     */
    options () {
      return {
        scales: {
          y: {
            ticks: {
              callback: function (value) {
                return value + '%'
              }
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                return context.dataset.label + ': ' + context.parsed.y.toFixed(2) + '%'
              }
            }
          },
          legend: {
            display: false
          }
        }
      }
    }
  }
}
</script>
