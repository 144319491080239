<template>
  <form
    class="note__container bg-white pt-2 px-4 flex items-center gap-3"
    @submit.prevent="handleSubmit"
  >
    <span class="text-4xl mr-2">
      ✍️
    </span>
    <div class="flex-1">
      <textarea
        ref="noteInput"
        :placeholder="'Écrivez vos notes ici. Elles seront accessibles pour les futurs entretiens.\nTapez Entrée ou cliquez sur + pour enregistrer.'"
        class="bg-gray-200 w-full h-full rounded-md pr-14"
        @keydown="handleKeyDown"
      />
    </div>
    <button
      type="submit"
      class="absolute right-8 bg-white w-12 h-12 rounded-full shadow-2xl flex items-center justify-center"
    >
      <icon
        v-if="!loading"
        name="plus"
        :size="30"
      />
      <base-spinner
        v-else
        size="button"
      />
    </button>
  </form>
</template>

<script>
import Icon from '@/renderer/app/core/icon/Icon.vue'
import { createNamespacedHelpers } from 'vuex'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'

const { mapActions } = createNamespacedHelpers('interview')

export default {
  name: 'InterviewConsumerNoteInput',
  components: { BaseSpinner, Icon },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    ...mapActions(['addNote']),
    /**
     * If the user presses Enter without holding Shift, submit the form
     *
     * @param event
     */
    handleKeyDown (event) {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault()
        this.handleSubmit()
      }
    },
    /**
     * Handle form submission
     */
    handleSubmit () {
      const text = this.$refs.noteInput.value.trim()

      if (text) {
        this.loading = true
        this.addNote(text)
          .finally(() => {
            this.loading = false
            this.$refs.noteInput.value = ''
          })
      }
    }
  }
}
</script>

<style scoped>
.note__container {
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.3);
}
</style>
