<template>
  <div
    :class="sizeClass + ' mx-auto'"
    :style="{aspectRatio: ratio}"
  >
    <div
      v-if="loading"
      :class="sizeClass + ' h-full flex items-center justify-center'"
    >
      <base-spinner :size="spinnerSize" />
    </div>
    <div
      v-else
      class="grid justify-items-center"
    >
      <div
        v-if="imageUrl"
        class="relative"
      >
        <img
          :src="imageUrl"
          alt="ordonnance"
          :class="sizeClass + ' cursor-pointer object-contain'"
          @click="download()"
        >
        <div
          v-if="printButton"
          class="absolute bottom-8 right-8"
        >
          <base-button
            primary
            class="rounded-3xl"
            style="padding:10px"
            @click="download()"
          >
            <v-icon color="white">
              mdi-printer
            </v-icon>
          </base-button>
        </div>
      </div>
      <prescription
        v-else
        class="max-h-28"
      />
    </div>
  </div>
</template>

<script>
import { secureFileApi, storageService } from '@/renderer/container'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import Prescription from '@/renderer/components/icons/Prescription.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'

export default {
  name: 'SecureImage',
  components: { BaseSpinner, BaseButton, Prescription },
  props: {
    urls: {
      type: Array,
      required: true
    },
    size: {
      type: Number,
      default: 84
    },
    printButton: {
      type: Boolean,
      default: false
    },
    ratio: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      imageUrl: null,
      images: [],
      pdfUrl: null,
      loading: true,
      showSlider: false
    }
  },
  computed: {
    /**
     * The tailwind size class.
     *
     * @returns {string}
     */
    sizeClass () {
      return `w-${this.size}`
    },
    /**
     * The image spinner size depending the size of the image.
     *
     * @returns {string}
     */
    spinnerSize () {
      return this.size >= 84 ? 'medium' : 'medium-small'
    }
  },
  mounted () {
    if (this.urls.length) {
      const url = this.urls[0].thumbnail.large
      this.pdfUrl = this.urls[0].pdf
      this.getImage(url)

      if (this.urls.length > 1) {
        this.getImages(this.urls)
      }
    }
  },
  methods: {
    /**
     * Get image url.
     *
     * @param url
     */
    getImage (url) {
      secureFileApi().get(url)
        .then(response => {
          const reader = new FileReader()

          reader.readAsDataURL(response)

          reader.onload = () => {
            this.imageUrl = reader.result
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    /**
     * Get images.
     *
     * @param urls
     */
    getImages (urls) {
      urls.map(url => {
        secureFileApi().get(url.thumbnail.large)
          .then(response => {
            const reader = new FileReader()

            reader.readAsDataURL(response)

            reader.onload = () => {
              this.images.push(reader.result)
            }
          })
      })
    },
    /**
     * Print a pdf.
     *
     * @param url
     */
    print (url) {
      window.ipcRenderer
        .invoke('print', {
          url: url,
          token: storageService().read('token')
        })
        .finally(() => {
          this.close()
        })
    },
    /**
     * Print images by putting them in html and then print it.
     */
    printImages () {
      const printWindow = window.open(
        '',
        '',
        'width=' + screen.availWidth + ',height=' + screen.availHeight
      )
      printWindow.document.write('<html><head><title>Visuel</title></head>')
      printWindow.document.write('<body>')

      if (this.images.length) {
        this.images.map(image => {
          printWindow.document.write('<img src="' + image + '"/>')
        })
      } else {
        printWindow.document.write('<img src="' + this.imageUrl + '"/>')
      }

      printWindow.document.write('</body></html>')
      printWindow.document.close()
      printWindow.print()
    }
  }
}

</script>
