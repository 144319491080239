<template>
  <div class="flex flex-col">
    <h2 class="text-lg font-bold self-end pr-5">
      Accès rapides
    </h2>
    <div class="flex flex-col gap-3 mt-5">
      <div
        v-for="(shortcut, index) in shortcuts"
        :key="index"
        class="flex items-center justify-between py-2 pl-4 pr-8 bg-white rounded-l-full shadow-md cursor-pointer hover:bg-gray-100 transition-all duration-300 ease-in-out"
        :class="{
          'bg-lightPurple hover:bg-lightPurple-darkest': shortcut.count && !!shortcut.count()
        }"
        @click="select(index)"
      >
        {{ shortcut.title }}
        <div
          v-if="shortcut.count && !!shortcut.count()"
          class="ml-2"
        >
          <span class="font-bold">{{ shortcut.count() }}</span>
        </div>
      </div>
    </div>
    <interview-short-cut-drawer-element
      :component-config="component"
      @close="component = null"
    />
  </div>
</template>

<script>
import InterviewProfessionalAttachments
from '@/renderer/app/interview/components/instance-modal/short-cuts/InterviewProfessionalAttachments.vue'
import Fade from '@/renderer/app/core/components/transitions/Fade.vue'
import SlideRightToLeft from '@/renderer/app/core/components/transitions/SlideRightToLeft.vue'
import InterviewShortCutDrawerElement
from '@/renderer/app/interview/components/instance-modal/InterviewShortCutDrawerElement.vue'
import InterviewPatientAttachments
from '@/renderer/app/interview/components/instance-modal/short-cuts/InterviewPatientAttachments.vue'
import { createNamespacedHelpers } from 'vuex'
import InterviewDrugInteraction from '@/renderer/app/interview/components/instance-modal/short-cuts/InterviewDrugInteraction.vue'
import InterviewTranscript from '@/renderer/app/interview/components/instance-modal/InterviewTranscript.vue'
import InterviewSurveys from '@/renderer/app/interview/components/instance-modal/short-cuts/InterviewSurveys.vue'
import InterviewConsumerCarts
  from '@/renderer/app/interview/components/instance-modal/short-cuts/InterviewConsumerCarts.vue'
import InterviewConsumerNotes
from '@/renderer/app/interview/components/instance-modal/short-cuts/InterviewConsumerNotes.vue'

const { mapGetters, mapState } = createNamespacedHelpers('interview')

export default {
  name: 'InterviewShortCuts',
  components: { InterviewShortCutDrawerElement, SlideRightToLeft, Fade },
  data () {
    return {
      selected: null,
      component: null,
      initialShortcut: [
        {
          title: 'Bibliographie',
          component: InterviewProfessionalAttachments,
          count: () => this.professionalAttachments.length
        },
        {
          title: 'Ressources patient',
          component: InterviewPatientAttachments,
          count: () => this.patientAttachments.length
        },
        {
          title: 'Questionnaires',
          component: InterviewSurveys,
          count: () => this.surveys.length
        },
        {
          title: 'Analyse pharma.',
          component: InterviewDrugInteraction,
          minWidth: '50%'
        },
        {
          title: 'Notes internes',
          count: () => this.notes,
          component: InterviewConsumerNotes
        },
        {
          title: 'Historique délivrances',
          component: InterviewConsumerCarts,
          minWidth: '400px'
        },
        {
          title: 'Profil patient'
        },
        {
          title: 'Objectifs'
        }
      ]
    }
  },
  methods: {
    select (index) {
      this.component = {
        component: this.shortcuts[index].component ?? null,
        minWidth: this.shortcuts[index].minWidth ?? '300px'
      }
    }
  },
  computed: {
    ...mapGetters(['professionalAttachments', 'patientAttachments']),
    ...mapState({
      loadingTranscript: state => state.loading.transcript,
      transcript: state => state.transcript,
      surveys: state => state.surveys,
      notes: state => state.noteCount
    }),
    /**
     * The shortcuts.
     *
     * @returns {Array}
     */
    shortcuts () {
      const _shortcuts = [...this.initialShortcut]

      if (this.loadingTranscript || this.transcript) {
        _shortcuts.push({
          title: `Transcript${this.loadingTranscript ? ' ...' : ''}`,
          component: InterviewTranscript,
          minWidth: '400px'
        })
      }

      return _shortcuts
    }
  }
}
</script>

<style scoped>

</style>
