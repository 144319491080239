<template>
  <BaseCalendar :events="events" />
</template>

<script>
import {
  laboratoryEventResource
} from '@/renderer/container'

import BaseCalendar from '@/renderer/components/base/BaseCalendar.vue'

export default {
  components: {
    BaseCalendar
  },

  data () {
    return {
      events: []
    }
  },

  mounted () {
    laboratoryEventResource()
      .get(this.$route.params.laboratoryId)
      .then(events => (this.events = events))
  }
}
</script>
