export default {
  /**
   * Format a date to a readable format.
   *
   * @param date
   * @return {string}
   */
  readableDate (date) {
    if (!date) {
      return ''
    }
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    return new Date(date).toLocaleDateString('fr-FR', options)
  },

  /**
   * Format on the fly a phone number input.
   */
  readablePhone (input) {
    if (!input) {
      return ''
    }

    return input
      .replace('+33', '0')
      .replace(/\D+/g, '')
      .replace(/(\d{2})\s?(\d{0,2})?\s?(\d{0,2})?\s?(\d{0,2})?\s?(\d{0,2})?/, (...args) => {
        return args.slice(1, 6).filter(arg => arg !== undefined).join(' ')
      })
      .substring(0, 14)
  },

  apiPhone (input) {
    const frenchCode = '+33'
    let phone = input.replace(/ /g, '')

    if (!phone.startsWith(frenchCode) && (phone.search('0') === 0 && phone.length === 10)) {
      phone = phone.replace(phone.substring(0, 1), frenchCode)
    }

    return phone
  },

  /**
   * Format a zip code to match the 5 characters french format
   */
  formatZipCode (postalCode) {
    if (!postalCode || (postalCode.length >= 5)) {
      return postalCode
    }
    const regex = new RegExp('[0-9]{' + postalCode.toString().length + '}')
    if (regex.test(postalCode)) {
      if (postalCode.toString().length >= 1 && postalCode.toString().length < 5) {
        return postalCode.toString() + '0'.repeat(5 - postalCode.toString().length)
      }
    }
  },
  /**
   * Check if the phone number is valid.
   *
   * @param {string} phone - The phone number.
   *
   * @returns {boolean}
   */
  phoneIsValid (phone) {
    const regex = /^(0|\+33)(6|7)[0-9]{8}$/
    return regex.test(phone.replace(/\s/g, ''))
  }
}
