<template>
  <div class="relative flex">
    <BaseSpinner
      v-if="isLoading"
      class="flex"
      color="white"
      size="medium-small"
    />
    <div
      v-else
      class="flex w-full h-full"
    >
      <div
        v-if="!!base64Thumbnail"
      >
        <AttachmentThumbnail
          :base64="base64Thumbnail"
          :attachment="attachment"
          :class="[{
            'cursor-pointer': !!$listeners.click
          }]"
          @click.native="$emit('click')"
        />
      </div>
      <div
        v-else
        class="flex w-full h-full blue-grey rounded-lg"
      />
    </div>
  </div>
</template>

<script>
import { programAttachmentResource } from '@/renderer/container'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import AttachmentThumbnail
from '@/renderer/app/program/components/program-attachment/thumbnails/AttachmentThumbnail.vue'

export default {
  name: 'ProgramAttachmentCompactThumbnail',
  components: { AttachmentThumbnail, BaseSpinner },
  props: {
    attachment: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      base64Thumbnail: null,
      isLoading: true,
      thumbnailComponent: null
    }
  },
  mounted () {
    programAttachmentResource()
      .renderThumbnail('programs', this.$route.params.programId, this.attachment.id)
      .then(response => {
        this.base64Thumbnail = Buffer.from(response).toString('base64')
      })
      .catch(() => {
        this.base64Thumbnail = null
      })
      .finally(() => {
        this.isLoading = false
      })
  }
}
</script>
