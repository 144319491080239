<template>
  <div
    v-if="!subscribed"
    class="flex flex-col gap-2"
  >
    <div class="flex justify-between items-center">
      <label
        class="text-sm font-bold"
      >
        Il vous reste
        <span v-if="!loading">{{ remainingRequests }}</span>
        <v-skeleton-loader
          v-else
          class="inline-block top-1"
          width="30"
          type="text"
        />
        {{ 'recherche' | pluralize(remainingRequests) }} cette semaine
      </label>
      <button
        class="text-primary text-sm font-bold"
        @click="modalVisible = true"
      >
        Passer en illimité !
      </button>
    </div>
    <div
      v-if="remainingRequests <= 0 && !loading"
      class="gradient p-3 rounded-md text-white flex flex-col gap-3"
    >
      <h3 class="font-content-bold">
        Profitez d'un accès illimité dès maintenant !
      </h3>
      <p>
        Vous avez atteint la limite de votre utilisation gratuite. Ne vous inquiétez pas, vos crédits de recherche
        seront disponibles lundi prochain.
      </p>
      <p class="font-bold">
        Mais pourquoi attendre ? Passez à l'illimité maintenant et continuez vos recherches sans attendre !
      </p>
      <ul class="flex flex-col gap-1">
        <li>
          <strong>Accès instantané et illimité</strong> à toutes les fonctionnalités
        </li>
        <li>
          <strong>Des résultats de recherche plus rapides</strong>
        </li>
        <li>
          <strong>Support prioritaire</strong> pour toutes vos questions
        </li>
      </ul>
      <base-button
        class="self-end font-bold"
        @click="modalVisible = true"
      >
        Passer en illimité !
      </base-button>
    </div>
    <extension-details-modal
      :visible="modalVisible"
      :extension="logisticsDisruptionExtension"
      @close="modalVisible = false"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import ExtensionDetailsModal
from '@/renderer/app/extension/components/extension-details-modal/ExtensionDetailsModal.vue'

const { mapState, mapGetters } = createNamespacedHelpers('extensions/disruptionLogisticsRequest')

export default {
  name: 'RequestsState',
  components: { ExtensionDetailsModal, BaseButton },
  data () {
    return {
      modalVisible: false
    }
  },
  computed: {
    ...mapState(['remainingRequests', 'loading']),
    ...mapGetters(['subscribed', 'logisticsDisruptionExtension'])
  }
}
</script>

<style scoped>

</style>
