import { extensionResource } from '@/renderer/container'
import disruptionLogisticsRequestStore from '@/renderer/app/disruption-logistics/stores/disruptionLogisticsRequestStore'

export default {
  namespaced: true,
  modules: {
    disruptionLogisticsRequest: disruptionLogisticsRequestStore
  },
  state: {
    /** The extensions
     *
     * @type {Array|null}
     */
    extensions: null,
    /**
     * The loading states.
     *
     * @type {boolean}
     */
    loading: false
  },
  mutations: {
    /**
     * Set the extensions.
     *
     * @param {Object} state
     * @param {Extension[]} extensions
     */
    setExtensions (state, extensions) {
      state.extensions = extensions
    },
    /**
     * Set the loading state.
     *
     * @param {Object} state
     * @param {boolean} loading
     */
    setLoading (state, loading) {
      state.loading = loading
    }
  },
  actions: {
    /**
     * Load the extensions in the store.
     *
     * @param {Function} commit
     *
     * @return {Promise<void>}
     */
    loadExtensions ({ commit }) {
      commit('setLoading', true)
      return extensionResource()
        .index({ types: ['program-bundle', 'pack'] })
        .then(response => {
          commit('setExtensions', response)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    },
    /**
     * Refresh the extensions without triggering loading.
     *
     * @param commit
     * @return {Promise<void>}
     */
    refreshExtensions ({ commit }) {
      return extensionResource()
        .index({ types: ['program-bundle', 'pack'] })
        .then(response => {
          commit('setExtensions', response)
        })
    }
  },
  getters: {
    /**
     * Get the enabled extensions.
     *
     * @param state
     * @return {Extension[]}
     */
    enabledExtensions: (state) => {
      return state.extensions?.filter(extension => extension.enabled) ?? []
    },
    /**
     * Get the extension enabled of type program-bundle.
     *
     * @param state
     * @param getters
     */
    enabledProgramExtensions: (state, getters) => {
      return getters.enabledExtensions
        .filter(extension => extension.extendable_type === 'program-bundle')
    },
    /**
     * Retrieve an extension by its slug.
     *
     * @param state
     *
     * @return {function(slug: string): Extension|null}
     */
    extensionBySlug: (state) => (slug) => {
      return state.extensions?.find(extension => extension.slug === slug)
    }
  }
}
