import Modal from '@/renderer/app/prescriptor/views/PrescriptorMapSearch.vue'

const PrescriptorMapModal = {
  install (Vue, options) {
    this.EventBus = new Vue()

    Vue.component('prescriptor-map-modal', Modal)

    Vue.prototype.$prescriptorMap = {
      start (params) {
        PrescriptorMapModal.EventBus.$emit('start', params)
      }
    }
  }
}

export default PrescriptorMapModal
