<template>
  <div v-if="sellinOffer">
    <div class="md:flex md:items-center md:justify-between md:space-x-5 mb-8">
      <div class="flex items-center space-x-5">
        <div class="flex-shrink-0">
          <SellinIcon :size="6" />
        </div>

        <div>
          <h1 class="text-2xl font-bold text-gray-900">
            {{ sellinOffer.name }}
          </h1>

          <p class="text-sm font-medium text-gray-500">
            Du

            <time :datetime="sellinOffer.startDate">
              {{ sellinOffer.formattedStartDate }}
            </time>

            au

            <time :datetime="sellinOffer.endDate">
              {{ sellinOffer.formattedEndDate }}
            </time>
          </p>
        </div>
      </div>

      <div class="mt-6">
        <div v-if="participation.hasAccepted || participation.hasRefused">
          <span
            v-if="participation.hasAccepted"
            class="inline-flex items-center px-3 py-0.5 rounded-full font-medium bg-green-100 text-green-800"
          >
            Accepté
          </span>

          <span
            v-if="participation.hasRefused"
            class="inline-flex items-center px-3 py-0.5 rounded-full font-medium bg-red-100 text-red-800"
          >
            Refusé
          </span>
        </div>

        <div v-else>
          <div v-if="expired">
            <span class="inline-flex items-center px-3 py-0.5 rounded-full font-medium bg-yellow-100 text-yellow-800">
              Expiré
            </span>
          </div>

          <div
            v-else
            class="flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3"
          >
            <BaseButton @click="conditionOpened = true">
              <svg
                fill="currentColor"
                viewBox="0 0 20 20"
                class="-ml-1 mr-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                />
              </svg>

              Accepter
            </BaseButton>

            <BaseButton @click="refuse">
              <svg
                viewBox="0 0 20 20"
                fill="currentColor"
                class="-ml-1 mr-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>

              Refuser
            </BaseButton>
          </div>
        </div>
      </div>
    </div>

    <div class="mx-auto grid grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-3">
      <div class="space-y-6 lg:col-start-1 lg:col-span-3">
        <section>
          <BaseCard>
            <BaseCardHeader v-if="sellinOffer.file">
              <BaseImage
                rendered
                :text="sellinOffer.name"
                :path="sellinOffer.file.renderPath"
                class="w-full h-96"
              />
            </BaseCardHeader>

            <BaseCardBody>
              <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div class="sm:col-span-2">
                  <dt class="text-sm font-medium text-gray-500">
                    Description
                  </dt>

                  <dd class="mt-1 text-sm text-gray-900">
                    {{ sellinOffer.description }}
                  </dd>
                </div>
              </dl>
            </BaseCardBody>
          </BaseCard>
        </section>

        <section aria-labelledby="products-title">
          <BaseCard>
            <BaseCardHeader>
              Produits
            </BaseCardHeader>

            <ul
              role="list"
              class="divide-y divide-gray-200"
            >
              <li
                v-for="product in sellinOffer.products"
                :key="product.id"
              >
                <div class="flex items-center px-4 py-4 sm:px-6">
                  <div class="min-w-0 flex-1 flex items-center">
                    <div
                      v-if="product.product.photoPath"
                      class="flex-shrink-0"
                    >
                      <ProductPhoto
                        class="h-20 w-20 rounded-lg"
                        :text="product.product.name"
                        :path="product.product.photoPath"
                      />
                    </div>

                    <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                      <div>
                        <p class="text-sm font-medium truncate">
                          {{ product.product.name }}
                        </p>

                        <p class="mt-2 flex items-center text-sm text-gray-500">
                          {{ product.product.code }}
                        </p>
                      </div>

                      <div class="hidden md:block">
                        <ProductPrice
                          :price="{
                            priceWithoutVat: Number(product.price),
                            discountPercentage: product.percentageOfDiscount,
                            priceDiscountedWithoutVat: product.priceAfterDiscountWithoutVat
                          }"
                        />

                        <p class="mt-1 flex items-center text-xs text-gray-500">
                          Quantité&nbsp;: {{ product.quantity }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </BaseCard>
        </section>
      </div>

      <section
        aria-labelledby="timeline-title"
        class="lg:col-start-3 lg:col-span-1"
      />
    </div>

    <LaboratoryCondition
      v-if="conditionOpened"
      :laboratory="sellinOffer.laboratory"
      @validate="validate"
      @close="conditionOpened = false"
    />
  </div>
</template>

<script>
import {
  shopOrderResource,
  sellinOfferResource
} from '@/renderer/container'

import BaseCard from '@/renderer/components/base/BaseCard.vue'
import BaseImage from '@/renderer/components/base/BaseImage.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import BaseCardBody from '@/renderer/components/base/BaseCardBody.vue'
import BaseCardHeader from '@/renderer/components/base/BaseCardHeader.vue'

import SellinIcon from '@/renderer/components/icons/Sellin.vue'
import ProductPrice from '@/renderer/components/brand/ProductPrice.vue'
import ProductPhoto from '@/renderer/components/brand/ProductPhoto.vue'
import LaboratoryCondition from '@/renderer/components/brand/LaboratoryCondition.vue'

export default {
  components: {
    BaseCard,
    BaseImage,
    BaseButton,
    SellinIcon,
    ProductPrice,
    ProductPhoto,
    BaseCardBody,
    BaseCardHeader,
    LaboratoryCondition
  },

  data () {
    return {
      expired: false,
      sellinOffer: null,
      participation: {},
      conditionOpened: false
    }
  },

  mounted () {
    sellinOfferResource()
      .read(this.$route.params.sellinOfferId)
      .then(sellinOffer => {
        const date = {
          month: 'long',
          day: 'numeric',
          weekday: 'long',
          year: 'numeric'
        }

        const startDate = new Date(sellinOffer.startDate)
        const endDate = new Date(sellinOffer.endDate)

        sellinOffer.formattedStartDate = startDate
          .toLocaleDateString(undefined, date)

        sellinOffer.formattedEndDate = endDate
          .toLocaleDateString(undefined, date)

        const now = new Date()

        if (endDate.getTime() < now.getTime()) {
          this.expired = true
        }

        this.participation = this.readParticipation(sellinOffer)

        sellinOffer.laboratory.cgvFullPath = process.env.VUE_APP_API_URL +
          '/' + sellinOffer.laboratory.cgvPath

        this.sellinOffer = sellinOffer
      })
  },

  methods: {
    refuse () {
      sellinOfferResource()
        .refuse(this.$route.params.sellinOfferId)
        .then(sellinOffer => {
          this.participation = this.readParticipation(sellinOffer)
        })
    },

    readParticipation (sellinOffer) {
      return sellinOffer.targets
        .find(target => target.target.id === this.$store.state.system.environment.entityId)
    },

    validate () {
      sellinOfferResource()
        .accept(this.$route.params.sellinOfferId)
        .then(sellinOffer => {
          this.conditionOpened = false
          this.participation = this.readParticipation(sellinOffer)

          sellinOfferResource()
            .validate(
              this.$store.state.system.environment.entityId,
              this.$route.params.sellinOfferId
            )
            .then(sellinOffer => {
              shopOrderResource()
                .get(
                  this.$route.params.laboratoryId,
                  this.$store.state.system.environment.entityId
                )
                .then(orders => {
                  const order = orders.shift()

                  this.$router.push({
                    name: 'brand.order',
                    params: { order }
                  })
                })
            })
        })
    }
  }
}
</script>
