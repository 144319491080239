<template>
  <div class="mb-10 gap-y-3 flex flex-column">
    <div class="flex align-center bg-gray-200 py-3 rounded-xl">
      <span class="flex-1 text-center text-lg font-extrabold">Catégorie</span>
      <span class="flex-1 text-center text-lg font-extrabold">{{ data.statistics.excluded.short }}</span>
      <span class="flex-1 text-center text-lg font-extrabold">{{ data.statistics.included.short }}</span>
    </div>
    <program-digest-groups-row
      v-for="(item, index) in filteredGroups"
      :key="index"
      :row="data.statistics.included[item]"
    />
  </div>
</template>

<script>
import ProgramDigestGroupsRow from '@/renderer/app/program/components/program-statistics/ProgramDigestGroupsRow.vue'

const FILTER_INCLUDED = ['label', 'short', 'total', 'ratio', 'intersection']

export default {
  name: 'ProgramDigestGroups',
  components: { ProgramDigestGroupsRow },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    filteredGroups () {
      return Object.keys(this.data.statistics.included)
        .filter((key) => !FILTER_INCLUDED.includes(key))
    }
  }
}
</script>
<style scoped>
.bg-apodis {
  background: #3A10EB;
}

.font-custom {
  text-shadow: 1px 0px #fff;
}

.border-apodis {
  border-color: #3A10EB;
}
</style>
