<template>
  <div>
    <div class="flex flex-row align-center mb-4">
      <p class="text-primary mt-1">
        J'ajoute un commentaire pour indiquer au patient la raison du refus.
      </p>
    </div>
    <div class="flex flex-col justify-center mt-4">
      <textarea
        v-model="comment"
        :placeholder="placeholderComment"
        rows="3"
        class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm ring-gray-700 border-gray-700 rounded-md"
      />
    </div>
    <div
      v-if="loading"
      class="flex flex-col justify-center align-center mt-2 h-32"
    >
      <BaseSpinner class="w-80" />
    </div>
    <div
      v-else
      class="flex flex-col justify-center align-center"
    >
      <base-button
        primary
        class="my-4"
        @click="validate(comment)"
      >
        Valider le refus
      </base-button>
    </div>
    <div class="flex mt-10">
      <p
        class="text-primary p-2 shadow rounded-lg cursor-pointer"
        @click="goBack"
      >
        Retour
      </p>
    </div>
  </div>
</template>

<script>

import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import Icon from '@/renderer/app/core/icon/Icon.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'

export default {
  name: 'RejectedOrderComment',
  components: { BaseButton, BaseSpinner, Icon },
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      comment: null,
      placeholderComment: 'Désolé nous ne pouvons prendre en charge votre commande'
    }
  },
  methods: {
    validate (comment) {
      if (comment) {
        this.$emit('validate', comment)
      } else {
        this.$emit('validate', this.placeholderComment)
      }
    },
    goBack () {
      this.$emit('back')
    }
  }
}
</script>
