<template>
  <div class="flex flex-1 items-center justify-center">
    <div v-if="!simple">
      <div class="bg-white shadow rounded-lg p-0.5 h-24 w-24 flex items-center">
        <BaseSpinner
          v-if="!qrCode"
          size="small"
        />
        <img
          v-else
          :src="qrCode"
          alt="qr-code"
          class="w-full p-2 cursor-pointer"
          @click="$refs.modal.open()"
        >
      </div>
    </div>
    <div
      v-else
      class="flex flex-1 justify-end"
    >
      <img
        alt="Certifier"
        width="50"
        :src="require('@/assets/images/icons/qrcode.svg')"
        class="cursor-pointer"
        @click="$refs.modal.open()"
      >
    </div>
    <CustomerCertificationQrCodeModal
      ref="modal"
      :qr-code="qrCode"
      :customer="customer"
    />
  </div>
</template>

<script>
import { customerCertificationResource } from '@/renderer/container'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import CustomerCertificationQrCodeModal from '@/renderer/app/customer-certification/components/CustomerCertificationQrCodeModal.vue'

export default {
  components: {
    CustomerCertificationQrCodeModal,
    BaseSpinner
  },
  props: {
    customer: {
      type: Object,
      required: true
    },
    simple: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      qrCode: null
    }
  },
  watch: {
    'customer.certification' () {
      this.load()
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    load () {
      customerCertificationResource()
        .readQrCode(this.customer.id)
        .then(qrcode => {
          const reader = new FileReader()

          reader.readAsDataURL(qrcode)

          reader.onload = () => {
            this.qrCode = reader.result
          }
        })
    }
  }
}
</script>
