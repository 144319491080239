<template>
  <div>
    <div class="mb-8">
      <div class="sm:hidden">
        <label
          class="sr-only"
          for="communication-pills"
        >
          Sélectionnez une rubrique
        </label>

        <select
          id="communication-pills"
          class="block w-full focus:ring-indigo-500 focus:border-indigo-500
          border-gray-300 rounded-md"
          @change="navigate($event.target.value)"
        >
          <option
            v-for="pill in pills"
            :key="pill.route"
            :value="pill.route"
          >
            {{ pill.label }}
          </option>
        </select>
      </div>

      <div class="hidden sm:block">
        <nav
          aria-label="Pills"
          class="flex space-x-4"
        >
          <router-link
            v-for="pill in pills"
            :key="pill.route"
            :to="{ name: pill.route }"
            active-class="bg-gray-200 text-gray-800"
            class="text-gray-600 hover:text-gray-800 px-3 py-2 font-medium
            text-sm rounded-md"
          >
            {{ pill.label }}
          </router-link>
        </nav>
      </div>
    </div>

    <router-view />
  </div>
</template>

<script>
export default {
  data () {
    return {
      pills: [
        {
          label: 'Plan média',
          route: 'brand.communication.media-plan.list'
        },

        {
          label: 'Planogramme',
          route: 'brand.communication.planogram.list'
        },

        {
          label: 'PLV',
          route: 'brand.communication.plv.list'
        }
      ]
    }
  },

  methods: {
    navigate (route) {
      this.$router.push({
        name: route
      })
    }
  }
}
</script>
