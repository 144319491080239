<template>
  <div>
    <a
      class="text-sm font-medium text-gray-500 hover:text-gray-700 flex cursor-pointer"
      @click="$router.back()"
    >
      <svg
        viewBox="0 0 20 20"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        class="flex-shrink-0 h-5 w-5"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
        />
      </svg>

      <span class="ml-2">
        Retour
      </span>
    </a>

    <h2 class="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">
      {{ $route.params.range.name }}
    </h2>

    <ul
      role="list"
      class="mt-8 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3"
    >
      <li
        v-for="product in products"
        :key="product.id"
        class="col-span-1 flex flex-col bg-white rounded-lg shadow divide-y divide-gray-200"
      >
        <div class="flex-1 flex flex-col p-6">
          <img
            v-if="product.fullPhotoPath"
            class="h-32 flex-shrink-0 mx-auto"
            :src="product.fullPhotoPath"
            :alt="product.name"
          >

          <svg
            v-else
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            class="w-32 h-32 flex-shrink-0 mx-auto text-gray-100"
          >
            <path
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
            />

            <path
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>

          <h3 class="mt-6 text-gray-900 text-sm font-medium">
            {{ product.name }}
          </h3>

          <dl class="mt-1 flex-grow flex flex-col">
            <dt class="sr-only">
              CIP
            </dt>

            <dd class="text-gray-500 text-sm">
              {{ product.code }}
            </dd>
          </dl>

          <ProductPrice :price="product.laboratoryShopPrice" />
        </div>

        <div class="divide-x divide-gray-200 flex">
          <dl class="flex-1 p-4 text-center">
            <dt class="text-sm font-normal text-gray-900">
              Stock
            </dt>

            <dd
              class="mt-1 text-lg font-semibold"
              :style="branding.textColor"
            >
              {{ product.needs.quantityInStock }}
            </dd>
          </dl>

          <dl class="flex-1 p-4 text-center">
            <dt class="text-sm font-normal text-gray-900">
              Ventes
            </dt>

            <div
              class="mt-1 flex justify-center items-baseline text-lg font-semibold"
              :style="branding.textColor"
            >
              {{ product.needs.monthlySales.toFixed() }}

              <span class="ml-2 text-xs font-medium text-gray-500 whitespace-nowrap">
                / mois
              </span>
            </div>
          </dl>

          <dl class="flex-1 p-4 text-center">
            <dt class="text-sm font-normal text-gray-900">
              Besoin
            </dt>

            <dd
              class="mt-1 text-lg font-semibold"
              :style="branding.textColor"
            >
              {{ product.needs.quantityNeeded }}
            </dd>
          </dl>
        </div>

        <div class="-mt-px flex">
          <a
            href="#"
            class="-mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
            @click="show(product)"
          >
            <svg
              viewBox="0 0 20 20"
              fill="currentColor"
              class="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 12a2 2 0 100-4 2 2 0 000 4z"
              />

              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
              />
            </svg>

            <span class="ml-3">
              Afficher
            </span>
          </a>
        </div>
      </li>
    </ul>

    <BaseModal
      v-show="opened"
      title="Informations sur le produit"
      @close="opened = false"
    >
      <div class="flex space-x-8 mt-8">
        <div class="flex-1">
          <img
            v-if="selectedProduct.fullPhotoPath"
            :src="selectedProduct.fullPhotoPath"
            :alt="selectedProduct.name"
            class="h-80 mx-auto"
          >

          <svg
            v-else
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            class="mx-auto text-gray-100"
          >
            <path
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
            />

            <path
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
        </div>

        <div class="flex-1">
          <h3 class="mt-6 text-gray-900 text-base font-medium">
            {{ selectedProduct.name }}
          </h3>

          <dl class="mt-1 flex-grow flex flex-col">
            <dt class="sr-only">
              CIP
            </dt>

            <dd class="text-gray-500 text-base">
              {{ selectedProduct.code }}
            </dd>
          </dl>

          <p
            v-if="selectedProduct.override"
            class="mt-4 text-gray-500"
          >
            {{ sanitizedDescription }}
          </p>

          <ProductPrice
            class="mt-4"
            :price="selectedProduct.laboratoryShopPrice"
          />

          <ProductPurchase
            v-model="selectedProduct.quantity"
            :product="selectedProduct"
            class="w-72 mt-4"
          />
        </div>
      </div>

      <template v-slot:footer>
        <BaseButton @click="close">
          Fermer
        </BaseButton>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import sanitizeHtml from 'sanitize-html'

import {
  shopCartResource,
  laboratoryProductResource,
  laboratoryRangeProductResource
} from '@/renderer/container'

import modalMixin from '@/renderer/mixins/modalMixin'
import brandingMixin from '@/renderer/mixins/brandingMixin'

import BaseModal from '@/renderer/components/base/BaseModal.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import ProductPrice from '@/renderer/components/brand/ProductPrice.vue'
import ProductPurchase from '@/renderer/components/brand/ProductPurchase.vue'

export default {
  components: {
    BaseModal,
    BaseButton,
    ProductPrice,
    ProductPurchase
  },

  mixins: [
    modalMixin,
    brandingMixin
  ],

  data () {
    return {
      products: [],
      opened: false,

      selectedProduct: {
        id: null,
        quantity: 0,

        laboratoryShopPrice: {
          priceWithoutVat: 0,
          discountPercentage: 0,
          priceDiscountedWithoutVat: 0
        }
      }
    }
  },

  computed: {
    sanitizedDescription () {
      return sanitizeHtml(this.selectedProduct.override.pharmacistDescription, {
        allowedTags: []
      })
    }
  },

  mounted () {
    laboratoryRangeProductResource()
      .get(
        this.$route.params.laboratoryId,
        this.$route.params.range.id
      )
      .then(products => {
        this.products = products
      })
  },

  methods: {
    async show (selectedProduct) {
      const cart = await shopCartResource()
        .read(
          this.$route.params.laboratoryId,
          this.$store.state.system.environment.entityId
        )

      const line = cart.lines
        .filter(line => line.productId === selectedProduct.id)
        .shift()

      selectedProduct.quantity = line
        ? line.quantity
        : 0

      const product = await laboratoryProductResource()
        .read(selectedProduct.id)

      selectedProduct.override = product.override

      this.selectedProduct = selectedProduct
      this.opened = true
    }
  }
}
</script>
