<template>
  <div class="flex flex-1">
    <ProgramLogo
      class="relative z-2 mr-3"
      :program="program"
    />
    <div class="flex-1 flex-col">
      <p class="mt-6 ml-2 text-primary text-xl font-bold">
        {{ program.name }}
      </p>
      <span
        v-if="program.formatted_end_date || program.end_at"
        class="table -ml-5 mt-2.5 p-0.5 pl-7 pr-2 rounded bg-lightPurple flex-none"
      >
        {{ formatDate(program) }}
      </span>
    </div>
    <div class="flex-1 flex justify-end">
      <img
        v-if="program.organization.logo"
        class="object-cover object-center"
        :src="`${program.organization.logo}&h=${size}`"
        :alt="`${program.organization.id}`"
      >
    </div>
  </div>
</template>

<script>
import dateHelper from '@/renderer/helpers/dateHelper'
import ProgramLogo from '@/renderer/app/program/components/program-logo/ProgramLogo.vue'

export default {
  name: 'ProgramHeader',
  components: {
    ProgramLogo
  },
  props: {
    size: {
      type: Number,
      default: 90
    },
    program: {
      type: Object,
      required: true
    }
  },
  methods: {
    localeDate (dateString) {
      return dateHelper.shortFormat(new Date(dateString))
    },
    formatDate (program) {
      if (program.formatted_end_date) {
        return `Jusqu'au ${program.formatted_end_date}`
      } else if (program.end_at) {
        return `Jusqu'au ${this.localeDate(program.end_at)}`
      } else {
        return 'Permanent'
      }
    }
  }
}
</script>
