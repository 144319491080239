/**
 * The laboratory branding resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Read a laboratory branding.
     * @param {number} laboratoryId - The ID of the laboratory.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    read (laboratoryId) {
      return client
        .get(`/laboratories/${laboratoryId}/branding`, {
          params: {
            include: 'laboratory.organization,logo,background,sliders,parentRanges,parentRanges.branding,parentRanges.branding.logo,parentRanges.branding.background,parentRanges.childrenRanges,parentRanges.childrenRanges.branding,parentRanges.childrenRanges.branding.background'
          }
        })
        .then(response => response.data.laboratoryBranding)
    }
  }
}
