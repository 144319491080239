export default function (hdsClient) {
  return {
    /**
     * Search a prescriptors
     *
     * @param {Object} params Params of the search (all are options and are the following : search, lat, lon, speciality_ids[])
     *
     * @returns {Promise}
     */
    search (params) {
      return hdsClient.get('prescriptors', { params })
        .then(response => response.data.data)
    }
  }
}
