<template>
  <div
    v-if="Object.keys(consumerPrograms).length > 0"
    class="h-auto w-full"
  >
    <h2 class="text-sm font-bold mx-3 mt-3">
      Programmes
    </h2>
    <div
      ref="programs"
      class="w-full"
    >
      <div
        v-for="consumerKey in Object.keys(consumerPrograms)"
        :key="`program-${consumerKey}`"
      >
        <p
          v-if="getConsumer(consumerKey).type === 'beneficiary' "
          class="text-xs flex line-clamp-1 mx-3 mt-3"
        >
          {{ getConsumer(consumerKey)?.information.name }}
        </p>
        <div :class="layoutClass">
          <a
            v-for="(program, index) in consumerPrograms[consumerKey].slice(0, maximumProgramsToDisplayPerConsumer)"
            :key="`program-${program.id}-consumer-${consumerKey}-${index}`"
            style="cursor: pointer!important"
            class="cursor-pointer"
            @click="showProgram(consumerKey, program)"
          >
            <component
              :is="layoutComponent"
              class="mb-2"
              :customer="item.customer"
              :program="program"
              :consumer="getConsumer(consumerKey)"
            />
          </a>
        </div>
        <div
          v-if="remainingConsumerPrograms(consumerKey) > 0"
          class="flex justify-end"
        >
          <span
            class="text-xs mx-3 hover:underline cursor-pointer"
            @click.stop="showCustomer()"
          >
            + {{ remainingConsumerPrograms(consumerKey) }} autre{{
              remainingConsumerPrograms(consumerKey) > 1 ? "s" : ""
            }} programme{{ remainingConsumerPrograms(consumerKey) > 1 ? "s" : "" }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationProgramLogoAdvanced
from '@/renderer/app/program/components/program-logo/NotificationProgramLogoAdvanced.vue'
import NotificationProgramLogoSimple
from '@/renderer/app/program/components/program-logo/NotificationProgramLogoSimple.vue'
import { pathwayApi } from '@/renderer/container'
import BaseTag from '@/renderer/components/base/BaseTag.vue'

export default {
  name: 'NotificationDetectionPrograms',
  components: { BaseTag },
  props: {
    /**
     * The detection item
     *
     * @type {Object}
     */
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      maximumProgramsToDisplayPerConsumer: 3
    }
  },

  computed: {
    /**
     * The consumers of the detection item
     * @return {Array}
     */
    consumers () {
      return [
        this.item.customer,
        ...(this.item.customer.beneficiaries ?? [])
      ]
    },

    /**
     * The programs of the consumers
     *
     * @return {Object}
     */
    consumerPrograms () {
      return this.consumers.reduce((acc, consumer) => {
        if (consumer.programs?.length > 0) {
          acc[consumer.key] = consumer.programs.filter(program => program.discreet)
        }

        return acc
      }, {})
    },
    /**
     * The layout class from settings.
     *
     * @return {String}
     */
    layoutClass () {
      return 'detection-programs-' + this.item.settings.notification.layout
    },
    /**
     * The layout component from settings.
     *
     * @return {Object}
     */
    layoutComponent () {
      return this.item.settings.notification.layout === 'advanced'
        ? NotificationProgramLogoAdvanced
        : NotificationProgramLogoSimple
    }
  },
  methods: {
    /**
     * Get the consumer by key
     *
     * @param key
     * @return {Object|null}
     */
    getConsumer (key) {
      return this.consumers.find(consumer => consumer.key === key)
    },

    /**
     * Navigate to the customer page.
     */
    showCustomer () {
      if (this.item.customer) {
        window.ipcRenderer.send(
          'navigate',
          `/customer/${this.item.customer.id}/home`,
          true
        )
      }
    },

    /**
     * Navigate on the consumer program page on the main window.
     *
     * @param {string} consumerKey
     * @param {Object} program
     */
    showProgram (consumerKey, program) {
      if (program.programmable_type === 'discount') {
        window.ipcRenderer.send(
          'navigate',
          `/brand/${program.organization.entity_id}/offer/discount/${program.programmable_id}`,
          true
        )
      }

      if (program.programmable_type === 'support') {
        if (program.next_step) {
          pathwayApi().track('notification-click', program.next_step.pathway_id, consumerKey)
        }

        window.ipcRenderer.send(
          'navigate',
          `/customer/${this.item.customer.id}/support-programs/${program.id}/${consumerKey}`,
          true
        )
      }

      if (program.programmable_type === 'feature') {
        window.ipcRenderer.send(
          'navigate',
          `/customer/${this.item.customer.id}/feature-programs/${program.id}/c_${this.item.customer.id}`,
          true
        )
      }
    },

    /**
     * Count the remaining consumer programs depending the maximum programs to display per consumer
     *
     * @param consumerKey
     * @return {number}
     */
    remainingConsumerPrograms (consumerKey) {
      const remaining = this.consumerPrograms[consumerKey].length - this.maximumProgramsToDisplayPerConsumer

      if (remaining < 0) {
        return 0
      }

      return remaining
    }
  }
}
</script>
