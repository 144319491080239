/**
 * The partner resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Get all partners.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    get () {
      return client
        .get('/laboratories/partners', {
          params: {
            include: 'branding.logo,laboratoryTypes'
          }
        })
        .then(response => response.data.data)
        .catch(() => [])
    }
  }
}
