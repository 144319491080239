<template>
  <base-card
    class="cursor-pointer survey-card-container"
    @click.native="startSurvey"
  >
    <img
      alt="survey-illutration"
      :src="survey.file.url"
      class="w-full object-cover h-32"
    >
    <base-card-body>
      <p class="font-bold">
        {{ survey.name }}
      </p>
    </base-card-body>
  </base-card>
</template>

<script>
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import BaseCardHeader from '@/renderer/components/base/BaseCardHeader.vue'
import BaseCardBody from '@/renderer/components/base/BaseCardBody.vue'
import SurveyService from '@/renderer/app/survey/services/SurveyService'

export default {
  name: 'SurveyCard',
  components: { BaseCardBody, BaseCardHeader, BaseCard },
  props: {
    survey: {
      type: Object,
      required: true
    },
    consumerKey: {
      type: String,
      required: true
    }
  },
  methods: {
    /**
     * Start a new survey instance.
     */
    startSurvey () {
      SurveyService.startSurvey(
        this.survey.id,
        this.consumerKey,
        null,
        null,
        {
          showVerdict: true,
          onClose: this.emitSurveyCompleted
        }
      )
    },
    /**
     * Emit the survey completed event.
     */
    emitSurveyCompleted () {
      this.$emit('survey-completed')
    }
  }
}
</script>

<style scoped>
.survey-card-container:hover img {
  transition: ease 0.8s;
  transform: scale(1.1);
}
</style>
