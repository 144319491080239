export default function (apiClient) {
  return {
    /**
     * Get all the wholesaler warehouses.
     *
     * @param {Object} params
     * @return {Promise<Array>}
     */
    getWholesalerWarehouses (params) {
      return apiClient.get('wholesalers/warehouses/all', { params })
        .then(response => response.data.wholesalerWarehouses)
    },

    /**
     * Get the wholesaler warehouse for the current healthcenter.
     *
     * @return {Promise<Array>}
     */
    getCurrentHealthcenterWarehouses (params = {}) {
      return apiClient.get('/wholesalers/warehouses/healthcenters', { params })
        .then(response => response.data)
    },

    /**
     * Path the healthcenter warehouse.
     *
     * @param {Number} healthcenterWarehouseId The healthcenter warehouse to patch.
     * @param {Number} wholesalerId The new wholesaler id.
     * @param {Number} warehouseId The new warehouse id.
     * @param {Number} rank The warehouse rank.
     *
     * @return {Promise<Object>}
     */
    patchHealthcenterWarehouse (healthcenterWarehouseId, wholesalerId, warehouseId, rank) {
      return apiClient.put(`/wholesalers/${wholesalerId}/warehouses/${warehouseId}/healthcenters/${healthcenterWarehouseId}?rank=${rank}&include=wholesaler`)
        .then((response) => response.data)
    },

    /**
     * Test the wholesaler credential.
     *
     * @param {Number} credentialId The credential id to test.
     * @param {Number} warehouseId The warehouse ID affected to the credential.
     *
     * @return {Promise<{success: boolean}>}
     */
    testCredential (credentialId, warehouseId) {
      return apiClient.get(`wholesalers/credentials/${credentialId}/warehouses/${warehouseId}/test`)
        .then(response => response.data.data)
    }
  }
}
