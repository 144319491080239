<template>
  <div class="w-full">
    <div class="flex flex-col">
      <div v-if="active">
        <PathwayGuidanceVerdict
          v-for="verdict in step.steppable.verdict"
          v-if="Array.isArray(step.steppable.verdict)"
          class="p-4 mr-8"
          :guidance="step.steppable"
          :verdict="verdict"
        />
        <PathwayGuidanceVerdict
          v-else
          class="p-4 mr-8"
          :guidance="step.steppable"
          :verdict="step.steppable.verdict"
        />
      </div>
      <div class="flex flex-col flex-1 w-full">
        <div class="flex flex-1 flex-row justify-between p-5">
          <markdown
            :content="description"
            class="text-right"
            inherit-styles
          />
        </div>
        <div class="flex flex-row justify-end p-5">
          <BaseButton
            :primary="true"
            class="text-xs font-semibold"
            @click="$listeners.start"
          >
            {{ action }}
          </BaseButton>
        </div>
      </div>
    </div>
    <PathwayStepInsert
      v-if="active"
      :step="step"
    />
  </div>
</template>

<script>
import Markdown from '@/renderer/app/core/components/markdown/Markdown.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import PathwayGuidanceVerdict from '@/renderer/app/pathway/components/PathwayGuidanceVerdict.vue'
import PathwayStepInsert from '@/renderer/app/pathway/components/PathwayStepInsert.vue'

export default {
  name: 'PathwayGuidanceStep',
  components: { PathwayStepInsert, PathwayGuidanceVerdict, BaseButton, Markdown },
  props: {
    step: {
      type: Object,
      required: true
    },
    consumerKey: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    description () {
      return this.step.resolved.description
    },
    action () {
      return this.step.resolved.action
    }
  }
}
</script>
