<template>
  <div
    class="
      max-w-lg
      m-auto
      inline-block
      align-middle
      px-4
      sm:px-6
      lg:px-8
    "
  >
    <div class="text-2xl text-white text-center my-8">
      Entrez votre nouveau mot de passe
    </div>
    <BaseAlert
      v-if="error"
      type="error"
      title="Erreur"
    >
      {{ error }}
    </BaseAlert>
    <RegisterInput
      v-model="password"
      type="password"
      placeholder="Mot de passe"
    />
    <RegisterInput
      v-model="passwordConfirmation"
      type="password"
      placeholder="Confirmez votre mot de passe"
    />
    <div
      class="mt-2 text-xs text-gray-300"
    >
      {{ passwordInfo }}
    </div>
    <button
      type="button"
      :disabled="!submitEnabled"
      :class="{ 'opacity-25 cursor-not-allowed': !submitEnabled }"
      class="flex justify-center mx-auto py-3 px-7 mt-8 text-xl text-primary-dark rounded-full bg-secondary disabled:opacity-25"
      @click="changePassword"
    >
      Changer mon mot de passe
    </button>
  </div>
</template>

<script>
import { usersResource } from '@/renderer/container'
import passwordPolicyHelper from '@/renderer/helpers/passwordPolicyHelper'
import BaseAlert from '@/renderer/components/base/BaseAlert.vue'
import RegisterInput from '@/renderer/components/views/register/RegisterInput.vue'

export default {
  name: 'ChangeForgotPassword',

  components: {
    BaseAlert,
    RegisterInput
  },

  data () {
    return {
      error: null,
      code: null,
      password: null,
      passwordConfirmation: null
    }
  },

  computed: {
    submitEnabled () {
      return (
        this.code !== null &&
        this.password === this.passwordConfirmation &&
        passwordPolicyHelper.isValid(this.password)
      )
    },

    passwordInfo () {
      if (passwordPolicyHelper.isValid(this.password)) {
        return 'Votre mot de passe est sécurisé 👍'
      } else {
        var info = 'Votre mot de passe doit contenir'
        if (!passwordPolicyHelper.hasMinimumLength(this.password)) {
          info +=
            ' au moins ' +
            String(passwordPolicyHelper.minLength) +
            ' caractères,'
        }
        if (!passwordPolicyHelper.hasNumber(this.password)) {
          info += ' un nombre,'
        }
        if (!passwordPolicyHelper.hasLowercase(this.password)) {
          info += ' un caractère minuscule,'
        }
        if (!passwordPolicyHelper.hasUppercase(this.password)) {
          info += ' un caractère majuscule,'
        }
        if (!passwordPolicyHelper.hasSpecial(this.password)) {
          info += ' un caractère spécial,'
        }
        return info.replace(/.$/, '.')
      }
    }
  },

  mounted () {
    this.code = this.$route.params.code
    usersResource()
      .verifyForgotPasswordCode(this.code)
      .then((response) => {
        if (response.error) {
          this.error = 'Code de réinitialisation non trouvé. Ce lien de réinitialisation est probablement expiré.'
        }
      })
  },

  methods: {
    changePassword () {
      usersResource()
        .changeForgotPassword(this.code, this.password)
        .then(response => {
          if (response.status === 'success') {
            this.$router.push({ name: 'login' })
          }
        })
    }
  }
}
</script>
