<template>
  <div>
    <div class="w-full bg-darkBlue-default text-xs text-white shadow-sm mt-2 mb-2 p-3 rounded">
      <p>Pour un réassortiment dans les meilleurs délais de votre officine</p>
      <p><b>Renseignez vos agences grossistes</b></p>
    </div>
    <h1 class="font-bold mb-2">
      Dernières offres de dépannage
    </h1>
    <ul class="space-y-3">
      <li class="bg-white shadow overflow-hidden rounded-md px-3 py-2">
        <table class="min-w-full">
          <thead>
            <tr class="text-left text-xs text-gray-500 tracking-wider">
              <th>Laboratoire</th>
              <th>Date</th>
              <th>Statut</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Zentiva</td>
              <td>20/12/2020</td>
              <td>
                <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-light text-gray-800">
                  Badge
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </li>
      <li class="bg-white shadow overflow-hidden rounded-md px-3 py-2">
        <table class="min-w-full">
          <thead>
            <tr class="text-left text-xs text-gray-500 tracking-wider">
              <th>Laboratoire</th>
              <th>Date</th>
              <th>Statut</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Zentiva</td>
              <td>20/12/2020</td>
              <td>
                <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-orange-light text-gray-800">
                  Badge
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </li>
      <li class="bg-white shadow overflow-hidden rounded-md px-3 py-2">
        <table class="min-w-full">
          <thead>
            <tr class="text-left text-xs text-gray-500 tracking-wider">
              <th>Laboratoire</th>
              <th>Date</th>
              <th>Statut</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Zentiva</td>
              <td>20/12/2020</td>
              <td>
                <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-light text-gray-800">
                  Badge
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </li>
    </ul>
    <h1 class="font-bold mt-4 mb-2">
      Laboratoires partenaires
    </h1>
    <div class="bg-white overflow-hidden shadow rounded-md">
      <div
        v-if="laboratoryPartners.length > 0"
        class="px-4 py-5 sm:p-6"
      >
        <div class="grid grid-cols-3 gap-8 md:grid-cols-6 lg:grid-cols-5">
          <div
            v-for="(laboratory, i) in laboratoryPartners"
            :key="i"
            class="col-span-1 flex md:col-span-2 lg:col-span-1"
          >
            <div
              v-if="laboratory.morphable.branding.logo"
              class="text-center"
            >
              <img
                class="cursor-pointer"
                :src="'https://api.apodispharma.com/images/filemanager/' + encodeURI(laboratory.morphable.branding.logo.path) + '?fit=crop&token=' + laboratory.morphable.branding.logo.token"
                :alt="laboratory.name"
              >
              <span class="text-center text-sm">{{ laboratory.morphable.name }}</span>
            </div>
            <div
              v-else
              class="text-center"
            >
              <img
                src="https://via.placeholder.com/100x100"
                class="cursor-pointer"
                :alt="laboratory.morphable.name"
              >
              <span class="text-sm">{{ laboratory.morphable.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="px-4 py-5 sm:p-6"
      >
        <div class="rounded-md bg-blue-50 p-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: information-circle -->
              <svg
                class="h-5 w-5 text-blue-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="ml-3 flex-1 md:flex md:justify-between">
              <p class="text-sm text-blue-700">
                Aucune donnée compatible avec la pharmacie sélectionnée.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h1 class="font-bold mt-4 mb-2">
      Rupture chez mes grossistes
    </h1>
    <div class="w-full sm:flex md:flex gap-2">
      <div
        v-if="wholesalerSortedRatings.length > 0"
        class="bg-white overflow-hidden shadow rounded-md mt-2 mb-2 md:w-1/2"
      >
        <div class="px-4 py-5 sm:p-6">
          <h1 class="text-darkBlue font-bold mx-auto">
            Taux de rupture
          </h1>
          <table class="w-full">
            <tr>
              <td class="text-left font-bold">
                Laboratoire
              </td>
              <td class="text-right">
                Zentiva
              </td>
            </tr>
            <tr>
              <td class="text-left">
                Produits livrés
              </td>
              <td class="text-right">
                5161
              </td>
            </tr>
            <tr>
              <td class="text-left">
                Produits commandés
              </td>
              <td class="text-right">
                516510
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div
        v-else
        class="bg-white overflow-hidden shadow rounded-md mt-2 mb-2"
      >
        <div class="px-4 py-5 sm:p-6">
          <div class="rounded-md bg-blue-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <!-- Heroicon name: information-circle -->
                <svg
                  class="h-5 w-5 text-blue-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="ml-3 flex-1 md:flex md:justify-between">
                <p class="text-sm text-blue-700">
                  Aucune donnée compatible avec la pharmacie sélectionnée.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h1 class="font-bold mt-4 mb-2">
      Top grossistes au national
    </h1>
    <div class="bg-white overflow-hidden shadow rounded-md mt-2 mb-2">
      <div class="px-4 py-5 sm:p-6">
        <table
          v-if="wholesalerTopDataSorted.length > 0"
          class="min-w-full"
        >
          <thead>
            <tr class="text-left text-xs text-gray-500 tracking-wider">
              <th>Laboratoire</th>
              <th>Taux de dispo</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(result, i) in wholesalerTopDataSorted"
              :key="i"
              class="text-left"
            >
              <td>{{ result.wholesalerName }}</td>
              <td>
                {{ result.topAvailability.toFixed(2) }} %
              </td>
            </tr>
          </tbody>
        </table>
        <div
          v-else
          class="rounded-md bg-blue-50 p-4"
        >
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: information-circle -->
              <svg
                class="h-5 w-5 text-blue-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="ml-3 flex-1 md:flex md:justify-between">
              <p class="text-sm text-blue-700">
                Aucune donnée compatible avec la pharmacie sélectionnée.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h1 class="font-bold mt-4 mb-2">
      Rupture laboratoire au national
    </h1>
    <div class="bg-white overflow-hidden shadow rounded-md mt-2 mb-2">
      <div class="px-4 py-5 sm:p-6">
        <table class="min-w-full">
          <thead>
            <tr class="text-center text-xs text-gray-500 tracking-wider">
              <th
                scope="col"
                class="p-1 tracking-wider"
              >
                Laboratoire
              </th>
              <th
                scope="col"
                class="p-1 tracking-wider"
              >
                Taux de dispo
              </th>
              <th
                scope="col"
                class="p-1 tracking-wider"
              >
                Commandés
              </th>
              <th
                scope="col"
                class="p-1 tracking-wider"
              >
                Livrés
              </th>
              <th
                scope="col"
                class="p-1 tracking-wider"
              >
                Non livrés
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="text-center"
            >
              <td class="whitespace-nowrap">
                Zentiva
              </td>
              <td class="whitespace-nowrap">
                99%
              </td>
              <td class="whitespace-nowrap">
                1203
              </td>
              <td class="whitespace-nowrap">
                513
              </td>
              <td class="whitespace-nowrap">
                690
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/renderer/http/clients/apiClient'

export default {
  data () {
    return {
      wholesalersTopData: [],
      wholesalersTopAvaibility: [],
      wholesalerTopDataSorted: [],
      wholesalerRatings: [],
      wholesalerSortedRatings: [],
      laboratoryPartners: []
    }
  },

  mounted () {
    api.get('/disruptions/wholesalers-top-national')
      .then(response => {
        this.wholesalersTopData = response.data
        this.wholesalersTopAvaibility = this.getWholesalersTopAvaibility('months')
      })

    api.get('/disruptions/rate-for-wholesalers')
      .then(response => {
        this.wholesalerRatings = response.data
        this.wholesalerSortedRatings = this.getWholesalersRatingSorted('month')
      })

    api.get('/partnerships/apodis-disruption/laboratory?include=morphable.branding.logo')
      .then(response => {
        this.laboratoryPartners = response.data.data
      })
  },

  methods: {
    getWholesalersTopAvaibility (period) {
      this.wholesalerTopDataSorted = this.wholesalersTopData.data.aggregations[period].wholesalers.buckets

      for (let i = 0; i < this.wholesalerTopDataSorted.length; i++) {
        this.wholesalerTopDataSorted[i].topAvailability = this.wholesalerTopDataSorted[i].deliveredQuantity.value * 100 / this.wholesalerTopDataSorted[i].orderedQuantity.value

        this.wholesalerTopDataSorted.sort(function (a, b) {
          return a.topAvailability + b.topAvailability
        })
      }

      this.wholesalerTopDataSorted.sort(function (a, b) {
        if (a.topAvailability < b.topAvailability) {
          return 1
        } else if (a.topAvailability > b.topAvailability) {
          return -1
        }
        return 0
      })

      return this.wholesalerTopDataSorted
    },

    getWholesalersRatingSorted (period) {
      this.wholesalerSortedRatings = this.wholesalerRatings.data.aggregations[period].wholesalers.buckets

      for (let i = 0; i < this.wholesalerSortedRatings.length; i++) {
        this.wholesalerSortedRatings[i].topAvailability = this.wholesalerSortedRatings[i].deliveredQuantity.value * 100 / this.wholesalerSortedRatings[i].orderedQuantity.value

        this.wholesalerSortedRatings.sort(function (a, b) {
          return a.topAvailability + b.topAvailability
        })
      }

      this.wholesalerSortedRatings.sort(function (a, b) {
        if (a.topAvailability < b.topAvailability) {
          return 1
        } else if (a.topAvailability > b.topAvailability) {
          return -1
        }
        return 0
      })

      return this.wholesalerSortedRatings
    }
  }
}
</script>
