<template>
  <BaseCard class="cursor-pointer">
    <BaseCardBody
      class="flex flex-1"
      style="padding: 10px 20px;"
    >
      <div class="flex flex-1 flex-row items-center">
        <div
          v-if="$slots.icon"
          class="mr-5"
        >
          <slot name="icon" />
        </div>
        <p
          v-if="value"
          class="text-4xl text-primary font-bold font-heading text-shadow-md mr-5"
          style="margin-top 0; margin-bottom: 0;"
        >
          {{ value }}
        </p>
        <div class="flex text-sm w-full items-center">
          <p
            class="font-content font-bold"
            style="margin-top 0; margin-bottom: 0;"
          >
            {{ label }}
          </p>
        </div>
        <ChevronRightIcon
          :size="50"
          class="text-primary ml-5"
        />
      </div>
    </BaseCardBody>
  </BaseCard>
</template>

<script>
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import BaseCardBody from '@/renderer/components/base/BaseCardBody.vue'
import ChevronRightIcon from '@/renderer/components/icons/ChevronRightIcon.vue'

export default {
  name: 'StatisticCard',
  components: {
    BaseCardBody,
    BaseCard,
    ChevronRightIcon
  },
  props: {
    value: {
      type: Number,
      default: null
    },
    icon: {
      type: [String, Object],
      default: null
    },
    label: {
      type: String,
      required: true
    }
  }
}
</script>
