<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="parsedItems"
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100],
        'items-per-page-text': 'Patients par page',
        'show-current-page': true,
        'show-first-last-page': true
      }"
      @click:row="onRowClick"
    >
      <template v-slot:item.dates="{ item }">
        <div
          v-for="_date in item.dates"
          :key="_date"
          class="flex"
        >
          <div
            v-if="_date"
            class="flex items-center gap-1"
          >
            <CheckLogo />
            {{ _date }}
          </div>
        </div>
      </template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        <span v-if="itemsLength > 0">
          Affichage de {{ pageStart }} à {{ pageStop }} sur {{ itemsLength }} patients
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CheckLogo from '@/assets/images/icons-vue/checkmark-full.vue'
import dateHelper from '@/renderer/helpers/dateHelper'

export default {
  name: 'ProgramGroupConsumersTable',
  components: {
    CheckLogo
  },
  props: {
    consumers: {
      type: Array,
      required: true
    },
    meta: {
      type: Object,
      required: true
    }
  },
  computed: {
    headers () {
      return [
        {
          text: `Patients (${this.consumers.length})`,
          value: 'name'
        },
        {
          text: 'Cibles',
          value: 'groups'
        },
        {
          text: 'Date',
          value: 'dates'
        },
        ...this.trackerColumns
          .map(trackerColumn => {
            return {
              text: trackerColumn,
              value: trackerColumn
            }
          })
      ]
    },

    parsedItems () {
      return this.consumers.map(consumer => {
        return {
          key: consumer.key,
          name: consumer.name,
          groups: this.groupLabels(consumer),
          dates: this.exclusionGroups().map(key => {
            return this.date(consumer, key)
          }),
          ...consumer.tracker?.columns.reduce((trackerColumns, tracker) => {
            trackerColumns[tracker.label] = tracker.value
            return trackerColumns
          }, {})
        }
      })
    },

    trackerColumns () {
      return this.consumers.reduce((trackerColumns, consumer) => {
        if (!consumer.tracker?.columns) {
          return trackerColumns
        }

        consumer.tracker.columns.forEach(tracker => {
          if (!trackerColumns.includes(tracker.label)) {
            trackerColumns.push(tracker.label)
          }
        })

        return trackerColumns
      }, [])
    }
  },
  methods: {
    groupLabels (consumer) {
      let groups = ''
      consumer.groups.included.forEach(group => {
        if (Object.hasOwn(this.meta.included, group.slug)) {
          if (!groups) {
            groups = this.meta.included[group.slug]
          } else {
            groups += ' | ' + this.meta.included[group.slug]
          }
        }
      })

      return groups
    },
    localeDate (dateString) {
      return dateHelper.shortFormat(new Date(dateString))
    },
    date (consumer, key) {
      const group = consumer.groups.excluded.find(item => item.slug === key)

      if (group && consumer.last_visits.excluded) {
        return this.localeDate(consumer.last_visits.excluded)
      }
    },
    exclusionGroups () {
      return this.meta ? Object.keys(this.meta.excluded) : []
    },
    onRowClick (item) {
      const consumer = this.consumers.find(consumer => consumer.key === item.key)

      if (consumer) {
        this.$emit('click', consumer)
      }
    }
  }
}
</script>
