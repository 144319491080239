<template>
  <base-card
    class="border-orange-default"
    style="border-width: 1px"
  >
    <base-card-body class="flex flex-col gap-3">
      <p class="font-bold">
        Invitations reçues
      </p>
      <healthcenter-group-received-invitation-row
        v-for="invitation in invitations"
        :key="invitation.id"
        :invitation="invitation"
        @invitation-handled="invitationHandled"
      />
    </base-card-body>
  </base-card>
</template>

<script>
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import BaseCardBody from '@/renderer/components/base/BaseCardBody.vue'
import HealthcenterGroupReceivedInvitationRow
from '@/renderer/app/disruption-logistics/components/healthcenter-groups/HealthcenterGroupReceivedInvitationRow.vue'

export default {
  name: 'ReceivedHealthcenterGroupsInvitationsCard',
  components: { HealthcenterGroupReceivedInvitationRow, BaseCardBody, BaseCard },
  props: {
    /**
     * The pending invitations to display.
     */
    invitations: {
      type: Array,
      required: true
    }
  },
  methods: {
    /**
     * Methods to handle the invitation
     *
     * @param {Object} invitation - The handled invitation
     * @param {Boolean} accepted - The status of the invitation
     */
    invitationHandled (invitation, accepted) {
      this.$emit('invitation-handled', invitation, accepted)
    }
  }
}
</script>
