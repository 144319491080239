<template>
  <div class="flex flex-col px-6">
    <div class="flex items-center justify-between flex-wrap gap-4">
      <div class="flex items-center flex-1">
        <div>
          <h2 class="font-bold text-lg">
            Quand vous êtes prêt, cliquez pour enregistrer l'audio
          </h2>
          <button
            class="font-bold text-primary underline"
            @click="microphoneConfigurationVisible = true"
          >
            Configurer le microphone
          </button>
          <p class="text-gray-500 text-sm">
            Vous pouvez également importer un fichier audio en cliquant
            <button
              class="text-primary underline"
              @click="openFileUpload"
            >
              ici
            </button>
          </p>
          <p
            v-if="error"
            class="text-red-500 text-sm"
          >
            {{ error }}
          </p>
        </div>
        <div
          v-if="recording"
          class="mx-3 flex items-center"
        >
          <div class="w-4 h-4 rounded-full bg-red-500 mr-1" />
          <p>{{ timerDisplay }}</p>
        </div>
      </div>
      <div class="flex items-center gap-3 flex-wrap">
        <base-button
          v-if="!recording"
          class="font-bold text-lg"
          @click="$emit('skipRecording')"
        >
          <div class="flex items-center">
            Aller directement au questionnaire
            <icon
              name="skip-forward"
              :size="20"
              class="ml-3"
            />
          </div>
        </base-button>
        <template>
          <base-button
            v-if="recording"
            class="font-bold text-lg"
            @click="togglePause"
          >
            <div class="flex items-center">
              <icon
                :name="isPaused ? 'play' : 'pause'"
                :size="20"
                class="mr-3"
                :color="'#000000'"
              />
              {{ isPaused ? 'Reprendre' : 'Pause' }}
            </div>
          </base-button>
          <base-button
            :primary="true"
            class="font-bold text-lg relative"
            @click="buttonPress"
          >
            <div class="flex items-center gap-3">
              <div v-if="loadingRecording">
                <base-spinner
                  size="button"
                  color="white"
                />
              </div>
              <icon
                v-else-if="recording === false"
                name="microphone"
                :size="20"
                :color="'#ffffff'"
              />
              <div
                v-else
                class="w-4 h-4 ratio rounded-full bg-red-500"
              />
              <p class="flex flex-1 truncate">
                {{ recording ? 'Terminer l\'entretien' : 'Lancer l\'entretien' }}
              </p>
            </div>
          </base-button>
        </template>
        <v-file-input
          v-show="false"
          id="input-file"
          accept="audio/mp3"
          @change="handleFileUpload"
        />
      </div>
      <microphone-configuration
        :visible="microphoneConfigurationVisible"
        @close="microphoneConfigurationVisible = false"
      />
    </div>
    <audio-vizualizer
      v-show="recording && !isPaused"
      :stream="stream"
    />
  </div>
</template>

<script>
import Icon from '@/renderer/app/core/icon/Icon.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import { createNamespacedHelpers } from 'vuex'
import InterviewAudioService from '@/renderer/app/interview/services/InterviewAudioService'
import MicrophoneConfiguration from '@/renderer/app/interview/components/microphone/MicrophoneConfiguration.vue'
import AudioVizualizer from '@/renderer/app/interview/components/microphone/AudioVizualizer.vue'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'

const { mapState, mapMutations } = createNamespacedHelpers('interview')

export default {
  name: 'InterviewRecording',
  components: { BaseSpinner, AudioVizualizer, MicrophoneConfiguration, BaseButton, Icon },
  data () {
    return {
      /** The MediaRecorder instance */
      recorder: null,
      /** The audio chunks recorded */
      audioChunks: [],
      /** The date when the recording started */
      startedAt: null,
      /** The timer in seconds */
      timer: null,
      /** The interval to update the timer */
      interval: null,
      /** If the recording is paused */
      isPaused: false,
      /** The error to display if any */
      error: null,
      /** The audio stream */
      stream: null,
      /** The recording device configuration visibility */
      microphoneConfigurationVisible: false,
      /** If the recording is loading */
      loadingRecording: false
    }
  },
  beforeDestroy () {
    if (this.recorder && this.recorder.state !== 'inactive') {
      this.finish()
    }
  },
  methods: {
    /**
     * Start recording the audio
     */
    record () {
      this.isPaused = false
      this.timer = 0
      this.audioChunks = []
      this.recorder = null
      this.stream = null

      const mediaConstraints = {
        audio: {
          sampleRate: 44100,
          channelCount: 1
        }
      }

      const streamToUse = InterviewAudioService.getSelectedAudioInput()

      if (!streamToUse) {
        this.microphoneConfigurationVisible = true
        return
      }

      mediaConstraints.audio.deviceId = { exact: streamToUse }
      this.loadingRecording = true
      return navigator.mediaDevices.getUserMedia(mediaConstraints)
        .then((stream) => {
          if (!stream?.active || !stream.getAudioTracks().length) {
            throw new Error('Microphone access denied or not available')
          }

          this.stream = stream
          this.recorder = new MediaRecorder(this.stream, { mimeType: InterviewAudioService.recordMimeType })

          this.error = null

          this.recorder.ondataavailable = (e) => {
            this.audioChunks.push(e.data)
          }

          this.recorder.onstop = () => {
            const audioBlob = new Blob(this.audioChunks, { type: InterviewAudioService.recordMimeType })
            this.$emit('finish', audioBlob)
            stream.getTracks().forEach(track => track.stop())
          }

          this.loadingRecording = false
          this.startedAt = new Date()
          this.recorder.start()
          this.setRecording(true)

          this.interval = setInterval(this.updateTimeAgo, 1000)
        })
        .catch(() => {
          this.loadingRecording = false
          this.microphoneConfigurationVisible = true
          this.error = 'Une erreur est survenue lors de l\'accès au microphone'
        })
    },
    /**
     * Finish the recording
     */
    finish () {
      this.startedAt = null
      clearInterval(this.interval)
      this.setRecording(false)
      if (this.recorder) {
        this.recorder.stop()
      }
    },
    /**
     * Toggle the recording
     */
    buttonPress () {
      if (this.recording) {
        this.finish()
      } else {
        this.record()
      }
    },
    /**
     * Update the timer
     */
    updateTimeAgo () {
      if (!this.startedAt) {
        return
      }

      this.timer += 1
    },
    /**
     * Pause or resume the recording
     */
    togglePause () {
      if (this.isPaused) {
        this.recorder.resume()
        this.interval = setInterval(this.updateTimeAgo, 1000)
      } else {
        this.recorder.pause()
        clearInterval(this.interval)
      }

      this.isPaused = !this.isPaused
    },
    ...mapMutations([
      'setRecording'
    ]),
    /**
     * Open the file upload dialog
     */
    openFileUpload () {
      document.getElementById('input-file').click()
    },
    /**
     * Handle the file upload
     *
     * @param {File} file
     */
    handleFileUpload (file) {
      const blob = new Blob([file], { type: 'audio/mp3' })

      this.$emit('finish', blob)
    }
  },
  computed: {
    ...mapState({
      recording: state => state.recording
    }),
    /** Format the timer in mm:ss format */
    timerDisplay () {
      const minutes = Math.floor(this.timer / 60)
      const seconds = this.timer % 60

      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds

      return `${formattedMinutes}:${formattedSeconds}`
    }
  }
}
</script>

<style scoped>

</style>
