<template>
  <BaseContainer>
    <BaseSpinner v-if="isLoading" />
    <div
      v-if="attachments"
    >
      <program-attachment-grid
        :attachments="attachments"
        class="px-4"
        :attachable-id="$route.params.programId"
        attachable-type="programs"
      />
    </div>
  </BaseContainer>
</template>

<script>
import { programAttachmentResource } from '@/renderer/container'
import BaseContainer from '@/renderer/components/base/BaseContainer.vue'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import ProgramAttachmentGrid from '@/renderer/app/program/components/program-attachment/ProgramAttachmentGrid.vue'

export default {
  name: 'ProgramDetailAttachments',
  components: { ProgramAttachmentGrid, BaseSpinner, BaseContainer },
  data () {
    return {
      isLoading: true,
      attachments: null
    }
  },
  watch: {
    '$route.params.programId': {
      immediate: true,

      handler (value) {
        this.index(value)
      }
    }
  },
  methods: {
    /**
     * Get the attachment index for the given program.
     *
     * @param {Number} programId - The program id.
     */
    index (programId) {
      programAttachmentResource()
        .attachmentIndex('programs', programId)
        .then(attachments => {
          this.attachments = attachments
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    /**
     * Extract the sections from the given attachments.
     *
     * @param {Array} attachments - The attachments to extract the sections from.
     *
     * @return {Array} The unique attachments sections.
     */
    extractSections (attachments) {
      return attachments.reduce((sections, attachment) => {
        const attachmentSections = attachment.sections

        attachmentSections.forEach(attachmentSection => {
          const section = sections.find(_section => _section.id === attachmentSection.id)

          if (!section) {
            sections.push(attachmentSection)
          }
        })

        return sections
      }, [])
    },
    /**
     * Get the attachments for the given section.
     *
     * @param {Array} attachments - The attachments to filter.
     * @param {Object} section - The section to filter the attachments for.
     *
     * @return {Array} The attachments for the given section.
     */
    attachmentsForSection (attachments, section) {
      return attachments.filter(attachment => {
        return attachment.sections.find(_section => _section.id === section.id)
      })
    },
    /**
     * Get the attachment without section.
     *
     * @param {Array} attachments - The attachments to filter.
     *
     * @return {Array} The attachments without section.
     */
    attachmentsWithoutSection (attachments) {
      return attachments.filter(attachment => {
        return attachment.sections.length === 0
      })
    }
  }
}
</script>

<style scoped>

</style>
