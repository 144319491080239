<template>
  <div>
    <div class="flex space-x-12">
      <FrancoProgress
        class="flex-1"
        :laboratory-id="$route.params.laboratoryId"
      />

      <div class="flex space-x-4 items-center">
        <div class="text-sm">
          <span class="font-bold">
            Total HT :
          </span>

          <span class="text-gray-700">
            {{ total }} €
          </span>
        </div>

        <BaseButton @click="update()">
          Tout ajouter au panier
        </BaseButton>
      </div>
    </div>

    <ul
      role="list"
      class="mt-8 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3"
    >
      <li
        v-for="product in products"
        :key="product.id"
        class="col-span-1 flex flex-col bg-white rounded-lg shadow divide-y divide-gray-200"
      >
        <div class="flex-1 flex flex-col p-6">
          <img
            v-if="product.fullPhotoPath"
            class="h-32 flex-shrink-0 mx-auto"
            :src="product.fullPhotoPath"
            :alt="product.name"
          >

          <svg
            v-else
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            class="w-32 h-32 flex-shrink-0 mx-auto text-gray-100"
          >
            <path
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
            />

            <path
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>

          <h3 class="mt-6 text-gray-900 text-sm font-medium">
            {{ product.name }}
          </h3>

          <dl class="mt-1 flex-grow flex flex-col">
            <dt class="sr-only">
              CIP
            </dt>

            <dd class="text-gray-500 text-sm">
              {{ product.code }}
            </dd>
          </dl>

          <ProductPrice
            class="mt-4"
            :price="product.laboratoryShopPrice"
          />

          <div class="text-xs mt-4 text-gray-900">
            <span
              class="font-bold"
              :style="branding.textColor"
            >
              {{ product.quantity }} produits
            </span>

            dans votre panier
          </div>

          <ProductPurchase
            v-model="product.need"
            :product="product"
            class="mt-2"
          />
        </div>

        <div class="divide-x divide-gray-200 flex">
          <dl class="flex-1 p-4 text-center">
            <dt class="text-sm font-normal text-gray-900">
              Stock
            </dt>

            <dd
              class="mt-1 text-lg font-semibold"
              :style="branding.textColor"
            >
              {{ product.needs.quantityInStock }}
            </dd>
          </dl>

          <dl class="flex-1 p-4 text-center">
            <dt class="text-sm font-normal text-gray-900">
              Ventes
            </dt>

            <div
              class="mt-1 flex justify-center items-baseline text-lg font-semibold"
              :style="branding.textColor"
            >
              {{ product.needs.monthlySales.toFixed() }}

              <span class="ml-2 text-xs font-medium text-gray-500 whitespace-nowrap">
                / mois
              </span>
            </div>
          </dl>

          <dl class="flex-1 p-4 text-center">
            <dt class="text-sm font-normal text-gray-900">
              Jours
            </dt>

            <dd
              class="mt-1 text-lg font-semibold"
              :style="branding.textColor"
            >
              {{ product.needs.quantityNeeded }}
            </dd>
          </dl>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {
  shopCartResource,
  laboratoryProductResource
} from '@/renderer/container'

import BaseButton from '@/renderer/components/base/BaseButton.vue'

import ProductPrice from '@/renderer/components/brand/ProductPrice.vue'
import FrancoProgress from '@/renderer/components/brand/FrancoProgress.vue'
import ProductPurchase from '@/renderer/components/brand/ProductPurchase.vue'

import brandingMixin from '@/renderer/mixins/brandingMixin'

export default {
  components: {
    BaseButton,
    ProductPrice,
    FrancoProgress,
    ProductPurchase
  },

  mixins: [
    brandingMixin
  ],

  data () {
    return {
      products: []
    }
  },

  computed: {
    total () {
      return this.products.reduce((total, product) => {
        total += product.laboratoryShopPrice.priceDiscountedWithoutVat * product.need

        return total
      }, 0)
    }
  },

  watch: {
    '$store.state.quantity': {
      immediate: true,

      handler () {
        shopCartResource()
          .read(
            this.$route.params.laboratoryId,
            this.$store.state.system.environment.entityId
          )
          .then(cart => {
            laboratoryProductResource()
              .get(this.$route.params.laboratoryId)
              .then(products => {
                this.products = products.map(product => {
                  const line = cart.lines
                    .find(line => line.productId === product.id)

                  const quantity = line
                    ? line.quantity
                    : 0

                  const need = Math.max(product.needs.quantityNeeded - quantity, 0)

                  product.quantity = quantity
                  product.need = need

                  return product
                })
              })
          })
      }
    }
  },

  methods: {
    update () {
      const products = {}

      this.products.forEach(product => {
        products[product.id] = product.need
      })

      shopCartResource()
        .update(
          this.$route.params.laboratoryId,
          this.$store.state.system.environment.entityId,
          products
        )
        .then(cart => {
          this.$store.commit('setQuantity', this.value)
        })
    }
  }
}
</script>
