<template>
  <base-container class="p-6">
    <div v-if="loading">
      <base-spinner />
    </div>
    <div v-else>
      <interview-grid
        :interviews="interviews ?? []"
        :consumer-key="customer.consumer_key"
        :consumer-name="customer.information.name"
        @interview-finished="loadInstances"
      />
      <customer-interview-grid
        class="mt-3"
        :customer="customer"
        :instances="consumerInstances ?? []"
      />
    </div>
  </base-container>
</template>

<script>
import BaseContainer from '@/renderer/components/base/BaseContainer.vue'
import InterviewGrid from '@/renderer/app/interview/components/InterviewGrid.vue'
import CustomerInterviewGrid from '@/renderer/app/interview/components/CustomerInterviewGrid.vue'
import { interviewApi } from '@/renderer/container'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'

export default {
  name: 'CustomerInterviews',
  components: { BaseSpinner, CustomerInterviewGrid, InterviewGrid, BaseContainer },
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      interviews: null,
      consumerInstances: null
    }
  },
  mounted () {
    this.loading = true

    Promise.all([
      this.loadInterviews(),
      this.loadInstances()
    ])
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    /**
     * Load the interviews.
     *
     * @returns {Promise<void>}
     */
    loadInterviews () {
      return interviewApi()
        .index()
        .then(interviews => {
          this.interviews = interviews
        })
    },
    /**
     * Load the interview instances.
     *
     * @returns {Promise<void>}
     */
    loadInstances () {
      return interviewApi()
        .indexConsumerInstances(this.customer.consumer_key)
        .then(consumerInstances => {
          this.consumerInstances = consumerInstances
        })
    }
  }
}
</script>

<style scoped>

</style>
