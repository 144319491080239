/**
 * The PharmaML resource.
 * @author Christophe Lablancherie <c.lablancherie@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Get all PharmaML.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    get () {
      return client.post('/disruptions/pharmamls').then(
        response => response.data.data
      )
    }
  }
}
