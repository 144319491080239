<template>
  <div>
    <order-details
      v-if="step === 'details'"
      :order="order"
      @handleOrder="handleOrder"
      @onPatientPress="onPatientPress"
    />
    <order-operator
      v-if="step === 'operator'"
      :order="order"
      :loading="editingOrder"
      @updateOperatorCode="updateOperatorCode"
      @back="setStep('details')"
    />
    <order-date
      v-if="step === 'date'"
      :order="order"
      @validate="validateDate"
      @back="setStep('operator')"
    />
    <order-comment
      v-if="step === 'comment'"
      :order="order"
      :loading="editingOrder"
      @validate="validateOrder"
      @back="setStep('date')"
    />
    <rejected-order-comment
      v-if="step === 'reject-comment'"
      :loading="editingOrder"
      @validate="rejectOrder"
      @back="setStep('operator')"
    />
  </div>
</template>

<script>
import OrderOperator from '@/renderer/app/order/components/OrderOperator.vue'
import OrderDetails from '@/renderer/app/order/components/OrderDetails.vue'
import OrderDate from '@/renderer/app/order/components/OrderDate.vue'
import OrderComment from '@/renderer/app/order/components/OrderComment.vue'
import dayjs from 'dayjs'
import { orderApi } from '@/renderer/container'
import RejectedOrderComment from '@/renderer/app/order/components/RejectedOrderComment.vue'

export default {
  name: 'HandleOrder',
  components: { RejectedOrderComment, OrderDetails, OrderOperator, OrderDate, OrderComment },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      show: false,
      step: 'details',
      editingOrder: false,
      isRefused: false
    }
  },
  methods: {
    /**
     * Update step value.
     *
     * @param step
     */
    setStep (step) {
      this.step = step
    },
    /**
     * Action on patient press.
     *
     * @param patient
     */
    onPatientPress (patient) {
      this.$emit('goToPatient', patient)
    },
    /**
     * Handle what happens when user accept or refuse an order.
     *
     * @param accept
     */
    handleOrder (accept) {
      if (!accept) {
        this.isRefused = true
      }
      this.step = 'operator'
    },
    /**
     * Update order with the operator code of the person in charge of it.
     *
     * @param code
     */
    updateOperatorCode (code) {
      this.order.operator_code = code
      if (!this.isRefused) {
        this.step = 'date'
      } else {
        this.step = 'reject-comment'
      }
    },
    /**
     * Add available date to the order.
     *
     * @param date
     */
    validateDate (date) {
      this.step = 'comment'
      this.order.available_at = date
    },
    /**
     * Validate the end of the handling process of the order.
     *
     * @param comment
     */
    validateOrder (comment) {
      this.editingOrder = true
      if (comment) {
        this.order.operator_comment = comment
      }
      orderApi().patch(this.order.id, this.order)
        .then(() => this.$emit('orderHandled'))
        .finally(() => this.editingOrder = false)
    },
    /**
     * Reject an order.
     */
    rejectOrder (comment) {
      this.editingOrder = true
      const SQLFormat = 'YYYY-MM-DD HH:mm:ss'
      const data = {
        rejected_at: dayjs().format(SQLFormat),
        operator_code: this.order.operator_code,
        operator_comment: comment
      }

      orderApi().patch(this.order.id, data)
        .then(() => this.$emit('orderHandled'))
        .finally(() => this.editingOrder = false)
    }
  }
}
</script>
