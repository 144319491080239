var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col gap-8"},_vm._l((_vm.utterances),function(utterance){return _c('div',{staticClass:"max-w-3xl",class:{
      'self-end': _vm.isPharmacistSpeaker(utterance),
      'self-start': !_vm.isPharmacistSpeaker(utterance),
    }},[_c('p',{staticClass:"text-sm mb-1 px-2",class:{
        'text-left': !_vm.isPharmacistSpeaker(utterance),
        'text-right': _vm.isPharmacistSpeaker(utterance),
      }},[_vm._v(" "+_vm._s(utterance.speaker)+" ")]),_c('div',{staticClass:"bg-gray-200 p-2 rounded-xl px-4",class:{
        'bg-primary text-white': _vm.isPharmacistSpeaker(utterance),
      }},[_c('p',[_vm._v(_vm._s(utterance.text))])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }