<template>
  <experience-button
    :event-name="eventName"
    :v-bind="extractedButtonProps"
    :primary="primary"
    :button-style="buttonStyle"
    :disabled="disabled"
    @click="handleClick"
  >
    <slot />
  </experience-button>
</template>

<script>
import ExperienceButton from '@/renderer/app/experience/components/ExperienceButton.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'

export default {
  name: 'ExperienceablePathwayStepButton',
  components: { ExperienceButton },
  props: {
    step: {
      type: Object,
      required: true
    },
    buttonStyle: {
      type: String,
      required: false,
      default: ''
    },
    ...BaseButton.props
  },
  computed: {
    /**
     * The event name to be used for the experience button.
     *
     * @return {null|string}
     */
    eventName () {
      if (this.step) {
        return `pathway_step.${this.step.id}.completed`
      }

      return null
    },
    /**
     * The button props extracted from the props.
     *
     * @return {Omit<{readonly disabled: boolean, readonly primary: boolean, readonly program: Object}, "program">}
     */
    extractedButtonProps () {
      const { program, ...props } = this.$props
      return props
    }
  },
  methods: {
    handleClick (event) {
      this.$emit('click', event)
    }
  }
}
</script>
