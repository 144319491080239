<template>
  <div
    class="detection-header cursor-pointer relative"
    :style="{ height: headerHeight + 'px'}"
    @click="$emit('show-customer', item.customer ?? null)"
  >
    <div class="mr-3 flex-shrink-0">
      <img
        class="h-10 w-10"
        src="@/assets/images/logos/apodis-white.svg"
        alt="Logo Apodis"
      >
    </div>
    <div class="text-secondary flex-1 text-lg font-black break-normal line-clamp-2">
      <div
        v-if="item.patient"
        class="flex-1"
      >
        {{ item.patient.firstName }} {{ item.patient.lastName }}
      </div>
      <div v-else>
        Patient inconnu
      </div>
    </div>
    <div class="flex flex-row gap-x-5 justify-around absolute -bottom-5 right-5 text-primary">
      <div
        class="w-10 h-10 flex items-center justify-center bg-white rounded-full shadow-custom font-black"
        @click.stop="$emit('navigate-surveys', item.customer ?? null)"
      >
        <survey-icon class="w-6 h-6" />
      </div>
      <div
        v-if="item.certified === false"
        class="w-10 h-10 flex items-center justify-center bg-white rounded-full shadow-custom"
        @click.stop="$emit('navigate-qr-code', item.customer ?? null)"
      >
        <print :size="15" />
      </div>
      <div
        v-if="item.certified === false"
        class="w-10 h-10 flex items-center justify-center bg-white rounded-full shadow-custom font-black"
        @click.stop="$emit('navigate-sms', item.customer ?? null)"
      >
        SMS
      </div>
    </div>
  </div>
</template>

<script>
import ChevronRightIcon from '@/renderer/components/icons/ChevronRightIcon.vue'
import QrCodeIcon from '@/renderer/components/icons/QrCodeIcon.vue'
import SurveyIcon from '@/renderer/components/icons/SurveyIcon.vue'
import Print from '@/renderer/components/icons/Print.vue'

export default {
  name: 'NotificationDetectionCustomerHeader',
  components: { Print, SurveyIcon, QrCodeIcon, ChevronRightIcon },
  props: {
    /**
     * The detection item.
     */
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      headerHeight: 80
    }
  }
}
</script>
