<template>
  <div>
    <div class="bg-white sticky top-0">
      <div>
        <BaseImage
          v-if="branding.background"
          rendered
          :path="branding.background.renderPath"
          :text="`Bandeau ${branding.laboratory.name}`"
          class="w-full h-32 object-none object-center lg:h-48"
        />

        <div
          v-else
          class="h-32 w-full object-cover"
          :style="`background-color: ${branding.primaryColor}`"
        />
      </div>

      <div class="container mx-auto max-w-5xl px-8">
        <div class="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
          <div class="flex">
            <BaseImage
              v-if="branding.logo.renderPath"
              rendered
              :text="`Logo ${branding.laboratory.name}`"
              :path="`${branding.logo.renderPath}&w=100`"
              class="object-none object-center bg-white w-32 h-32 rounded-lg ring-4 ring-gray-100 p-2"
            />
          </div>

          <div class="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
            <div class="sm:hidden md:block mt-6 min-w-0 flex-1">
              <h1 class="text-2xl font-bold text-gray-900 truncate">
                {{ branding.laboratory.name }}
              </h1>
            </div>

            <div class="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
              <router-link
                :to="{ name: 'brand.calendar' }"
                :style="`background-color: ${branding.primaryColor}`"
                class="inline-flex items-center px-2.5 py-1.5 border border-transparent
                text-xs font-medium rounded shadow-sm text-white focus:outline-none
                focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
              >
                <svg
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2
                    0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0
                    5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  />
                </svg>
              </router-link>

              <BrandCart />
            </div>
          </div>
        </div>

        <div class="hidden sm:block md:hidden mt-6 min-w-0 flex-1">
          <h1 class="text-2xl font-bold text-gray-900 truncate">
            {{ branding.laboratory.name }}
          </h1>
        </div>

        <div class="mt-2">
          <div class="sm:hidden">
            <label
              for="tabs"
              class="sr-only"
            >
              Sélectionnez un onglet
            </label>

            <select
              id="tabs"
              name="tabs"
              class="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
              @change="navigate($event.target.value)"
            >
              <template v-for="tab in tabs">
                <option
                  v-if="tab.route"
                  :key="tab.name"
                  :value="tab.route.name"
                >
                  {{ tab.name }}
                </option>
              </template>
            </select>
          </div>

          <div class="hidden sm:block">
            <div class="border-b border-gray-200">
              <nav
                class="-mb-px flex space-x-8"
                aria-label="Onglets"
              >
                <template v-for="tab in tabs">
                  <router-link
                    v-if="tab.route"
                    :key="tab.name"
                    :to="tab.route"
                    active-class="border-gray-900 text-gray-900"
                    class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                  >
                    {{ tab.name }}
                  </router-link>
                </template>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>

    <BaseContainer>
      <router-view />
    </BaseContainer>
  </div>
</template>

<script>
import {
  shopCartResource,
  laboratoryBrandingResource
} from '@/renderer/container'

import BaseImage from '@/renderer/components/base/BaseImage.vue'
import BaseContainer from '@/renderer/components/base/BaseContainer.vue'
import BrandCart from '@/renderer/components/views/brand/BrandCart.vue'

export default {
  components: {
    BaseImage,
    BrandCart,
    BaseContainer
  },

  data () {
    return {
      branding: {
        laboratory: {
          name: null
        },

        logo: {
          renderPath: null
        }
      },

      tabs: [
        {
          name: 'Dashboard'
        },

        {
          name: 'Catalogue',

          route: {
            name: 'brand.range.list'
          }
        },

        {
          name: 'Besoins',

          route: {
            name: 'brand.need.list'
          }
        },

        {
          name: 'Commandes'
        },

        {
          name: 'Offres',

          route: {
            name: 'brand.offer.list'
          }
        },

        {
          name: 'Communication',

          route: {
            name: 'brand.communication.media-plan.list'
          }
        },

        {
          name: 'Statistiques'
        },

        {
          name: 'Paramètres'
        }
      ]
    }
  },

  mounted () {
    laboratoryBrandingResource()
      .read(this.$route.params.laboratoryId)
      .then(branding => {
        this.branding = branding
        this.$store.commit('setBranding', branding)
      })

    shopCartResource()
      .read(
        this.$route.params.laboratoryId,
        this.$store.state.system.environment.entityId
      )
      .then(cart => {
        const totalQuantity = cart.lines.reduce(
          (total, line) => total + line.quantity
          , 0)

        this.$store.commit('setQuantity', totalQuantity)
      })
  },

  methods: {
    navigate (route) {
      this.$router.push({
        name: route
      })
    }
  }
}
</script>
