<template>
  <div class="flex">
    <span class="flex-1 d-flex justify-center align-center text-center bg-gray-200 rounded-l-xl py-3">
      {{ row.label }}
    </span>
    <span class="flex-1 d-flex justify-center align-center gap-1 py-3 text-primary">
      {{ excludedRatio }}% <span class="text-sm">({{ row.excluded.total }})
      </span>
    </span>
    <span class="flex-1 d-flex justify-center align-center gap-1 py-3 bg-primary text-white font-extrabold rounded-r-xl">
      {{ includedRatio }}% <span class="text-sm">({{ row.count - (row.excluded.total || 0) }})
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'ProgramDigestGroupsRow',
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  computed: {
    excludedRatio () {
      return this.row.count ? Math.round(this.row.excluded.total * 100 / this.row.count) : 0
    },
    includedRatio () {
      return this.row.count ? Math.round((this.row.count - this.row.excluded.total) * 100 / this.row.count) : 0
    }
  }
}
</script>

<style scoped>

</style>
