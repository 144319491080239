import DisruptionLogisticsHomePage from '@/renderer/app/disruption-logistics/views/DisruptionLogisticsHomePage.vue'
import AvailabilitiesPage
from '@/renderer/app/disruption-logistics/views/AvailabilitiesPage.vue'
import ProductAvailabilityPage
from '@/renderer/app/disruption-logistics/views/ProductAvailabilityPage.vue'
import HealthcenterGroups
from '@/renderer/app/disruption-logistics/views/HealthcenterGroupsPage.vue'
import HealthcenterGroupDetailsPage
from '@/renderer/app/disruption-logistics/views/HealthcenterGroupDetailsPage.vue'
import CreateHealthcenterGroup
from '@/renderer/app/disruption-logistics/views/CreateHealthcenterGroupPage.vue'
import ProductAvailableAgainPage from '@/renderer/app/disruption-logistics/views/ProductAvailableAgainPage.vue'
import DirectAvailabilitySourcesPage from '@/renderer/app/disruption-logistics/views/DirectAvailabilitySourcesPage.vue'
import DirectAvailabilitiesPage from '@/renderer/app/disruption-logistics/views/DirectAvailabilitiesPage.vue'
import AvailabilityHistoryPage from '@/renderer/app/disruption-logistics/views/AvailabilityHistoryPage.vue'
import WholesalerWarehouseHealthcenterPage
from '@/renderer/app/disruption-logistics/views/WholesalerWarehouseHealthcenterPage.vue'

/**
 * Premium disruption routes.
 */
export default [
  {
    path: '/disruption-logistics',
    name: 'disruption-logistics',
    component: DisruptionLogisticsHomePage,
    redirect: '/disruption-logistics/availabilities',

    children: [
      {
        path: 'availabilities',
        name: 'disruption-logistics.availabilities',
        component: AvailabilitiesPage,
        meta: {
          title: 'Info dispo'
        }
      }, {
        path: 'disruptions',
        name: 'disruption-logistics.disruptions',
        component: ProductAvailableAgainPage,
        meta: {
          title: 'Retours de disponibilité'
        }
      },
      {
        path: 'direct-availability-sources',
        name: 'disruption-logistics.direct-availability-sources',
        component: DirectAvailabilitySourcesPage,
        meta: {
          title: 'Disponibilités ventes directes'
        }
      }
    ],

    meta: {
      title: 'Rupture premium'
    }
  },
  {
    path: '/disruption-logistics/products/:productId',
    name: 'disruption-logistics.product-availability',
    component: ProductAvailabilityPage
  },
  {
    path: '/disruption-logistics/healthcenter-groups',
    name: 'disruption-logistics.healthcenter-groups',
    component: HealthcenterGroups
  },
  {
    path: '/disruption-logistics/healthcenter-groups/:healthcenterGroupId',
    name: 'disruption-logistics.healthcenter-group',
    component: HealthcenterGroupDetailsPage
  },
  {
    path: '/disruption-logistics/healthcenter-groups/create',
    name: 'disruption-logistics.healthcenter-group-create',
    component: CreateHealthcenterGroup
  },
  {
    path: '/disruption-logistics/direct-availability-sources/:sourceId',
    name: 'disruption-logistics.direct-availabilities',
    component: DirectAvailabilitiesPage
  },
  {
    path: '/disruption-logistics/availabilities/:availabilityId/history',
    name: 'disruption-logistics.availability-history',
    component: AvailabilityHistoryPage
  },
  {
    path: '/disruption-logistics/wholesaler-warehouse-healthcenter',
    name: 'disruption-logistics.wholesaler-warehouse-healthcenter',
    component: WholesalerWarehouseHealthcenterPage
  }
]
