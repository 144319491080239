<template>
  <base-modal
    v-if="visible"
    title="Configuration du microphone"
    @close="$emit('close')"
  >
    <div class="flex flex-col gap-3">
      <div>
        <h3 class="uppercase font-content-bold">
          Périphérique d'entrée audio
        </h3>
        <v-select
          v-model="selectedMicrophoneId"
          dense
          :items="audioInputDevices"
          item-text="label"
          item-value="deviceId"
          :disabled="testing"
          @change="saveAudioInput"
        />
      </div>
      <div class="flex flex-col gap-2">
        <div>
          <h3 class="text-sm font-content-bold uppercase">
            Test du micro
          </h3>
          <p class="text-sm">
            Des problèmes de micro ? Démarre un test et dis un truc drôle...
          </p>
        </div>
        <div class="flex items-center gap-2">
          <button
            :disabled="!selectedMicrophoneId"
            class="bg-primary rounded-md text-white text-xs font-content-bold p-2"
            @click="testing ? stopTesting() : testMicrophone()"
          >
            Vérifions ça
          </button>
          <audio-vizualizer
            :stream="stream"
            class="flex-1"
          />
        </div>
        <div>
          <p class="text-xs">
            Si tu n'entends rien, vérifie que ton micro est bien branché et que le volume est suffisant
          </p>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from '@/renderer/components/base/BaseModal.vue'
import AudioVizualizer from '@/renderer/app/interview/components/microphone/AudioVizualizer.vue'
import InterviewAudioService from '@/renderer/app/interview/services/InterviewAudioService'
import ToastService from '@/renderer/services/ToastService'

export default {
  name: 'MicrophoneConfiguration',
  components: { AudioVizualizer, BaseModal },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      audioInputDevices: [],
      selectedMicrophoneId: null,
      testing: false,
      stream: null
    }
  },
  watch: {
    visible (newValue) {
      if (newValue) {
        this.getAudioInputDevices()
      } else {
        this.stopTesting()
      }
    }
  },
  methods: {
    /**
     * Get the audio input devices.
     */
    async getAudioInputDevices () {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices()

        this.audioInputDevices = devices.filter(device => device.kind === 'audioinput')

        const savedAudioInput = InterviewAudioService.getSelectedAudioInput()
        if (savedAudioInput) {
          this.selectedMicrophoneId = savedAudioInput
        }
      } catch (error) {
        console.error(error)
      }
    },
    /**
     * Run the microphone test.
     *
     * @return {Promise<void>}
     */
    async testMicrophone () {
      this.stopTesting()

      try {
        this.stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            deviceId: this.selectedMicrophoneId ? { exact: this.selectedMicrophoneId } : undefined
          },
          video: false
        })

        this.testing = true
      } catch (error) {
        ToastService().warning('Impossible de démarrer le test du micro')
      }
    },
    /**
     * Stop the microphone test.
     */
    stopTesting () {
      if (this.stream) {
        this.stream.getTracks().forEach(track => track.stop())
        this.stream = null
        this.testing = false
      }
    },
    /**
     * Save the audio input configuration.
     */
    async saveAudioInput () {
      InterviewAudioService.setSelectedAudioInput(
        this.selectedMicrophoneId
      )
    }
  }
}
</script>

<style scoped>

</style>
