<template>
  <select class="w-1/4 bg-white rounded-md mr-5 p-2 border">
    <option
      v-for="option in options"
      :key="option.id"
      :value="option.id"
    >
      {{ option.title }}
      <span v-if="option.label">
        {{ option.label }}
      </span>
    </option>
  </select>
</template>

<script>
export default {
  props: {
    options: {
      default: () => {
        return []
      },

      type: Array
    }
  }
}
</script>
