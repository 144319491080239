<template>
  <div>
    <h2 class="text-center text-primary font-bold text-3xl">
      Entretien terminé !
    </h2>
    <div class="flex flex-col text-center my-6 font-bold">
      <p class="text-center">
        Vous pouvez garder une copie de l'entretien sur votre ordinateur
        <span v-if="audio"> (Apodis ne sauvegarde pas l'audio)</span>
        <br>
        N'oubliez pas d'ajouter le document récapitulatif de l'entretien sur le DMP du patient.
      </p>
      <div class="self-center mt-2 gap-4 flex">
        <base-button
          class="font-bold"
          @click="downloadSurveyInstance"
        >
          <div class="flex">
            <icon
              name="file-blank"
              :color="'#000000'"
              :size="20"
              class="mr-2"
            />
            Télécharger l'entretien
          </div>
        </base-button>
        <base-button
          v-if="audio"
          class="font-bold"
          @click="downloadAudio"
        >
          <div class="flex">
            <icon
              name="volume"
              :color="'#000000'"
              :size="20"
              class="mr-2"
            />
            Télécharger l'audio
          </div>
        </base-button>
      </div>
    </div>
    <interview-container class="shadow-custom">
      <p class="font-bold">
        Accompagnement terminé !
      </p>
      <p class="mt-2">
        Vous avez réalisé tous les entretiens nécessaires à l’accompagnement de votre patient. <br><br>
        Vous pouvez maintenant facturer le code acte <strong>{{ actCodes }}</strong> depuis votre LGO. <br><br>
        ⚠️ Pour être valide, ce code acte doit être facturé seul, c’est-à-dire indépendamment de toute autre facturation
        (médicaments, LPP…).
      </p>
      <base-button
        class="align-right self-end font-bold mt-3"
        primary
        @click="$emit('close')"
      >
        Terminer
      </base-button>
    </interview-container>
  </div>
</template>

<script>
import InterviewContainer from '@/renderer/app/interview/components/instance-modal/InterviewContainer.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import Icon from '@/renderer/app/core/icon/Icon.vue'
import { surveyAnswerInstanceApi } from '@/renderer/container'
import InterviewAudioService from '@/renderer/app/interview/services/InterviewAudioService'

export default {
  name: 'InterviewFinished',
  components: { Icon, BaseButton, InterviewContainer },
  props: {
    interview: {
      type: Object,
      required: true
    },
    audio: {
      type: Blob,
      required: false
    },
    interviewInstance: {
      type: Object,
      required: true
    }
  },
  computed: {
    /**
     * Extract the codes from the interview linked acts.
     */
    actCodes () {
      return this.interview.acts?.map(actCode => actCode.code).join(', ') ?? ''
    }
  },
  methods: {
    /**
     * Download the audio.
     */
    downloadAudio () {
      if (!this.audio) {
        return
      }

      const objectUrl = URL.createObjectURL(this.audio)
      const a = document.createElement('a')
      a.href = objectUrl
      a.download = 'audio.' + InterviewAudioService.recordExtension
      a.click()

      URL.revokeObjectURL(objectUrl)
    },
    /**
     * Download the survey instance.
     *
     * @returns {Promise<void>}
     */
    downloadSurveyInstance () {
      return surveyAnswerInstanceApi()
        .export(this.interviewInstance.survey_answer_instance_id)
        .then(blob => {
          const url = window.URL.createObjectURL(blob)
          window.open(url)
        })
    }
  }
}
</script>

<style scoped>

</style>
