<template>
  <div>
    <div class="mt-5">
      <div v-if="loading">
        <BaseSpinner />
      </div>
      <div
        v-else
        class="flex flex-col gap-y-5 relative flex-1"
      >
        <div
          v-for="notification in notifications"
          :key="notification.id"
        >
          <certification-notification-item
            :notification="notification"
            @selected="$emit('selected', notification)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { notificationTemplateResource } from '@/renderer/container'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import CertificationNotificationItem
from '@/renderer/app/customer-certification/components/CertificationNotificationItem.vue'

export default {
  name: 'CustomerCertificationNotificationSelection',
  components: { CertificationNotificationItem, BaseSpinner },
  data () {
    return {
      customer: this.$route.params.customer,
      notifications: null,
      loading: true
    }
  },
  /**
   * On mount, get the message template index.
   */
  mounted () {
    this.loading = true
    notificationTemplateResource()
      .index({ type: 'sms' })
      .then((messages) => {
        this.notifications = messages
      })
      .finally(() => {
        this.loading = false
      })
  }
}
</script>

<style scoped>

</style>
