<template>
  <installation-step-layout @openGuide="$emit('openGuide')">
    <template v-slot:title>
      Installer Apodis Pro
    </template>
    <template v-slot:subtitle>
      Pilotez votre officine
    </template>
    <template v-slot:image>
      <img
        :src="require('@/assets/images/gamification-level-5.png')"
        alt="image gamification"
        class="h-24 xl:h-32 transform rotate-12 drop-shadow-xl"
      >
    </template>
    <template v-slot:content>
      <p class="text-primary">
        Mettez la data au profit de vos objectifs<br>
        <strong>Disponible sur mobile et tablette</strong>
      </p>
    </template>
    <template v-slot:download>
      <div class="flex flex-row flex-wrap justify-center flew-wrap gap-2 my-4 mx-4">
        <div>
          <img
            :src="require('@/assets/images/pro-app-qr-code.png')"
            class="w-24 object-contain mr-8"
            alt="app store qr code"
          >
          <label class="text-sm text-center text-primary font-bold">Scannez-moi</label>
        </div>
        <button @click="$emit('openAppStore')">
          <img
            alt="download app store"
            :src="require('@/assets/images/icons/download-appstore.svg')"
          >
        </button>
        <button @click="$emit('openPlayStore')">
          <img
            alt="download playstore"
            :src="require('@/assets/images/icons/download-playstore.svg')"
          >
        </button>
      </div>
    </template>
  </installation-step-layout>
</template>

<script>
import { defineComponent } from 'vue'
import DownloadButton from '@/renderer/components/views/onboarding/DownloadButton.vue'
import InstallationStepLayout
from '@/renderer/components/views/onboarding/installation-process/InstallationStepLayout.vue'

export default defineComponent({
  name: 'ApodisProStep',
  components: { InstallationStepLayout, DownloadButton }
})
</script>

<style scoped>

</style>
