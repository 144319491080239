<template>
  <div
    class="h-full flex flex-1 justify-center align-center"
  >
    <BaseSpinner v-bind="$attrs" />
  </div>
</template>

<script>
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'

/**
 * The base spinner.
 */
export default {
  name: 'BaseFlexSpinner',
  components: { BaseSpinner }
}
</script>
