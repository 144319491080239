<template>
  <div class="bg-white w-0 sm:w-64 md:w-80 sm:px-2 sm:py-2 md:px-4 md:py-4 overflow-y-scroll">
    <div class="text-primary font-bold md:text-2xl sm:text-lg mb-5">
      Appareils connectés
    </div>
    <div class="my-4">
      <div v-if="santeSecure !== null">
        <span class="font-semibold text-xl text-gray-800">{{ santeSecure.santeSecureVersion }}</span>
        <div class="flex flex-row rounded-lg shadow-black shadow-md border-gray-200 border p-2">
          <div
            class="flex justify-end mx-2 mt-2 w-28"
            :style="{
              'background-image': 'url(' + require('@/assets/images/icons/logo_sante_secure.png') + ')',
              'backgroundPosition': 'center top',
              'backgroundRepeat': 'no-repeat',
              'backgroundSize': 'contain'
            }"
          >
            <div
              class="rounded-full -mt-2 -mr-2 w-6 h-6"
              :class="[santeSecureState.syncState ? 'bg-secondary' : 'bg-red-light']"
            />
          </div>
          <div class="flex flex-col ml-2">
            <div class="font-black text-base text-gray-600">
              {{ santeSecureState.syncState ? 'Transmission ok' : 'Connexion perdue' }}
            </div>
            <div class="text-xs text-gray-400">
              {{ santeSecureState.lastSync }}
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="text-sm text-gray-600">
          Santé Secure n'est pas installé dans votre pharmacie.
        </div>
        <div
          class="text-sm text-primary text-right m-2 font-black flex-wrap max-w-sm cursor-pointer"
          @click="showGuide('sante-secure')"
        >
          Installer Santé Secure →
        </div>
      </div>
    </div>
    <div class="my-6">
      <h2>
        <span
          v-if="hosts.length"
          class="font-semibold text-xl text-gray-800"
        >
          Apodis Connect ({{ hosts.length }})
        </span>
      </h2>
      <ul v-if="hosts.length">
        <li
          v-for="(host) in hosts"
          :key="host.id"
        >
          <div class="flex flex-row rounded-lg shadow-black shadow-md border-gray-200 border p-2 my-2">
            <img
              alt="laptop icon"
              class="mx-2"
              :src="require('@/assets/images/icons/icone-ordinateur.svg')"
            >
            <div class="font-base text-base text-gray-800">
              {{ host.name }}
            </div>
          </div>
        </li>
      </ul>
      <div v-else>
        <div class="text-sm text-gray-600">
          Aucun de vos postes comptoir ne dispose d'Apodis Connect.
        </div>
        <div
          class="text-sm text-primary text-right m-2 font-black flex-wrap max-w-sm cursor-pointer"
          @click="showGuide('connect')"
        >
          Installer Apodis Connect →
        </div>
      </div>
    </div>
    <div class="my-6">
      <h2>
        <span
          v-if="mobiles.length"
          class="font-semibold text-xl text-gray-800"
        >
          Apodis Pro ({{ mobiles.length }})
        </span>
      </h2>
      <ul v-if="mobiles.length">
        <li
          v-for="(mobile) in mobiles"
          :key="mobile.id"
        >
          <div class="flex flex-row rounded-lg shadow-black shadow-md border-gray-200 border p-2 my-2">
            <img
              alt="iphone icone"
              class="mx-2"
              :src="require('@/assets/images/icons/icone-iphone.svg')"
            >
            <div class="font-base text-base text-gray-800">
              {{ mobile.mobileName || 'Appareil sans nom' }}
              <div class="font-base text-base text-gray-500">
                Connecté {{ getRelativeFormat(mobile.updatedAt) }}
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div v-else>
        <div class="text-sm text-gray-600">
          Vous ne vous êtes pas encore connecté sur l'application Apodis Pro.
        </div>
        <div
          class="text-sm text-primary text-right m-2 font-black flex-wrap max-w-sm"
          @click="showGuide('pro')"
        >
          Installer Apodis Pro →
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  contentfulService
} from '@/renderer/container'
import dateHelper from '@/renderer/helpers/dateHelper'
import { mapGetters } from 'vuex'

export default {
  name: 'TheSanteSecureMenu',
  components: {},
  computed: {
    ...mapGetters('website', ['santeSecure', 'hosts', 'mobiles']),
    santeSecureState () {
      let lastSync = false
      let syncState = ''
      if (this.santeSecure && this.santeSecure.lastLgoImport) {
        const date = new Date(this.santeSecure.lastLgoImport.replace(' ', 'T'))
        // Si pas de remontée depuis plus de trois jours
        const diff = new Date().getTime() - date.getTime()
        if (diff > (1000 * 60 * 60 * 24 * 3)) {
          lastSync = 'vos données ne sont plus sauvegardées depuis ' + Math.floor(diff / 86400000) + ' jours'
          syncState = false
        } else {
          lastSync = 'dernière synchronisation des données le ' + dateHelper.longFormat(date)
          syncState = true
        }
      } else {
        lastSync = 'date de dernière synchronisation inconnue'
        syncState = false
      }
      return { lastSync, syncState }
    }
  },
  methods: {
    showGuide (guide) {
      let guideId
      switch (guide) {
        case 'connect':
          guideId = contentfulService().getConnectGuideId()
          break
        case 'pro':
          guideId = contentfulService().getProGuideId()
          break
        default:
          guideId = contentfulService().getSanteSecureGuideId()
          break
      }
      this.$root.$emit('show-guide', guideId)
    },

    getRelativeFormat (date) {
      const newDate = new Date(date)
      const diff = new Date().getTime() - newDate.getTime()
      if (diff > 31536000000) {
        const value = Math.floor(diff / 31536000000)
        return 'il y a ' + value + ' an' + (value > 1 ? 's' : '')
      } else if (diff > 2592000000) {
        return 'il y a ' + Math.floor(diff / 2592000000) + ' mois'
      } else if (diff > 86400000) {
        const value = Math.floor(diff / 86400000)
        return 'il y a ' + value + ' jour' + (value > 1 ? 's' : '')
      } else if (diff > 3600000) {
        const value = Math.floor(diff / 3600000)
        return 'il y a ' + value + ' heure' + (value > 1 ? 's' : '')
      } else if (diff > 60000) {
        const value = Math.floor(diff / 60000)
        return 'il y a ' + value + ' minute' + (value > 1 ? 's' : '')
      } else {
        return 'maintenant'
      }
    }
  }
}
</script>
