<template>
  <div class="flex flex-col gap-2">
    <h3 class="font-content-bold text-xl">
      Taux de disponibilité par grossiste
    </h3>
    <line-chart
      v-if="data.length"
      :data="chartData"
      :options="options"
    />
    <div v-else>
      <p>Nous n'avons trouvé aucune agence.</p>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import LineChart from '@/renderer/app/core/components/charts/LineChart.vue'

export default {
  name: 'AvailabilityRateByWholesalerLineChart',
  components: { LineChart },
  props: {
    data: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    /**
     * The chart data.
     *
     * @return {{datasets: *, labels: ([]|*)}}
     */
    chartData () {
      return {
        labels: this.labels,
        datasets: this.datasets
      }
    },
    /**
     * The datasets.
     *
     * @return {{data: *, label: *}[]}
     */
    datasets () {
      return this.data.map((datum) => {
        return {
          label: datum.wholesaler_name,
          data: datum.histogram.map(histo => {
            return histo.rate * 100
          })
        }
      })
    },
    /**
     * The labels.
     *
     * @return {*|*[]}
     */
    labels () {
      if (!this.data.length) {
        return []
      }

      return this.data[0].histogram.map(histo => {
        return dayjs(histo.date).format('MM/YYYY')
      })
    },
    /**
     * The chart options.
     *
     * @return {Object}
     */
    options () {
      return {
        scales: {
          y: {
            ticks: {
              callback: function (value) {
                return value + '%'
              }
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                return context.dataset.label + ': ' + context.parsed.y.toFixed(2) + '%'
              }
            }
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    }
  }
}
</script>
