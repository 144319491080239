<template>
  <div class="relative flex flex-col w-52">
    <div class="absolute -top-2 -right-1">
      <img
        :src="require('@/assets/images/icons/cart-bubble.svg')"
      >
    </div>
    <div class="bg-primary bg-opacity-50 rounded-t-xl py-2 px-4">
      <p class="text-xs pt-1">
        {{ formatHelper.readableDate(order.created_at) }}
      </p>
    </div>
    <div class="p-5">
      <p class="text-sm font-bold">
        {{
          order.customer.name ||
            (order.customer.information?.first_name + " " + order.customer.information?.last_name)
        }}
      </p>
      <p class="text-sm">
        {{ !order.summary?.in_stock ? "🚨 " : "" }}{{ order.product_count || order.summary?.product_count }}
        {{ " produit" | pluralize(order.product_count || order.summary?.product_count) }}
        {{ order.content_type !== "cart" ? ", avec ordonnance" : "" }}
      </p>
    </div>
  </div>
</template>

<script>

import formatHelper from '../../../helpers/formatHelper'
import { pluralize } from '../../core/filters/Pluralize'

/**
 * Component that display dynamic actions that the user can interact with.
 */
export default {
  name: 'HomeLiveOrder',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    formatHelper () {
      return formatHelper
    }
  },
  methods: { pluralize }
}
</script>
