<template>
  <VExpansionPanel>
    <VExpansionPanelHeader color="bg-primary-lightest">
      <div class="flex flex-row align-center">
        <img
          class="h-16"
          src="@/assets/images/logo-GDPR.png"
          alt="GDRP"
        >
        <span class="font-weight-bold mr-10">RGPD</span>
        <span>Voir les informations</span>
      </div>
    </VExpansionPanelHeader>
    <VExpansionPanelContent color="bg-primary-lightest">
      <div class="flex flex-1 flex-col justify-center">
        <p class="font-content text-sm">
          Dans le cadre du RGPD, vous pouvez informer le patient que ces données sont traitées pour
          les finalités de santé publique pour améliorer sa prise en charge dans sa pathologie.
        </p>
        <p
          class="underline font-content text-sm cursor-pointer mt-1 self-start"
          @click="show"
        >
          Imprimer l'information patient
        </p>
      </div>
    </VExpansionPanelContent>
  </VExpansionPanel>
</template>

<script>

export default {
  name: 'SupportProgramGDPRMention',
  methods: {
    show () {
      window.open('https://www.apodis-sante.com/Apodis-DataPolicy.pdf', '_blank')
    }
  }
}
</script>
