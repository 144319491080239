<template>
  <base-container>
    <div
      class="flex flex-1 flex-col"
    >
      <div class="mb-5">
        <nav
          class="flex border-b-2 border-collapse"
          aria-label="Tabs"
        >
          <router-link
            :to="{ name: 'patient-administration.orders' }"
            active-class="border-primary text-primary font-semibold border-b-2.5"
            class="border-transparent -mb-0.5 text-primary whitespace-nowrap py-2 px-10 font-medium text-sm"
          >
            Click&Collect
          </router-link>
          <router-link
            :to="{ name: 'patient-administration.renewals' }"
            active-class="border-primary text-primary font-semibold border-b-2.5"
            class="border-transparent -mb-0.5 text-primary whitespace-nowrap py-2 px-10 font-medium text-sm"
          >
            Renouvellement
          </router-link>
          <router-link
            :to="{ name: 'patient-administration.certified-patients' }"
            active-class="border-primary text-primary font-semibold border-b-2.5"
            class="border-transparent -mb-0.5 text-primary whitespace-nowrap py-2 px-10 font-medium text-sm"
          >
            Patients certifiés
          </router-link>
        </nav>
      </div>
      <router-view :key="$route.path" />
    </div>
  </base-container>
</template>

<script>
import BaseContainer from '@/renderer/components/base/BaseContainer.vue'

export default {
  name: 'PatientAdministrationScreen',
  components: {
    BaseContainer
  }
}
</script>
