<template>
  <div class="flex flex-col gap-1">
    <p class="italic">
      À nouveau disponible {{ dateLabel }}
    </p>
    <base-card>
      <base-card-body class="flex flex-col gap-4">
        <div
          v-for="(warehouse, index) in Object.keys(availabilities)"
          :key="warehouse"
          class="flex flex-col gap-2"
        >
          <p class="text-primary font-bold">
            {{ warehouse }}
          </p>
          <div
            v-for="(availability, _index) in availabilities[warehouse]"
            :key="_index"
          >
            <p>{{ availability.product.name }}</p>
          </div>
          <hr
            v-if="index !== Object.keys(availabilities).length - 1"
            class="mt-2"
          >
        </div>
      </base-card-body>
    </base-card>
  </div>
</template>
<script>
import dateHelper from '@/renderer/helpers/dateHelper'
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import BaseCardBody from '@/renderer/components/base/BaseCardBody.vue'

export default {
  name: 'ProductAvailableAgainCard',
  components: { BaseCardBody, BaseCard },
  props: {
    availabilities: {
      type: Object,
      required: true
    },
    date: {
      type: String,
      required: true
    }
  },
  computed: {
    /**
     * Check if the date is today.
     *
     * @returns {boolean}
     */
    isToday () {
      return this.date === new Date().toDateString()
    },
    /**
     * Get the label for the date.
     *
     * @returns {string}
     */
    dateLabel () {
      return this.isToday ? 'Aujourd\'hui' : 'le ' + dateHelper.shortFormat(this.date)
    }
  }
}
</script>
