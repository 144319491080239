const DistributorAvailabilityMapService = () => {
  /**
   * Get the marker image based on the warehouse availability.
   *
   * 3 different state :
   *   1. Is available and has members
   *   2. Is available and has no members
   *   3. Is not available
   *
   * @param {Boolean|null} isAvailable
   * @param {Array|null} warehouseMembers
   */
  const getMarkerImage = (isAvailable, warehouseMembers = []) => {
    if (isAvailable === null) {
      return require('@/assets/images/icons/map-markers/availabilities/unreachable.svg')
    }

    if (isAvailable) {
      return warehouseMembers?.length
        ? require('@/assets/images/icons/map-markers/availabilities/available_for_friends.svg')
        : require('@/assets/images/icons/map-markers/availabilities/available.svg')
    }

    return require('@/assets/images/icons/map-markers/availabilities/unavailable.svg')
  }

  return {
    getMarkerImage
  }
}

export default DistributorAvailabilityMapService()
