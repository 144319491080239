<template>
  <div>
    <div class="bg-gray-200 p-1 rounded mb-1">
      <div class="flex flex-1 flex-row text-left">
        <div class="flex flex-1">
          <span>Patients ({{ consumers.length }})</span>
        </div>
        <div class="flex flex-1">
          <span>Produits dans l'armoire</span>
        </div>
        <div class="flex flex-1">
          <span>Produits périmés</span>
        </div>
      </div>
    </div>
    <div
      v-for="(consumer, index) in consumers"
      :key="index"
      class="hover:text-gray-500 hover:bg-gray-100 rounded p-2 cursor-pointer border-b border-gray-100"
      @click="$emit('click', consumer)"
    >
      <div class="flex-1 flex flex-row text-left">
        <div class="flex flex-1">
          <span class="text-sm">{{ consumer.information.name }} </span>
        </div>
        <div class="flex flex-1 text-center">
          <span class="text-sm">{{ consumer?.medicine_cabinet?.item_count }}</span>
        </div>
        <div class="flex flex-1">
          <span class="text-sm">{{ consumer?.medicine_cabinet?.expired_count }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MedicineCabinetConsumersTable',
  props: {
    consumers: {
      type: Array,
      required: true
    }
  }
}
</script>
