<template>
  <div>
    <v-autocomplete
      v-model="select"
      class="shadow healthcenter-search-bar"
      :items="healthcenters"
      append-icon="mdi-magnify"
      :search-input.sync="search"
      item-text="name"
      item-value="id"
      :loading="loading"
      placeholder="Rechercher une pharmacie"
      no-data-text="Aucune pharmacie trouvée"
      solo
      hide-no-data
      hide-details
      persistent-placeholder
      return-object
      @change="emitSelection"
    />
  </div>
</template>

<script>

import { healthcenterSearchResource } from '@/renderer/container'

export default {
  name: 'HealthcenterSearchBar',
  data () {
    return {
      search: null,
      select: null,
      healthcenters: [],
      loading: false,
      timer: null
    }
  },
  watch: {
    search (value) {
      if (this.timer) {
        clearTimeout(this.timer)
      }

      this.timer = setTimeout(() => {
        if (!value || !value.length || value === this.select?.name) {
          return
        }

        this.loading = true
        healthcenterSearchResource()
          .read({ query: value, resource: true })
          .then((healthcenters) => {
            this.healthcenters = healthcenters.data.map(healthcenter => {
              let name = healthcenter.name + ' - ' + healthcenter.cip

              if (healthcenter.address?.formatted?.trim()?.length) {
                name += ' - ' + healthcenter.address.formatted
              }

              return {
                id: healthcenter.id,
                name
              }
            })
          })
          .finally(() => {
            this.loading = false
          })
      }, 300)
    }

  },
  methods: {
    /**
     * Emit the selected product to parent component.
     */
    emitSelection () {
      this.$emit('select', this.select)
    }
  }
}
</script>

<style>
.healthcenter-search-bar.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: rotate(0);
}
</style>
