<template>
  <div class="p-4">
    <span class="text-primary font-bold text-xl">Nouveautés</span>
    <div
      v-for="(release, index) in releases"
      :key="index"
      class="bg-white rounded-lg my-6 p-4"
    >
      <div class="flex flex-row justify-space-between pb-4">
        <span class="font-bold">Version {{ release.fields.version }}</span>
        <span>{{ dateHelper.dayOnlyLongFormat(release.fields.releaseDate) }}</span>
      </div>
      <rich-text-renderer
        :document="release.fields.content"
        :node-renderers="contentfulHelper.renderNodes()"
      />
    </div>
  </div>
</template>

<script>
import { contentfulService } from '@/renderer/container'
import dateHelper from '@/renderer/helpers/dateHelper'
import contentfulHelper from '@/renderer/helpers/contentfulHelper'
import RichTextRenderer from 'contentful-rich-text-vue-renderer'

export default {
  name: 'PatchNotesScreen',
  components: { RichTextRenderer },
  data () {
    return {
      releases: []
    }
  },
  computed: {
    dateHelper () {
      return dateHelper
    },
    contentfulHelper () {
      return contentfulHelper
    }
  },
  mounted () {
    this.getReleases()
  },
  methods: {
    getReleases () {
      contentfulService().releases()
        .then(response => {
          this.releases = response
        })
    }
  }
}

</script>
