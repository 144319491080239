/**
 * The healthcenter user resource.
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Get healthcenter users (can be used to have pending certification users for example)
     *
     * @param {{certification_status?: string}} params Params of the search.
     *
     * @return {Promise}
     */
    index (params) {
      return client.get('healthcenter/users', { params })
        .then(response => response.data.data)
    }
  }
}
