import { render, staticRenderFns } from "./ExperienceButton.vue?vue&type=template&id=6bcfa084&scoped=true&"
import script from "./ExperienceButton.vue?vue&type=script&lang=js&"
export * from "./ExperienceButton.vue?vue&type=script&lang=js&"
import style0 from "./ExperienceButton.vue?vue&type=style&index=0&id=6bcfa084&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bcfa084",
  null
  
)

export default component.exports