import Vue from 'vue'

/**
 * Service for surveys.
 */
const SurveyService = () => {
  /**
   * Start a survey session by opening the survey modal.
   *
   * @param {Number} surveyId The survey id.
   * @param {String} consumerKey The consumer key for whom the instance is.
   * @param {Number} [pathwayStepId] The optional pathway step id attached to the survey.
   * @param {Number} [supportProgramId] The optional support program id attached to the survey.
   * @param {String} [type] The optional type of the survey (default is survey, otherwise it will be survey-bundle)
   * @param {Object} [options] The optional options to pass to the survey.
   *
   * @returns {void}
   */
  const startSurvey = (surveyId, consumerKey, pathwayStepId = null, supportProgramId = null, type = 'survey', options = {}) => {
    Vue.prototype.$survey.start({
      consumerKey,
      surveyId,
      pathwayStepId,
      supportProgramId,
      type,
      ...options
    })
  }

  return {
    startSurvey
  }
}

export default SurveyService()
