<template>
  <VueSlickCarousel v-if="images.length">
    <BaseImage
      v-for="image in images"
      :key="image.id"
      :path="image.path"
      class="w-full h-96"
    />

    <template #prevArrow>
      <div class="text-gray-900 hover:text-gray-500">
        <svg
          fill="none"
          class="h-6 w-6"
          viewBox="0 0 24 24"
          stroke="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-width="2"
            d="M15 19l-7-7 7-7"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </template>

    <template #nextArrow>
      <div class="text-gray-900 hover:text-gray-500">
        <svg
          fill="none"
          class="h-6 w-6"
          viewBox="0 0 24 24"
          stroke="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-width="2"
            d="M9 5l7 7-7 7"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </template>
  </VueSlickCarousel>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

import BaseImage from '@/renderer/components/base/BaseImage.vue'

import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  components: {
    BaseImage,
    VueSlickCarousel
  },

  props: {
    images: {
      type: Array,
      required: true
    }
  }
}
</script>
