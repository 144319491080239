<template>
  <div
    class="flex flex-1 cursor-pointer"
    @click="redirect"
  >
    <div
      :class="item.type === 'order-created'
        ? 'flex bg-orange-light items-center rounded-l-2xl pl-3 pr-1'
        : 'flex bg-white items-center rounded-l-2xl pl-3 pr-1'"
      :style="{ height: containerHeight + 'px'}"
    >
      <div class="mr-3 flex-shrink-0">
        <img
          class="h-10 w-10"
          src="@/assets/images/logos/apodis.svg"
          alt="Logo Apodis"
        >
      </div>
      <div
        class="flex flex-col"
      >
        <div class="text-primary text-lg font-black break-all line-clamp-1">
          {{ item.title }}
        </div>
        <div class="text-sm font-bold text-gray-800 break-words line-clamp-2">
          {{ item.description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { LiveItemType } from '@/renderer/enum'

export const BROADCAST_NOTIFICATION_HEIGHT = 100

export default {
  name: 'NotificationBroadcast',
  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      containerHeight: BROADCAST_NOTIFICATION_HEIGHT
    }
  },

  methods: {
    redirect () {
      if (this.item.type && this.item.type === LiveItemType.ORDER_CREATED) {
        window.ipcRenderer.send(
          'navigate',
          `/notification/history?orderId=${this.item.resource_id}`,
          true
        )
      } else {
        window.ipcRenderer.send(
          'navigate',
          '/notification/history',
          true
        )
      }
    }
  }
}
</script>
