<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 216.115 30.387"
  >
    <path
      id="Tracé_1821"
      data-name="Tracé 1821"
      class="fill-current"
      d="M5390.241,2019.3s-18.1-6.968-42.068,11.49-68.319-2-80.439,5.125c-18.659,10.963-93.023,2.4-93.607,12.482h216.115v-29.1"
      transform="translate(-5174.126 -2018.006)"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'pathocurve'
})
</script>
