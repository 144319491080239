<template>
  <LineChartComponent
    :chart-data="chartData"
    :chart-options="chartOptions"
    :chart-style="chartStyles"
  />
</template>

<script>
import { Line as LineChartComponent } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement
} from 'chart.js'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../../../../../tailwind.config'

ChartJS.register(Title, Tooltip, Legend, CategoryScale, LinearScale, LineElement, PointElement)

const { theme } = resolveConfig(tailwindConfig)

export default {
  name: 'LineChart',
  components: {
    LineChartComponent
  },
  props: {
    /**
     * The data to display.
     *
     * @type {Object}
     */
    data: {
      type: Object,
      required: false,
      default: () => ({
        labels: [],
        datasets: []
      })
    },
    /**
     * The options to apply to the chart.
     */
    options: {
      type: Object,
      required: false,
      default: () => {}
    },
    /**
     * The style to apply to the chart.
     */
    chartStyle: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  computed: {
    /**
     * Returns the common options for the chart.
     *
     * @return {{responsive: boolean, maintainAspectRatio: boolean}}
     */
    commonsOptions () {
      return {
        responsive: true,
        maintainAspectRatio: false
      }
    },
    /**
     * Returns the common data options for the chart.
     *
     * @return {{pointBackgroundColor, tension: number, borderColor, borderWidth: number, pointRadius: number}}
     */
    commonsDataOptions () {
      return {
        tension: 0.3,
        borderWidth: 2,
        pointRadius: 2,
        borderColor: theme.colors.primary.DEFAULT,
        pointBackgroundColor: theme.colors.primary.DEFAULT
      }
    },
    /**
     * Returns the data to display in the chart.
     *
     * @return {{labels: Array, datasets: Array}}
     */
    chartData () {
      return {
        labels: this.data.labels,
        datasets: this.data.datasets.map((dataset, index) => {
          const colors = {}

          if (!dataset.borderColor && !dataset.pointBackgroundColor) {
            if (this.data.datasets.length > 1) {
              const color = this.getTheme(index)
              colors.borderColor = color
              colors.pointBackgroundColor = color
            } else {
              colors.borderColor = theme.colors.primary.DEFAULT
              colors.pointBackgroundColor = theme.colors.primary.DEFAULT
            }
          }

          return {
            ...this.commonsDataOptions,
            ...dataset,
            ...colors
          }
        })
      }
    },
    /**
     * Returns the options to apply to the chart.
     *
     * @return {Object}
     */
    chartOptions () {
      return {
        ...this.commonsOptions,
        ...this.options
      }
    },
    /**
     * Returns the style to apply to the chart.
     *
     * @return {Object}
     */
    chartStyles () {
      return {
        height: '500px',
        position: 'relative',
        ...this.chartStyle
      }
    }
  },
  methods: {
    /**
     * Get a theme color from the index.
     *
     * @param index
     * @return {string}
     */
    getTheme (index) {
      const colors = [
        '#41b8a6',
        '#E46651',
        '#00D8FF',
        '#DD1B16',
        '#7741b8',
        '#e451d0',
        '#457031',
        '#1637dd',
        '#c9a779',
        '#3e80c2',
        '#621a1a',
        '#62ce3e'
      ]

      return colors[index % colors.length]
    }
  }
}
</script>
