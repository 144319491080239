import jwtDecode from 'jwt-decode'

/**
 * The authentication service.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @param {Object} storageService - The injected storage service.
 * @returns {Object} The public object.
 */
export default function (client, storageService) {
  return {
    /**
     * Authenticated getter.
     * @returns {boolean}
     */
    get authenticated () {
      return !!storageService().read('token')
    },

    /**
     * Read user from jwt.
     * @returns {object}
     */
    get user () {
      const token = storageService().read('token')
      const decoded = jwtDecode(token)
      return decoded && decoded.user
    },

    /**
     * Read access from jwt.
     * @returns {object}
     */
    get access () {
      const token = storageService().read('token')
      const decoded = jwtDecode(token)
      return decoded && decoded.access
    },

    /**
     * Read authorizations from jwt.
     * @returns {object}
     */
    get authorizations () {
      const token = storageService().read('token')
      const decoded = jwtDecode(token)
      return decoded && decoded.authorizations
    },

    /**
     * Login.
     * @param {string} email - The email of the user.
     * @param {string} password - The password of the user.
     * @throws {Error} If environment not healthcenter.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    login (email, password) {
      return client
        .post('/authenticate', {}, {
          params: {
            include: 'currentAccess.organization'
          },

          auth: {
            username: email,
            password: password
          }
        })
        .then(response => {
          const organization = response.data
            .user
            .currentAccess
            .organization

          if (organization.morphable_type !== 'healthcenter') {
            throw new Error('Sélectionnez un environnement « pharmacie » sur Apodis')
          }

          if (response.data.user.currentAccess.rankId !== 1 && response.data.user.currentAccess.rankId !== 2) {
            return null
          } else {
            storageService().store('token', response.data.token)
          }

          return organization
        })
    },

    /**
     * Logout.
     * @returns {void}
     */
    logout () {
      storageService().destroy('token')
    },

    forgotPassword (email) {
      return client
        .post('/users/forgotPassword', { email })
        .then(response => {
          return response.data
        })
    }
  }
}
