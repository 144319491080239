<template>
  <div class="w-full">
    <div class="flex flex-col md:flex-row">
      <div class="w-full">
        <div class="flex flex-1 flex-row justify-between p-5">
          <markdown
            :content="description"
            class="text-right"
            inherit-styles
          />
        </div>
        <div class="flex flex-row space-x-4 p-4">
          <PathwayDocumentCard
            v-for="document in step.steppable.documents"
            :key="document.id"
            :consumer-key="consumerKey"
            :document="document"
          />
        </div>
        <div class="flex flex-row justify-end p-5">
          <BaseButton
            :primary="true"
            class="text-xs font-semibold"
            @click="$emit('finish')"
          >
            Document remis
          </BaseButton>
        </div>
      </div>
    </div>
    <PathwayStepInsert
      v-if="active"
      :step="step"
    />
  </div>
</template>

<script>
import Markdown from '@/renderer/app/core/components/markdown/Markdown.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import PathwayStepInsert from '@/renderer/app/pathway/components/PathwayStepInsert.vue'
import PathwayDocumentCard from '@/renderer/app/pathway/components/PathwayDocumentCard.vue'

export default {
  name: 'PathwayOnboardingStep',
  components: { PathwayDocumentCard, PathwayStepInsert, BaseButton, Markdown },
  props: {
    step: {
      type: Object,
      required: true
    },
    consumerKey: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    description () {
      return this.step.resolved.description
    },
    action () {
      return this.step.resolved.action
    }
  }
}
</script>
