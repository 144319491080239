import { BLOCKS } from '@contentful/rich-text-types'

const customEmbeddedAsset = (node, key, h) => {
  if (node.data.target.fields.file.contentType.startsWith('image')) {
    return (<img alt={node.data.target.fields.title} src={'https:' + node.data.target.fields.file.url}
      style="max-width: 70%; max-height: 500px; margin-left:auto; margin-right:auto; margin-bottom: 20px; margin-top: 20px;"/>)
  } else if (node.data.target.fields.file.contentType.startsWith('video')) {
    return (
      <video controls style="max-width: 50%; max-height: 400px; margin-left:auto; margin-right:auto;">
        <source src={'https:' + node.data.target.fields.file.url} type="video/mp4"/>
      </video>)
  }
  return (<iframe style="width: 0; min-width: 100% !important;" src={'https:' + node.data.target.fields.file.url}/>)
}

const customEmbeddedEntry = (node, key, h) => {
  if (node.data.target.fields.content.nodeType === 'document') {
    return (
      <div>
        <a style="width: 100% !important;"
          href={location.protocol + '//' + location.host + '/guide/' + node.data.target.sys.id}>{node.data.target.fields.title}</a>
      </div>
    )
  }
  return (<p>undefined asset</p>)
}

const customHeading6 = (node, key, h) => {
  return <span class="text-primary font-bold">{node.content[0].value}</span>
}

export default {
  renderNodes () {
    return {
      [BLOCKS.EMBEDDED_ENTRY]: customEmbeddedEntry,
      [BLOCKS.EMBEDDED_ASSET]: customEmbeddedAsset,
      [BLOCKS.HEADING_6]: customHeading6
    }
  }
}
