<template>
  <div
    v-if="!editable"
    class="flex justify-center"
  >
    <p class="p-4 rounded-lg bg-primary-lightest">
      {{ !!value.length ? value[0] : "" }}
    </p>
  </div>
  <div v-else>
    <textarea
      class="w-full p-4 rounded-lg bg-primary-lightest"
      :value="value"
      @change="handleChange"
    />
  </div>
</template>

<script>
export default {
  name: 'SurveyBuiltInput',
  props: {
    question: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array],
      default: null
    }
  },
  methods: {
    handleChange (event) {
      this.$emit('change', event.target.value)
    }
  }
}
</script>
