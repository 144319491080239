/**
 * The user resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Get user by base64 mail.
     * @param {string} base64mail - The bas64 of the user email.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    getByInvitationHash (invitationHash) {
      return client.get(`/guests/${invitationHash}`)
        .then(response => response.data)
    },

    /**
     * Register user from invitation.
     * @param {object} user - The user to register.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    registerFromInvitation (user) {
      return client.post('/users/register', user)
        .then(response => response.data)
    },

    /**
     * Get user mobiles.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    getUserMobiles () {
      return client.get('/users/mobiles')
        .then(response => response.data)
    },

    /**
     * Verify forgot password code.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    verifyForgotPasswordCode (code) {
      return client.get(`/users/confirmForgotPassword/${code}`)
        .then(response => response.data)
    },

    /**
     * Change forgot password.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    changeForgotPassword (code, password) {
      return client.put('/users/changeForgotPassword', { code, password })
        .then(response => response.data)
    }
  }
}
