<template>
  <div class="flex flex-1 flex-col">
    <h1 class="font-semibold text-md">
      {{ label }}
    </h1>
    <p class="text-sm">
      {{ value || '_' }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'CustomerInformationBlock',
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: false,
      default: '_'
    }
  }
}
</script>
