<template>
  <base-container class="p-6 flex flex-col gap-3">
    <h1 class="text-xl text-primary font-bold">
      Vérifiez la disponibilité d'un produit
    </h1>
    <product-search-bar />

    <div class="flex flex-col md:flex-row gap-8">
      <healthcenter-warehouses-card class="flex-1" />
      <healthcenter-group-card class="flex-1" />
    </div>
  </base-container>
</template>

<script>
import BaseContainer from '@/renderer/components/base/BaseContainer.vue'
import ProductSearchBar from '@/renderer/app/disruption-logistics/components/product-searchbar/ProductSearchBar.vue'
import HealthcenterWarehousesCard
from '@/renderer/app/disruption-logistics/components/home/HealthcenterWarehousesCard.vue'
import HealthcenterGroupCard from '@/renderer/app/disruption-logistics/components/home/HealthcenterGroupCard.vue'

export default {
  name: 'AvailabilitiesPage',
  components: { HealthcenterGroupCard, HealthcenterWarehousesCard, ProductSearchBar, BaseContainer }
}
</script>
