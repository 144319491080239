<template>
  <div>
    <lottie-animation
      v-if="isLoading"
      :height="150"
      style="margin: 0"
      path="animations/fetching.json"
    />
    <div class="w-full flex flex-row">
      <transition
        name="slide-fade"
        class="w-full flex flex-row"
      >
        <div
          v-if="actions?.length && !isLoading"
          class="w-full flex p-4 bg-orange-light overflow-x-scroll"
        >
          <div>
            <p class="text-lg">
              Commandes Click & Collect ({{ actions.length }})
            </p>
            <transition-group
              name="list"
              class="flex flex-row"
            >
              <div
                v-for="action in actions"
                :key="action.data.id"
                class="list-action bg-white rounded-xl mx-4 mt-4 shadow-lg cursor-pointer"
                @click="handleAction(action.data)"
              >
                <home-live-order :order="action.data" />
              </div>
            </transition-group>
          </div>
        </div>
      </transition>
    </div>
    <action-modal
      v-if="processAction"
      :process-action="processAction"
      :order-id="processAction.id"
      :opened="opened"
      @closeModal="closeModal"
      @show="show"
    />
  </div>
</template>

<script>
import HomeLiveOrder from '@/renderer/app/home/components/HomeLiveOrder.vue'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import ActionModal from '@/renderer/app/order/components/ActionModal.vue'

/**
 * Component that display dynamic actions that the user can interact with.
 */
export default {
  name: 'HomeLiveActions',
  components: {
    ActionModal,
    HomeLiveOrder,
    LottieAnimation
  },
  data () {
    return {
      isLoading: true,
      opened: false,
      processAction: null
    }
  },
  computed: {
    actions () {
      return this.$store.getters['live/items']()
    }
  },
  mounted () {
    this.loadActions()
      .finally(() => {
        this.isLoading = false
      })
  },
  methods: {
    /**
     * Load live actions. Only orders for now.
     */
    loadActions () {
      return this.$store.dispatch('live/loadItems')
    },
    /**
     * Handle when an action card has been pressed by the user.
     */
    handleAction (action) {
      this.processAction = action
      this.opened = true
    },
    /**
     * Close modal.
     */
    closeModal () {
      this.processAction = null
      this.opened = false
    },
    /**
     * Show customer.
     *
     * @param customer
     */
    show (customer) {
      this.$emit('onPatientPress', customer)
      this.closeModal()
    }
  }
}
</script>

<style>
/* Slide fade animation. */
.slide-fade-enter-active {
  transition: all 1s ease;
}

.slide-fade-leave-active {
  transition: height 1.5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

/* List animation. */
.list-action {
  transition: all 1s;
  display: inline-block;
  margin-right: 10px;
}

.list-enter, .list-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}
</style>
