/**
 * The document resource.
 *
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Get pathway document file.
     *
     * @param {number} id The pathway document id.
     * @param {string} consumerKey The consumer key to fill the document with patient details.
     *
     * @returns {Promise<Blob>} Promise object represents the HTTP request.
     */
    document (id, consumerKey) {
      return client.get(`/pathway-documents/${id}/document`, {
        params: { consumer_key: consumerKey },
        responseType: 'blob'
      })
        .then(response => response.data)
    },

    /**
     * Get the pathway document thumbnail.
     *
     * @param {number} id The pathway document id.
     *
     * @returns {Promise<Blob>} Promise object represents the HTTP request.
     */
    thumbnail (id) {
      return client.get(`/pathway-documents/${id}/thumbnail`, {
        responseType: 'blob'
      })
        .then(response => response.data)
    }
  }
}
