/**
 * The notification service.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @returns {Object} The public object.
 */
export default function () {
  return {
    /**
     * Create a notification.
     * @param {string} title - The title of the notification.
     * @param {string} body - The body of the notification.
     * @returns {Object} The notification instance.
     */
    create (title, body) {
      return new Notification(title, { body })
    },

    /**
     * Display the notification window.
     *
     * @param {number} height - The window height
     *
     * @returns {void}
     */
    showNotification (height = null) {
      window.ipcRenderer.send('show-notification', {
        height
      })
    },

    /**
     * Get the vertical margin from settings and display size.
     * @param {number} height - The window height
     * @param {Electron.Display} display - The display to use for calculation
     */
    verticalPosition (settings, height, display) {
      switch (settings.notification.position) {
        case 'bottom-edge':
          return display.bounds.y + display.size.height - height - 50
        default:
        case 'bottom-right':
          return display.bounds.y + display.size.height * 0.75 - (height / 2)
        case 'middle-right':
          return display.bounds.y + display.size.height * 0.5 - (height / 2)
        case 'top-right':
          return display.bounds.y + display.size.height * 0.25 - (height / 2)
        case 'top-edge':
          return display.bounds.y
      }
    }
  }
}
