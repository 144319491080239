<template>
  <svg
    id="clipboard"
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="38.191"
    viewBox="0 0 30 38.191"
    class="fill-current"
  >
    <path
      id="Tracé_1785"
      d="M68.851-511.769a4.309,4.309,0,0,0-2.731,2.134l-.246.507h-1.5c-2.075,0-2.134.037-2.134,1.463v.776H60.358a12.553,12.553,0,0,0-2.358.112,3.968,3.968,0,0,0-2.918,2.933c-.06.284-.082,4.3-.082,13.776,0,13.119,0,13.4.149,13.865a3.64,3.64,0,0,0,2.784,2.41c.478.1,1.776.112,12.328.1l11.791-.022.5-.194a4.011,4.011,0,0,0,2.075-1.7c.4-.784.373.015.373-14.485,0-14.649.022-13.761-.425-14.7a4.662,4.662,0,0,0-1.679-1.664c-.769-.366-1.194-.425-3.246-.425H77.761v-.776c0-.91-.06-1.112-.388-1.313-.224-.134-.381-.149-1.791-.149H74.037l-.239-.507a4.44,4.44,0,0,0-1.8-1.8A5.206,5.206,0,0,0,68.851-511.769Z"
      transform="translate(-55 511.879)"
      fill="#fff"
    />
    <g id="clipboard-2">
      <path
        id="Tracé_1785-2"
        d="M68.851-511.769a4.309,4.309,0,0,0-2.731,2.134l-.246.507h-1.5c-2.075,0-2.134.037-2.134,1.463v.776H60.358a12.553,12.553,0,0,0-2.358.112,3.968,3.968,0,0,0-2.918,2.933c-.06.284-.082,4.3-.082,13.776,0,13.119,0,13.4.149,13.865a3.64,3.64,0,0,0,2.784,2.41c.478.1,1.776.112,12.328.1l11.791-.022.5-.194a4.011,4.011,0,0,0,2.075-1.7c.4-.784.373.015.373-14.485,0-14.649.022-13.761-.425-14.7a4.662,4.662,0,0,0-1.679-1.664c-.769-.366-1.194-.425-3.246-.425H77.761v-.776c0-.91-.06-1.112-.388-1.313-.224-.134-.381-.149-1.791-.149H74.037l-.239-.507a4.44,4.44,0,0,0-1.8-1.8A5.206,5.206,0,0,0,68.851-511.769Zm2.119,1.821a2.538,2.538,0,0,1,1.4,1.642c.261.91.4.97,2.179.97H75.9v3.657H64.1v-3.649l1.373-.022c1.649-.03,1.687-.045,1.993-.851a2.545,2.545,0,0,1,.813-1.321,2.171,2.171,0,0,1,1.754-.627A2.112,2.112,0,0,1,70.97-509.948Zm-8.716,6.134c.022,1.194.037,1.306.187,1.522a1.062,1.062,0,0,0,.373.321c.157.06,2.082.082,7.276.067l7.06-.022.2-.187c.358-.328.41-.567.41-1.836v-1.164l1.925.03,1.918.022.425.209a2.007,2.007,0,0,1,.978.978l.164.306.022,13.246c.015,10.284,0,13.321-.075,13.582a1.858,1.858,0,0,1-1.075,1.089c-.351.134-.761.142-11.851.164-12.492.022-11.978.037-12.649-.388a1.471,1.471,0,0,1-.672-.9c-.067-.224-.082-3.657-.067-13.545.022-12.769.03-13.254.164-13.507a2.063,2.063,0,0,1,1.47-1.187c.179-.037,1.1-.067,2.06-.075l1.724-.007Z"
        transform="translate(-55 511.879)"
      />
      <path
        id="Tracé_1786"
        d="M120.944-340.793a16.426,16.426,0,0,0-1.339,1.19c-.637.607-1.179,1.107-1.2,1.107a4.242,4.242,0,0,1-.506-.494c-.583-.613-.7-.7-1.024-.7a.681.681,0,0,0-.637.875c.03.179.2.381.9,1.1a8.379,8.379,0,0,0,1.042.952c.351.143.512.036,2.1-1.47,1.446-1.369,1.7-1.649,1.7-1.9a.885.885,0,0,0-.423-.637A.6.6,0,0,0,120.944-340.793Z"
        transform="translate(-111.363 354.038)"
      />
      <path
        id="Tracé_1787"
        d="M232.381-310.9a.72.72,0,0,0-.381.679.731.731,0,0,0,.661.685c.143.018,2.137.03,4.429.018,4.536-.018,4.339-.006,4.554-.363a.681.681,0,0,0-.232-.976l-.2-.137h-4.321A28.817,28.817,0,0,0,232.381-310.9Z"
        transform="translate(-216.95 326.146)"
      />
      <path
        id="Tracé_1788"
        d="M120.727-242.948c-.065.036-.643.571-1.286,1.19l-1.167,1.119-.554-.565c-.577-.589-.815-.726-1.125-.655a.753.753,0,0,0-.518.9,15.556,15.556,0,0,0,1.833,1.97.841.841,0,0,0,.679,0,43.674,43.674,0,0,0,3.179-3.083.741.741,0,0,0-.244-.792A.788.788,0,0,0,120.727-242.948Z"
        transform="translate(-111.21 263.717)"
      />
      <path
        id="Tracé_1789"
        d="M232.461-212.875a.744.744,0,0,0-.024,1.28c.262.137,8.726.137,9.012,0a.715.715,0,0,0,.3-.982c-.19-.369-.012-.357-4.8-.351C234.55-212.928,232.526-212.9,232.461-212.875Z"
        transform="translate(-217.029 235.463)"
      />
      <path
        id="Tracé_1790"
        d="M120.944-145.773a14.717,14.717,0,0,0-1.339,1.185c-.637.607-1.179,1.107-1.2,1.107a5.58,5.58,0,0,1-.506-.494c-.6-.619-.708-.7-1.024-.7a.681.681,0,0,0-.637.875c.03.179.2.381.9,1.1a8.375,8.375,0,0,0,1.042.952c.357.149.512.036,2.1-1.47,1.446-1.369,1.7-1.649,1.7-1.9a.885.885,0,0,0-.423-.637A.581.581,0,0,0,120.944-145.773Z"
        transform="translate(-111.363 175.37)"
      />
      <path
        id="Tracé_1791"
        d="M232.37-115.94a.86.86,0,0,0-.28.238.7.7,0,0,0,.363,1.131c.333.089,8.488.077,8.762-.018a.826.826,0,0,0,.339-.274.681.681,0,0,0-.214-1l-.2-.137-4.321.006C234.441-115.994,232.442-115.97,232.37-115.94Z"
        transform="translate(-216.88 145.85)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SurveyIcon'
}
</script>

<style scoped>

</style>
