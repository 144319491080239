<template>
  <div class="flex flex-col items-center z-100">
    <div class="w-full flex flex-col items-center mb-2">
      <div class="w-full">
        <div class="flex flex-col items-center relative">
          <div class="w-full">
            <div class="shadow-sm p-1 bg-white flex border border-gray-200 rounded">
              <div class="flex flex-auto flex-wrap" />
              <input
                v-model="search"
                :type="type"
                class="w-full bg-white rounded-md mr-5 p-2"
                :placeholder="placeholder"
                @input="onChange"
                @keyup.down="incrementArrowCounter"
                @keyup.up="decrementArrowCounter"
                @keyup.enter="searchCustomer"
              >
              <span>
                <img
                  class="w-6 mr-4 mt-3"
                  src="@/assets/images/icons/search.svg"
                >
              </span>
            </div>
          </div>
          <div
            v-if="results"
            v-show="isOpen"
            class="absolute shadow bg-white top-full z-100 w-full lef-0 rounded max-h-72 overflow-y-auto"
          >
            <div
              v-if="isLoading"
              class="flex flex-col w-full"
            >
              <div class="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-teal-100">
                <div class="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-teal-100">
                  <div class="w-full items-center flex">
                    Loading results...
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div
                v-for="(result, index) in results"
                :key="index"
                class="grid grid-cols-3 mx-auto text-sm p-2 border-b-1 border-red-500 hover:bg-purple-200"
                :class="{ 'is-active': index === arrowCounter }"
                @click="setResult(result)"
              >
                <div class="pl-2 text-purple-700">
                  {{ result.information.name }}
                </div>
                <div class="text-center">
                  Assuré
                </div>
                <div class="text-center">
                  {{ result.information.birth_date }}
                </div>
                <div class="col-span-3">
                  <div
                    v-for="(beneficiary, indexBeneficiary) in result.beneficiaries"
                    :key="indexBeneficiary"
                  >
                    <span class="ml-5 text-xs">
                      {{ beneficiary.information.name }} - Ayant droit - {{ beneficiary.information.formatted_birth_date }}
                    </span>
                  </div>
                </div>
                <hr class="col-span-3 w-full">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  customerResource
} from '@/renderer/container'

export default {
  props: {
    label: {
      type: String,
      default: null
    },

    type: {
      type: String,
      default: 'text'
    },

    inputClass: {
      type: String,
      default: ''
    },

    placeholder: {
      type: String,
      default: ''
    },

    items: {
      type: Array,
      required: false,
      default: () => []
    },

    isAsync: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      isOpen: false,
      isOpenModal: false,
      results: [],
      search: '',
      isLoading: false,
      arrowCounter: 0,
      customerId: null,
      qrCodeModalOpened: false
    }
  },

  watch: {
    items (value, oldValue) {
      if (value.length !== oldValue.length) {
        this.results = value
        this.isLoading = false
      }
    }
  },

  mounted () {
    document.addEventListener('click', this.closeResultList)
  },

  destroyed () {
    document.removeEventListener('click', this.closeResultList)
  },

  methods: {
    onCloseModal (newValue) {
      this.qrCodeModalOpened = newValue
    },

    onChange () {
      // Let's warn the parent that a change was made
      this.$emit('input', this.search)

      this.customers = customerResource().get()

      // Is the data given by an outside ajax request?
      if (this.isAsync) {
        this.isLoading = true
      } else {
        // Let's search our flat array
        this.filterResults()
        this.isOpen = true
      }
    },

    filterResults () {
      // first uncapitalize all the things
      this.results = this.items.filter(item => {
        return item.information.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      })
    },

    setResult (result) {
      this.$router.push({
        name: 'customer.show',
        params: {
          id: result.id
        }
      })
    },

    incrementArrowCounter (evt) {
      if (this.arrowCounter < this.results.length) {
        ++this.arrowCounter
      }
    },

    decrementArrowCounter () {
      if (this.arrowCounter > 0) {
        --this.arrowCounter
      }
    },

    searchCustomer () {
      this.search = this.results[this.arrowCounter]
      this.isOpen = false
      --this.arrowCounter
    },

    closeResultList (event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false
        --this.arrowCounter
      }
    }
  }
}
</script>
