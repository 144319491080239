export default {

  /**
   * Minimum password length.
   * @returns {number}
   */
  get minLength () {
    return 8
  },

  /**
   * Check password for all requirements.
   *
   * @param {string} password - The password.
   * @return {boolean}
   */
  isValid (password) {
    return this.hasMinimumLength(password) &&
      this.hasNumber(password) &&
      this.hasLowercase(password) &&
      this.hasUppercase(password) &&
      this.hasSpecial(password)
  },

  /**
   * Check password for minimum length.
   *
   * @param {string} password - The password.
   * @return {boolean}
   */
  hasMinimumLength (password) {
    return !!password && (password.length >= this.minLength)
  },

  /**
   * Check password for number.
   *
   * @param {string} password - The password.
   * @return {boolean}
   */
  hasNumber (password) {
    return /\d/.test(password)
  },

  /**
   * Check password for lowercase.
   *
   * @param {string} password - The password.
   * @return {boolean}
   */
  hasLowercase (password) {
    return !!password && /[a-z]/.test(password)
  },

  /**
   * Check password for uppercase.
   *
   * @param {string} password - The password.
   * @return {boolean}
   */
  hasUppercase (password) {
    return /[A-Z]/.test(password)
  },

  /**
   * Check password for special caracters.
   *
   * @param {string} password - The password.
   * @return {boolean}
   */
  hasSpecial (password) {
    return /[^\w]/.test(password)
  }
}
