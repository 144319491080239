import { render, staticRenderFns } from "./HealthcenterGroupAvailabilityMapMarker.vue?vue&type=template&id=2108d48c&scoped=true&"
import script from "./HealthcenterGroupAvailabilityMapMarker.vue?vue&type=script&lang=js&"
export * from "./HealthcenterGroupAvailabilityMapMarker.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2108d48c",
  null
  
)

export default component.exports