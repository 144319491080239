<template>
  <div>
    <label
      v-if="label"
      class="block text-sm font-medium text-gray-700"
    >
      {{ label }}
    </label>

    <div class="mt-1 relative rounded-md shadow-sm">
      <input
        v-if="type !== 'textarea'"
        v-model="model"
        :type="type"
        :class="[ errors.length
          ? 'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'
          : 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
        ]"
      >
      <textarea
        v-else
        v-model="model"
        :class="[ errors.length
          ? 'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'
          : 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
        ]"
      />

      <div
        v-if="errors.length"
        class="absolute inset-y-0 right-0 pr-3 flex items-center valueer-events-none"
      >
        <svg
          aria-hidden="true"
          viewBox="0 0 20 20"
          fill="currentColor"
          class="h-5 w-5 text-red-500"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
          />
        </svg>
      </div>
    </div>

    <p
      v-if="errors.length"
      class="mt-2 text-sm text-red-600"
    >
      {{ errors[0] }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: false,
      default: null
    },

    type: {
      type: String,
      required: true
    },

    value: {
      type: [
        String,
        Number
      ],

      required: true
    },

    errors: {
      type: Array,
      default () {
        return []
      }
    }
  },

  computed: {
    model: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
