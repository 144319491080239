/**
 * The laboratory range product resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Get all laboratory products of a range.
     * @param {number} laboratoryId - The ID of the laboratory.
     * @param {number} rangeId - The ID of the range.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    get (laboratoryId, rangeId) {
      return client
        .get(`/laboratories/${laboratoryId}/ranges/${rangeId}/products/needs`, {
          params: {
            include: 'laboratoryPriceRanges.laboratoryPrice'
          }
        })
        .then(response => response.data.data)
    }
  }
}
