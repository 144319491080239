<template>
  <div class="flex w-full items-center justify-center">
    <div class="flex items-center align-middle justify-center sm:px-6 lg:px-8">
      <div class="max-w-md sm:p-10">
        <img
          class="mx-auto h-auto w-96"
          src="@/assets/images/logos/apodis-text-white.png"
          alt="Workflow"
        >

        <BaseAlert
          v-if="this.$route.query.unknownECPS"
          type=""
          title="Votre compte Pro Santé Connect n'est lié à aucun compte Apodis."
        >
          Veuillez vous connecter avec votre email mot de passe si vous disposez d'un compte, puis nous vous inviterons
          à lier votre e-cps.
          <br><br>
          Si vous n'avez pas encore de compte Apodis, cliquez sur Créer mon compte.
        </BaseAlert>
        <BaseAlert
          v-if="this.$route.query.noAccount && this.$route.query.noPharmacist"
          type=""
          title="Votre compte Pro Santé Connect n'est lié à aucun compte Apodis."
        >
          Rendez-vous sur l’application mobile Apodis Pro afin de créer un compte.
        </BaseAlert>
        <BaseAlert
          v-if="this.$route.query.noPharmacist && !this.$route.query.noAccount"
          type=""
          title="Vous ne pouvez pas utiliser cette application."
        >
          Rendez-vous sur l’application Apodis PRO disponible sur smartphone et tablette
        </BaseAlert>

        <BaseAlert
          v-if="error"
          type="error"
          title="Erreur"
        >
          {{ error }}
        </BaseAlert>

        <BaseAlert
          v-if="info"
          title=""
          class="text-center mb-4"
        >
          {{ info }}
        </BaseAlert>

        <div class="space-y-4 mb-12">
          <div>
            <div class="mb-2 text-s text-white text-center">
              M'identifier avec
            </div>
            <div class="flex flex-col items-center">
              <button
                type="button"
                @click="logWithProSanteConnect"
              >
                <img
                  alt="psc-button"
                  class="w-60"
                  :src="require('@/assets/images/logos/psc-button.svg')"
                >
              </button>
            </div>
            <div class="text-gray-400 text-center my-2">
              ou
            </div>
            <div>
              <div class="mb-4">
                <label
                  for="email-address"
                  class="sr-only"
                >Email</label>
                <input
                  id="email-address"
                  v-model="email"
                  type="email"
                  required
                  class="appearance-none w-full rounded relative block px-3 py-2 h-12
                    placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500
                    focus:border-indigo-500 focus:z-10 sm:text-sm shadow-lg"
                  placeholder="Email"
                  @keyup.enter="keyUpEmail"
                >
              </div>
            </div>
            <div v-if="!forgotPassMode">
              <label
                for="password"
                class="sr-only"
              >Mot de passe</label>
              <input
                id="password"
                v-model="password"
                type="password"
                required
                class="appearance-none w-full rounded relative block px-3 py-2 h-12
                  placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500
                  focus:border-indigo-500 focus:z-10 sm:text-sm shadow-lg mb-1"
                placeholder="Mot de passe"
                @keyup.enter="validate"
              >
            </div>
            <button
              type="button"
              class="text-xs text-gray-200 focus:outline-none"
              @click="switchForgotPassMode"
            >
              {{ forgotPassMode ? 'Se connecter' : 'Mot de passe oublié' }}
            </button>
          </div>

          <div class="flex flex-col items-center">
            <button
              type="button"
              class="group relative flex justify-center mx-auto py-3 px-7 text-xl
                rounded-full bg-secondary text-primary-dark"
              @click="validate"
            >
              {{ forgotPassMode ? 'Réinitialiser mon mot de passe' : 'Me connecter' }}
            </button>
          </div>
        </div>
        <div>
          <p class="text-xl text-white text-center mb-2">
            Nouveau sur Apodis Pro ?
          </p>
          <div class="flex flex-col items-center">
            <router-link
              to="/register"
              tag="button"
              class="justify-center"
            >
              <button
                type="button"
                class="group relative flex mx-auto py-3 px-7 text-xl
                  rounded-full bg-white text-primary"
              >
                Créer un compte
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authenticationService, storageService } from '@/renderer/container'
import electronHelper from '@/main/utils/electronHelper'
import pscHelper, { PSC_STATES } from '@/renderer/helpers/pscHelper'
import BaseAlert from '@/renderer/components/base/BaseAlert.vue'

export default {
  components: {
    BaseAlert
  },

  data () {
    return {
      error: null,
      info: null,
      email: null,
      password: null,
      forgotPassMode: false
    }
  },

  mounted () {
    this.email = process.env.VUE_APP_LOGIN_EMAIL
    this.password = process.env.VUE_APP_LOGIN_PASSWORD

    if (authenticationService().authenticated) {
      this.start()
    }
  },

  methods: {
    validate () {
      if (this.forgotPassMode) {
        this.error = null
        authenticationService()
          .forgotPassword(this.email)
          .then(response => {
            if (response.error === false) {
              this.info = "Un mail de réinitialisation de mot de passe vient d'être envoyé."
            } else {
              this.error = response.message
            }
          })
          .catch(error => {
            this.error = error.response
              ? error.response.data.message
              : error
          })
      } else {
        authenticationService()
          .login(this.email, this.password)
          .then(organization => {
            this.$analytics.logEvent('login', {
              method: 'email',
              email: this.email
            })
            if (organization) {
              this.start()
            } else {
              this.info = 'Pour profiter des services Apodis, veuillez télécharger l’application Apodis Pro ' +
                'disponible sur mobile et tablette, via les stores Android et Apple 🙂'
            }
          })
          .catch(error => {
            if (error.response?.status === 401) {
              this.error = 'Cet email et ce mot de passe ne correspondent à aucun compte. Vérifiez vos identifiants et réessayez.'
            } else {
              this.error = error.response
                ? error.response.data.message
                : error
            }
          })
      }
    },

    keyUpEmail () {
      if (this.forgotPassMode) {
        this.validate()
      } else {
        document.getElementById('password').focus()
      }
    },

    start () {
      this.$router.push({
        name: electronHelper.isElectron() ? 'home' : 'onboarding'
      })

      if (window.ipcRenderer) {
        window.ipcRenderer.send('authenticated', storageService().loadSettings())
      }
    },

    logWithProSanteConnect () {
      if (window.ipcRenderer) {
        window.ipcRenderer.send('psc-auth', PSC_STATES.connect_login)
      } else {
        window.location.href = pscHelper.pscAuthLoginURL()
      }
    },

    switchForgotPassMode () {
      this.forgotPassMode = !this.forgotPassMode
    }
  }
}
</script>
