<template>
  <div>
    <p class="font-bold text-primary text-xl pb-4">
      Diffusez le plan de renouvellement au patient
    </p>
    <div class="border-lightPurple-darkest border rounded-xl py-5 bg-white">
      <div v-if="isRenewable">
        <ul>
          <li>
            <p>
              <span class="font-bold pr-2">Date de fin de validité :</span>
              <span>{{ endDateFormatted }}</span>
            </p>
            <div class="flex flex-col lg:flex-row align-center justify-space-between p-2 pr-4">
              <div class="flex flex-row">
                <span class="pr-2">🔔</span>
                <span>Le patient recevra cette notification</span>
              </div>
              <img
                alt="Expiration"
                :src="require('@/assets/images/expiration-notification.png')"
              >
            </div>
          </li>
          <hr class="mr-4 py-2" />
          <li class="py-2">
            <div class="flex flex-row align-center">
              <span class="font-bold pr-2 min-w-max">Dates de renouvellement :</span>
              <v-chip-group
                active-class="primary--text"
                column
              >
                <v-chip
                  v-for="date in dates"
                  :key="date"
                  color="#DEDCF3"
                >
                  <span>{{ dateHelper.shortFormat(date) }}</span>
                </v-chip>
              </v-chip-group>
            </div>
            <div class="flex flex-col lg:flex-row align-center justify-space-between p-2 pr-4">
              <div class="flex flex-row">
                <span class="pr-2">🔔</span>
                <span>Le patient recevra cette notification 3 jours avant la date de renouvellement</span>
              </div>
              <img
                alt="Renouvellement"
                :src="require('@/assets/images/renewal-notification.png')"
              >
            </div>
          </li>
          <hr class="mr-4 py-2" />
          <li class="py-2">
            <p>
              <span class="font-bold">Plan de renouvellement traité par : </span>
              <span>{{ selectedOperatorLabel }}</span>
            </p>
          </li>
        </ul>
      </div>
      <div v-else>
        <span class="p-4">L'ordonnance sera marqué comme non renouvelable</span>
      </div>
    </div>
    <div
      v-if="isRenewable"
      class="border rounded-lg p-4 mt-4"
      style="background-color: #F3EDE6"
    >
      <p>En cas de stock insuffisant avant un renouvellement, vous recevez également une notification.</p>
    </div>
    <div class="flex flex-row justify-end py-4">
      <base-button
        primary
        @click="$emit('on-publish')"
      >
        Valider et diffuser
      </base-button>
    </div>
  </div>
</template>

<script>

import BaseButton from '@/renderer/components/base/BaseButton.vue'
import dateHelper from '../../../helpers/dateHelper'

export default {
  name: 'PrescriptionRenewalPublish',
  components: { BaseButton },
  props: {
    selectedOperatorLabel: {
      type: String,
      default: null
    },
    dates: {
      type: Array,
      required: true
    },
    endDateFormatted: {
      type: String,
      required: true
    },
    isRenewable: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    dateHelper () {
      return dateHelper
    }
  }
}
</script>
