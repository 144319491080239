<template>
  <div class="flex flex-col gap-2">
    <div
      v-if="data"
      class="border-black rounded-lg p-3"
      style="border-width: 1px"
    >
      <div class="flex gap-12 items-center justify-between">
        <div class="flex-col flex">
          <label class="text-gray-500">
            <span class="font-content-bold text-black">{{ data.total_ordered_quantity }}</span>
            {{ 'boîte' | pluralize(data.total_ordered_quantity) }} {{ 'commandée' | pluralize(data.total_ordered_quantity) }}
          </label>
          <label class="text-gray-500">
            <span class="font-content-bold text-black">{{ data.total_delivered_quantity }}</span>
            {{ 'boîte' | pluralize(data.total_delivered_quantity) }} {{ 'livrée' | pluralize(data.total_delivered_quantity) }}
          </label>
          <label class="text-gray-500">
            <span class="font-content-bold text-black">{{ data.total_missing_quantity }}</span>
            {{ 'boîte' | pluralize(data.total_missing_quantity) }} non {{ 'livrée' | pluralize(data.total_missing_quantity) }}
          </label>
        </div>
        <div class="flex flex-col">
          <label class="font-content-bold text-primary text-3xl">{{ rate | numberFormat(0) }}%</label>
          <label class="text-sm">Taux de disponibilité</label>
        </div>
      </div>
    </div>
    <label
      v-if="label"
      class="self-end"
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'PharmaMlStatisticCard',
  props: {
    data: {
      required: false,
      type: Object,
      default: () => ({})
    },
    label: {
      required: false,
      type: String,
      default: null
    },
    loading: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * Get the rate of the delivered quantity.
     *
     * @return {number}
     */
    rate () {
      const _rate = (this.data.total_delivered_quantity / this.data.total_ordered_quantity) * 100

      return isNaN(_rate) ? 0 : _rate
    }
  }
}
</script>
