<template>
  <div>
    <v-carousel
      v-model="model"
      :hide-delimiters="true"
      :show-arrows="false"
      :height="'auto'"
      class="relative"
    >
      <v-carousel-item
        v-for="(urls, index) in images"
        :key="index"
      >
        <secure-image
          :urls="urls"
          :size="size"
          :ratio="ratio"
        />
      </v-carousel-item>
    </v-carousel>
    <div class="flex justify-between mt-2">
      <button @click="model--">
        <icon :name="'arrow-left'" />
      </button>
      <span class="text-xs">{{ model + 1 }}/{{ images.length }}</span>
      <button @click="model++">
        <icon :name="'arrow-right'" />
      </button>
    </div>
  </div>
</template>

<script>
import SecureImage from '@/renderer/app/core/components/secure-image/SecureImage.vue'
import Icon from '@/renderer/app/core/icon/Icon.vue'

export default {
  name: 'SecureImageSlider',
  components: { Icon, SecureImage },
  props: {
    images: {
      type: Array,
      required: true
    },
    size: {
      type: Number,
      default: 84
    },
    ratio: {
      type: Number
    }
  },
  data () {
    return {
      model: null
    }
  }
}
</script>

<style scoped>

</style>
