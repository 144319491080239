<template>
  <base-card>
    <base-card-body>
      <div class="flex flex-col gap-1">
        <div class="flex justify-between">
          <div class="flex flex-col">
            <label class="font-bold mb-2">{{ healthcenter.name }} ({{ healthcenter.cip }})</label>
            <label v-if="healthcenter.holder?.name">
              {{ healthcenter.holder.name }}
            </label>
            <label
              v-if="healthcenter.holder"
              class="text-sm"
            >{{ healthcenter.holder?.email }}</label>
          </div>
          <div v-if="canDeleteMember && !healthcenterIsGroupOwner">
            <button @click="confirmDelete">
              <icon
                name="trash-alt"
                color="red"
                :size="25"
              />
            </button>
          </div>
          <div v-if="canLeave && !healthcenterIsGroupOwner">
            <button @click="confirmLeave">
              <v-icon
                color="red"
                :size="25"
              >
                mdi-exit-to-app
              </v-icon>
            </button>
          </div>
        </div>
        <div class="flex flex-row justify-between text-sm mt-2">
          <label>
            {{ address ?? '' }}
          </label>
          <label
            v-if="healthcenter.last_lgo_import"
            class="self-end text-gray-500"
          >
            Dernière transmission: {{ healthcenter.last_lgo_import | date('DD/MM/YYYY') }}
          </label>
        </div>
      </div>
    </base-card-body>
  </base-card>
</template>

<script>
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import BaseCardBody from '@/renderer/components/base/BaseCardBody.vue'
import HealthcenterGroupService from '@/renderer/app/disruption-logistics/services/HealthcenterGroupService'
import Icon from '@/renderer/app/core/icon/Icon.vue'
import { disruptionLogisticsApi } from '@/renderer/container'

export default {
  name: 'HealthcenterGroupHealthcenterRow',
  components: { Icon, BaseCardBody, BaseCard },
  props: {
    /** @type {HealthcenterGroupMember} */
    healthcenter: {
      type: Object,
      required: true
    },
    ownerAccessId: {
      type: Number,
      required: true
    },
    groupId: {
      type: Number,
      required: true
    }
  },
  computed: {
    /**
     * Get the healthcenter address.
     *
     * @returns {string|null}
     */
    address () {
      if (this.healthcenter.address) {
        return this.healthcenter.address.formatted
      }

      return null
    },
    /**
     * Check if the current user can delete the member.
     *
     * @returns {boolean}
     */
    canDeleteMember () {
      return HealthcenterGroupService.isOwner(this.ownerAccessId)
    },
    /**
     * Determine if the user can leave the group.
     *
     * @return {boolean}
     */
    canLeave () {
      return HealthcenterGroupService.healthcenterIsAuth(this.healthcenter.id)
    },
    /**
     * Check if the healthcenter is the owner of the group.
     *
     * @returns {boolean}
     */
    healthcenterIsGroupOwner () {
      return this.healthcenter.owners.some(owner => owner.id === this.ownerAccessId)
    }
  },
  methods: {
    /**
     * Confirm the deletion of the member.
     */
    confirmDelete () {
      this.$confirmationModal.show(
        'Suppression d\'un membre',
        'Êtes-vous sûr de vouloir supprimer ce membre ?',
        this.deleteMember
      )
    },
    /**
     * Confirm leaving the group.
     */
    confirmLeave () {
      this.$confirmationModal.show(
        'Quitter le groupe',
        'En quittant le groupe, vous ne pourrez plus accéder aux données partagées par les membres du groupe, et vos données ne seront plus accessibles par les membres du groupe.',
        this.leaveGroup
      )
    },
    /**
     * Delete the member, then emit the member-deleted event.
     *
     * @return {Promise<void>}
     */
    deleteMember () {
      return disruptionLogisticsApi()
        .deleteHealthcenterGroupMember(this.groupId, this.healthcenter.id)
        .then(() => {
          this.$emit('member-deleted', this.healthcenter.id)
        })
    },
    /**
     * Leave the group.
     *
     * @return {Promise<void>}
     */
    leaveGroup () {
      return disruptionLogisticsApi()
        .leaveHealthcenterGroup(this.groupId)
        .then(() => {
          this.$router.replace({ name: 'disruption-logistics.healthcenter-groups' })
        })
    }
  }
}
</script>

<style scoped>

</style>
