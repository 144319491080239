import Vue from 'vue'
import store from '@/renderer/stores/store'
import router from '@/renderer/router'
import App from '@/renderer/app/App.vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import firebase from '@firebase/app'
import 'firebase/firestore'
import 'firebase/analytics'
import electronHelper from '@/main/utils/electronHelper'
import 'tailwindcss/tailwind.css'
import vuetify from './plugins/vuetify'
import SurveyModal from '@/renderer/plugins/survey-modal'
import PrescriptorMapModal from '@/renderer/plugins/prescriptor-map-modal'
import InterviewPlugin from '@/renderer/plugins/interview-instance-modal-plugin'
import { pluralize } from '@/renderer/app/core/filters/Pluralize'
import { numberFormat } from '@/renderer/app/core/filters/NumberFormat'
import { phoneFormat } from '@/renderer/app/core/filters/Phone'
import dateHelper from '@/renderer/helpers/dateHelper'
import ModalPlugin from '@/renderer/app/modal/plugins/modalPlugin'

const firebaseConfig = electronHelper.isElectron() ? {
  apiKey: 'AIzaSyApj47K235ktB37ksfhjQGVFZF77YezSmQ',
  authDomain: 'apodis-connect.firebaseapp.com',
  projectId: 'apodis-connect',
  storageBucket: 'apodis-connect.appspot.com',
  messagingSenderId: '174232536494',
  appId: '1:174232536494:web:160eeec5aacd771438f43f',
  measurementId: 'G-FP0VY0V27Y'
} : {
  apiKey: 'AIzaSyApj47K235ktB37ksfhjQGVFZF77YezSmQ',
  authDomain: 'apodis-connect.firebaseapp.com',
  projectId: 'apodis-connect',
  storageBucket: 'apodis-connect.appspot.com',
  messagingSenderId: '174232536494',
  appId: '1:174232536494:web:ade0f571eb3575e338f43f',
  measurementId: 'G-72STR3S3YY'
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

Vue.config.productionTip = false

// alias
Vue.prototype.$analytics = firebase.analytics()

Vue.use(VueGoogleMaps, { load: { key: 'AIzaSyD9YjvZsx6b11ANy-TE2kB5_oT2BguQ9o8', libraries: 'places' } })
Vue.use(SurveyModal)
Vue.use(PrescriptorMapModal)
Vue.use(InterviewPlugin)
Vue.use(ModalPlugin)
Vue.filter('pluralize', pluralize)
Vue.filter('numberFormat', numberFormat)
Vue.filter('phoneFormat', phoneFormat)
Vue.filter('date', function (value, formatName = null) {
  switch (formatName) {
    case 'short':
      return dateHelper.shortFormat(value)
    case 'dayOnly':
      return dateHelper.dayOnlyLongFormat(value)
    default:
      return dateHelper.mediumFormat(value)
  }
})

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
