<template>
  <div class="flex space-x-4">
    <img
      class="h-14 rounded-full"
      :src="avatar()"
    >
    <div>
      <div
        v-if="customer.information.name"
        class="text-xl flex flex-row"
        :class="active ? 'font-semibold': ''"
      >
        <div>{{ customer.information.name }}</div>
        <button @click="refresh">
          <Icon
            class="pl-2"
            name="sync"
            :size="12"
            color="#FFFFFF"
          />
        </button>
      </div>
      <div class="flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
        <div v-if="customer.information.birth_date">
          <div class="text-xs">
            né le {{ customer.information.formatted_birth_date }}
          </div>
          <div class="text-sm">
            {{ age }} ans
          </div>
        </div>
        <div
          v-else
          class="text-xs"
        >
          Date de naissance non renseignée.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import Icon from '@/renderer/app/core/icon/Icon.vue'

export default {
  name: 'CustomerSummary',
  components: { Icon },
  props: {
    customer: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    age () {
      const birthdate = dayjs(this.customer.information.birth_date)

      return Math.floor(dayjs().diff(birthdate, 'year'))
    }
  },
  methods: {
    avatar () {
      return this.customer.information.gender === 1
        ? require('@/assets/images/icons/user-male.png')
        : require('@/assets/images/icons/user-female.png')
    },
    refresh () {
      window.dispatchEvent(new Event('refresh-customer'))
    }
  }
}
</script>
