<template>
  <div class="p-4">
    <p>{{ step.steppable.name }}</p>
    <div
      class="grid justify-start gap-4 m-4"
    >
      <base-button
        v-for="action in step.steppable.actions"
        :key="action.id"
        primary
      >
        {{ action.name }}
      </base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/renderer/components/base/BaseButton.vue'

export default {
  name: 'PathwayCheckupStep',
  components: { BaseButton },
  props: {
    step: {
      type: Object,
      required: true
    }
  }
}
</script>
