<template>
  <div class="bg-white shadow overflow-hidden rounded-lg mt-5 self-center">
    <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
      <div class="py-3 flex flex-row flex-wrap gap-x-6 bg-gray-50 text-sm px-6">
        <label
          class="font-medium text-gray-500"
          style="min-width: 150px"
        >
          Fonctionnement
        </label>
        <div
          class="flex flex-col flex-1"
          style="flex-basis: fit-content"
        >
          <div
            v-for="objective in loyaltyCard.objectives"
            :key="objective.id"
          >
            {{ objective.reward.teaser }}
          </div>
        </div>
      </div>
      <div class="py-3 flex flex-row gap-x-6 flex-wrap text-sm px-6">
        <label
          class="font-medium text-gray-500"
          style="min-width: 150px"
        >Barème</label>
        <div
          class="mt-1 text-sm text-gray-900 sm:mt-0 flex-1 sm:col-span-2 basis-48"
          style="flex-basis: fit-content"
        >
          <span v-if="loyaltyCard.point">
            {{ loyaltyCard.point.teaser }}
          </span>

          <span v-else>
            Aucun
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loyaltyCard: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
