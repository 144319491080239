/**
 * The shop order resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Get all shop orders.
     * @param {number} laboratoryId - The ID of the laboratory.
     * @param {number} healthcenterId - The ID of the healthcenter.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    get (laboratoryId, healthcenterId) {
      return client.get(`/shop/laboratories/${laboratoryId}/healthcenters/${healthcenterId}/orders`)
        .then(response => response.data.data)
    }
  }
}
