<template>
  <div
    class="flex items-center gap-2"
  >
    <healthcenter-group-icon />
    <div class="flex flex-1">
      <span>
        <span class="font-bold">
          {{ invitation.group.name }}
        </span> invité par {{ invitation.group.owner.healthcenter.name }}<label v-if="address">,
          {{ addressLabel }}
        </label>
      </span>
    </div>
    <div>
      <div
        v-if="!loading"
        class="flex font-bold gap-5"
      >
        <button @click="handleInvitation(false)">
          <span>Refuser</span>
        </button>
        <button
          class="text-primary"
          @click="handleInvitation(true)"
        >
          <span>Accepter</span>
        </button>
      </div>
      <div v-else>
        <base-spinner size="button" />
      </div>
    </div>
  </div>
</template>

<script>
import HealthcenterGroupIcon
from '@/renderer/app/disruption-logistics/components/healthcenter-groups/HealthcenterGroupIcon.vue'
import { disruptionLogisticsApi } from '@/renderer/container'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'

export default {
  name: 'HealthcenterGroupReceivedInvitationRow',
  components: { BaseSpinner, HealthcenterGroupIcon },
  props: {
    invitation: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    /**
     * The address of the healthcenter owner of the invitation group.
     *
     * @returns {null|Object}
     */
    address () {
      return this.invitation.group.owner?.healthcenter?.address
    },
    /**
     * The label of the address.
     *
     * @returns {string}
     */
    addressLabel () {
      return `${this.address.postal_code} ${this.address.city}`
    }
  },
  methods: {
    /**
     * Handle the invitation
     *
     * @param {boolean} accepted - The accepted status
     *
     * @returns {Promise<void>}
     */
    handleInvitation (accepted) {
      this.loading = true
      return disruptionLogisticsApi()
        .handleInvitation(this.invitation.id, accepted)
        .then(() => {
          this.$emit('invitation-handled', this.invitation, accepted)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
