/**
 * The laboratory merchandising plan resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Get all laboratory merchandising plans.
     * @param {number} laboratory - The ID of the laboratory.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    get (laboratoryId) {
      return client
        .get(`/laboratories/${laboratoryId}/merchandising-plans`, {
          params: {
            include: 'files'
          }
        })
        .then(response => response.data.data)
    }
  }
}
