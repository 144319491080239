<template>
  <BaseContainer>
    <BaseFlexSpinner v-if="isLoading" />
    <div
      v-else
    >
      <ProgramHeader
        class="px-5 mb-5"
        :program="program"
      />
      <ProgramDescription
        class="mb-5"
        :program="program"
      />
      <FeatureStatistics
        v-if="program.programmable_type === 'feature'"
        :program="program"
      />
      <BrandOfferDiscount
        v-if="program.programmable_type==='discount'"
        :discount-id="program.programmable_id"
      />
      <SupportProgramDetailStatistics
        v-if="program.programmable_type === 'support'"
        :program="program"
      />
      <LoyaltyCardStatistics
        v-if="program.programmable_type === 'loyalty-card'"
        class="mx-5"
        :programmable-id="program.programmable_id"
      />
    </div>
  </BaseContainer>
</template>

<script>
import BrandOfferDiscount from '@/renderer/components/views/brand/BrandOfferDiscount.vue'
import SupportProgramDetailStatistics
from '@/renderer/app/program/components/support-program/SupportProgramDetailStatistics.vue'
import BaseContainer from '@/renderer/components/base/BaseContainer.vue'
import { programResource } from '@/renderer/container'
import ProgramHeader from '@/renderer/app/program/components/program-header/ProgramHeader.vue'
import ProgramDescription from '@/renderer/app/program/components/program-description/ProgramDescription.vue'
import LoyaltyCardStatistics from '../components/loyalty-card-statistics/LoyaltyCardStatistics.vue'
import BaseFlexSpinner from '@/renderer/components/base/spinner/BaseFlexSpinner.vue'
import FeatureStatistics from '@/renderer/app/program/components/feature-statistics/FeatureStatistics.vue'

export default {
  name: 'ProgramDetailContent',
  components: {
    FeatureStatistics,
    BaseFlexSpinner,
    ProgramDescription,
    ProgramHeader,
    BrandOfferDiscount,
    BaseContainer,
    SupportProgramDetailStatistics,
    LoyaltyCardStatistics
  },
  data () {
    return {
      isLoading: true,
      program: null
    }
  },
  watch: {
    '$route.params.programId': {
      immediate: true,

      handler (value) {
        this.read(value)
      }
    }
  },
  methods: {
    read (id) {
      programResource()
        .read(id)
        .then(program => {
          this.program = program
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
