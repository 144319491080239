<template>
  <div :key="componentKey">
    <div class="px-6 pt-6 pb-3">
      <div class="flex flex-row justify-between items-center">
        <h1>{{ title }}</h1>
        <div>
          <button @click="previous()">
            <img
              v-if="currentSlide > 0 && !allSlidesVisible()"
              class="rotate-180"
              :src="require('@/assets/images/icons/arrow-left.svg')"
            >
          </button>
          <button @click="next()">
            <img
              v-if="hasSlides() && !allSlidesVisible()"
              :src="require('@/assets/images/icons/arrow-right.svg')"
            >
          </button>
        </div>
      </div>
    </div>
    <ul
      v-if="hasSlides()"
      :id="'slider'"
      class="flex flex-row overflow-hidden pl-5 pb-6"
    >
      <li
        v-for="(slide, index) in slides.data"
        :id="'slide-' + index"
        :key="index"
        class="mr-6 py-2 px-1"
      >
        <component
          :is="slides.component"
          v-bind="{[slides.prop]: slide}"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import domHelper from '@/renderer/helpers/domHelper'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'

export default {
  components: { BaseSpinner },
  props: {
    slides: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      currentSlide: 0,
      componentKey: 0
    }
  },
  computed: {
    lastSlide () {
      return this.hasSlides() && this.slides.data.length - 1
    }
  },
  created () {
    window.addEventListener('resize', this.update)
  },
  mounted () {
    this.update()
  },
  methods: {
    next () {
      if (this.allSlidesVisible()) {
        this.currentSlide = 0
        return
      }

      this.currentSlide = this.currentSlide > this.lastSlide ? this.currentSlide + 1 : this.lastSlide
      this.scrollTo(this.currentSlide)
    },
    previous () {
      this.currentSlide = this.currentSlide <= 0 ? 0 : this.currentSlide - 1
      this.scrollTo(this.currentSlide)
    },
    scrollTo (slide) {
      domHelper.scrollIntoView('slide-' + slide, {
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest'
      })
    },
    hasSlides () {
      return !!this.slides && !!this.slides.data && !!this.slides.data.length
    },
    allSlidesVisible () {
      const slider = document.getElementById('slider')
      const lastSlide = document.getElementById('slide-' + this.lastSlide)

      if (!slider || !lastSlide) {
        return false
      }

      return lastSlide.offsetLeft + lastSlide.offsetWidth < slider.offsetLeft + slider.offsetWidth
    },
    update () {
      this.componentKey = this.componentKey > 1000 ? 0 : this.componentKey++
    }
  }
}
</script>

<style scoped>

</style>
