export default {
  computed: {
    branding () {
      const branding = this.$store.state.system.branding

      const textColor = branding.primaryColor
        ? `color: ${branding.primaryColor}`
        : null

      const backgroundColor = branding.primaryColor
        ? `background-color: ${branding.primaryColor}`
        : null

      return {
        textColor,
        backgroundColor
      }
    }
  }
}
