/**
 * The customer QR code resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Read the QR code of a customer.
     *
     * @param {number} customerId - The ID of the customer.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    readQrCode (customerId) {
      return client
        .get(`/customers/${customerId}/certification/qrcode`, {
          responseType: 'blob'
        })
        .then(response => response.data)
    },

    /**
     * Certify a phone number
     *
     * @param {number} customerId - The customer id.
     * @param {string} phone - The phone number.
     *
     * @return {*}
     */
    certifyPhone (customerId, phone) {
      return client
        .post(`/customers/${customerId}/certification`, { data: { phone } })
        .then(response => response.data.data)
    },

    /**
     * Cancel a customer certification
     *
     * @param {number} customerId - The customer id.
     *
     * @return {*}
     */
    cancel (customerId) {
      return client
        .delete(`/customers/${customerId}/certification`)
        .then(response => response.data.data)
    },

    /**
     * Get the certification pdf url.
     *
     * @param {number} customerId - The customer id.
     * @param {string} type - The onboarding type.
     *
     * @return {string}
     */
    pdfUrl (customerId, type) {
      return `${client.defaults.baseURL}/customers/${customerId}/certification/pdf?type=${type}`
    },

    /**
     * Get the certifications for a given customer.
     *
     * @param customerId The customer id.
     * @param status The certification status.
     * One of : 'pending', 'validated', 'pre_certified'
     *
     * @return {Promise<Array>}
     */
    certifications (customerId, status = null) {
      return client
        .get(`/customers/${customerId}/certifications`, { params: { status } })
        .then(response => response.data.data)
    },

    /**
     * Create a certification notification.
     *
     * @param {Number} certificationId - The certification id.
     * @param {Number} messageId - The message template id.
     *
     * @return {Promise<void>}
     */
    createNotification (certificationId, notificationId) {
      return client
        .post(`certifications/${certificationId}/notifications`, { data: { notification_template_id: notificationId } })
    }
  }
}
