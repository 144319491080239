<template>
  <div>
    <v-autocomplete
      v-model="select"
      class="shadow product-search-bar"
      :items="products"
      append-icon="mdi-magnify"
      :search-input.sync="search"
      item-text="name"
      item-value="id"
      :loading="isLoading"
      placeholder="Rechercher un produit"
      no-data-text="Aucun produit trouvé"
      :disabled="disabled"
      solo
      no-filter
      hide-no-data
      hide-details
      persistent-placeholder
      return-object
      @change="emitSelection"
    />
  </div>
</template>

<script>
import { productApi } from '@/renderer/container'

export default {
  name: 'ProductSearchBar',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      search: null,
      select: null,
      products: [],
      localLoading: false,
      timer: null
    }
  },
  computed: {
    isLoading () {
      return this.loading || this.localLoading
    }
  },
  watch: {
    /**
     * Search for a product
     *
     * @param {String|null} value - The search query value
     */
    search (value) {
      if (this.timer) {
        clearTimeout(this.timer)
      }

      this.timer = setTimeout(() => {
        if (!value || !value.length) {
          return
        }

        this.localLoading = true
        productApi()
          .search(value)
          .then((products) => {
            this.products = products
          })
          .finally(() => {
            this.localLoading = false
          })
      }, 300)
    }
  },
  methods: {
    /**
     * Emit the selected product to parent component.
     */
    emitSelection () {
      this.$emit('select', this.select)
    }
  }
}
</script>

<style>
.product-search-bar.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: rotate(0);
}
</style>
