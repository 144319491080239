<template>
  <div class="bg-white shadow-md p-4 rounded-md flex flex-col gap-6">
    <div class="flex gap-2 items-end">
      <span>
        <v-icon
          color="orange"
          size="40"
        >
          mdi-warehouse
        </v-icon>
      </span>
      <p> Agence #{{ rank }}</p>
    </div>
    <div class="flex flex-col">
      <div class="flex flex-col md:flex-row gap-x-3 gap-y-2 md:items-center">
        <label class="text-sm">Grossistes</label>
        <v-select
          v-model="selectedWholesalerId"
          item-text="name"
          item-value="id"
          class="w-full"
          dense
          :items="availableWholesalers"
          placeholder="Sélectionnez un grossiste"
          @change="handleWholesalerSelected"
        />
      </div>
      <div class="flex flex-col md:flex-row gap-x-3 gap-y-2 md:items-center">
        <label class="text-sm">Agences</label>
        <v-select
          v-model="selectedWarehouseId"
          dense
          item-text="name"
          item-value="id"
          :items="availableWarehouses ?? []"
          placeholder="Sélectionnez un entrepôt"
          no-data-text="Veuillez d'abord sélectionner un grossiste"
        />
      </div>
      <div class="flex flex-col md:flex-row gap-x-3 gap-y-2 md:items-center">
        <label class="text-sm">Accès</label>
        <v-select
          v-model="selectedHealthcenterWarehouseId"
          item-text="name"
          item-value="id"
          dense
          :items="availableCredentials ?? []"
          placeholder="Sélectionnez des accès"
          no-data-text="Veuillez d'abord sélectionner un grossiste"
          @change="handleCredentialChange"
        />
      </div>
    </div>
    <v-divider v-if="selectedCredential" />
    <div
      v-if="selectedCredential"
      class="flex flex-col gap-2 text-sm"
    >
      <p>
        <span class="font-bold">URL : </span> {{ selectedCredential.url }}
      </p>
      <p>
        <span class="font-bold">Code grossiste : </span> {{ selectedCredential.wholesaler.id }}
      </p>
      <p>
        <span class="font-bold">Code agence : </span> {{ selectedCredential.warehouseCode }}
      </p>
      <p>
        <span class="font-bold">Identifiant : </span> {{ selectedCredential.healthcenterCode }}
      </p>
      <p>
        <span class="font-bold">Clé : </span> {{ selectedCredential.healthcenterKey }}
      </p>
    </div>
    <div class="flex gap-4 justify-end">
      <div>
        <base-button @click="testCredential">
          <div class="flex gap-2 items-center">
            <span>
              {{
                credentialVerified === null
                  ? 'Tester les accès'
                  : credentialVerified ? 'Accès valides' : 'Accès invalides'
              }}
            </span>
            <v-icon
              v-if="credentialVerified !== null"
              :color="credentialVerified ? 'green': 'red'"
            >
              {{ credentialVerified ? 'mdi-check' : 'mdi-close' }}
            </v-icon>
          </div>
        </base-button>
      </div>
      <base-button
        primary
        class="self-end font-bold"
        :disabled="buttonDisabled"
        @click="save"
      >
        <span v-if="refreshing">
          <base-spinner
            color="white"
            size="button"
          />
        </span>
        <span v-else>Enregistrer</span>
      </base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import { wholesalerApi } from '@/renderer/container'
import ToastService from '@/renderer/services/ToastService'

export default {
  name: 'SelectWholesalerExpansionPanel',
  components: { BaseSpinner, BaseButton },
  props: {
    warehouses: {
      type: Array,
      required: false,
      default: () => []
    },
    healthcenterWarehouses: {
      type: Array,
      required: false,
      default: () => ([])
    },
    healthcenterWarehouse: {
      type: Object,
      required: false,
      default: () => null
    },
    rank: {
      type: Number,
      required: true
    },
    refreshing: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      selectedHealthcenterWarehouseId: this.healthcenterWarehouse?.id,
      selectedWholesalerId: this.healthcenterWarehouse?.wholesaler.id,
      selectedWarehouseId: this.healthcenterWarehouse?.wholesalerWarehouseId,
      credentialVerified: null
    }
  },
  computed: {
    /**
     * Get available wholesalers to display in the selector.
     */
    availableWholesalers () {
      return this.healthcenterWarehouses?.map(warehouse => warehouse.wholesaler) ?? []
    },
    /**
     * Get available warehouses to display in the selector.
     */
    availableWarehouses () {
      return this.warehouses
        .filter(warehouse => warehouse.wholesaler.id === this.selectedWholesalerId)
    },
    /**
     * Get available credentials to display in the selector.
     */
    availableCredentials () {
      if (!this.selectedWholesalerId) {
        return []
      }

      return this.healthcenterWarehouses
        .filter((healthcenterWarehouse) => {
          return healthcenterWarehouse.wholesaler.id === this.selectedWholesalerId
        })
    },
    /**
     * Get the selected credential.
     *
     * @return {Object|null}
     */
    selectedCredential () {
      return this.availableCredentials
        .find(credential => credential.id === this.selectedHealthcenterWarehouseId)
    },
    /**
     * The save button disabled state.
     *
     * @return {boolean}
     */
    buttonDisabled () {
      return !this.selectedHealthcenterWarehouseId || !this.selectedWarehouseId || !this.credentialVerified
    }
  },
  watch: {
    healthcenterWarehouse: {
      handler (newValue) {
        this.selectedHealthcenterWarehouseId = newValue?.id
        this.selectedWholesalerId = newValue?.wholesaler.id
        this.selectedWarehouseId = newValue?.wholesalerWarehouseId
      }
    }
  },
  methods: {
    /**
     * On wholesaler selected, reset the selected warehouse.
     */
    handleWholesalerSelected () {
      this.selectedWarehouseId = null
    },
    handleCredentialChange () {
      this.credentialVerified = null
    },
    /**
     * Emit the save method.
     */
    save () {
      this.$emit('save', {
        healthcenterWarehouseId: this.selectedHealthcenterWarehouseId,
        warehouseId: this.selectedWarehouseId,
        wholesalerId: this.selectedWholesalerId,
        rank: this.rank
      })
    },
    /**
     * Make a HTTP call to test the credentials.
     *
     * @return {Promise<void>|void}
     */
    testCredential () {
      if (!this.selectedCredential?.id || !this.selectedWarehouseId) {
        return
      }

      this.credentialVerified = null

      return wholesalerApi()
        .testCredential(this.selectedCredential.id, this.selectedWarehouseId)
        .then(({ success }) => {
          this.credentialVerified = success

          if (!success) {
            ToastService().error('La configuration de cet accès ne fonctionne pas, veuillez en sélectionner un autre ou contacter le support')
          }
        })
    }
  }
}
</script>

<style scoped>
.v-expansion-panel-header {
  font-size: inherit;
  line-height: inherit;
}
</style>
