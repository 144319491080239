/**
 * The contentful service.
 * @author Alexis Perret <a.perret@apodispharma.com>
 * @returns {Object} The public object.
 */
export default function (contentful) {
  const client = contentful.createClient({
    space: process.env.VUE_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.VUE_APP_CONTENTFUL_API_KEY
  })

  return {
    /**
     * Get the Sante Secure guide ID.
     * @returns {string} The contentful guide ID.
     */
    getSanteSecureGuideId () {
      return '3aPzs5fKxkivUyGv4Tpyg7'
    },
    /**
     * Get the Connect guide ID.
     * @returns {string} The contentful guide ID.
     */
    getConnectGuideId () {
      return '1d3DYAqaWJudelZox9ibJb'
    },
    /**
     * Get the Apodis Pro guide ID.
     * @returns {string} The contentful guide ID.
     */
    getProGuideId () {
      return '11g4LkMes93BEwxt64yA31'
    },
    /**
     * Get guides.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    getGuides () {
      return client.getEntries({
        content_type: 'guide',
        order: 'fields.order'
      }).then(response => {
        return response.items
      })
    },
    /**
     * Get specific guide information.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    getGuide (id) {
      return client.getEntries({
        'sys.id': id,
        include: 10
      })
        .then(response => {
          return response.items[0].fields
        })
    },
    /**
     * Get specific guide information filtered by specific tag.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    getSpecificTagGuide (tag) {
      return client.getEntries({
        content_type: 'guide',
        order: 'fields.order',
        'fields.tags': tag
      }).then(response => {
        return response.items
      })
    },
    /**
     * Get releases.
     *
     * @returns {Promise} Promise object represents the HTTP request.
     */
    releases () {
      return client.getEntries({
        content_type: 'release',
        order: '-fields.releaseDate'
      }).then(response => {
        return response.items
      })
    },
    /**
     * Get latest release.
     *
     * @returns {Promise} Promise object represents the HTTP request.
     */
    latestRelease () {
      return client.getEntries({
        content_type: 'release',
        order: '-fields.releaseDate',
        limit: 1
      }).then(response => {
        return response.items
      })
    }
  }
}
