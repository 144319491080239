/**
 * The drug interaction resource.
 *
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    read (productCips) {
      return client.get('vidal', { params: { ids: productCips } })
        .then(response => response.data.data)
    }
  }
}
