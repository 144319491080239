/**
 * The healthcenter reassort config resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Read a healthcenter reassort config.
     * @param {number} healthcenterId - The ID of the healthcenter.
     * @param {number} laboratoryId - The ID of the laboratory.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    read (healthcenterId, laboratoryId) {
      return client.get(`/healthcenters/${healthcenterId}/reassortConfig/${laboratoryId}`)
        .then(response => response.data.data)
    }
  }
}
