<template>
  <span class="rounded-full inline-flex p-3 bg-red-50 text-red-700 ring-4 ring-white">
    <svg
      width="512"
      height="512"
      viewBox="0 0 510 510"
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 510 510"
      :class="`fill-current h-${size} w-${size}`"
    >
      <path
        d="M497,64h-50V15c0-8.284-6.716-15-15-15H80c-8.284,0-15,6.716-15,15v49H15C6.716,64,0,70.716,0,79v80
      c0,52.383,42.617,95,95,95h36.741c22.112,26.297,48.458,45.631,77.259,55.823V386h-65c-25.916,0-47,21.084-47,47v64
      c0,8.284,6.716,15,15,15h288c8.284,0,15-6.716,15-15v-64c0-25.916-21.084-47-47-47h-65v-76.177
      c28.802-10.192,55.147-29.526,77.259-55.823H417c52.383,0,95-42.617,95-95V79C512,70.716,505.284,64,497,64z M95,30h322v34h-81H95
      V30z M95,224c-35.841,0-65-29.159-65-65V94h36.502c6.068,49.419,21.57,93.926,43.697,130H95z M368,416c9.374,0,17,7.626,17,17v49
      H127v-49c0-9.374,7.626-17,17-17H368z M239,386v-69.061c5.607,0.694,11.276,1.061,17,1.061s11.393-0.367,17-1.061V386H239z
      M363.324,227.089c-1.999,1.528-3.604,3.54-4.631,5.87C329.523,268.494,293.218,288,256,288
      c-36.873,0-72.846-19.153-101.875-54.063c-1.119-3.116-3.231-5.753-5.966-7.525C121.534,191.189,103.562,144.781,96.744,94H336
      h79.256C408.395,145.109,390.233,191.786,363.324,227.089z M482,159c0,35.841-29.159,65-65,65h-15.199
      c22.126-36.074,37.629-80.581,43.697-130H482V159z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      required: true
    }
  }
}
</script>
