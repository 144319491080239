<template>
  <div class="flex flex-wrap flex-row gap-2 justify-center">
    <survey-answer-button
      v-for="answer in question.answers"
      :key="answer.id"
      :style="{'background-color': answer?.color}"
      @click.native="onPress(answer.value)"
    >
      <span
        class="font-content"
        :style="{'color': answer?.color ? 'white' : 'black'}"
      >
        {{ answer.label }}
      </span>
    </survey-answer-button>
  </div>
</template>

<script>
import SurveyAnswerButton from '@/renderer/app/survey/components/SurveyAnswerButton.vue'

export default {
  name: 'SurveyAnswerSingleChoice',
  components: { SurveyAnswerButton },
  props: {
    question: {
      type: Object,
      required: true
    },
    inline: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    /**
     * Emit an onPress event when an answer is pressed.
     *
     * @param {number} id The pressed answer id.
     */
    onPress (id) {
      this.$emit('onPress', id)
    }
  }
}
</script>
