<template>
  <BaseCard class="relative overflow-visible bg-pink-lighter">
    <BaseCardBody style="padding: 14px 14px 18px 14px;">
      <h1 class="text-sm font-content-bold">
        {{ pathway.next_step.title }}
      </h1>
      <div class="text-xs">
        {{ lead }}
      </div>
      <experienceable-pathway-step-button
        v-if="action && isReady"
        :step="pathway.next_step"
        :primary="true"
        button-style="padding: 5px 25px;"
        style="position: absolute; bottom: -15px; right: 25px;"
        @click="() => start(pathway.next_step)"
      >
        <div class="text-xs font-semibold">
          {{ isLocked ? "Répondre à la question" : action }}
        </div>
      </experienceable-pathway-step-button>
    </BaseCardBody>
  </BaseCard>
</template>

<script>
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import BaseCardBody from '@/renderer/components/base/BaseCardBody.vue'
import { pathwayService } from '@/renderer/container'
import ExperienceablePathwayStepButton
from '@/renderer/app/program/components/experienceable-program-button/ExperienceablePathwayStepButton.vue'

export default {
  name: 'PathwayActionCard',
  components: { ExperienceablePathwayStepButton, BaseCardBody, BaseCard },
  props: {
    pathway: {
      type: Object,
      required: true
    },
    consumerKey: {
      type: String,
      required: true
    }
  },
  computed: {
    lead () {
      return this.step.resolved.lead || this.step.lead
    },
    action () {
      return this.step.resolved.action
    },
    step () {
      return this.pathway.next_step
    },
    isLocked () {
      return this.step.type === 'aftercare' &&
        this.step.steppable.verdict?.verdict &&
        !this.step.consumer_step?.steppable?.id
    },
    isReady () {
      if (!this.step) {
        return true
      }

      return this.step.elapsed >= this.step.delay
    }
  },
  methods: {
    start (step) {
      if (!this.isLocked) {
        return pathwayService()
          .start(this.pathway, step, this.consumerKey)
      }
    }
  }
}
</script>
