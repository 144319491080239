<template>
  <div class="flex flex-row bg-gray-200 items-center justify-between px-5 py-2 w-full">
    <div class="flex flex-row items-center">
      <img
        :src="require('@/assets/images/clockwise.png')"
        alt="delay-illustration"
        class="w-16 object-contain"
      >
      <p class="ml-3">
        Vous pouvez ignorer le délai en cliquant sur le bouton suivant
      </p>
    </div>
    <base-button
      class="text-xs font-semibold"
      @click="$emit('onSkipDelay')"
    >
      Passer le délai
    </base-button>
  </div>
</template>

<script>
import BaseButton from '@/renderer/components/base/BaseButton.vue'

export default {
  name: 'PathwayDelayLabel',
  components: { BaseButton }
}
</script>
