<template>
  <div class="flex justify-between text-white gradient py-5 px-3">
    <span class="font-bold text-xl">
      {{ consumerName }} - {{ interviewName }}
    </span>
    <button @click="$emit('close')">
      <span>Fermer</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'InterviewInstanceModalHeader',
  props: {
    /** The consumer name. */
    consumerName: {
      type: String,
      required: true
    },
    /** The interview name. */
    interviewName: {
      type: String,
      required: true
    }
  }
}
</script>
