<template>
  <div class="flex flex-col gap-2 mb-4">
    <base-product-search-bar
      :loading="isLoadingRequest"
      :disabled="isLoadingRequest || !canRequest"
      @select="handleProductSelection"
    />
    <requests-state />
  </div>
</template>

<script>
import DisruptionLogisticsRequestService
from '@/renderer/app/disruption-logistics/services/DisruptionLogisticsRequestService'
import BaseProductSearchBar from '@/renderer/app/product/components/ProductSearchBar.vue'
import { createNamespacedHelpers } from 'vuex'
import RequestsState from '@/renderer/app/disruption-logistics/components/requests-state/RequestsState.vue'

const { mapGetters, mapState } = createNamespacedHelpers('extensions/disruptionLogisticsRequest')

export default {
  name: 'ProductSearchBar',
  components: {
    RequestsState,
    BaseProductSearchBar
  },
  props: {
    replaceRoute: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoadingRequest: false,
      requests: {
        subscribed: null,
        remaining: null
      }
    }
  },
  computed: {
    ...mapGetters(['subscribed']),
    ...mapState(['remainingRequests']),
    /**
     * Determine if the user can request for a product
     *
     * @return {boolean}
     */
    canRequest () {
      return this.subscribed || this.remainingRequests > 0
    }
  },
  methods: {
    /**
     * Handle the product selection
     *
     * @param {Object} product - The selected product
     */
    handleProductSelection (product) {
      if (!product) {
        return
      }

      this.isLoadingRequest = true
      DisruptionLogisticsRequestService.requestDisruptionLogisticsData(
        product,
        this.$router,
        this.replaceRoute
      ).finally(() => {
        this.isLoadingRequest = false
      })
    }
  }
}
</script>

<style scoped>

</style>
