<template>
  <VExpansionPanels flat>
    <SupportProgramGDPRMention />
    <VExpansionPanel
      v-for="mention in mentions"
      :key="mention.id"
    >
      <VExpansionPanelHeader color="bg-primary-lightest">
        <div class="flex flex-row align-center">
          <span class="font-weight-bold mr-10">{{ mention.name }}</span>
          <span>Voir les informations</span>
        </div>
      </VExpansionPanelHeader>
      <VExpansionPanelContent color="bg-primary-lightest">
        <div class="flex flex-1 flex-col justify-center">
          <markdown
            :content="mention.markdown"
            class="text-sm"
            inherit-styles
          />
        </div>
      </VExpansionPanelContent>
    </VExpansionPanel>
  </VExpansionPanels>
</template>

<script>
import Markdown from '../../../core/components/markdown/Markdown.vue'
import SupportProgramGDPRMention from '@/renderer/app/program/components/support-program/SupportProgramGDPRMention.vue'

export default {
  name: 'SupportProgramMention',
  components: {
    SupportProgramGDPRMention,
    Markdown
  },
  props: {
    mentions: {
      type: Array,
      required: true
    }
  }
}
</script>
