/**
 * The groupings resource.
 * @author Mathieu Bolard <m.bolard@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {

    /**
     * Search for groupings.
     * @param {string} name - The name query.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    search (name) {
      return client
        .get('/grouping/search', { params: { name } })
        .then(response => response.data)
    }
  }
}
