<template>
  <BaseContainer>
    <BaseFlexSpinner v-if="isLoading" />
    <div
      v-else
      class="flex flex-1 flex-col"
    >
      <div class="mb-5">
        <nav
          v-if="!!program"
          class="flex border-b-2 border-collapse"
          aria-label="Tabs"
        >
          <router-link
            :to="{ name: 'program.detail.content', params: {programId: program.id} }"
            active-class="border-primary text-primary font-semibold border-b-2.5"
            class="border-transparent -mb-0.5 text-primary whitespace-nowrap py-2 px-10 font-medium text-sm"
          >
            Programme
          </router-link>
          <router-link
            :to="{ name: 'program.detail.consumers', params: {
              programId: program.id,
              program: program,
              programmableId: program.programmable_id,
              programType: program.programmable_type
            } }"
            active-class="border-primary text-primary font-semibold border-b-2.5"
            class="border-transparent -mb-0.5 text-primary whitespace-nowrap py-2 px-10 font-medium text-sm"
          >
            Patients concernés
          </router-link>
          <router-link
            v-if="program.attachments_count > 0"
            :to="{ name: 'program.detail.attachments', params: {programId: program.id} }"
            active-class="border-primary text-primary font-semibold border-b-2.5"
            class="border-transparent -mb-0.5 text-primary whitespace-nowrap py-2 px-10 font-medium text-sm"
          >
            Informations et ressources
          </router-link>
          <router-link
            v-if="program.programmable_type === 'support'"
            :to="{ name: 'program.detail.pathway', params: {supportProgramId: program.programmable_id} }"
            active-class="border-primary text-primary font-semibold border-b-2.5"
            class="border-transparent -mb-0.5 text-primary whitespace-nowrap py-2 px-10 font-medium text-sm"
          >
            Parcours de soin
          </router-link>
          <router-link
            v-if="program.programmable_type === 'loyalty-card'"
            :to="{ name: 'program.detail.animations', params: {loyaltyCardId: program.programmable_id} }"
            active-class="border-primary text-primary font-semibold border-b-2.5"
            class="border-transparent -mb-0.5 text-primary whitespace-nowrap py-2 px-10 font-medium text-sm"
          >
            Animations
          </router-link>
        </nav>
      </div>
      <router-view :key="$route.path" />
    </div>
  </BaseContainer>
</template>

<script>
import { programResource } from '@/renderer/container'
import BaseContainer from '@/renderer/components/base/BaseContainer.vue'
import BaseFlexSpinner from '@/renderer/components/base/spinner/BaseFlexSpinner.vue'

export default {
  name: 'ProgramDetails',
  components: {
    BaseFlexSpinner,
    BaseContainer
  },
  data () {
    return {
      isLoading: true,
      program: null
    }
  },
  watch: {
    '$route.params.programId': {
      immediate: true,

      handler (value) {
        this.read(value)
      }
    }
  },
  methods: {
    read (id) {
      this.isLoading = true

      programResource()
        .read(id)
        .then(program => {
          this.program = program
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    refresh () {
      this.$router.go()
    }
  }
}
</script>
