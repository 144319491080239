<template>
  <BaseCard class="flex flex-1 flex-col p-5 mr-20">
    <div class="font-heading text-3xl font-bold text-primary mb-4 mt-1">
      Par QR code
    </div>
    <ul class="space-y-3">
      <li class="flex flex-row items-center">
        <span
          class="text-5xl text-lightPurple-darkest text-center font-content font-bold w-7"
          :style="{'filter': 'drop-shadow(0 3px 6px rgb(0 0 0 / 0.16))'}"
        >
          1
        </span>
        <span class="text-black font-content font-normal ml-3">
          Votre patient télécharge l'application Apodis sur son mobile.
        </span>
      </li>
      <li class="flex flex-row items-center">
        <span
          class="text-5xl text-lightPurple-darkest text-center font-content font-bold w-7"
          :style="{'filter': 'drop-shadow(0 3px 6px rgb(0 0 0 / 0.16))'}"
        >
          2
        </span>
        <span class="text-black font-content font-normal ml-2">
          Il s'inscrit et suit les instructions.
        </span>
      </li>
      <li class="flex flex-row items-center">
        <span
          class="text-5xl text-lightPurple-darkest text-center font-content font-bold w-7"
          :style="{'filter': 'drop-shadow(0 3px 6px rgb(0 0 0 / 0.16))'}"
        >
          3
        </span>
        <span class="text-black font-content font-normal ml-2">
          Lorsqu'il doit scanner le QR code, présentez lui.
        </span>
      </li>
      <li>
        <CustomerCertificationQrCode :customer="customer" />
      </li>
    </ul>
    <div class="flex flex-row justify-between mt-10">
      <div class="flex flex-col">
        <span class="font-bold">Ou</span>
        <span>Imprimez le Qr code</span>
      </div>
      <BaseButton
        :disabled="downloading"
        @click="print"
      >
        Imprimer
        <img
          :src="require('@/assets/images/icons/print.svg')"
          alt="Print"
          class="ml-2"
          width="25"
          height="25"
        >
      </BaseButton>
    </div>
  </BaseCard>
</template>

<script>
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import CustomerCertificationQrCode from '@/renderer/app/customer-certification/components/CustomerCertificationQrCode.vue'

export default {
  name: 'CustomerOnboardingQrCodeCard',
  components: {
    CustomerCertificationQrCode,
    BaseButton,
    BaseCard
  },
  props: {
    downloading: {
      type: Boolean,
      required: true
    },
    print: {
      type: Function,
      required: true
    },
    customer: {
      type: Object,
      required: true
    }
  }
}
</script>
