<template>
  <div>
    <base-spinner v-if="loading"/>
    <div
      v-else
      class="mx-6"
    >
      <base-card
        v-for="order in orders"
        :key="order.id"
        class="relative overflow-visible my-5 cursor-pointer"
      >
        <base-card-body>
          <div
            class="flex flex-row"
            @click="setOrderId(order.id)"
          >
            <div class="flex justify-between align-center ml-4">
              <div>
                <p>{{ order.customer.information.name }}</p>
                <p>{{ order.content_type === 'cart' ? 'Click & Collect' : 'Ordonnance' }}</p>
                <v-chip
                  class="mt-2"
                  :color="OrderService.statusColor(order)"
                  small
                >
                  <p class="text-sm">
                    {{ OrderService.statusLabel(order) }}
                  </p>
                </v-chip>
              </div>
            </div>
          </div>
        </base-card-body>
      </base-card>
      <div class="text-center mb-4">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="7"
          circle
        />
      </div>
      <action-modal
        :order-id="orderId"
        :opened="showModal"
        @closeModal="closeModal"
        @show="show"
      />
    </div>
  </div>
</template>

<script>
import { orderApi } from '@/renderer/container'
import ActionModal from '@/renderer/app/order/components/ActionModal.vue'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import BaseCardBody from '@/renderer/components/base/BaseCardBody.vue'
import OrderService from '@/renderer/app/order/services/OrderService'

export default {
  name: 'OrdersScreen',
  components: { BaseCardBody, BaseCard, BaseSpinner, ActionModal },
  data () {
    return {
      orders: [],
      loading: false,
      showModal: false,
      orderId: null,
      page: 1,
      totalPage: 1
    }
  },
  computed: {
    OrderService () {
      return OrderService
    }
  },
  watch: {
    page: {
      immediate: true,

      handler () {
        this.loading = true
        this.loadOrders()
          .finally(() => {
            this.loading = false
          })
      }
    }
  },
  methods: {
    /**
     * Load the orders.
     *
     * @return {Promise<unknown>}
     */
    loadOrders () {
      return orderApi().index({ page: this.page })
        .then((response) => {
          this.orders = response.data
          this.totalPage = response.meta.last_page
        })
    },
    /**
     * Close the modal and reset order id.
     */
    closeModal () {
      orderApi().read(this.orderId)
        .then(response => {
          const index = this.orders.findIndex(order => order.id === this.orderId)

          if (index !== -1) {
            this.orders[index] = response
          }
        })
        .finally(() => {
          this.orderId = null
          this.showModal = false
        })
    },
    /**
     * Open the modal and set order id.
     *
     * @param orderId
     */
    setOrderId (orderId) {
      this.orderId = orderId
      this.showModal = true
    },

    /**
     * Show customer page.
     *
     * @param customer
     */
    show (customer) {
      this.$router.push({
        name: 'customer.home',
        params: {
          customerId: customer.id
        }
      })
    }
  }
}
</script>
