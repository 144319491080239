<template>
  <div
    class="flex items-center"
    :style="{width: `${height}px`, height: `${height}px`}"
  >
    <div
      class="logo bg-white flex items-center"
      style="border-radius: 10px"
      :class="{shadow}"
      :style="{width: `${height}px`}"
    >
      <img
        v-if="program.logo"
        class="object-center w-full"
        style="border-radius: 10px"
        :class="{shadow}"
        :src="`${program.logo.url}&h=${size}`"
        :alt="`${program.name}`"
      >
      <div
        v-else
        class="w-full flex justify-center align-center text-3xl font-bold text-gray-600"
        :style="{borderRadius: `10px`, height: `${height}px`}"
      >
        P
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Program logo component.
 */
export default {
  props: {
    /**
     * The program to display the logo.
     */
    program: {
      type: Object,
      required: true
    },
    size: {
      type: Number,
      default: 90
    },
    shadow: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      height: null
    }
  },
  mounted () {
    if (this.program.programmable_type === 'loyalty-card') {
      this.height = this.size * 2
    } else {
      this.height = this.size
    }
  }
}
</script>
