<template>
  <div>
    <p class="font-bold text-primary text-xl pb-4">
      Renseignez le plan
    </p>
    <div class="border-lightPurple-darkest border rounded-xl p-4 bg-white">
      <div class="flex flex-row align-center justify-space-between p-2">
        <p class="pr-4">
          Date de première délivrance
        </p>
        <v-menu
          v-model="menuStartDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          :min-width="350"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDateFormatted"
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              style="max-width: 140px"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="localeStartDate"
            no-title
            @input="menuStartDate = false"
          />
        </v-menu>
      </div>
      <div class="flex flex-row align-center justify-space-between px-2 pb-2">
        <p class="pr-4">
          Fin de validité de l'ordonnance
        </p>
        <v-menu
          v-model="menuEndDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          :min-width="350"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endDateFormatted"
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              style="max-width: 140px"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="localeEndDate"
            no-title
            @input="menuEndDate = false"
          />
        </v-menu>
      </div>
      <div class="py-4 px-2">
        <p>
          Sélectionnez les dates de renouvellement
        </p>
        <div class="flex justify-center pt-2">
          <v-date-picker
            v-model="localeDates"
            :min="localeStartDate"
            :max="localeEndDate"
            no-title
            multiple
            :selected-items-text="selectedDatesLabel"
            :events="[localeStartDate, localeEndDate]"
            color="#9988F0"
            :event-color="date => date === localeStartDate ? 'green' : 'red'"
            locale="fr-fr"
          />
        </div>
        <div class="bg-gray-200 p-4 rounded-b-xl mb-2">
          <p>
            Allez plus vite en choisissant une fréquence !
            <span class="text-sm">Elle sera appliquée du {{ startDateFormatted }} au {{ endDateFormatted }}</span>
          </p>

          <v-chip-group
            active-class="primary--text"
            column
          >
            <v-chip
              v-for="frequency in frequencies"
              :key="frequency"
              color="white"
              @click="quickFill(frequency)"
            >
              <span>{{ frequency }} jours</span>
            </v-chip>
          </v-chip-group>
        </div>
      </div>
    </div>
    <div
      v-if="!!localeDates.length"
      class="border rounded-xl p-4 mt-4"
      style="background-color: #DEDCF3"
    >
      <p>
        L'ordonnance pourra être renouvelée aux dates suivantes:
      </p>
      <v-chip-group
        active-class="primary--text"
        column
      >
        <v-chip
          v-for="date in localeDates"
          :key="date"
          color="white"
          close
          close-icon="mdi-close"
          @click="removeDate(date)"
          @click:close="removeDate(date)"
        >
          <span>{{ dateHelper.shortFormat(date) }}</span>
        </v-chip>
      </v-chip-group>
    </div>
    <div class="flex flex-row justify-end py-4">
      <base-button
        class="mr-4"
        @click="onPress(false)"
      >
        Noter comme non renouvelable
      </base-button>
      <base-button
        :disabled="!localeDates.length"
        primary
        @click="onPress(true)"
      >
        Continuer
      </base-button>
    </div>
  </div>
</template>

<script>

import BaseButton from '@/renderer/components/base/BaseButton.vue'
import dateHelper from '@/renderer/helpers/dateHelper'
import dayjs from 'dayjs'

export default {
  name: 'PrescriptionRenewalPlan',
  components: { BaseButton },
  props: {
    dates: {
      type: Array,
      required: true
    },
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      menuStartDate: false,
      menuEndDate: false,
      frequencies: [7, 28, 30, 84, 90],
      localeStartDate: this.startDate,
      localeEndDate: this.endDate,
      localeDates: this.dates
    }
  },
  computed: {
    dateHelper () {
      return dateHelper
    },
    startDateFormatted () {
      return dateHelper.shortFormat(this.localeStartDate)
    },
    endDateFormatted () {
      return dateHelper.shortFormat(this.localeEndDate)
    },
    selectedDatesLabel () {
      return this.dates.length + ' séléctionnées'
    }
  },
  watch: {
    localeStartDate: {
      handler (value) {
        if (dayjs(value).isAfter(dayjs(this.localeEndDate))) {
          this.localeEndDate = value
        }
        this.startUpdate(value)
      }
    },
    localeEndDate: {
      handler (value) {
        if (dayjs(value).isBefore(dayjs(this.localeStartDate))) {
          this.localeStartDate = value
        }
        this.endUpdate(value)
      }
    },
    dates: {
      handler (value) {
        this.localeDates = value
      }
    }
  },
  methods: {
    quickFill (frequency) {
      this.$emit('quick-fill', frequency)
    },
    removeDate (date) {
      this.$emit('remove-date', date)
    },
    onPress (isRenewable) {
      this.$emit('next-step', isRenewable)
    },
    startUpdate (date) {
      this.$emit('start-update', date)
    },
    endUpdate (date) {
      this.$emit('end-update', date)
    }
  }
}
</script>
