<template>
  <interview-glowing-container class="w-full flex flex-1">
    <div
      v-if="loadingTranscript"
      class="flex flex-col items-center justify-center h-full gap-6 text-center"
    >
      <base-spinner />
      <p>Votre entretien est en cours de transcription ! Ça ne devrait prendre qu'une minute. Merci de patienter !</p>
    </div>
    <div
      v-else-if="transcript"
      class="flex flex-col w-full"
    >
      <interview-transcript-utterances :utterances="transcript.utterances" />
    </div>
    <div v-else>
      Une erreur s'est produite lors de la transcription de l'entretien. Veuillez réessayer plus tard.
    </div>
  </interview-glowing-container>
</template>

<script>
import InterviewContainer from '@/renderer/app/interview/components/instance-modal/InterviewContainer.vue'
import InterviewTranscriptUtterances
from '@/renderer/app/interview/components/instance-modal/InterviewTranscriptUtterances.vue'
import InterviewGlowingContainer
from '@/renderer/app/interview/components/instance-modal/InterviewGlowingContainer.vue'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import Fade from '@/renderer/app/core/components/transitions/Fade.vue'
import { createNamespacedHelpers } from 'vuex'
import BaseButton from '@/renderer/components/base/BaseButton.vue'

const { mapState } = createNamespacedHelpers('interview')

export default {
  name: 'InterviewTranscript',
  components: {
    BaseButton,
    Fade,
    BaseSpinner,
    InterviewGlowingContainer,
    InterviewTranscriptUtterances,
    InterviewContainer
  },
  computed: {
    ...mapState({
      loadingTranscript: state => state.loading.transcript,
      transcript: state => state.transcript
    })
  }
}
</script>
