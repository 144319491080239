<template>
  <base-modal
    v-if="modalVisible"
    :title="title"
    :container-style="{
      'max-height': '80vh',
      'overflow-y': 'auto !important'
    }"
    @close="$emit('close')"
  >
    <div v-if="isLoading">
      <BaseSpinner />
    </div>
    <div v-else>
      <div
        v-if="!hasEnoughCredit"
        class="mt-5"
      >
        <lottie-animation
          :height="300"
          :loop="false"
          path="animations/sad-bank-card.json"
        />
        <div class="flex flex-row items-center">
          <p class="flex-1">
            Vous n'avez pas suffisament de crédit. Veuillez vous rendre sur
            <strong class="text-primary">Apodis Pro</strong>, dans la section <strong class="text-primary">SMS
              Premium</strong> du
            <strong class="text-primary">store</strong>
          </p>
          <img
            class="ml-4"
            :src="require('@/assets/images/sms-premium.png')"
            width="80"
            height="80"
            alt="SMS Premium"
          >
        </div>
      </div>
      <div v-else>
        <customer-certification-notification-selection
          v-if="!notification"
          @selected="handleSelected"
        />
        <customer-certification-notification-confirmation
          v-if="notification"
          :notification="notification"
          @onModify="notification = null"
        />
      </div>
    </div>
    <template
      v-if="!isLoading && hasEnoughCredit"
      v-slot:footer
    >
      <certification-notification-footer
        :balance="credit?.balance"
        :certification="certification"
        :notification="notification"
        :on-send="send"
      />
    </template>
  </base-modal>
</template>

<script>
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import { customerCertificationResource, organizationCreditResource } from '@/renderer/container'
import BaseModal from '@/renderer/components/base/BaseModal.vue'
import CertificationNotificationFooter from '@/renderer/app/customer-certification/components/CertificationNotificationFooter.vue'
import CustomerCertificationNotificationSelection
from '@/renderer/app/customer-certification/screens/CustomerCertificationNotificationSelection.vue'
import CustomerCertificationNotificationConfirmation
from '@/renderer/app/customer-certification/screens/CustomerCertificationNotificationConfirmation.vue'
import ToastService from '@/renderer/services/ToastService'

export default {
  name: 'CustomerCertificationNotificationModal',
  components: {
    CustomerCertificationNotificationConfirmation,
    CustomerCertificationNotificationSelection,
    CertificationNotificationFooter,
    BaseModal,
    BaseSpinner,
    LottieAnimation
  },
  props: {
    modalVisible: {
      type: Boolean,
      required: true
    },
    certification: {
      type: Object,
      required: true
    }
  },
  data: () => {
    return {
      isLoading: false,
      credit: null,
      notification: null
    }
  },
  computed: {
    /**
     * Check if there is enough credit to send a message.
     *
     * @return {boolean}
     */
    hasEnoughCredit () {
      return this.credit?.balance >= 1
    },
    title () {
      return this.notification ? 'Confirmer l\'envoi du SMS' : 'Choisissez le message à envoyer au patient'
    }
  },
  watch: {
    modalVisible (value) {
      if (!value) {
        this.notification = null
      } else {
        this.load()
      }
    }
  },
  methods: {
    /**
     * Handle selected message
     *
     * @param {Object} message - Message
     */
    handleSelected (message) {
      this.notification = message
    },
    /**
     * Load credit.
     */
    load () {
      this.isLoading = true
      organizationCreditResource()
        .getBalance()
        .then((credit) => {
          this.credit = credit
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    /**
     * Send the message.
     *
     * @return {Promise<void>}
     */
    send () {
      if (!this.notification) {
        return Promise.resolve()
      }

      return customerCertificationResource()
        .createNotification(this.certification.id, this.notification.id)
        .then(() => {
          ToastService().success('Le SMS a été envoyé avec succès.')
          window.dispatchEvent(new Event('refresh-customer'))
          this.$emit('close')
        })
    }
  }
}
</script>
