<template>
  <div>
    <button
      type="button"
      :style="branding.backgroundColor"
      class="inline-flex items-center px-2.5 py-1.5 border border-transparent
      text-xs font-medium rounded shadow-sm text-white focus:outline-none
      focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
      @click="slideOpened = true"
    >
      <svg
        viewBox="0 0 20 20"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        class="-ml-1 mr-2 h-5 w-5"
      >
        <path
          d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358
        5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1
        1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5
        1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
        />
      </svg>

      <span
        class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-white"
        :style="branding.textColor"
      >
        {{ totalQuantity }}
      </span>
    </button>

    <div
      v-if="slideOpened"
      class="fixed inset-0 overflow-hidden"
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="true"
    >
      <div class="absolute inset-0 overflow-hidden">
        <div
          class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <div class="w-screen max-w-md">
            <div class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
              <div class="flex-1 py-6 overflow-y-auto px-4 sm:px-6">
                <div class="flex items-start justify-between">
                  <h2
                    id="slide-over-title"
                    class="text-lg font-medium text-gray-900"
                  >
                    Panier
                  </h2>

                  <div class="ml-3 h-7 flex items-center">
                    <button
                      type="button"
                      class="-m-2 p-2 text-gray-400 hover:text-gray-500"
                      @click="slideOpened = false"
                    >
                      <span class="sr-only">
                        Fermer
                      </span>

                      <svg
                        class="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>

                <div class="mt-8">
                  <div class="flow-root">
                    <ul
                      v-if="cart.lines.length"
                      role="list"
                      class="-my-6 divide-y divide-gray-200"
                    >
                      <li
                        v-for="line in cart.lines"
                        :key="line.id"
                        class="py-6 flex"
                      >
                        <div class="flex-shrink-0 w-24 h-24 overflow-hidden ring-4 ring-gray-100 rounded-lg p-2">
                          <img
                            v-if="line.product.fullPhotoPath"
                            :src="line.product.fullPhotoPath"
                            :alt="line.product.name"
                          >

                          <svg
                            v-else
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            class="mx-auto text-gray-100"
                          >
                            <path
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2
                              2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0
                              0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0
                              01-2-2V9z"
                            />

                            <path
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        </div>

                        <div class="ml-4 flex-1 flex flex-col">
                          <div>
                            <div class="flex justify-between text-sm font-medium text-gray-900">
                              <h3>
                                {{ line.product.name }}
                              </h3>

                              <p class="ml-4">
                                {{ line.totalDiscountedWithoutVat }}€
                              </p>
                            </div>

                            <p class="mt-1 text-sm text-gray-500">
                              {{ line.product.code }}
                            </p>
                          </div>

                          <div class="flex-1 flex items-end justify-between text-sm">
                            <p class="text-gray-500">
                              Quantité : {{ line.quantity }}
                            </p>

                            <div class="flex" />
                          </div>
                        </div>
                      </li>
                    </ul>

                    <div
                      v-else
                      class="text-center mt-2"
                    >
                      <svg
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        class="mx-auto h-12 w-12 text-gray-400"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293
                          2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4
                          2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                        />
                      </svg>

                      <h3 class="mt-2 text-sm font-medium text-gray-900">
                        Aucun produit
                      </h3>

                      <p class="mt-1 text-sm text-gray-500">
                        Vous n’avez pas encore ajouter de produits dans votre panier
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-6 flex justify-center text-sm text-center text-gray-500 py-6 px-4 border-t border-gray-200">
                <button
                  type="button"
                  class="font-medium"
                  :style="branding.textColor"
                  @click="empty()"
                >
                  vider mon panier
                </button>
              </div>

              <div class="border-t border-gray-200 py-6 px-4">
                <FrancoProgress
                  v-if="cart"
                  :laboratory-id="$route.params.laboratoryId"
                  class="flex-1 -mt-3"
                  @progress="setProgress"
                />
              </div>

              <div class="border-t border-gray-200 py-6 px-4 sm:px-6">
                <div class="flex justify-between text-base font-medium text-gray-900">
                  <p>
                    Total HT
                  </p>

                  <p>
                    {{ totalWithoutVat.toFixed(2) }}€
                  </p>
                </div>

                <div class="flex justify-between text-base font-medium text-gray-900">
                  <p>
                    Total TVA
                  </p>

                  <p>
                    {{ totalVat.toFixed(2) }}€
                  </p>
                </div>

                <div class="flex justify-between text-base font-medium text-gray-900">
                  <p>Total TTC</p>

                  <p>
                    {{ totalWithVat.toFixed(2) }}€
                  </p>
                </div>

                <div class="mt-6">
                  <a
                    v-if="progress === '100%'"
                    class="flex justify-center items-center px-6 py-3 border
                    border-transparent rounded-md shadow-sm text-base font-medium
                    text-white cursor-pointer"
                    :style="branding.backgroundColor"
                    @click="conditionOpened = true"
                  >
                    {{ buttonLabel }}
                  </a>

                  <span
                    v-else
                    class="flex justify-center items-center px-6 py-3 border
                    border-transparent rounded-md shadow-sm text-base font-medium
                    bg-gray-200 text-gray-400"
                  >
                    {{ buttonLabel }}
                  </span>
                </div>

                <div class="mt-6 flex justify-center text-sm text-center text-gray-500">
                  <p>
                    ou

                    <button
                      type="button"
                      class="font-medium"
                      :style="branding.textColor"
                      @click="slideOpened = false"
                    >
                      continuer les achats

                      <span aria-hidden="true">
                        &rarr;
                      </span>
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <LaboratoryCondition
      v-if="conditionOpened"
      :laboratory="$store.state.branding.laboratory"
      @validate="validate"
      @close="conditionOpened = false"
    />
  </div>
</template>

<script>
import {
  shopCartResource,
  shopOrderResource
} from '@/renderer/container'

import FrancoProgress from '@/renderer/components/brand/FrancoProgress.vue'
import LaboratoryCondition from '@/renderer/components/brand/LaboratoryCondition.vue'

import brandingMixin from '@/renderer/mixins/brandingMixin'

export default {
  components: {
    FrancoProgress,
    LaboratoryCondition
  },

  mixins: [
    brandingMixin
  ],

  data () {
    return {
      cart: null,
      progress: 0,
      totalVat: 0,
      totalWithVat: 0,
      totalQuantity: 0,
      totalWithoutVat: 0,
      slideOpened: false,
      conditionOpened: false,
      buttonLabel: 'Commander'
    }
  },

  watch: {
    '$store.state.quantity': {
      immediate: true,

      handler () {
        shopCartResource()
          .read(
            this.$route.params.laboratoryId,
            this.$store.state.system.environment.entityId
          )
          .then(cart => {
            this.cart = cart

            this.totalQuantity = cart.lines.reduce(
              (total, line) => total + line.quantity
              , 0)

            this.totalWithoutVat = cart.lines.reduce(
              (total, line) => total + line.totalDiscountedWithoutVat
              , 0)

            this.totalWithVat = cart.lines.reduce(
              (total, line) => total + line.totalDiscountedWithVat
              , 0)

            this.totalVat = this.totalWithVat - this.totalWithoutVat
          })
      }
    }
  },

  methods: {
    setProgress (progress) {
      this.progress = progress
    },

    validate () {
      shopCartResource()
        .validate(
          this.$route.params.laboratoryId,
          this.$store.state.system.environment.entityId
        )
        .then(() => {
          this.slideOpened = false
          this.conditionOpened = false
          this.reset()

          shopOrderResource()
            .get(
              this.$route.params.laboratoryId,
              this.$store.state.system.environment.entityId
            )
            .then(orders => {
              const order = orders.shift()

              this.$router.push({
                name: 'brand.order',
                params: { order }
              })
            })
        })
    },

    empty () {
      shopCartResource()
        .empty(
          this.$route.params.laboratoryId,
          this.$store.state.system.environment.entityId
        )
        .then(() => {
          this.slideOpened = false
          this.reset()
        })
    },

    reset () {
      this.$store.commit('setQuantity', 0)
    }
  }
}
</script>
