<template>
  <div
    v-if="data.type !== 'kpi'"
    class="flex flex-1 flex-col"
  >
    <div class="flex flex-1 items-center">
      <div
        class="rounded-full p-3"
        :class="computeIconBg"
      >
        <ShopBagIcon
          v-if="data.type === 'consumer-sale' || data.type === 'sale'"
          class="w-10 icon-white"
        />
        <UserIcon
          v-else-if="data.type === 'consumer' || data.type === 'coverage'"
          class="w-10 icon-white"
        />
      </div>
      <div class="flex flex-col mx-5">
        <span>{{ data.label }}</span>
        <span class="font-bold text-2xl">{{ data.value }}</span>
      </div>
    </div>
    <span class="text-sm text-apodis">{{ data.extra }}</span>
  </div>
  <div
    v-else
    class="flex flex-1 flex-col"
  >
    <div class="flex flex-col flex-1 text-primary">
      <div
        class="text-xs font-weight-bold"
        style="height: 35px; overflow: hidden; text-overflow: ellipsis"
      >
        {{ data.label }}
      </div>
      <div
        class="font-weight-bold flex flex-1 flex-col h-full justify-center align-center items-center"
        style="font-size: 45px;"
      >
        {{ data.value }}
      </div>
    </div>
  </div>
</template>

<script>
import ShopBagIcon from '@/assets/images/icons-vue/shopbag-solid.vue'
import UserIcon from '@/assets/images/icons-vue/user-solid.vue'

export default {
  name: 'ProgramStatisticsCard',
  components: {
    ShopBagIcon,
    UserIcon
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    computeIconBg () {
      if (this.data.type === 'consumer-sale') {
        return 'bg-blue-300'
      } else if (this.data.type === 'sale') {
        return 'bg-pink-300'
      }
      return 'bg-purple-300'
    }
  }
}
</script>
<style>
.icon-white {
  fill: white !important;
}

.bg-apodis {
  background: #3A10EB;
}

.text-apodis {
  color: #3A10EB;
}

.border-apodis {
  border-color: #3A10EB;
}
</style>
