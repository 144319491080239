<template>
  <div class="flex flex-1 flex-col bg-orange-light">
    <img
      class="absolute mt-20 right-0 top-15 -z-50"
      src="@/assets/images/iphone-mockup.png"
      :style="{'transform': 'rotate(-25deg)'}"
      width="370"
      alt="Iphone"
    >
    <div
      v-if="customer"
      class="p-5 z-10"
    >
      <CustomerOnboardingHeader />
      <div class="relative z-2 flex flex-1 flex-col justify-between">
        <div class="flex flex-row justify-between mt-10">
          <CustomerOnboardingQrCodeCard
            v-if="customer"
            :downloading="downloading"
            :customer="customer"
            :print="print"
          />
          <div class="flex-1 flex-col">
            <CustomerOnboardingPhoneCard
              :customer="customer"
              :refresh="refresh"
            />
            <div class="flex flex-row justify-between mt-10 px-5">
              <div class="flex flex-col">
                <span class="font-bold">Ou</span>
                <span>Remettez-lui la fiche explicative</span>
              </div>
              <BaseButton
                :disabled="downloading"
                @click="print"
              >
                Imprimer
                <img
                  :src="require('@/assets/images/icons/print.svg')"
                  alt="Print"
                  class="m-auto"
                  width="25"
                  height="25"
                >
              </BaseButton>
            </div>
          </div>
        </div>
        <CustomerOnboardingFooter />
      </div>
    </div>
  </div>
</template>

<script>

import BaseButton from '@/renderer/components/base/BaseButton.vue'
import { customerCertificationResource, storageService } from '@/renderer/container'
import CustomerOnboardingHeader from '@/renderer/app/customer-certification/components/CustomerOnboardingHeader.vue'
import CustomerOnboardingPhoneCard
from '@/renderer/app/customer-certification/components/CustomerOnboardingPhoneCard.vue'
import CustomerOnboardingQrCodeCard
from '@/renderer/app/customer-certification/components/CustomerOnboardingQrCodeCard.vue'
import CustomerOnboardingFooter from '@/renderer/app/customer-certification/components/CustomerOnboardingFooter.vue'

export default {
  name: 'CustomerCertificationOnboarding',
  components: {
    CustomerOnboardingFooter,
    CustomerOnboardingPhoneCard,
    CustomerOnboardingHeader,
    CustomerOnboardingQrCodeCard,
    BaseButton
  },
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      downloading: false
    }
  },
  methods: {
    /**
     * Cancel the certification.
     */
    cancel () {
      this.$router.go(-1)
    },
    /**
     * Print customer certification.
     */
    print () {
      const customer = this.customer
      this.downloading = true

      if (customer?.id) {
        window.ipcRenderer
          .invoke('print', {
            url: customerCertificationResource().pdfUrl(customer.id, 'qrcode'),
            token: storageService().read('token')
          })
          .finally(() => {
            this.downloading = false
            this.close()
          })
      }
    },
    /**
     * Refresh customer certification.
     *
     * @param {Object} certification
     */
    refresh (certification) {
      this.customer.certification = certification
    }
  }
}
</script>
