export default function (client, hdsClient) {
  return {

    /**
     * Get the activated support program index.
     *
     * @returns {Promise<Array>}
     */
    indexActivatedPrograms () {
      const params = {
        enabled: true
      }

      return client.get('extensions', { params })
        .then(response => response.data.data)
    },

    /**
     * Read a program.
     *
     * @param id - The program id.
     *
     * @return {Promise<Array>}
     */
    read (id) {
      return client.get('programs/' + id)
        .then(response => response.data.data)
    },

    /**
     * Read a program bundle.
     *
     * @param id - The program bundle id.
     *
     * @return {Promise<Array>}
     */
    readBundle (id) {
      return client.get('program-bundles/' + id)
        .then(response => response.data.data)
    },

    /**
     * Get consumers index with group data for a given program.
     *
     * @param id - The program id.
     * @param consumerKey - An optional consumer key to filter.
     * @param withSales - An optional flag to include sales.
     *
     * @return {Promise<Array>}
     */
    consumers (id, consumerKey = null, withSales = false) {
      const params = {
        limit: 5000
      }

      if (consumerKey) {
        params.consumer_key = consumerKey
        params.with_sales = true
      }

      return hdsClient.get(`support-programs/${id}/consumers`, { params })
        .then(response => response.data)
    },

    /**
     * Get purchase history for a given consumer.
     *
     * @param id - The program id.
     * @param consumerKey - The consumer key.
     *
     * @return {Promise<{data, meta: *}>}
     */
    history (id, consumerKey) {
      return this.consumers(id, consumerKey, true)
        .then(response => {
          return {
            data: response.data.length && response.data[0],
            meta: response.meta
          }
        })
    },

    /**
     * Get the support program consumer settings index for a given consumer.
     *
     * @param consumerKey - The consumer key.
     * @param params - The optional params.
     *
     * @return {Promise<Object>}
     */
    consumerSupportProgramSettings (consumerKey, params = {}) {
      return hdsClient.get(`consumers/${consumerKey}/support-program-consumer-settings`, { params })
        .then(response => response.data.data)
    },

    /**
     * Download the secure file behind the given setting id.
     *
     * @param {number} settingId - The setting id.
     *
     * @return {Promise<Blob>}
     */
    downloadProgramSettingFile (settingId) {
      return hdsClient.get(`support-program-consumer-settings/${settingId}/secure_file`, { responseType: 'blob' })
        .then(response => response.data)
    },

    /**
     * Get the consumer settings for a given program.
     *
     * @param {Number} id - The support program id.
     * @param {String} consumerKey - The consumer key.
     *
     * @return {Promise<Object>}
     */
    readSupportProgramConsumerSettings (id, consumerKey) {
      return client.get(`support-programs/${id}/consumers/${consumerKey}/settings`)
        .then(response => response.data.data)
    },

    /**
     * Get all the consumer settings of a given support program.
     *
     * @param {Number} id The support program id.
     * @param {Object} params The optional params.
     *
     * @returns {Promise<Array>}
     */
    supportProgramConsumerSettings (id, params = {}) {
      return hdsClient.get(`support-programs/${id}/consumer-settings`, { params })
        .then(response => response.data.data)
    },

    /**
     * Toggle the consumer disabled settings for the given support program.
     *
     * @param {Number} id - The support program id.
     * @param {String} consumerKey - The consumer key.
     * @return {Promise<Object>}
     */
    toggleConsumerSettings (id, consumerKey) {
      return hdsClient.patch(`support-programs/${id}/consumers/${consumerKey}/settings`)
        .then(response => response.data.data)
    },

    /**
     * Get the product advices for a given consumer and program.
     *
     * @param id - The program id.
     * @param consumerKey - An optional consumer key to filter.
     *
     * @return {Promise<Array>}
     */
    advices (id, consumerKey) {
      return client.get(`support-programs/${id}/advices`, { params: { consumer_key: consumerKey } })
        .then(response => response.data.data)
    },

    /**
     * Get the support program pathway, if any.
     *
     * @param id - The program id.
     * @param consumerKey - An optional consumer key to filter.
     * @param skipDelay
     *
     * @return {Promise<Array>}
     */
    pathway (id, consumerKey, skipDelay = false) {
      return client.get(`support-programs/${id}/pathway`, { params: { consumer_key: consumerKey, skip_delay: skipDelay } })
        .then(response => response.data.data)
    },

    /**
     * Get the support program.
     *
     * @param id - The support program id.
     *
     * @return {Promise<Array>}
     */
    supportProgram (id) {
      return client.get(`support-programs/${id}`)
        .then(response => response.data.data)
    },

    /**
     * Get the support program statistics.
     *
     * @param id - The support program id.
     *
     * @return {Promise<Array>}
     */
    statistics (id) {
      return client.get(`support-programs/${id}/market-statistics`)
        .then(response => response.data.data)
    },

    /**
     * Get terms of a support program.
     *
     * @param id Support program id.
     *
     * @return {Promise<Array>}
     */
    terms (id) {
      return client.get(`support-programs/${id}/terms`)
        .then(response => response.data.data)
    },

    /**
     * Read a bundle category.
     *
     * @param bundleId
     * @param categoryId
     * @return {Promise<Array>}
     */
    bundleCategory (bundleId, categoryId) {
      return client.get(`program-bundles/${bundleId}/program-categories/${categoryId}`)
        .then(response => response.data.data)
    },

    /**
     * Get the program category consumer index.
     *
     * @param {string} bundleId - The bundle id that belongs to the category.
     * @param {string} categoryId - The category id.
     * @param {number} page - The page number to get (start to 0).
     * @param {number} limit - Number of record per page.
     *
     * @return {Promise<{data, meta: *}>}
     */
    bundleCategoryConsumers (bundleId, categoryId, page = 0, limit = 10) {
      return hdsClient.get(`program-bundles/${bundleId}/program-categories/${categoryId}/consumers`, {
        params: {
          page,
          perPage: limit
        }
      })
        .then(response => response.data)
    },

    /**
     * Read a feature program.
     *
     * @param id Feature program id.
     *
     * @return {Promise}
     */
    readFeature (id) {
      return client.get(`feature-programs/${id}`)
        .then(response => response.data.data)
    }
  }
}
