import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../../tailwind.config.js'

Vue.use(Vuetify)

const { theme } = resolveConfig(tailwindConfig)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: theme.colors.primary.DEFAULT,
        secondary: theme.colors.secondary.DEFAULT,
        gray: theme.colors.gray.default,
        accent: '#8c9eff',
        error: '#b71c1c',
        orange: theme.colors.orange.default
      }
    }
  }
})
