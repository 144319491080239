/**
 * The survey resource.
 *
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Read patient's medicine cabinet.
     *
     * @param {number} healthcenterId - Healthcenter id.
     * @param {number} medicineCabinetId - Medicine cabinet id.
     *
     * @return {Promise}
     */
    readMedicineCabinet (healthcenterId, medicineCabinetId) {
      return client.get(`healthcenters/${healthcenterId}/medicine-cabinets/${medicineCabinetId}`)
        .then(response => response.data.data)
    },

    /**
     * Read consumer's medicine cabinet statistics.
     *
     * @param {number} healthcenterId - Healthcenter id.
     *
     * @return {Promise}
     */
    consumersStatistics (healthcenterId) {
      return client.get(`healthcenters/${healthcenterId}/medicine-cabinet-statistics/consumers`)
        .then(response => response.data.data)
    },

    /**
     * Read statistics of a medicine cabinet category.
     *
     * @param {number} healthcenterId - The healthcenter id.
     *
     * @return {Promise}
     */
    statistics (healthcenterId) {
      return client.get(`healthcenters/${healthcenterId}/medicine-cabinet-statistics`)
        .then(response => response.data.data)
    }
  }
}
