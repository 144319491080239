<template>
  <div class="flex flex-col flex-1">
    <section v-show="programs">
      <ConsumerPrograms
        :customer="customer"
        :consumer="customer"
        :consumers="consumers"
        display-merged-programs
      />
    </section>
    <div class="px-6 pt-5 space-y-3">
      <section
        v-show="customer && !customer.certification?.validated_at"
        class="mb-10"
      >
        <CustomerOnboardingCard :customer="customer" />
      </section>
      <div>
        <div class="flex flex-col md:flex-row w-full">
          <div class="flex flex-1 flex-col">
            <div class="flex flex-1 flex-row mb-7 leading-none">
              📍 Pour infos
              <div class="flex flex-1 border-b border-gray-400" />
            </div>

            <div
              v-if="partnership"
              class="my-5 mx-2 sm:mx-10 lg:mx-20"
            >
              <div>
                <CustomerMedicineCabinetCard
                  :medicine-cabinet-id="customer.medicine_cabinet_id"
                  :program="partnership"
                  :customer="customer"
                />
              </div>
            </div>

            <div class="my-5 mx-2 sm:mx-10 lg:mx-20">
              <div
                class="flex cursor-pointer border-green"
                @click="navigateToSurveys"
              >
                <div
                  class="bg-white px-4 py-2 shadow rounded-md sm:rounded-lg sm:overflow-hidden min-w-max flex flex-1 flex-row align-center justify-space-between"
                >
                  <div class="flex flex-row items-center">
                    <img
                      width="30"
                      :src="require('@/assets/images/icons/survey.svg')"
                      class="cursor-pointer mr-5"
                    >
                    <h2 class="text-primary">
                      Voir les questionnaires
                    </h2>
                  </div>
                  <p class="text-5xl text-primary">
                    >
                  </p>
                </div>
              </div>
            </div>

            <div
              v-if="memberships"
              class="my-5 mx-2 sm:mx-10 lg:mx-20"
            >
              <div>
                <CustomerMembershipCard
                  :memberships="memberships"
                  :customer-id="customer.id"
                />
              </div>
            </div>
            <div
              v-else
              class="flex flex-1 justify-center"
            >
              <BaseSpinner />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerMembershipCard from '@/renderer/app/customer/components/customer-home/CustomerMembershipCard.vue'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import CustomerOnboardingCard from '@/renderer/app/customer/components/customer-home/CustomerOnboardingCard.vue'
import CustomerMedicineCabinetCard
from '@/renderer/app/customer/components/customer-home/CustomerMedicineCabinetCard.vue'
import ConsumerPrograms from '@/renderer/app/consumer/components/consumer-programs/ConsumerPrograms.vue'

export default {
  name: 'CustomerHome',
  components: {
    ConsumerPrograms,
    CustomerMedicineCabinetCard,
    CustomerOnboardingCard,
    BaseSpinner,
    CustomerMembershipCard
  },

  props: {
    customer: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      qrcode: null
    }
  },
  computed: {
    memberships () {
      return this.$store.getters['patient/memberships']()
    },
    partnership () {
      return this.customer.programs.find(program => program.programmable_type === 'feature')
    },
    programs () {
      return this.customer.programs.filter(program => program.programmable_type !== 'feature')
    },
    consumers () {
      return [
        this.customer,
        ...(this.customer.beneficiaries ?? [])
      ]
    }
  },
  watch: {
    customer: {
      immediate: true,

      handler (value) {
        if (this.memberships === null) {
          this.$store
            .dispatch('patient/loadMemberships', { customerId: this.$route.params.customerId })
            .catch()
        }
      }
    }
  },
  methods: {
    navigateToSurveys () {
      this.$router.push({ name: 'customer.survey.list' })
    }
  }
}
</script>
