<template>
  <img
    :src="`data:image;base64,${base64}`"
    :alt="alt"
  >
</template>

<script>
export default {
  name: 'Base64Image',
  props: {
    base64: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      required: true
    }
  }
}
</script>
