<template>
  <BaseCard>
    <BaseCardBody>
      <h3 class="text-xl font-bold leading-6 font-medium text-gray-900 mb-5">
        Statistiques
      </h3>
      <ProgramDigestCards
        v-if="!supportProgram.meta.options.hideGroups"
        :statistics-included="supportProgram.statistics.included"
        :statistics-excluded="supportProgram.statistics.excluded"
      />
      <ProgramDigestGroups
        v-if="!supportProgram.meta.options.hideGroups"
        :data="supportProgram"
      />
      <ProgramStatisticsCards
        v-if="marketStatistics?.length"
        :stats="marketStatistics"
      />
    </BaseCardBody>
  </BaseCard>
</template>

<script>
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import BaseCardBody from '@/renderer/components/base/BaseCardBody.vue'
import ProgramDigestCards from '@/renderer/app/program/components/program-statistics/ProgramDigestCards.vue'
import ProgramDigestGroups from '@/renderer/app/program/components/program-statistics/ProgramDigestGroups.vue'
import ProgramStatisticsCards from '@/renderer/app/program/components/program-statistics/ProgramStatisticsCards.vue'

export default {
  name: 'SupportProgramStatistics',
  components: { BaseCardBody, BaseCard, ProgramDigestCards, ProgramDigestGroups, ProgramStatisticsCards },
  props: {
    supportProgram: {
      type: Object,
      required: true
    },
    marketStatistics: {
      type: Array,
      required: true
    }
  }
}
</script>
