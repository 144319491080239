<template>
  <div>
    <div
      ref="expandableContainer"
      :class="{
        'max-h-20': !isExpanded,
      }"
      class="overflow-hidden flex flex-col relative expandable-text-overflow"
    >
      <slot />
    </div>

    <div
      v-if="needExpand"
      class="flex flex-row justify-end relative"
    >
      <div
        v-if="!isExpanded"
        class="w-full absolute h-12 -top-12 left-0 backdrop-filter"
        style="background: linear-gradient(to top, rgb(238,236,247), rgba(238,236,247, 0.8));"
      />
      <a
        class="text-primary font-bold cursor-pointer hover:underline"
        @click="isExpanded = !isExpanded"
      >Voir {{ isExpanded ? 'moins' : 'plus' }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExpandableTextOverflow',
  data () {
    return {
      isExpanded: false,
      isMounted: false
    }
  },
  computed: {
    needExpand () {
      if (!this.isMounted) {
        return false
      }

      const element = this.$refs.expandableContainer

      return element.scrollHeight > element.clientHeight || element.scrollHeight < element.clientHeight
    }
  },
  mounted () {
    this.isMounted = true
  }
}
</script>

<style scoped>
.overflow-mask {
  width: 100%;
  height: 20px;
  top: -1.25rem;
  position: absolute;
  left: 0;
  background: linear-gradient(transparent, transparent 1px, white);
}
</style>
