<template>
  <div class="mt-6">
    <div
      v-if="currentGuide.video"
      class="flex justify-center"
    >
      <iframe
        :src="`https://www.youtube.com/embed/${currentGuide.video}`"
        width="100%"
        height="315"
        frameborder="0"
        class="m-2"
        allowfullscreen
      />
    </div>
    <DownloadButton
      v-if="showDownloadButton"
      class="mt-4 mb-6 mx-auto"
      :title="showDownloadButton"
      :icon="showDownloadButton === 'Santé Secure' ? require('@/assets/images/icons/logo_sante_secure.png') : require('@/assets/images/icons/apodis-connect.svg')"
      @click="downloadApp"
    />
    <div class="m-6 prose max-w-full">
      <RichTextRenderer
        :document="currentGuide && currentGuide.content"
        :node-renderers="contentfulHelper.renderNodes()"
      />
    </div>

    <div class="text-base text-primary text-center mt-10 mx-auto max-w-4xl">
      Vous rencontrez un problème ?
      <br>
      Contactez-nous directement par chat en cliquant sur l'icône bleu en bas à droite de l'écran.
      Ou prenez rendez-vous avec un de nos opérateurs pour obtenir de l'aide.
    </div>

    <!-- Start of Meetings Embed Script -->
    <div
      class="meetings-iframe-container"
      data-src="https://meetings.hubspot.com/marion-liere?embed=true"
    />
    <!-- End of Meetings Embed Script -->

    <TheHubspotAppointment />
  </div>
</template>

<script>
import { contentfulService } from '@/renderer/container'
import RichTextRenderer from 'contentful-rich-text-vue-renderer'
import TheHubspotAppointment from '@/renderer/components/partials/TheHubspotAppointment.vue'
import DownloadButton from '@/renderer/components/views/onboarding/DownloadButton.vue'
import contentfulHelper from '@/renderer/helpers/contentfulHelper'

const linkSanteSecure = 'https://files.santesecure.com/sante-secure-installer.exe'
const linkConnect = 'https://files.santesecure.com/connect/apodis-connect-Setup-latest-ia32.exe'

const santeSecureRegexp = new RegExp(/sant[eéè] secure?/, 'i')
const connectRegexp = new RegExp(/connect/, 'i')

export default {
  name: 'Guide',

  components: {
    RichTextRenderer,
    TheHubspotAppointment,
    DownloadButton
  },

  props: {
    guide: {
      type: Object,
      required: false,
      default: undefined
    }
  },

  data () {
    return {
      guideFromRoute: undefined
    }
  },

  computed: {
    currentGuide () {
      return this.guide || this.guideFromRoute
    },
    showDownloadButton () {
      if (santeSecureRegexp.test(this.currentGuide.title)) {
        return 'Santé Secure'
      } else if (connectRegexp.test(this.currentGuide.title)) {
        return 'Connect'
      }
      return santeSecureRegexp.test(this.currentGuide.title) | connectRegexp.test(this.currentGuide.title)
    },
    contentfulHelper () {
      return contentfulHelper
    }
  },

  mounted () {
    if (this.$route.params && this.$route.params.guideId) {
      contentfulService()
        .getGuide(this.$route.params.guideId)
        .then(theGuide => {
          this.guideFromRoute = theGuide
        })
    }
  },

  methods: {
    downloadApp () {
      if (santeSecureRegexp.test(this.currentGuide.title)) {
        window.open(linkSanteSecure)
      } else if (connectRegexp.test(this.currentGuide.title)) {
        window.open(linkConnect)
      }
    }
  }
}
</script>
