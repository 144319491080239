<template>
  <div class="ml-7 mt-1 h-52 w-76 bg-gray-300 rounded-md relative text-tiny font-semibold">
    <div class="bottom-6 right-0 rounded-md w-32 h-36 bg-gray-50 absolute bg-primary text-white py-2 pl-1 pr-1">
      <div class="flex flex-1 justify-center">
        Patient comptoir
      </div>
      <div class="flex flex-col p-2 space-y-3">
        <div
          v-for="index in [1,2,3]"
          :key="index"
        >
          <div class="flex flex-row">
            <div class="flex rounded-md w-6 h-6 bg-gray-100 text-black items-center justify-center">
              {{ index }}
            </div>
            <div class="flex flex-col flex-1 space-y-1 justify-center">
              <div
                v-for="bar in [1,2]"
                :key="index * bar"
                class="ml-2 flex h-2 rounded-xl bg-gray-100"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotificationAdvancedLayout'
}
</script>
