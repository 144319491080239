<template>
  <interview-short-cut-container title="Ressources">
    <program-attachment-grid
      :attachments="professionalAttachments"
      :attachable-id="interview.id"
      attachable-type="interviews"
    />
  </interview-short-cut-container>
</template>

<script>
import ProgramAttachmentGrid from '@/renderer/app/program/components/program-attachment/ProgramAttachmentGrid.vue'
import InterviewShortCutContainer
from '@/renderer/app/interview/components/instance-modal/short-cuts/InterviewShortCutContainer.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapState } = createNamespacedHelpers('interview')

export default {
  name: 'InterviewAttachments',
  components: { InterviewShortCutContainer, ProgramAttachmentGrid },
  computed: {
    ...mapGetters(['professionalAttachments']),
    ...mapState(['interview'])
  }
}
</script>

<style scoped>

</style>
