/**
 * The sante-secure resource.
 * @author Mathieu Bolard <m.bolard@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Get sante-secure state.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    getState () {
      const now = new Date()
      const yesterday = new Date()
      yesterday.setDate(yesterday.getDate() - 1)
      const params = {
        date: {
          fromFormat: yesterday.toISOString().slice(0, 10),
          toFormat: now.toISOString().slice(0, 10)
        },
        interval: '30m'
      }
      return client.post('/admin/santesecure', params)
        .then(response => response.data)
        .catch(() => null)
    }
  }
}
