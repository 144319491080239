<template>
  <div class="flex flex-1 items-center hover:text-gray-500 hover:bg-gray-100 rounded cursor-pointer">
    <div
      class="flex-1"
    >
      <span>{{ consumer.customer.name }}</span>
    </div>
    <div
      class="flex-1"
    >
      <span>{{ consumer.statistics.lastPurchase ? dateHelper.shortFormat(consumer.statistics.lastPurchase) : '-' }}</span>
    </div>
    <div
      class="flex-1 hideColumn"
    >
      <span>{{ avg_basket }}</span>
    </div>
    <div
      class="flex-1 hideColumn"
    >
      <span>{{ consumer.total.quantity }} (<span>{{ total_revenue }}€</span>)</span>
    </div>
    <div
      class="flex-1 hideColumn"
    >
      <span class="">{{ consumer.total.value }}/{{ consumer.total.value + consumer.total.balance }}</span>
    </div>
    <div
      class="flex-1"
    >
      <span class="">{{ consumer.total.balance }}</span>
    </div>
    <div
      class="flex flex-1 flex-column"
    >
      <span class="">{{ consumer.statistics.reward.discounts }} €</span>
      <span
        v-if="consumer.statistics.reward.products !== 0"
        class=""
      >
        {{ consumer.statistics.reward.products }} {{ 'produit' | pluralize(consumer.statistics.reward.products) }}
      </span>
    </div>
  </div>
</template>

<script>

import dateHelper from '@/renderer/helpers/dateHelper'

export default {
  name: 'LoyaltyCardTableBody',
  props: {
    consumer: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      carts: []
    }
  },
  computed: {
    dateHelper () {
      return dateHelper
    },
    avg_basket () {
      return this.consumer.total.quantity
        ? String((this.consumer.total.revenue / this.consumer.total.quantity).toFixed(2)) + '€'
        : '-'
    },
    total_revenue () {
      return this.consumer.total.revenue.toFixed(2)
    }
  }
}
</script>

<style>
@media screen and (max-width: 900px) {
  .hideColumn {
    display: none
  }
}
</style>
