<template>
  <div class="flex flex-row justify-between mt-10">
    <div class="flex-1 p-5">
      <!-- Content hidden until tutorial video is available
      <span class="text-primary font-bold mr-10">Besoin d'aide ?</span>
     <BaseButton @click="open">
       Voir le tutoriel
     </BaseButton>-->
    </div>
    <div class="flex-1">
      <div class="flex flex-row justify-around p-5">
        <img
          class="w-30"
          src="@/assets/images/download-app-store.png"
          alt="App store"
        >
        <img
          class="w-30"
          src="@/assets/images/download-play-store.png"
          alt="Play store"
        >
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CustomerOnboardingFooter'
}
</script>
