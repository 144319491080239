/**
 * The pathway resource.
 *
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Store a pathway consumer step.
     *
     * @param {number} stepId The pathway step id.
     * @param {string} consumerKey The consumer key.
     * @param {number|null} consumerSteppableId The consumer steppable id.
     * @param {string|null} consumerSteppableType The consumer steppable type.
     *
     * @returns {Promise} The stored consumer step.
     */
    storeConsumerStep (stepId, consumerKey, consumerSteppableId = null, consumerSteppableType = null, completed = true) {
      return client
        .post('pathway-consumer-steps', {
          consumer_key: consumerKey,
          pathway_step_id: stepId,
          consumer_steppable_id: consumerSteppableId,
          consumer_steppable_type: consumerSteppableType,
          completed
        })
        .then(response => response.data.data)
    },
    /**
     * Track a pathway custom event.
     *
     * @param name The event name.
     * @param pathwayId  The pathway id.
     * @param consumerKey The consumer key.
     * @param data The event data.
     * @returns {*}
     */
    track (name, pathwayId, consumerKey = null, data = null) {
      const params = { name, consumer_key: consumerKey, pathway_id: pathwayId }

      if (data) {
        params.data = data
      }

      return client
        .post('pathway-events', params)
        .catch(() => Promise.resolve())
    }
  }
}
