import Vue from 'vue'
import router from '@/renderer/router'
import Toasted from 'vue-toasted'

import {
  storageService
} from '@/renderer/container'

Vue.use(Toasted)

const duration = 5000

export default {
  success (response) {
    return response
  },

  failure (error) {
    switch (error.response.status) {
      case 401:
        Vue.toasted.error('Accès non autorisé', {
          duration,
          action: {
            text: 'Se connecter',
            onClick (event, toast) {
              toast.goAway(0)

              storageService().destroy('token')

              router.push({
                name: 'login'
              })
            }
          }
        })
        break

      case 403:
        Vue.toasted.error('Action non autorisée', { duration })
        break

      case 500:
        Vue.toasted.error('Une erreur est survenue', { duration })
        break
    }

    return Promise.reject(error)
  }
}
