<template>
  <div class="max-h-screen overflow-y-auto flex flex-col">
    <div class="container mx-auto flex flex-col py-4 px-4 sm:px-6 lg:px-8">
      <div class="flex flex-row justify-between">
        <div class="bg-gradient-to-r from-darkPurple-default to-darkBlue-default p-4 rounded-3xl">
          <div class="flex flex-row items-center">
            <img
              alt="logo white"
              class="ml-3 mr-6 hidden sm:block"
              :style="{'objectFit': 'scale-down', 'width': '50px'}"
              :src="require('@/assets/images/logos/apodis-white.svg')"
            >
            <div class="text-white text-3xl font-extrabold flex flex-col">
              <div class="flex flex-col flex-wrap sm:flex-row">
                <div class="text-white mr-4">
                  Bienvenue
                </div>
                <div class="text-secondary">
                  {{ accessPoint && accessPoint.user.name + " 👋" }}
                </div>
              </div>
              <div class="text-lg text-white capitalize">
                {{ accessPoint && accessPoint.entity.name.toLowerCase() }}
              </div>
            </div>
          </div>
        </div>
        <button
          class="ml-4"
          @click="logout"
        >
          <img
            alt="logout"
            title="Se déconnecter"
            :src="require('@/assets/images/icons/icone-logout.png')"
          >
        </button>
      </div>
      <div>
        <h1 class="font-heading text-3xl font-bold text-primary text-center mt-10 mb-4">
          Aidez vos patients à devenir acteurs de leur santé !
        </h1>
        <h2 class="font-heading text-base text-primary text-center mb-10 mx-auto max-w-4xl">
          Installez dès maintenant <b>Santé Secure</b>, <b>Apodis Pro</b> et <b>Apodis Connect</b> en suivant nos guides
          afin de profiter pleinement de vos nouveaux services.
        </h2>
      </div>
      <div class="flex flex-col">
        <div class="font-heading text-xl font-extrabold text-primary mb-2">
          Simplifiez votre métier au quotidien
        </div>
        <installation-process-tab
          :access-point="accessPoint"
          @openGuide="(guideId) => showGuide(guideId)"
        />
      </div>
    </div>
    <div class="container mx-auto flex flex-col py-4 px-4 sm:px-6 lg:px-8 mt-2">
      <div class="flex flex-col self-center items-center">
        <div class="font-heading self-start text-xl font-semibold text-primary mb-2">
          Tutoriels
        </div>
        <div class="flex rounded-3xl shadow-black shadow-xl bg-white mb-10 px-4 shrink">
          <div class="flex shrink overflow-x-auto overflow-hidden my-4">
            <div class="flex w-max p-2 items-center">
              <p
                class="text-3xl font-semibold text-shadow-md font-heading min-w-fit"
                style="writing-mode: vertical-rl; text-orientation: upright; color: #3513E2; letter-spacing: -0.6em"
              >
                TUTOS
              </p>
            </div>
            <div
              v-for="(guide, index) in guides"
              :key="index"
              class="flex-none mx-2 h-48 bg-primary rounded-lg cursor-pointer overflow-hidden"
              :style="{
                'background-image': 'url(' + guide.fields.preview.fields.file.url + ')',
                'backgroundPosition': 'center center',
                'backgroundRepeat': 'no-repeat',
                'backgroundSize': 'cover'
              }"
              @click="showGuide(guide.sys.id)"
            >
              <div class="flex items-end h-48">
                <div class="text-white p-2 bg-primary">
                  {{ guide.fields.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <security />
    <div class="container mx-auto flex flex-col py-4 px-4 sm:px-6 lg:px-8">
      <div
        v-if="showFirstSteps"
        class="flex flex-col self-center items-center"
      >
        <div class="font-heading self-start text-xl font-semibold text-primary mb-2">
          Premiers pas
        </div>
        <div class="flex rounded-3xl shadow-black shadow-xl bg-white mb-10 px-4 shrink">
          <div class="flex shrink overflow-x-auto overflow-hidden my-4">
            <div class="flex w-max p-2 items-center">
              <p
                class="text-3xl font-semibold text-shadow-md font-heading min-w-fit"
                style="writing-mode: vertical-rl; text-orientation: upright; color: #3513E2; letter-spacing: -0.6em"
              >
                TUTOS
              </p>
            </div>
            <div
              v-for="(guide, index) in firstStepsGuides"
              :key="index"
              class="flex-none mx-2 h-48 bg-primary rounded-lg cursor-pointer overflow-hidden"
              :style="{
                'background-image': 'url(' + guide.fields.preview.fields.file.url + ')',
                'backgroundPosition': 'center center',
                'backgroundRepeat': 'no-repeat',
                'backgroundSize': 'cover'
              }"
              @click="showGuide(guide.sys.id)"
            >
              <div class="flex items-end h-48">
                <div class="text-white p-2 bg-primary">
                  {{ guide.fields.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col self-center items-center w-2/3">
        <div class="font-heading self-start text-xl font-semibold text-primary mb-2">
          Pour aller plus loin
        </div>
        <div
          :v-if="tutorials.length !== 0"
          class="grid grid-cols-2 place-items-center gap-2 w-full"
        >
          <div
            v-for="(item, index) in tutorials"
            :key="index"
            class="w-full p-2"
          >
            <iframe
              :src="'https://www.youtube.com/embed/' + item.snippet.resourceId.videoId + '?rel=0&autoplay=0&showinfo=0&controls=0'"
              width="100%"
              height="200"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col self-center items-center w-2/3 mt-8">
        <div class="font-heading self-start text-xl font-semibold text-primary mb-2">
          Replay de nos webinaires
        </div>
        <div
          :v-if="webinars.length !== 0"
          class="grid grid-cols-2 place-items-center gap-2 w-full"
        >
          <div
            v-for="(item, index) in webinars"
            :key="index"
            class="w-full p-2"
          >
            <iframe
              :src="'https://www.youtube.com/embed/' + item.snippet.resourceId.videoId + '?rel=0&autoplay=0&showinfo=0&controls=0'"
              width="100%"
              height="200"
            />
          </div>
        </div>
      </div>
      <div>
        <div class="font-heading text-3xl font-semibold text-primary text-center my-8">
          Besoin d'aide ?
        </div>
        <div class="font-heading text-base text-primary text-center mb-8 mx-auto max-w-4xl">
          L'équipe d'Apodis vous accompagne à chaque étape de votre parcours<br>afin d'utiliser au mieux vos services.
          <br>
          Contactez-nous par
          <span
            class="font-bold underline cursor-pointer"
            @click="openChat()"
          >
            chat
          </span>
          ,
          <a
            class="font-bold underline"
            href="mailto:contact@apodispharma.com"
          >
            email
          </a>
        </div>
      </div>
      <GuideModal
        :is-guide-modal-visible="isGuideModalVisible"
        :guide="currentGuide"
      />
      <BaseModal
        v-show="isAppointmentModalVisible"
        :title="'Prise de rendez-vous'"
      >
        <!-- Start of Meetings Embed Script -->
        <div
          class="meetings-iframe-container"
          data-src="https://meetings.hubspot.com/marion-liere?embed=true"
        />
        <!-- End of Meetings Embed Script -->

        <template v-slot:footer>
          <BaseButton @click="isAppointmentModalVisible = false">
            Fermer
          </BaseButton>
        </template>
      </BaseModal>
    </div>
  </div>
</template>

<script>

import { authenticationService, contentfulService } from '@/renderer/container'
import GuideModal from '@/renderer/components/views/guide/GuideModal.vue'
import BaseModal from '@/renderer/components/base/BaseModal.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import axios from 'axios'
import InstallationProcessTab
from '@/renderer/components/views/onboarding/installation-process/InstallationProcessTab.vue'
import Security from '@/renderer/components/views/onboarding/security/Security.vue'

export default {
  name: 'Onboarding',

  components: {
    Security,
    InstallationProcessTab,
    GuideModal,
    BaseModal,
    BaseButton
  },

  data () {
    return {
      accessPoint: null,
      guides: [],
      firstStepsGuides: [],
      currentGuide: {},
      isGuideModalVisible: false,
      isAppointmentModalVisible: false,
      santeSecureGuideId: null,
      connectGuideId: null,
      proGuideId: null,
      tutorials: [],
      webinars: []
    }
  },

  computed: {
    shouldShowProModal: function () {
      if (localStorage) {
        if (localStorage.getItem('proModalViewed')) {
          return false
        }
      }
      return true
    },
    showFirstSteps () {
      return !!this.firstStepsGuides.length
    }
  },

  mounted () {
    this.accessPoint = this.$store.state.system.environment
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'setEnvironment') {
        this.accessPoint = mutation.payload
      }
    })
    contentfulService()
      .getSpecificTagGuide('install')
      .then(guides => {
        this.guides = guides
      })

    contentfulService()
      .getSpecificTagGuide('first-steps')
      .then(guides => {
        this.firstStepsGuides = guides
      })

    this.santeSecureGuideId = contentfulService().getSanteSecureGuideId()
    this.connectGuideId = contentfulService().getConnectGuideId()
    this.proGuideId = contentfulService().getProGuideId()

    const playlistTutorialId = 'PLsBHFpvnM0qIIFxMb9zQfxUyDwDxla2zU'
    axios.get('https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=' + playlistTutorialId + '&key=' + process.env.VUE_APP_YOUTUBE_API_KEY + '&maxResults=1337').then(resp => {
      this.tutorials = resp.data.items
    })

    const playlistWebinarId = 'PLsBHFpvnM0qIvzdq_zS-rtUn5dVK7Iy5i'
    axios.get('https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=' + playlistWebinarId + '&key=' + process.env.VUE_APP_YOUTUBE_API_KEY + '&maxResults=1337').then(resp => {
      this.webinars = resp.data.items
    })

    this.$root.$on('show-guide', (guideId) => {
      this.showGuide(guideId)
    })
  },

  methods: {
    openAppStore () {
      const link = 'https://itunes.apple.com/app/id1580581211'
      window.open(link)
    },

    openPlayStore () {
      const link = 'https://play.google.com/store/apps/details?id=com.apodis.pro'
      window.open(link)
    },

    downloadConnect () {
      const link = 'https://files.santesecure.com/connect/apodis-connect-Setup-latest-ia32.exe'
      window.open(link)
    },

    downloadSanteSecure () {
      const link = 'https://files.santesecure.com/sante-secure-installer.exe'
      window.open(link)
    },

    showGuide (guideId) {
      contentfulService()
        .getGuide(guideId)
        .then(guide => {
          this.isGuideModalVisible = true
          this.currentGuide = guide
        })
    },
    showProModal () {
      this.isProModalVisible = true
    },

    closeProModal () {
      localStorage.setItem('proModalViewed', '1')
      this.isProModalVisible = false
    },

    openChat () {
      window.HubSpotConversations.widget.open()
    },

    showAppointmentModal () {
      this.isAppointmentModalVisible = true
    },

    logout () {
      authenticationService().logout()

      this.$router.push({
        name: 'login'
      })

      if (window.ipcRenderer) {
        window.ipcRenderer.send('unauthenticated')
      }
    }
  }
}
</script>
