<template>
  <div
    v-if="membership"
    class="m-6"
  >
    <div class="flex flex-row justify-between flex-wrap gap-4">
      <loyalty-card-header
        :loyalty-card="membership.loyalty_card"
        @click="redirectoToLoyaltyCard"
      />
      <MembershipHeader
        :membership="membership"
        :refresh="refresh"
      />
    </div>
    <div class="grid grid-cols-2 gap-5">
      <MembershipBalances :membership="membership" />
      <loyalty-card-teasers :loyalty-card="membership.loyalty_card" />
    </div>

    <MembershipTransactions
      :transactions="transactions.data"
      :selected-type="selectedType"
      :on-type-selected="handleSelectedTransactionType"
    />
    <MembershipInformation :membership="membership" />
  </div>
</template>

<script>
import {
  loyaltyCardMembershipResource,
  loyaltyCardMembershipTransactionResource
} from '@/renderer/container'

import MembershipHeader
from '@/renderer/app/loyalty-card-membership/components/loyalty-card-membership-header/LoyaltyCardMembershipHeader.vue'
import MembershipBalances
from '@/renderer/app/loyalty-card-membership/components/loyalty-card-membership-balances/LoyaltyCardMembershipBalances.vue'
import MembershipInformation
from '@/renderer/app/loyalty-card-membership/components/loyalty-card-membership-information/LoyaltyCardMembershipInformation.vue'
import MembershipTransactions
from '@/renderer/app/loyalty-card-membership/components/loyalty-card-membership-transactions/LoyaltyCardMembershipTransactions.vue'
import LoyaltyCardHeader
from '@/renderer/app/loyalty-card-membership/components/loyalty-card-header/LoyaltyCardHeader.vue'
import LoyaltyCardTeasers
from '@/renderer/app/loyalty-card-membership/components/loyalty-card-teasers/LoyaltyCardTeasers.vue'

export default {
  components: {
    LoyaltyCardTeasers,
    LoyaltyCardHeader,
    MembershipBalances,
    MembershipHeader,
    MembershipInformation,
    MembershipTransactions
  },

  data () {
    return {
      transactions: {
        meta: {
          links: []
        },
        data: []
      },
      membership: null,
      selectedType: null,
      pointBalance: 0
    }
  },

  watch: {
    selectedType: {
      immediate: true,

      handler () {
        this.page = 0
        this.getTransactions()
      }
    },

    page () {
      this.getTransactions()
    }
  },

  mounted () {
    this.readMembership()
  },

  methods: {
    readMembership () {
      loyaltyCardMembershipResource()
        .read(
          this.$route.params.customerId,
          this.$route.params.membershipId
        )
        .then(membership => {
          this.membership = membership
          this.pointBalance = membership.point_balance
        })
    },

    redirectoToLoyaltyCard () {
      this.$router.push({
        name: 'loyalty-card',
        params: {
          customerId: this.$route.params.customerId,
          loyaltyCardId: this.membership.loyalty_card.id
        }
      })
    },

    getTransactions () {
      loyaltyCardMembershipTransactionResource()
        .get(
          this.$route.params.customerId,
          this.$route.params.membershipId,
          this.selectedType
        )
        .then(transactions => {
          this.transactions = transactions
        })
    },

    handleSelectedTransactionType (selectedType) {
      this.selectedType = selectedType
    },

    refresh () {
      this.readMembership()
      this.getTransactions()
    }
  }
}
</script>
