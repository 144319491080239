<template>
  <div>
    <button
      id="menu-1"
      type="button"
      class="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary ml-1"
      aria-expanded="false"
      aria-haspopup="true"
      @click="isOpen = !isOpen"
    >
      <span class="sr-only">
        Ouvrir le menu utilisateur
      </span>

      <span class="inline-block h-8 w-8 overflow-hidden">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="96 96 832 832"
          class="fill-current text-primary"
        >
          <path
            d="M512 96c-229.8 0-416 186.2-416 416s186.2 416 416 416 416-186.2 416-416-186.2-416-416-416zM512 893.4c-117.2 0-222.2-53.2-292.2-136.6 35.6-15.4 124.4-47.4 180.6-63.8 4.4-1.4 5.2-1.6 5.2-21.4 0-21.2-2.4-36.2-7.6-47.2-7-15-15.4-40.4-18.4-63.2-8.4-9.8-19.8-29-27.2-65.8-6.4-32.4-3.4-44.2 0.8-55.2 0.4-1.2 1-2.4 1.2-3.6 1.6-7.4-0.6-47-6.2-77.6-3.8-21 1-65.6 30-102.6 18.2-23.4 53.2-52 116-56h35c63.8 4 98.8 32.6 117 56 29 37 33.8 81.6 29.8 102.6-5.6 30.6-7.8 70-6.2 77.6 0.2 1.2 0.8 2.4 1.2 3.4 4.2 11 7.4 22.8 0.8 55.2-7.4 36.8-18.8 56-27.2 65.8-3 22.8-11.4 48-18.4 63.2-6.6 13.8-13.2 30.2-13.2 46.6 0 19.8 0.8 20 5.4 21.4 53.4 15.8 145.4 47.6 186 64.2-70 83.6-175 137-292.4 137z"
          />
        </svg>
      </span>
    </button>

    <div
      v-show="isOpen"
      class="origin-top-right absolute z-30 right-0 mt-2 w-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-1"
    >
      <div
        v-if="environment"
        class="py-1"
      >
        <p class="block px-4 py-2 text-sm text-gray-700">
          Connecté avec la {{ environment.entity.name }}
        </p>
      </div>
      <div class="py-1">
        <a
          href="#"
          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:bg-gray-100"
          @click="route('notification.preference')"
        >
          Paramètres de notification
        </a>
      </div>
      <div class="py-1">
        <a
          href="#"
          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:bg-gray-100"
          @click="route('support')"
        >
          Support
        </a>
      </div>
      <div class="py-1">
        <a
          href="#"
          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:bg-gray-100"
          @click="logout()"
        >
          Déconnexion
        </a>
      </div>
      <div class="py-1">
        <a
          href="#"
          class="block px-4 py-2 text-sm text-gray-700"
        >
          {{ `Version ${version} - ${env}` }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {
  authenticationService
} from '@/renderer/container'

import { version } from '../../../../package.json'

export default {
  data: () => ({
    isOpen: false,
    version: version,
    env: process.env.NODE_ENV === 'development' ? 'dev' : process.env.NODE_ENV === 'production' ? 'prod' : process.env.NODE_ENV === 'staging' ? 'stag' : '???'
  }),

  computed: {
    environment () {
      return this.$store.state.system.environment
    }
  },

  methods: {
    route (name) {
      this.$router.push({
        name
      })

      this.isOpen = false
    },

    logout () {
      authenticationService().logout()

      this.route('login')

      if (window.ipcRenderer) {
        window.ipcRenderer.send('unauthenticated')
      }
    }
  }
}
</script>
