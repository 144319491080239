<template>
  <div class="bg-primary p-3 rounded-xl hover:opacity-50 h-12">
    <renew :class="'icon-white'" />
  </div>
</template>

<script>
import Renew from '@/assets/images/icons-vue/renew.vue'

export default {
  name: 'PrescriptionRenewalButton',
  components: { Renew }
}
</script>
