const defaultTheme = require('tailwindcss/defaultTheme')

module.exports = {
  purge: [
    './src/**/*.vue'
  ],
  darkMode: false, // or 'media' or 'class'
  theme: {
    extend: {
      borderWidth: {
        2.5: '2.5px'
      },
      fontFamily: {
        sans: [
          'Comfortaa',
          ...defaultTheme.fontFamily.sans
        ],
        heading: ['Poppins', 'sans-serif'],
        content: ['Poppins', 'sans-serif'],
        semibold: ['Poppins-SemiBold', 'sans-serif'],
        'content-bold': ['Poppins-Bold', 'sans-serif']
      },
      fontSize: {
        tiny: '10px',
        xs: '12px',
        sm: '14px',
        base: '16px',
        lg: '18px'
      },
      flex: {
        2: '2 2 0%'
      },
      colors: {
        primary: {
          lightest: '#f3f5f7',
          DEFAULT: '#3513e2',
          dark: '#3c008a'
        },
        secondary: {
          lightest: '#C2F5E4',
          DEFAULT: '#65efca'
        },
        neutral: {
          DEFAULT: '#f1f1f1'
        },
        lightPurple: {
          lightest: '#EEECF7',
          DEFAULT: '#E2C4FF',
          darkest: '#AB74E0'
        },
        darkPurple: {
          default: '#3c008a'
        },
        darkBlue: {
          default: '#3513e2'
        },
        gray: {
          default: '#cecece',
          dark: '#353535'
        },
        green: {
          light: '#65efca'
        },
        orange: {
          light: '#ffd7a4',
          default: '#FFBC68'
        },
        pink: {
          light: '#ff80a280',
          default: '#FF80A2',
          lighter: 'rgba(226, 196, 255, 0.3)'
        },
        red: {
          default: '#ff2525',
          light: '#eb5e57'
        }
      },
      boxShadow: {
        custom: '0px 2px 6px rgba(0, 0, 0, 0.16)',
        deep: '0px 3px 23px #AB74E0'
      }
    }
  },
  variants: {},
  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/line-clamp'),
    require('tailwindcss-textshadow'),
    require('@tailwindcss/typography')
  ]
}
