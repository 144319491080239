<template>
  <div class="flex flex-row w-full">
    <CustomerHeaderInsured :customer="customer" />
    <CustomerHeaderBeneficiaries
      v-if="customer.beneficiaries.length"
      :customer="customer"
    />
  </div>
</template>

<script>
import CustomerHeaderInsured from '@/renderer/app/customer/components/customer-header/CustomerHeaderInsured.vue'
import CustomerHeaderBeneficiaries from '@/renderer/app/customer/components/customer-header/CustomerHeaderBeneficiaries.vue'

export default {
  name: 'CustomerHeader',
  components: {
    CustomerHeaderBeneficiaries,
    CustomerHeaderInsured
  },
  props: {
    customer: {
      type: Object,
      required: true
    }
  }
}
</script>
