<template>
  <div class="bg-gray-300 p-4 h-full">
    <h2
      v-if="title"
      class="font-bold text-lg mb-4"
    >
      {{ title }}
    </h2>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'InterviewShortCutContainer',
  props: {
    title: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped>

</style>
