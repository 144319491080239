<template>
  <div
    role="dialog"
    aria-modal="true"
    aria-labelledby="modal-title"
    class="fixed z-10 inset-0 overflow-y-auto"
  >
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div
        aria-hidden="true"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        @click="$emit('close')"
      />

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
      >
        &#8203;
      </span>

      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full"
        :style="containerStyle"
      >
        <div class="bg-white px-8 py-6">
          <div>
            <div class="flex mb-5 items-center">
              <h3
                v-if="title"
                id="modal-title"
                class="text-lg font-content-bold leading-6 text-gray-900 flex-1"
              >
                {{ title }}
              </h3>
              <CloseIcon
                class="close-icon w-8 h-8"
                @click.native="$emit('close')"
              />
            </div>
            <div>
              <slot />
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 space-x-2">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/assets/images/icons-vue/close-solid.vue'

export default {
  name: 'BaseModal',
  components: {
    CloseIcon
  },
  props: {
    title: {
      type: String,
      default: null
    },
    closable: {
      type: Boolean,
      default: true
    },
    containerStyle: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style scoped>
.close-icon {
  fill: rgb(187, 187, 187);
  cursor: pointer;
}

.close-icon:hover {
  fill: gray;
}
</style>
