/**
 * The laboratory event resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Get all laboratory events.
     * @param {number} laboratory - The ID of the laboratory.
     * @param {Array} [types] - The event types container.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    get (laboratoryId, types) {
      const type = types ? types.join(',') : null

      return client
        .get(`/calendars/healthcenters/${laboratoryId}`, {
          params: { type }
        })
        .then(response => response.data.events)
    }
  }
}
