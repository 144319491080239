<template>
  <div class="flex flex-col gap-3">
    <header-back-button
      :title="`Disponibilités ventes directes - ${sourceName}`"
    />
    <base-flex-spinner v-if="loading" />
    <div
      v-else
      class="flex flex-col gap-3 px-3"
    >
      <base-card
        v-if="phone || email"
      >
        <base-card-body class="flex flex-col gap-3">
          <h1 class="text-lg font-content-bold">
            Coordonnées
          </h1>
          <div v-if="phone">
            <span>
              <v-icon
                size="25"
                color="black"
              >
                mdi-phone
              </v-icon>
              {{ phone }}
            </span>
          </div>
          <div v-if="email">
            <span>
              <v-icon
                size="25"
                color="black"
              >
                mdi-email
              </v-icon>
              {{ email }}
            </span>
          </div>
        </base-card-body>
      </base-card>
      <p v-if="!availabilities?.length">
        Aucune donnée à afficher. Veuillez nous contacter pour configurer vos disponibilités.
      </p>
      <div
        v-else
        class="flex flex-col gap-3"
      >
        <base-input-search
          :value="search"
          placeholder="Rechercher un produit"
          @input="(value) => search = value"
        />
        <div
          v-for="availability in filteredAvailabilities"
          :key="availability.id"
          class="bg-white rounded-lg shadow p-3 gap-2 text-primary text-md flex items-center cursor-pointer"
          @click="$router.push({
            name: 'disruption-logistics.availability-history',
            params: {availabilityId: availability.id}, query: {productName: availability.product.name}
          })"
        >
          <div
            class="w-4 h-4 rounded-full"
            :class="{
              'bg-green-500': availability.available,
              'bg-red-500': !availability.available
            }"
          />
          <p class="font-bold flex-1 truncate">
            {{ availability.product.name }}
          </p>
          <div class="flex gap-8">
            <base-tag
              v-if="availability.product.has_order_quota"
              class="font-bold uppercase"
            >
              Sous quota
            </base-tag>
            <p class="self-end">
              Actuellement {{ availability.available ? 'disponible' : 'indisponible' }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBackButton from '@/renderer/app/core/components/header-back-button/HeaderBackButton.vue'
import BaseFlexSpinner from '@/renderer/components/base/spinner/BaseFlexSpinner.vue'
import { disruptionLogisticsApi } from '@/renderer/container'
import BaseInputSearch from '@/renderer/components/base/BaseInputSearch.vue'
import BaseTag from '@/renderer/components/base/BaseTag.vue'
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import BaseCardBody from '@/renderer/components/base/BaseCardBody.vue'

export default {
  name: 'DirectAvailabilitiesPage',
  components: { BaseTag, BaseCardBody, BaseCard, BaseInputSearch, BaseFlexSpinner, HeaderBackButton },
  data () {
    return {
      availabilities: null,
      loading: false,
      search: null
    }
  },
  computed: {
    sourceId () {
      return this.$route.params.sourceId
    },
    sourceName () {
      return this.$route.query.sourceName
    },
    email () {
      return this.$route.query?.email
    },
    phone () {
      return this.$route.query?.phone
    },
    filteredAvailabilities () {
      if (!this.availabilities) {
        return []
      }

      if (!this.search) {
        return this.availabilities
      }

      return this.availabilities.filter((availability) => {
        return availability.product.name.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  watch: {
    sourceId: {
      handler () {
        this.loading = true
        this.loadAvailabilities()
          .finally(() => {
            this.loading = false
          })
      },
      immediate: true
    }
  },
  methods: {
    /**
     * Load the availabilities
     *
     * @return {Promise<void>}
     */
    loadAvailabilities () {
      return disruptionLogisticsApi().availabilities('depositary', this.sourceId)
        .then((response) => {
          this.availabilities = response.sort((a, b) => a.product.name.localeCompare(b.product.name))
        })
    }
  }
}
</script>

<style scoped>

</style>
