<template>
  <gmap-marker
    v-if="healthcenter.address.latitude && healthcenter.address.longitude && google"
    :position="{ lat: healthcenter.address.latitude, lng: healthcenter.address.longitude }"
    :animation="google.maps.Animation.DROP"
    :clickable="true"
    :icon="markerIcon"
    :label="{
      text: productStock.toString(),
      color: 'white',
      fontSize: '12px',
      fontWeight: 'bold'
    }"
    @click="$emit('click', healthcenter)"
  />
</template>

<script>
import { gmapApi } from 'vue2-google-maps'

export default {
  name: 'HealthcenterGroupAvailabilityMapMarker',
  props: {
    healthcenter: {
      type: Object,
      required: true
    }
  },
  computed: {
    /**
     * Get the google maps api.
     *
     * @return {Object|null}
     */
    google () {
      return gmapApi()
    },
    /**
     * Get the marker icon.
     *
     * @return {Object}
     */
    markerIcon () {
      return {
        url: this.isProductAvailable
          ? require('@/assets/images/icons/map-markers/availabilities/group-available.svg')
          : require('@/assets/images/icons/map-markers/availabilities/group-unavailable.svg'),
        scaledSize: this.google && new this.google.maps.Size(40, 40),
        anchor: this.google && new this.google.maps.Point(20, 20)
      }
    },
    /**
     * Get the healthcenter product stock.
     * @return {number}
     */
    productStock () {
      return this.healthcenter.stocks?.length ? this.healthcenter.stocks[0].quantity : 0
    },
    /**
     * Check if the product is available.
     *
     * @return {boolean}
     */
    isProductAvailable () {
      return this.healthcenter.stocks?.length && this.healthcenter.stocks[0].quantity > 0
    }
  }
}
</script>

<style scoped>

</style>
