<template>
  <div>
    <div class="flex items-center">
      <div class="flex-1">
        <div class="font-bold mb-2">
          {{ notification.name }}
        </div>
        <div class="flex flex-row items-center gap-x-3">
          <div class="text-sm bg-lightPurple p-4 rounded-xl flex-1">
            {{ notification.parsed_content }}
          </div>
          <BaseButton
            :primary="!editMode"
            @click="$emit('selected', notification)"
          >
            {{ editMode ? 'Modifier' : 'Sélectionner' }}
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/renderer/components/base/BaseButton.vue'

export default {
  name: 'CertificationNotificationItem',
  components: { BaseButton },
  props: {
    /**
     * The message object.
     */
    notification: {
      type: Object,
      required: true
    },
    /**
     * Whether it should display the edition mode.
     */
    editMode: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
