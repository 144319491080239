<template>
  <div>
    <ul
      role="list"
      class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3"
    >
      <li
        v-for="event in events"
        :key="event.id"
        class="col-span-1 flex flex-col text-center bg-white rounded-lg
        shadow divide-y divide-gray-200"
      >
        <BaseImage
          :path="event.imagePath"
          :text="event.name"
          class="w-full h-32"
        />

        <div class="flex-1 flex flex-col p-6">
          <h3 class="text-gray-900 text-sm font-bold">
            {{ event.name }}
          </h3>

          <dl class="mt-1 flex-grow flex flex-col justify-between">
            <dt class="sr-only">
              Type
            </dt>

            <dd class="text-gray-500 text-sm">
              <p v-if="event.event_type">
                {{ event.event_type.name }}
              </p>

              <p v-else>
                Offre commerciale
              </p>
            </dd>

            <dt class="sr-only">
              Fin
            </dt>

            <dd class="text-xs mt-2 text-gray-900">
              <p v-if="event.remainingDays">
                Se termine dans {{ event.remainingDays }}

                <span v-if="event.remainingDays > 1">
                  jours
                </span>

                <span v-else>
                  jour
                </span>
              </p>

              <p v-else>
                Terminé
              </p>
            </dd>
          </dl>
        </div>

        <div>
          <div class="-mt-px flex divide-x divide-gray-200">
            <div class="w-0 flex-1 flex">
              <router-link
                :to="event.route"
                class="-mr-px w-0 flex-1 inline-flex items-center justify-center
                py-4 text-sm text-gray-700 font-medium border border-transparent
                rounded-bl-lg hover:text-gray-500"
              >
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 12a2 2 0 100-4 2 2 0 000 4z"
                  />

                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542
                    7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14
                    10a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>

                <span class="ml-3">
                  Afficher
                </span>
              </router-link>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {
  laboratoryEventResource
} from '@/renderer/container'

import dayjs from 'dayjs'
import eventHelper from '@/renderer/helpers/eventHelper'
import BaseImage from '@/renderer/components/base/BaseImage.vue'

export default {
  components: {
    BaseImage
  },

  data () {
    return {
      events: []
    }
  },

  mounted () {
    laboratoryEventResource()
      .get(this.$route.params.laboratoryId, [
        'tv-ad',
        'press-ad',
        'radio-ad',
        'poster-ad',
        'formation',
        'animation',
        'medical-visit',
        'product-launch',
        'digital-campaign',
        'sellout-participation',
        'commercial-appointment',
        'prestation-participation'
      ])
      .then(events => {
        this.events = events.map(event => {
          const now = dayjs()
            .endOf('day')

          const endDate = dayjs(event.end)
            .add(1, 'day')
            .endOf('day')

          event.route = eventHelper.generateRoute(event)
          event.remainingDays = Math.max(endDate.diff(now, 'day'), 0)
        })

        this.events = events
      })
  }
}
</script>
