<template>
  <div>
    <div class="flex flex-row mt-10">
      <icon
        name="capsule"
        :size="24"
        :color="'#3513e2'"
      />
      <p class="ml-2 text-primary">
        Produits commandés ({{ orderProducts.length }})
      </p>
    </div>
    <div
      v-for="orderProduct in orderProducts"
      class="flex flex-row my-4"
    >
      <icon
        name="shopping-cart"
        :size="24"
        :color="'#3513e2'"
      />
      <p class="mx-5">
        {{ orderProduct.quantity }}x
      </p>
      <p class="flex flex-1">
        {{ orderProduct.product.name }}
      </p>
      <p>{{ orderProduct.total_price }}€</p>
    </div>
    <div
      v-if="!!order?.user_comment"
      class="flex flex-row mt-4 items-center"
    >
      <p class="my-5">
        Commentaire du patient : {{ order.user_comment }}
      </p>
    </div>
  </div>
</template>

<script>
import SecureImage from '@/renderer/app/core/components/secure-image/SecureImage.vue'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import Icon from '@/renderer/app/core/icon/Icon.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import OrderOperator from '@/renderer/app/order/components/OrderOperator.vue'

export default {
  name: 'OrderProducts',
  components: { SecureImage, BaseSpinner, Icon, BaseButton, OrderOperator },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    orderProducts () {
      if (this.order) {
        return this.order?.content?.products || this.order?.content?.items || []
      }

      return []
    }
  }
}

</script>
