<template>
  <div
    v-if="isVisible"
    class="absolute shadow bg-white w-80 bottom-28 right-0 px-5 py-2 rounded-lg z-10"
  >
    <p class="font-heading text-primary text-md mb-3">
      Professionnels de santé
    </p>
    <div class="flex flex-row items-center mb-3">
      <img
        :src="require('@/assets/images/icons/cross-filled.svg')"
        alt="Healthcenter"
        class="mr-4"
        width="25"
        height="25"
      >
      <div>
        <p class="font-content font-bold text-sm capitalize">
          Dr. {{ prescriptor.name.toLowerCase() }}
        </p>
        <p class="font-content text-xs mt-1">
          {{ prescriptor.address.street }}, {{ prescriptor.address.city }}
          {{ prescriptor.address.postal_code }}
        </p>
        <p class="font-content text-xs">
          {{ prescriptor.speciality }}
        </p>
        <div
          v-if="prescriptor.phone"
          class="flex flex-row align-center items-center -mt-4"
        >
          <img
            :src="require('@/assets/images/icons/phone.svg')"
            alt="phone"
            class="mr-2"
            width="15"
            height="15"
          >
          <p class="font-content text-xs items-end mt-4">
            {{ prescriptor.phone }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrescriptorPopupInformation',
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    prescriptor: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
