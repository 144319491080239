<template>
  <div>
    <div class="mt-4">
      <certification-notification-item
        :notification="notification"
        edit-mode
        @selected="$emit('onModify', notification)"
      />
    </div>
  </div>
</template>

<script>
import CertificationNotificationItem from '@/renderer/app/customer-certification/components/CertificationNotificationItem.vue'

export default {
  name: 'CustomerCertificationNotificationConfirmation',
  components: { CertificationNotificationItem },
  props: {
    /**
     * The message object.
     */
    notification: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
