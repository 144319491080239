/**
 * The host resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Update a host.
     * @param {Ojbect} data - The data object.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    update (data) {
      return client.put('/hosts', { data })
        .then(response => response.data.data)
        .catch(() => null)
    },

    /**
     * Get hosts.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    get () {
      return client.get('/hosts')
        .then(response => response.data.data)
        .catch(() => null)
    }
  }
}
