const InterviewAudioService = () => {
  /**
   * The mime type to use when recording interview audio.
   *
   * @type {string}
   */
  const recordMimeType = 'audio/webm;codecs=opus'

  /**
   * The file extension being generated when downloading the audio.
   *
   * @type {string}
   */
  const recordExtension = 'webm'

  /**
   * Retrieve the selected audio input device from local storage.
   */
  const getSelectedAudioInput = () => {
    return localStorage.getItem('selectedAudioInputId')
  }

  /**
   * Set the selected audio input device in local storage.
   *
   * @param audioInputId
   */
  const setSelectedAudioInput = (audioInputId) => {
    localStorage.setItem('selectedAudioInputId', audioInputId)
  }

  return {
    recordMimeType,
    recordExtension,
    getSelectedAudioInput,
    setSelectedAudioInput
  }
}

export default InterviewAudioService()
