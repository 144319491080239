<template>
  <base-card>
    <base-card-body class="flex flex-col gap-3">
      <h1 class="text-lg font-bold">
        {{ healthcenterCount }} {{ 'pharmacie' | pluralize(healthcenterCount) }}
        {{ 'partenaire' | pluralize(healthcenterCount) }}
      </h1>
      <healthcenter-group-healthcenter-row
        v-for="healthcenter in healthcenters"
        :key="healthcenter.id"
        :healthcenter="healthcenter"
        :owner-access-id="ownerAccessId"
        :group-id="groupId"
        @member-deleted="(healthcenterId) => $emit('member-deleted', healthcenterId)"
      />
    </base-card-body>
  </base-card>
</template>

<script>
import BaseCardBody from '@/renderer/components/base/BaseCardBody.vue'
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import HealthcenterGroupHealthcenterRow
from '@/renderer/app/disruption-logistics/components/healthcenter-groups/HealthcenterGroupHealthcenterRow.vue'

export default {
  name: 'HealthcenterGroupHealthcenters',
  components: { HealthcenterGroupHealthcenterRow, BaseCard, BaseCardBody },
  props: {
    /**
     * The owner access id of the group.
     *
     * @type {Number}
     */
    ownerAccessId: {
      type: Number,
      required: true
    },
    /**
     * The healthcenter group id.
     */
    groupId: {
      type: Number,
      required: true
    },
    /**
     * The healthcenter members.
     *
     * @type {HealthcenterGroupMember[]}
     */
    healthcenters: {
      type: Array,
      required: true
    }
  },
  computed: {
    /**
     * Get the healthcenter count.
     *
     * @returns {number}
     */
    healthcenterCount () {
      return this.healthcenters.length
    }
  }
}
</script>
