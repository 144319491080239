<template>
  <div class="bg-white shadow rounded-md sm:rounded-lg sm:overflow-hidden min-w-max">
    <div class="divide-y divide-gray-200">
      <div class="px-4 py-5 sm:px-6">
        <h2
          id="memberships-title"
          class="text-lg font-medium text-gray-900"
        >
          Cartes de fidélité
        </h2>
      </div>

      <div class="overflow-hidden">
        <div
          v-if="memberships.length"
          class="space-y-4 my-4"
        >
          <div
            v-for="membership in memberships"
            :key="membership.id"
            class="px-6 flex flex-row"
          >
            <div class="basis-20 flex-shrink-0">
              <img
                :src="!!membership.loyalty_card.file && membership.loyalty_card.file.url"
                class="rounded-lg max-w-24 min-w-24 min-h-24 max-h-24 md:max-w-36 md:min-w-36 md:max-h-36 md:min-h-36 lg:max-w-52 lg:min-w-52 lg:max-h-52 lg:min-h-52 border border-gray-200 flex-shrink-0"
                alt="Loyalty card"
              >
            </div>

            <div class="ml-6">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                {{ membership.loyalty_card.organization.name }}
              </h3>

              <p class="mt-1 text-sm text-gray-500">
                {{ membership.loyalty_card.name }}<br>
                Créé le {{ membership.formatted.created_at }}
              </p>
              <loyalty-card-membership-point-balance
                class="text-sm text-gray-500"
                :formatted_point_balance="membership.formatted.point_balance"
                :point_balance="membership.point_balance"
                :rate="membership.loyalty_card.point.rate"
              />

              <div class="mt-4">
                <button
                  type="button"
                  class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-dark mr-2 whitespace-nowrap"
                  @click="showMembership(membership)"
                >
                  Gérer la carte
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          v-else
          class="px-4 py-5"
        >
          <p class="text-sm font-medium text-gray-500 px-2">
            Aucune carte de fidélité active
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoyaltyCardMembershipPointBalance
from '@/renderer/app/loyalty-card-membership/components/loyalty-card-membership-point-balance/LoyaltyCardMembershipPointBalance.vue'

export default {
  components: { LoyaltyCardMembershipPointBalance },
  props: {
    memberships: {
      type: Array,
      required: true
    },
    customerId: {
      type: Number,
      required: true
    }
  },
  methods: {
    showMembership (membership) {
      this.$router.push({
        name: 'customer.loyalty.membership',

        params: {
          customerId: this.customerId,
          membershipId: membership.id
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
