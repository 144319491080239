<template>
  <BaseModal title="Conditions générales de vente">
    <iframe
      v-if="fullPath"
      class="w-full h-96"
      :src="fullPath"
    />

    <div class="mt-6 text-sm">
      {{ sanitizedText }}
    </div>

    <template v-slot:footer>
      <BaseButton @click="$emit('close')">
        Fermer
      </BaseButton>

      <BaseButton
        primary
        @click="$emit('validate')"
      >
        Valider
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import sanitizeHtml from 'sanitize-html'
import BaseModal from '@/renderer/components/base/BaseModal.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'

export default {
  components: {
    BaseModal,
    BaseButton
  },

  props: {
    laboratory: {
      type: Object,
      required: true
    }
  },

  computed: {
    fullPath () {
      return this.rendered
        ? this.path
        : `${process.env.VUE_APP_API_URL}/${this.laboratory.cgvPath}`
    },

    sanitizedText () {
      return sanitizeHtml(this.laboratory.cgvValidationText, {
        allowedTags: []
      })
    }
  }
}
</script>
