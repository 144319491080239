<template>
  <div class="flex justify-between items-center">
    <p>{{ consumer.information.name }}</p>
    <base-button
      :primary="true"
      @click="toggleSetting(consumer)"
    >
      {{ disabled === false ? "Désactiver la notification" : "Réactiver la notification" }}
    </base-button>
  </div>
</template>

<script>
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import { programResource } from '@/renderer/container'

export default {
  name: 'ProgramExcludedConsumerRow',
  components: { BaseButton },
  props: {
    consumer: {
      type: Object,
      required: true
    },
    supportProgramId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      disabled: true
    }
  },
  methods: {
    /**
     * Toggle the setting of a consumer.
     *
     * @param consumer The consumer to toggle the setting.
     */
    toggleSetting (consumer) {
      return programResource()
        .toggleConsumerSettings(this.supportProgramId, consumer.key)
        .then(() => {
          this.disabled = !this.disabled
        })
    }
  }
}
</script>
