<template>
  <div
    v-if="extensions.length"
    class="mt-5 mb-10"
  >
    <h3 class="mb-5 text-lg leading-6 font-medium text-gray-900">
      {{ title }}
    </h3>
    <ul
      v-if="isSupportGrid === false"
      role="list"
      class="flex flex-row justify-start flex-wrap ml-6"
    >
      <li
        v-for="extension in extensions"
        :key="extension.id"
        class="text-center flex flex-col cursor-pointer mr-20"
        @click="showProgram(extension)"
      >
        <ProgramLogo
          :program="extension"
        />
        <p
          class="text-gray-900 text-sm font-medium text-left break-words mt-2"
          style="max-width: 100px"
        >
          {{ extension.name }}
        </p>
      </li>
    </ul>
    <div
      v-else
      class="grid lg:grid-cols-4 grid-cols-2 gap-x-16 gap-y-8"
    >
      <div
        v-for="extension in extensions"
        :key="extension.id"
        class="col-span-1 mb-5"
        @click="showProgram(extension)"
      >
        <SupportProgramHomeRow :program="extension" />
      </div>
    </div>
  </div>
</template>
<script>

import ProgramLogo from '@/renderer/app/program/components/program-logo/ProgramLogo.vue'
import SupportProgramHomeRow from '@/renderer/app/program/components/support-program-home-row/SupportProgramHomeRow.vue'

/**
 * ProgramGrid component.
 */
export default {
  name: 'ExtensionGrid',

  components: {
    SupportProgramHomeRow,
    ProgramLogo
  },

  props: {
    /**
     * The list of programs.
     */
    title: {
      type: [String],
      required: true
    },
    extensions: {
      type: Array,
      required: true
    }
  },
  computed: {
    isSupportGrid () {
      return this.extensions.every(extension => extension.sub_type === 'support')
    }
  },
  methods: {
    showProgram (extension) {
      if (extension.program_bundle?.programs.length > 1) {
        this.$router.push({
          name: 'program-bundle.detail',
          params: {
            programBundleId: extension.program_bundle.id
          }
        })
        return
      }

      if (extension.program_bundle?.programs.length === 1) {
        const id = extension.program_bundle.programs[0].id

        this.$router.push({
          name: 'program.detail.content',
          params: {
            programId: id
          }
        })
        return
      }

      this.$router.push({
        name: 'program.detail.content',
        params: {
          programId: extension.extendable_id
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
