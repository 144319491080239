<template>
  <div>
    <div class="flex flex-row align-center mb-4">
      <img
        :src="require('@/assets/images/icons/calendar.svg')"
        alt="Calendar"
        class="mr-4"
        width="40"
        height="40"
      >
      <p class="text-primary mt-1">
        J'indique à {{ order?.customer.information.name }} quel jour il peut venir chercher sa commande
      </p>
    </div>
    <div class="flex justify-center mt-2">
      <v-date-picker
        v-model="date"
        :min="now"
        :max="maxDay"
        locale="fr-fr"
      />
      <v-time-picker
        ref="picker"
        v-model="hour"
        format="24hr"
        class="use-hours-only ml-5"
        @click:hour="onHourClick"
      />
    </div>
    <div class="flex justify-center mt-2 h-16">
      <base-button
        v-if="date"
        primary
        class="my-2"
        @click="validate"
      >
        Valider la date et l'heure
      </base-button>
    </div>
    <div class="flex mt-10">
      <p
        class="text-primary p-2 shadow rounded-lg cursor-pointer"
        @click="goBack"
      >
        Retour
      </p>
    </div>
  </div>
</template>

<script>

import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import Icon from '@/renderer/app/core/icon/Icon.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import dayjs from 'dayjs'

export default {
  name: 'OrderDate',
  components: { BaseSpinner, Icon, BaseButton },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      now: dayjs().format('YYYY-MM-DD'),
      maxDay: dayjs().add(1, 'year').format('YYYY-MM-DD'),
      date: null,
      hour: '12:00'
    }
  },
  methods: {
    /**
     * Validate the date selection.
     */
    validate () {
      if (this.date) {
        const value = dayjs(this.date + 'T' + this.hour).format('YYYY-MM-DD HH:mm:ss')
        this.$emit('validate', value)
      }
    },
    /**
     * Function that help us to not choose minutes on the picker once an hour has been selecting. Since
     * our goal is for the user to only select one hour.
     */
    onHourClick () {
      this.$nextTick(() => {
        this.$refs.picker.selectingHour = true
      })
    },
    goBack () {
      this.$emit('back')
    }
  }
}
</script>

<style>
.use-hours-only {
  .v-time-picker-title {
    pointer-events: none;
  }
}
</style>
