/**
 * The laboratory product resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Get all laboratory products.
     * @param {number} laboratoryId - The ID of the laboratory.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    get (laboratoryId) {
      return client
        .get(`/laboratories/${laboratoryId}/ranges/products/paginatedWithNeeds`, {
          params: {
            include: 'laboratoryPriceRanges.laboratoryPrice'
          }
        })
        .then(response => response.data.data)
    },

    /**
     * Read a laboratory product
     * @param {number} productId - The ID of the product.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    read (productId) {
      return client
        .get(`/laboratories/products/${productId}`, {
          params: {
            include: 'override,needs,laboratoryProductPrices,laboratoryPriceRanges.laboratoryPrice'
          }
        })
        .then(response => response.data.product)
    }
  }
}
