<template>
  <div class="p-5 bg-white rounded-md flex flex-col border-2 border-primary">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'InterviewContainer'
}
</script>
