<template>
  <div class="w-full flex-1">
    <BaseFlexSpinner v-if="loading" />
    <div v-else-if="!enabledProgramExtensions?.length">
      <div class="text-primary flex flex-row py-10 justify-center items-center font-bold">
        <div>
          <StoreIcon />
        </div>
        <p class="ml-4 text-center">
          Parcourez le store depuis Apodis PRO afin d'activer de nouveaux programmes !
        </p>
      </div>
    </div>
    <div v-else>
      <ExtensionGrid
        :title="'Programmes'"
        :extensions="filterProgramType('support')"
      />
      <ExtensionGrid
        :title="'Services'"
        :extensions="filterProgramType('feature')"
      />
      <ExtensionGrid
        :title="'Offres Commerciales'"
        :extensions="filterProgramType('discount')"
      />
      <ExtensionGrid
        :title="'Cartes de Fidélité'"
        :extensions="filterProgramType('loyalty-card')"
      />
    </div>
  </div>
</template>

<script>
import ExtensionGrid from '@/renderer/app/program/components/program-grid/ExtensionGrid.vue'
import StoreIcon from '@/renderer/app/core/components/store-icon/StoreIcon.vue'
import BaseFlexSpinner from '@/renderer/components/base/spinner/BaseFlexSpinner.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapState, mapActions } = createNamespacedHelpers('extensions')

/**
 * Smart component for enabled programs.
 */
export default {
  name: 'EnabledProgramGrid',

  components: {
    BaseFlexSpinner,
    StoreIcon,
    ExtensionGrid
  },
  computed: {
    ...mapState(['loading']),
    ...mapGetters(['enabledProgramExtensions'])
  },
  mounted () {
    if (!this.loading) {
      this.refreshExtensions()
    }
  },
  methods: {
    ...mapActions(['refreshExtensions']),
    filterProgramType (type) {
      return this.enabledProgramExtensions
        .filter(extension => extension.sub_type === type)
    }
  }
}
</script>

<style scoped>

</style>
