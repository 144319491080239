<template>
  <div class="bg-white p-4 rounded-xl shadow-md flex justify-between relative w-80 cursor-pointer">
    <img
      alt="program-category-illustration"
      class="w-20 h-20 object-contain mr-4"
      :src="category.illustration_url"
    >
    <div class="flex flex-col flex-1">
      <span
        class="text-lg font-medium line-clamp-2 flex flex-1"
        style="overflow: hidden; text-overflow: ellipsis;"
      >{{ category.name }}</span>
      <span class="text-3xl font-extrabold mt-2">{{ patientCount }} <span class="text-lg font-semibold mr-4">Patients</span></span>
    </div>
    <svg
      class="w-6 h-6 text-gray-500"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M9 5l7 7-7 7"
      />
    </svg>
    <pathocurve
      class="absolute bottom-0 right-0 rounded-b-xl"
      :style="`color: ${category.color}`"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import Pathocurve from '@/assets/images/icons-vue/pathocurve.vue'

export default defineComponent({
  name: 'ProgramCategoryCard',
  components: { Pathocurve },
  props: {
    /**
     * The category to display.
     */
    category: {
      type: Object,
      required: true
    },
    /**
     * The number of patients included in the category.
     */
    patientCount: {
      type: Number,
      required: true
    }
  }
})
</script>
