<template>
  <v-stepper
    class="w-full"
    non-linear
    :value="defaultStepIndex"
  >
    <v-stepper-header>
      <v-stepper-step
        editable
        step="1"
        edit-icon="mdi-check"
        :complete="isApodisSecureCompleted"
      >
        Apodis Secure
      </v-stepper-step>
      <v-stepper-step
        editable
        step="2"
        edit-icon="mdi-check"
        :complete="isApodisProCompleted"
      >
        Apodis Pro
      </v-stepper-step>
      <v-stepper-step
        editable
        step="3"
        edit-icon="mdi-check"
        :complete="isApodisConnectCompleted"
      >
        Apodis Connect
      </v-stepper-step>
    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content step="1">
        <apodis-secure-step
          :lgo-name="lgoName"
          :lgo-install="lgoInstall"
          @downloadApodisSecure="downloadApodisSecure"
          @openGuide="$emit('openGuide', santeSecureGuideId)"
        />
      </v-stepper-content>
      <v-stepper-content step="2">
        <apodis-pro-step
          @openAppStore="openAppStore"
          @openPlayStore="openPlayStore"
          @openGuide="$emit('openGuide', apodisProGuideId)"
        />
      </v-stepper-content>
      <v-stepper-content step="3">
        <apodis-connect-step
          @downloadApodisConnect="downloadApodisConnect"
          @openGuide="openGuide(apodisConnectGuideId)"
        />
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { defineComponent } from 'vue'
import ApodisSecureStep from '@/renderer/components/views/onboarding/installation-process/ApodisSecureStep.vue'
import ApodisConnectStep from '@/renderer/components/views/onboarding/installation-process/ApodisConnectStep.vue'
import ApodisProStep from '@/renderer/components/views/onboarding/installation-process/ApodisProStep.vue'
import {
  contentfulService
} from '@/renderer/container'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'InstallationProcessTab',
  components: { ApodisProStep, ApodisConnectStep, ApodisSecureStep },
  data: () => ({
    santeSecureGuideId: contentfulService().getSanteSecureGuideId(),
    apodisProGuideId: contentfulService().getProGuideId(),
    apodisConnectGuideId: contentfulService().getConnectGuideId()
  }),
  props: {
    accessPoint: {
      type: Object,
      required: false
    }
  },
  methods: {
    downloadApodisSecure () {
      const link = 'https://files.santesecure.com/sante-secure-installer.exe'
      window.open(link)
    },
    downloadApodisConnect () {
      const link = 'https://files.santesecure.com/connect/apodis-connect-Setup-latest-ia32.exe'
      window.open(link)
    },
    openAppStore () {
      const link = 'https://itunes.apple.com/app/id1580581211'
      window.open(link)
    },

    openPlayStore () {
      const link = 'https://play.google.com/store/apps/details?id=com.apodis.pro'
      window.open(link)
    },
    openGuide (guideId) {
      this.$emit('openGuide', guideId)
    }
  },
  computed: {
    ...mapGetters('website', ['santeSecure', 'hosts', 'mobiles']),
    isApodisSecureCompleted () {
      return this.santeSecure && !!this.santeSecure.lastLgoPing
    },
    isApodisConnectCompleted () {
      return this.hosts && this.hosts.length > 0
    },
    isApodisProCompleted () {
      return this.mobiles && this.mobiles.length > 0
    },
    defaultStepIndex () {
      if (!this.isApodisSecureCompleted) {
        return 1
      }

      if (!this.isApodisProCompleted) {
        return 2
      }

      if (!this.isApodisConnectCompleted) {
        return 3
      }

      return 3
    },
    lgoName: function () {
      const lgo = this.accessPoint && this.accessPoint.entity.lgo
      switch (lgo) {
        case 1:
          return 'Actipharm (MSI 2000)'
        case 2:
          return 'Alliadis / SmartRX'
        case 3:
          return 'Axiopharm'
        case 4:
          return 'Caduciel'
        case 5:
          return 'CIP Primoris'
        case 6:
          return 'CIP-GS (Pharmagest)'
        case 7:
          return 'Crystal (Infosoft)'
        case 8:
          return 'Data conseil (Alliadis)'
        case 9:
          return 'Esculape'
        case 10:
          return 'LEO Isipharm'
        case 11:
          return 'LGPI (Pharmagest)'
        case 12:
          return 'Logipharm'
        case 13:
          return 'Magic Vente'
        case 14:
          return 'Opus (PG Informatique)'
        case 15:
          return 'Pharmaland'
        case 16:
          return 'Pharmavitale Cepi'
        case 17:
          return 'Primoris (Pharmagest)'
        case 18:
          return 'Périphar (Aspline)'
        case 19:
          return 'Vindilis'
        case 20:
          return 'Visiosoft (Visiopharm)'
        case 21:
          return 'Winpharma (Everys)'
        default:
          return ''
      }
    },
    lgoInstall: function () {
      const lgo = this.accessPoint && this.accessPoint.entity.lgo
      switch (lgo) {
        case 11: // LGPI
        case 21: // Winpharma
          return 'client'
        case 10: // LEO
        case 16: // Pharmavitale
          return 'server'
        case 2: // SmartRX
        case 15: // Pharmaland
        default:
          return 'support'
      }
    }
  }
})
</script>

<style scoped>
.v-stepper {
  border-radius: 8px;
  box-shadow: none !important;
}

.v-stepper__header {
  @apply shadow-none h-auto flex-col md:flex-row;
}

::v-deep .v-stepper__step__step {
  @apply text-2xl lg:text-3xl xl:text-6xl w-auto h-auto bg-transparent border-0;
}

::v-deep .v-stepper__step {
  @apply bg-transparent border-0 flex flex-1 px-5 py-2 pb-0 self-stretch;
  color: black !important;
  border: 1px solid theme('colors.primary.DEFAULT') !important;
}

::v-deep .v-stepper__step:first-of-type {
  border-top-left-radius: 8px;
}

::v-deep .v-stepper__step:last-of-type {
  @apply md:rounded-tr-lg
}

@media (min-width: theme('screens.md')) {
  ::v-deep .v-stepper__step:nth-child(2) {
    border-left: 0 solid black !important;
    border-right: 0 solid black !important;
  }
}

::v-deep .v-stepper__step--editable:hover {
  background-color: theme('colors.lightPurple.DEFAULT') !important;
}

::v-deep .v-stepper__step--active {
  font-weight: bolder;
  @apply bg-lightPurple-lightest;
  border-bottom-color: transparent !important;
}

::v-deep .v-stepper__step__step {
  color: black !important;
}

::v-deep .v-stepper__step > span {
  background-color: transparent !important;
  margin-right: 5px !important;
}

::v-deep .v-stepper__label {
  @apply text-base xl:text-xl ml-2;
  color: black !important;
}

::v-deep .v-stepper__content {
  @apply p-0;
}

::v-deep .v-stepper__items {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  overflow: hidden;
}

::v-deep .v-stepper__step--complete > span > i {
  background-color: theme('colors.secondary.DEFAULT') !important;
  @apply rounded-full
}

::v-deep .v-stepper__step--complete > .v-stepper__step__step > .v-icon {
  font-size: 2.5rem !important;
}

::v-deep .v-stepper__step--complete {
  @apply items-center p-4;
  color: theme('colors.primary.DEFAULT') !important;
}

::v-deep .v-stepper__step--complete > .v-stepper__label {
  color: theme('colors.primary.DEFAULT') !important;
}
</style>
