<template>
  <div class="m-4">
    <ProgramHeader
      v-if="program"
      :program="program"
      class="mb-5"
    />

    <BaseSection>
      <BaseCard>
        <BaseImage
          rendered
          :text="instantDiscountVoucher.name"
          :path="instantDiscountVoucher.image.renderPath"
          class="w-full h-96 border-b border-gray-200"
        />

        <BaseCardBody>
          <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div class="sm:col-span-2">
              <dt class="text font-medium">
                Description
              </dt>

              <dd class="mt-1 text-sm text-gray-900">
                {{ instantDiscountVoucher.description }}
              </dd>
            </div>
          </dl>
        </BaseCardBody>
      </BaseCard>

      <BaseCard>
        <BaseCardHeader>
          Règles
        </BaseCardHeader>

        <BaseCardBody>
          <ul class="list-disc list-inside">
            <li
              v-for="reward in instantDiscountVoucher.rewards"
              :key="reward.id"
              class="text-sm"
            >
              {{ reward.teaser }}
            </li>
          </ul>

          <div class="mt-4">
            <h3 class="text-sm mb-1 font-bold">
              Description du fonctionnement
            </h3>

            <p class="text-sm">
              Enregistrez la vente comme d’habitude dans votre LGO avec la réduction correspondante,
              Apodis récupèrera la vente et la transmettra au laboratoire afin qu’il puisse vous
              rembourser les montants concernés.
            </p>
          </div>
        </BaseCardBody>
      </BaseCard>

      <BaseCard>
        <BaseCardHeader>
          Produits ciblés par l’offre
        </BaseCardHeader>

        <ul
          role="list"
          class="divide-y divide-gray-200"
        >
          <li
            v-for="product in instantDiscountVoucher.productsTrigger"
            :key="product.id"
          >
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="min-w-0 flex-1 flex items-center">
                <div class="flex-shrink-0">
                  <BaseImage
                    v-if="!!product.fullPhotoPath"
                    rendered
                    class="h-20 w-20 rounded-lg"
                    :text="product.name"
                    :path="product.fullPhotoPath"
                  />
                  <BaseImage
                    v-else
                    rendered
                    class="h-20 w-20 rounded-lg"
                    :text="product.name"
                    :path="require('@/assets/images/no-product-image.png')"
                  />
                </div>

                <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-1 md:gap-4">
                  <div>
                    <p class="text-sm font-medium">
                      {{ product.name }}
                    </p>

                    <p class="mt-1 flex items-center text-sm text-gray-500">
                      {{ product.code }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </BaseCard>
    </BaseSection>
  </div>
</template>

<script>
import {
  instantDiscountVoucherResource, programResource
} from '@/renderer/container'

import BaseCard from '@/renderer/components/base/BaseCard.vue'
import BaseImage from '@/renderer/components/base/BaseImage.vue'
import BaseSection from '@/renderer/components/base/BaseSection.vue'
import BaseCardBody from '@/renderer/components/base/BaseCardBody.vue'
import BaseCardHeader from '@/renderer/components/base/BaseCardHeader.vue'
import ProgramHeader from '@/renderer/app/program/components/program-header/ProgramHeader.vue'

export default {
  components: {
    ProgramHeader,
    BaseCard,
    BaseImage,
    BaseSection,
    BaseCardBody,
    BaseCardHeader
  },

  data () {
    return {
      instantDiscountVoucher: {
        image: {},
        formatted: {}
      },
      program: {
        type: Object
      }
    }
  },

  mounted () {
    instantDiscountVoucherResource()
      .read(this.$route.params.discountId)
      .then(instantDiscountVoucher => {
        this.instantDiscountVoucher = instantDiscountVoucher
      })
      .then(() => programResource().indexActivatedPrograms())
      .then(programs => {
        const discountProgram = programs.find(program => program.programmable_id === this.instantDiscountVoucher.id &&
          program.programmable_type === 'discount')
        if (discountProgram) {
          return programResource().read(discountProgram.id)
        }
        return null
      })
      .then(program => {
        if (program) {
          this.program = program
        }
      })
  }
}
</script>
