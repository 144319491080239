<template>
  <div>
    <div class="w-full">
      <nav
        class="flex space-x-4"
        aria-label="Tabs"
      >
        <router-link
          :to="{ name: 'disruption.dashboard' }"
          active-class="bg-secondary text-primary hover:text-primary font-bold"
          class="text-xs sm:text-xs md:text-sm bg-white text-black-500 hover:text-gray-700 px-3 py-2 font-medium rounded-full shadow"
        >
          Dashboard
        </router-link>

        <router-link
          :to="{ name: 'disruption.order' }"
          active-class="bg-secondary text-primary hover:text-primary font-bold"
          class="text-xs sm:text-xs md:text-sm bg-white text-black-500 hover:text-gray-700 px-3 py-2 font-medium rounded-full shadow"
        >
          Analyse des commandes
        </router-link>

        <router-link
          :to="{ name: 'disruption.offer' }"
          active-class="bg-secondary text-primary hover:text-primary font-bold"
          class="text-xs sm:text-xs md:text-sm bg-white text-black-500 hover:text-gray-700 px-3 py-2 font-medium rounded-full shadow"
        >
          Offres de dépannage
        </router-link>

        <router-link
          :to="{ name: 'disruption.pharmaml' }"
          active-class="bg-secondary text-primary hover:text-primary font-bold"
          class="text-xs sm:text-xs md:text-sm bg-white text-black-500 hover:text-gray-700 px-3 py-2 font-medium rounded-full shadow"
        >
          Retour PharmaML
        </router-link>
      </nav>
    </div>
    <div class="w-full">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  mounted () {
    const token = localStorage.getItem('token')

    if (!token) {
      this.$router.push({
        name: 'login'
      })
    }

    this.$router.push({
      name: 'disruption.dashboard'
    })
  }
}
</script>
