<template>
  <div class="flex flex-col lg:flex-row gap-x-14 gap-y-6">
    <div class="flex flex-col">
      <label
        class="font-content-bold text-4xl"
      >{{ availabilityCount ?? '0' }}</label>
      <label>{{ 'agence' | pluralize(availabilityCount) }} ont ce produit</label>
    </div>
    <div class="flex flex-col text-primary">
      <label class="font-content-bold text-4xl">{{ membersAvailabilityCount ?? '0' }}</label>
      <label>
        {{ 'pharmacie' | pluralize(membersAvailabilityCount) }} {{ 'partenaire' | pluralize(membersAvailabilityCount) }}
        peuvent commander ce produit
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DistributorMapStatistics',
  props: {
    /** @type {{warehouse: WholesalerWarehouse; products: DistributorProductAvailability[]; members: HealthcenterGroupMember[]}} */
    availabilities: {
      type: Array,
      required: false,
      default: () => null
    }
  },
  computed: {
    /**
     * Get the count of the available warehouses.
     *
     * @return {number}
     */
    availabilityCount () {
      return this.availableAvailabilities?.length ?? 0
    },
    /**
     * Count the members who can order the product to his warehouses.
     *
     * @return {number|null}
     */
    membersAvailabilityCount () {
      if (!this.availabilities?.length) {
        return null
      }

      const members = this.availableAvailabilities.map(warehouse => warehouse.members).flat()

      const uniqueMembers = members.filter((member, index, self) => {
        return index === self.findIndex(healthcenter => (
          healthcenter.id === member.id
        ))
      })

      return uniqueMembers.length
    },
    /**
     * Get the available availabilities.
     *
     * @return {Array}
     */
    availableAvailabilities () {
      return this.availabilities?.filter(availability => {
        return availability.products?.some(product => product.isAvailable)
      }) ?? []
    }
  }
}
</script>

<style scoped>

</style>
