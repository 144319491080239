import Modal from '@/renderer/app/interview/views/InterviewInstanceModal'

const InterviewInstanceModalPlugin = {
  install (Vue, options) {
    this.EventBus = new Vue()

    Vue.component('interview-instance-modal', Modal)

    Vue.prototype.$interviewInstanceModal = {
      start (params) {
        InterviewInstanceModalPlugin.EventBus.$emit('start', params)
      }
    }
  }
}

export default InterviewInstanceModalPlugin
