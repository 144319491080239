<template>
  <base-container>
    <header-back-button title="Info dispo" />
    <div class="p-6 flex flex-col gap-8">
      <div>
        <product-search-bar replace-route />
        <h1 class="text-xl font-content-bold text-primary">
          {{ product.name }} <span class="text-sm text-black font-content">{{ product.code }}</span>
        </h1>
      </div>
      <product-availability-card-grid
        :product-id="Number($route.params.productId)"
        :request-token="$route.query.requestToken"
        @availability-loaded="handleAvailabilityData"
      />
      <healthcenter-group-availability-map
        :product="product"
        :data="healthcenterGroups"
      />
      <distributor-availability-map
        :product="product"
        :data="groupWholesalers"
      />
      <product-availability-statistics-card
        :product-id="Number($route.params.productId)"
        :request-token="$route.query.requestToken"
      />
    </div>
  </base-container>
</template>

<script>
import BaseContainer from '@/renderer/components/base/BaseContainer.vue'
import ProductSearchBar from '@/renderer/app/disruption-logistics/components/product-searchbar/ProductSearchBar.vue'
import ProductAvailabilityCardGrid
from '@/renderer/app/disruption-logistics/components/product-availability-card/ProductAvailabilityCardGrid.vue'
import HeaderBackButton from '@/renderer/app/core/components/header-back-button/HeaderBackButton.vue'
import HealthcenterGroupAvailabilityMap
from '@/renderer/app/disruption-logistics/components/maps/healthcenter-groups/HealthcenterGroupAvailabilityMap.vue'
import DistributorAvailabilityMap
from '@/renderer/app/disruption-logistics/components/maps/distributors/DistributorAvailabilityMap.vue'
import ProductAvailabilityStatisticsCard
from '@/renderer/app/disruption-logistics/components/availability-statistics/ProductAvailabilityStatisticsCard.vue'
import { AVAILABILITY_PROVIDERS } from '@/renderer/http/resources/DisruptionLogisticsResource'

export default {
  name: 'ProductAvailabilityPage',
  components: {
    ProductAvailabilityStatisticsCard,
    DistributorAvailabilityMap,
    HealthcenterGroupAvailabilityMap,
    HeaderBackButton,
    ProductAvailabilityCardGrid,
    ProductSearchBar,
    BaseContainer
  },
  data () {
    return {
      groupWholesalers: null,
      healthcenterGroups: null
    }
  },
  computed: {
    product () {
      return {
        id: this.$route.params.productId,
        name: this.$route.query.productName,
        code: this.$route.query.productCode
      }
    }
  },
  watch: {
    '$route.params.productId': {
      immediate: true,
      handler () {
        this.groupWholesalers = null
        this.healthcenterGroups = null
      }
    }
  },
  methods: {
    /**
     * Handle the product selection
     *
     * @param {Object} product - The selected product
     */
    handleProductSelection (product) {
      if (!product) {
        return
      }

      this.$router.replace({
        name: 'disruption-logistics.product-availability',
        params: { productId: product.id },
        query: { productName: product.name }
      })
    },
    handleAvailabilityData ({ provider, data }) {
      if (provider === AVAILABILITY_PROVIDERS.healthcenterGroupsDistributor) {
        this.groupWholesalers = data.details
      }

      if (provider === AVAILABILITY_PROVIDERS.healthcenterGroups) {
        this.healthcenterGroups = data.details
      }
    }
  }
}
</script>
