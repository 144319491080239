<template>
  <div>
    <base-card class="relative overflow-visible m-5 cursor-pointer hover:opacity-50">
      <base-card-body>
        <div
          class="flex flex-row align-center"
          @click="onClick"
        >
          <div class="w-24 h-24">
            <secure-image
              :urls="prescription.files"
              :size="24"
            />
          </div>
          <div class="flex flex-1 justify-between align-center ml-4">
            <div>
              <p class="text-sm">
                Dr. {{ prescription.professional.last_name }}
              </p>
              <p>Ordonnance du {{ prescription.created_at | date('short') }}</p>
            </div>
          </div>
          <prescription-renewal-button />
        </div>
      </base-card-body>
    </base-card>
  </div>
</template>

<script>
import SecureImage from '@/renderer/app/core/components/secure-image/SecureImage.vue'
import BaseCardBody from '@/renderer/components/base/BaseCardBody.vue'
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import PrescriptionRenewalButton from '@/renderer/app/prescription/components/PrescriptionRenewalButton.vue'

export default {
  name: 'PrescriptionCard',
  components: { PrescriptionRenewalButton, BaseCard, BaseCardBody, SecureImage },
  props: {
    prescription: {
      type: Object,
      required: true
    }
  },
  methods: {
    onClick () {
      this.$emit('on-card-press', this.prescription)
    }
  }
}
</script>
