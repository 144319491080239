import { disruptionLogisticsApi } from '@/renderer/container'

export default {
  namespaced: true,
  state: {
    /**
     * The remaining request for the disruption logistics.
     *
     * @type {number|null}
     */
    remainingRequests: null,
    /**
     * The loading states.
     *
     * @type {boolean}
     */
    loading: false
  },
  mutations: {
    /**
     * Set the remaining requests.
     *
     * @param {Object} state
     * @param {number} remainingRequests
     */
    setRemainingRequests (state, remainingRequests) {
      state.remainingRequests = remainingRequests
    },
    /**
     * Set the loading state.
     *
     * @param {Object} state
     * @param {boolean} loading
     */
    setLoading (state, loading) {
      state.loading = loading
    },
    /**
     * Decrease the remaining requests by one.
     *
     * @param state
     */
    decreaseRemainingRequests (state) {
      state.remainingRequests -= 1
    }
  },
  actions: {
    /**
     * Load the remaining requests in the store.
     *
     * @param {Function} commit
     *
     * @return {Promise<void>}
     */
    loadRemainingRequests ({ commit }) {
      commit('setLoading', true)
      return disruptionLogisticsApi()
        .remainingRequests()
        .then(response => {
          commit('setRemainingRequests', response.remaining)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    }
  },
  getters: {
    /**
     * Whether the user is subscribed to the disruption logistics.
     *
     * @param state
     * @param getters
     * @param rootState
     * @param rootGetters
     * @return {*|boolean}
     */
    subscribed: (state, getters) => {
      return getters.logisticsDisruptionExtension?.enabled ?? false
    },
    /**
     * Get the disruption logistics extension.
     *
     * @param state
     * @param getters
     * @param rootState
     * @param rootGetters
     * @return {Extension}
     */
    logisticsDisruptionExtension: (state, getters, rootState, rootGetters) => {
      return rootGetters['extensions/extensionBySlug']('disruption-logistics')
    }
  }
}
