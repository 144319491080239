<template>
  <base-container>
    <h3 class="text-primary font-bold text-lg">
      Vérifiez et complétez le questionnaire
    </h3>
    <p>Certaine réponses sont déjà renseignées grâce à l'IA et l'audio enregistré lors de l'entretien.</p>
    <interview-container class="mt-4">
      <base-spinner
        v-if="loading"
        class="mx-auto mt-4"
      />
      <div v-else-if="survey">
        <interview-survey-built
          :survey="survey"
          :answers="answers"
          :errors="errors"
          @change="handleChange"
        />
        <base-button
          class="float-right mt-6 font-bold"
          :disabled="submitting"
          :primary="true"
          @click="submit"
        >
          <base-spinner
            v-if="submitting"
            class="mx-auto"
            size="button"
            color="white"
          />
          <span v-else>
            Enregister les réponses
          </span>
        </base-button>
      </div>
    </interview-container>
  </base-container>
</template>

<script>
import BaseContainer from '@/renderer/components/base/BaseContainer.vue'
import InterviewContainer from '@/renderer/app/interview/components/instance-modal/InterviewContainer.vue'
import { surveyApi } from '@/renderer/container'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import SurveyBuiltSection from '@/renderer/app/survey/components/SurveyBuiltSection.vue'
import InterviewSurveyBuilt from '@/renderer/app/interview/components/instance-modal/InterviewSurveyBuilt.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('interview')

export default {
  name: 'InterviewSurvey',
  components: {
    BaseButton, InterviewSurveyBuilt, SurveyBuiltSection, BaseSpinner, InterviewContainer, BaseContainer
  },
  props: {
    surveyId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      /** The survey. */
      survey: null,
      /** Whether the survey is loading. */
      surveyLoading: false,
      /** Whether the survey is submitting. */
      submitting: false,
      /** The answer values. */
      userAnswerValues: {},
      /** Whether the form has been submitted. */
      dirty: false
    }
  },
  /**
   * On mount, fetch the interview survey.
   */
  mounted () {
    this.readSurvey()
  },
  methods: {
    /**
     * Read the survey.
     */
    readSurvey () {
      this.surveyLoading = true
      surveyApi()
        .readBuiltSurvey(this.surveyId)
        .then((response) => {
          this.survey = response

          this.initAnswers(response)
        })
        .finally(() => {
          this.surveyLoading = false
        })
    },
    /**
     * Initialize the answer values.
     *
     * @param {Object} survey
     */
    initAnswers (survey) {
      const questions = survey.sections.map(section => section.questions)
        .flat()

      this.userAnswerValues = questions.reduce((acc, question) => {
        acc[question.id] = this.llmAnswers[question.id] ?? []

        return acc
      }, {})
    },
    /**
     * On value change, update the answer values.
     *
     * @param {*} value The new value.
     * @param {Number} questionId The corresponding question id.
     */
    handleChange ({ value, questionId }) {
      this.userAnswerValues[questionId] = [value]
    },
    /**
     * Submit the survey.
     * If callback is a promise, set submitting to true and finally to false.
     */
    submit () {
      if (this.$listeners.submit) {
        this.dirty = true

        if (this.hasErrors()) {
          return
        }

        const callback = this.$listeners.submit(this.answers)

        if (callback instanceof Promise) {
          this.submitting = true

          callback.finally(() => {
            this.submitting = false
          })
        }
      }
    },
    /**
     * Determine if the form has errors.
     *
     * @returns {boolean}
     */
    hasErrors () {
      return Object.keys(this.errors)
        .some(key => this.errors[key] !== undefined)
    }
  },
  computed: {
    ...mapState({
      transcriptIsLoading: state => state.loading.transcript,
      llmIsLoading: state => state.loading.llmSurveyAnswers,
      llmAnswers: state => state.llmSurveyAnswers?.reduce((acc, answer) => {
        acc[answer.question_id] = answer.answer

        return acc
      }, {}) ?? {}
    }),
    /**
     * Get the final answers.
     * If a user answer has been provided, use it, otherwise use the LLM answer.
     *
     * @returns {Object}
     */
    answers () {
      return Object.keys(this.userAnswerValues)
        .reduce((acc, key) => {
          acc[key] = this.userAnswerValues[key].length ? this.userAnswerValues[key] : this.llmAnswers[key] ?? []

          return acc
        }, {})
    },
    /**
     * Get the errors from the answers.
     *
     * @returns {Object} An object containing the errors.
     */
    errors () {
      if (!this.survey || !this.dirty) {
        return {}
      }

      return this.survey.sections.map(section => section.questions)
        .flat()
        .reduce((acc, question) => {
          const answer = this.answers[question.id]?.filter(value => {
            return value !== null && value !== undefined && value !== ''
          })

          if (!question.optional) {
            acc[question.id] = !answer.length ? 'Ce champ est obligatoire' : undefined
          }

          return acc
        }, {})
    },
    /**
     * Determine whether data are still loading.
     *
     * @returns {boolean}
     */
    loading () {
      return this.surveyLoading || this.transcriptIsLoading || this.llmIsLoading
    }
  }
}
</script>
