<template>
  <div class="p-3 flex flex-col">
    <base-flex-spinner v-if="loading" />
    <div v-else-if="!depositaries?.length">
      <p class="text-center">
        Aucune donnée à afficher. Veuillez nous contacter pour configurer vos disponibilités
      </p>
    </div>
    <div
      v-else
      class="flex flex-col gap-3"
    >
      <button
        v-for="depositary in depositaries"
        :key="depositary.id"
        class="bg-white rounded-lg shadow p-3 gap-2 text-primary text-lg"
        @click="$router.push({
          name: 'disruption-logistics.direct-availabilities',
          params: {sourceId: depositary.id},
          query: {sourceName: depositary.name, phone: depositary.parent.contact?.phone, email: depositary.parent.contact?.email},
        })"
      >
        {{ depositary.name }}
      </button>
    </div>
  </div>
</template>

<script>
import { disruptionLogisticsApi } from '@/renderer/container'
import BaseFlexSpinner from '@/renderer/components/base/spinner/BaseFlexSpinner.vue'

export default {
  name: 'DirectAvailabilitySourcePage',
  components: { BaseFlexSpinner },
  data () {
    return {
      depositaries: null,
      loading: false
    }
  },
  mounted () {
    this.loading = true

    this.getDepositaries()
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    /**
     * Load the depositaries
     *
     * @return {Promise<void>}
     */
    getDepositaries () {
      return disruptionLogisticsApi()
        .availabilitySources()
        .then((response) => {
          this.depositaries = response.filter((source) => source.type === 'depositary')
        })
    }
  }
}
</script>

<style scoped>

</style>
