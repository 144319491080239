<template>
  <BaseCard>
    <BaseCardBody>
      <p class="font-heading text-xl text-primary">
        Déroulement du parcours
      </p>
      <div>
        <v-timeline
          dense
          class="flex flex-col items-start"
        >
          <v-timeline-item
            color="secondary"
            icon="mdi-flag"
            fill-dot
          >
            <v-card
              class="flex justify-center"
            >
              <v-card-text class="text-title text-primary font-bold">
                Détection du patient concerné au comptoir
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            v-for="step in pathway.steps"
            :key="step.id"
            fill-dot
          >
            <template v-slot:icon>
              <span class="font-content text-white font-bold">{{ step.index }}</span>
            </template>
            <v-card>
              <v-card-title class="font-heading">
                {{ step.title }}
              </v-card-title>
              <v-card-subtitle>
                <markdown
                  :content="step.instructions"
                  class="text-sm"
                  inherit-styles
                />
              </v-card-subtitle>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            icon="mdi-check"
            fill-dot
            color="secondary"
          >
            <v-card
              class="flex justify-center"
            >
              <v-card-text class="text-title text-primary font-bold">
                Fin du parcours
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </div>
    </BaseCardBody>
  </BaseCard>
</template>

<script>
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import BaseCardBody from '@/renderer/components/base/BaseCardBody.vue'
import Markdown from '@/renderer/app/core/components/markdown/Markdown.vue'

export default {
  name: 'SupportProgramPathwayTimeline',
  components: { BaseCardBody, BaseCard, Markdown },
  props: {
    pathway: {
      type: Object,
      required: true
    }
  }
}
</script>
