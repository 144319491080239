<template>
  <div v-if="!loading">
    <div class="bg-white px-4 text-primary py-2 flex items-center font-bold">
      <Icon
        class="cursor-pointer"
        name="arrow-left"
        :size="24"
        @click.native="$router.go(-1)"
      />
      <label v-if="!!category">{{ category.name }}</label>
    </div>
    <v-tabs grow>
      <v-tab :to="{name: 'program-bundle.detail.category.attachments'}">
        Ressources
      </v-tab>
      <v-tab :to="{name: 'program-bundle.detail.category.programs'}">
        Programmes
      </v-tab>
      <v-tab :to="{name: 'program-bundle.detail.category.consumers'}">
        Patients
      </v-tab>
    </v-tabs>
    <router-view
      v-if="category"
      :category="category"
    />
  </div>
  <base-flex-spinner v-else />
</template>

<script>
import { defineComponent } from 'vue'
import { programResource } from '@/renderer/container'
import Icon from '@/renderer/app/core/icon/Icon.vue'
import BaseFlexSpinner from '@/renderer/components/base/spinner/BaseFlexSpinner.vue'

/**
 * Display a program category details for a bundle
 */
export default defineComponent({
  name: 'ProgramBundleProgramCategoryDetails',
  components: { BaseFlexSpinner, Icon },
  watch: {
    '$route.params': {
      immediate: true,
      handler (value) {
        this.category = null
        this.loading = true
        this.readCategory(value.programBundleId, value.programCategoryId)
          .finally(() => this.loading = false)
      }
    }
  },
  data () {
    return {
      category: null,
      loading: false
    }
  },
  methods: {
    /**
     * Read the bundle category.
     *
     * @param {number} bundleId - The bundle id.
     * @param {number} categoryId - The category id.
     *
     * @return {Promise<void>}
     */
    readCategory (bundleId, categoryId) {
      return programResource()
        .bundleCategory(bundleId, categoryId)
        .then(category => {
          this.category = category
        })
    }
  }
})
</script>
