<template>
  <div v-if="loading">
    <BaseSpinner />
  </div>
  <div
    v-else
    class="grid justify-items-center"
  >
    <img
      alt="survey-image"
      :src="imageUri"
    >
  </div>
</template>

<script>
import { surveyApi } from '@/renderer/container'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'

export default {
  name: 'SurveyImage',
  components: { BaseSpinner },
  props: {
    answerId: {
      type: Number,
      default: null
    },
    questionId: {
      type: Number,
      required: true
    },
    surveyId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      imageUri: null,
      loading: false
    }
  },
  mounted () {
    this.loading = true
    console.log(this.answerId)
    const request = this.answerId
      ? surveyApi().answerImage(this.surveyId, this.questionId, this.answerId)
      : surveyApi().questionImage(this.surveyId, this.questionId)

    request.then(response => {
      const reader = new FileReader()

      reader.readAsDataURL(response)

      reader.onload = () => {
        this.imageUri = reader.result
      }
    })
      .finally(() => {
        this.loading = false
      })
  }
}
</script>
