export const AVAILABILITY_PROVIDERS = {
  medidestock: 'medidestock',
  leComptoirDesPharmacies: 'le-comptoir-des-pharmacies',
  healthcenterGroups: 'healthcenter-groups',
  direct: 'direct',
  healthcenterGroupsDistributor: 'healthcenter-groups-distributor'
}

export default function (apiClient) {
  /**
   * Get the availability of a product
   *
   * @param {string} provider The provider to use.
   * @param {number} productId The product id to check.
   * @param {string} requestToken The request token to use.
   *
   * @returns {Promise<{
   *   is_available: boolean,
   *   messages: string[]|null
   * }>}
   */
  const productAvailability = (provider, productId, requestToken) => {
    return apiClient.get(`/disruption-logistics/availability/${provider}/products/${productId}`, {
      headers: {
        'X-Request-Token': requestToken
      }
    })
      .then(response => response.data.data)
  }

  /**
   * Get the healthcenter groups.
   *
   * @returns {Promise<HealthcenterGroup[]>}
   */
  const healthcenterGroups = () => {
    return apiClient.get('disruption-logistics/healthcenter-groups')
      .then(response => response.data.data)
  }

  /**
   * Read a healthcenter group.
   *
   * @param {Number} healthcenterGroupId The healthcenter group id to read.
   *
   * @returns {Promise<HealthcenterGroupWithMembers>}
   */
  const readHealthcenterGroup = (healthcenterGroupId) => {
    return apiClient.get(`disruption-logistics/healthcenter-groups/${healthcenterGroupId}`)
      .then(response => response.data.data)
  }

  /**
   * Get all the group members of all the healthcenter groups.
   *
   * @return {Promise<HealthcenterGroupMemberWithWarehouses[]>}
   */
  const indexGroupsMembers = () => {
    return apiClient.get('disruption-logistics/healthcenter-groups/members')
      .then(response => response.data.data)
  }

  /**
   * Get the healthcenter group invitations received.
   *
   * @param {string} invitationStatus The invitation status to filter on.
   *
   * @returns {Promise<ReceivedHealthcenterGroupInvitation[]>}
   */
  const receivedHealthcenterGroupInvitations = (invitationStatus) => {
    const params = {}

    if (invitationStatus) {
      params.status = invitationStatus
    }

    return apiClient.get('disruption-logistics/healthcenter-groups/invitations/received', { params })
      .then(response => response.data.data)
  }

  /**
   * Get the healthcenter group invitations sent.
   *
   * @param {Number} groupId The healthcenter group id to get the sent invitations for.
   * @param {string} invitationStatus The invitation status to filter on.
   *
   * @returns {Promise<HealthcenterGroupInvitation[]>}
   */
  const sentHealthcenterGroupInvitations = (groupId, invitationStatus) => {
    const params = {}

    if (invitationStatus) {
      params.status = invitationStatus
    }

    return apiClient.get(`disruption-logistics/healthcenter-groups/${groupId}/invitations/sent`, { params })
      .then(response => response.data.data)
  }

  /**
   * Accept or reject an invitation
   *
   * @param {number} invitationId The invitation id to handle.
   * @param {boolean} accepted Whether the invitation is accepted or not.
   *
   * @return {Promise<HealthcenterGroupInvitation>}
   */
  const handleInvitation = (invitationId, accepted) => {
    const url = `disruption-logistics/healthcenter-groups/invitations/${invitationId}/${accepted ? 'accept' : 'reject'}`

    return apiClient.patch(url)
      .then(response => response.data.data)
  }

  /**
   * Create an invitation
   *
   * @param {Number} healthcenterGroupId The healthcenter group id to create the invitation for.
   * @param {String} email The email to invite.
   * @param {Number} healthcenterId The healthcenter id to invite.
   *
   * @returns {Promise<HealthcenterGroupInvitation>}
   */
  const createInvitation = (healthcenterGroupId, email, healthcenterId) => {
    const body = {
      email,
      healthcenter_id: healthcenterId
    }

    return apiClient.post(`disruption-logistics/healthcenter-groups/${healthcenterGroupId}/invitations`, body)
      .then(response => {
        return response.data.data[0] ?? null
      })
  }

  /**
   * Delete a healthcenter group invitation
   *
   * @param groupId The healthcenter group id
   * @param invitationId The invitation id
   *
   * @returns {Promise<{
   *   deleted: boolean
   * }>}
   */
  const deleteHealthcenterGroupInvitation = (groupId, invitationId) => {
    return apiClient.delete(`disruption-logistics/healthcenter-groups/${groupId}/invitations/${invitationId}`)
      .then(response => response.data.data)
  }

  /**
   * Delete a healthcenter from a healthcenter group.
   *
   * @param {Number} healthcenterGroupId The healthcenter group that the healthcenter belongs to.
   * @param {Number} healthcenterId The healthcenter to delete.
   *
   * @return {Promise<{
   *   deleted: boolean
   * }>}
   */
  const deleteHealthcenterGroupMember = (healthcenterGroupId, healthcenterId) => {
    return apiClient.delete(`disruption-logistics/healthcenter-groups/${healthcenterGroupId}/healthcenters/${healthcenterId}`)
      .then(response => response.data.data)
  }

  /**
   * Create a healthcenter group
   *
   * @param {{name: string; description: string;}} data
   *   The data to create the healthcenter group with.
   *
   * @return {Promise<HealthcenterGroup>}
   */
  const createHealthcenterGroup = (data) => {
    return apiClient.post('disruption-logistics/healthcenter-groups', data)
      .then(response => response.data.data)
  }

  /**
   * Edit a healthcenter group
   *
   * @param {Number} healthcenterGroupId
   * @param {{name: string; description: string;}} data
   *
   * @return {Promise<HealthcenterGroup>}
   */
  const editHealthcenterGroup = (healthcenterGroupId, data) => {
    return apiClient.patch(`disruption-logistics/healthcenter-groups/${healthcenterGroupId}`, data)
      .then(response => response.data.data)
  }

  /**
   * Delete a healthcenter group
   *
   * @param healthcenterGroupId
   * @return {Promise<{deleted: boolean}>}
   */
  const deleteHealthcenterGroup = (healthcenterGroupId) => {
    return apiClient.delete(`disruption-logistics/healthcenter-groups/${healthcenterGroupId}`)
      .then(response => response.data.data)
  }

  /**
   * Leave a healthcenter group
   *
   * @param healthcenterGroupId
   *
   * @return {Promise<{deleted: boolean}>}
   */
  const leaveHealthcenterGroup = (healthcenterGroupId) => {
    return apiClient.delete(`disruption-logistics/healthcenter-groups/${healthcenterGroupId}/leave`)
      .then(response => response.data.data)
  }

  /**
   * Get the statistics of the product availability
   *
   * @param {Number} productId
   *
   * @param requestToken
   * @return {Promise<Object>}
   */
  const productAvailabilityStatistics = (productId, requestToken) => {
    return apiClient.get(`disruption-logistics/products/${productId}/availabilities/statistics`, {
      headers: {
        'X-Request-Token': requestToken
      }
    })
      .then(response => response.data.data)
  }

  /**
   * Get availabilities that are available again scoped on the healthcenter ranked warehouses if applicable.
   *
   * @return {Promise<Object>}
   */
  const productsAvailableAgain = () => {
    return apiClient.get('availabilities/available-again')
      .then(response => response.data.data)
  }

  /**
   * Create a request for a product
   *
   * @param productId
   * @return {*}
   */
  const createRequest = (productId) => {
    return apiClient.post(`disruption-logistics/requests/${productId}`)
      .then(response => response.data.data)
  }

  /**
   * Get the remaining requests of the user
   *
   * @return {Promise<{remaining: number; subscribed: boolean}>}
   */
  const remainingRequests = () => {
    return apiClient.get('disruption-logistics/requests/remaining')
      .then(response => response.data.data)
  }

  /**
   * Get the availability sources
   *
   * @param params
   * @return {Promise<Array>}
   */
  const availabilitySources = (params = {}) => {
    return apiClient.get('availabilities/sources', { params })
      .then(response => response.data.data)
  }

  /**
   * Get availabilities for a source
   *
   * @param {'depositary'|'warehouse'} sourceType The source type
   * @param {Number} sourceId The source id
   *
   * @return {Promise<Array>}
   */
  const availabilities = (sourceType, sourceId) => {
    return apiClient.get('availabilities', {
      params: {
        source_type: sourceType,
        source_id: sourceId
      }
    })
      .then(response => response.data.data)
  }

  /**
   * Get the availability history
   *
   * @param availabilityId
   *
   * @return {Promise<Array>}
   */
  const availabilityHistory = (availabilityId) => {
    return apiClient.get(`availabilities/${availabilityId}`)
      .then(response => response.data.data)
  }

  return {
    productAvailability,
    receivedHealthcenterGroupInvitations,
    handleInvitation,
    healthcenterGroups,
    readHealthcenterGroup,
    createInvitation,
    sentHealthcenterGroupInvitations,
    deleteHealthcenterGroupInvitation,
    deleteHealthcenterGroupMember,
    indexGroupsMembers,
    createHealthcenterGroup,
    deleteHealthcenterGroup,
    productAvailabilityStatistics,
    createRequest,
    remainingRequests,
    productsAvailableAgain,
    availabilitySources,
    availabilities,
    availabilityHistory,
    editHealthcenterGroup,
    leaveHealthcenterGroup
  }
}
