import Vue from 'vue'

export default function () {
  return {
    /**
     * Display a success message.
     *
     * @param {String} message - The message to display.
     */
    success (message) {
      Vue.toasted.success(message, {
        duration: 5000
      })
    },

    /**
     * Display a warning message.
     *
     * @param {String} message - The message to display.
     */
    warning (message) {
      Vue.toasted.warning(message, {
        duration: 5000
      })
    },

    /**
     * Display an error message.
     *
     * @param {String} message - The message to display.
     */
    error (message) {
      Vue.toasted.error(message, {
        duration: 5000
      })
    }
  }
}
