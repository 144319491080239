<template>
  <div
    class="w-full pl-5"
    @mouseenter="onHoverStart"
    @mouseleave="onHoverEnd"
  >
    <div
      class="shadow-custom cursor-pointer rounded-md flex-row flex items-center relative pl-7 pr-2 h-auto w-full py-5 z-10"
    >
      <img
        v-if="program.logo"
        :src="`${program.logo.url}&h=400`"
        :alt="program.name"
        class="absolute"
        style="height:50px; width:50px; left:-25px; border-radius: 10px;"
      >
      <div
        v-else
        class="items-center justify-center flex bg-gray-200 absolute left-0"
        style="height:40px; width:40px; left:-15px; border-radius: 10px;"
      >
        {{ program.id }}
      </div>
      <div class="text-xs mx-1">
        <program-teaser-text :program="program" />
      </div>
      <div class="mx-1">
        <chevron-right-icon class="h-4 w-4 text-gray-400" />
      </div>
      <program-teaser-action
        :clamp="true"
        class="absolute right-2 -bottom-3.5 drop-shadow-md z-10"
        :program="program"
      />
    </div>
    <notification-support-program-setting-exlusion-toggler
      :consumer-key="consumer.key"
      :next-step="program.next_step"
      :support-program-id="program.programmable_id"
      :display="isSupportProgram && isHovered"
      :has-action="hasAction"
    />
  </div>
</template>

<script>
import { pathwayApi } from '@/renderer/container'
import ProgramTeaserText from '@/renderer/app/program/components/program-teaser/ProgramTeaserText.vue'
import ProgramTeaserAction from '@/renderer/app/program/components/program-teaser/ProgramTeaserAction.vue'
import ChevronRightIcon from '@/renderer/components/icons/ChevronRightIcon.vue'
import ConsumerProgramService from '@/renderer/services/ConsumerProgramService'
import NotificationSupportProgramSettingExlusionToggler
from '@/renderer/components/views/notification/notification-detection/programs/NotificationSupportProgramSettingExclusionToggler.vue'

export default {
  name: 'NotificationProgramLogoAdvanced',
  components: {
    NotificationSupportProgramSettingExlusionToggler,
    ChevronRightIcon,
    ProgramTeaserAction,
    ProgramTeaserText
  },
  props: {
    program: {
      type: Object,
      required: true
    },
    customer: {
      type: Object,
      required: true
    },
    consumer: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isHovered: false,
      timeout: null
    }
  },
  computed: {
    /**
     * Determine if the program has an action even if it not ready.
     *
     * @return {boolean}
     */
    hasAction () {
      return ConsumerProgramService().hasAction(this.program)
    },
    /**
     * Determine if the program is a support program.
     *
     * @return {boolean}
     */
    isSupportProgram () {
      return this.program.programmable_type === 'support'
    }
  },
  mounted () {
    if (this.program.next_step) {
      pathwayApi().track('notification-show', this.program.next_step.pathway_id, this.consumer.key)
    }
  },
  methods: {
    /**
     * On hover start, set a timeout to display the exclusion toggler.
     */
    onHoverStart () {
      this.timeout = setTimeout(() => {
        this.isHovered = true
      }, 200)
    },
    /**
     * On hover end, clear the timeout if overed is not true otherwise and set the hovered state to true.
     */
    onHoverEnd () {
      if (this.isHovered === false) {
        clearTimeout(this.timeout)
      } else {
        this.isHovered = false
      }
    }
  }
}
</script>
