import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeScreen from '@/renderer/app/home/views/HomeScreen.vue'
import Onboarding from '@/renderer/components/views/onboarding/Onboarding.vue'
import Login from '@/renderer/components/views/Login.vue'
import Register from '@/renderer/components/views/register/Register.vue'
import RegisterGuest from '@/renderer/components/views/register/RegisterGuest.vue'
import Guide from '@/renderer/components/views/guide/Guide.vue'
import LinkCPS from '@/renderer/components/views/onboarding/LinkCPS.vue'
import ChangeForgotPassword from '@/renderer/components/views/change-forgot-password/ChangeForgotPassword.vue'
import Notification from '@/renderer/components/views/notification/Notification.vue'
import NotificationHistory from '@/renderer/components/views/notification/NotificationHistory.vue'
import NotificationSettings from '@/renderer/app/settings/screens/NotificationSettings.vue'
import Customer from '@/renderer/app/customer/screens/Customer.vue'
import CustomerHome from '@/renderer/app/customer/components/customer-home/CustomerHome.vue'
import CustomerLoyalty from '@/renderer/components/views/customer/CustomerLoyalty.vue'
import CustomerDiscount from '@/renderer/components/views/customer/CustomerDiscount.vue'
import CustomerSupportProgram from '@/renderer/components/views/customer/CustomerSupportProgram.vue'
import CustomerDiscountList from '@/renderer/components/views/customer/CustomerDiscountList.vue'
import Disruption from '@/renderer/components/views/disruption/Disruption.vue'
import DisruptionOrder from '@/renderer/components/views/disruption/DisruptionOrder.vue'
import DisruptionOffer from '@/renderer/components/views/disruption/DisruptionOffer.vue'
import DisruptionPharmaml from '@/renderer/components/views/disruption/DisruptionPharmaml.vue'
import DisruptionDashboard from '@/renderer/components/views/disruption/DisruptionDashboard.vue'
import Brand from '@/renderer/components/views/brand/Brand.vue'
import BrandList from '@/renderer/components/views/brand/BrandList.vue'
import BrandOrder from '@/renderer/components/views/brand/BrandOrder.vue'
import BrandEvent from '@/renderer/components/views/brand/BrandEvent.vue'
import BrandCalendar from '@/renderer/components/views/brand/BrandCalendar.vue'
import BrandNeedList from '@/renderer/components/views/brand/BrandNeedList.vue'
import BrandRangeList from '@/renderer/components/views/brand/BrandRangeList.vue'
import BrandOfferList from '@/renderer/components/views/brand/BrandOfferList.vue'
import BrandOfferSellin from '@/renderer/components/views/brand/BrandOfferSellin.vue'
import BrandOfferSellout from '@/renderer/components/views/brand/BrandOfferSellout.vue'
import BrandCommunication from '@/renderer/components/views/brand/BrandCommunication.vue'
import BrandOfferDiscount from '@/renderer/components/views/brand/BrandOfferDiscount.vue'
import BrandOfferPrestation from '@/renderer/components/views/brand/BrandOfferPrestation.vue'
import BrandRangeProductList from '@/renderer/components/views/brand/BrandRangeProductList.vue'
import BrandOfferSellinChallenge from '@/renderer/components/views/brand/BrandOfferSellinChallenge.vue'
import BrandCommunicationPlvList from '@/renderer/components/views/brand/BrandCommunicationPlvList.vue'
import BrandCommunicationMediaPlanList from '@/renderer/components/views/brand/BrandCommunicationMediaPlanList.vue'
import BrandCommunicationPlanogramList from '@/renderer/components/views/brand/BrandCommunicationPlanogramList.vue'
import NotificationDetection
from '@/renderer/components/views/notification/notification-detection/NotificationDetection.vue'
import Tutorial from '@/renderer/components/views/tutorial/Tutorial.vue'
import Support from '@/renderer/components/views/support/Support.vue'
import electronHelper from '../main/utils/electronHelper'
import LoyaltyCardMembershipScreen from '@/renderer/app/loyalty-card-membership/views/LoyaltyCardMembershipScreen.vue'
import ProgramDetails from '@/renderer/app/program/views/ProgramDetails.vue'
import CustomerCertificationOnboarding
from '@/renderer/app/customer-certification/screens/CustomerCertificationOnboarding.vue'
import BeneficiaryHome from '@/renderer/app/customer/components/customer-beneficiary/BeneficiaryHome.vue'
import ProgramDetailContent from '@/renderer/app/program/views/ProgramDetailContent.vue'
import ProgramConsumers from '@/renderer/app/program/components/program-consumers/ProgramConsumers.vue'
import ProgramDetailAttachments from '@/renderer/app/program/views/ProgramDetailAttachments.vue'
import SupportProgramPathwayDetail from '@/renderer/app/program/views/SupportProgramPathwayDetail.vue'
import PrescriptorMapSearch from '@/renderer/app/prescriptor/views/PrescriptorMapSearch.vue'
import ProgramBundleDetails from '@/renderer/app/program/views/ProgramBundleDetails.vue'
import CustomerFeatureProgram from '@/renderer/app/customer/screens/CustomerFeatureProgram.vue'
import ProgramBundleProgramCategoryDetails from '@/renderer/app/program/views/ProgramBundleProgramCategoryDetails.vue'
import ProgramBundleProgramCategoryResource
from '@/renderer/app/program/views/ProgramBundleProgramCategoryResource.vue'
import ProgramBundleProgramCategoryPrograms
from '@/renderer/app/program/views/ProgramBundleProgramCategoryPrograms.vue'
import ProgramBundleProgramCategoryConsumers
from '@/renderer/app/program/views/ProgramBundleProgramCategoryConsumers.vue'
import CustomerSurveys from '@/renderer/app/customer/components/customer-surveys/CustomerSurveys.vue'
import SurveyBuilt from '@/renderer/app/survey/views/SurveyBuilt.vue'
import CustomerHospitalFiles from '@/renderer/app/customer/components/customer-files/CustomerHospitalFiles.vue'
import LoyaltyCardAnimations from '@/renderer/app/loyalty-card-animations/views/LoyaltyCardAnimations.vue'
import LoyaltyCardAnimationTransactions
from '@/renderer/app/loyalty-card-animations/views/LoyaltyCardAnimationTransactions.vue'
import CustomerClickCollect from '@/renderer/app/customer/components/customer-click-collect/CustomerClickCollect.vue'
import PatientAdministrationScreen from '@/renderer/app/patient-administration/views/PatientAdministrationScreen.vue'
import OrdersScreen from '@/renderer/app/order/views/OrdersScreen.vue'
import CustomerInterviews from '@/renderer/app/interview/views/CustomerInterviews.vue'
import PrescriptionRenewalScreen from '@/renderer/app/prescription/views/PrescriptionRenewalScreen.vue'
import PatchNotesScreen from '@/renderer/app/home/views/PatchNotesScreen.vue'
import PrescriptionRenewalDetailScreen from '@/renderer/app/prescription/views/PrescriptionRenewalDetailScreen.vue'
import CustomersListScreen from '@/renderer/app/customer/screens/CustomersListScreen.vue'
import CustomerPrescriptions from '@/renderer/app/customer/components/customer-prescriptions/CustomerPrescriptions.vue'
import disruptionLogisticsRouter from '@/renderer/app/disruption-logistics/disruptionLogisticsRouter'

Vue.use(VueRouter)

export default new VueRouter({
  mode: electronHelper.isElectron() ? 'hash' : 'history',
  routes: [

    {
      path: '/',
      name: 'onboarding',
      component: Onboarding,

      meta: {
        layout: 'TheWebsiteLayout'
      }
    },
    {
      path: '/patch-notes',
      name: 'patch-notes',
      component: PatchNotesScreen
    },

    {
      path: '/home',
      name: 'home',
      component: HomeScreen
    },
    {
      path: '/patient-administration',
      name: 'patient-administration',
      component: PatientAdministrationScreen,
      children: [
        {
          path: 'orders',
          name: 'patient-administration.orders',
          component: OrdersScreen
        },
        {
          path: 'renewals',
          name: 'patient-administration.renewals',
          component: PrescriptionRenewalScreen
        },
        {
          path: 'detail',
          name: 'patient-administration.renewals.detail',
          component: PrescriptionRenewalDetailScreen
        },
        {
          path: 'certified-patients',
          name: 'patient-administration.certified-patients',
          component: CustomersListScreen
        }
      ]
    },

    {
      path: '/prescriptor',
      name: 'prescriptor',
      component: PrescriptorMapSearch
    },

    {
      path: '/program-bundle/:programBundleId',
      name: 'program-bundle.detail',
      component: ProgramBundleDetails
    },
    {
      path: '/program-bundle/:programBundleId/category/:programCategoryId',
      name: 'program-bundle.detail.category',
      component: ProgramBundleProgramCategoryDetails,
      children: [
        {
          path: 'attachments',
          name: 'program-bundle.detail.category.attachments',
          component: ProgramBundleProgramCategoryResource
        },
        {
          path: 'programs',
          name: 'program-bundle.detail.category.programs',
          component: ProgramBundleProgramCategoryPrograms
        },
        {
          path: 'consumers',
          name: 'program-bundle.detail.category.consumers',
          component: ProgramBundleProgramCategoryConsumers
        }
      ]
    },
    {
      path: '/program',
      name: 'program.detail',
      component: ProgramDetails,

      children: [{
        path: '/program/content',
        name: 'program.detail.content',
        component: ProgramDetailContent
      }, {
        path: '/program/patients',
        name: 'program.detail.consumers',
        component: ProgramConsumers
      }, {
        path: '/program/attachments',
        name: 'program.detail.attachments',
        component: ProgramDetailAttachments
      }, {
        path: '/program/pathway',
        name: 'program.detail.pathway',
        component: SupportProgramPathwayDetail
      }, {
        path: '/program/animations',
        name: 'program.detail.animations',
        component: LoyaltyCardAnimations
      }, {
        path: '/program/animations/transactions',
        name: 'program.detail.animations.transactions',
        component: LoyaltyCardAnimationTransactions
      }]
    },

    {
      path: '/login',
      name: 'login',
      component: Login,

      meta: {
        layout: 'TheAuthenticationLayout'
      }
    },

    {
      path: '/register',
      name: 'register',
      component: Register,

      meta: {
        layout: 'TheAuthenticationLayout'
      }
    },

    {
      path: '/linkcps',
      name: 'linkcps',
      component: LinkCPS,

      meta: {
        layout: 'TheAuthenticationLayout'
      }
    },

    {
      path: '/register/:hash',
      name: 'register.guest',
      component: RegisterGuest,

      meta: {
        layout: 'TheAuthenticationLayout'
      }
    },

    {
      path: '/guide/:guideId',
      name: 'guide',
      component: Guide,

      meta: {
        layout: 'TheEmptyLayout'
      }
    },

    {
      path: '/change-forgot-password/:code',
      name: 'change-forgot-password',
      component: ChangeForgotPassword,

      meta: {
        layout: 'TheAuthenticationLayout'
      }
    },

    {
      path: '/disruption',
      name: 'disruption',
      component: Disruption,

      children: [
        {
          path: '/disruption/dashboard',
          name: 'disruption.dashboard',
          component: DisruptionDashboard
        },

        {
          path: '/disruption/order',
          name: 'disruption.order',
          component: DisruptionOrder
        },

        {
          path: '/disruption/offer',
          name: 'disruption.offer',
          component: DisruptionOffer
        },

        {
          path: '/disruption/pharmaml',
          name: 'disruption.pharmaml',
          component: DisruptionPharmaml
        }
      ]
    },

    {
      path: '/tutorial',
      name: 'tutorial',
      component: Tutorial,

      meta: {
        title: 'Tutoriels'
      }
    },

    {
      path: '/support',
      name: 'support',
      component: Support,

      meta: {
        title: 'Support'
      }
    },

    {
      path: '/notification',
      name: 'notification',
      component: Notification,

      meta: {
        title: 'Notifications',
        layout: 'TheNotificationLayout'
      }
    },

    {
      path: '/notification-detection',
      name: 'notification-detection',
      component: NotificationDetection,

      meta: {
        title: 'Notification detection',
        layout: 'TheNotificationLayout'
      }
    },

    {
      path: '/notification/preference',
      name: 'notification.preference',
      component: NotificationSettings,

      meta: {
        title: 'Paramètres de notification'
      }
    },

    {
      path: '/notification/history',
      name: 'notification.history',
      component: NotificationHistory,

      meta: {
        title: 'Notifications'
      }
    },

    {
      path: '/customer/:customerId',
      name: 'customer',
      component: Customer,

      children: [
        {
          path: 'home',
          name: 'customer.home',
          component: CustomerHome
        },

        {
          path: 'beneficiary/:id',
          name: 'customer.beneficiary',
          component: BeneficiaryHome
        },

        {
          name: 'customer.certification',
          path: 'certification',
          component: CustomerCertificationOnboarding
        },

        {
          path: 'loyalty',
          name: 'customer.loyalty',
          component: CustomerLoyalty
        },

        {
          path: 'loyalty/membership/:membershipId',
          name: 'customer.loyalty.membership',
          component: LoyaltyCardMembershipScreen
        },

        {
          path: 'discount',
          name: 'customer.discount.list',
          component: CustomerDiscountList
        },

        {
          path: 'survey-instances',
          name: 'customer.survey.list',
          component: CustomerSurveys
        },
        {
          path: 'prescriptions',
          name: 'customer.prescriptions',
          component: CustomerPrescriptions
        },
        {
          path: 'renewal',
          name: 'customer.prescriptions.renewal',
          component: PrescriptionRenewalDetailScreen
        },

        {
          path: 'survey-instances/:instanceId',
          name: 'customer.survey',
          component: SurveyBuilt
        },

        {
          path: 'files',
          name: 'customer.hospital-file.list',
          component: CustomerHospitalFiles
        },
        {
          path: 'click-collect',
          name: 'customer.click-collect',
          component: CustomerClickCollect
        },

        {
          path: 'support-programs/:programId?/:consumerKey?',
          name: 'customer.support-program',
          component: CustomerSupportProgram
        },
        {
          path: 'feature-programs/:programId?/:consumerKey?',
          name: 'customer.feature-program',
          component: CustomerFeatureProgram
        },

        {
          path: 'discount/:discountId',
          name: 'customer.discount',
          component: CustomerDiscount
        },
        {
          path: 'interviews',
          name: 'customer.interviews',
          component: CustomerInterviews
        }
      ]
    },
    {
      path: '/brand',
      name: 'brand.list',
      component: BrandList,

      meta: {
        title: 'Espace marques'
      }
    },

    {
      path: '/brand/:laboratoryId',
      name: 'brand',
      component: Brand,

      meta: {
        title: 'Espace marques'
      },

      children: [
        {
          path: 'range',
          name: 'brand.range.list',
          component: BrandRangeList,

          meta: {
            title: 'Espace marques'
          }
        },

        {
          path: 'range/:range/product',
          name: 'brand.range.product.list',
          component: BrandRangeProductList,

          meta: {
            title: 'Espace marques'
          }
        },

        {
          path: 'need',
          name: 'brand.need.list',
          component: BrandNeedList,

          meta: {
            title: 'Espace marques'
          }
        },

        {
          path: 'offer',
          name: 'brand.offer.list',
          component: BrandOfferList,

          meta: {
            title: 'Espace marques'
          }
        },

        {
          path: 'offer/sellout/:selloutId',
          name: 'brand.offer.sellout',
          component: BrandOfferSellout,

          meta: {
            title: 'Espace marques'
          }
        },

        {
          path: 'offer/prestation/:prestationId',
          name: 'brand.offer.prestation',
          component: BrandOfferPrestation,

          meta: {
            title: 'Espace marques'
          }
        },

        {
          path: 'offer/discount/:discountId',
          name: 'brand.offer.discount',
          component: BrandOfferDiscount,

          meta: {
            title: 'Espace marques'
          }
        },

        {
          path: 'offer/sellin/:sellinOfferId',
          name: 'brand.offer.sellin',
          component: BrandOfferSellin,

          meta: {
            title: 'Espace marques'
          }
        },

        {
          path: 'offer/sellin/challenge/:sellinChallengeId',
          name: 'brand.offer.sellin.challenge',
          component: BrandOfferSellinChallenge,

          meta: {
            title: 'Espace marques'
          }
        },

        {
          path: 'order/:order',
          name: 'brand.order',
          component: BrandOrder,

          meta: {
            title: 'Espace marques'
          }
        },

        {
          path: 'calendar',
          name: 'brand.calendar',
          component: BrandCalendar,

          meta: {
            title: 'Espace marques'
          }
        },

        {
          path: 'event/:eventId',
          name: 'brand.event',
          component: BrandEvent,

          meta: {
            title: 'Espace marques'
          }
        },

        {
          path: 'communication',
          name: 'brand.communication',
          component: BrandCommunication,

          meta: {
            title: 'Espace marques'
          },

          children: [
            {
              path: 'media-plan',
              name: 'brand.communication.media-plan.list',
              component: BrandCommunicationMediaPlanList,

              meta: {
                title: 'Espace marques'
              }
            },

            {
              path: 'planogram',
              name: 'brand.communication.planogram.list',
              component: BrandCommunicationPlanogramList,

              meta: {
                title: 'Espace marques'
              }
            },

            {
              path: 'plv',
              name: 'brand.communication.plv.list',
              component: BrandCommunicationPlvList,

              meta: {
                title: 'Espace marques'
              }
            }
          ]
        }
      ]
    },
    ...disruptionLogisticsRouter
  ]
})
