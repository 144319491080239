<template>
  <BaseCard>
    <BaseSpinner
      v-if="!advices"
      :size="'small'"
    />
    <BaseSlider
      v-if="!!advices && mergedAdvices && !!mergedAdvices.length"
      :title="'Recommandé pour ce patient'"
      :slides="{
        component: 'support-program-advice',
        prop: 'advice',
        data: mergedAdvices
      }"
    />
  </BaseCard>
</template>

<script>
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import BaseSlider from '@/renderer/components/base/BaseSlider.vue'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'

export default {
  components: { BaseSpinner, BaseSlider, BaseCard },
  props: {
    advices: {
      type: Array,
      default: () => null
    }
  },
  computed: {
    mergedAdvices () {
      const mergedAdvices = []

      if (this.advices) {
        for (const item of this.advices) {
          for (const advice of item.advices) {
            advice.description = item.description
            mergedAdvices.push(advice)
          }
        }
      }

      return mergedAdvices
    }
  }
}
</script>
