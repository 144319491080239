<template>
  <div>
    <label
      v-if="label"
      class="block text-sm font-medium text-gray-700"
    >
      {{ label }}
    </label>

    <div class="relative">
      <div class="mt-1 relative rounded-md shadow-sm">
        <input
          v-model="model"
          type="search"
          :placeholder="placeholder"
          :class="[ errors.length
            ? 'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'
            : 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
          ]"
          @focus="opened = true"
        >

        <div
          v-if="errors.length"
          class="absolute inset-y-0 right-0 pr-3 flex items-center valueer-events-none"
        >
          <svg
            aria-hidden="true"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="h-5 w-5 text-red-500"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            />
          </svg>
        </div>
      </div>

      <ul
        v-show="opened && items.length"
        class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
      >
        <li
          v-for="item in items"
          :key="item.key"
          class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-gray-100"
          @click="select(item)"
        >
          <span
            class="font-normal block truncate"
            :class="{ 'font-semibold': item.value === model }"
          >
            {{ item.value }}
          </span>

          <span
            v-if="item.value === model"
            class="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
          >
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: false,
      default: null
    },

    placeholder: {
      type: String,
      required: false,
      default: null
    },

    items: {
      type: Array,

      default () {
        return []
      }
    },

    errors: {
      type: Array,
      default () {
        return []
      }
    },

    value: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      opened: false,
      selectedItem: {}
    }
  },

  computed: {
    model: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    }
  },

  watch: {
    model (value) {
      this.$emit('changed', value)
    }
  },

  methods: {
    select (item) {
      this.opened = false
      this.model = item.value
      this.selectedItem = item

      this.$emit('selected', item)
    }
  }
}
</script>
