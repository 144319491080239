<template>
  <svg
    :class="`h-${size} w-${size}`"
    viewBox="0 0 30 30"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="11.6364"
      height="11.6364"
      rx="4"
      stroke="none"
      stroke-width="2"
    />
    <rect
      x="17.3638"
      y="1"
      width="11.6364"
      height="11.6364"
      rx="4"
      stroke="none"
      stroke-width="2"
    />
    <rect
      x="1"
      y="17.3635"
      width="11.6364"
      height="11.6364"
      rx="4"
      stroke="none"
      stroke-width="2"
    />
    <path
      d="M23.182 17.2729C23.3779 17.2729 23.5658 17.3508 23.7043 17.4893C23.8429 17.6278 23.9207 17.8157 23.9207 18.0116V22.4434H28.3525C28.5484 22.4434 28.7363 22.5212 28.8748 22.6597C29.0133 22.7983 29.0911 22.9861 29.0911 23.182C29.0911 23.3779 29.0133 23.5658 28.8748 23.7043C28.7363 23.8429 28.5484 23.9207 28.3525 23.9207H23.9207V28.3525C23.9207 28.5484 23.8429 28.7363 23.7043 28.8748C23.5658 29.0133 23.3779 29.0911 23.182 29.0911C22.9861 29.0911 22.7983 29.0133 22.6597 28.8748C22.5212 28.7363 22.4434 28.5484 22.4434 28.3525V23.9207H18.0116C17.8157 23.9207 17.6278 23.8429 17.4893 23.7043C17.3508 23.5658 17.2729 23.3779 17.2729 23.182C17.2729 22.9861 17.3508 22.7983 17.4893 22.6597C17.6278 22.5212 17.8157 22.4434 18.0116 22.4434H22.4434V18.0116C22.4434 17.8157 22.5212 17.6278 22.6597 17.4893C22.7983 17.3508 22.9861 17.2729 23.182 17.2729V17.2729Z"
      fill=""
    />
  </svg>
</template>

<script>
export default {
  name: 'StoreIcon',
  props: {
    size: {
      type: String,
      default: '10'
    }
  }
}
</script>
