<template>
  <v-data-table
    :headers="headers"
    :items="transformedConsumers"
    :disable-sort="true"
    :loading-text="'Chargement en cours...'"
    no-data-text="Aucun patients n'a bénéficié de cette offre"
    @click:row="handleClick"
  />
</template>

<script>
import LoyaltyCardTableHeader
from '@/renderer/app/program/components/loyalty-card-table-header/LoyaltyCardTableHeader.vue'
import LoyaltyCardTableBody from '@/renderer/app/program/components/loyalty-card-table-body/LoyaltyCardTableBody.vue'

export default {
  name: 'InstantDiscountVoucherConsumersTable',
  components: {
    LoyaltyCardTableHeader,
    LoyaltyCardTableBody
  },
  props: {
    consumers: {
      type: Array,
      required: true
    }
  },
  computed: {
    /**
     * The table header configuration.
     *
     * @return {Array}
     */
    headers () {
      return [
        {
          text: 'Nom',
          value: 'name'
        },
        {
          text: 'Remises',
          value: 'total_discount'
        },
        {
          text: 'Marges',
          value: 'total_margin'
        },
        {
          text: 'Quantité',
          value: 'total_quantity'
        },
        {
          text: 'Recette',
          value: 'total_revenue'
        }
      ]
    },
    /**
     * Transform the customers for the table.
     *
     * @return {Array}
     */
    transformedConsumers () {
      if (!this.consumers) {
        return undefined
      }

      return this.consumers.map((consumer) => {
        return {
          consumer: consumer,
          name: consumer.customer.information.name,
          total_quantity: consumer.total_quantity,
          total_revenue: consumer.total_revenue,
          total_margin: consumer.total_margin.toFixed(2),
          total_discount: consumer.total_discount
        }
      })
    }
  },
  methods: {
    /**
     * Redirect to customer details.
     *
     * @param row
     */
    handleClick (row) {
      this.$emit('click', row.consumer)
    }
  }
}
</script>
