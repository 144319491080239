import { render, staticRenderFns } from "./BaseFlexSpinner.vue?vue&type=template&id=d8711db2&"
import script from "./BaseFlexSpinner.vue?vue&type=script&lang=js&"
export * from "./BaseFlexSpinner.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports