/**
 * The loyalty card membership reward resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Create a loyalty card membership reward.
     * @param {number} customerId - The ID of the customer.
     * @param {number} membershipId - The ID of the membership.
     * @param {Object} data -
     * @returns {Promise} Promise object represents the HTTP request.
     */
    create (customerId, membershipId, data) {
      return client
        .post(`customers/${customerId}/loyalty-card-memberships/${membershipId}/rewards`, {
          data
        })
        .then(response => response.data.data)
    }
  }
}
