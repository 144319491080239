<template>
  <div>
    <label
      v-if="label"
      class="block text-sm font-medium text-gray-700"
    >
      {{ label }}
    </label>

    <div
      :class="['flex mt-1 relative border-b-2', error !== null
        ? 'border-orange-light'
        : ''
      ]"
    >
      <input
        v-model="model"
        :type="computedType"
        :placeholder="placeholder"
        class="flex-1 appearance-none px-1 md:px-3 py-2 h-12 placeholder-gray-300 text-white border-0 focus:border-0 focus:ring-0 sm:text-sm bg-transparent"
        @blur="onblur"
      >
      <img
        v-if="hasEye"
        :src="showEye ? require('@/assets/images/icons/password_eye_off.svg') : require('@/assets/images/icons/password_eye_on.svg')"
        @click="toggleEye"
      >

      <div
        v-if="error"
        class="absolute inset-y-0 right-0 pr-3 flex items-center valueer-events-none"
      >
        <svg
          aria-hidden="true"
          viewBox="0 0 20 20"
          fill="currentColor"
          class="h-5 w-5 text-orange-light"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
          />
        </svg>
      </div>
    </div>

    <p
      v-if="error"
      class="mt-2 text-sm text-orange-light"
    >
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'RegisterInput',

  props: {
    label: {
      type: String,
      required: false,
      default: null
    },

    type: {
      type: String,
      required: true
    },

    value: {
      type: [
        String,
        Number
      ],
      required: false,
      default: ''
    },

    placeholder: {
      type: String,
      required: false,
      default: null
    },

    error: {
      type: String,
      required: false,
      default: null
    }
  },

  data () {
    return {
      eye: false
    }
  },

  computed: {
    model: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },
    computedType () {
      return (this.hasEye && this.showEye) ? 'text' : this.type
    },
    hasEye () {
      return this.type === 'password'
    },
    showEye () {
      return this.hasEye && this.eye
    }
  },

  methods: {
    toggleEye () {
      this.eye = !this.eye
    },
    onblur () {
      this.$emit('blur')
    }
  }
}
</script>
