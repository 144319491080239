var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseCard',{staticClass:"cursor-pointer",style:({
    'background-image': `url(${require('../../../../../assets/images/pathway.png')})`,
    'background-size': 'contain',
    'background-repeat': 'no-repeat',
    'background-position': 'bottom right',
    'height': '150px'
  })},[_c('div',{staticClass:"bg-primary bg-opacity-90 self-end mb-5 p-3 text-white font-content",style:({
      'max-width': '33%'
    })},[_c('p',{staticClass:"font-bold",staticStyle:{"margin":"0"}},[_vm._v(" Voir le parcours en "+_vm._s(_vm.pathwayStepCount)+" étapes ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }