<template>
  <div class="flex flex-col">
    <BaseButton
      primary
      @click="click"
    >
      <div
        class="text-small text-center w-full flex flex-row justify-center items-center gap-x-2"
      >
        Envoyer un SMS
      </div>
    </BaseButton>
    <customer-certification-notification-modal
      :modal-visible="isModalVisible"
      :certification="customer.certification"
      @close="close"
    />
  </div>
</template>

<script>
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import CustomerCertificationNotificationModal
from '@/renderer/app/customer-certification/screens/CustomerCertificationNotificationModal.vue'
import Sms from '@/renderer/components/icons/Sms.vue'

export default {
  name: 'CustomerCertificationNotificationButton',
  components: { Sms, CustomerCertificationNotificationModal, BaseButton },
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  data: () => {
    return {
      isModalVisible: false,
      isLoading: false
    }
  },
  methods: {
    /**
     * Open the modal.
     */
    click () {
      this.isModalVisible = true
    },
    /**
     * Close the modal.
     */
    close () {
      this.isModalVisible = false
    }
  }
}
</script>
