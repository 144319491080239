<template>
  <div>
    <header-back-button
      :title="`Historique de disponibilité de ${productName}`"
    />
    <div class="flex flex-col p-3">
      <base-flex-spinner v-if="loading" />
      <div
        v-else
        class="flex flex-col gap-2"
      >
        <div class="flex gap-2 items-center">
          <div
            class="w-4 h-4 rounded-full"
            :class="{
              'bg-green-500': availability.available,
              'bg-red-500': !availability.available
            }"
          />
          <p class="font-semibold text-primary">
            Produit {{ availability.available ? 'disponible' : 'indisponible' }} chez le dépositaire en direct
          </p>
        </div>
        <h3 class="font-content-bold">
          Historique de disponibilité :
        </h3>
        <p v-if="!histories">
          Aucun historique de disponibilité n'est disponible
        </p>
        <div
          v-for="history in histories"
          :key="history.id"
          class="flex gap-2 items-center bg-white rounded-lg shadow p-3 text-md"
        >
          <div
            class="w-4 h-4 rounded-full"
            :class="{
              'bg-green-500': history.was,
              'bg-red-500': !history.was
            }"
          />
          <p>
            Était {{ history.was ? 'disponible' : 'indisponible' }} avant le {{ history.updated_at | date('short') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { disruptionLogisticsApi } from '@/renderer/container'
import HeaderBackButton from '@/renderer/app/core/components/header-back-button/HeaderBackButton.vue'
import BaseFlexSpinner from '@/renderer/components/base/spinner/BaseFlexSpinner.vue'

export default {
  name: 'AvailabilityHistoryPage',
  components: { BaseFlexSpinner, HeaderBackButton },
  data () {
    return {
      availability: null,
      loading: false
    }
  },
  computed: {
    productName () {
      return this.$route.query.productName
    },
    availabilityId () {
      return this.$route.params.availabilityId
    },
    /**
     * Reverse the history order.
     *
     * @return {Array}
     */
    histories () {
      const availability = this.availability

      if (!availability) {
        return []
      }

      return availability?.history?.reverse()
    }
  },
  watch: {
    availabilityId: {
      handler () {
        this.loading = true
        this.loadHistory()
          .finally(() => {
            this.loading = false
          })
      },
      immediate: true
    }
  },
  methods: {
    /**
     * Load the availability history.
     *
     * @return {Promise<void>}
     */
    loadHistory () {
      return disruptionLogisticsApi()
        .availabilityHistory(this.availabilityId)
        .then((response) => {
          this.availability = response
        })
    }
  }
}
</script>

<style scoped>

</style>
