<template>
  <div>
    <nav
      class="flex space-x-2 bg-white"
      aria-label="Tabs"
    >
      <router-link
        :to="{name: 'disruption-logistics.availabilities'}"
        active-class="nav-item-active"
        class="nav-item"
      >
        Info dispo
      </router-link>

      <router-link
        :to="{name: 'disruption-logistics.direct-availability-sources'}"
        active-class="nav-item-active"
        class="nav-item"
      >
        Disponibilités ventes directes
      </router-link>

      <router-link
        :to="{name: 'disruption-logistics.disruptions'}"
        active-class="nav-item-active"
        class="nav-item"
      >
        Retours de dispo
      </router-link>
    </nav>
    <router-view
      :key="$route.path"
    />
  </div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'

const { mapActions } = createNamespacedHelpers('extensions/disruptionLogisticsRequest')

export default {
  name: 'DisruptionLogisticsHomePage',
  mounted () {
    this.loadRemainingRequests()
  },
  methods: {
    ...mapActions(['loadRemainingRequests'])
  }
}
</script>

<style scoped>
.nav-item {
  @apply border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-2 px-3 border-b-2 font-medium
}

.nav-item-active {
  @apply border-primary text-primary
}
</style>
