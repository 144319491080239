import { orderApi } from '@/renderer/container'
import LiveItemMapperService from '@/renderer/services/LiveItemMapperService'

export default {
  namespaced: true,
  state: {
    items: []
  },
  getters: {
    items: (state) => () => {
      return state.items
    }
  },
  mutations: {
    storeItems (state, items) {
      state.items = items
    },
    newItems (state, item) {
      state.items.unshift(LiveItemMapperService().newOrder(item))
    },
    deleteItems (state, item) {
      const index = state.items.findIndex((currentItem) => currentItem.data.id === item.id)
      if (index > -1) {
        state.items.splice(index, 1)
      }
    },
    reset (state) {
      state.items = []
    }
  },
  actions: {
    loadItems ({ commit, state }) {
      return orderApi().index({ status: 'pending' })
        .then(orders => {
          if (orders?.data?.length) {
            const results = orders.data.map((order) => LiveItemMapperService().newOrder(order))

            commit('storeItems', results)
          }
        })
    }
  }
}
