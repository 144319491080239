
<template>
  <button
    class="flex flex-row bg-primary text-white rounded-md gap-2 h-14 px-2 lg:px-4 items-center"
    @click="$emit('click')"
  >
    <img
      class="w-8 h-8"
      :src="require('@/assets/images/icons/picto-download.svg')"
    >
    <div class="flex flex-col">
      <div class="text-sm">
        Télécharger
      </div>
      <div class="text-base font-extrabold">
        {{ title }}
      </div>
    </div>
    <img
      class="w-10 h-10 self-center"
      :src="icon"
    >
  </button>
</template>

<script>

export default {
  name: 'DownloadButton',

  props: {
    title: {
      type: String,
      default: null,
      required: false
    },
    icon: {
      type: String,
      default: null,
      required: false
    }
  },

  methods: {

  }
}
</script>
