<template>
  <div
    class="rounded-md p-4"
    :class="`bg-${color}-50`"
  >
    <div class="flex">
      <div class="flex-shrink-0">
        <svg
          v-if="type === 'success'"
          class="h-5 w-5"
          aria-hidden="true"
          viewBox="0 0 20 20"
          fill="currentColor"
          :class="`text-${color}-400`"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9
            10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          />
        </svg>

        <svg
          v-if="type === 'error'"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
          class="h-5 w-5"
          :class="`text-${color}-400`"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586
            10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414
            10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
          />
        </svg>
      </div>

      <div
        class="ml-3 text-sm"
        :class="`text-${color}-700`"
      >
        <h3
          :class="`text-${color}-800`"
          class="text-sm font-bold mb-1"
        >
          {{ title }}
        </h3>

        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'default'
    },

    title: {
      type: String,
      required: true
    }
  },

  computed: {
    color () {
      switch (this.type) {
        case 'success':
          return 'green'

        case 'error':
          return 'red'

        default:
          return 'gray'
      }
    }
  }
}
</script>
