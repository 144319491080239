<template>
  <form class="container max-h-screen overflow-y-auto mx-auto px-4 sm:px-6 lg:px-8">
    <div class="flex text-white text-xl justify-center my-10">
      Veuillez compléter ce formulaire pour créer un compte
    </div>
    <div class="md:grid md:grid-cols-2">
      <div class="invisible md:visible h-0 w-0 md:h-full md:w-[384px]">
        <lottie-animation
          path="animations/73812-cloud-computing-security.json"
          :width="384"
          :height="384"
        />
      </div>
      <div class="max-w-md">
        <div class="grid grid-cols-2 gap-2">
          <RegisterInput
            v-model="firstname"
            :error="errors.firstname"
            type="text"
            placeholder="Prénom"
          />
          <RegisterInput
            v-model="lastname"
            :error="errors.lastname"
            type="text"
            placeholder="Nom"
          />
        </div>
        <div class="flex flex-row flex-wrap mt-4 mb-2 gap-4 text-sm text-white">
          <input
            id="man"
            v-model="gender"
            value="0"
            type="radio"
            class="hidden"
          >
          <label
            for="man"
            :class="['flex flex-row items-center', (gender == 0)
              ? 'opacity-100'
              : 'opacity-60'
            ]"
          >
            <img
              :class="['h-16 mr-2', gender == 0
                ? 'rounded-full border-white border-2'
                : 'border-0'
              ]"
              :src="require('@/assets/images/icons/profile-male.png')"
            >
            Homme
          </label>
          <br>
          <input
            id="woman"
            v-model="gender"
            value="1"
            type="radio"
            class="hidden"
          >
          <label
            for="woman"
            :class="['flex flex-row items-center', (gender == 1)
              ? 'opacity-100'
              : 'opacity-60'
            ]"
          >
            <img
              :class="['h-16 mr-2', gender == 1
                ? 'rounded-full border-white border-2'
                : 'border-0'
              ]"
              :src="require('@/assets/images/icons/profile-female.png')"
            >
            Femme
          </label>
        </div>
        <div
          v-if="errors.gender"
          class="mt-2 text-sm text-orange-light"
        >
          {{ errors.gender }}
        </div>
        <RegisterInput
          v-model="postalCode"
          :error="errors.postalCode"
          type="number"
          placeholder="Code Postal de votre pharmacie"
          @blur="formatZipcode"
          @input="() => debounceFormatZipCode(10000)"
        />

        <RegisterInput
          v-model="email"
          :error="errors.email"
          type="email"
          placeholder="Email"
          @blur="verifyEmail"
        />

        <RegisterInput
          v-model="password"
          type="password"
          placeholder="Mot de passe"
        />
        <RegisterInput
          v-model="passwordConfirmation"
          :error="errors.passwordConfirmation"
          type="password"
          placeholder="Confirmez votre mot de passe"
        />
        <div
          :class="['mt-2 text-xs', errors.password
            ? 'text-orange-light'
            : 'text-gray-300'
          ]"
        >
          {{ errors.password || passwordInfo }}
        </div>
        <div
          :class="['mt-1 relative border-b-2', errors.lgo
            ? 'mb-0 border-orange-light'
            : 'mb-8'
          ]"
        >
          <select
            v-model="lgo"
            class="appearance-none w-full px-3 py-2 h-12 placeholder-gray-300 text-white border-0 focus:border-0 focus:ring-0 sm:text-sm bg-transparent"
          >
            <option
              :value="null"
              disabled
              hidden
            >
              Choisissez votre LGO
            </option>
            <option
              v-for="thelgo in lgos"
              :key="thelgo.id"
              :value="thelgo.id"
              class="text-black"
            >
              {{ thelgo.name }}
            </option>
          </select>
        </div>
        <div
          v-if="errors.lgo"
          class="mt-2 text-sm text-orange-light"
        >
          {{ errors.lgo }}
        </div>
        <Dropdown
          :options="[{name: 'Aucun groupement', id: null}, ...groupings]"
          :disabled="false"
          :max-item="10"
          name="Groupement"
          placeholder="Aucun groupement. Cliquez ici pour chercher..."
          @selected="selectedGrouping"
        />
        <div class="my-4">
          <input
            v-model="referral"
            type="text"
            placeholder="Code de parrainage"
            class="px-3 py-2 h-8 placeholder-gray-700 text-white rounded-md border-0 focus:border-0 focus:ring-0 sm:text-sm bg-lightPurple-default"
            style="color: #353535"
          >
        </div>
      </div>
    </div>
    <BaseAlert
      v-if="errors.page"
      type="error"
      title="Erreur"
    >
      {{ errors.page }}
    </BaseAlert>
    <div class="text-gray-200 my-2">
      <input
        v-model="checkedCGU"
        type="checkbox"
        class="accent-secondary mr-2"
      >
      j'ai lu et j'accepte les
      <a
        href="https://api.apodispharma.com/contracts/versions/3/show"
        target="_blank"
        class="underline"
      >
        CGU
      </a>
    </div>
    <div class="text-gray-200 my-2">
      <input
        v-model="checkedTERMS"
        type="checkbox"
        class="accent-secondary mr-2"
      >
      j'ai lu et j'accepte la
      <a
        href="https://www.apodis-sante.com/politique-de-confidentialite/"
        target="_blank"
        class="underline"
      >
        politique de confidentialité des données
      </a>
    </div>
    <button
      type="button"
      class="flex justify-center mx-auto py-3 px-7 mt-8 text-xl text-primary-dark rounded-full bg-secondary"
      @click="validate"
    >
      Créer mon compte
    </button>
  </form>
</template>

<script>
import Dropdown from '@/renderer/components/base/BaseDropdown.vue'
import { lgoResource, registrationService, groupingsResource } from '@/renderer/container'
import BaseAlert from '@/renderer/components/base/BaseAlert.vue'
import RegisterInput from '@/renderer/components/views/register/RegisterInput.vue'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import passwordPolicyHelper from '@/renderer/helpers/passwordPolicyHelper'
import formatHelper from '@/renderer/helpers/formatHelper'

export default {
  name: 'RegisterForm',

  components: {
    BaseAlert,
    Dropdown,
    RegisterInput,
    LottieAnimation
  },

  props: {
    ecps: {
      type: Object,
      required: false,
      default: null
    }
  },

  data () {
    return {
      errors: [],
      lgos: [],
      groupings: [],
      alreadyUsedEmail: false,
      firstname: null,
      lastname: null,
      gender: null,
      postalCode: null,
      email: null,
      password: null,
      passwordConfirmation: null,
      referral: null,
      checkedCGU: false,
      checkedTERMS: false,
      lgo: null,
      groupingId: null,
      timer: null
    }
  },

  computed: {
    isEmailValid () {
      return this.email && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.email)
    },

    submitEnabled () {
      return (this.firstname !== null && this.firstname.length > 0) &&
        (this.lastname !== null && this.firstname.length > 0) &&
        (this.postalCode !== null && this.postalCode.length > 0) &&
        (this.gender !== null) &&
        this.isEmailValid && !this.alreadyUsedEmail &&
        (this.lgo !== null) &&
        this.checkedCGU &&
        this.checkedTERMS &&
        (this.password === this.passwordConfirmation) &&
        passwordPolicyHelper.isValid(this.password)
    },

    passwordInfo () {
      if (passwordPolicyHelper.isValid(this.password)) {
        return 'Votre mot de passe est sécurisé 👍'
      } else {
        var info = 'Votre mot de passe doit contenir'
        if (!passwordPolicyHelper.hasMinimumLength(this.password)) {
          info += ' au moins ' + String(passwordPolicyHelper.minLength) + ' caractères,'
        }
        if (!passwordPolicyHelper.hasNumber(this.password)) {
          info += ' un nombre,'
        }
        if (!passwordPolicyHelper.hasLowercase(this.password)) {
          info += ' un caractère minuscule,'
        }
        if (!passwordPolicyHelper.hasUppercase(this.password)) {
          info += ' un caractère majuscule,'
        }
        if (!passwordPolicyHelper.hasSpecial(this.password)) {
          info += ' un caractère spécial,'
        }
        return info.replace(/.$/, '.')
      }
    }
  },

  mounted () {
    this.email = process.env.VUE_APP_LOGIN_EMAIL
    this.password = process.env.VUE_APP_LOGIN_PASSWORD

    lgoResource().getList().then(list => {
      this.lgos = list.lgo
    })

    groupingsResource().search('').then(res => {
      this.groupings = res.groups
    })

    this.autocompleteECPS()
  },

  methods: {
    autocompleteECPS () {
      if (this.ecps) {
        if (this.firstname === null && this.ecps.given_name) {
          this.firstname = this.ecps.given_name
        }
        if (this.lastname === null && this.ecps.family_name) {
          this.lastname = this.ecps.family_name
        }
        if (
          this.email === null &&
          this.ecps.SubjectRefPro?.exercices?.length > 0 &&
          this.ecps.SubjectRefPro?.exercices[0]?.activities?.length > 0 &&
          this.ecps.SubjectRefPro?.exercices[0]?.activities[0].adresseEMail
        ) {
          this.email = this.ecps.SubjectRefPro?.exercices[0]?.activities[0].adresseEMail
        }
      }
    },

    selectedGrouping (g) {
      this.groupingId = (g && g.id) || null
    },

    verifyEmail () {
      if (this.isEmailValid) {
        registrationService().isMailAvailable(this.email).then(response => {
          this.alreadyUsedEmail = !response.success
          const newErrors = Object.assign([], this.errors)
          if (!response.success) {
            newErrors.email = 'Un compte existe déjà avec cet email'
          } else {
            newErrors.email = undefined
          }
          this.errors = newErrors
        })
      }
    },
    debounceFormatZipCode (timeout) {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.formatZipcode()
      }, timeout)
    },
    formatZipcode () {
      this.postalCode = formatHelper.formatZipCode(this.postalCode)
    },
    validate () {
      if (this.submitEnabled) {
        const user = {
          firstname: this.firstname,
          lastname: this.lastname,
          gender: this.gender,
          email: this.email,
          password: this.password,
          lgo: this.lgo,
          referral: this.referral,
          postalCode: this.postalCode,
          groupingId: this.groupingId
        }
        this.$emit('validate', user)
      } else {
        const errorsToDisplay = []
        if (this.firstname === null || this.firstname.length === 0) {
          errorsToDisplay.firstname = 'Prénom requis'
        }
        if (this.lastname === null || this.lastname.length === 0) {
          errorsToDisplay.lastname = 'Nom requis'
        }
        if (this.gender === null) {
          errorsToDisplay.gender = 'Genre requis'
        }
        if (this.postalCode === null || this.postalCode.length === 0) {
          errorsToDisplay.postalCode = 'Code postal non valide'
        }
        if (!this.isEmailValid) {
          errorsToDisplay.email = 'Email non valide'
        }
        if (this.alreadyUsedEmail) {
          errorsToDisplay.email = 'Un compte existe déjà avec cet email'
        }
        if (this.lgo === null) {
          errorsToDisplay.lgo = 'Le choix de votre lgo est requis'
        }

        if (!passwordPolicyHelper.isValid(this.password)) {
          errorsToDisplay.password = this.passwordInfo
        } else if (this.password !== this.passwordConfirmation) {
          errorsToDisplay.password = 'Les 2 mots de passe entrés ne correspondent pas'
        }

        if (!this.checkedTERMS) {
          errorsToDisplay.page = 'Veuillez accepter la politique de confidentialité des données pour continuer'
        }
        if (!this.checkedCGU) {
          errorsToDisplay.page = 'Veuillez accepter les CGU pour continuer'
        }

        this.errors = errorsToDisplay
      }
    }
  }
}
</script>
