<template>
  <div>
    <div>
      <div v-if="ready">
        <div class="flex flex-1 flex-row justify-between p-5">
          <markdown
            :content="description"
          />
        </div>
        <SurveyInline
          v-if="active && verdictIsPositive"
          ref="survey"
          :survey="step.steppable.survey"
          :consumer-key="consumerKey"
          :pathway-step-id="step.id"
          :stored-instance-id="storedInstanceId"
        />
        <div class="flex flex-row justify-end align-center p-5">
          <div
            v-if="error"
            class="text-red-500 font-italic text-xs mr-5"
          >
            {{ error }}
          </div>
          <BaseButton
            :primary="true"
            class="text-xs font-semibold"
            @click="start"
          >
            {{ step.action }}
          </BaseButton>
        </div>
      </div>
      <div v-else>
        <div class="flex flex-1 flex-row justify-between px-14 py-20">
          {{ delayMessage }}
        </div>
        <pathway-delay-label
          v-if="!ready"
          @onSkipDelay="skipDelayMethod()"
        />
      </div>
    </div>
    <PathwayStepInsert
      v-if="active"
      :step="step"
    />
  </div>
</template>

<script>
import SurveyInline from '@/renderer/app/survey/components/SurveyInline.vue'
import Markdown from '@/renderer/app/core/components/markdown/Markdown.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import PathwayStepInsert from '@/renderer/app/pathway/components/PathwayStepInsert.vue'
import PathwayDelayLabel from '@/renderer/app/pathway/components/PathwayDelayLabel.vue'

export default {
  name: 'PathwayAftercareStep',
  components: { PathwayDelayLabel, PathwayStepInsert, BaseButton, Markdown, SurveyInline },
  props: {
    step: {
      type: Object,
      required: true
    },
    consumerKey: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      error: null
    }
  },
  computed: {
    ready () {
      if (!this.step.delay) {
        return true
      }

      return this.step.elapsed >= this.step.delay
    },
    verdictIsPositive () {
      if (Array.isArray(this.step.steppable.verdict)) {
        return this.step.steppable.verdict.length > 0
      }
      return !!this.step.steppable.verdict?.verdict
    },
    storedInstanceId () {
      return this.step.consumer_step?.steppable?.id
    },
    description () {
      return this.step.resolved.description
    },
    delayMessage () {
      return 'Merci 🙂 ' + this.step.resolved.description
    }
  },
  methods: {
    start (event) {
      this.error = null

      if (!this.verdictIsPositive || (this.verdictIsPositive && this.$refs.survey.answered)) {
        return this.$listeners.start(event)
      }

      this.error = 'Merci de répondre au questionnaire avant de continuer.'

      return Promise.resolve()
    },
    skipDelayMethod () {
      this.$emit('skipDelay')
    }
  }
}
</script>
