/**
 * The dependency container.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 */
import dayjs from 'dayjs'
import ContentfulService from '@/renderer/services/ContentfulService'
import apiClient from '@/renderer/http/clients/apiClient'
import hdsClient from '@/renderer/http/clients/hdsClient'
import StorageService from '@/renderer/services/StorageService'
import HostResource from '@/renderer/http/resources/HostResource'
import SanteSecureResource from '@/renderer/http/resources/SanteSecureResource'
import UsersResource from '@/renderer/http/resources/UsersResource'
import EventResource from '@/renderer/http/resources/EventResource'
import PartnerResource from '@/renderer/http/resources/PartnerResource'
import NotificationService from '@/renderer/services/NotificationService'
import CustomerResource from '@/renderer/http/resources/CustomerResource'
import PharmamlResource from '@/renderer/http/resources/PharmamlResource'
import ShopCartResource from '@/renderer/http/resources/ShopCartResource'
import ShopOrderResource from '@/renderer/http/resources/ShopOrderResource'
import SelloutTeaserService from '@/renderer/services/SelloutTeaserService'
import DetectionResource from '@/renderer/http/resources/DetectionResource'
import AuthenticationService from '@/renderer/services/AuthenticationService'
import RegistrationService from '@/renderer/services/RegistrationService'
import SellinOfferResource from '@/renderer/http/resources/SellinOfferResource'
import EnvironmentResource from '@/renderer/http/resources/EnvironmentResource'
import LoyaltyCardResource from '@/renderer/http/resources/LoyaltyCardResource'
import NotificationResource from '@/renderer/http/resources/NotificationResource'
import CustomerCertificationResource from '@/renderer/http/resources/CustomerQrCodeResource'
import EmergencyOfferResource from '@/renderer/http/resources/EmergencyOfferResource'
import SellinChallengeResource from '@/renderer/http/resources/SellinChallengeResource'
import CustomerSegmentResource from '@/renderer/http/resources/CustomerSegmentResource'
import LaboratoryEventResource from '@/renderer/http/resources/LaboratoryEventResource'
import LaboratoryFrancoResource from '@/renderer/http/resources/LaboratoryFrancoResource'
import LaboratoryProductResource from '@/renderer/http/resources/LaboratoryProductResource'
import LaboratoryBrandingResource from '@/renderer/http/resources/LaboratoryBrandingResource'
import LoyaltyCardProductResource from '@/renderer/http/resources/LoyaltyCardProductResource'
import RewardDistributionResource from '@/renderer/http/resources/RewardDistributionResource'
import SelloutParticipationResource from '@/renderer/http/resources/SelloutParticipationResource'
import HealthcenterOperatorResource from '@/renderer/http/resources/HealthcenterOperatorResource'
import HealthcenterSearchResource from '@/renderer/http/resources/HealthcenterSearchResource'
import GroupingsResource from '@/renderer/http/resources/GroupingsResource'
import ProSanteConnectResource from '@/renderer/http/resources/ProSanteConnectResource'
import LgoResource from '@/renderer/http/resources/LgoResource'
import LoyaltyCardMembershipResource from '@/renderer/http/resources/LoyaltyCardMembershipResource'
import InstantDiscountVoucherResource from '@/renderer/http/resources/InstantDiscountVoucherResource'
import LaboratoryRangeProductResource from '@/renderer/http/resources/LaboratoryRangeProductResource'
import HealthcenterReassortConfigResource from '@/renderer/http/resources/HealthcenterReassortConfigResource'
import LoyaltyCardMembershipRewardResource from '@/renderer/http/resources/LoyaltyCardMembershipRewardResource'
import LoyaltyCardMembershipCreditResource from '@/renderer/http/resources/LoyaltyCardMembershipCreditResource'
import LaboratoryMerchandisingPlanResource from '@/renderer/http/resources/LaboratoryMerchandisingPlanResource'
import CustomerInstantDiscountVoucherResource from '@/renderer/http/resources/CustomerInstantDiscountVoucherResource'
import LoyaltyCardMembershipTransactionResource from '@/renderer/http/resources/LoyaltyCardMembershipTransactionResource'
import CustomerProgramResource from '@/renderer/http/resources/CustomerProgramResource'
import ProgramResource from '@/renderer/http/resources/ProgramResource'
import ExtensionResource from '@/renderer/http/resources/ExtensionResource'
import SurveyResource from '@/renderer/http/resources/SurveyResource'
import ProgramAttachmentResource from '@/renderer/http/resources/ProgramAttachmentResource'
import PathwayService from '@/renderer/services/PathwayService'
import PrescriptorResource from '@/renderer/http/resources/PrescriptorResource'
import GuidanceResource from '@/renderer/http/resources/GuidanceResource'
import PathwayResource from '@/renderer/http/resources/PathwayResource'
import CartResource from './http/resources/CartResource'
import OrganizationCreditResource from '@/renderer/http/resources/OrganizationCreditResource'
import NotificationTemplateResource from '@/renderer/http/resources/NotificationTemplateResource'
import HubspotResource from './http/resources/HubspotResource'
import ExperienceResource from '@/renderer/http/resources/ExperienceResource'
import MedicineCabinetResource from '@/renderer/http/resources/MedicineCabinetResource'
import DocumentResource from '@/renderer/http/resources/DocumentResource'
import SurveyAnswerInstanceResource from '@/renderer/http/resources/SurveyAnswerInstanceResource'
import HospitalFileResource from '@/renderer/http/resources/HospitalFileResource'
import OrderResource from '@/renderer/http/resources/OrderResource'
import TeamResource from '@/renderer/http/resources/TeamResource'
import SecureFileResource from '@/renderer/http/resources/SecureFileResource'
import ConsumerProductResource from '@/renderer/http/resources/CustomerProductResource'
import DrugInteractionResource from '@/renderer/http/resources/DrugInteractionResource'
import InterviewResource from '@/renderer/http/resources/InterviewResource'
import CustomerCartResource from '@/renderer/http/resources/ConsumerCartResource'
import SurveyBundleResource from '@/renderer/http/resources/SurveyBundleResource'
import PrescriptionResource from '@/renderer/http/resources/PrescriptionResource'
import HealthcenterUserResource from '@/renderer/http/resources/HealthcenterUserResource'
import ProductResource from '@/renderer/http/resources/ProductResource'
import DisruptionLogisticsResource from '@/renderer/http/resources/DisruptionLogisticsResource'
import WholesalerResource from '@/renderer/http/resources/WholesalerResource'

const contentful = require('contentful')

/**
 * Bind the storage service.
 * @returns {Object} The storage service.
 */
export function storageService () {
  return new StorageService()
}

/**
 * Bind the authentication service.
 * @returns {Object} The authentication service.
 */
export function authenticationService () {
  return new AuthenticationService(
    apiClient,
    storageService
  )
}

/**
 * Bind the registration service.
 * @returns {Object} The registration service.
 */
export function registrationService () {
  return new RegistrationService(
    apiClient
  )
}

/**
 * Bind the contentful service.
 * @returns {Object} The contentful service.
 */
export function contentfulService () {
  return new ContentfulService(
    contentful
  )
}

/**
 * Bind the survey service.
 * @returns {Object} The contentful service.
 */
export function pathwayService () {
  return new PathwayService()
}

/**
 * Bind the notification service.
 * @returns {Object} The notification service.
 */
export function notificationService () {
  return new NotificationService()
}

/**
 * Bind the users resource.
 * @returns {Object} The users resource.
 */
export function usersResource () {
  return new UsersResource(
    apiClient
  )
}

/**
 * Bind the host resource.
 * @returns {Object} The host resource.
 */
export function hostResource () {
  return new HostResource(
    apiClient
  )
}

/**
 * Bind the hubspot resource.
 * @returns {Object} The hubspot resource.
 */
export function hubspotResource () {
  return new HubspotResource(
    apiClient
  )
}

/**
 * Bind the sante-secure resource.
 * @returns {Object} The host resource.
 */
export function santeSecureResource () {
  return new SanteSecureResource(
    apiClient
  )
}

/**
 * Bind the environment resource.
 * @returns {Object} The environment resource.
 */
export function environmentResource () {
  return new EnvironmentResource(
    apiClient
  )
}

/**
 * Bind the customer resource.
 * @returns {Object} The customer resource.
 */
export function customerResource () {
  return new CustomerResource(
    hdsClient
  )
}

/**
 * Bind the customer QR code resource.
 * @returns {Object} The customer QR code resource.
 */
export function customerCertificationResource () {
  return new CustomerCertificationResource(
    hdsClient
  )
}

/**
 * Bind the detection resource.
 * @returns {Object} The detection resource.
 */
export function detectionResource () {
  return new DetectionResource(
    hdsClient
  )
}

/**
 * Bind the customer segment resource.
 * @returns {Object} The customer segment resource.
 */
export function customerSegmentResource () {
  return new CustomerSegmentResource(
    hdsClient
  )
}

/**
 * Bind the customer program resource.
 * @returns {Object} The customer program resource.
 */
export function customerProgramResource () {
  return new CustomerProgramResource(
    apiClient
  )
}

/**
 * Bind the program resource.
 *
 * @returns {Object} The  program resource.
 */
export function programResource () {
  return new ProgramResource(
    apiClient,
    hdsClient
  )
}

/**
 * Bind the program attachment resource.
 *
 * @return {Object}
 */
export function programAttachmentResource () {
  return new ProgramAttachmentResource(
    apiClient
  )
}

/**
 * Bind the extension resource.
 *
 * @returns {Object} The  program resource.
 */
export function extensionResource () {
  return new ExtensionResource(
    apiClient
  )
}

/**
 * Bind the customer instant discount voucher resource.
 * @returns {Object} The customer instant discount voucher resource.
 */
export function customerInstantDiscountVoucherResource () {
  return new CustomerInstantDiscountVoucherResource(
    hdsClient
  )
}

/**
 * Bind the healthcenter operator resource.
 * @returns {Object} The healthcenter operator resource.
 */
export function healthcenterOperatorResource () {
  return new HealthcenterOperatorResource(
    hdsClient
  )
}

/**
 * Bind the healthcenter operator resource.
 * @returns {Object} The healthcenter operator resource.
 */
export function healthcenterSearchResource () {
  return new HealthcenterSearchResource(
    apiClient
  )
}

/**
 * Bind the groupings resource.
 * @returns {Object} The groupings resource.
 */
export function groupingsResource () {
  return new GroupingsResource(
    apiClient
  )
}

/**
 * Bind the PharmaML resource.
 * @returns {Object} The PharmaML resource.
 */
export function pharmamlResource () {
  return new PharmamlResource(
    apiClient
  )
}

/**
 * Bind the pro sante connect resource.
 * @returns {Object} The pro sante connect operator resource.
 */
export function proSanteConnectResource () {
  return new ProSanteConnectResource(
    apiClient
  )
}

/**
 * Bind the lgo resource.
 * @returns {Object} The lgo operator resource.
 */
export function lgoResource () {
  return new LgoResource(
    apiClient
  )
}

/**
 * Bind the laboratory order resource.
 * @returns {Object} The laboratory order resource.
 */
export function emergencyOfferResource () {
  return new EmergencyOfferResource(
    apiClient
  )
}

/**
 * Bind the loyalty card resource.
 * @returns {Object} The loyalty card resource.
 */
export function loyaltyCardResource () {
  return new LoyaltyCardResource(
    apiClient,
    hdsClient
  )
}

/**
 * Bind the loyalty card resource.
 * @returns {Object} The loyalty card resource.
 */
export function cartResource () {
  return new CartResource(
    apiClient,
    hdsClient
  )
}

/**
 * Bind the loyalty card product resource.
 * @returns {Object} The loyalty card product resource.
 */
export function loyaltyCardProductResource () {
  return new LoyaltyCardProductResource(
    apiClient
  )
}

/**
 * Bind the loyalty card membership resource.
 * @returns {Object} The loyalty card membership resource.
 */
export function loyaltyCardMembershipResource () {
  return new LoyaltyCardMembershipResource(
    hdsClient
  )
}

/**
 * Bind the loyalty card membership transaction resource.
 * @returns {Object} The loyalty card membership transaction resource.
 */
export function loyaltyCardMembershipTransactionResource () {
  return new LoyaltyCardMembershipTransactionResource(
    hdsClient
  )
}

/**
 * Bind the loyalty card membership reward resource.
 * @returns {Object} The loyalty card membership reward resource.
 */
export function loyaltyCardMembershipRewardResource () {
  return new LoyaltyCardMembershipRewardResource(
    hdsClient
  )
}

/**
 * Bind the loyalty card membership credit resource.
 * @returns {Object} The loyalty card membership credit resource.
 */
export function loyaltyCardMembershipCreditResource () {
  return new LoyaltyCardMembershipCreditResource(
    hdsClient
  )
}

/**
 * Bind the notification resource.
 * @returns {Object} The notification resource.
 */
export function notificationResource () {
  return new NotificationResource(
    apiClient
  )
}

/**
 * Bind the organization credit resources.
 *
 * @return {Object}
 */
export function organizationCreditResource () {
  return new OrganizationCreditResource(apiClient)
}

/**
 * Bind the instant discount voucher resource.
 * @returns {Object} The instant discount voucher resource.
 */
export function instantDiscountVoucherResource () {
  return new InstantDiscountVoucherResource(
    apiClient,
    hdsClient
  )
}

/**
 * Bind the partner resource.
 * @returns {Object} The partner resource.
 */
export function partnerResource () {
  return new PartnerResource(
    apiClient
  )
}

/**
 * Bind the laboratory branding resource.
 * @returns {Object} The laboratory branding resource.
 */
export function laboratoryBrandingResource () {
  return new LaboratoryBrandingResource(
    apiClient
  )
}

/**
 * Bind the laboratory range product resource.
 * @returns {Object} The laboratory range product resource.
 */
export function laboratoryRangeProductResource () {
  return new LaboratoryRangeProductResource(
    apiClient
  )
}

/**
 * Bind the laboratory product resource.
 * @returns {Object} The laboratory product resource.
 */
export function laboratoryProductResource () {
  return new LaboratoryProductResource(
    apiClient
  )
}

/**
 * Bind the laboratory franco resource.
 * @returns {Object} The laboratory franco resource.
 */
export function laboratoryFrancoResource () {
  return new LaboratoryFrancoResource(
    apiClient
  )
}

/**
 * Bind the shop cart resource.
 * @returns {Object} The shop cart resource.
 */
export function shopCartResource () {
  return new ShopCartResource(
    apiClient
  )
}

/**
 * Bind the laboratory event resource.
 * @returns {Object} The laboratory event resource.
 */
export function laboratoryEventResource () {
  return new LaboratoryEventResource(
    apiClient
  )
}

/**
 * Bind the sellin offer resource.
 * @returns {Object} The sellin offer resource.
 */
export function sellinOfferResource () {
  return new SellinOfferResource(
    apiClient
  )
}

/**
 * Bind the sellin challenge resource.
 * @returns {Object} The sellin challenge resource.
 */
export function sellinChallengeResource () {
  return new SellinChallengeResource(
    apiClient
  )
}

/**
 * Bind the healthcenter reassort config resource.
 * @returns {Object} The healthcenter reassort config resource.
 */
export function healthcenterReassortConfigResource () {
  return new HealthcenterReassortConfigResource(
    apiClient
  )
}

/**
 * Bind the sellout participation resource.
 * @returns {Object} The sellout participation resource.
 */
export function selloutParticipationResource () {
  return new SelloutParticipationResource(
    apiClient
  )
}

/**
 * Bind the sellout teaser service.
 * @returns {Object} The sellout teaser service.
 */
export function selloutTeaserService () {
  return new SelloutTeaserService(
    dayjs
  )
}

/**
 * Bind the reward distribution resource.
 * @returns {Object} The reward distribution resource.
 */
export function rewardDistributionResource () {
  return new RewardDistributionResource(
    apiClient
  )
}

/**
 * Bind the shop order resource.
 * @returns {Object} The shop order resource.
 */
export function shopOrderResource () {
  return new ShopOrderResource(
    apiClient
  )
}

/**
 * Bind the event resource.
 * @returns {Object} The event resource.
 */
export function eventResource () {
  return new EventResource(
    apiClient
  )
}

/**
 * Bind the Experience resource.
 *
 * @return {Object}
 */
export function experienceResource () {
  return new ExperienceResource(
    apiClient
  )
}

/**
 * Bind the laboratory merchandising plan resource.
 * @returns {Object} The laboratory merchandising plan resource.
 */
export function laboratoryMerchandisingPlanResource () {
  return new LaboratoryMerchandisingPlanResource(
    apiClient
  )
}

/**
 * Bind the survey resource.
 *
 * @returns {Object} The survey resource.
 */
export function surveyApi () {
  return new SurveyResource(apiClient)
}

/**
 * Bind the survey resource.
 *
 * @returns {Object} The survey resource.
 */
export function surveyBundleApi () {
  return new SurveyBundleResource(apiClient)
}

/**
 * Bind the survey answer instance resource.
 * As some endpoint need authorization check we need to use the hds client.
 * Often, the request is forwarded to the api.
 *
 * @return {Object}
 */
export function surveyAnswerInstanceApi () {
  return new SurveyAnswerInstanceResource(hdsClient)
}

/**
 * Bind the guidance api.
 *
 * @returns {Object} The guidance resource.
 */
export function guidanceApi () {
  return new GuidanceResource(
    apiClient,
    hdsClient
  )
}

/**
 * Bind the guidance api.
 *
 * @returns {Object} The guidance resource.
 */
export function documentApi () {
  return new DocumentResource(apiClient)
}

/**
 * Bind the pathway api.
 *
 * @returns {Object} The guidance resource.
 */
export function pathwayApi () {
  return new PathwayResource(apiClient)
}

/**
 * Bind the prescriptor resource.
 *
 * @returns {Object} The prescriptor resource.
 */
export function prescriptorResource () {
  return new PrescriptorResource(
    hdsClient
  )
}

/**
 * Bind the message template resource.
 * @return {Object}
 */
export function notificationTemplateResource () {
  return new NotificationTemplateResource(
    hdsClient
  )
}

/**
 * Bind the medicine cabinet resource.
 *
 * @returns {Object} The survey resource.
 */
export function medicineCabinetApi () {
  return new MedicineCabinetResource(hdsClient)
}

/**
 * Bind the hospital file resource.
 *
 * @return {Object}
 */
export function hospitalFileApi () {
  return new HospitalFileResource(hdsClient)
}

/**
 * Bind the order resource.
 *
 * @return {Object}
 */
export function orderApi () {
  return new OrderResource(hdsClient)
}

/**
 * Bind the team resource.
 *
 * @return {Object}
 */
export function teamApi () {
  return new TeamResource(apiClient)
}

/**
 * Bind the order resource.
 *
 * @return {Object}
 */
export function secureFileApi () {
  return new SecureFileResource(hdsClient)
}

/**
 * Bind the interview resource
 */
export function interviewApi () {
  return new InterviewResource(apiClient, hdsClient)
}

/**
 * Bind the customer product resource.
 *
 * @return {Object}
 */
export function consumerProductApi () {
  return new ConsumerProductResource(hdsClient)
}

/**
 * Bind the drug interaction resource.
 *
 * @return {Object}
 */
export function drugInteractionApi () {
  return new DrugInteractionResource(apiClient)
}

/**
 * Bind the prescription resource.
 *
 * @return {Object}
 */
export function prescriptionApi () {
  return new PrescriptionResource(hdsClient)
}

/**
 * Bind the customer cart resource.
 *
 * @returns {Object}
 */
export function consumerCartApi () {
  return new CustomerCartResource(hdsClient)
}

/**
 * Bind the healthcenter user resource.
 *
 * @return {Object}
 */
export function healthcenterUserApi () {
  return new HealthcenterUserResource(hdsClient)
}

/**
 * Bind the product resource.
 *
 * @returns {Object} The product resource.
 */
export function productApi () {
  return new ProductResource(apiClient)
}

/**
 * Bind the premium disruption resource.
 *
 * @returns {Object}
 */
export function disruptionLogisticsApi () {
  return new DisruptionLogisticsResource(apiClient)
}

/**
 * Bind the wholesaler resource.
 *
 * @return {Object}
 */
export function wholesalerApi () {
  return new WholesalerResource(apiClient)
}
