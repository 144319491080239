<template>
  <div class="bg-white">
    <p class="px-4">
      Sélectionnez des produits parmi ceux délivrés au patient pour analyser les interactions médicamenteuses. Vous
      pouvez aussi ajouter un produit.
    </p>
    <BaseSpinner
      v-if="loading"
      size="small"
    />
    <div
      v-else
      class="my-4"
    >
      <div
        v-for="product in products"
        v-if="products"
      >
        <v-checkbox
          v-if="product.id_13"
          v-model="selected"
          :value="product.id_13"
          class="px-4"
        >
          <template v-slot:label>
            <div class="p-2">
              <p>{{ product.name }}</p>
              <p class="text-gray-500">
                {{ product.id_13 }}
              </p>
            </div>
          </template>
        </v-checkbox>
      </div>
    </div>
    <div
      v-if="!!selected.length"
      class="flex flex-1 justify-center mb-4"
    >
      <base-button
        primary
        class="my-2 w-80"
        @click="launchAnalysis"
      >
        Lancer l'analyse
      </base-button>
    </div>
  </div>
</template>

<script>

import { consumerProductApi } from '@/renderer/container'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'

export default {
  name: 'DrugSelection',
  components: { BaseButton, BaseSpinner },
  props: {
    consumerKey: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      products: null,
      loading: true,
      selected: []
    }
  },
  mounted () {
    this.loadProducts()
  },
  methods: {
    loadProducts () {
      consumerProductApi().index(this.consumerKey)
        .then(response => {
          this.products = response
        })
        .finally(() => this.loading = false)
    },
    launchAnalysis () {
      if (this.selected.length) {
        this.$emit('launchAnalysis', this.selected)
      }
    }
  }
}
</script>
