<template>
  <v-icon
    :color="color"
    :size="size"
  >
    mdi-account-group
  </v-icon>
</template>

<script>

export default {
  props: {
    size: {
      type: Number,
      default: 30
    },
    color: {
      type: String,
      default: 'black'
    }
  }
}
</script>

<style scoped>

</style>
