var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header-back-button',{attrs:{"title":`Historique de disponibilité de ${_vm.productName}`}}),_c('div',{staticClass:"flex flex-col p-3"},[(_vm.loading)?_c('base-flex-spinner'):_c('div',{staticClass:"flex flex-col gap-2"},[_c('div',{staticClass:"flex gap-2 items-center"},[_c('div',{staticClass:"w-4 h-4 rounded-full",class:{
            'bg-green-500': _vm.availability.available,
            'bg-red-500': !_vm.availability.available
          }}),_c('p',{staticClass:"font-semibold text-primary"},[_vm._v(" Produit "+_vm._s(_vm.availability.available ? 'disponible' : 'indisponible')+" chez le dépositaire en direct ")])]),_c('h3',{staticClass:"font-content-bold"},[_vm._v(" Historique de disponibilité : ")]),(!_vm.histories)?_c('p',[_vm._v(" Aucun historique de disponibilité n'est disponible ")]):_vm._e(),_vm._l((_vm.histories),function(history){return _c('div',{key:history.id,staticClass:"flex gap-2 items-center bg-white rounded-lg shadow p-3 text-md"},[_c('div',{staticClass:"w-4 h-4 rounded-full",class:{
            'bg-green-500': history.was,
            'bg-red-500': !history.was
          }}),_c('p',[_vm._v(" Était "+_vm._s(history.was ? 'disponible' : 'indisponible')+" avant le "+_vm._s(_vm._f("date")(history.updated_at,'short'))+" ")])])})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }