<template>
  <div>
    <div
      v-if="price.priceDiscountedWithoutVat < price.priceWithoutVat"
      class="flex space-x-4"
    >
      <div class="flex space-x-2">
        <div class="line-through text-lg">
          {{ price.priceWithoutVat.toFixed(2) }}€
        </div>

        <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
          -{{ parseFloat(price.discountPercentage).toFixed() }}%
        </span>
      </div>

      <div class="text-lg">
        {{ price.priceDiscountedWithoutVat.toFixed(2) }}€

        <span class="text-xs font-medium text-gray-500 whitespace-nowrap uppercase">
          ht
        </span>
      </div>
    </div>

    <div
      v-else
      class="text-lg"
    >
      {{ price.priceWithoutVat.toFixed(2) }}€

      <span class="text-xs font-medium text-gray-500 whitespace-nowrap uppercase">
        ht
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    price: {
      required: true,
      type: Object
    }
  }
}
</script>
