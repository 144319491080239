<template>
  <installation-step-layout @openGuide="$emit('openGuide')">
    <template v-slot:title>
      Installer Apodis Secure
    </template>
    <template v-slot:subtitle>
      La sécurité des données avant tout !
    </template>
    <template v-slot:image>
      <img
        :src="require('@/assets/images/icons/cloud-data.svg')"
        alt="image cloud"
        class="h-24 xl:h-32"
      >
    </template>
    <template v-slot:content>
      <p class="text-primary">
        Votre LGO est <strong>{{ lgoName }}</strong>,
      </p>
      <p v-if="lgoInstall === 'client'">
        Veuillez télécharger et installer Santé Secure sur un seul <strong>poste comptoir</strong>.
      </p>
      <p v-if="lgoInstall === 'server'">
        Veuillez télécharger et installer Santé Secure sur votre <strong>serveur</strong>.
      </p>
      <p v-if="lgoInstall === 'support'">
        Contactez-nous pour prendre rendez-vous avec <strong>Marion</strong> qui vous accompagnera ->
        <a
          target="_blank"
          href="https://meetings.hubspot.com/marion-liere"
          style="color: blue!important"
        >
          Prendre rendez-vous
        </a>
      </p>
    </template>
    <template
      v-if="lgoInstall !== 'support'"
      v-slot:download
    >
      <DownloadButton
        title="Apodis Secure"
        :icon="require('@/assets/images/icons/logo_sante_secure.png')"
        @click="$emit('downloadApodisSecure')"
      />
    </template>
  </installation-step-layout>
</template>

<script>
import { defineComponent } from 'vue'
import DownloadButton from '@/renderer/components/views/onboarding/DownloadButton.vue'
import InstallationStepLayout
from '@/renderer/components/views/onboarding/installation-process/InstallationStepLayout.vue'

export default defineComponent({
  name: 'ApodisSecureStep',
  components: { InstallationStepLayout, DownloadButton },
  props: {
    lgoName: {
      type: String,
      required: true
    },
    lgoInstall: {
      type: String,
      required: true
    }
  }
})
</script>

<style scoped>

</style>
