<template>
  <div class="flex flex-wrap flex-row gap-4 justify-center">
    <div
      v-for="answer in question.answers"
      :key="answer.value"
    >
      <div class="mr-5 grid justify-items-center">
        <SurveyImage
          v-if="answer?.image"
          :survey-id="surveyId"
          :question-id="question.id"
          :answer-id="answer.value"
          class="w-32 h-32"
        />
      </div>
      <div
        class="bg-white cursor-pointer rounded-md p-5 border border-gray-300 shadow-md mr-5"
        :style="{'background-color': isValueSelected(answer.value) ? (answer?.color || '#3513e2') : 'white'}"
        @click="editable && $emit('change', answer.value)"
      >
        <span
          class="font-content"
          :style="{'color': isValueSelected(answer.value) ? 'white' : 'black'}"
        >
          {{ answer.label }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import SurveyImage from '@/renderer/app/survey/components/SurveyImage.vue'

export default {
  name: 'SurveyBuiltChoices',
  components: { SurveyImage },
  props: {
    surveyId: {
      type: Number,
      required: true
    },
    question: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array],
      default: null
    }
  },
  methods: {
    /**
     * If the value has been an user answer.
     *
     * @param value
     *
     * @return {boolean}
     */
    isValueSelected (value) {
      if (!this.value?.length) {
        return false
      }

      return this.value.includes(value)
    }
  }
}
</script>
