import { interviewApi, surveyApi } from '@/renderer/container'

export default {
  namespaced: true,
  state: {
    /**
     * The interview.
     *
     * @type {Object|null}
     */
    interview: null,
    /**
     * The interview attachments.
     *
     * @type {Array|null}
     */
    attachments: null,
    /**
     * The surveys.
     */
    surveys: null,
    /**
     * Whether the app is recording.
     *
     * @type {boolean}
     */
    recording: false,
    /**
     * The transcript.
     * Transcript is only available if an audio was recorded.
     *
     * @type {Object|null}
     */
    transcript: null,
    /**
     * The survey answers made by LLM service.
     * LLM survey answers are only available if an audio was recorded.
     */
    llmSurveyAnswers: null,
    /**
     * Number of notes.
     */
    noteCount: null,
    /**
     * The loading states.
     *
     * @type {Object}
     */
    loading: {
      interview: false,
      attachments: false,
      surveys: false,
      transcript: false,
      llmSurveyAnswers: false
    },
    /**
     * The consumer key.
     *
     * @type {string|null}
     */
    consumerKey: null
  },
  mutations: {
    /**
     * Generic mutation to update state properties.
     * @param {Object} state - The current state of the store.
     * @param {Object} payload - Contains the property to update and its new value.
     */
    updateStateProperty (state, { propertyPath, value }) {
      const pathArray = propertyPath.split('.')
      let current = state
      for (let i = 0; i < pathArray.length - 1; i++) {
        current = current[pathArray[i]]
      }

      current[pathArray[pathArray.length - 1]] = value
    },
    resetStates (state) {
      state.interview = null
      state.attachments = null
      state.transcript = null
      state.llmSurveyAnswers = null
      state.recording = false
      state.loading = {
        interview: false,
        attachments: false,
        transcript: false,
        llmSurveyAnswers: false,
        surveys: false
      }
      state.consumerKey = null
    },
    setRecording (state, value) {
      state.recording = value
    },
    setConsumerKey (state, value) {
      state.consumerKey = value
    }
  },
  actions: {
    /**
     * Initialize the interview by reading the interview and fetching the attachments.
     *
     * @param dispatch
     * @param commit
     * @param interviewID
     * @param consumerKey
     * @returns {Promise<void>}
     */
    initialize ({ dispatch, commit }, { interviewID, consumerKey }) {
      commit('resetStates')
      commit('setConsumerKey', consumerKey)
      commit('updateStateProperty', { propertyPath: 'loading.interview', value: true })
      commit('updateStateProperty', { propertyPath: 'loading.attachments', value: true })
      commit('updateStateProperty', { propertyPath: 'loading.surveys', value: true })

      return Promise.all([
        dispatch('readInterview', interviewID),
        dispatch('fetchAttachments', interviewID),
        dispatch('fetchSurveys'),
        dispatch('getNoteCount')
      ]).finally(() => {
        commit('updateStateProperty', { propertyPath: 'loading.interview', value: false })
        commit('updateStateProperty', { propertyPath: 'loading.attachments', value: false })
        commit('updateStateProperty', { propertyPath: 'loading.surveys', value: false })
      })
    },
    /**
     * Read an interview by its ID.
     *
     * @param {Function} commit
     * @param {Number} interviewId
     *
     * @returns {Promise<void>}
     */
    readInterview ({ commit }, interviewId) {
      return interviewApi()
        .read(interviewId)
        .then(interview => {
          commit('updateStateProperty', { propertyPath: 'interview', value: interview })
        })
    },
    /**
     * Fetch attachments for an interview.
     *
     * @param {Function} commit
     * @param {Number} interviewId
     *
     * @returns {Promise<void>}
     */
    fetchAttachments ({ commit }, interviewId) {
      return interviewApi()
        .attachments(interviewId)
        .then(attachments => {
          commit('updateStateProperty', { propertyPath: 'attachments', value: attachments })
        })
    },
    /**
     * Fetch surveys.
     *
     * @param commit
     * @returns {Promise<void>}
     */
    fetchSurveys ({ commit }) {
      return surveyApi()
        .index()
        .then(surveys => {
          commit('updateStateProperty', { propertyPath: 'surveys', value: surveys })
        })
    },

    /**
     * Get the number of notes for the consumer.
     *
     * @param commit
     * @param state
     * @returns {Promise<void>}
     */
    getNoteCount ({ commit, state }) {
      return interviewApi().indexNotes(state.consumerKey, 1, 1)
        .then((data) => {
          commit('updateStateProperty', { propertyPath: 'noteCount', value: data.meta.total })
        })
    },

    /**
     * Read the transcript for an interview.
     *
     * @param {Function} commit
     * @param {Function} dispatch
     * @param {Object} state
     * @param {Number} interviewId The interview ID.
     * @param {Blob} audio The audio blob.
     *
     * @returns {Promise<void>}
     */
    readTranscript ({ commit, dispatch, state }, { interviewId, audio }) {
      commit('updateStateProperty', { propertyPath: 'loading.transcript', value: true })
      return interviewApi()
        .transcript(interviewId, audio, state.consumerKey)
        .then(transcript => {
          commit('updateStateProperty', { propertyPath: 'transcript', value: transcript })

          const transcriptText = transcript.utterances.map((utterance) => utterance.text).join(' ')

          dispatch('prefillInterviewSurvey', { interviewId, transcriptId: transcript.transcript_id, transcriptText })
        })
        .finally(() => {
          commit('updateStateProperty', { propertyPath: 'loading.transcript', value: false })
        })
    },
    /**
     * Get the interview survey answers from the transcript filled with LLM service.
     *
     * @param {Function} commit
     * @param {Number} interviewId
     * @param {String} transcriptId
     *
     * @returns {*}
     */
    prefillInterviewSurvey ({ commit }, { interviewId, transcriptId, transcriptText }) {
      commit('updateStateProperty', { propertyPath: 'loading.llmSurveyAnswers', value: true })
      return interviewApi()
        .prefillInterviewSurvey(interviewId, transcriptId, transcriptText)
        .then((response) => {
          commit('updateStateProperty', { propertyPath: 'llmSurveyAnswers', value: response })
        })
        .finally(() => {
          commit('updateStateProperty', { propertyPath: 'loading.llmSurveyAnswers', value: false })
        })
    },
    /**
     * Add a note to the interview.
     *
     * @param commit
     * @param state
     * @param content
     * @returns {Promise<void>}
     */
    addNote ({ commit, state }, content) {
      return interviewApi()
        .createNote(state.consumerKey, state.interview?.id, content)
        .then(() => {
          commit('updateStateProperty', { propertyPath: 'noteCount', value: state.noteCount + 1 })
        })
    }
  },
  getters: {
    /**
     * Get the attachments for the healthcenter.
     *
     * @param state
     * @returns {Array}
     */
    professionalAttachments (state) {
      return state.attachments.filter((attachment) => {
        return attachment.entity_types.map((entity) => entity.slug).includes('healthcenter')
      })
    },
    /**
     * Get the attachments for the patient.
     *
     * @param state
     * @returns {Array}
     */
    patientAttachments (state) {
      return state.attachments.filter((attachment) => {
        return attachment.entity_types.map((entity) => entity.slug).includes('patient')
      })
    }
  }
}
