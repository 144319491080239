<template>
  <div v-if="isLoading">
    <BaseSpinner />
  </div>
  <div
    v-else
    class="mx-6"
  >
    <div v-if="!!animations?.length">
      <BaseCard
        v-for="animation in animations"
        class="relative overflow-visible my-5"
      >
        <BaseCardHeader>
          <div class="flex justify-between">
            <p>{{ animation.name }}</p>
            <p class="text-sm">
              Du {{ dateHelper.shortFormat(animation.start_date) }} au {{ dateHelper.shortFormat(animation.end_date) }}
            </p>
          </div>
        </BaseCardHeader>
        <BaseCardBody>
          <div class="flex justify-between align-center">
            <div>
              <p>{{ animation.description }}</p>
              <p class="text-primary">
                x{{ animation.coefficient }} appliqué aux points crédités.
              </p>
            </div>
            <BaseButton
              v-if="animation.status !== 'incoming'"
              primary
              class="justify-center"
              @click="onPress(animation)"
            >
              Voir les ventes
            </BaseButton>
          </div>
        </BaseCardBody>
      </BaseCard>
    </div>
    <div
      v-else
      class="flex align-center justify-center"
    >
      <p>Aucune animations pour cette carte de fidélité, rendez-vous sur Apodis Pro pour en créer</p>
    </div>
  </div>
</template>

<script>

import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import { loyaltyCardResource } from '@/renderer/container'
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import dateHelper from '../../../helpers/dateHelper'
import BaseCardBody from '@/renderer/components/base/BaseCardBody.vue'
import BaseCardHeader from '@/renderer/components/base/BaseCardHeader.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'

export default {
  components: { BaseButton, BaseCardHeader, BaseCardBody, BaseCard, BaseSpinner },
  data () {
    return {
      isLoading: true,
      animations: null
    }
  },
  computed: {
    dateHelper () {
      return dateHelper
    }
  },
  watch: {
    '$route.params.loyaltyCardId': {
      immediate: true,

      handler (value) {
        this.readAnimations(value)
      }
    }
  },
  methods: {
    /**
     * Read the animations of loyalty card.
     *
     * @param id - The loyalty card id.
     * @return {Promise<Array>}
     */
    readAnimations (id) {
      this.isLoading = true
      return loyaltyCardResource()
        .animations(this.$store.state.system.environment.entityId, id)
        .then(response => {
          this.animations = response
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    /**
     * Action when see sales button is pressed.
     *
     * @param animation
     */
    onPress (animation) {
      this.$router.push({
        name: 'program.detail.animations.transactions',
        params: { animation: animation }
      })
    }
  }
}

</script>
