<template>
  <BaseContainer>
    <div class="flex flex-1 flex-col p-5 bg-gradient-to-r from-darkPurple-default to-darkBlue-default">
      <div
        v-if="!answered"
        class="flex flex-row justify-between"
      >
        <div
          v-if="loading"
          class="flex flex-1"
        >
          <BaseSpinner
            size="medium-small"
            color="white"
          />
        </div>
        <div v-if="!loading && !!question">
          <div class="flex flex-row items-center">
            <div class="flex bg-white rounded-full mr-3 p-2">
              <img
                :src="require('@/assets/images/icons/pharmacist.svg')"
                class="h-12"
                alt="pharmacien"
              >
            </div>
            <span class="text-md font-bold text-white">
              {{ question.title }}
            </span>
          </div>
          <div class="flex flex-1 flex-row flex-wrap mt-5">
            <survey-answer
              :question="question"
              inline
              class="mb-5"
              @onAnswerPress="answerQuestion"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="flex flex-row items-center">
          <div class="flex bg-white rounded-full mr-3 p-2">
            <img
              :src="require('@/assets/images/icons/pharmacist.svg')"
              class="h-12"
              alt="pharmacien"
            >
          </div>
          <div>
            <span class="font-bold text-white">
              Votre réponse a bien été enregistrée, merci.
            </span>
          </div>
        </div>
      </div>
    </div>
  </BaseContainer>
</template>

<script>
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import BaseContainer from '@/renderer/components/base/BaseContainer.vue'
import SurveyAnswer from '@/renderer/app/survey/components/SurveyAnswer.vue'
import { pathwayApi, surveyApi } from '@/renderer/container'

export default {
  name: 'SurveyInline',
  components: {
    SurveyAnswer,
    BaseSpinner,
    BaseContainer
  },
  props: {
    survey: {
      type: Object,
      required: true
    },
    consumerKey: {
      type: String,
      required: true
    },
    pathwayStepId: {
      type: Number,
      required: false,
      default: null
    },
    storedInstanceId: {
      type: Number,
      required: false,
      default: null
    }
  },
  data () {
    return {
      question: null,
      answered: false,
      loading: false,
      instanceId: null
    }
  },
  mounted () {
    this.question = this.survey.sections[0].questions[0]

    if (!this.storedInstanceId) {
      surveyApi()
        .createInstance(this.survey.id, this.consumerKey)
        .then(response => {
          this.instanceId = response.id
        })
    } else {
      this.answered = true
      this.instanceId = this.storedInstanceId
    }
  },
  methods: {
    /**
     * Answer a survey question.
     *
     * @param answer The answer to register.
     */
    answerQuestion (answer) {
      this.loading = true

      surveyApi()
        .answer(this.survey.id, this.instanceId, [answer])
        .then(this.storeAnswer)
        .finally(() => {
          this.answered = true
          this.loading = false
        })
    },
    /**
     * Store the instance in pathway if a pathway exists.
     *
     * @param answer
     * @returns {Promise}
     */
    storeAnswer (answer) {
      if (!this.pathwayStepId) {
        return Promise.resolve()
      }

      return pathwayApi().storeConsumerStep(this.pathwayStepId, this.consumerKey, this.instanceId, 'survey-answer', false)
    }
  }
}
</script>
