<template>
  <BaseCard
    class="cursor-pointer"
    :style="{
      'background-image': `url(${require('../../../../../assets/images/pathway.png')})`,
      'background-size': 'contain',
      'background-repeat': 'no-repeat',
      'background-position': 'bottom right',
      'height': '150px'
    }"
  >
    <div
      class="bg-primary bg-opacity-90 self-end mb-5 p-3 text-white font-content"
      :style="{
        'max-width': '33%'
      }"
    >
      <p
        class="font-bold"
        style="margin: 0;"
      >
        Voir le parcours en {{ pathwayStepCount }} étapes
      </p>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from '@/renderer/components/base/BaseCard.vue'

export default {
  name: 'SupportProgramPathwayCard',
  components: { BaseCard },
  props: {
    pathwayStepCount: {
      type: Number,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
