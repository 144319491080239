/**
 * The Lgo resource.
 * @author Mathieu Bolard <m.bolard@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Get lgo list.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    getList () {
      return client
        .get('/lgo/list')
        .then(response => response.data)
    }
  }
}
