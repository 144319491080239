<template>
  <div class="m-6 p-5 rounded-lg bg-white">
    <strong>{{ section.title.length > 0 ? section.title : "" }}</strong>
    <SurveyBuiltQuestion
      v-for="question in section.questions"
      :key="`question-${question.id}`"
      :question="question"
      :survey-id="surveyId"
    />
  </div>
</template>

<script>
import SurveyBuiltQuestion from '@/renderer/app/survey/components/SurveyBuiltQuestion.vue'

export default {
  name: 'SurveyBuiltSection',
  components: { SurveyBuiltQuestion },
  props: {
    surveyId: {
      type: Number,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  }
}
</script>
