<template>
  <div class="mx-6 flex flex-1">
    <BaseFlexSpinner v-if="isLoading" />
    <div
      v-else
      class="flex flex-1 flex-col"
    >
      <SupportProgramTabShortcuts
        :program="program"
        :support-program="supportProgram"
      />
      <SupportProgramStatistics
        :support-program="supportProgram"
        :market-statistics="marketStatistics"
      />
    </div>
  </div>
</template>

<script>
import { programResource } from '@/renderer/container'
import SupportProgramStatistics from '@/renderer/app/program/components/support-program/SupportProgramStatistics.vue'
import SupportProgramTabShortcuts from '@/renderer/app/program/components/support-program/SupportProgramTabShortcuts.vue'
import BaseFlexSpinner from '@/renderer/components/base/spinner/BaseFlexSpinner.vue'

export default {
  name: 'SupportProgramDetailStatistics',
  components: {
    BaseFlexSpinner,
    SupportProgramTabShortcuts,
    SupportProgramStatistics
  },
  props: {
    program: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      supportProgram: {},
      marketStatistics: [],
      isLoading: true
    }
  },
  mounted () {
    Promise.all([
      this.readSupportProgram(this.program.programmable_id),
      this.readMarketStatistics(this.program.programmable_id)
    ]).finally(() => {
      this.isLoading = false
    })
  },
  methods: {
    /**
     * Read a support program.
     *
     * @param {Number} id - The support program id.
     *
     * @return {Promise<Object>}
     */
    readSupportProgram (id) {
      return programResource()
        .supportProgram(id)
        .then(supportProgram => {
          this.supportProgram = supportProgram
        })
    },
    /**
     * Read a support program market statistics.
     *
     * @param {Number} id - The support program id.
     *
     * @return {Promise<Object>}
     */
    readMarketStatistics (id) {
      return programResource()
        .statistics(id)
        .then((responseStats) => {
          this.marketStatistics = responseStats
        })
    }
  }
}
</script>
