/**
 * The registration service.
 * @author Mathieu Bolard <m.bolard@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Register.
     * @param {object} user - The user object.
     * @throws {Error} If error registering user.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    register (user) {
      return client
        .post('/users', user)
        .then(response => {
          const user = response.data.user

          if (!user) {
            throw new Error('Erreur d\'enregistrement de l\'utilisateur.')
          }

          return user
        })
    },

    /**
     * Verify email availability.
     * @param {string} email - The email of the user.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    isMailAvailable (email) {
      return client
        .get(`/users/${email}/available`)
        .then(response => response.data)
    }
  }
}
