<template>
  <div class="flex-shrink-0 flex space-x-2">
    <Discount
      v-if="membership.loyalty_card.point_rewards_count"
      :membership="membership"
      @attributed="refresh"
    />

    <Product
      v-if="membership.loyalty_card.product_rewards_count"
      :membership="membership"
      @attributed="refresh"
    />

    <Credit
      :rate="membership.loyalty_card.point.rate"
      @credited="refresh"
    />
  </div>
</template>

<script>
import Credit from '@/renderer/components/views/customer/CustomerLoyaltyMembershipCredit.vue'
import Product from '@/renderer/components/views/customer/CustomerLoyaltyMembershipProduct.vue'
import Discount from '@/renderer/components/views/customer/CustomerLoyaltyMembershipDiscount.vue'

export default {
  components: {
    Credit,
    Product,
    Discount
  },
  props: {
    /**
     * The membership to display.
     */
    membership: {
      type: Object,
      required: true
    },
    /**
     * Refresh callback when action is done.
     */
    refresh: {
      type: Function,
      required: true
    }
  }
}
</script>
