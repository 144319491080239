<template>
  <div>
    <div class="flex flew-row flex-start flex-wrap space-x-4 my-3">
      <div
        v-for="(step, index) in pathway.steps"
        :key="index"
        class="px-4 py-3 rounded-lg flex flex-row"
        :class="index + 1 === currentStep ? 'bg-pink-lighter' : index + 1 !== currentStep ? 'bg-white text-gray-400' : 'bg-white'"
      >
        <img
          v-if="stepCompleted(index)"
          alt="Green check"
          class="w-6 mr-2"
          :src="require('@/assets/images/icons/green-check.svg')"
        >
        <div
          v-else
          class="text-2xl font-bold mr-2"
        >
          {{ index + 1 }}
        </div>
        <div style="margin-top: 6px">
          {{ step.title }}
        </div>
      </div>
    </div>

    <v-stepper
      v-model="currentStep"
      class="rounded-lg"
      style="border: 1px solid rgba(53,19,226, 0.5); box-shadow: 0 3px 1px -2px rgb(53 19 226 / 20%), 0 2px 2px 0 rgb(53 19 226 / 14%), 0 1px 5px 0 rgb(53 19 226 / 12%);;"
      alt-labels
    >
      <v-stepper-items>
        <template v-for="(step, index) in pathway.steps">
          <v-stepper-content
            :key="index"
            :step="index+ 1"
            style="padding: 0;"
          >
            <component
              :is="stepComponent(step)"
              :step="step"
              :consumer-key="consumerKey"
              :active="currentStep === step.index"
              @start="() => start(step)"
              @finish="() => finish(step)"
              @skipDelay="$emit('skipDelay')"
            />
          </v-stepper-content>
        </template>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { pathwayService } from '@/renderer/container'
import PathwayAftercareStep from '@/renderer/app/pathway/components/PathwayAftercareStep.vue'
import PathwayLoopStep from '@/renderer/app/pathway/components/PathwayLoopStep.vue'
import PathwayGuidanceStep from '@/renderer/app/pathway/components/PathwayGuidanceStep.vue'
import PathwayOnboardingStep from '@/renderer/app/pathway/components/PathwayOnboardingStep.vue'
import PathwayEnd from '@/renderer/app/pathway/components/PathwayEnd.vue'
import PathwayCheckupStep from '@/renderer/app/pathway/components/PathwayCheckupStep.vue'

export default {
  name: 'PathwayStepper',
  components: {
    PathwayLoopStep,
    PathwayAftercareStep,
    PathwayGuidanceStep,
    PathwayOnboardingStep,
    PathwayCheckupStep,
    PathwayEnd
  },
  props: {
    pathway: {
      type: Object,
      required: true
    },
    consumerKey: {
      type: String,
      required: true
    }
  },
  computed: {
    currentStep () {
      return this.pathway.next_step.index
    }
  },
  methods: {
    skipDelay () {
      this.$emit('skipDelay')
    },
    start (step) {
      return pathwayService().start(this.pathway, step, this.consumerKey)
    },
    stepCompleted (index) {
      return this.currentStep > index + 1
    },
    finish (step) {
      return pathwayService().finish(this.pathway, step, this.consumerKey)
    },
    hasRepeatingStep () {
      return this.pathway.steps.some(step => !!step.repeating)
    },
    stepComponent (step) {
      switch (step.type) {
        case 'aftercare':
          return 'PathwayAftercareStep'
        case 'guidance':
          return 'PathwayGuidanceStep'
        case 'onboarding':
          return 'PathwayOnboardingStep'
        case 'checkup':
          return 'PathwayCheckupStep'
        default:
          return this.hasRepeatingStep() ? 'PathwayLoopStep' : 'PathwayEnd'
      }
    }
  }
}
</script>
