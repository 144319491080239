<template>
  <div>
    <div v-if="isLoading">
      <BaseFlexSpinner />
    </div>
    <div v-else>
      <MedicineCabinetStatistics
        v-if="featureSlug === 'medicine-cabinet'"
        :statistics="statistics"
      />
    </div>
  </div>
</template>

<script>
import BaseFlexSpinner from '@/renderer/components/base/spinner/BaseFlexSpinner.vue'
import { medicineCabinetApi, programResource } from '@/renderer/container'
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import UserSolid from '@/assets/images/icons-vue/user-solid.vue'
import StarSolid from '@/assets/images/icons-vue/star-solid.vue'
import ShopbagSolid from '@/assets/images/icons-vue/shopbag-solid.vue'
import MedicineCabinetStatistics
from '@/renderer/app/program/components/medicine-cabinet-statistics/MedicineCabinetStatistics.vue'

export default {
  name: 'FeatureStatistics',
  components: { MedicineCabinetStatistics, ShopbagSolid, StarSolid, UserSolid, BaseCard, BaseFlexSpinner },
  props: {
    program: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      statistics: null,
      featureSlug: null,
      isLoading: false
    }
  },
  watch: {
    program: {
      handler () {
        this.load()
      },
      immediate: true
    }
  },
  methods: {
    /**
     * Load statistics based on feature program slug.
     *
     * @param slug
     *
     * @return {Promise}
     */
    loadStatistics (slug) {
      if (slug === 'medicine-cabinet') {
        return medicineCabinetApi().statistics(this.$store.state.system.environment.entityId)
          .then(response => {
            this.statistics = response
          })
      } else {
        return Promise.resolve()
      }
    },
    /**
     * Load data that needs to be displayed on the screen.
     */
    load () {
      this.isLoading = true
      if (this.program.programmable_type === 'feature') {
        programResource().readFeature(this.program.programmable_id)
          .then(response => {
            this.featureSlug = response.slug
            return this.loadStatistics(response.slug)
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        this.isLoading = false
      }
    }
  }
}
</script>
