export const PSC_STATES = {
  connect_login: 'connect_login',
  register: 'register',
  linkcps: 'linkcps',
  deciphera: 'deciphera'
}

export default {
  /**
   * Returns Pro Sante Connect auth url with connect_login state
   *
   * @return string
   */
  pscAuthLoginURL () {
    return this.pscAuthURL(PSC_STATES.connect_login)
  },

  /**
   * Returns Pro Sante Connect auth url with register state
   *
   * @return string
   */
  pscAuthRegisterURL () {
    return this.pscAuthURL(PSC_STATES.register)
  },

  /**
   * Returns Pro Sante Connect auth url with linkcps state
   *
   * @return string
   */
  pscLinkURL () {
    return this.pscAuthURL(PSC_STATES.linkcps)
  },

  /**
  * Returns Pro Sante Connect auth url with given state
  *
  * @param  {string} state - State to pass to PSC.
  *
  * @return string
  */
  pscAuthURL (state) {
    const responseType = 'code'
    const scope = encodeURIComponent('openid scope_all')
    const clientId = process.env.VUE_APP_PROSANTECONNECT_CLIENT_ID
    const redirect = encodeURIComponent(process.env.VUE_APP_PROSANTECONNECT_CALLBACK_URL)

    // Valeur générée aléatoirement par le FS, recopié dans le token d'authentification pour être vérifié par le FS.
    // Il permet de se prémunir contre les attaques de rejeu
    const nonce = [...Array(20)].map(i => (~~(Math.random() * 36)).toString(36)).join('')

    const acrValues = 'eidas1'
    const params = `response_type=${responseType}&scope=${scope}&client_id=${clientId}&state=${state}&redirect_uri=${redirect}&nonce=${nonce}&acr_values=${acrValues}`
    return `${process.env.VUE_APP_PROSANTECONNECT_WALLET_URL}/auth/?${params}`
  }
}
