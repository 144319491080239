<template>
  <div class="w-full flex flex-col items-end">
    <textarea
      v-model="answer"
      autofocus
      class="w-full rounded-md border-gray-300 border-2 p-2"
      rows="5"
      placeholder="Entrez votre réponse ici..."
    />
    <base-button
      class="mt-3"
      :disabled="!question.optional && !answer"
      @click="$emit('onPress', answer)"
    >
      Suivant
    </base-button>
  </div>
</template>

<script>
import BaseButton from '@/renderer/components/base/BaseButton.vue'

export default {
  name: 'SurveyAnswerTextInput',
  components: { BaseButton },
  props: {
    question: {
      type: Object,
      required: true
    },
    inline: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      answer: undefined
    }
  }
}
</script>
