<template>
  <div class="mb-5">
    <BaseSpinner
      v-if="isLoading"
      class="flex"
    />
    <div
      v-else
    >
      <div style="height: 215px">
        <AttachmentThumbnail
          :base64="
            base64Thumbnail"
          :attachment="attachment"
          class="shadow-xl"
          style="height: 200px"
          :class="[{
            'cursor-pointer': !!$listeners.click
          }]"
          @click.native="$emit('click')"
        />
        <BaseButton
          v-if="displayPrintButton"
          type="button"
          class="absolute right-2 bottom-5 pt-2 pb-2 pr-2 pl-2 rounded-full"
          @click="$emit('click')"
        >
          <Print
            :size="25"
            class="text-primary"
          />
        </BaseButton>
      </div>
      <p
        v-if="displayTitle"
        style="max-width: fit-content;"
        class="mt-2 text-center font-bold text-sm"
      >
        {{ attachment.title }}
      </p>
      <slot />
    </div>
  </div>
</template>

<script>
import { programAttachmentResource } from '@/renderer/container'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import AttachmentThumbnail
from '@/renderer/app/program/components/program-attachment/thumbnails/AttachmentThumbnail.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import Print from '@/renderer/components/icons/Print.vue'

export default {
  name: 'ProgramAttachmentThumbnail',
  components: { AttachmentThumbnail, BaseSpinner, BaseButton, Print },
  props: {
    attachment: {
      type: Object,
      required: true
    },
    displayTitle: {
      type: Boolean,
      default: false
    },
    displayPrintButton: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      base64Thumbnail: null,
      isLoading: true,
      thumbnailComponent: null
    }
  },
  mounted () {
    programAttachmentResource()
      .renderThumbnail('programs', this.$route.params.programId, this.attachment.id)
      .then(response => {
        this.base64Thumbnail = Buffer.from(response).toString('base64')
      }).finally(() => {
        this.isLoading = false
      })
  }
}
</script>
