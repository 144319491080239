/**
 * Resource for survey answered instances.
 *
 * @param client
 * @return {Object}
 */
export default function (client) {
  return {
    /**
     * Get the answered instances.
     *
     * @param params
     * @return {*}
     */
    answeredInstance (params) {
      return client.get('/surveys/answered-instances', { params })
        .then(response => response.data)
    },
    /**
     * Get the survey answer instance export.
     *
     * @param instanceID
     * @returns {Promise<Blob>}
     */
    export (instanceID) {
      return client.get(`survey-answer-instances/${instanceID}/export`, { responseType: 'blob' })
        .then(response => response.data)
    }
  }
}
