/**
 * The sellin offer resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Read a sellin offer.
     * @param {number} id - The ID of the sellin offer.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    read (id) {
      return client.get(`/sellin-offers/${id}`)
        .then(response => response.data.sellinOffer)
    },

    /**
     * Accept a sellin offer.
     * @param {number} id - The ID of the sellin offer.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    accept (id) {
      return client.put(`/sellin-offers/${id}/accept`)
        .then(response => response.data.sellinOffer)
    },

    /**
     * Refuse a sellin offer.
     * @param {number} id - The ID of the sellin offer.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    refuse (id) {
      return client.put(`/sellin-offers/${id}/refuse`)
        .then(response => response.data.sellinOffer)
    },

    /**
     * Validate a sellin offer.
     * @param {number} healthcenterId - The ID of the healthcenter.
     * @param {number} id - The ID of the sellin offer.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    validate (healthcenterId, id) {
      return client.post(`/shop/healthcenters/${healthcenterId}/sellinOffer/${id}/validateOrderSellin`)
        .then(response => response.data.data)
    }
  }
}
