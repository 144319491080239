<template>
  <div>
    <div
      class="w-64 flex flex-col bg-white rounded-md relative overflow-hidden image-container cursor-pointer"
      :style="`box-shadow: 5px 5px 5px ${tintColor}`"
      @click="onClick()"
    >
      <div class="flex h-64">
        <base64-image
          v-if="attachment.has_thumbnail && thumbnail"
          alt="Image thumbnail"
          class="flex flex-1 object-cover"
          :base64="thumbnail"
        />
        <div
          v-else
          class="flex flex-1 items-center justify-center"
        >
          <img
            :src="require('@/assets/images/logos/apodis.svg')"
            alt="Default attachment thumbnail"
            class="object-contain w-32"
          >
        </div>
      </div>
      <div class="flex flex-col overflow-hidden p-2">
        <span class="text-primary font-medium line-clamp-3">{{ attachment.title }}</span>
      </div>
      <div
        v-if="attachment.external_link || attachment.description"
        class="overlay flex flex-col text-white font-medium text-sm"
      >
        <p
          class="text-center font-medium text-sm"
          :style="`-webkit-line-clamp: ${attachment.external_link ? 12 : 13}`"
        >
          {{ attachment.description }}
        </p>
        <a
          v-if="attachment.external_link"
          :href="attachment.external_link"
          target="_blank"
          @click.stop
        >
          <span class="text-center font-medium text-sm underline line-clamp-1 mt-2">
            {{ attachment.external_link }}
          </span>
        </a>
        <div class="flex flex-1 justify-end items-end w-full">
          <label class="self-end font-bold cursor-pointer text-end">
            Voir
          </label>
        </div>
      </div>
    </div>
    <base-modal
      v-if="attachment.type === 'video'"
      class="w-150 m-auto"
    >
      <video
        :id="`program-attachment-video_${attachment.id}`"
        width="100%"
        height="176"
        preload="auto"
        controls
      />
    </base-modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { programAttachmentResource } from '@/renderer/container'
import Base64Image from '@/renderer/app/core/components/base-64-image/Base64Image.vue'
import ProgramAttachmentService from '@/renderer/app/program/services/ProgramAttachmentService'
import BaseModal from '@/renderer/components/base/BaseModal.vue'
import ChevronRightIcon from '@/renderer/components/icons/ChevronRightIcon.vue'
import Icon from '@/renderer/app/core/icon/Icon.vue'

/**
 * A card to display an attachment.
 */
export default defineComponent({
  name: 'ProgramAttachmentCard',
  components: { Icon, ChevronRightIcon, BaseModal, Base64Image },
  props: {
    /**
     * The type of the attachable model linked with the attachment.
     *
     * @values programs, program-categories, program-bundles
     */
    attachableType: {
      type: String,
      required: true
    },
    /**
     * The attachment to display.
     */
    attachment: {
      type: Object,
      required: true
    },
    /**
     * The id of the attachable model linked with the attachment.
     */
    attachableId: {
      type: Number,
      required: true
    },
    /**
     * The tint color of the card.
     */
    tintColor: {
      type: String,
      default: '#000000'
    }
  },
  data () {
    return {
      thumbnail: null,
      video: null,
      showModal: false
    }
  },
  /**
   * On mount, if attachment has thumbnail, render it.
   */
  mounted () {
    if (this.attachment.has_thumbnail) {
      programAttachmentResource()
        .renderThumbnail(this.attachableType, this.attachableId, this.attachment.id)
        .then(response => {
          this.thumbnail = Buffer.from(response).toString('base64')
        })
    }
  },
  methods: {
    /**
     * On click, open the attachment.
     * If no file is attached, open the external link.
     */
    onClick () {
      if (!this.attachment.type && this.attachment.external_link) {
        window.open(this.attachment.external_link, '_blank')
        return
      }

      switch (this.attachment.type) {
        case 'document':
        case 'image':
          ProgramAttachmentService.downloadAttachmentAndOpen(
            this.attachableType,
            this.attachableId,
            this.attachment.id
          )
          break
        case 'video':
          this.openVideo()
          break
      }
    },
    /**
     * Open a video attachment in a modal.
     */
    openVideo () {
      programAttachmentResource()
        .renderAttachment(this.attachableType, this.attachableId, this.attachment.id)
        .then(response => {
          this.video = document.getElementById(`program-attachment-video_${this.attachment.id}`)
          this.video.src = window.URL.createObjectURL(new Blob([response], { type: response.type }))
          this.video.load()
          this.video.onloadeddata = () => {
            this.showModal = true
            this.video.play()
          }
        })
    }
  }
})
</script>

<style scoped>
.overlay {
  position: absolute;
  right: 0;
  left: 0;
  bottom: -100%;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  width: 100%;
  height: 100%;
  transition: .5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.overlay p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 13;
  overflow: hidden;
}

.image-container:hover .overlay {
  bottom: 0;
}

.image-container img {
  transition: transform .5s ease;
}

.image-container:hover img {
  transform: scale(1.2);
}
</style>
