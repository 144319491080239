<template>
  <unicon
    :name="name"
    :fill="color"
    :width="size"
    :height="size"
  />
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#3513e2'
    },
    size: {
      type: Number,
      default: 18
    }
  }
}
</script>
