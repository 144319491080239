<template>
  <BaseCard class="p-5">
    <div class="font-heading text-3xl font-bold text-primary mb-2">
      Par numéro de mobile
    </div>
    <div class="flex justify-center my-5">
      <div
        v-if="loading"
        class="flex"
      >
        <v-skeleton-loader
          max-width="500"
          type="button"
        />
      </div>
      <div v-else>
        <CustomerCertificationPhone
          v-if="!customer.certification"
          ref="phoneInput"
          :customer="customer"
          @certified="refresh"
          @conflict="handleConflict"
        />
        <CustomerCertificationStatus
          v-else
          :customer="customer"
        />
      </div>
    </div>
    <ul
      v-if="!conflictingCustomer"
      class="space-y-3"
    >
      <li class="flex flex-row items-center">
        <span
          class="text-5xl text-lightPurple-darkest text-center font-content font-bold w-7"
          :style="{'filter': 'drop-shadow(0 3px 6px rgb(0 0 0 / 0.16))'}"
        >
          1
        </span>
        <span class="text-black font-content ml-2">
          Renseigner le numéro de mobile du patient.
        </span>
      </li>
      <li class="flex flex-row items-center">
        <span
          class="text-5xl text-lightPurple-darkest font-content text-center font-bold w-7"
          :style="{'filter': 'drop-shadow(0 3px 6px rgb(0 0 0 / 0.16))'}"
        >
          2
        </span>
        <span class="text-black font-content ml-2">
          Votre patient télécharge l'application apodis sur son mobile.
        </span>
      </li>
      <li class="flex flex-row items-center">
        <span
          class="text-5xl text-lightPurple-darkest font-content text-center drop-shadow-lg font-bold w-7"
          :style="{'filter': 'drop-shadow(0 3px 6px rgb(0 0 0 / 0.16))'}"
        >
          3
        </span>
        <span class="text-black font-content ml-2">
          Il s'inscrit avec le numéro de mobile renseigné plus haut.
        </span>
      </li>
    </ul>
    <div v-else>
      <p>
        <v-icon
          color="red"
          class="mr-2"
        >
          mdi-account-alert
        </v-icon>Ce numéro correspond à un patient déjà certifié dans votre pharmacie.
      </p>
      <div class="flex flex-row flex-1 justify-around flex-wrap">
        <BaseButton
          primary
          class="mt-3 w-60"
          @click="navigateToConflictingCertification"
        >
          Voir le patient certifié
        </BaseButton>
        <BaseButton
          class="mt-3 w-60"
          @click="reset"
        >
          Certifier un autre numéro
        </BaseButton>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import CustomerCertificationStatus
from '@/renderer/app/customer-certification/components/CustomerCertificationStatus.vue'
import CustomerCertificationPhone from '@/renderer/app/customer-certification/components/CustomerCertificationPhone.vue'
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import { customerCertificationResource } from '@/renderer/container'
import BaseButton from '@/renderer/components/base/BaseButton.vue'

export default {
  name: 'CustomerOnboardingPhoneCard',
  components: {
    BaseButton,
    CustomerCertificationStatus,
    CustomerCertificationPhone,
    BaseCard
  },
  props: {
    customer: {
      type: Object,
      required: true
    },
    refresh: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      conflictingCustomer: null
    }
  },
  watch: {
    customer: {
      handler () {
        this.loadPhoneCertification()
      },
      immediate: true
    }
  },
  mounted () {
    if (!this.customer.certification) {
      this.loadPhoneCertification()
    }
  },
  methods: {
    loadPhoneCertification () {
      this.loading = true
      return customerCertificationResource()
        .certifications(this.customer.id, 'pre_certified')
        .then(certifications => {
          if (certifications.length > 0) {
            this.refresh(certifications[0])
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    /**
     * Handle certification conflict event.
     *
     * @param {object} customer - The conflicting customer.
     */
    handleConflict (customer) {
      this.conflictingCustomer = customer
    },
    /**
     * Navigate to conflicting customer certification.
     */
    navigateToConflictingCertification () {
      this.$router.push({ name: 'customer.certification', params: { customerId: this.conflictingCustomer.id } })
    },
    /**
     * Reset the phone certification interface.
     */
    reset () {
      this.$refs.phoneInput.clear()
      this.conflictingCustomer = null
    }
  }
}
</script>
