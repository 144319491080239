<template>
  <div
    class="prose max-w-full"
    :class="{format: !inheritStyles}"
    v-html="markdownToHtml"
  />
</template>

<script>
import { marked } from 'marked'

export default {
  name: 'Markdown',
  props: {
    content: {
      type: String,
      required: true
    },
    inheritStyles: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    markdownToHtml () {
      const renderer = new marked.Renderer()

      renderer.link = (href, title, text) => {
        return `<a href="${href}" target="_blank" rel="noopener noreferrer">${text}</a>`
      }

      return marked(this.content, { renderer })
    }
  }
}
</script>

<style>
@layer components {
  .format h1 {
    @apply text-2xl font-bold mb-0 font-heading !important;
  }

  .format h2 {
    @apply text-xl font-bold my-0 font-heading !important;
  }

  .format p {
    @apply font-content !important;
  }

  .format li {
    @apply text-sm !important;
  }

  .prose blockquote p {
    content: '' !important;
    font-style: normal !important;
  }

  .prose blockquote p::after {
    content: '' !important;
  }

  .prose blockquote p::before {
    content: '' !important;
  }
}
</style>
