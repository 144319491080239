<template>
  <div
    v-if="loading"
    class="p-6"
  >
    <BaseSpinner size="medium-small" />
  </div>
  <div v-else>
    <div class="p-6 flex flex-row text-white bg-gradient-to-r from-darkPurple-default to-darkBlue-default">
      <div class="flex flex-col">
        <p class="text-2xl">
          {{ survey.name }}
        </p>
        <p class="my-2">
          {{ verdict?.title }}
        </p>
        <p class="my-5">
          {{ verdict?.description || "" }}
        </p>
      </div>
      <div
        v-if="verdict?.options.isScoreEssential"
        class="px-5 flex flex-1 flex-col align-end"
      >
        <span class="text-5xl bg-white text-primary p-5 rounded-full">{{ verdict.score }}</span>
      </div>
    </div>
    <div
      v-if="!!survey?.sections"
      class="p-6 mt-6"
    >
      <SurveyBuiltSection
        v-for="section in survey.sections"
        :key="`section-${section.id}`"
        :section="section"
        :survey-id="survey.id"
      />
    </div>
  </div>
</template>

<script>
import { surveyApi } from '@/renderer/container'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import SurveyBuiltSection from '@/renderer/app/survey/components/SurveyBuiltSection.vue'
import CustomerSurveyCard from '@/renderer/app/customer/components/customer-surveys/CustomerSurveyCard.vue'

export default {
  name: 'SurveyBuilt',
  components: { CustomerSurveyCard, SurveyBuiltSection, BaseSpinner },
  data () {
    return {
      survey: null,
      verdict: null,
      loading: false
    }
  },
  watch: {
    '$route.params': {
      immediate: true,

      handler (params) {
        if (params.instanceId) {
          this.loading = true
          this.verdict = params.verdict
          surveyApi()
            .builtAnsweredInstance(params.instanceId)
            .then((response) => {
              this.survey = response.survey
            })
            .finally(() => {
              this.loading = false
            })
        }
      }
    }
  }
}
</script>
