<template>
  <div class="p-5 bg-white rounded-md flex justify-between gap-10">
    <div class="flex flex-1 flex-col">
      <h2 class="text-lg font-bold">
        Objectif de l'entretien
      </h2>
      <p>{{ description }}</p>
    </div>
    <div>
      <span
        v-if="duration"
        class="text-sm"
      >Durée : {{ duration }}min</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InterviewDescription',
  props: {
    /** The interview description */
    description: {
      type: String,
      required: true
    },
    /** The interview duration */
    duration: {
      type: Number,
      required: false
    }
  }
}
</script>

<style scoped>

</style>
