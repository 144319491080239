<template>
  <CustomerCertificationQrCode
    v-if="!hasValidCertification"
    :customer="customer"
    :simple="true"
  />
  <img
    v-else
    alt="Certifié"
    class="rounded-xl bg-white p-2 cursor-pointer"
    :src="require('@/assets/images/icons/certified.svg')"
    @click="redirectToOnboarding"
  >
</template>

<script>
import CustomerCertificationQrCode
from '@/renderer/app/customer-certification/components/CustomerCertificationQrCode.vue'

export default {
  components: {
    CustomerCertificationQrCode
  },
  props: {
    /**
     * The customer object.
     */
    customer: {
      type: Object,
      required: true
    }
  },
  computed: {
    /**
     * Determine if the customer has a valid certification.
     *
     * @return {boolean}
     */
    hasValidCertification () {
      return !!this.customer?.certification?.validated_at
    }
  },
  /**
   * On mount, subscribe for certification update.
   */
  mounted () {
    const channel = 'certification.updated'

    window.ipcRenderer.send('socket:subscribe', `private-customers.${this.customer.id}`)
    window.ipcRenderer.send('socket:listen', channel)
    window.ipcRenderer.on('socket:event-received.' + channel, (event, data) => {
      this.refresh(data)
    })
  },
  /**
   * When destroyed, unsubscribe the socket channel.
   */
  destroyed () {
    window.ipcRenderer.send('socket:unsubscribe', `private-customers.${this.customer.id}`)
  },
  methods: {
    /**
     * Refresh the customer certification.
     *
     * @param certification
     */
    refresh (certification) {
      this.customer.certification = certification
    },
    /**
     * Redirect to onboarding.
     */
    redirectToOnboarding () {
      this.$router.push({ name: 'customer.certification' })
    }
  }
}
</script>
