<template>
  <div class="space-y-3">
    <BaseRadio
      v-model="model"
      :value="value"
      :label="label"
      @change="$emit('change', value)"
    />
    <NotificationSimpleLayout v-if="value === 'simple'" />
    <NotificationAdvancedLayout v-if="value === 'advanced'" />
  </div>
</template>

<script>
import BaseRadio from '@/renderer/components/base/BaseRadio.vue'
import NotificationSimpleLayout from '@/renderer/app/settings/components/NotificationSimpleLayout.vue'
import NotificationAdvancedLayout from '@/renderer/app/settings/components/NotificationAdvancedLayout.vue'

export default {
  name: 'NotificationLayoutRadioButton',
  components: { NotificationAdvancedLayout, NotificationSimpleLayout, BaseRadio },
  model: {
    prop: 'model',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    model: {
      type: null,
      required: true
    }
  }
}
</script>
