<template>
  <div class="relative">
    <div class="relative rounded-md shadow-sm">
      <input
        v-model="keyword"
        type="search"
        class="block w-full pr-10 py-3 border-gray-100 rounded-md shadow-md focus:ring-indigo-500 focus:border-indigo-500 placeholder-gray-300"
        placeholder="Rechercher, activer un patient…"
        @input="search()"
      >

      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <svg
          v-if="!isLoading"
          xmlns="http://www.w3.org/2000/svg"
          class="h-7 w-7 text-primary"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
        <BaseSpinner
          v-else
          size="small"
        />
      </div>
    </div>

    <div
      v-if="results.length"
      class="absolute shadow bg-white top-full z-20 w-full left-0 rounded max-h-72 overflow-y-auto"
    >
      <div
        v-if="isLoading"
        class="flex flex-col w-full"
      >
        <div class="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-teal-100">
          <div class="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-teal-100">
            <div class="w-full items-center flex">
              Recherche en cours…
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <div
          v-for="(result, index) in results"
          :key="index"
          class="grid grid-cols-3 mx-auto text-sm p-2 border-b-1 border-red-500 hover:bg-purple-200 cursor-pointer"
          @click="show(result)"
        >
          <div class="pl-2 text-purple-700">
            {{ result.information.name }}
          </div>

          <div class="text-center">
            Assuré
          </div>

          <div class="text-center">
            {{ result.information.birth_date }}
          </div>

          <div class="col-span-3">
            <div
              v-for="(beneficiary, indexBeneficiary) in result.beneficiaries"
              :key="indexBeneficiary"
            >
              <span class="ml-5 text-xs">
                {{ beneficiary.information.name }} - Ayant droit - {{ beneficiary.information.formatted_birth_date }}
              </span>
            </div>
          </div>

          <hr class="col-span-3 w-full">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import { customerResource } from '@/renderer/container'

export default {
  components: { BaseSpinner },
  params: {
    input: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      results: [],
      keyword: null,
      isOpen: false,
      isLoading: false
    }
  },
  watch: {
    '$attrs.input': {
      handler (value) {
        this.keyword = value
        this.search()
      }
    },
    keyword: {
      handler (value) {
        if (value.length === 0) {
          this.results = []
        }
      }
    }
  },
  methods: {
    /**
     * Search a customer based on provided keyword.
     */
    search () {
      if (this.keyword.length > 3) {
        setTimeout(() => {
          this.isLoading = true

          customerResource().search(this.keyword)
            .then(customers => {
              this.results = customers
              this.isLoading = false
            })
        }, 500)
      }
    },
    /**
     * Show customer details page.
     *
     * @param customer
     */
    show (customer) {
      this.$router.push({
        name: 'customer.home',
        params: {
          customerId: customer.id
        }
      })
    }
  }
}
</script>
