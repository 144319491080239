<template>
  <gmap-marker
    v-if="warehouse.geo_lat && warehouse.geo_lon"
    :position="{ lat: warehouse.geo_lat, lng: warehouse.geo_lon }"
    :animation="google.maps.Animation.DROP"
    :clickable="true"
    :icon="markerIcon"
    :optimized="true"
    :z-index="zIndex"
    @click="$emit('click', warehouse)"
  />
</template>

<script>
import { gmapApi } from 'vue2-google-maps'
import DistributorAvailabilityMapService
from '@/renderer/app/disruption-logistics/services/DistributorAvailabilityMapService'

export default {
  name: 'DistributorAvailabilityMapMarker',
  props: {
    warehouse: {
      type: Object,
      required: true
    },
    isAvailable: {
      type: Boolean,
      required: false,
      default: null
    },
    members: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    /**
     * Get the google maps api.
     *
     * @return {Object|null}
     */
    google () {
      return gmapApi()
    },
    /**
     * The marker icon to display.
     *
     * @return {Object}
     */
    markerIcon () {
      return {
        url: DistributorAvailabilityMapService.getMarkerImage(this.isAvailable, this.members),
        scaledSize: this.google && new this.google.maps.Size(40, 40),
        anchor: this.google && new this.google.maps.Point(22, 38),
        labelOrigin: this.google && new this.google.maps.Point(20, 16)
      }
    },
    zIndex () {
      if (this.isAvailable === null) {
        return 1
      }

      if (!this.isAvailable) {
        return 2
      }

      if (this.isAvailable && !this.members.length) {
        return 3
      }

      return 4
    }
  }
}
</script>

<style scoped>

</style>
