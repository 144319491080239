<template>
  <div class="flex flex-1 flex-col mt-2 gap-y-1.5">
    <BaseButton
      v-if="!customer.certification.validated_at"
      primary
      class="h-9 flex items-center justify-center"
      @click="print"
    >
      <div
        v-if="!printing"
        class="text-small text-center w-full"
      >
        Imprimer le guide d'installation
      </div>
      <img
        v-else
        :src="require('@/assets/loader-button.gif')"
        alt="Loading"
        width="24"
      >
    </BaseButton>
    <CustomerCertificationNotificationButton
      v-if="customer.certification.phone && !customer.certification.validated_at"
      :customer="customer"
    />
    <BaseButton
      class="h-9 flex items-center"
      :disabled="canceling"
      @click="cancel"
    >
      <div
        class="text-small text-center w-full"
      >
        Annuler la certification
      </div>
    </BaseButton>
  </div>
</template>

<script>
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import { customerCertificationResource, storageService } from '@/renderer/container'
import CustomerCertificationNotificationButton
from '@/renderer/app/customer-certification/components/CustomerCertificationNotificationButton.vue'

export default {
  components: { CustomerCertificationNotificationButton, BaseButton },
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      canceling: false,
      printing: false
    }
  },
  methods: {
    print () {
      this.printing = true

      window.ipcRenderer.invoke('print', {
        url: customerCertificationResource().pdfUrl(this.customer.id, 'phone'),
        token: storageService().read('token')
      })
        .finally(() => {
          this.printing = false
        })
    },
    cancel () {
      this.canceling = true

      return customerCertificationResource()
        .cancel(this.customer.id)
        .then(() => {
          this.customer.certification = null
        })
        .finally(() => {
          this.canceling = false
        })
    }
  }
}
</script>

<style scoped>

</style>
