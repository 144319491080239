import { authenticationService, hostResource, santeSecureResource, usersResource } from '@/renderer/container'

export default {
  namespaced: true,
  state: {
    santeSecure: null,
    hosts: [],
    mobiles: []
  },
  getters: {
    santeSecure: state => state.santeSecure,
    hosts: state => state.hosts,
    mobiles: state => state.mobiles
  },
  mutations: {
    setSanteSecure (state, santeSecure) {
      state.santeSecure = santeSecure
    },
    setHosts (state, hosts) {
      state.hosts = hosts
    },
    setMobiles (state, mobiles) {
      state.mobiles = mobiles
    }
  },
  actions: {
    fetchData ({ commit }) {
      if (authenticationService().authenticated) {
        santeSecureResource()
          .getState()
          .then((state) => {
            commit('setSanteSecure', state.healthcenter)
          })

        hostResource()
          .get()
          .then((hosts) => {
            commit('setHosts', hosts)
          })

        usersResource()
          .getUserMobiles()
          .then((response) => {
            commit('setMobiles', response.mobiles.sort((a, b) => b.updatedAt.localeCompare(a.updatedAt)))
          })
      }
    }
  }
}
