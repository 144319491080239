<template>
  <div class="m-4">
    <div
      v-if="cards.length"
      class="space-y-4"
    >
      <div
        v-for="card in cards"
        :key="card.id"
        class="bg-white overflow-hidden shadow rounded-lg"
      >
        <div class="px-4 py-5 sm:p-6 flex flex-row">
          <div class="basis-20 flex-shrink-0">
            <img
              v-if="card.file"
              :src="card.file.renderPath"
              class="rounded-xl w-48 max-w-sm border border-gray-200"
            >
            <div
              v-else
              class="w-48 h-48 rounded-xl max-w-sm border bg-gray-200"
            />
          </div>

          <div class="ml-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              {{ card.organization.morphable.name }}
            </h3>

            <p class="mt-1 text-sm text-gray-500">
              {{ card.name }}
            </p>

            <p
              v-if="card.membership"
              class="mt-1 text-sm text-gray-500"
            >
              Activé le {{ card.membership.formatted_created_at }}
            </p>

            <div
              v-if="card.membership"
              class="mt-4 flex-shrink-0"
            >
              <button
                type="button"
                class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-dark mr-2"
                @click="showMembership(card.membership)"
              >
                Gérer le programme
              </button>
            </div>

            <div
              v-else
              class="mt-4 flex-shrink-0"
            >
              <button
                type="button"
                class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-dark mr-2"
                @click="display(card)"
              >
                Voir ce programme
              </button>

              <button
                type="button"
                class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-dark"
                @click="activate(card)"
              >
                Activer ce programme
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="loading">
      <BaseSpinner />
    </div>

    <div v-else>
      <p class="text-sm font-medium text-gray-500">
        Aucun programme disponible
      </p>
    </div>

    <div
      v-show="modal === 'activation'"
      class="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        />

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >&#8203;</span>

        <div
          class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
        >
          <div>
            <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <svg
                class="h-6 w-6 text-green-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-5">
              <h3
                id="modal-title"
                class="text-lg leading-6 font-medium text-gray-900"
              >
                Le programme de fidélité {{ activatedCard.name }} est activé pour {{ customer.information.name }}
              </h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  Les avantages seront cumulés automatiquement lors de ses achats dans votre pharmacie.
                  Pensez à facturer les achats sur la fiche client de votre LGO.
                </p>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-6">
            <button
              type="button"
              class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-dark sm:text-sm"
              @click="closeModal()"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      v-show="modal === 'display'"
      class="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        />

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >&#8203;</span>

        <div
          class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
        >
          <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              @click="closeModal()"
            >
              <span class="sr-only">Close</span>
              <!-- Heroicon name: outline/x -->
              <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div class="sm:flex sm:items-start">
            <div>
              <div>
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  {{ displayedCard.organization.morphable.name }}
                </h3>
                <p class="mt-1 max-w-2xl text-sm text-gray-500">
                  {{ displayedCard.name }}
                </p>
              </div>

              <div class="mt-4">
                <img
                  v-if="displayedCard.file"
                  :src="displayedCard.file.renderPath"
                  class="rounded-2xl border border-gray-200"
                >
                <div
                  v-else
                  class="rounded-2xl border bg-gray-200 w-24 h-24"
                />
              </div>

              <div class="mt-5 border-t border-gray-200">
                <dl class="sm:divide-y sm:divide-gray-200">
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt class="text-sm font-medium text-gray-500">
                      Période de validité
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {{ displayedCard.period }}
                    </dd>
                  </div>

                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt class="text-sm font-medium text-gray-500">
                      Récompenses
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <div
                        v-for="objective in displayedCard.objectives"
                        :key="objective.id"
                      >
                        {{ objective.reward.teaser }}
                      </div>
                    </dd>
                  </div>

                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt class="text-sm font-medium text-gray-500">
                      Description
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {{ displayedCard.description }}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  customerResource,
  loyaltyCardResource,
  loyaltyCardMembershipResource
} from '@/renderer/container'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'

export default {
  components: { BaseSpinner },
  data () {
    return {
      cards: [],
      loading: true,
      displayedCard: {
        name: null,
        startDate: null,
        enddate: null,
        description: null,

        file: {
          renderPath: null
        },

        organization: {
          morphable: {
            name: null
          }
        }
      },

      activatedCard: {},
      modal: null,

      customer: {
        information: {
          name: null
        }
      }
    }
  },

  watch: {
    '$route.params.customerId': {
      immediate: true,

      handler (value) {
        this.read(value)
      }
    }
  },
  methods: {
    read (id) {
      customerResource()
        .read(id)
        .then(customer => {
          this.customer = customer
        })

      loyaltyCardResource()
        .get()
        .then(async cards => {
          const memberships = await loyaltyCardMembershipResource()
            .get(id)

          this.cards = cards.map(card => {
            memberships.forEach(membership => {
              if (membership.loyalty_card.id === card.id) {
                card.membership = membership
              }
            })

            return card
          })
        })
        .finally(() => {
          this.loading = false
        })
    },

    display (card) {
      this.displayedCard = card
      this.modal = 'display'
    },

    activate (card) {
      loyaltyCardMembershipResource()
        .create(card.id, this.customer.id)
        .then(membership => {
          this.activatedCard = membership.loyalty_card
          this.modal = 'activation'
          this.read(this.$route.params.customerId)
        })
    },

    closeModal () {
      this.modal = null
    },

    showMembership (membership) {
      this.$router.push({
        name: 'customer.loyalty.membership',

        params: {
          customerId: this.$route.params.customerId,
          membershipId: membership.id
        }
      })
    }
  }
}
</script>
