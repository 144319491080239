<template>
  <div class="flex flex-row cursor-pointer justify-center">
    <ProgramLogo
      :program="program"
      class="z-10"
    />
    <div class="flex-1 flex flex-row bg-white -ml-2 pl-5 pr-3 py-2 my-4 rounded-r-2xl items-center">
      <div class="flex flex-1 flex-col">
        <p
          class="font-heading font-semibold"
          style="margin-bottom: 5px;"
        >
          {{ program.name }}
        </p>
        <p
          v-if="!!program.short_description"
          class="font-content text-sm line-clamp-2"
          style="margin: 0;"
        >
          {{ program.short_description }}
        </p>
      </div>
      <ChevronRightIcon
        class="ml-5"
        :size="15"
      />
    </div>
  </div>
</template>

<script>
import ProgramLogo from '@/renderer/app/program/components/program-logo/ProgramLogo.vue'
import ChevronRightIcon from '@/renderer/components/icons/ChevronRightIcon.vue'
export default {
  name: 'SupportProgramHomeRow',
  components: { ChevronRightIcon, ProgramLogo },
  props: {
    program: {
      type: Object,
      required: true
    }
  }
}
</script>
