<template>
  <div v-if="sellinChallenge">
    <div class="flex items-center space-x-5 mb-8">
      <div class="flex-shrink-0">
        <SellinIcon :size="6" />
      </div>

      <div>
        <h1 class="text-2xl font-bold text-gray-900">
          {{ sellinChallenge.name }}
        </h1>

        <p class="text-sm font-medium text-gray-500">
          Du

          <time :datetime="sellinChallenge.startDate">
            {{ sellinChallenge.formattedStartDate }}
          </time>

          au

          <time :datetime="sellinChallenge.endDate">
            {{ sellinChallenge.formattedEndDate }}
          </time>
        </p>
      </div>
    </div>

    <BaseSection>
      <BaseCard>
        <BaseImage
          :text="sellinChallenge.name"
          :path="sellinChallenge.file.renderPath"
          class="w-full h-96 border-b border-gray-200"
        />

        <BaseCardBody>
          <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div class="sm:col-span-2">
              <dt class="text-sm font-medium text-gray-500">
                Promoteur
              </dt>

              <dd class="mt-1 text-sm text-gray-900">
                {{ sellinChallenge.organization.morphable.name }}
              </dd>
            </div>

            <div class="sm:col-span-2">
              <dt class="text-sm font-medium text-gray-500">
                Description
              </dt>

              <dd class="mt-1 text-sm text-gray-900">
                {{ sellinChallenge.description }}
              </dd>
            </div>
          </dl>
        </BaseCardBody>
      </BaseCard>

      <BaseCard>
        <BaseCardHeader>
          Récompenses
        </BaseCardHeader>

        <BaseCardBody>
          <ul class="list-disc list-inside">
            <li
              v-for="step in sellinChallenge.steps"
              :key="step.id"
              class="text-sm"
            >
              {{ step.stepValue }}

              <span v-if="step.type && step.type.id === 1">
                unité(s) achetée(s)&nbsp;:
              </span>

              <span v-else>
                € d’achat&nbsp;:
              </span>

              <span v-if="step.discountType && step.discountType.id === 2">
                + {{ step.discountValue }}% de remise (s’additionne à la remise de base)
                par rapport à la remise de base
              </span>

              <span v-else>
                {{ step.discountValue }}% de remise (remplace la remise de base)
              </span>
            </li>
          </ul>
        </BaseCardBody>
      </BaseCard>

      <BaseCard>
        <BaseCardHeader>
          Produits
        </BaseCardHeader>

        <ul
          role="list"
          class="divide-y divide-gray-200"
        >
          <li
            v-for="product in sellinChallenge.products"
            :key="product.id"
          >
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="min-w-0 flex-1 flex items-center">
                <div class="flex-shrink-0">
                  <BaseImage
                    class="h-20 w-20 rounded-lg"
                    :text="product.product.name"
                    :path="product.product.fullPhotoPath"
                  />
                </div>

                <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4">
                  <div>
                    <p class="text-sm font-medium">
                      {{ product.product.name }}
                    </p>

                    <p class="mt-1 flex items-center text-sm text-gray-500">
                      {{ product.product.code }}
                    </p>
                  </div>

                  <div class="hidden md:block">
                    <p class="text-sm">
                      <span class="font-medium">
                        Stock&nbsp;:
                      </span>

                      {{ product.product.needs.quantityInStock }}
                    </p>

                    <p class="text-sm">
                      <span class="font-medium">
                        Prix unité(s)&nbsp;:
                      </span>

                      {{ product.product.laboratoryShopPrice.priceDiscountedWithoutVat }}
                    </p>

                    <p class="text-sm">
                      <span class="font-medium">
                        Besoins sur {{ reassortConfig.daysCount }} jours&nbsp;:
                      </span>

                      {{ product.product.needs.quantityNeeded }}
                    </p>
                  </div>

                  <div class="hidden md:block">
                    <ProductPurchase
                      v-model="product.quantity"
                      :product="product.product"
                      class="w-48"
                    />
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </BaseCard>
    </BaseSection>
  </div>
</template>

<script>
import {
  shopCartResource,
  sellinChallengeResource,
  healthcenterReassortConfigResource
} from '@/renderer/container'

import BaseCard from '@/renderer/components/base/BaseCard.vue'
import SellinIcon from '@/renderer/components/icons/Sellin.vue'
import BaseImage from '@/renderer/components/base/BaseImage.vue'
import BaseSection from '@/renderer/components/base/BaseSection.vue'
import BaseCardBody from '@/renderer/components/base/BaseCardBody.vue'
import BaseCardHeader from '@/renderer/components/base/BaseCardHeader.vue'
import ProductPurchase from '@/renderer/components/brand/ProductPurchase.vue'

export default {
  components: {
    BaseCard,
    BaseImage,
    SellinIcon,
    BaseSection,
    BaseCardBody,
    BaseCardHeader,
    ProductPurchase
  },

  data () {
    return {
      reassortConfig: {},
      sellinChallenge: null
    }
  },

  mounted () {
    shopCartResource()
      .read(
        this.$route.params.laboratoryId,
        this.$store.state.system.environment.entityId
      )
      .then(cart => {
        sellinChallengeResource()
          .read(this.$route.params.sellinChallengeId)
          .then(sellinChallenge => {
            const date = {
              month: 'long',
              day: 'numeric',
              weekday: 'long',
              year: 'numeric'
            }

            const startDate = new Date(sellinChallenge.startDate)
            const endDate = new Date(sellinChallenge.endDate)

            sellinChallenge.formattedStartDate = startDate
              .toLocaleDateString(undefined, date)

            sellinChallenge.formattedEndDate = endDate
              .toLocaleDateString(undefined, date)

            sellinChallenge.products
              .map(product => {
                product.product.needs = product.product.needs.length || {
                  quantityNeeded: 0,
                  quantityInStock: 0
                }

                product.product.laboratoryShopPrice = product.product.laboratoryShopPrice.length || {
                  priceDiscountedWithoutVat: 0
                }

                const line = cart.lines
                  .find(line => line.productId === product.product.id)

                const quantity = line
                  ? line.quantity
                  : 0

                product.quantity = quantity

                return product
              })

            this.sellinChallenge = sellinChallenge
          })
      })

    healthcenterReassortConfigResource()
      .read(
        this.$store.state.system.environment.entityId,
        this.$route.params.laboratoryId
      )
      .then(reassortConfig => {
        this.reassortConfig = reassortConfig || {
          daysCount: 60
        }
      })
  }
}
</script>
