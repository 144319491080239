<template>
  <div
    class="grid md:grid-cols-3 justify-items-center gap-2"
    style="min-width: 400px;"
  >
    <div
      v-for="(statisticInfo, index) in statisticsInfo"
      v-if="statistics"
      class="flex flex-1 flex-col gap-5 p-4 rounded-lg w-full"
    >
      <BaseCard
        class="mb-4 flex flex-grow items-center p-4 max-w-md"
      >
        <div
          class="rounded-full p-2 flex items-center w-18"
          :style="[{'background-color': '#3513e2'},
                   index < 2 ? {'background-color': '#0B614D'} : '',
                   index === 3 ? {'background-color': 'white'} : '']"
        >
          <UserSolid
            v-if="index < 2"
            width="48"
            :class="'icon-white'"
          />
          <StarSolid
            v-else-if="index === 3"
            width="48"
            :class="'icon-yellow'"
          />
          <ShopbagSolid
            v-else
            width="48"
            :class="'icon-white'"
          />
        </div>
        <div class="flex flex-col ml-4">
          <div class="self-start">
            {{ statisticInfo.label }}
          </div>
          <div class="self-start font-bold text-lg">
            {{ statistics[statisticInfo.slug] }}
          </div>
        </div>
      </BaseCard>
    </div>
  </div>
</template>

<script>
import StarSolid from '@/assets/images/icons-vue/star-solid.vue'
import ShopbagSolid from '@/assets/images/icons-vue/shopbag-solid.vue'
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import UserSolid from '@/assets/images/icons-vue/user-solid.vue'

export default {
  name: 'MedicineCabinetStatistics',
  components: { UserSolid, BaseCard, ShopbagSolid, StarSolid },
  props: {
    statistics: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      statisticsInfo: [
        {
          label: 'Patients groupama',
          slug: 'target_users'
        },
        {
          label: 'Patients avec armoire',
          slug: 'healtcenter_medicine_cabinets'
        },
        {
          label: 'Produits périmés dans les armoires',
          slug: 'expired'
        },
        {
          label: 'Conseils sur les catégories',
          slug: 'category_advices'
        },
        {
          label: 'Produits conseillés',
          slug: 'category_product_advices'
        }
      ]
    }
  }
}
</script>
