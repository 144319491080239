import formatHelper from '@/renderer/helpers/formatHelper'

/**
 * Format phone.
 *
 * @param value
 * @return {string|*}
 */
export const phoneFormat = (value) => {
  return formatHelper.readablePhone(value)
}
