var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.label)?_c('label',{staticClass:"block text-sm font-medium text-gray-700"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"mt-1 relative rounded-md shadow-sm"},[((_vm.type)==='checkbox'&&(_vm.type !== 'textarea'))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],class:[ _vm.errors.length
        ? 'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'
        : 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
      ],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.model)?_vm._i(_vm.model,null)>-1:(_vm.model)},on:{"change":function($event){var $$a=_vm.model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.model=$$a.concat([$$v]))}else{$$i>-1&&(_vm.model=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.model=$$c}}}}):((_vm.type)==='radio'&&(_vm.type !== 'textarea'))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],class:[ _vm.errors.length
        ? 'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'
        : 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
      ],attrs:{"type":"radio"},domProps:{"checked":_vm._q(_vm.model,null)},on:{"change":function($event){_vm.model=null}}}):(_vm.type !== 'textarea')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],class:[ _vm.errors.length
        ? 'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'
        : 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
      ],attrs:{"type":_vm.type},domProps:{"value":(_vm.model)},on:{"input":function($event){if($event.target.composing)return;_vm.model=$event.target.value}}}):_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],class:[ _vm.errors.length
        ? 'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'
        : 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
      ],domProps:{"value":(_vm.model)},on:{"input":function($event){if($event.target.composing)return;_vm.model=$event.target.value}}}),(_vm.errors.length)?_c('div',{staticClass:"absolute inset-y-0 right-0 pr-3 flex items-center valueer-events-none"},[_c('svg',{staticClass:"h-5 w-5 text-red-500",attrs:{"aria-hidden":"true","viewBox":"0 0 20 20","fill":"currentColor","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"}})])]):_vm._e()]),(_vm.errors.length)?_c('p',{staticClass:"mt-2 text-sm text-red-600"},[_vm._v(" "+_vm._s(_vm.errors[0])+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }