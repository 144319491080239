<template>
  <div
    class="relative items-center justify-center flex flex-row flex-wrap bg-gradient-to-b from-secondary-lightest py-36 my-12"
  >
    <div class="relative top-5">
      <img
        alt="iphone mockup"
        :style="{'objectFit': 'scale-down'}"
        :src="require('@/assets/images/icons/iphone-secure-mockup.png')"
      >
      <img
        alt="RGPD"
        :style="{'position': 'absolute', 'objectFit': 'scale-down', 'width': '119px', 'left': '140px', 'top': '20px'}"
        :src="require('@/assets/images/icons/RGPD.png')"
      >
    </div>
    <div class="flex flex-col bg-white self-center p-4 rounded-sm">
      <h3 class="font-heading text-3xl font-bold text-primary">
        Sécurité
      </h3>
      <div class="font-heading max-w-xl text-primary">
        La protection des données des patients est une priorité absolue
        pour Apodis et guide notre action au quotidien. L'application
        garantit la stricte confidentialité des informations personnelles
        conformément au Règlement Général sur la Protection des Données
        (RGPD).
        <br>Les données traitées sont stockées en France par un hébergeur
        agréé données de santé (HDS).
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Security'
})
</script>
