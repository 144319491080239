<template>
  <BaseContainer class="relative">
    <div v-if="error">
      <p class="p-5">
        Nous avons rencontré un problème lors du chargement des informations du patient.
      </p>
    </div>
    <div v-if="!loading && !error">
      <CustomerHeader :customer="customer" />
      <CustomerInformationModal
        v-if="showInformation"
        :customer="customer"
      />
      <div class="mt-1">
        <div class="border-b border-gray-200">
          <nav
            class="flex space-x-2"
            aria-label="Tabs"
          >
            <router-link
              :to="{ name: 'customer.home' }"
              active-class="nav-item-active"
              class="nav-item"
            >
              Fiche patient
            </router-link>
            <router-link
              :to="{ name: 'customer.prescriptions' }"
              active-class="nav-item-active"
              class="nav-item"
            >
              Ordonnances
            </router-link>
            <router-link
              :to="{ name: 'customer.loyalty' }"
              active-class="nav-item-active"
              class="nav-item"
            >
              Cartes de fidélité
            </router-link>

            <router-link
              :to="{ name: 'customer.survey.list' }"
              active-class="nav-item-active"
              class="nav-item"
            >
              Questionnaires
            </router-link>
            <router-link
              :to="{ name: 'customer.hospital-file.list' }"
              active-class="nav-item-active"
              class="nav-item"
            >
              Documents hospitaliers
            </router-link>
            <router-link
              :to="{ name: 'customer.click-collect' }"
              active-class="nav-item-active"
              class="nav-item"
            >
              Click & collect
            </router-link>
            <router-link
              v-show="$route.name === 'customer.support-program'"
              :to="{ name: 'customer.support-program' }"
              active-class="nav-item-active"
              class="nav-item"
            >
              Programme
            </router-link>
            <template v-if="experimentalFeatures">
              <router-link
                :to="{name: 'customer.interviews'}"
                active-class="nav-item-active"
                class="nav-item"
              >
                Entretiens
              </router-link>
            </template>
          </nav>
        </div>
      </div>
      <router-view
        :key="$route.path"
        :customer="customer"
      />
    </div>
    <div
      v-else
      class="flex flex-1 flex-row justify-center"
    >
      <BaseSpinner />
    </div>
  </BaseContainer>
</template>

<script>
import BaseContainer from '@/renderer/components/base/BaseContainer.vue'
import CustomerHeader from '@/renderer/app/customer/components/customer-header/CustomerHeader.vue'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import CustomerInformationModal
from '@/renderer/app/customer/components/customer-information/CustomerInformationModal.vue'

export default {
  components: {
    CustomerInformationModal,
    BaseSpinner,
    CustomerHeader,
    BaseContainer
  },
  data () {
    return {
      showInformation: false,
      error: false,
      experimentalFeatures: false
    }
  },
  computed: {
    customer () {
      return this.$store.getters['patient/customer']()
    },
    loading () {
      return !this.customer && !this.error
    }
  },

  watch: {
    '$route.params.customerId': {
      immediate: true,

      handler () {
        if (parseInt(this.$route.params.customerId) !== this.customer?.id) {
          this.load()
        }
      }
    }
  },

  created () {
    window.addEventListener('keypress', this.handleKeypress)
    window.addEventListener('insured-clicked', this.toggleInformationModal)
    window.addEventListener('information-closed', this.toggleInformationModal)
    window.addEventListener('refresh-customer', this.load)
  },
  destroyed () {
    window.removeEventListener('keypress', this.handleKeypress)
    window.removeEventListener('insured-clicked', this.toggleInformationModal)
    window.removeEventListener('information-closed', this.toggleInformationModal)
    window.removeEventListener('refresh-customer', this.load)
  },
  methods: {
    /**
     * Refresh the customer data.
     *
     * @return {Promise<unknown>}
     */
    load () {
      return this.$store
        .dispatch('patient/load', { customerId: this.$route.params.customerId })
        .catch(() => {
          this.error = true
        })
    },
    toggleInformationModal (event) {
      this.showInformation = !this.showInformation
    },
    handleKeypress (event) {
      if (event.keyCode === 4 && event.ctrlKey && event.shiftKey) {
        window.ipcRenderer.send('reset-emulation')
      } else if (event.keyCode === 4 && event.ctrlKey) { // CTRL + d
        window.ipcRenderer.send('emulate-detection', this.customer)
      } else if (event.keyCode === 24 && event.ctrlKey) { // CTRL + x
        this.experimentalFeatures = !this.experimentalFeatures
      }
    }
  }
}
</script>

<style scoped>
.nav-item {
  @apply border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-2 px-3 border-b-2 font-medium text-sm
}

.nav-item-active {
  @apply border-primary text-primary
}
</style>
