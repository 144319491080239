<template>
  <div class="relative">
    <div class="absolute z-1 -bottom-7 right-4 inline-flex">
      <BaseButton
        v-if="!isLoading && !medicineCabinet"
        class="pt-2 pb-2 px-4 mr-5 rounded-full bg-white"
        @click="show"
      >
        <div class="flex flex-row items-center">
          <strong class="py-1 text-primary mr-2">
            Envoyer un sms
          </strong>
        </div>
      </BaseButton>
      <BaseButton
        v-if="!isLoading && !medicineCabinet"
        :disabled="downloading"
        class="pt-2 pb-2 px-4 rounded-full bg-white"
        @click="print"
      >
        <div class="flex flex-row items-center">
          <strong class="py-1 text-primary mr-2">
            Imprimer
          </strong>
          <Print
            v-if="!downloading"
            :size="6"
            class="text-primary"
          />
          <img
            v-else
            :src="require('@/assets/loader.gif')"
            alt="Loading"
            class="w-6 h-5"
          >
        </div>
      </BaseButton>
    </div>
    <v-skeleton-loader v-if="isLoading" />
    <div
      v-else
      class="flex flex-1 flex-row cursor-pointer border-green"
      @click="navigateToProgram"
    >
      <ProgramLogo
        :program="program"
        :size="75"
        :shadow="true"
        class="mr-5"
      />
      <div
        v-if="medicineCabinet"
        class="bg-white shadow rounded-md sm:rounded-lg sm:overflow-hidden min-w-max flex flex-1 flex-row align-center justify-space-between"
      >
        <div class="px-2 py-3 sm:px-6">
          <h3
            class="text-md font-medium text-primary"
          >
            Voir l'armoire à pharmacie
          </h3>
          <p class="mt-1 text-sm text-gray-900">
            {{ medicineCabinet.item_count }} produits dans l'armoire ({{ medicineCabinet.expired_count }} périmés)
          </p>
        </div>
        <p class="text-5xl text-primary mr-5">
          >
        </p>
      </div>
      <div
        v-else
        class="shadow rounded-md sm:rounded-lg sm:overflow-hidden min-w-max flex flex-1 flex-row align-start"
        style="background-color: #D7E5E2"
      >
        <div class="px-2 py-3 sm:px-6">
          <h3
            class="text-md font-small"
            style="color: #0B614D"
          >
            {{ program?.teaser || "" }}
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { customerCertificationResource, medicineCabinetApi, storageService } from '@/renderer/container'
import BaseFlexSpinner from '@/renderer/components/base/spinner/BaseFlexSpinner.vue'
import ProgramLogo from '@/renderer/app/program/components/program-logo/ProgramLogo.vue'
import programHelper from '@/renderer/helpers/programHelper'
import Print from '@/renderer/components/icons/Print.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'

export default {
  name: 'CustomerMedicineCabinetCard',
  components: { BaseButton, Print, ProgramLogo, BaseFlexSpinner },
  props: {
    medicineCabinetId: {
      type: Number,
      required: true
    },
    program: {
      type: Object,
      required: true
    },
    customer: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      medicineCabinet: undefined,
      downloading: false
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    load () {
      this.isLoading = true
      if (this.medicineCabinetId) {
        medicineCabinetApi()
          .readMedicineCabinet(this.$store.state.system.environment.entityId, this.medicineCabinetId)
          .then(response => {
            this.medicineCabinet = response
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        this.isLoading = false
      }
    },
    navigateToProgram () {
      const route = programHelper.generateRoute(this.program, this.customer, this.customer)

      if (route) {
        this.$router.push(route)
      }
    },
    print () {
      this.downloading = true

      window.ipcRenderer
        .invoke('print', {
          url: customerCertificationResource().pdfUrl(this.customer.id, 'groupama'),
          token: storageService().read('token')
        })
        .finally(() => {
          this.downloading = false
          this.close()
        })
    },
    show () {
      this.$router.push({
        name: 'customer.certification',
        params: { customer: this.customer }
      })
    }
  }
}
</script>
