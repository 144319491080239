<template>
  <base-card class="relative overflow-visible m-5 cursor-pointer hover:opacity-70">
    <base-card-body>
      <div
        class="flex flex-row justify-between align-center"
        @click="onClick"
      >
        <div class="w-24 h-24">
          <secure-image
            :urls="prescription.files"
            :size="20"
          />
        </div>
        <p class="text-sm">
          {{ prescription.customer.information.name }}
        </p>
        <p class="text-sm">
          Dr. {{ prescription.professional.last_name }}
        </p>
        <p class="hidden md:hidden lg:block">
          {{ prescription.created_at | date('short') }}
        </p>
        <prescription-renewal-button />
      </div>
    </base-card-body>
  </base-card>
</template>

<script>
import SecureImage from '@/renderer/app/core/components/secure-image/SecureImage.vue'
import BaseCardBody from '@/renderer/components/base/BaseCardBody.vue'
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import PrescriptionRenewalButton from '@/renderer/app/prescription/components/PrescriptionRenewalButton.vue'

export default {
  name: 'PrescriptionRenewalCard',
  components: { PrescriptionRenewalButton, BaseCard, BaseCardBody, SecureImage },
  props: {
    prescription: {
      type: Object,
      required: true
    }
  },
  methods: {
    onClick () {
      this.$emit('on-card-press', this.prescription)
    }
  }
}
</script>
