import axios from 'axios'
import requestInterceptor from '@/renderer/http/interceptors/requestInterceptor'
import responseInterceptor from '@/renderer/http/interceptors/responseInterceptor'

const hdsClient = axios.create({
  baseURL: process.env.VUE_APP_HDS_URL
})

hdsClient.interceptors.request.use(
  requestInterceptor.success
)

hdsClient.interceptors.response.use(
  responseInterceptor.success,
  responseInterceptor.failure
)

export default hdsClient
