<template>
  <div>
    <base-spinner v-if="isLoading" />
    <div v-else>
      <information-panel>
        <div class="flex flex-row justify-space-between">
          <div class="flex flex-col">
            <p class="font-bold text-white">
              Plan de renouvellement
            </p>
            <p class="text-white">
              Le patient reçoit une notification lorsqu'il peut renouveler son traitement, et
              lorsque l'ordonnance arrive à échéance
            </p>
            <div class="flex flex-row align-items pt-2">
              <icon
                name="info-circle"
                class="mr-2"
                color="white"
              />
              <p class="text-sm text-white">
                En cas de stock insuffisant pour un traitement, vous recevez également une notification.
              </p>
            </div>
          </div>
          <div class="relative w-28 ml-6 hidden lg:block">
            <img
              alt="illustration"
              :src="require('@/assets/images/pharmacist-renewal.png')"
              class="w-32 object-contain hidden sm:block"
            >
          </div>
        </div>
      </information-panel>
      <prescription-list
        :prescriptions="prescriptions"
        :type="'renewal'"
        @on-press="onPress"
      />
    </div>
  </div>
</template>

<script>
import { prescriptionApi } from '@/renderer/container'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import PrescriptionList from '@/renderer/app/prescription/components/PrescriptionList.vue'
import InformationPanel from '@/renderer/app/core/components/InformationPanel.vue'
import Icon from '@/renderer/app/core/icon/Icon.vue'

export default {
  name: 'PrescriptionRenewalScreen',
  components: { Icon, InformationPanel, PrescriptionList, BaseSpinner },
  data: () => {
    return {
      isLoading: false,
      prescriptions: []
    }
  },
  mounted () {
    this.index()
  },
  methods: {
    index () {
      this.isLoading = true

      const params = { no_renewal_plan: true, certified: true, limit: 15 }
      prescriptionApi().index(params)
        .then(response => {
          this.prescriptions = response.data
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    onPress (prescription) {
      this.$router.push({ name: 'patient-administration.renewals.detail', params: { prescription } })
    }
  }
}
</script>
