<template>
  <div
    class="flex-col m-8"
  >
    <div class="text-xl font-black text-primary">
      Besoin d'aide ?
    </div>
    <div class="text-lg font-medium text-primary">
      Nous sommes là pour vous aider 😊
    </div>
    <p class="text-sm mt-5">
      👉 Décrivez au mieux le problème rencontré, indiquez un numéro de téléphone puis cliquez sur envoyer et nous vous
      rappellerons au plus vite
    </p>
    <textarea
      v-model="message"
      rows="3"
      placeholder="Quel est votre problème ?"
      class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md my-4 w-full"
    />
    <div
      class="flex justify-end"
    >
      <base-button
        primary
        @click="onSend"
      >
        <div class="gap-x-2">
          Envoyer
        </div>
      </base-button>
    </div>
    <div
      class="bg-orange w-full rounded-md items-center justify-center mt-12 text-primary text-center"
    >
      Pour télécharger notre module de maintenance, c'est ici:
    </div>
    <div
      class="flex justify-center"
    >
      <base-button
        class="bg-orange-light"
        @click="onDownload"
      >
        <div
          class="flex text-l text-primary font-bold items-center"
        >
          Télémaintenance
          <img
            class="ml-2 w-8 h-8"
            :src="require('@/assets/images/icons/picto-download-blue.svg')"
          >
        </div>
      </base-button>
    </div>
    <div class="flex flex-col items-center justify-center mt-5">
      <div
        class="flex text-l text-primary items-center"
      >
        Pour prendre rendez-vous avec le service client :
      </div>
      <div class="flex items-center text-primary text-decoration-underline">
        <a
          class="my-2"
          href="https://meetings.hubspot.com/marion-liere"
          target="_blank"
        >
          Cliquez-ici
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {
  hubspotResource
} from '@/renderer/container'
import BaseButton from '../../base/BaseButton.vue'
import ToastService from '@/renderer/services/ToastService'

const linkScreenConnect = 'http://files.santesecure.com/ConnectWiseControl.ClientSetup.exe'

export default {
  components: { BaseButton },
  data () {
    return {
      message: null
    }
  },
  methods: {
    onSend () {
      return hubspotResource().createTicket(this.message).then(response => {
        const toast = ToastService()
        if (response.success) {
          this.message = null
          toast.success('Votre message a bien été envoyé à notre équipe, nous regardons au plus vite 🙂')
        } else {
          toast.error('Une erreur s\'est produite lors de l\'envoi du message')
        }
      })
    },
    onDownload () {
      window.open(linkScreenConnect)
    }
  }
}
</script>
