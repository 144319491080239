import { LiveItemType } from '@/renderer/enum'

/**
 * Live item mapper service.
 */
export default function () {
  return {
    newOrder (order) {
      return {
        type: LiveItemType.ORDER_CREATED,
        data: order
      }
    }
  }
}
