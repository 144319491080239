<template>
  <div class="flex flex-col gap-8">
    <distributor-availability-map-legend-item
      :warehouse="warehouse"
      :is-available="true"
    />
    <distributor-availability-map-legend-item
      :warehouse="warehouse"
      :is-available="true"
      :members="members"
    />
    <distributor-availability-map-legend-item
      :warehouse="warehouse"
      :is-available="false"
      :members="members"
      message="Manque rayon"
    />
    <distributor-availability-map-legend-item
      :warehouse="warehouse"
      :is-available="null"
      :members="members"
    />
  </div>
</template>

<script>
import DistributorAvailabilityMapLegendItem
from '@/renderer/app/disruption-logistics/components/maps/distributors/DistributorAvailabilityMapLegendItem.vue'

export default {
  name: 'DistributorAvailabilityMapLegend',
  components: { DistributorAvailabilityMapLegendItem },
  computed: {
    warehouse () {
      return {
        name: 'Alliance Healthcare Blois',
        street_address_line1: '28 place Gaston Paillhou',
        postal_code: '37000',
        city_name: 'Tours',
        id: 1
      }
    },
    members () {
      return [
        {
          id: 1,
          name: 'Pharmacie de la Gare',
          cip: '12345678',
          address: {
            postal_code: '37000',
            city_name: 'Tours'
          }
        },
        {
          id: 2,
          name: 'Pharmacie Centrale',
          cip: '2345678',
          address: {
            postal_code: '37000',
            city_name: 'Tours'
          },
          wholesaler_warehouses: [
            {
              wholesaler_warehouse_id: 1,
              rank: 1
            }
          ]
        }
      ]
    }
  }
}
</script>
