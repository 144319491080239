<template>
  <img
    :alt="text"
    :src="fullPath"
  >
</template>

<script>
export default {
  props: {
    path: {
      type: String,
      required: true
    },

    text: {
      type: String,
      required: true
    }
  },

  computed: {
    fullPath () {
      return process.env.VUE_APP_API_URL +
        '/images/' +
        this.path
    }
  }
}
</script>
