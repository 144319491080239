var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseCard',{staticClass:"mb-4 p-0 flex flex-grow items-center p-4",staticStyle:{"width":"240px"}},[_c('div',{staticClass:"flex items-center gap-5"},[_c('div',{staticClass:"rounded-full p-2 flex items-center w-14",class:[
        _vm.bgColor === 'white' ? 'bg-tranparent' : '',
        _vm.bgColor === 'pink' ? 'bg-pink-200' : '',
        _vm.bgColor === 'blue' ? 'bg-blue-300' : '',
        _vm.bgColor === 'yellow' ? 'bg-yellow-300' : '',
        _vm.shadow ? 'shadow' : ''
      ]},[(_vm.icon === 'star')?_c('StarSolid',{class:[
          _vm.fillColor === 'yellow' ? 'icon-yellow' : '',
          _vm.fillColor === 'pink' ? 'fill-pink-200' : '',
          _vm.fillColor === 'blue' ? 'fill-blue-300' : '',
          _vm.fillColor === 'white' ? 'icon-white' : '',
        ],attrs:{"width":"48"}}):(_vm.icon === 'user')?_c('UserSolid',{class:[
          _vm.fillColor === 'yellow' ? 'icon-yellow' : '',
          _vm.fillColor === 'pink' ? 'icon-pink' : '',
          _vm.fillColor === 'blue' ? 'fill-blue-300' : '',
          _vm.fillColor === 'white' ? 'icon-white' : ''
        ],attrs:{"width":"48"}}):(_vm.icon === 'shopping-bag')?_c('ShopbagSolid',{class:[
          _vm.fillColor === 'yellow' ? 'icon-yellow' : '',
          _vm.fillColor === 'pink' ? 'fill-pink-200' : '',
          _vm.fillColor === 'blue' ? 'fill-blue-300' : '',
          _vm.fillColor === 'white' ? 'icon-white' : ''
        ],attrs:{"width":"48"}}):_vm._e()],1),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"self-start"},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{staticClass:"self-start font-bold text-lg"},[_vm._v(" "+_vm._s(_vm.processedValue)+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }