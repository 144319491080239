/**
 * The customer product resource.
 *
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    index (consumerKey) {
      return client.get(`consumers/${consumerKey}/products`)
        .then(response => response.data.data)
    }
  }
}
