<template>
  <div
    v-if="!!loyaltyCards?.length"
    class="m-3"
  >
    <h2
      class="text-sm font-bold mb-2"
    >
      Cartes de fidélité
    </h2>
    <div
      ref="loyalty"
      class="flex flex-col gap-4"
    >
      <a
        v-for="loyaltyCard in loyaltyCards.slice(0, maximumLoyaltyCardsToDisplay)"
        :key="`loyalty-${loyaltyCard.id}`"
        class="cursor-pointer"
        @click="$emit('show-membership', loyaltyCard)"
      >
        <notification-detection-loyalty-card-item
          :loyalty-card="loyaltyCard"
          @link-membership="handleLinkMembership"
        />
      </a>
      <div
        v-if="remainingLoyaltyCards > 0"
        class="flex justify-end w-full"
      >
        <span class="text-xs mx-3">
          + {{ remainingLoyaltyCards }} autre{{
            remainingLoyaltyCards > 1 ? "s" : ""
          }} carte{{ remainingLoyaltyCards > 1 ? "s" : "" }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTag from '@/renderer/components/base/BaseTag.vue'
import NotificationLoyaltyCardSimpleVue
from '@/renderer/app/program/components/loyalty-card-notification/NotificationLoyaltyCardSimple.vue'
import NotificationDetectionLoyaltyCardItem
from '@/renderer/components/views/notification/notification-detection/loyalty-cards/NotificationDetectionLoyaltyCardItem.vue'

export default {
  name: 'NotificationDetectionMemberships',
  components: { NotificationDetectionLoyaltyCardItem, NotificationLoyaltyCardSimpleVue, BaseTag },
  props: {
    /**
     * The memberships to display.
     *
     * @type {Object}
     */
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      maximumLoyaltyCardsToDisplay: 3
    }
  },
  computed: {
    /**
     * The consumer memberships.
     *
     * @return {Array}
     */
    loyaltyCards () {
      return this.item.loyalty_cards
    },
    /**
     * The remaining loyalty cards to display.
     *
     * @return {Number}
     */
    remainingLoyaltyCards () {
      return this.loyaltyCards.length - this.maximumLoyaltyCardsToDisplay
    }
  },
  methods: {
    /**
     * Trigger the attach loyalty card event.
     *
     * @param {Object} loyaltyCard
     */
    handleLinkMembership (loyaltyCard) {
      return this.$listeners['link-membership'](loyaltyCard)
    }
  }
}
</script>

<style scoped>

</style>
