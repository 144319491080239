<template>
  <base-card>
    <base-card-body class="flex flex-col gap-3">
      <p class="font-bold text-xl">
        Invitations en attentes
      </p>
      <healthcenter-group-sent-invitation-row
        v-for="invitation in invitations"
        :key="invitation.id"
        :invitation="invitation"
        @cancel-invitation="$emit('cancel-invitation', invitation)"
      />
    </base-card-body>
  </base-card>
</template>

<script>
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import BaseCardBody from '@/renderer/components/base/BaseCardBody.vue'
import HealthcenterGroupSentInvitationRow
from '@/renderer/app/disruption-logistics/components/healthcenter-groups/HealthcenterGroupSentInvitationRow.vue'

export default {
  name: 'HealthcenterGroupSentInvitationCard',
  components: { HealthcenterGroupSentInvitationRow, BaseCardBody, BaseCard },
  props: {
    invitations: {
      type: Array,
      required: true
    }
  }
}
</script>
