<template>
  <div class="w-full">
    <div class="flex flex-col md:flex-row">
      <div class="w-full">
        <div class="flex flex-1 flex-row justify-between p-5">
          <markdown
            :content="step.resolved.description"
          />
          <img
            alt="illustration"
            :src="step.image ? step.image.url : require('@/assets/images/pharmacist-bubble.png')"
            class="w-32 object-contain hidden sm:block"
          >
        </div>
        <div class="flex flex-row justify-end items-center gap-x-3">
          <pathway-delay-label
            v-if="!ready"
            @onSkipDelay="skipDelayMethod()"
          />
          <div
            v-else
            class="p-5"
          >
            <experienceable-pathway-step-button
              :primary="true"
              class="text-xs font-semibold"
              :step="step"
              @click="start"
            >
              {{ step.action }}
            </experienceable-pathway-step-button>
          </div>
        </div>
      </div>
    </div>
    <PathwayStepInsert
      v-if="active"
      :step="step"
    />
  </div>
</template>

<script>
import Markdown from '@/renderer/app/core/components/markdown/Markdown.vue'
import PathwayStepInsert from '@/renderer/app/pathway/components/PathwayStepInsert.vue'
import ExperienceablePathwayStepButton
from '@/renderer/app/program/components/experienceable-program-button/ExperienceablePathwayStepButton.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import PathwayDelayLabel from '@/renderer/app/pathway/components/PathwayDelayLabel.vue'

export default {
  name: 'PathwayLoopStep',
  components: {
    PathwayDelayLabel, BaseButton, ExperienceablePathwayStepButton, PathwayStepInsert, Markdown
  },
  props: {
    step: {
      type: Object,
      required: true
    },
    consumerKey: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ready () {
      if (!this.step.delay) {
        return true
      }

      return this.step.elapsed >= this.step.delay
    }
  },
  methods: {
    /**
     * Emit the start event.
     */
    start () {
      this.$emit('start')
    },
    skipDelayMethod () {
      this.$emit('skipDelay')
    }
  }
}
</script>
