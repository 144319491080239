<template>
  <div class="text-center bg-gray-50 p-12 rounded-lg">
    <svg
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      class="mx-auto h-12 w-12 text-gray-400"
    >
      <path
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>

    <h3 class="mt-2 text-sm font-medium text-gray-900">
      {{ title }}
    </h3>

    <p
      v-if="description"
      class="mt-1 text-sm text-gray-500"
    >
      {{ description }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },

    description: {
      type: String,
      default: null,
      required: false
    }
  }
}
</script>
