<template>
  <div class="grid grid-cols-5 p-2 gap-4 align-center">
    <div class="flex-shrink-0 w-24">
      <BaseImage
        v-if="!!getImageUrl()"
        rendered
        class="h-20 w-20 rounded-lg"
        :text="item.product.name"
        :path="getImageUrl()"
      />
      <BaseImage
        v-else
        rendered
        class="h-20 w-20 rounded-lg"
        :text="item.product.name"
        :path="require('@/assets/images/no-product-image.png')"
      />
    </div>
    <div class="col-span-2">
      <p>{{ item.product.name }}</p>
    </div>
    <p> x{{ item.quantity }}</p>
    <p
      v-if="item.expired"
      class="text-primary text-sm"
    >
      dont {{ item.expired }} {{ 'périmé' | pluralize(item.expired) }}
    </p>
  </div>
</template>

<script>
import BaseImage from '@/renderer/components/base/BaseImage.vue'

export default {
  name: 'CustomerMedicineCabinetItem',
  components: { BaseImage },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    getImageUrl () {
      const path = this.item.product?.image_url

      if (path) {
        return `${process.env.VUE_APP_API_URL}/images/${path}`
      }

      return undefined
    }
  }
}
</script>
