<template>
  <p>
    {{ formatted_point_balance }} <span v-if="rewardInEuro">({{ rewardInEuro }} €)</span>
  </p>
</template>

<script>
export default {
  name: 'LoyaltyCardMembershipPointBalance',
  props: {
    /**
     * The membership point balance.
     */
    point_balance: {
      type: Number,
      required: true
    },
    /**
     * The formatted point balance.
     */
    formatted_point_balance: {
      type: String,
      required: false
    },
    /**
     * The loyalty card point rate in order to compute reward in euro.
     */
    rate: {
      type: Number,
      required: false
    }
  },
  computed: {
    /**
     * Compute the reward equivalent in euro by applying the point rate to the point balance.
     *
     * @return {string|null}
     */
    rewardInEuro () {
      if (!this.rate) {
        return null
      }

      return (this.point_balance * this.rate).toFixed(2)
    }
  }
}
</script>
