<template>
  <BaseContainer>
    <BaseFlexSpinner v-if="isLoading" />
    <BaseCard
      v-else
      class="p-5 md:mx-5"
    >
      <h3 class="my-5 text-xl leading-6 font-bold">
        Patients
      </h3>
      <div
        v-if="disabledConsumerSettings.length > 0"
        class="text-right"
      >
        <p
          class="text-sm mt-6 text-primary hover:underline cursor-pointer"
          @click="disabledConsumersModalVisible = true"
        >
          Voir les {{ disabledConsumerSettings.length }} patient(s) dont la notification a été désactivé
        </p>
        <program-excluded-consumers-modal
          :visible="disabledConsumersModalVisible"
          :consumers="disabledConsumers"
          :support-program-id="resourceId"
          @close="settingsModalClose"
        />
      </div>
      <div
        v-if="meta && !meta.progress"
        class="flex gap-5"
      >
        <button
          class="text-sm font-semibold"
          :class="!groupFilter ? 'text-primary': ''"
          @click="setGroupFilter(null)"
        >
          Tous
        </button>
        <button
          v-for="(item) in Object.entries(meta.included)"
          :key="item[0]"
          class="text-sm font-semibold"
          :class="groupFilter === item[0] ? 'text-primary': ''"
          @click="setGroupFilter(item[0])"
        >
          {{ item[1] }}
        </button>
      </div>
      <input
        v-if="programType !== 'loyalty-card'"
        v-model="consumerFilter"
        type="search"
        class="block w-full mr-10 my-3 border-gray-100 rounded-md shadow-md focus:ring-indigo-500 focus:border-indigo-500 placeholder-gray-300"
        placeholder="Filtrer patients.."
      >
      <component
        :is="consumersTable"
        :consumers="displayedConsumers"
        :resource-id="resourceId"
        :meta="meta"
        @click="showCustomer"
      />
    </BaseCard>
  </BaseContainer>
</template>

<script>
import BaseContainer from '@/renderer/components/base/BaseContainer.vue'
import { instantDiscountVoucherResource, programResource, medicineCabinetApi } from '@/renderer/container'
import LoyaltyCardTableHeader from '../loyalty-card-table-header/LoyaltyCardTableHeader.vue'
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import BaseFlexSpinner from '@/renderer/components/base/spinner/BaseFlexSpinner.vue'
import programHelper from '@/renderer/helpers/programHelper'
import LoyaltyCardConsumersTable
from '@/renderer/app/program/components/program-consumers/LoyaltyCardConsumersTable.vue'
import ProgramGroupConsumersTable
from '@/renderer/app/program/components/program-consumers/ProgramGroupConsumersTable.vue'
import ProgramPathwayConsumersTable
from '@/renderer/app/program/components/program-consumers/ProgramPathwayConsumersTable.vue'
import MedicineCabinetConsumersTable
from '@/renderer/app/program/components/program-consumers/MedicineCabinetConsumersTable.vue'
import InstantDiscountVoucherConsumersTable
from '@/renderer/app/program/components/program-consumers/InstantDiscountVoucherConsumersTable.vue'
import ProgramExcludedConsumersModal
from '@/renderer/app/program/components/program-consumers/ProgramExcludedConsumersModal.vue'

export default {
  name: 'ProgramConsumers',
  components: {
    ProgramExcludedConsumersModal,
    BaseFlexSpinner,
    BaseCard,
    BaseContainer,
    LoyaltyCardTableHeader
  },
  data () {
    return {
      isLoading: false,
      consumers: undefined,
      consumerFilter: null,
      groupFilter: null,
      meta: null,
      programType: String,
      resourceId: null,
      disabledConsumerSettings: [],
      disabledConsumersModalVisible: false
    }
  },
  computed: {
    displayedConsumers () {
      if (this.consumers === undefined) return []
      if (this.consumers.length > 0 && this.consumers[0].groups) {
        return this.consumers
          .filter(consumer =>
            !this.consumerFilter || consumer.name.toLowerCase().includes(this.consumerFilter.toLowerCase()))
          .filter(
            consumer => {
              const groups = consumer.groups.included.map(group => group.slug)

              return !this.groupFilter || (!!this.groupFilter && groups.includes(this.groupFilter))
            }
          )
      } else {
        return this.consumers
          .filter(consumer => {
            let name = consumer.name
            if (this.programType === 'feature') {
              name = consumer.information.name
            }
            return !this.consumerFilter || name.toLowerCase().includes(this.consumerFilter.toLowerCase())
          })
      }
    },
    consumersTable () {
      if (this.programType === 'support') {
        return this.meta.progress ? ProgramPathwayConsumersTable : ProgramGroupConsumersTable
      } else if (this.programType === 'feature') {
        return MedicineCabinetConsumersTable
      } else if (this.programType === 'loyalty-card') {
        return LoyaltyCardConsumersTable
      } else {
        return InstantDiscountVoucherConsumersTable
      }
    },
    disabledConsumers () {
      return this.disabledConsumerSettings.map((setting) => setting.consumer)
    }
  },
  watch: {
    '$route.params': {
      immediate: true,
      deep: true,
      handler (value) {
        this.programType = value.programType
        this.resourceId = value.programmableId
        if (value.programType !== 'loyalty-card') {
          this.isLoading = true
          this.loadConsumers(value.programmableId, value.programType)
            .finally(() => {
              this.isLoading = false
            })
        }
      }
    }
  },
  methods: {
    /**
     * Load the program consumers, depending on the program type.
     *
     * @param id
     * @param type
     * @returns {Promise<Awaited<void>[]>|Promise<void>}
     */
    loadConsumers (id, type) {
      switch (type) {
        case 'support':
          return Promise.all([
            this.loadSupportProgramConsumers(id),
            this.loadExcludedConsumers(id)
          ])
        case 'discount':
          return instantDiscountVoucherResource().customers(id)
            .then(response => {
              this.consumers = response
            })
        case 'feature':
          return medicineCabinetApi().consumersStatistics(this.$store.state.system.environment.entityId)
            .then(response => {
              this.consumers = response
            })
        case 'loyalty-card':
        //
      }
    },
    /**
     * Load the excluded consumers of a support program.
     *
     * @param id
     * @returns {Promise<void>}
     */
    loadExcludedConsumers (id) {
      if (this.programType === 'support') {
        return programResource()
          .supportProgramConsumerSettings(id, { disabled: true })
          .then((data) => {
            this.disabledConsumerSettings = data
          })
      }
    },
    /**
     * Load the consumers of a support program.
     *
     * @param {Number} id - The support program id.
     * @returns {Promise<void>}
     */
    loadSupportProgramConsumers (id) {
      return programResource().consumers(id)
        .then(response => {
          this.consumers = response.data
          this.meta = response.meta
        })
    },
    /**
     * Set the program group filter.
     *
     * @param slug
     */
    setGroupFilter (slug) {
      this.groupFilter = slug
    },
    /**
     * Show the customer details.
     *
     * @param patient
     */
    showCustomer (patient) {
      let route = null
      const program = {}

      if (this.programType === 'support') {
        patient.id = patient.key.slice(2)
      } else if (this.programType === 'discount') {
        patient.id = patient.customer_key.slice(2)
      } else if (this.programType !== 'feature') {
        patient.id = patient.customer.id
      }

      program.id = this.$route.params.programId
      program.programmable_type = this.programType
      program.programmable_id = this.$route.params.programmableId

      if (program.programmable_type === 'support' || program.programmable_type === 'feature') {
        route = {
          name: 'customer.home',
          params: {
            customerId: patient.id
          }
        }
      } else {
        route = programHelper.userProgramRoute(program, patient)
      }

      if (route) {
        this.$router.push(route)
      }
    },
    /**
     * On settings modal close, set the visible state to false and refresh the settings.
     */
    settingsModalClose () {
      this.disabledConsumersModalVisible = false

      if (this.programType === 'support') {
        this.loadExcludedConsumers(this.resourceId)
      }
    }
  }
}
</script>
