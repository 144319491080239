<template>
  <base-container>
    <base-flex-spinner v-if="isLoading" />
    <div
      v-else
      class="flex flex-1 flex-col"
    >
      <div class="flex flex-row w-full p-4">
        <program-logo
          class="mr-3"
          :program="bundle"
        />
        <p class="mt-6 ml-2 text-primary text-xl font-bold">
          {{ bundle.name }}
        </p>
      </div>
      <program-description
        class="mb-5"
        :program="bundle"
      />
      <program-bundle-categories
        v-if="bundle.categories.length"
        :bundle="bundle"
      />
      <div class="flex flex-col px-4">
        <span class="text-xl text-primary font-bold my-2">Programmes</span>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <program-card
            v-for="program in bundle.programs"
            :key="program.id"
            :program="program"
            @click.native="showProgram(program)"
          />
        </div>
      </div>
    </div>
  </base-container>
</template>

<script>
import { programResource } from '@/renderer/container'
import BaseContainer from '@/renderer/components/base/BaseContainer.vue'
import BaseFlexSpinner from '@/renderer/components/base/spinner/BaseFlexSpinner.vue'
import ProgramHeader from '@/renderer/app/program/components/program-header/ProgramHeader.vue'
import ProgramDescription from '@/renderer/app/program/components/program-description/ProgramDescription.vue'
import ProgramLogo from '@/renderer/app/program/components/program-logo/ProgramLogo.vue'
import ProgramBundleCategories
from '@/renderer/app/program/components/program-bundle-categories/ProgramBundleCategories.vue'
import ProgramCard from '@/renderer/app/program/components/program-card/ProgramCard.vue'

export default {
  name: 'ProgramDetails',
  components: {
    ProgramCard,
    ProgramBundleCategories,
    ProgramLogo,
    ProgramDescription,
    ProgramHeader,
    BaseFlexSpinner,
    BaseContainer
  },
  data () {
    return {
      isLoading: true,
      bundle: null
    }
  },
  watch: {
    '$route.params.programBundleId': {
      immediate: true,

      handler (value) {
        this.read(value)
      }
    }
  },
  methods: {
    /**
     * Read a bundle.
     *
     * @param {Number} id
     *
     * @return {void}
     */
    read (id) {
      this.isLoading = true

      programResource()
        .readBundle(id)
        .then(bundle => {
          this.bundle = bundle
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    /**
     * Redirect to the program detail page.
     *
     * @param {Object} program
     */
    showProgram (program) {
      this.$router.push({
        name: 'program.detail.content',
        params: {
          programId: program.id
        }
      })
    }
  }
}
</script>
