var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col gap-3"},[_c('header-back-button',{attrs:{"title":`Disponibilités ventes directes - ${_vm.sourceName}`}}),(_vm.loading)?_c('base-flex-spinner'):_c('div',{staticClass:"flex flex-col gap-3 px-3"},[(_vm.phone || _vm.email)?_c('base-card',[_c('base-card-body',{staticClass:"flex flex-col gap-3"},[_c('h1',{staticClass:"text-lg font-content-bold"},[_vm._v(" Coordonnées ")]),(_vm.phone)?_c('div',[_c('span',[_c('v-icon',{attrs:{"size":"25","color":"black"}},[_vm._v(" mdi-phone ")]),_vm._v(" "+_vm._s(_vm.phone)+" ")],1)]):_vm._e(),(_vm.email)?_c('div',[_c('span',[_c('v-icon',{attrs:{"size":"25","color":"black"}},[_vm._v(" mdi-email ")]),_vm._v(" "+_vm._s(_vm.email)+" ")],1)]):_vm._e()])],1):_vm._e(),(!_vm.availabilities?.length)?_c('p',[_vm._v(" Aucune donnée à afficher. Veuillez nous contacter pour configurer vos disponibilités. ")]):_c('div',{staticClass:"flex flex-col gap-3"},[_c('base-input-search',{attrs:{"value":_vm.search,"placeholder":"Rechercher un produit"},on:{"input":(value) => _vm.search = value}}),_vm._l((_vm.filteredAvailabilities),function(availability){return _c('div',{key:availability.id,staticClass:"bg-white rounded-lg shadow p-3 gap-2 text-primary text-md flex items-center cursor-pointer",on:{"click":function($event){return _vm.$router.push({
          name: 'disruption-logistics.availability-history',
          params: {availabilityId: availability.id}, query: {productName: availability.product.name}
        })}}},[_c('div',{staticClass:"w-4 h-4 rounded-full",class:{
            'bg-green-500': availability.available,
            'bg-red-500': !availability.available
          }}),_c('p',{staticClass:"font-bold flex-1 truncate"},[_vm._v(" "+_vm._s(availability.product.name)+" ")]),_c('div',{staticClass:"flex gap-8"},[(availability.product.has_order_quota)?_c('base-tag',{staticClass:"font-bold uppercase"},[_vm._v(" Sous quota ")]):_vm._e(),_c('p',{staticClass:"self-end"},[_vm._v(" Actuellement "+_vm._s(availability.available ? 'disponible' : 'indisponible')+" ")])],1)])})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }