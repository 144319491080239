<template>
  <div
    class="pt-5 pr-5 pb-5 flex flex-1 leading-4"
    :class="isActive ? 'bg-primary text-white': 'bg-white text-primary'"
    style="min-height: 90px"
  >
    <div class="px-2 flex items-center">
      <button @click="back">
        <Icon
          name="arrow-left"
          :size="24"
          :color="isActive ? '#ffffff': '#3513e2'"
        />
      </button>
    </div>
    <div
      v-show="customer && customer.id && customer.information.name"
      class="flex flex-1 items-center"
    >
      <router-link
        :to="{ name: 'customer.home' }"
        class="flex"
        @mousedown.native="clicked"
      >
        <CustomerSummary
          :customer="customer"
          :active="isActive"
        />
      </router-link>
      <div class="flex flex-1 justify-end">
        <CustomerCertificationHeader
          :customer="customer"
          class="self-end"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/renderer/app/core/icon/Icon.vue'
import CustomerCertificationHeader from '@/renderer/app/customer-certification/components/CustomerCertificationHeader.vue'
import CustomerSummary from '@/renderer/app/customer/components/customer-summary/CustomerSummary.vue'

export default {
  name: 'CustomerHeaderInsured',
  components: { CustomerSummary, CustomerCertificationHeader, Icon },
  props: {
    customer: {
      type: Object,
      required: true
    }
  },

  computed: {
    isActive () {
      return this.$route.name !== 'customer.beneficiary'
    }
  },

  methods: {
    back () {
      if (this.$route.name === 'customer.home' || this.$route.name === 'customer.beneficiary') {
        this.$router.push({ name: 'home' })
      } else {
        this.$router.go(-1)
      }
    },
    clicked () {
      if (this.$route.name === 'customer.home') {
        window.dispatchEvent(new Event('insured-clicked'))
      }
    }
  }
}
</script>
