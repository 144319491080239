import { programAttachmentResource } from '@/renderer/container'
import Vue from 'vue'

export default {
  /**
   * Download the program attachment, and open it in a new browser window.
   *
   * @param {'programs' | 'program-bundles' | 'program-categories'} attachableType - The attachment attachable type.
   * @param {Number} attachableId - The attachment attachable id.
   * @param {Number} attachmentId - The attachment id.
   *
   * @return {Promise<void>}
   */
  downloadAttachmentAndOpen (attachableType, attachableId, attachmentId) {
    return programAttachmentResource()
      .renderAttachment(attachableType, attachableId, attachmentId)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response], { type: response.type }))
        window.open(url)
      })
      .catch(() => {
        Vue.toasted.error('Une erreur est survenue', { duration: 2000 })
      })
  }
}
