<template>
  <div class="w-full bg-lightPurple-lightest px-5 py-2">
    <p
      class="text-xl text-primary font-bold"
      style="margin-bottom: 5px"
    >
      Description
    </p>
    <expandable-text-overflow>
      <div class="font-content">
        <markdown :content="program.description" />
      </div>
    </expandable-text-overflow>
  </div>
</template>

<script>
import ExpandableTextOverflow from '@/renderer/app/core/components/expandable-text-overflow/ExpandableTextOverflow.vue'
import Markdown from '../../../core/components/markdown/Markdown.vue'
export default {
  name: 'ProgramDescription',
  components: { Markdown, ExpandableTextOverflow },
  props: {
    program: {
      type: Object,
      required: true
    }
  }
}
</script>
