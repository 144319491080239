<template>
  <div class="mt-2">
    <ul
      v-if="emergencyOffers.length > 0"
      class="space-y-3"
    >
      <li
        v-for="(offer, index) in emergencyOffers"
        :key="index"
        class="bg-white shadow overflow-hidden rounded-md px-3 py-2"
      >
        <table class="min-w-full">
          <thead>
            <tr class="text-left text-xs text-gray-500 tracking-wider">
              <th>Laboratoire</th>
              <th>Date</th>
              <th>Statut</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ offer.name }}</td>
              <td>{{ offer.startDate }}</td>
              <td>
                <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-light text-gray-800">
                  Badge
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </li>
    </ul>
    <div
      v-else
      class="rounded-md bg-blue-50 p-4"
    >
      <div class="flex">
        <div class="flex-shrink-0">
          <!-- Heroicon name: information-circle -->
          <svg
            class="h-5 w-5 text-blue-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div class="ml-3 flex-1 md:flex md:justify-between">
          <p class="text-sm text-blue-700">
            Aucune donnée compatible avec la pharmacie sélectionnée.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  emergencyOfferResource
} from '@/renderer/container'

export default {
  data () {
    return {
      emergencyOffers: []
    }
  },

  mounted () {
    emergencyOfferResource().get().then((response) => {
      this.emergencyOffers = response
    })
  }
}
</script>
