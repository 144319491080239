import defaultSettings from '@/assets/files/preference.json'

/**
 * The storage service.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @returns {Object} The public object.
 */
export default function () {
  return {
    /**
     * Store the item.
     * @param {string} key - The key of the item.
     * @param {string} value - The value of the item.
     * @returns {void}
     */
    store (key, value) {
      localStorage.setItem(key, value)
    },

    /**
     * Read the item.
     * @param {string} key - The key of the item.
     * @returns {string} The value of the item.
     */
    read (key) {
      return localStorage.getItem(key)
    },

    /**
     * Destroy the item.
     * @param {string} key - The key of the item.
     * @return {void}
     */
    destroy (key) {
      localStorage.removeItem(key)
    },

    loadSettings () {
      let settings

      if (localStorage.getItem('preference')) {
        settings = JSON.parse(localStorage.getItem('preference'))

        const storedKeys = Object.keys(settings.notification).sort()
        const defaultKeys = Object.keys(defaultSettings.notification).sort()

        if (JSON.stringify(storedKeys) === JSON.stringify(defaultKeys)) {
          return settings
        } else {
          localStorage.setItem('preference', JSON.stringify(defaultSettings))
        }
      }

      return defaultSettings
    }
  }
}
