import { render, staticRenderFns } from "./HealthcenterGroupIcon.vue?vue&type=template&id=478040d5&scoped=true&"
import script from "./HealthcenterGroupIcon.vue?vue&type=script&lang=js&"
export * from "./HealthcenterGroupIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "478040d5",
  null
  
)

export default component.exports