<template>
  <div class="flex flex-1 border-4">
    <iframe
      height="100%"
      width="100%"
      loading="lazy"
      :src="link"
    />
  </div>
</template>

<script>
export default {
  name: 'DrugInteraction',
  props: {
    link: {
      type: String,
      required: true
    }
  }
}
</script>
