<template>
  <div
    id="distributor-map"
    class="flex flex-col-reverse lg:flex-row gap-4"
  >
    <div
      v-if="legendOpen"
      class="flex items-center"
    >
      <distributor-availability-map-legend />
    </div>
    <div class="bg-white rounded-md shadow flex-1">
      <div class="p-3 flex flex-col gap-3">
        <div>
          <h2 class="font-content-bold text-xl">
            Disponibilité dans les agences grossistes
          </h2>
          <button
            class="text-sm text-primary"
            @click="legendOpen = !legendOpen"
          >
            {{ legendOpen ? 'Cacher' : 'Voir' }} la légende
          </button>
        </div>
        <distributor-map-statistics
          :availabilities="successData"
        />
      </div>
      <disruption-logistics-map
        ref="mapRef"
        :loading-data="loading"
      >
        <distributor-availability-map-marker
          v-for="warehouse in warehouses"
          :key="warehouse.id"
          :warehouse="warehouse"
          :is-available="isAvailable(warehouse)"
          :members="warehouseMembers(warehouse)"
          @click="toggleInfoWindow"
        />
        <gmap-info-window
          :opened="infoWindowOpened"
          :position="infoContent?.position"
          :options="{ pixelOffset: { width: 0, height: 0 } }"
          @closeclick="infoWindowOpened = false"
        >
          <distributor-availability-info-window
            v-if="infoContent"
            :content="infoContent"
            :members="warehouseMembers(infoContent?.warehouse)"
          />
        </gmap-info-window>
      </disruption-logistics-map>
    </div>
  </div>
</template>

<script>
import DisruptionLogisticsMap from '@/renderer/app/disruption-logistics/components/maps/DisruptionLogisticsMap.vue'
import DistributorAvailabilityMapMarker
from '@/renderer/app/disruption-logistics/components/maps/distributors/DistributorAvailabilityMapMarker.vue'
import DistributorAvailabilityInfoWindow
from '@/renderer/app/disruption-logistics/components/maps/distributors/DistributorAvailabilityInfoWindow.vue'
import DistributorMapStatistics
from '@/renderer/app/disruption-logistics/components/maps/distributors/DistributorMapStatistics.vue'
import DistributorAvailabilityMapLegend
from '@/renderer/app/disruption-logistics/components/maps/distributors/DistributorAvailabilityMapLegend.vue'

export default {
  name: 'DistributorAvailabilityMap',
  components: {
    DistributorAvailabilityMapLegend,
    DistributorMapStatistics,
    DistributorAvailabilityInfoWindow,
    DistributorAvailabilityMapMarker,
    DisruptionLogisticsMap
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    data: {
      type: Array,
      required: false,
      default: () => null
    }
  },
  data () {
    return {
      infoWindowOpened: false,
      infoContent: null,
      legendOpen: false
    }
  },

  computed: {
    loading () {
      return !this.data
    },

    /**
     * The warehouses.
     *
     * @return {WholesalerWarehouse[]}
     */
    warehouses () {
      if (!this.data) {
        return []
      }

      return this.data.map(datum => datum.warehouse)
    },
    /**
     * Get the data for the success availability.
     *
     * @return {Array}
     */
    successData () {
      if (!this.data) {
        return []
      }

      return this.data.filter(datum => datum.success)
    }
  },

  watch: {
    warehouses: {
      handler (warehouses) {
        if (!warehouses?.length) {
          return
        }

        this.$nextTick(() => {
          const bounds = warehouses.map(warehouse => ({
            lat: warehouse.geo_lat,
            lng: warehouse.geo_lon
          }))

          try {
            this.$refs.mapRef.fitMapToBounds(bounds, 0)
          } catch (e) {
            console.error(e)
          }
        })
      },
      deep: true
    }
  },

  methods: {

    /**
     * Get the availability for a warehouse.
     *
     * @param warehouse
     *
     * @return {DistributorProductAvailability[]|null}
     */
    warehouseAvailability (warehouse) {
      if (!this.data) {
        return null
      }

      return this.data.find(
        availability => availability.warehouse?.id === warehouse.id
      )?.products
    },

    /**
     * Determine if the product is available in the warehouse.
     * It return null, if we doesn't have the availability for a warehouse
     *
     * @param {WholesalerWarehouse} warehouse
     *
     * @return {boolean|null}
     */
    isAvailable (warehouse) {
      const availabilities = this.warehouseAvailability(warehouse)

      if (!availabilities?.length) {
        return null
      }

      return availabilities.some(product => product.isAvailable)
    },

    /**
     * Toggle warehouse info window.
     *
     * @param {WholesalerWarehouse} warehouse
     */
    toggleInfoWindow (warehouse) {
      const available = this.isAvailable(warehouse)
      const availabilities = this.warehouseAvailability(warehouse)
      let message = null

      if (availabilities?.length) {
        message = availabilities[0].message?.additif
      }

      this.infoContent = {
        warehouse: warehouse,
        available,
        message,
        position: {
          lat: warehouse.geo_lat,
          lng: warehouse.geo_lon
        }
      }

      this.infoWindowOpened = true
    },
    /**
     * Get the members for a warehouse.
     *
     * @param {Object} warehouse
     *
     * @return {HealthcenterGroupMemberWithWarehouses[]}
     */
    warehouseMembers (warehouse) {
      if (!this.data) {
        return []
      }

      return this.data?.filter(datum => datum.warehouse.id === warehouse.id)
        .map(datum => datum.members)
        .flat()
    }
  }
}
</script>
