<template>
  <div style="width: 34rem;">
    <div
      v-if="attachment.has_thumbnail"
      class="w-32 float-left pr-2 pb-2"
    >
      <base64-image
        v-if="thumbnail"
        alt="Image thumbnail"
        class="object-contain"
        :base64="thumbnail"
      />
    </div>
    <div>
      <span class="line-clamp-3 mb-2 font-bold">
        {{ attachment.title }}
      </span>
      <p>{{ attachment.description }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { programAttachmentResource } from '@/renderer/container'
import Base64Image from '@/renderer/app/core/components/base-64-image/Base64Image.vue'

/**
 * The program attachment card without action component.
 */
export default defineComponent({
  name: 'ProgramAttachmentCardWithoutAction',
  components: { Base64Image },
  props: {
    /**
     * The type of the attachable model linked with the attachment.
     *
     * @values programs, program-categories, program-bundles
     */
    attachableType: {
      type: String,
      required: true
    },
    /**
     * The attachment to display.
     */
    attachment: {
      type: Object,
      required: true
    },
    /**
     * The id of the attachable model linked with the attachment.
     */
    attachableId: {
      type: Number,
      required: true
    },
    /**
     * The tint color of the card.
     */
    tintColor: {
      type: String,
      default: '#000000'
    }
  },
  data () {
    return {
      thumbnail: null
    }
  },
  /**
   * On mount, if the attachment has thumbnail, render it.
   */
  mounted () {
    if (this.attachment.has_thumbnail) {
      programAttachmentResource()
        .renderThumbnail(this.attachableType, this.attachableId, this.attachment.id)
        .then(response => {
          this.thumbnail = Buffer.from(response).toString('base64')
        })
    }
  }
})
</script>
