<template>
  <interview-short-cut-container title="Questionnaires">
    <p>Lancez un questionnaire rapide</p>
    <div class="flex flex-wrap gap-3 mt-3">
      <div
        v-for="survey in surveys"
        class="bg-white rounded-md px-3 py-2 cursor-pointer"
        @click="startSurvey(survey)"
      >
        <span
          v-if="survey.emoji"
          class="mr-2"
        >{{ survey.emoji }}</span> <span>{{ survey.theme ?? survey.name }}</span>
      </div>
    </div>
    <div class="mt-4">
      <h2 class="font-bold text-lg mb-4">
        Résultats {{ this.meta?.total ? `(${this.meta.total})` : "" }}
      </h2>
      <div
        v-if="loading"
        class="mx-auto"
      >
        <base-spinner size="medium-small" />
      </div>
      <div
        v-else
        class="flex flex-col gap-3"
      >
        <p
          v-if="!instances?.length"
          class="text-center"
        >
          Aucun résultats de questionnaire
        </p>
        <div
          v-else
          class="flex flex-col gap-3"
        >
          <div
            v-for="instance in instances"
            class="cursor-pointer"
            @click="exportInstance(instance.id)"
          >
            <div class="bg-white rounded-lg px-3 py-2 shadow-md">
              <p class="line-clamp-2">
                <span
                  v-if="instance.survey.emoji"
                  class="mr-2"
                >{{ instance.survey.emoji }}</span>{{
                  instance.survey.name
                }}
              </p>
              <div
                v-if="instance.verdict"
                class="flex mt-2"
              >
                <div
                  v-if="instance.verdict.color"
                  class="w-1 mr-2 rounded-sm"
                  :style="{
                    backgroundColor: instance.verdict.color
                  }"
                />
                <p
                  v-if="instance.verdict"
                  class="text-sm flex flex-1 line-clamp-3"
                >
                  {{ instance.verdict.title }}
                </p>
              </div>
            </div>
            <p class="text-end text-xs mt-1">
              {{ instance.completed_at | date }}
            </p>
          </div>
          <base-button
            v-if="meta?.current_page < meta?.last_page"
            class="font-medium"
            :disabled="loadingMore"
            @click="loadMore"
          >
            <base-spinner
              v-if="loadingMore"
              size="button"
            />
            <span v-else>Voir plus</span>
          </base-button>
        </div>
      </div>
    </div>
  </interview-short-cut-container>
</template>

<script>
import InterviewShortCutContainer
from '@/renderer/app/interview/components/instance-modal/short-cuts/InterviewShortCutContainer.vue'
import { createNamespacedHelpers } from 'vuex'
import { surveyAnswerInstanceApi } from '@/renderer/container'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import SurveyService from '@/renderer/app/survey/services/SurveyService'
import SurveyAnswerInstanceService from '@/renderer/app/survey/services/SurveyAnswerInstanceService'
import BaseButton from '@/renderer/components/base/BaseButton.vue'

const { mapState } = createNamespacedHelpers('interview')

export default {
  name: 'InterviewSurveys',
  components: { BaseButton, BaseSpinner, InterviewShortCutContainer },
  data () {
    return {
      instances: null,
      loading: false,
      loadingMore: false,
      exporting: false,
      meta: null
    }
  },
  computed: {
    ...mapState(['surveys', 'consumerKey'])
  },
  mounted () {
    this.loading = true
    this.loadConsumerInstances()
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    /**
     * Load the consumer instances.
     *
     * @returns {Promise<void>|*}
     */
    loadConsumerInstances (page = 1) {
      if (!this.consumerKey) {
        return Promise.resolve()
      }

      return surveyAnswerInstanceApi()
        .answeredInstance({ consumer_key: this.consumerKey, page })
        .then((response) => {
          this.meta = response.meta
          this.instances = [...(this.instances ?? []), ...response.data]
        })
    },
    /**
     * Start a new survey instance.
     *
     * @param {Object} survey The survey to start.
     */
    startSurvey (survey) {
      SurveyService.startSurvey(
        survey.id,
        this.consumerKey,
        null,
        null,
        {
          showVerdict: true,
          onClose: () => {
            this.instances = null
            this.loading = true
            this.loadConsumerInstances()
              .finally(() => {
                this.loading = false
              })
          }
        }
      )
    },
    /**
     * Export the survey instance.
     *
     * @param {Number} instanceId The instance id.
     */
    exportInstance (instanceId) {
      if (this.exporting) {
        return
      }

      this.exporting = true
      SurveyAnswerInstanceService.exportAndOpen(instanceId)
        .finally(() => {
          this.exporting = false
        })
    },
    /**
     * Load next page of consumer instances.
     */
    loadMore () {
      if (!this.meta) {
        return
      }

      this.loadingMore = true
      this.loadConsumerInstances(this.meta?.current_page + 1)
        .finally(() => {
          this.loadingMore = false
        })
    }
  }
}
</script>

<style scoped>

</style>
