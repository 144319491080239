<template>
  <div class="relative">
    <button
      type="button"
      class="absolute z-1 bottom-5 right-10 inline-flex items-center px-4 border border-transparent shadow-sm text-sm font-bold rounded-md text-primary bg-white focus:outline-none focus:ring-2 mr-2"
      @click="show"
    >
      <img
        src="@/assets/images/icons/logo-apodis.png"
        class="block w-auto h-10"
      >
      Connecter le patient à votre pharmacie
    </button>
    <BaseCard
      class="bg-orange-default"
      style="min-height: 140px"
    >
      <BaseCardBody>
        <div class="flex col gap-10">
          <span class="w-3/4 text-primary font-content">
            {{ customer.information.name }} n'a pas l'application Apodis pour les patients.
            <br>
            Afin de gérer la relation avec votre patientèle et bénéficier pleinement de nos services, cliquez sur
            <span class="font-content font-bold">Connecter le patient à votre pharmacie.</span>
          </span>
          <div class="flex-col">
            <img
              class="absolute w-36 z-5 -mt-10"
              src="@/assets/images/pharmacist.png"
              alt="Pharmacien"
            >
          </div>
        </div>
      </BaseCardBody>
    </BaseCard>
    <BaseButton
      type="button"
      class="absolute bottom-5 left-10 pt-2 pb-2 pr-2 pl-2 rounded-full"
      :disabled="downloading"
      @click="print"
    >
      <Print
        v-if="!downloading"
        :size="6"
        class="text-primary"
      />
      <div
        v-else
        class="py-1"
      >
        <img
          :src="require('@/assets/loader.gif')"
          alt="Loading"
          width="25"
          height="25"
        >
      </div>
    </BaseButton>
  </div>
</template>

<script>
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import BaseCardBody from '@/renderer/components/base/BaseCardBody.vue'
import { customerCertificationResource, storageService } from '@/renderer/container'
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import Print from '@/renderer/components/icons/Print.vue'

export default {
  components: {
    Print,
    BaseButton,
    BaseCardBody,
    BaseCard
  },
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      downloading: false
    }
  },
  methods: {
    print () {
      this.downloading = true

      window.ipcRenderer
        .invoke('print', {
          url: customerCertificationResource().pdfUrl(this.customer.id, 'qrcode'),
          token: storageService().read('token')
        })
        .finally(() => {
          this.downloading = false
          this.close()
        })
    },
    show () {
      this.$router.push({
        name: 'customer.certification',
        params: { customer: this.customer }
      })
    }
  }
}
</script>
