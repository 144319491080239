<template>
  <div class="relative rounded-md shadow-sm">
    <input
      v-model="model"
      type="search"
      class="block w-full pr-10 py-3 border-gray-100 rounded-md shadow-md focus:ring-indigo-500 focus:border-indigo-500 placeholder-gray-300"
      placeholder="Rechercher, activer un patient…"
    >

    <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
      <svg
        v-if="!isLoading"
        xmlns="http://www.w3.org/2000/svg"
        class="h-7 w-7 text-primary"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>
      <BaseSpinner
        v-else
        size="small"
      />
    </div>
  </div>
</template>

<script>

import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'

export default {
  name: 'SearchBar',
  components: { BaseSpinner },
  props: {
    value: {
      type: String,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
