/**
 * The detection resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Create a detection.
     * @param {string} host - The name of the host.
     * @param {Object} patient - The patient object.
     * @param {boolean} isActive - The detection is active?
     * @returns {Promise} Promise object represents the HTTP request.
     */
    create (host, patient, isActive) {
      return client
        .post('/detections', {
          data: {
            first_name: patient.firstName,
            last_name: patient.lastName,
            is_active: isActive,
            birth_date: patient.birthDate
          }
        }, {
          headers: {
            'X-Host': host,
            'X-Nir': patient.nir
          }
        })
        .then(response => response.data.data)
        .catch(() => null)
    },

    /**
     * Read the detailed detection for the detected customer id.
     *
     * @param {int} customerId
     *
     * @return {Promise<{
     *   consumers: Array<Object>,
     *   memberships: Array<Object>,
     *   certified: Boolean
     * }>} The array of consumer.
     */
    read (customerId) {
      return client
        .get(`/customers/${customerId}/detection`)
        .then(response => response.data.data)
        .catch(() => [])
    }
  }
}
