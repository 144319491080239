<template>
  <base-modal
    v-if="isVisible"
    :title="title"
    @close="cancel"
  >
    <div class="flex flex-col gap-3">
      <p>{{ message }}</p>
      <div class="flex gap-5 self-center">
        <base-button
          :primary="false"
          :disabled="isLoading"
          class="font-bold"
          @click="cancel"
        >
          Annuler
        </base-button>
        <base-button
          primary
          :disabled="isLoading"
          @click="confirm"
        >
          <base-spinner
            v-if="isLoading"
            class="mx-auto"
            size="button"
            color="white"
          />
          <span
            v-else
            class="font-bold"
          >
            Confirmer
          </span>
        </base-button>
      </div>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from '@/renderer/components/base/BaseModal.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'

export default {
  name: 'ConfirmationModal',
  components: { BaseSpinner, BaseButton, BaseModal },
  data () {
    return {
      isVisible: true,
      title: '',
      message: '',
      isLoading: false,
      successCallback: null
    }
  },
  methods: {
    /**
     * Show the modal.
     *
     * @param {String} title The title.
     * @param {String} message The message.
     * @param {() => any | Promise<any>} success The success callback.
     */
    show (title, message, success) {
      this.title = title
      this.message = message
      this.isVisible = true
      this.successCallback = success
    },
    /**
     * Handle the modal confirmation.
     */
    confirm () {
      const callback = this.successCallback()

      if (callback instanceof Promise) {
        this.handlePromiseCallback(callback)
      } else {
        this.isVisible = false
      }
    },
    /**
     * Handle the confirmation of type Promise.
     *
     * @param {Promise} callback The callback.
     */
    handlePromiseCallback (callback) {
      this.isLoading = true
      callback
        .then(() => {
          this.isLoading = false
          this.isVisible = false
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    /**
     * Cancel the modal.
     */
    cancel () {
      this.isVisible = false
    }
  }
}
</script>
