<template>
  <button
    class="relative px-6 inline-flex items-center content-center py-2 border font-medium shadow text-sm rounded-2xl focus:outline-none"
    :class="[ primary
                ? 'border-transparent text-white bg-primary'
                : 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50',
              { 'cursor-not-allowed opacity-50': disabled }
    ]"
    :disabled="disabled"
    @click="click"
  >
    <div
      style="width: 100%"
      :class="{'invisible': loading}"
    >
      <slot />
    </div>
    <div
      v-if="loading"
      class="absolute left-1/2"
      style="margin-left: -10px"
    >
      <BaseSpinner
        size="button"
        :color="primary ? 'white' : 'primary'"
      />
    </div>
  </button>
</template>

<script>
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'

export default {
  components: { BaseSpinner },
  props: {
    primary: {
      type: Boolean
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    /**
     * Click handler.
     */
    click (event) {
      if (this.$listeners.click) {
        const callback = this.$listeners.click(event)

        if (callback instanceof Promise) {
          this.loading = true

          callback.finally(() => {
            this.loading = false
          })
        }
      }
    }
  }
}
</script>

<style scoped>
.invisible {
  color: #3513e2;
}
</style>
