/**
 * Pluralize a string
 *
 * @param {String} value - The string to pluralize
 * @param {Number} count - The count to determine if the string should be pluralized
 * @return {*|string}
 */
export const pluralize = (value, count) => {
  if (count > 1) {
    return `${value}s`
  }

  return value
}
