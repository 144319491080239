<template>
  <div class="p-6">
    <div class="flex flex-row pt-6 pb-4">
      <SupportProgramDescription
        v-if="program"
        :program="program"
        class="flex w-64"
      />
      <PathwayActionCard
        v-if="pathway"
        :pathway="pathway"
        :consumer-key="$route.params.consumerKey"
        class="flex flex-1 ml-12"
      />
      <BaseCard
        v-if="hasLoadedPathway && !pathway"
        class="w-64 flex flex-1 ml-12 overflow-visible bg-pink-lighter"
      >
        <BaseCardBody style="padding: 8px 14px;">
          <h1 class="text-sm font-content-bold">
            Que faire ?
          </h1>
          <div class="text-xs">
            {{ program.teaser }}
          </div>
        </BaseCardBody>
      </BaseCard>
    </div>
    <PathwayStepper
      v-if="pathway"
      :pathway="pathway"
      :consumer-key="$route.params.consumerKey"
      class="mt-4"
      @skipDelay="skipDelay()"
    />
    <div
      v-if="!hasLoadedAttachments || !hasLoadedAdvices ||!hasLoadedPathway"
      class="p-6"
    >
      <BaseSpinner size="medium-small" />
    </div>
    <div
      v-if="hasLoadedAttachments && hasLoadedAdvices && hasLoadedPathway"
      class="flex flex-col space-y-4 pt-4"
    >
      <SupportProgramAdvices
        v-if="hasLoadedAdvices && advices && advices.length > 0"
        :advices="advices"
      />
      <SupportProgramDocumentGrid
        v-if="hasLoadedAttachments && attachments && attachments.length > 0"
        :attachments="attachments"
      />
      <SupportProgramProtocol
        v-if="consumerSettings"
        :consumer-protocol="consumerSettings"
      />
      <SupportProgramPurchaseHistory
        v-if="hasLoadedHistory"
        :history="history"
      />
      <div class="my-6">
        <BaseButton
          v-if="!hasLoadedHistory"
          class="mr-6"
          @click="loadSaleHistory(program.programmable_id, $route.params.consumerKey)"
        >
          Afficher l'historique des achats
        </BaseButton>
        <BaseButton @click="toggleConsumerSettings(program.programmable_id, $route.params.consumerKey)">
          {{
            consumerDisabled
              ? 'Ce patient a été noté comme exclu du programme'
              : 'Exclure ce patient du programme'
          }}
        </BaseButton>
      </div>
      <div v-if="hasLoadedTerms">
        <SupportProgramMention :mentions="terms" />
      </div>
      <div
        v-if="!!program.additional_information?.length"
        class="pl-10"
      >
        <markdown :content="program.additional_information" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import { programAttachmentResource, programResource } from '@/renderer/container'
import SupportProgramDescription from '@/renderer/app/program/components/support-program/SupportProgramDescription.vue'
import SupportProgramPurchaseHistory
from '@/renderer/app/program/components/support-program/SupportProgramPurchaseHistory.vue'
import SupportProgramAdvices from '@/renderer/app/program/components/support-program/SupportProgramAdvices.vue'
import SupportProgramDocumentGrid
from '@/renderer/app/program/components/support-program/SupportProgramDocumentGrid.vue'
import PathwayActionCard from '@/renderer/app/pathway/components/PathwayActionCard.vue'
import PathwayStepper from '@/renderer/app/pathway/components/PathwayStepper.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import BaseCardBody from '@/renderer/components/base/BaseCardBody.vue'
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import SupportProgramMention from '@/renderer/app/program/components/support-program/SupportProgramMention.vue'
import SupportProgramProtocol from '@/renderer/app/program/components/support-program/SupportProgramProtocol.vue'
import Markdown from '@/renderer/app/core/components/markdown/Markdown.vue'

export default {
  components: {
    Markdown,
    SupportProgramProtocol,
    SupportProgramMention,
    BaseCard,
    BaseCardBody,
    BaseButton,
    PathwayStepper,
    PathwayActionCard,
    SupportProgramDocumentGrid,
    SupportProgramAdvices,
    SupportProgramPurchaseHistory,
    SupportProgramDescription,
    BaseSpinner
  },

  data () {
    return {
      program: null,
      advices: null,
      history: null,
      attachments: null,
      terms: null,
      consumerDisabled: null,
      hasLoadedHistory: false,
      hasLoadedAdvices: false,
      hasLoadedAttachments: false,
      hasLoadedPathway: false,
      hasLoadedTerms: false,
      consumerSettings: null
    }
  },
  computed: {
    pathway () {
      return this.program && this.$store.getters['patient/pathway'](this.program.programmable_id)
    }
  },
  watch: {
    '$route.params': {
      immediate: true,

      handler (params) {
        if (params.program) {
          this.load(params)
        } else if (params.programId) {
          programResource()
            .read(params.programId)
            .then((program) => this.load({
              program,
              consumerKey: params.consumerKey
            }))
        }
      }
    }
  },
  methods: {
    load (params) {
      this.program = params.program
      this.getConsumerSettings(params.program.programmable_id, params.consumerKey)
      this.loadAdvices(params.program.programmable_id, params.consumerKey)
      this.loadPatientAttachments(params.program.id)
      this.loadPathway(params.program.programmable_id, params.consumerKey)
      this.loadTerms(params.program.programmable_id)
    },
    /**
     * Load the consumer sale history for the support program.
     *
     * @param {Number} supportProgramId - The support program id.
     * @param {String} consumerKey - The consumer key.
     *
     * @return {Promise<void>}
     */
    loadSaleHistory (supportProgramId, consumerKey) {
      return programResource()
        .history(supportProgramId, consumerKey)
        .then(history => {
          this.history = history
          this.hasLoadedHistory = true
        })
    },
    /**
     * Load the consumer advices for the support program.
     * @param {Number} supportProgramId - The support program id.
     * @param {String} consumerKey - The consumer key.
     * @return {Promise<void>}
     */
    loadAdvices (supportProgramId, consumerKey) {
      return programResource()
        .advices(supportProgramId, consumerKey)
        .then(advices => {
          this.advices = advices
          this.hasLoadedAdvices = true
        })
    },
    /**
     * Load the pathway.
     * @param {Number} supportProgramId - The support program id.
     * @param {Number} consumerKey - The consumer key.
     * @param skipDelay
     */
    loadPathway (supportProgramId, consumerKey, skipDelay = false) {
      return this.$store.dispatch('patient/loadPathway', {
        supportProgramId,
        consumerKey,
        skipDelay
      })
        .catch(error => {
          if (!error.response || error.response.status !== 404) {
            throw error
          }
        })
        .finally(() => {
          this.hasLoadedPathway = true
        })
    },
    /**
     * Load program attachments destined for the consumer.
     *
     * @param {Number} programId - The program id.
     *
     * @return {Promise<void>}
     */
    loadPatientAttachments (programId) {
      const params = {
        entity_type_slug: 'patient',
        type: 'document'
      }

      return programAttachmentResource()
        .attachmentIndex('programs', programId, params)
        .then(attachments => {
          this.attachments = attachments
          this.hasLoadedAttachments = true
        })
    },
    /**
     * Get the consumer settings.
     *
     * @param {Number} supportProgramId - The support program id.
     * @param {String} consumerKey - The consumer key.
     *
     * @return {Promise<Object>}
     */
    getConsumerSettings (supportProgramId, consumerKey) {
      return programResource()
        .readSupportProgramConsumerSettings(supportProgramId, consumerKey)
        .then(response => {
          this.consumerDisabled = response.disabled
          this.consumerSettings = response
        })
    },
    /**
     * Toggle the consumer disabled settings.
     *
     * @param {Number} supportProgramId - The support program id.
     * @param {String} consumerKey - The consumer key.
     *
     * @return {Promise<Object>}
     */
    toggleConsumerSettings (supportProgramId, consumerKey) {
      return programResource()
        .toggleConsumerSettings(supportProgramId, consumerKey)
        .then(response => {
          this.consumerDisabled = response.disabled
          window.dispatchEvent(new Event('refresh-customer'))
        })
    },

    loadTerms (supportProgramId) {
      return programResource()
        .terms(supportProgramId)
        .then(response => {
          this.terms = response
          this.hasLoadedTerms = true
        })
    },
    /**
     * Reload the pathway skipping the delay.
     *
     * @return {Promise<unknown>}
     */
    skipDelay () {
      this.hasLoadedPathway = true
      return this.loadPathway(
        this.program.programmable_id,
        this.$route.params.consumerKey,
        true
      )
    }
  }
}
</script>
