<template>
  <div>
    <div
      v-if="discounts.length"
      class="space-y-4 m-4"
    >
      <div
        v-for="discount in discounts"
        :key="discount.id"
        class="bg-white overflow-hidden shadow rounded-lg"
      >
        <div class="px-4 py-5 sm:p-6 flex flex-row">
          <div class="basis-20 flex-shrink-0">
            <BaseImage
              rendered
              :text="discount.name"
              :path="discount.image.renderPath"
              class="w-48 h-48 rounded-xl border border-gray-200"
            />
          </div>

          <div class="ml-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              {{ discount.name }}
            </h3>

            <p class="mt-1 text-sm text-gray-500">
              Valable jusqu’au {{ discount.formatted.endDate }}
            </p>

            <div class="mt-4 flex-shrink-0 text-white">
              <router-link
                :to="{
                  name: 'customer.discount',

                  params: {
                    discountId: discount.id
                  }
                }"
                class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-dark mr-2"
              >
                Voir
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <p class="text-sm font-medium text-gray-500">
        Aucune promotion disponible
      </p>
    </div>
  </div>
</template>

<script>
import {
  instantDiscountVoucherResource
} from '@/renderer/container'

import BaseImage from '@/renderer/components/base/BaseImage.vue'

export default {
  components: {
    BaseImage
  },

  data () {
    return {
      discounts: []
    }
  },

  mounted () {
    instantDiscountVoucherResource()
      .get()
      .then(discounts => (this.discounts = discounts))
  }
}
</script>
