<template>
  <div class="ml-7 mt-1 h-52 w-76 bg-gray-300 rounded-md relative text-tiny font-semibold">
    <div class="bottom-6 right-0 rounded-md w-32 h-16 bg-gray-50 absolute bg-primary text-white py-2 pl-1 pr-2">
      <div class="flex flex-1 justify-center">
        Patient comptoir
      </div>
      <div class="flex flex-row p-2 space-x-3">
        <div
          v-for="index in [1,2,3]"
          :key="index"
          class="flex rounded-md w-6 h-6 bg-gray-100 text-black items-center justify-center"
        >
          {{ index }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotificationSimpleLayout'
}
</script>
