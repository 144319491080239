import systemStore from '@/renderer/stores/system-store'

const HealthcenterGroupService = () => {
  /**
   * Check if the given environment is the owner of the group.
   *
   * @param {number} ownerAccessId The group to check.
   */
  const isOwner = (ownerAccessId) => {
    const environment = systemStore.state.environment

    if (!environment) {
      return false
    }

    return ownerAccessId === environment.id
  }

  /**
   * Determine if the given healthcenter ID the same as the authenticated healthcenter.
   *
   * @param {Number} healthcenterId
   * @return {boolean}
   */
  const healthcenterIsAuth = (healthcenterId) => {
    const authHealthcenterId = systemStore.state.environment.entityId

    if (!healthcenterId) {
      return false
    }

    return healthcenterId === authHealthcenterId
  }

  return {
    isOwner,
    healthcenterIsAuth
  }
}

export default HealthcenterGroupService()
