<template>
  <BaseModal
    v-show="isGuideModalVisible"
    :title="guide.title"
    @close="isGuideModalVisible = false"
  >
    <Guide
      :v-if="guide"
      :guide="guide"
    />
    <template v-slot:footer>
      <BaseButton @click="isGuideModalVisible = false">
        Fermer
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/renderer/components/base/BaseModal.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import Guide from '@/renderer/components/views/guide/Guide.vue'

export default {
  name: 'GuideModal',

  components: {
    Guide,
    BaseModal,
    BaseButton
  },
  props: {
    guide: {
      type: Object,
      required: false,
      default: undefined
    },
    isGuideModalVisible: {
      type: Boolean,
      required: true
    }
  }
}
</script>
