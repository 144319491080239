/**
 * The event helper.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @returns {Object} The public object.
 */
export default {
  /**
   * Generate a route from an event.
   * @param {Object} event - The event object.
   * @returns {Object|null} The generated route.
   */
  generateRoute (event) {
    if (event.custom_event) {
      return {
        name: 'brand.event',

        params: {
          eventId: event.id
        }
      }
    }

    switch (event.type) {
      case 'sellout-participation':
        return {
          name: 'brand.offer.sellout',

          params: {
            selloutId: event.id
          }
        }

      case 'prestation-participation':
        return {
          name: 'brand.offer.prestation',

          params: {
            prestationId: event.id
          }
        }

      case 'instant-discount-voucher':
        return {
          name: 'brand.offer.discount',

          params: {
            discountId: event.id
          }
        }

      case 'sellin-offer-participation':
        return {
          name: 'brand.offer.sellin',

          params: {
            sellinOfferId: event.id
          }
        }

      case 'sellin-challenge-participation':
        return {
          name: 'brand.offer.sellin.challenge',

          params: {
            sellinChallengeId: event.id
          }
        }

      default:
        return null
    }
  }
}
