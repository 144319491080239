<template>
  <div class="grid md:grid-cols-3 justify-items-center gap-2">
    <div
      v-for="(item, itemIdx) in stats"
      :key="itemIdx"
      class="flex flex-1 flex-col gap-5 p-4 rounded-lg shadow-md w-full"
      :style="{'background-color': item.color ? item.color : 'white'}"
    >
      <ProgramStatisticsCard
        v-if="item.type !=='pie-chart' && item.type !=='binomial-pie-chart'"
        :data="item"
      />
      <ProgramPieChartStatisticsCard
        v-if="item.type ==='pie-chart'"
        :data="item"
      />
      <ProgramBinomialPieChartStatisticsCard
        v-if="item.type ==='binomial-pie-chart'"
        :data="item"
      />
    </div>
  </div>
</template>

<script>
import ProgramStatisticsCard from '@/renderer/app/program/components/program-statistics/ProgramStatisticsCard.vue'
import ProgramPieChartStatisticsCard
from '@/renderer/app/program/components/program-statistics/ProgramPieChartStatisticsCard.vue'
import ProgramBinomialPieChartStatisticsCard
from '@/renderer/app/program/components/program-statistics/ProgramBinomialPieChartStatisticsCard.vue'

export default {
  name: 'ProgramStatisticsCards',
  components: {
    ProgramBinomialPieChartStatisticsCard,
    ProgramPieChartStatisticsCard,
    ProgramStatisticsCard
  },
  props: {
    stats: {
      type: Array,
      required: true
    }
  }
}
</script>
