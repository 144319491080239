<template>
  <div class="w-full">
    <component
      :is="answerComponent"
      :question="question"
      :inline="inline"
      @onPress="onAnswerPress"
    />
    <div
      v-if="question.optional && question.type.slug !== SurveyQuestionTypeSlug.TEXT_INPUT"
      class="flex flex-row justify-end mt-2"
    >
      <base-button
        @click="onAnswerPress(undefined)"
      >
        <span class="font-content">Suivant</span>
      </base-button>
    </div>
  </div>
</template>

<script>
import SurveyAnswerYesNo from '@/renderer/app/survey/components/SurveyAnswerYesNo.vue'
import SurveyAnswerSingleChoice from '@/renderer/app/survey/components/SurveyAnswerSingleChoice.vue'
import { SurveyQuestionTypeSlug } from '@/renderer/enum'
import SurveyAnswerTextInput from '@/renderer/app/survey/components/SurveyAnswerTextInput.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'

export default {
  name: 'SurveyAnswer',
  components: {
    BaseButton,
    SurveyAnswerYesNo,
    SurveyAnswerSingleChoice,
    SurveyAnswerTextInput
  },
  props: {
    question: {
      type: Object,
      required: true
    },
    inline: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      SurveyQuestionTypeSlug
    }
  },
  computed: {
    /**
     * Return the answer content.
     *
     * @returns {string}
     */
    answerComponent () {
      switch (this.question.type.slug) {
        case SurveyQuestionTypeSlug.YES_NO:
          return 'SurveyAnswerYesNo'
        case SurveyQuestionTypeSlug.SINGLE_CHOICE:
        case SurveyQuestionTypeSlug.MULTIPLE_CHOICE:
          return 'SurveyAnswerSingleChoice'
        case SurveyQuestionTypeSlug.TEXT_INPUT:
          return 'SurveyAnswerTextInput'
        default:
          return 'SurveyAnswerSingleChoice'
      }
    }
  },
  methods: {
    /**
     * Emit an onAnswerPress event when an answer is pressed.l
     *
     * @param answer
     */
    onAnswerPress (answer) {
      this.$emit('onAnswerPress', answer)
    }
  }
}
</script>
