<template>
  <div class="h-full">
    <section v-show="beneficiary.programs">
      <ConsumerPrograms
        :customer="customer"
        :consumer="beneficiary"
        :consumers="[beneficiary]"
      />
    </section>
  </div>
</template>

<script>
import ConsumerPrograms from '@/renderer/app/consumer/components/consumer-programs/ConsumerPrograms.vue'

export default {
  components: {
    ConsumerPrograms
  },

  props: {
    customer: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      qrcode: null,
      memberships: null,
      programs: null
    }
  },
  computed: {
    beneficiary () {
      return this.customer.beneficiaries.find(beneficiary => beneficiary.id === this.$route.params.id)
    }
  }
}
</script>
