export default function (hdsClient) {
  return {
    /**
     * Get the hospital patient files for a given consumer key.
     *
     * @returns {Promise} Promise object represents the HTTP request.
     */
    index (consumerKey) {
      return hdsClient
        .get(`consumers/${consumerKey}/hospital-patient-files`)
        .then(response => response.data.data)
    },

    /**
     * Get the hospital patient file for a given id.
     *
     * @param {Object }file
     * @return {Promise<Blob>}
     */
    download (file) {
      return hdsClient
        .get(`hospital-patient-files/${file.id}`, { responseType: 'blob' })
        .then((response) => response.data)
    }
  }
}
