<template>
  <BaseModal
    v-show="opened"
    :title="order ? 'Gestion commande n°' + order.id : 'Gestion commande'"
    class="z-20"
    @close="closeModal"
  >
    <BaseSpinner v-if="isLoading" />
    <handle-order
      v-if="order&&!isLoading"
      :order="order"
      @orderHandled="processedAction(order)"
      @goToPatient="goToPatient"
    />
  </BaseModal>
</template>

<script>

import HandleOrder from '@/renderer/app/order/components/HandleOrder.vue'
import BaseModal from '@/renderer/components/base/BaseModal.vue'
import { orderApi } from '@/renderer/container'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'

export default {
  name: 'ActionModal',
  components: {
    BaseSpinner,
    HandleOrder,
    BaseModal
  },
  props: {
    opened: {
      type: Boolean,
      required: true
    },
    processAction: {
      type: Object,
      default: null
    },
    orderId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      order: this.processAction
    }
  },
  watch: {
    orderId: {
      immediate: true,

      handler () {
        if (this.orderId && !this.processAction?.content) {
          this.isLoading = true
          orderApi().read(this.orderId)
            .then(response => {
              this.order = response
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      }
    },
    processAction: {
      handler () {
        this.order = this.processAction
      }
    }
  },
  methods: {
    /**
     * Close the modal.
     */
    closeModal () {
      this.$emit('closeModal')
    },
    /**
     * Delete live item once the action associated has been processed.
     *
     * @param action
     */
    processedAction (action) {
      this.closeModal()
      this.$store.commit('live/deleteItems', action)
    },
    /**
     * Show patient details.
     *
     * @param patient
     */
    goToPatient (patient) {
      this.$emit('show', patient)
    }
  }
}
</script>
