<template>
  <div>
    <base-spinner v-if="loading" />
    <v-stepper
      v-else
      v-model="step"
      :non-linear="true"
      style="box-shadow: none; background: transparent;"
    >
      <v-stepper-header class="mx-4 mb-2 bg-white rounded-xl">
        <v-stepper-step
          :complete="step > 1"
          step="1"
          @click="step=1"
        >
          Saisir
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          :complete="step > 2"
          step="2"
          @click="step > 2 ? step=2 : null"
        >
          Identifier
        </v-stepper-step>

        <v-divider />

        <v-stepper-step step="3">
          Diffuser
        </v-stepper-step>
      </v-stepper-header>
      <div class="flex flex-row">
        <prescription-visual
          :prescription="prescription"
          class="pt-8"
        />
        <div class="flex flex-1 flex-col p-2">
          <v-stepper-items>
            <v-stepper-content step="1">
              <prescription-renewal-plan
                :start-date="startDate"
                :end-date="endDate"
                :dates="dates"
                @quick-fill="quickFill"
                @remove-date="removeDate"
                @next-step="handlePlanInput"
                @start-update="updateStartDate"
                @end-update="updateEndDate"
              />
            </v-stepper-content>

            <v-stepper-content step="2">
              <prescription-renewal-identifier
                :team-access="teamAccess"
                :operator-codes="operatorCodes"
                :selected-operator-code="selectedOperatorCode"
                @on-code-update="updateOperatorCode"
                @next-step="step=3"
              />
            </v-stepper-content>
            <v-stepper-content step="3">
              <prescription-renewal-publish
                :dates="dates"
                :is-renewable="isRenewable"
                :end-date-formatted="endDateFormatted"
                :selected-operator-label="itemLabel(selectedOperatorCode)"
                @on-publish="isRenewable ? createRenewalPlan() : updateRenewableState()"
              />
            </v-stepper-content>
          </v-stepper-items>
        </div>
      </div>
    </v-stepper>
  </div>
</template>

<script>
import dateHelper from '@/renderer/helpers/dateHelper'
import dayjs from 'dayjs'
import PrescriptionRenewalPlan from '@/renderer/app/prescription/components/PrescriptionRenewalPlan.vue'
import PrescriptionVisual from '@/renderer/app/prescription/components/PrescriptionVisual.vue'
import PrescriptionRenewalPublish from '@/renderer/app/prescription/components/PrescriptionRenewalPublish.vue'
import { prescriptionApi, teamApi } from '@/renderer/container'
import PrescriptionRenewalIdentifier from '@/renderer/app/prescription/components/PrescriptionRenewalIdentifier.vue'
import ToastService from '@/renderer/services/ToastService'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'

export default {
  name: 'PrescriptionRenewalDetailScreen',
  components: {
    BaseSpinner,
    PrescriptionRenewalIdentifier,
    PrescriptionRenewalPlan,
    PrescriptionVisual,
    PrescriptionRenewalPublish
  },
  data () {
    return {
      prescription: null,
      dates: [],
      teamAccess: [],
      operatorCodes: [],
      selectedOperatorCode: null,
      isRenewable: true,
      loading: true,
      step: 1,
      startDate: dayjs().format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD')
    }
  },
  computed: {
    dateHelper () {
      return dateHelper
    },
    endDateFormatted () {
      return dateHelper.shortFormat(this.endDate)
    }
  },
  watch: {
    '$route.params.prescription': {
      immediate: true,

      handler (value) {
        this.prescription = value
      }
    },
    startDate: {
      handler () {
        this.dates = []
      }
    },
    endDate: {
      handler () {
        this.dates = []
      }
    }
  },
  mounted () {
    this.read()
    this.indexOperatorCodes()
  },
  methods: {
    /**
     * Read details of the prescription, necessary to have information missing in index ressource.
     */
    read () {
      prescriptionApi().read(this.prescription.customer.id, this.prescription.key)
        .then(response => {
          this.prescription = response
        })
        .finally(() => {
          this.loading = false
        })
    },

    /**
     * Get item label.
     *
     * @param {string} code - The operator code.
     * @return {string}
     */
    itemLabel (code) {
      const access = this.teamAccess?.find(access => access.operatorCode === code)

      if (!access) {
        return code
      }

      return `${access.user.name} (${code})`
    },
    /**
     * Remove a date from the list of renewal dates.
     *
     * @param date
     */
    removeDate (date) {
      this.dates.splice(this.dates.indexOf(date), 1)
    },
    /**
     * Quickly fill renewal dates based on provided frequency and selected renewal end date.
     *
     * @param frequency
     */
    quickFill (frequency) {
      this.dates = []
      let newDate = dayjs(this.startDate).add(frequency, 'day')

      while (newDate.isBefore(dayjs(this.endDate))) {
        this.dates.push(newDate.format('YYYY-MM-DD'))
        newDate = newDate.add(frequency, 'day')
      }
    },
    /**
     * Get list of operator codes.
     */
    indexOperatorCodes () {
      teamApi().index()
        .then(response => {
          this.teamAccess = response.team

          return teamApi().operatorCodes()
        })
        .then(response => {
          this.operatorCodes = response
        })
    },
    /**
     * Update start date of renewal plan.
     *
     * @param date
     */
    updateStartDate (date) {
      this.startDate = date
    },
    /**
     * Update end date of renewal plan.
     *
     * @param date
     */
    updateEndDate (date) {
      this.endDate = date
    },
    /**
     * Update the operator code of the renewal plan.
     *
     * @param code
     */
    updateOperatorCode (code) {
      this.selectedOperatorCode = code
    },
    /**
     * Handle what's the next step if the prescription has been noted as non renewable or not.
     *
     * @param {boolean} renewable
     */
    handlePlanInput (renewable) {
      this.isRenewable = renewable
      if (renewable) {
        this.step = 2
      } else {
        this.step = 3
      }
    },
    /**
     * Update the renewable state of the prescription.
     *
     * @return {Promise<unknown>}
     */
    updateRenewableState () {
      if (this.prescription && this.prescription.professional.id) {
        const toast = ToastService()
        return prescriptionApi().overwrite(this.prescription, this.prescription.customer.id, !this.prescription.renewable)
          .then(() => {
            toast.success('L\'ordonnance a été marqué comme non renouvelable')
            this.$router.go(-1)
          })
          .catch(() => {
            toast.error('Erreur lors de la mise à jour de l\'ordonnance')
          })
      }
    },
    /**
     * Create a new renewal plan.
     *
     * @return {Promise<unknown>}
     */
    createRenewalPlan () {
      const params = {
        end_at: this.endDate,
        dates: this.dates.map(date => {
          return { renew_at: date }
        }),
        prescription_key: this.prescription.key,
        operator_code: this.selectedOperatorCode
      }
      const toast = ToastService()
      return prescriptionApi().createRenewal(params)
        .then(() => {
          toast.success('Le plan de renouvellement a été créé avec succès')
        })
        .catch(() => {
          toast.error('Une erreur s\'est produite lors de la création du plan de renouvellement')
        })
        .finally(() => {
          this.$router.go(-1)
        })
    }
  }
}
</script>
