<template>
  <div v-if="isLoading">
    <BaseSpinner />
  </div>
  <div
    v-else
    class="mx-6"
  >
    <support-program-pathway-timeline
      v-if="pathway"
      :pathway="pathway"
    />
    <div v-else>
      <p>Aucun parcours de soin n'existe pour ce programme.</p>
    </div>
  </div>
</template>

<script>
import { programResource } from '@/renderer/container'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import SupportProgramPathwayTimeline
from '@/renderer/app/program/components/support-program-pathway-timeline/SupportProgramPathwayTimeline.vue'

export default {
  name: 'SupportProgramPathwayDetail',
  components: { BaseSpinner, SupportProgramPathwayTimeline },
  data () {
    return {
      isLoading: true,
      pathway: null
    }
  },
  watch: {
    '$route.params.supportProgramId': {
      immediate: true,

      handler (value) {
        this.readPathway(value)
      }
    }
  },
  methods: {
    /**
     * Read the support program pathway.
     *
     * @param id - The support program id.
     * @return {Promise<void>}
     */
    readPathway (id) {
      this.isLoading = true
      return programResource()
        .pathway(id)
        .then(pathway => {
          this.pathway = pathway
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
