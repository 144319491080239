<template>
  <div
    id="group-map"
    class="bg-white rounded-md shadow"
  >
    <div class="p-3">
      <h2 class="font-content-bold text-xl">
        Disponibilité dans les pharmacies partenaires
      </h2>
    </div>
    <disruption-logistics-map
      ref="mapRef"
      :loading-data="loading"
    >
      <healthcenter-group-availability-map-marker
        v-for="healthcenter in uniqueHealthCenters"
        :key="healthcenter.id"
        :healthcenter="healthcenter"
        @click="toggleInfoWindow"
      />
      <gmap-info-window
        :opened="infoWindowOpened"
        :position="infoContent?.position"
        :options="{ pixelOffset: { width: 0, height: 0 } }"
        @closeclick="infoWindowOpened = false"
      >
        <healthcenter-group-availability-info-window
          :content="infoContent"
        />
      </gmap-info-window>
    </disruption-logistics-map>
    <div class="p-4 flex flex-col gap-3">
      <healthcenter-group-map-statistics :healthcenters="uniqueHealthCenters" />
      <div class="flex justify-end">
        <button
          class="flex justify-end items-center gap-1"
          @click="$router.push({ name: 'disruption-logistics.healthcenter-groups' })"
        >
          <v-icon>
            mdi-cog
          </v-icon>
          <span class="text-primary">
            Gérer mes partenaires
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DisruptionLogisticsMap from '@/renderer/app/disruption-logistics/components/maps/DisruptionLogisticsMap.vue'
import HealthcenterGroupAvailabilityInfoWindow
from '@/renderer/app/disruption-logistics/components/maps/healthcenter-groups/HealthcenterGroupAvailabilityInfoWindow.vue'
import HealthcenterGroupMapStatistics
from '@/renderer/app/disruption-logistics/components/maps/healthcenter-groups/HealthcenterGroupMapStatistics.vue'
import { gmapApi } from 'vue2-google-maps'
import HealthcenterGroupAvailabilityMapMarker
from '@/renderer/app/disruption-logistics/components/maps/healthcenter-groups/HealthcenterGroupAvailabilityMapMarker.vue'

export default {
  name: 'HealthcenterGroupAvailabilityMap',
  components: {
    HealthcenterGroupAvailabilityMapMarker,
    HealthcenterGroupMapStatistics,
    HealthcenterGroupAvailabilityInfoWindow,
    DisruptionLogisticsMap
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    /** @type {HealthcenterGroupMemberWithProductsStock[]|null} */
    data: {
      type: Array,
      required: false,
      default: () => null
    }
  },
  data () {
    return {
      infoWindowOpened: false,
      infoPosition: null,
      infoContent: null
    }
  },
  computed: {
    loading () {
      return !this.data
    },

    google () {
      return gmapApi()
    },
    /**
     * Get the unique health centers from the groups.
     *
     * @return {HealthcenterGroupMemberWithProductsStock[]}
     */
    uniqueHealthCenters () {
      if (!this.data) {
        return []
      }

      const reducedArray = this.data.flatMap(group => group.healthcenters)
        .reduce((acc, healthcenter) => {
          return {
            ...acc,
            [healthcenter.id]: healthcenter
          }
        }, {})

      return Object.values(reducedArray)
    }
  },
  watch: {
    data: {
      handler () {
        this.infoWindowOpened = false
        this.infoContent = null
        this.infoPosition = null
        this.$nextTick(() => {
          const bounds = this.uniqueHealthCenters
            .map(healthcenter => ({
              lat: healthcenter.address.latitude,
              lng: healthcenter.address.longitude
            }))

          if (bounds.length) {
            this.$refs.mapRef.fitMapToBounds(bounds)
          }
        })
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    /**
     * Toggle the info window
     *
     * @param {HealthcenterGroupMemberWithProductsStock} healthcenter
     */
    toggleInfoWindow (healthcenter) {
      const healthcenterGroups = this.data
        .filter(group => group.healthcenters.some(h => h.id === healthcenter.id))

      this.infoContent = {
        healthcenter: healthcenter,
        groups: healthcenterGroups,
        position: {
          lat: healthcenter.address.latitude,
          lng: healthcenter.address.longitude
        }
      }

      this.infoWindowOpened = true
    }
  }
}
</script>
