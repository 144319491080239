<template>
  <create-healthcenter-group-form
    :name="group.name"
    :description="group.description"
    edit-mode
    :loading="loading"
    @submit="handleSubmit"
  />
</template>

<script>
import CreateHealthcenterGroupForm
from '@/renderer/app/disruption-logistics/components/healthcenter-groups/CreateHealthcenterGroupForm.vue'
import { disruptionLogisticsApi } from '@/renderer/container'

export default {
  name: 'HealthcenterGroupEditForm',
  components: { CreateHealthcenterGroupForm },
  props: {
    group: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    handleSubmit (data) {
      this.loading = true

      disruptionLogisticsApi()
        .editHealthcenterGroup(this.group.id, data)
        .then((group) => {
          this.$emit('saved', group)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
