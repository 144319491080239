<template>
  <interview-short-cut-container title="Historique de délivrances">
    <div v-if="loading">
      <base-flex-spinner />
    </div>
    <div v-else-if="carts">
      <div v-if="!carts.length">
        <p class="text-center">
          Aucun produit trouvé
        </p>
      </div>
      <div
        v-else
        class="flex flex-col gap-3"
      >
        <div v-for="cart in carts">
          <p class="text-sm">
            {{ cart.date }}
          </p>
          <div class="bg-white shadow rounded-md p-2 flex flex-col gap-1">
            <div class="flex gap-4">
              <div class="flex flex-col gap-1 flex-1">
                <div v-for="product in cart.products">
                  <div class="flex">
                    <p class="text-sm">
                      <span class="font-bold">{{ product.quantity }}x</span> {{ product.name }}
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="cart.prescription_urls?.length">
                <secure-image
                  v-if="cart.prescription_urls?.length === 1"
                  :urls="cart.prescription_urls[0]"
                  :size="32"
                  :ratio="0.7"
                />
                <secure-image-slider
                  v-else
                  :images="cart.prescription_urls"
                  :size="32"
                  :ratio="0.7"
                />
              </div>
            </div>
          </div>
        </div>
        <base-button
          v-if="meta.after_key"
          @click="loadMore"
        >
          Voir plus
        </base-button>
      </div>
    </div>
    <div v-else>
      <p class="text-center">
        Une erreur s'est produite
      </p>
    </div>
  </interview-short-cut-container>
</template>

<script>
import InterviewShortCutContainer
from '@/renderer/app/interview/components/instance-modal/short-cuts/InterviewShortCutContainer.vue'
import { createNamespacedHelpers } from 'vuex'
import { consumerCartApi } from '@/renderer/container'
import BaseFlexSpinner from '@/renderer/components/base/spinner/BaseFlexSpinner.vue'
import LabeledSeparator from '@/renderer/app/core/labeled-separator/LabeledSeparator.vue'
import SecureImage from '@/renderer/app/core/components/secure-image/SecureImage.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import SecureImageSlider from '@/renderer/app/core/components/secure-image/SecureImageSlider.vue'

const { mapState } = createNamespacedHelpers('interview')

export default {
  name: 'InterviewConsumerCarts',
  components: {
    SecureImageSlider,
    BaseButton,
    SecureImage,
    LabeledSeparator,
    BaseFlexSpinner,
    InterviewShortCutContainer
  },
  data () {
    return {
      carts: null,
      loading: true,
      meta: null
    }
  },
  mounted () {
    this.loading = true
    this.loadCarts()
      .finally(() => {
        this.loading = false
      })
  },
  computed: {
    ...mapState({
      consumerKey: state => state.consumerKey
    })
  },
  methods: {
    /**
     * Load consumer carts
     *
     * @param afterKey
     * @returns {Promise<void>}
     */
    loadCarts (afterKey = null) {
      return consumerCartApi()
        .index(this.consumerKey, afterKey)
        .then(response => {
          this.carts = [...(this.carts ?? []), ...response.data]
          this.meta = response.meta
        })
    },
    /**
     * Load more carts
     *
     * @returns {Promise<void>}
     */
    loadMore () {
      return this.loadCarts(this.meta.after_key)
    }
  }
}
</script>
