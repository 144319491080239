<template>
  <div>
    <div class="mb-4 flex items-center justify-between">
      <BaseButton @click="previous()">
        <svg
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0
            01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
          />
        </svg>
      </BaseButton>

      <span class="capitalize font-bold">
        {{ date }}
      </span>

      <BaseButton @click="next()">
        <svg
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0
            011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
          />
        </svg>
      </BaseButton>
    </div>

    <Calendar
      ref="calendar"
      style="height: 800px;"
      view="month"
      :is-read-only="true"
      :use-detail-popup="false"
      :usage-statistics="false"
      :schedules="schedules"
      :month="{
        daynames: [
          'Dimanche',
          'Lundi',
          'Mardi',
          'Mercredi',
          'Jeudi',
          'Vendredi',
          'Samedi'
        ]
      }"
      @clickSchedule="navigate"
    />
  </div>
</template>

<script>
import {
  Calendar
} from '@toast-ui/vue-calendar'

import 'tui-calendar/dist/tui-calendar.css'
import eventHelper from '@/renderer/helpers/eventHelper'
import BaseButton from '@/renderer/components/base/BaseButton.vue'

export default {
  components: {
    Calendar,
    BaseButton
  },

  props: {
    events: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      timestamp: null
    }
  },

  computed: {
    schedules () {
      return this.events.map(event => {
        let route = null

        if (event.custom_event) {
          route = {
            name: 'brand.event',

            params: {
              eventId: event.id
            }
          }
        } else {
          route = eventHelper.generateRoute(event)
        }

        return {
          id: event.id,
          title: event.name,
          category: 'time',
          start: event.start,
          end: event.end,
          bgColor: event.lightColor,
          raw: { route }
        }
      })
    },

    date () {
      return new Date(this.timestamp)
        .toLocaleDateString(undefined, {
          month: 'long',
          year: 'numeric'
        })
    }
  },

  mounted () {
    this.setTimestamp()
  },

  methods: {
    previous () {
      this.$refs.calendar.invoke('prev')
      this.setTimestamp()
    },

    next () {
      this.$refs.calendar.invoke('next')
      this.setTimestamp()
    },

    setTimestamp () {
      this.timestamp = this.$refs.calendar.invoke('getDate').getTime()
    },

    navigate (item) {
      const route = item.schedule.raw.route

      if (route) {
        this.$router.push(route)
      }
    }
  }
}
</script>
