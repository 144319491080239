<template>
  <div>
    <div class="flex flex-row align-center mb-4">
      <p class="text-primary mt-1">
        J'ajoute un commentaire à la commande, si nécessaire, pour conclure
      </p>
    </div>
    <div class="flex flex-col justify-center mt-4">
      <p>Je sélectionne un commentaire : </p>
      <div
        v-for="predefinedComment in predefinedComments"
        class="bg-primary-lightest p-3 my-3 rounded-lg cursor-pointer"
        @click="onPredefinedCommentPress(predefinedComment)"
      >
        <p>{{ predefinedComment }}</p>
      </div>
    </div>
    <div class="flex flex-col justify-center mt-4">
      <p>Ou je le saisis manuellement : </p>
      <textarea
        v-model="comment"
        rows="3"
        class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm ring-gray-700 border-gray-700 rounded-md"
      />
    </div>
    <div
      v-if="loading"
      class="flex flex-col justify-center align-center mt-2 h-32"
    >
      <BaseSpinner class="w-80" />
    </div>
    <div
      v-else
      class="flex flex-col justify-center align-center mt-2 h-32"
    >
      <transition name="slide-fade">
        <base-button
          v-if="comment"
          primary
          class="my-2 w-80"
          @click="validate(comment)"
        >
          Valider avec commentaire
        </base-button>
      </transition>
      <base-button
        class="my-2 w-80"
        @click="validate(null)"
      >
        Valider sans commentaire
      </base-button>
    </div>
    <div class="flex mt-10">
      <p
        class="text-primary p-2 shadow rounded-lg cursor-pointer"
        @click="goBack"
      >
        Retour
      </p>
    </div>
  </div>
</template>

<script>

import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import Icon from '@/renderer/app/core/icon/Icon.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'

export default {
  name: 'OrderComment',
  components: { BaseSpinner, Icon, BaseButton },
  props: {
    order: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      comment: null,
      predefinedComments: [
        'Les médicaments de votre ordonnance sont prêts. Vous pouvez vous rendre à votre pharmacie pour les récupérer.' +
        ' Pensez à votre carte de vitale et votre ordonnance !',
        'Votre commande est prête, elle vous attend à la pharmacie. Pour gagner du temps, rendez vous en ' +
        'caisse “click & collect”'
      ]
    }
  },
  methods: {
    validate (comment) {
      this.$emit('validate', comment)
    },
    onPredefinedCommentPress (predefinedComment) {
      this.comment = predefinedComment
    },
    goBack () {
      this.$emit('back')
    }
  }
}
</script>

<style>
/* Slide fade animation. */
.slide-fade-enter-active {
  transition: all 1s ease;
}

.slide-fade-leave-active {
  transition: height 1.5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
