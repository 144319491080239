export default function (hdsClient) {
  return {
    /**
     * List of prescriptions
     *
     * @param {Object} params Params of the search, the available params are listed below.
     *     limit?: number;
     *     certified?: boolean;
     *     recently_renewed?: boolean;
     *     no_dosage_plan?: boolean;
     *     no_renewal_plan?: boolean;
     *     after?: any;
     *     from?: string;
     *     to?: string
     *
     * @returns {Promise}
     */
    index (params = {}) {
      return hdsClient.get('prescriptions', { params })
        .then(response => response.data)
    },

    /**
     * Read a prescription.
     *
     * @param customerId Customer id.
     * @param prescriptionKey Prescripiton key.
     *
     * @return {Promise}
     */
    read (customerId, prescriptionKey) {
      return hdsClient.get(`customers/${customerId}/prescriptions/${prescriptionKey}`)
        .then(response => response.data.data)
    },

    /**
     * Overwrite a prescription.
     *
     * @param {Prescription} prescription - The prescription.
     * @param {number} customerId - The customer id.
     * @param {boolean} renewable - The renewable status.
     *
     * @returns {Promise}
     */
    overwrite (prescription, customerId, renewable) {
      const data = { prescriptor_id: prescription.professional.id, products: prescription.products, renewable }
      return hdsClient.post(`customers/${customerId}/prescriptions/${prescription.key}`, { data })
        .then(response => response.data.data)
    },

    /**
     *
     * @param {Object} params Params necessary to create a prescription renewal, the necessary params are listed below.
     *    end_at: string;
     *    dates: string[];
     *    prescription_key: string
     *    operator_code?: string
     *
     * @return {Promise}
     */
    createRenewal (params) {
      return hdsClient.post('prescriptions/renewals/plans', { data: params })
        .then(response => response.data.data)
    },

    /**
     * List customer prescriptions.
     *
     * @param {number} customerId Customer id of the list of prescriptions we want to get.
     * @param {Object} [params] Params of the request are the following :
     *   limit?: number;
     *   after?: string;
     * @param {number} [beneficiaryId] Beneficiary id, default is 0 to only show customer prescriptions.
     *
     * @return {Promise}
     */
    customerIndex (customerId, params = {}, beneficiaryId = 0) {
      params = { beneficiary_id: beneficiaryId, ...params }
      return hdsClient.get(`customers/${customerId}/prescriptions`, { params })
        .then(response => response.data)
    }
  }
}
