/**
 * The sellout participation resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Read a sellout participation.
     * @param {number} selloutId - The ID of the sellout.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    read (selloutId) {
      return client
        .get(`/user/me/sellout/${selloutId}`, {
          params: {
            include: 'sellout.organization.morphable,sellout.products'
          }
        })
        .then(response => response.data.userSellout)
    },

    /**
     * Accept a sellout participation.
     * @param {number} selloutId - The ID of the sellout.
     * @param {number} rewardDistributionId - The ID of the reward distribution.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    accept (selloutId, rewardDistributionId) {
      return client
        .put(`/user/me/sellout/${selloutId}/accept`, {
          rewardDistributionId
        }, {
          params: {
            include: 'sellout.organization.morphable,sellout.products'
          }
        })
        .then(response => response.data.userSellout)
    },

    /**
     * Refuse a sellout participation.
     * @param {number} selloutId - The ID of the sellout.
     * @param {string} comments - The reason for refusal.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    refuse (selloutId, comments) {
      return client
        .put(`/user/me/sellout/${selloutId}/refuse`, {
          comments
        }, {
          params: {
            include: 'sellout.organization.morphable,sellout.products'
          }
        })
        .then(response => response.data.userSellout)
    }
  }
}
