<template>
  <div class="relative">
    <div class="flex flex-row justify-around bg-white rounded-md shadow-sm mx-4">
      <input
        v-model="keyword"
        type="search"
        class="block w-full pr-10 py-3 rounded-md placeholder-gray-500 focus:shadow-none focus:ring-transparent"
        placeholder="Rechercher par nom, prénom, RPPS…"
      >

      <div class="pr-3 flex items-center pointer-events-none">
        <svg
          v-if="!(isLoading || processing)"
          xmlns="http://www.w3.org/2000/svg"
          class="h-7 w-7 text-primary"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
        <BaseSpinner
          v-else
          size="small"
        />
      </div>
      <div
        v-if="results.length"
        class="absolute shadow top-full z-10 w-full lef-0 rounded max-h-72 overflow-y-auto p-4"
      >
        <div
          v-if="isLoading"
          class="flex flex-col w-full z-100 bg-white"
        >
          <div class="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-teal-100">
            <div
              class="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-teal-100"
            >
              <div class="w-full items-center flex">
                Recherche en cours…
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div
            v-for="(result, index) in results"
            :key="index"
            class="grid grid-cols-3 mx-auto text-sm p-2 border-b-1 border-red-500 hover:bg-purple-200 cursor-pointe bg-white"
            @click="onPress(result)"
          >
            <div class="pl-2 text-purple-700">
              {{ result.name }}
            </div>

            <div class="col-span-3">
              <div>
                <span
                  v-if="result.speciality"
                  class="ml-5 text-xs"
                >{{ result.speciality }}</span>
                <span class="ml-5 text-xs">{{ result.address.street }} {{ result.address.city }}
                  {{ result.address.postal_code }}
                </span>
              </div>
            </div>

            <hr class="col-span-3 w-full">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import { prescriptorResource } from '@/renderer/container'

export default {
  name: 'PrescriptorSearchBar',
  components: { BaseSpinner },
  props: {
    selectedFilters: {
      type: Array,
      default: null
    },
    processing: {
      type: Boolean,
      default: false
    },
    onPress: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      results: [],
      keyword: null,
      isLoading: false,
      timeoutId: null
    }
  },
  watch: {
    keyword: {
      handler (value) {
        if (value.length === 0) {
          clearTimeout(this.timeoutId)
          this.results = []
        } else if (value.length >= 3) {
          this.search()
        }
      }
    }
  },
  methods: {
    /**
     * Search a prescriptor
     */
    search () {
      clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(() => {
        this.isLoading = true
        const params = { search: this.keyword }
        if (this.selectedFilters) {
          params.speciality_ids = this.selectedFilters
        }
        prescriptorResource().search(params)
          .then(prescriptors => {
            this.results = prescriptors
            this.isLoading = false
          })
          .catch(() => {})
      }, 600)
    }
  }
}
</script>
