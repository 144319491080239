<template>
  <interview-short-cut-container title="Pour le patient">
    <program-attachment-grid
      :attachments="patientAttachments"
      :attachable-id="interview.id"
      attachable-type="interviews"
    />
  </interview-short-cut-container>
</template>

<script>
import ProgramAttachmentGrid from '@/renderer/app/program/components/program-attachment/ProgramAttachmentGrid.vue'
import InterviewShortCutContainer
from '@/renderer/app/interview/components/instance-modal/short-cuts/InterviewShortCutContainer.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapState, mapGetters } = createNamespacedHelpers('interview')

export default {
  name: 'InterviewPatientAttachments',
  components: { InterviewShortCutContainer, ProgramAttachmentGrid },
  computed: {
    ...mapGetters(['patientAttachments']),
    ...mapState(['interview'])
  }
}
</script>
