export default function (client) {
  return {
    /**
     * Get the credit balance for the current organization.
     *
     * @return {Promise<Object>}
     */
    getBalance () {
      return client
        .get('/organization/credit')
        .then(response => response.data.data)
    }
  }
}
