import { render, staticRenderFns } from "./CustomerCertificationStatusRow.vue?vue&type=template&id=2c0ec6e4&"
import script from "./CustomerCertificationStatusRow.vue?vue&type=script&lang=js&"
export * from "./CustomerCertificationStatusRow.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports