<template>
  <div>
    <div
      v-for="section in extractSections(attachments)"
      class="flex flex-col mb-4"
    >
      <div class="mb-4">
        <span class="text-primary font-bold">{{ section.title }}</span>
        <p class="flex flex-1">
          {{ section.description }}
        </p>
      </div>
      <div class="flex flex-row gap-8 flex-wrap px-2 items-start">
        <div
          v-for="attachment in attachmentsForSection(attachments, section)"
          :key="attachment.id"
        >
          <program-attachment-card
            v-if="hasAction(attachment)"
            :attachment="attachment"
            :attachable-type="attachableType"
            :attachable-id="attachableId"
            :tint-color="tintColor"
          />
          <program-attachment-card-without-action
            v-else
            :attachable-id="attachableId"
            :attachment="attachment"
            :attachable-type="attachableType"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-col w-full">
      <div
        v-if="extractSections(attachments).length !== 0 && attachmentsWithoutSection(attachments).length > 0"
        class="mb-4"
      >
        <span class="text-primary font-bold">Autres ressources</span>
      </div>
      <div class="flex flex-row gap-8 flex-wrap px-2 items-start">
        <div
          v-for="attachment in attachmentsWithoutSection(attachments)"
          :key="attachment.id"
        >
          <program-attachment-card
            v-if="hasAction(attachment)"
            :attachment="attachment"
            :attachable-type="attachableType"
            :attachable-id="attachableId"
            :tint-color="tintColor"
          />
          <program-attachment-card-without-action
            v-else
            :attachable-id="attachableId"
            :attachment="attachment"
            :attachable-type="attachableType"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import ProgramAttachmentCard from '@/renderer/app/program/components/program-attachment/ProgramAttachmentCard.vue'
import ProgramAttachmentCardWithoutAction
from '@/renderer/app/program/components/program-attachment/ProgramAttachmentCardWithoutAction.vue'
import BaseContainer from '@/renderer/components/base/BaseContainer.vue'
import LabeledSeparator from '@/renderer/app/core/labeled-separator/LabeledSeparator.vue'

/**
 * The program attachment grid component.
 * Extract sections from the attachments and display them.
 */
export default defineComponent({
  name: 'ProgramAttachmentGrid',
  components: { LabeledSeparator, BaseContainer, ProgramAttachmentCardWithoutAction, ProgramAttachmentCard },
  props: {
    /**
     * The type of the attachable model linked with the attachment.
     *
     * @values programs, program-categories, program-bundles
     */
    attachableType: {
      type: String,
      required: true
    },
    /**
     * The id of the attachable model linked with the attachment.
     */
    attachableId: {
      type: Number,
      required: true
    },
    /**
     * The attachments to display.
     */
    attachments: {
      type: Array,
      required: true
    },
    /**
     * The tint color of the cards.
     */
    tintColor: {
      type: String,
      default: '#00000030'
    }
  },
  methods: {
    /**
     * Determine if a given attachment has an action associated.
     *
     * @param {Object} attachment - The attachment
     *
     * @return {boolean}
     */
    hasAction (attachment) {
      return attachment.type || attachment.external_link
    },
    /**
     * Extract the sections from the given attachments.
     *
     * @param {Array} attachments - The attachments to extract the sections from.
     *
     * @return {Array} The unique attachments sections.
     */
    extractSections (attachments) {
      return attachments.reduce((sections, attachment) => {
        const attachmentSections = attachment.sections

        attachmentSections.forEach(attachmentSection => {
          const section = sections.find(_section => _section.id === attachmentSection.id)

          if (!section) {
            sections.push(attachmentSection)
          }
        })

        return sections
      }, [])
    },
    /**
     * Get the attachments for the given section.
     *
     * @param {Array} attachments - The attachments to filter.
     * @param {Object} section - The section to filter the attachments for.
     *
     * @return {Array} The attachments for the given section.
     */
    attachmentsForSection (attachments, section) {
      const filteredAttachments = attachments.filter(attachment => {
        return attachment.sections.find(_section => _section.id === section.id)
      })

      return this.orderAttachments(filteredAttachments)
    },
    /**
     * Get the attachment without section.
     *
     * @param {Array} attachments - The attachments to filter.
     *
     * @return {Array} The attachments without section.
     */
    attachmentsWithoutSection (attachments) {
      const filteredAttachments = attachments.filter(attachment => {
        return attachment.sections.length === 0
      })

      return this.orderAttachments(filteredAttachments)
    },
    /**
     * Order the attachments to get these who has action first.
     *
     * @param attachments
     */
    orderAttachments (attachments) {
      return attachments.sort((a, b) => {
        if (this.hasAction(a) && !this.hasAction(b)) {
          return -1
        }

        if (!this.hasAction(a) && this.hasAction(b)) {
          return 1
        }

        return 0
      })
    }
  }
})
</script>
