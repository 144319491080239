<template>
  <div>
    <div v-if="prescriptions.length">
      <component
        :is="prescriptionCard"
        v-for="prescription in prescriptions"
        :key="prescription.id"
        :prescription="prescription"
        @on-card-press="onCardPress"
      />
    </div>
    <div v-else>
      {{ noPrescriptionMessage }}
    </div>
  </div>
</template>

<script>
import PrescriptionRenewalCard from '@/renderer/app/prescription/components/PrescriptionRenewalCard.vue'
import PrescriptionCard from '@/renderer/app/prescription/components/PrescriptionCard.vue'

export default {
  name: 'PrescriptionList',
  props: {
    prescriptions: {
      type: Array,
      required: true
    },
    noPrescriptionMessage: {
      type: String,
      default: 'Aucune ordonnance disponible'
    },
    type: {
      type: String,
      default: 'prescription',
      validator (value) {
        return ['prescription', 'renewal', 'dosage-plan'].includes(value)
      }
    }
  },
  computed: {
    prescriptionCard () {
      if (this.type === 'renewal') {
        return PrescriptionRenewalCard
      } else {
        return PrescriptionCard
      }
    }
  },
  methods: {
    onCardPress (prescription) {
      this.$emit('on-press', prescription)
    }
  }
}
</script>
