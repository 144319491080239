<template>
  <div>
    <h1 class="text-2xl font-bold text-gray-900">
      Détail de la commande
    </h1>

    <p class="text-sm font-medium text-gray-500">
      Commandée le {{ date }}
    </p>

    <BaseCard class="mt-6">
      <BaseCardBody>
        <BaseAlert
          type="success"
          title="Nouvelle commande"
        >
          <p>Votre commande a bien été prise en compte.</p>
        </BaseAlert>

        <ul
          role="list"
          class="divide-y divide-gray-200 space-y-4 mt-4"
        >
          <li
            v-for="line in order.lines"
            :key="line.id"
            class="pt-4"
          >
            <p class="text-sm font-bold">
              {{ line.productName }}
            </p>

            <p class="mt-1 flex items-center text-sm text-gray-500">
              {{ line.productCip13 }}
            </p>

            <ul class="text-sm flex flex-col mt-4 space-y-2 sm:flex-row sm:space-x-6 sm:space-y-0">
              <li>
                <span class="font-bold">
                  Quantité :
                </span>

                <span class="text-gray-700">
                  {{ line.quantity }}
                </span>
              </li>

              <li>
                <span class="font-bold">
                  Prix HT :
                </span>

                <span class="text-gray-700">
                  {{ line.priceWithoutVat.toFixed(2) }} €
                </span>
              </li>

              <li>
                <span class="font-bold">
                  TVA {{ line.vatRate }}% :
                </span>

                <span class="text-gray-700">
                  {{ ( line.totalDiscountedWithVat - line.totalDiscountedWithoutVat).toFixed(2) }} €
                </span>
              </li>

              <li>
                <span class="font-bold">
                  Total TTC :
                </span>

                <span class="text-gray-700">
                  {{ line.totalDiscountedWithVat.toFixed(2) }} €
                </span>
              </li>
            </ul>
          </li>
        </ul>

        <div class="space-y-6 border-t border-gray-200 pt-8 mt-8 text-sm sm:flex sm:space-y-0">
          <div class="flex-1">
            <h3 class="font-bold mb-2">
              Adresse d’expédition
            </h3>

            <p class="text-gray-700">
              {{ order.healthcenterName }}<br>
              {{ order.healthcenterAddressLine1 }}
              {{ order.healthcenterAddressLine2 }}<br>
              {{ order.healthcenterZipCode }}
              {{ order.healthcenterCity }}
            </p>
          </div>

          <div class="flex-1">
            <h3 class="font-bold mb-2">
              Adresse de facturation
            </h3>

            <p class="text-gray-700">
              {{ order.healthcenterName }}<br>
              {{ order.healthcenterAddressLine1 }}
              {{ order.healthcenterAddressLine2 }}<br>
              {{ order.healthcenterZipCode }}
              {{ order.healthcenterCity }}
            </p>
          </div>
        </div>

        <div class="flex border-t border-gray-200 pt-8 mt-8 text-sm">
          <ul class="flex-1 font-bold space-y-4">
            <li>Cumul des remises</li>
            <li>Total HT</li>
            <li>Total TVA</li>
            <li>Total TTC</li>
          </ul>

          <ul class="flex-1 space-y-4 text-gray-700">
            <li>
              {{ totalRemisesHT.toFixed(2) }} €

              <i class="text-xs">
                soit {{ ((totalRemisesHT / totalHT) * 100).toFixed(1) }}%
                du Total HT
              </i>
            </li>

            <li>{{ totalHT.toFixed(2) }}€</li>
            <li>{{ (totalTTC - totalHT).toFixed(2) }} €</li>
            <li>{{ totalTTC.toFixed(2) }} €</li>
          </ul>
        </div>
      </BaseCardBody>
    </BaseCard>
  </div>
</template>

<script>
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import BaseAlert from '@/renderer/components/base/BaseAlert.vue'
import BaseCardBody from '@/renderer/components/base/BaseCardBody.vue'

export default {
  components: {
    BaseCard,
    BaseAlert,
    BaseCardBody
  },

  data () {
    return {
      order: {
        lines: [],
        createdAt: null,
        healthcenterName: null,
        healthcenterAddressLine1: null,
        healthcenterAddressLine2: null,
        healthcenterZipCode: null,
        healthcenterCity: null
      }
    }
  },

  computed: {
    totalHT () {
      return this.order.lines.reduce(
        (total, item) => total + item.totalDiscountedWithoutVat, 0
      )
    },

    totalTTC () {
      return this.order.lines.reduce(
        (total, item) => total + item.totalDiscountedWithVat, 0
      )
    },

    totalRemisesHT () {
      return this.order.lines.reduce(
        (total, item) => total + (item.priceWithoutVat * item.quantity) - item.totalDiscountedWithoutVat, 0
      )
    },

    date () {
      return new Date(this.order.createdAt)
        .toLocaleDateString(undefined, {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
          weekday: 'long'
        })
    }
  },

  mounted () {
    this.order = this.$route.params.order
  }
}
</script>
