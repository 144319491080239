<template>
  <div class="h-screen overflow-hidden bg-primary-lightest flex flex-col">
    <TheTopBar />

    <div class="min-h-0 flex-1 flex overflow-hidden">
      <TheSideBar />

      <main class="min-w-0 flex-1 lg:flex">
        <section
          aria-labelledby="primary-heading"
          class="min-w-0 flex-1 h-full flex flex-col overflow-auto lg:order-last"
        >
          <router-view />
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import {
  hostResource,
  environmentResource,
  authenticationService,
  notificationResource
} from '@/renderer/container'

import { version } from '../../../../package.json'
import TheTopBar from '@/renderer/components/partials/TheTopBar.vue'
import TheSideBar from '@/renderer/components/partials/TheSideBar.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapActions } = createNamespacedHelpers('extensions')

export default {
  components: {
    TheTopBar,
    TheSideBar
  },

  mounted () {
    if (!authenticationService().authenticated) {
      this.$router.push({
        name: 'login'
      })
    } else {
      this.loadExtensions()

      hostResource()
        .update({
          name: window.hostname,
          connect_version: version
        })
        .then(host => {
          this.$store.commit('setHost', host)
        })

      environmentResource()
        .read()
        .then(environment => {
          this.$store.commit('setEnvironment', environment)
        })

      notificationResource()
        .count()
        .then(response => {
          this.$store.commit('setNotificationCount', response.count)
        })
    }
  },
  methods: {
    ...mapActions(['loadExtensions'])
  }
}
</script>
