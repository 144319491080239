/**
 * The team resource.
 *
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Index of team members.
     *
     * @return {Promise}
     */
    index () {
      return client.get('/access/team')
        .then(response => response.data)
    },

    /**
     * Get operator codes.
     *
     * @returns {Promise}
     */
    operatorCodes () {
      return client.get('/access/operators')
        .then(response => response.data.operators)
    }
  }
}
