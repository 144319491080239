<template>
  <div>
    <ul
      role="list"
      class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3"
    >
      <li
        v-for="merchandisingPlan in merchandisingPlans"
        :key="merchandisingPlan.id"
        class="col-span-1 flex flex-col text-center bg-white rounded-lg
        shadow divide-y divide-gray-200"
      >
        <BaseImage
          v-if="merchandisingPlan.files.length"
          :path="merchandisingPlan.files[0].path"
          :text="merchandisingPlan.title"
          class="w-full h-32"
        />

        <div class="flex-1 flex flex-col p-6">
          <h3 class="text-gray-900 text-sm font-bold">
            {{ merchandisingPlan.title }}
          </h3>
        </div>

        <div>
          <div class="-mt-px flex divide-x divide-gray-200">
            <div class="w-0 flex-1 flex">
              <button
                v-if="merchandisingPlan.files.length"
                class="-mr-px w-0 flex-1 inline-flex items-center justify-center
                py-4 text-sm text-gray-700 font-medium border border-transparent
                rounded-bl-lg hover:text-gray-500 focus:outline-none"
                @click="show(merchandisingPlan)"
              >
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0
                    00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                  />
                </svg>

                <span class="ml-3">
                  Voir les photos
                </span>
              </button>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <BaseModal
      v-show="isModalVisible === 'show'"
      :title="selectedMerchandisingPlan.title"
    >
      <BaseCarousel :images="selectedMerchandisingPlan.files" />

      <template v-slot:footer>
        <BaseButton
          @click="close()"
          @close="isModalVisible = false"
        >
          Fermer
        </BaseButton>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import {
  laboratoryMerchandisingPlanResource
} from '@/renderer/container'

import BaseImage from '@/renderer/components/base/BaseImage.vue'
import BaseModal from '@/renderer/components/base/BaseModal.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import BaseCarousel from '@/renderer/components/base/BaseCarousel.vue'

export default {
  components: {
    BaseImage,
    BaseModal,
    BaseButton,
    BaseCarousel
  },

  data () {
    return {
      isModalVisible: false,
      merchandisingPlans: [],

      selectedMerchandisingPlan: {
        files: [],
        title: null
      }
    }
  },

  mounted () {
    laboratoryMerchandisingPlanResource()
      .get(this.$route.params.laboratoryId)
      .then(merchandisingPlans => (this.merchandisingPlans = merchandisingPlans))
  },

  methods: {
    show (merchandisingPlan) {
      this.selectedMerchandisingPlan = merchandisingPlan
      this.isModalVisible = true
    },

    close () {
      this.isModalVisible = false
      this.selectedMerchandisingPlan = {
        files: []
      }
    }
  }
}
</script>
