<template>
  <div class="container max-h-screen overflow-y-auto mx-auto px-4 sm:px-6 lg:px-8 flex flex-col">
    <img
      class="w-56 absolute top-2 left-4"
      :src="require('@/assets/images/logos/apodis-text-white.png')"
    >
    <div class="text-3xl text-white text-center">
      Votre inscription est terminée {{ user.firstname }} ! 🥳
    </div>
    <div class="md:grid md:grid-cols-2">
      <div class="invisible md:visible h-0 w-0 md:h-full md:w-[384px]">
        <lottie-animation
          path="animations/91620-jumping-lottie-animation.json"
          :width="384"
          :height="384"
        />
      </div>

      <div v-if="healthcenter">
        <div class="my-8 text-xl text-white text-left">
          Votre compte Apodis Pro pour la <b>{{ healthcenter.name }}</b> a été créé avec succès.
        </div>
        <div class="my-8 text-lg text-white text-left">
          Dès à présent, laissez vous guider vers les prochaines étapes d'installation afin de profiter pleinement de
          tous vos produits et services !
        </div>
      </div>

      <div v-else>
        <div class="my-8 text-lg text-white text-left">
          Nous sommes heureux de vous accueillir !
          <br>Téléchargez l’application Apodis Pro sur votre mobile ou tablette afin de profiter des services Apodis !
        </div>
        <div class="flex-1">
          <div class="flex flex-row flex-wrap justify-around p-5 gap-x-2">
            <img
              class="w-30"
              src="@/assets/images/download-app-store.png"
              alt="App store"
            >
            <img
              class="w-30"
              src="@/assets/images/download-play-store.png"
              alt="Play store"
            >
          </div>
        </div>
      </div>
    </div>
    <div v-if="healthcenter">
      <button
        type="button"
        class="self-center group relative flex justify-center mx-auto py-3 px-7 text-xl
          rounded-full bg-secondary text-primary-dark"
        @click="login"
      >
        C'est par ici !
      </button>
    </div>
    <div v-else>
      <button
        type="button"
        class="self-center group relative flex justify-center mx-auto py-3 px-7 text-xl
          rounded-full bg-secondary text-primary-dark"
        @click="$router.push({ name: 'home' })"
      >
        Revenir à l'accueil
      </button>
    </div>
  </div>
</template>

<script>

import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'

export default {
  name: 'RegisterSuccess',

  components: {
    LottieAnimation
  },

  props: {
    user: {
      type: Object,
      required: true
    },
    healthcenter: {
      type: Object,
      default: null,
      required: false
    }
  },

  methods: {
    login () {
      this.$emit('login')
    }
  }
}
</script>
