import Vue from 'vue'

const InterviewInstanceService = () => {
  return {
    /**
     * Start an interview instance by opening the interview instance modal.
     *
     * @param {Number} interviewId The interview id.
     * @param {String} consumerName The consumer name for whom the instance is.
     * @param {String} consumerKey The consumer key for whom the instance is.
     * @param {String} interviewName The interview name.
     * @param {Function} onFinish The function to call when the interview is finished.
     *
     * @returns {void}
     */
    start: (interviewId, consumerName, consumerKey, interviewName, onFinish) => {
      Vue.prototype.$interviewInstanceModal.start({
        consumerName,
        consumerKey,
        interviewId,
        interviewName,
        onFinish
      })
    }
  }
}

export default InterviewInstanceService()
