<template>
  <interview-container>
    <h2 class="text-lg font-bold">
      Déroulé de l'entretien
    </h2>
    <p>
      Aidez-vous des ressources disponibles dans le panel de droite 👉 et suivez les consignes ci-dessous 👇
    </p>
    <div class="flex flex-col gap-5 mt-3">
      <div
        v-for="guideline in guidelines"
        :key="guideline.id"
        class="flex items-start"
      >
        <input
          type="checkbox"
          class="w-6 h-6 mr-3 text-blue-600 bg-gray-100 border-gray-300 rounded mt-1 focus:ring-0 focus:ring-primary"
          :name="guideline.id"
        >
        <label :for="guideline.id">{{ guideline.name }}</label>
      </div>
    </div>
  </interview-container>
</template>

<script>
import InterviewContainer from '@/renderer/app/interview/components/instance-modal/InterviewContainer.vue'

export default {
  name: 'InterviewGuidelines',
  components: { InterviewContainer },
  props: {
    guidelines: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
</style>
