import Vuex from 'vuex'
import systemStore from '@/renderer/stores/system-store'
import patientStore from '@/renderer/stores/patient-store'
import experienceStore from '@/renderer/stores/experience-store'
import Vue from 'vue'
import websiteStore from '@/renderer/stores/website-store'
import liveStore from '@/renderer/stores/live-store'
import interviewStore from '@/renderer/stores/interview-store'
import extensionsStore from '@/renderer/stores/extensions-store'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    system: systemStore,
    patient: patientStore,
    experience: experienceStore,
    website: websiteStore,
    live: liveStore,
    interview: interviewStore,
    extensions: extensionsStore
  }
})

export default store
