export default function (apiClient) {
  return {
    /**
     * Search for a product.
     *
     * @param {string} searchTerm - The search term.
     * @param {number} [size=20] - The number of results to return.
     *
     * @returns {Promise<Object>}
     */
    search (searchTerm, size = 20) {
      const data = {
        q: searchTerm,
        size,
        request_types: ['idProducts']
      }

      return apiClient.post('searchbar/autocompletefilters', data)
        .then(response => response.data.product)
    }
  }
}
