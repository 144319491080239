<template>
  <div class="bg-white flex items-center p-3">
    <button
      class="flex items-center"
      @click="$router.go(-1)"
    >
      <icon
        name="arrow-left"
        class="text-primary"
        :size="30"
      />
    </button>
    <p
      v-if="title"
      class="text-lg text-primary"
    >
      {{ title }}
    </p>
  </div>
</template>

<script>
import Icon from '@/renderer/app/core/icon/Icon.vue'

export default {
  name: 'HeaderBackButton',
  components: { Icon },
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>
