<template>
  <div>
    <div v-if="isLoading">
      <BaseSpinner />
    </div>
    <div v-else>
      <div class="flex flex-row align-center mb-4">
        <img
          :src="require('@/assets/images/icons/dialog.svg')"
          alt="Dialog"
          class="mr-4"
          width="40"
          height="40"
        >
        <p class="text-primary mt-1">
          Qui prend en charge la demande ?
        </p>
      </div>
      <div class="flex flex-wrap">
        <div
          v-for="(operator, index) in operators"
          :key="index"
          :class="selected(index)
            ? 'p-2 m-4 bg-primary text-white rounded-lg cursor-pointer'
            : 'p-2 m-4 bg-primary-lightest rounded-lg cursor-pointer border-transparent border-2 hover:border-primary'"
          @click="selectCode(operator)"
        >
          <p>{{ itemLabel(operator) }}</p>
        </div>
      </div>
      <div class="flex mt-10">
        <p
          class="text-primary p-2 shadow rounded-lg cursor-pointer"
          @click="goBack"
        >
          Retour
        </p>
      </div>
    </div>
    <div v-if="loading">
      <BaseSpinner />
    </div>
  </div>
</template>

<script>
import { teamApi } from '@/renderer/container'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'

export default {
  name: 'OrderOperator',
  components: { BaseSpinner },
  props: {
    order: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      teamAccess: null,
      operators: null,
      isLoading: true
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    /**
     * Load team and operators data.
     *
     * @return {Promise}
     */
    load () {
      return teamApi().index()
        .then(response => {
          this.teamAccess = response.team

          return teamApi().operatorCodes()
        })
        .then(response => {
          this.operators = response
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    goBack () {
      this.$emit('back')
    },
    /**
     * Get item label.
     *
     * @param {string} code - The operator code.
     * @return {string}
     */
    itemLabel (code) {
      const access = this.teamAccess?.find(access => access.operatorCode === code)

      if (!access) {
        return code
      }

      return `${access.user.name} (${code})`
    },
    /**
     * Select operator code that will handle the order.
     *
     * @param code
     */
    selectCode (code) {
      if (!this.loading) {
        this.$emit('updateOperatorCode', code)
      }
    },
    /**
     * If the operator is selected.
     *
     * @param index
     *
     * @return {boolean}
     */
    selected (index) {
      const selectedIndex = this.operators.findIndex(code => code === this.order?.operator_code)

      return Array.isArray(selectedIndex) ? selectedIndex.includes(index) : selectedIndex === index
    }
  }
}
</script>
