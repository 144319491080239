<template>
  <installation-step-layout @openGuide="$emit('openGuide')">
    <template v-slot:title>
      Installer Apodis Connect
    </template>
    <template v-slot:subtitle>
      Communiquez avec vos patients
    </template>
    <template v-slot:image>
      <lottie-animation
        :width="200"
        :height="200"
        style="margin: 0"
        path="animations/bell.json"
      />
    </template>
    <template v-slot:content>
      <p class="text-primary">
        Aidez votre équipe à mieux suivre vos patients <br>À installer <strong>sur tous vos
          postes comptoir</strong>
      </p>
    </template>
    <template v-slot:download>
      <DownloadButton
        title="Apodis Connect"
        :icon="require('@/assets/images/icons/apodis-connect.svg')"
        @click="$emit('openGuide')"
      />
    </template>
  </installation-step-layout>
</template>

<script>
import { defineComponent } from 'vue'
import DownloadButton from '@/renderer/components/views/onboarding/DownloadButton.vue'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import InstallationStepLayout
from '@/renderer/components/views/onboarding/installation-process/InstallationStepLayout.vue'

export default defineComponent({
  name: 'ApodisConnectStep',
  components: { InstallationStepLayout, DownloadButton, LottieAnimation }
})
</script>

<style scoped>

</style>
