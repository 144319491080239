<template>
  <base-container class="p-3 gap-3">
    <h3 class="font-content-bold text-xl">
      Retours de disponibilité
    </h3>
    <base-flex-spinner v-if="loading" />
    <div
      v-else
      class="flex flex-col gap-4"
    >
      <product-available-again-card
        v-for="date in Object.keys(availabilitiesByDay)"
        :key="date"
        :date="date"
        :availabilities="availabilitiesByDay[date]"
      />
    </div>
  </base-container>
</template>

<script>
import BaseContainer from '@/renderer/components/base/BaseContainer.vue'
import { disruptionLogisticsApi } from '@/renderer/container'
import ProductAvailableAgainCard
from '@/renderer/app/disruption-logistics/components/product-available-again/ProductAvailableAgainCard.vue'
import BaseFlexSpinner from '@/renderer/components/base/spinner/BaseFlexSpinner.vue'

export default {
  name: 'ProductAvailableAgainPage',
  components: { BaseFlexSpinner, ProductAvailableAgainCard, BaseContainer },
  data () {
    return {
      availabilitiesByDay: null,
      loading: true
    }
  },
  mounted () {
    this.loading = true

    disruptionLogisticsApi()
      .productsAvailableAgain()
      .then((data) => {
        this.availabilitiesByDay = this.groupByDayAndSource(data)
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    /**
     * Group the availabilities by day and source.
     *
     * @param availabilities
     * @return {Record<string, Object>}
     */
    groupByDayAndSource (availabilities) {
      const groups = availabilities.reduce((acc, availability) => {
        availability.history.forEach((history) => {
          const date = new Date(history.updated_at).toDateString()

          if (!acc[date]) {
            acc[date] = []
          }

          acc[date].push(availability)
        })

        return acc
      }, {})

      Object.keys(groups).forEach((date) => {
        groups[date] = groups[date].reduce((acc, availability) => {
          const source = availability.source?.name || ''

          if (!acc[source]) {
            acc[source] = []
          }

          acc[source].push(availability)

          return acc
        }, {})
      })

      return groups
    }
  }
}
</script>
