<template>
  <base-modal
    class="z-20"
    @close="$emit('close')"
  >
    <h2 class="text-primary font-bold text-xl">
      Certication à distance
    </h2>
    <p>
      Séléctionner le patient correspondant à <span class="font-bold">{{ customerName }}</span>
      ({{ customer.phone | phoneFormat }})
    </p>
    <search-bar
      v-model="keyword"
      class="mt-6"
      :is-loading="isLoading"
      @input="search"
    />
    <div v-if="results.length">
      <div
        v-for="(result, index) in results"
        :key="index"
        class="rounded-lg"
      >
        <span
          v-if="isSamePhoneNumber(customer.phone, result.information.phone)"
          class="text-primary p-2"
        >
          Ce patient a le même numéro que {{ customerName }}
        </span>
        <div
          class="grid grid-cols-3 mx-auto text-sm p-2 border-b-1 rounded-lg align-center hover:bg-purple-200 cursor-pointer"
          :class="[isSamePhoneNumber(customer.phone, result.information.phone) ? 'border-2 border-primary' : '']"
        >
          <div class="flex flex-col p-4">
            <span class="font-bold">{{ result.information.name }}</span>
            <span>Né le {{ result.information.birth_date | date('short') }}</span>
          </div>
          <div class="flex justify-center">
            <span>{{ result.information.phone | phoneFormat }}</span>
          </div>
          <div class="flex justify-end">
            <base-button
              :primary="result.status === 'none' || result.status === 'pending'"
              @click="result.status === 'validated' || result.status === 'pre-certified' ? cancel(result) : certifyPhone(result)"
            >
              {{ result.status === 'validated' || result.status === 'pre-certified' ? 'Annuler' : 'Certifier' }}
            </base-button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="p-4"
    >
      <span v-if="isLoading">Recherche en cours ...</span>
      <span v-else>Aucun patient ne correspond à votre recherche</span>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from '@/renderer/components/base/BaseModal.vue'
import SearchBar from '@/renderer/app/core/components/search-bar/SearchBar.vue'
import { customerCertificationResource, customerResource } from '@/renderer/container'
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import formatHelper from '@/renderer/helpers/formatHelper'
import ToastService from '@/renderer/services/ToastService'

export default {
  name: 'CustomerRemoteCertification',
  components: { BaseButton, BaseModal, SearchBar },
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      keyword: `${this.customer.first_name} ${this.customer.last_name}`,
      isLoading: true,
      certificationLoading: false,
      canceling: false,
      results: [],
      timer: null
    }
  },
  computed: {
    /**
     * Get customer full name.
     *
     * @return {string}
     */
    customerName () {
      return `${this.customer.first_name} ${this.customer.last_name}`
    }
  },
  mounted () {
    this.search()
  },
  methods: {
    /**
     * Compare if two phone number are identical.
     *
     * @param phone1
     * @param phone2
     */
    isSamePhoneNumber (phone1, phone2) {
      return formatHelper.readablePhone(phone1) === formatHelper.readablePhone(phone2)
    },
    /**
     * Search a customer based on provided keyword.
     */
    search () {
      if (this.timer) {
        clearTimeout(this.timer)
      }

      if (this.keyword.length > 3) {
        this.timer = setTimeout(() => {
          this.isLoading = true

          customerResource().search(this.keyword)
            .then(customers => {
              this.results = customers
              this.isLoading = false
            })
        }, 500)
      }
    },
    /**
     * Certify a phone number for the current customer.
     *
     * @param {Object} customer The customer.
     */
    certifyPhone (customer) {
      if (!customer.information.phone || !formatHelper.phoneIsValid(customer.information.phone)) {
        ToastService().error('Le numéro de ce patient n\'est pas valide')
        return
      }

      this.certificationLoading = true

      return customerCertificationResource()
        .certifyPhone(customer.id, formatHelper.apiPhone(customer.information.phone))
        .then(() => {
          this.search()
        })
        .catch(() => {
          ToastService()
            .error('La certification n\'a pas fonctionné, vérifier que le numéro n\'est pas déjà associé à un patient')
        })
        .finally(() => {
          this.certificationLoading = false
        })
    },
    /**
     * Cancel a certification.
     *
     * @param customer
     */
    cancel (customer) {
      this.canceling = true

      return customerCertificationResource()
        .cancel(customer.id)
        .then(() => {
          this.search()
        })
        .finally(() => {
          this.canceling = false
        })
    }
  }
}

</script>
