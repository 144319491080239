<template>
  <slide-to-top>
    <div
      v-if="visible"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
      class="fixed z-10 inset-0 overflow-y-auto bg-gray-200"
      :class="{
        'border-4 border-red-500': recording
      }"
    >
      <interview-instance-modal-header
        :interview-name="interviewName"
        :consumer-name="consumerName"
        @close="close"
      />
      <base-container>
        <base-spinner
          v-if="loading"
          class="mx-auto mt-8"
        />
        <div v-else>
          <div v-if="interview">
            <div
              class="flex flex-col w-full gap-6"
              style="padding-bottom: 130px"
            >
              <interview-description
                :duration="interview.duration"
                class="m-5"
                :description="interview.description"
              />
              <interview-recording
                v-if="currentScreen === screens.GUIDELINES"
                @skipRecording="currentScreen = screens.SURVEY"
                @finish="recordStopped"
              />
              <div class="flex gap-x-6 pl-5">
                <interview-guidelines
                  v-if="currentScreen === screens.GUIDELINES"
                  class="flex-1 self-start"
                  :guidelines="interview.guidelines"
                />
                <interview-survey
                  v-if="currentScreen === screens.SURVEY"
                  :survey-id="interview.survey.id"
                  @submit="submit"
                />
                <interview-finished
                  v-if="currentScreen === screens.FINISHED"
                  class="flex-1"
                  :interview="interview"
                  :audio="audio"
                  :interview-instance="interviewInstance"
                  @close="close"
                />
                <interview-short-cuts />
              </div>
            </div>
            <interview-consumer-note-input
              class="fixed bottom-0 w-full"
              style="height: 100px"
            />
          </div>
          <div v-else>
            <p>Oups... une erreur c'est produite</p>
          </div>
        </div>
      </base-container>
    </div>
  </slide-to-top>
</template>

<script>
import InterviewInstanceModalPlugin from '@/renderer/plugins/interview-instance-modal-plugin'
import SlideToTop from '@/renderer/app/core/components/transitions/SlideToTop.vue'
import InterviewInstanceModalHeader
from '@/renderer/app/interview/components/instance-modal/InterviewInstanceModalHeader.vue'
import BaseContainer from '@/renderer/components/base/BaseContainer.vue'
import { interviewApi } from '@/renderer/container'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import InterviewDescription from '@/renderer/app/interview/components/instance-modal/InterviewDescription.vue'
import InterviewGuidelines from '@/renderer/app/interview/components/instance-modal/InterviewGuidelines.vue'
import InterviewShortCuts from '@/renderer/app/interview/components/instance-modal/InterviewShortCuts.vue'
import InterviewSurvey from '@/renderer/app/interview/components/instance-modal/InterviewSurvey.vue'
import Icon from '@/renderer/app/core/icon/Icon.vue'
import SlideRightToLeft from '@/renderer/app/core/components/transitions/SlideRightToLeft.vue'
import InterviewFinished from '@/renderer/app/interview/components/instance-modal/InterviewFinished.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import InterviewRecording from '@/renderer/app/interview/components/instance-modal/InterviewRecording.vue'
import InterviewTranscript from '@/renderer/app/interview/components/instance-modal/InterviewTranscript.vue'
import { createNamespacedHelpers } from 'vuex'
import InterviewConsumerNoteInput
from '@/renderer/app/interview/components/instance-modal/InterviewConsumerNoteInput.vue'

const { mapState, mapMutations, mapActions } = createNamespacedHelpers('interview')

const screens = {
  GUIDELINES: 0,
  SURVEY: 1,
  FINISHED: 2
}

export default {
  name: 'InterviewInstanceModal',
  components: {
    InterviewConsumerNoteInput,
    InterviewTranscript,
    InterviewRecording,
    BaseButton,
    InterviewFinished,
    SlideRightToLeft,
    Icon,
    InterviewSurvey,
    InterviewShortCuts,
    InterviewGuidelines,
    InterviewDescription,
    BaseSpinner,
    BaseContainer,
    InterviewInstanceModalHeader,
    SlideToTop
  },
  data () {
    return {
      /** The modal visibility. */
      visible: false,
      /** The interview id. */
      interviewId: null,
      /** The interview name. */
      interviewName: null,
      /** The consumer name. */
      consumerName: null,
      /** The current step. */
      currentStep: 0,
      /** The optional audio blob. */
      audio: null,
      /** The stored interview instance. */
      interviewInstance: null,
      /** The current screen. */
      currentScreen: screens.GUIDELINES,
      /** On finish callback. */
      onFinish: null
    }
  },
  beforeCreate () {
    InterviewInstanceModalPlugin.EventBus.$off('start')
    InterviewInstanceModalPlugin.EventBus.$on('start', (params) => {
      this.start(params)
    })
  },
  methods: {
    /**
     * Start new interview instance.
     *
     * @param {{
     *   consumerName: string,
     *   consumerKey: string,
     *   interviewId: number,
     *   interviewName: string,
     *   onFinish: function
     * }} params The parameters.
     */
    start (params) {
      const { consumerName, consumerKey, interviewId, interviewName, onFinish } = params

      if (this.visible) {
        return
      }

      this.initialize({ interviewID: interviewId, consumerKey })

      this.currentScreen = screens.GUIDELINES
      this.currentStep = 0
      this.audio = null
      this.consumerName = consumerName
      this.interviewId = interviewId
      this.interviewName = interviewName
      this.interviewInstance = null
      this.onFinish = onFinish ?? null

      this.visible = true
    },
    /**
     * Close the modal.
     */
    close () {
      if (this.interviewInstance && this.onFinish) {
        this.onFinish(this.interviewInstance)
      }

      this.visible = false
    },
    /**
     * Store the audio blob.
     * Launch the speech to text process and navigate to the next screen.
     *
     * @param {Blob} audioBlob The audio blob.
     */
    recordStopped (audioBlob) {
      this.audio = audioBlob

      this.readTranscript({
        interviewId: this.interviewId,
        audio: audioBlob
      })

      this.currentScreen = screens.SURVEY
    },

    /**
     * Submit the interview.
     */
    submit (answerValues) {
      const formattedAnswers = Object.keys(answerValues)
        .map(key => {
          return {
            question_id: key,
            answer: answerValues[key]
          }
        })

      return interviewApi()
        .storeInstance(this.interviewId, this.consumerKey, {
          survey_answers: formattedAnswers
        })
        .then((instance) => {
          this.interviewInstance = instance
          this.currentScreen = screens.FINISHED
        })
    },
    ...mapActions([
      'initialize',
      'readTranscript'
    ]),
    ...mapMutations([
      'setConsumerKey'
    ])
  },
  computed: {
    ...mapState({
      loading: state => state.loading.interview || state.loading.attachments,
      interview: state => state.interview,
      recording: state => state.recording,
      consumerKey: state => state.consumerKey
    }),
    /**
     * Get the screens.
     *
     * @returns {{GUIDELINES: number, SURVEY: number, FINISHED: number}}
     */
    screens () {
      return screens
    }
  }
}
</script>
