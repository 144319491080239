import { disruptionLogisticsApi } from '@/renderer/container'
import store from '@/renderer/stores/store'

const DisruptionLogisticsRequestService = () => {
  /**
   * Call the create request endpoint and redirect to the data page
   *
   * @param {{name: string; id: number}}product
   * @param {Object} router
   * @param {boolean} [replaceRoute=false]
   *
   * @return {Promise<void>}
   */
  const requestDisruptionLogisticsData = (product, router, replaceRoute = false) => {
    return disruptionLogisticsApi()
      .createRequest(product.id)
      .then(response => {
        store.commit('extensions/disruptionLogisticsRequest/decreaseRemainingRequests')

        const params = {
          name: 'disruption-logistics.product-availability',
          params: {
            productId: product.id
          },
          query: {
            productName: product.name,
            productCode: product.code,
            requestToken: response.token
          }
        }

        replaceRoute ? router.replace(params) : router.push(params)
      })
  }

  return {
    requestDisruptionLogisticsData
  }
}

export default DisruptionLogisticsRequestService()
