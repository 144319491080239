export default function (client) {
  return {
    /**
     * Get all extensions.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    index (params = {}) {
      return client
        .get('extensions', { params })
        .then(response => response.data.data)
        .catch(() => [])
    },
    /**
     * Read an extension.
     * @param extensionId
     * @return {Promise}
     */
    read (extensionId) {
      return client
        .get(`extensions/${extensionId}`)
        .then(response => response.data.data)
    }
  }
}
