<template>
  <div class="h-screen overflow-hidden bg-primary-lightest flex flex-row">
    <div class="min-h-0 flex-1 flex overflow-hidden">
      <main class="min-w-0 flex-1 lg:flex">
        <section
          aria-labelledby="primary-heading"
          class="min-w-0 flex-1 h-full flex flex-col overflow-auto lg:order-last"
        >
          <router-view />
        </section>
      </main>
    </div>
    <TheSanteSecureMenu />
    <TheHubspotChat />
    <TheHubspotAppointment />
  </div>
</template>

<script>
import {
  environmentResource,
  authenticationService
} from '@/renderer/container'

import TheSanteSecureMenu from '@/renderer/components/partials/TheSanteSecureMenu.vue'
import TheHubspotChat from '@/renderer/components/partials/TheHubspotChat.vue'
import TheHubspotAppointment from '@/renderer/components/partials/TheHubspotAppointment.vue'

export default {
  components: {
    TheSanteSecureMenu,
    TheHubspotChat,
    TheHubspotAppointment
  },

  mounted () {
    const intervalId = setInterval(() => {
      this.$store.dispatch('website/fetchData')
    }, 1000 * 60)

    this.$once('hook:beforeDestroy', () => {
      clearInterval(intervalId)
    })

    if (!authenticationService().authenticated) {
      this.$router.push({
        name: 'login'
      })
    } else if ((authenticationService().access && (authenticationService().access.cps === '' || authenticationService().access.cps === null))) {
      this.$router.push({
        name: 'linkcps'
      })
    } else {
      this.$store.dispatch('website/fetchData')

      environmentResource()
        .read()
        .then(environment => {
          this.$store.commit('setEnvironment', environment)
        })
    }
  }
}
</script>
