<template>
  <v-data-table
    :headers="headers"
    :items="transformedConsumers"
    :items-per-page="Number(meta.per_page)"
    :loading="loading"
    :disable-sort="true"
    :server-items-length="meta.total"
    :loading-text="'Chargement en cours...'"
    no-data-text="Aucun patients n'a activé cette carte de fidélité"
    :footer-props="{
      'items-per-page-text': 'Éléments par page',
      'page-text': '{0}-{1} sur {2}',
      'total-items': 'Total des éléments'
    }"
    @update:page="onPageChange"
    @click:row="handleClick"
  />
</template>

<script>
import dateHelper from '@/renderer/helpers/dateHelper'
import { loyaltyCardResource } from '@/renderer/container'

export default {
  name: 'LoyaltyCardConsumersTable',
  props: {
    /** The loyalty card id. */
    resourceId: {
      type: Number,
      required: true
    }
  },
  data: function () {
    return {
      /** The loading state of the table. */
      loading: true,
      /** The loyalty card consumers */
      consumers: [],
      /** The pagination meta data. */
      meta: {
        total: undefined,
        per_page: undefined
      }
    }
  },
  computed: {
    /**
     * The table header configuration.
     *
     * @return {Array}
     */
    headers () {
      return [
        {
          text: 'Nom',
          value: 'name'
        },
        {
          text: 'Dernière visite',
          value: 'lastPurchase'
        },
        {
          text: 'Panier moyen',
          value: 'averageBasket'
        },
        {
          text: 'Achats',
          value: 'purchases'
        },
        {
          text: 'Points débités / crédités',
          value: 'points'
        },
        {
          text: 'Solde',
          value: 'balance'
        },
        {
          text: 'Récompenses',
          value: 'rewards'
        }
      ]
    },
    /**
     * Transform the customers for the table.
     *
     * @return {Array}
     */
    transformedConsumers () {
      if (!this.consumers) {
        return undefined
      }

      return this.consumers.map((consumer) => {
        return {
          customerId: consumer.customer.id,
          membershipId: consumer.customer.membership_id,
          name: consumer.customer.name,
          lastPurchase: consumer.statistics.last_purchase ? dateHelper.shortFormat(consumer.statistics.last_purchase) : '-',
          averageBasket: consumer.total.quantity
            ? String((consumer.total.revenue / consumer.total.quantity).toFixed(2)) + '€'
            : '-',
          balance: `${consumer.total.balance} point${consumer.total.balance > 1 ? 's' : ''}`,
          purchases: `${consumer.total.quantity} (${consumer.total.revenue.toFixed(2)}€)`,
          points: `${consumer.total.value}/${consumer.total.value + consumer.total.balance}`,
          rewards: `${consumer.statistics.reward.discounts.toFixed(2)}€`
        }
      })
    }
  },
  /**
   * On mount, load first customer page.
   */
  mounted () {
    this.load(1)
  },
  methods: {
    /**
     * Load the given page.
     *
     * @param {Number} page
     */
    onPageChange (page) {
      this.load(page)
    },
    /**
     * Load the given page.
     *
     * @param page
     */
    load (page) {
      this.loading = true
      loyaltyCardResource()
        .getMembership(this.resourceId, page, 20)
        .then((response) => {
          this.consumers = response.data
          this.meta = response.meta
        }).finally(() => {
          this.loading = false
        })
    },
    /**
     * Redirect to customer details.
     *
     * @param row
     */
    handleClick (row) {
      this.$router.push({
        name: 'customer.loyalty.membership',
        params: {
          customerId: row.customerId,
          membershipId: row.membershipId
        }
      })
    }
  }
}
</script>
