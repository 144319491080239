<template>
  <div class="rounded-xl grow bg-white flex flex-row cursor-pointer items-center gap-x-3 p-3">
    <div class="flex flex-col justify-center-center h-full">
      <program-logo
        :program="program"
        class="h-full"
      />
    </div>
    <div class="flex flex-col flex-1 justify-center-center">
      <div class="font-medium text-primary text-lg line-clamp-2 ">
        {{ program.name }}
      </div>
      <div class="flex flex-1 flex-row items-center text-sm">
        <span class="font-bold text-xl mr-1">{{ program.consumer_count }}</span> patients
      </div>
    </div>
    <chevron-right-icon :size="15" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import ProgramLogo from '@/renderer/app/program/components/program-logo/ProgramLogo.vue'
import ChevronRightIcon from '@/renderer/components/icons/ChevronRightIcon.vue'

/**
 * The program card component.
 */
export default defineComponent({
  name: 'ProgramCard',
  components: { ChevronRightIcon, ProgramLogo },
  props: {
    /**
     * The program to display.
     */
    program: {
      type: Object,
      required: true
    }
  }
})
</script>
