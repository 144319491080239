<template>
  <div class="w-full pointer-events-auto rounded-l-3xl bg-white">
    <div class="flex items-start">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close () {
      window.ipcRenderer.send('hide-notification')
    }
  }
}
</script>
