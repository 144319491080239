/**
 * Format a number.
 *
 * @param {Number} value - The value.
 * @param {Number} decimals - The number of decimal.
 * default to 2.
 *
 * @return {*|string}
 */
export const numberFormat = (value, decimals = 2) => {
  if (isNaN(value)) {
    return value
  }

  return value.toLocaleString('fr-FR', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  })
}
