<template>
  <experienceable-pathway-step-button
    v-if="action && actionIsReady"
    :step="step"
    primary
    class="mt-2"
    button-style="padding: 5px 15px;"
    @click="clicked"
  >
    <div
      :class="{ 'line-clamp-1': clamp}"
      class="text-xs font-semibold whitespace-normal over min-h-4 flex-wrap"
    >
      {{ isLocked ? "Répondre à la question" : action }}
    </div>
  </experienceable-pathway-step-button>
</template>

<script>
import ExperienceablePathwayStepButton
from '@/renderer/app/program/components/experienceable-program-button/ExperienceablePathwayStepButton.vue'
import ConsumerProgramService from '@/renderer/services/ConsumerProgramService'

export default {
  name: 'ProgramTeaserAction',
  components: { ExperienceablePathwayStepButton },
  props: {
    /**
     * The program to get the teaser action from.
     */
    program: {
      type: Object,
      required: true
    },
    /**
     * Fix the number of line of the teaser text to 1.
     */
    clamp: {
      type: Boolean
    }
  },
  computed: {
    /**
     * Get the program next step
     *
     * @return {Object|null}
     */
    step () {
      return this.program.next_step
    },
    /**
     * Get the next step action
     *
     * @return {string}
     */
    action () {
      return this.step ? this.step.resolved.action : null
    },
    /**
     * Determine if the step action is ready.
     *
     * @return {boolean}
     */
    actionIsReady () {
      return ConsumerProgramService().hasAction(this.program)
    },
    /**
     * Determine if the step is locked.
     *
     * @return {boolean}
     */
    isLocked () {
      return this.step.type === 'aftercare' &&
        this.step.steppable?.verdict?.verdict &&
        !this.step.consumer_step?.steppable?.id
    }
  },
  methods: {
    /**
     * Handle the click event.
     *
     * @param {Event} event
     */
    clicked (event) {
      if (!this.isLocked) {
        return this.$listeners.action(event)
      }
    }
  }
}
</script>
