<template>
  <div class="flex flex-col flex-1 p-2">
    <div class="flex flex-col pl-4 lg:pl-0 lg:flex-row justify-space-around pb-4">
      <div class="flex flex-row pb-2">
        <icon
          :name="'user'"
          :size="24"
          class="pr-2"
        />
        <span>{{ prescription.customer.information.name }}</span>
      </div>
      <div class="flex flex-row pb-2">
        <icon
          :name="'medkit'"
          :size="24"
          class="pr-2"
        />
        <span>Dr. {{ prescription.professional.last_name }}</span>
      </div>
      <div class="flex flex-row pb-2">
        <icon
          :name="'schedule'"
          :size="24"
          class="pr-2"
        />
        <span>{{ dateHelper.shortFormat(prescription.created_at) }}</span>
      </div>
    </div>
    <secure-image
      :urls="prescription.files"
    />
  </div>
</template>

<script>

import Icon from '@/renderer/app/core/icon/Icon.vue'
import SecureImage from '@/renderer/app/core/components/secure-image/SecureImage.vue'
import dateHelper from '../../../helpers/dateHelper'

export default {
  name: 'PrescriptionVisual',
  components: { Icon, SecureImage },
  props: {
    prescription: {
      type: Object,
      required: true
    }
  },
  computed: {
    dateHelper () {
      return dateHelper
    }
  }
}
</script>
