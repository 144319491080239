<template>
  <div class="flex justify-center">
    <p class="p-4 rounded-lg bg-primary-lightest">
      {{ renderDate(question?.user_answer) }}
    </p>
  </div>
</template>

<script>
import dateHelper from '../../../helpers/dateHelper'

export default {
  name: 'SurveyBuiltDate',
  props: {
    question: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array],
      default: null
    }
  },
  computed: {
    dateHelper () {
      return dateHelper
    }
  },
  methods: {
    /**
     * Display date with format DD/MM/YYYY.
     *
     * @return {string}
     */
    renderDate () {
      if (value?.length) {
        return dateHelper.shortFormat(value[0])
      }

      return ''
    }
  }
}
</script>

<style scoped>

</style>
