<template>
  <div class="flex flex-1">
    <base-spinner v-if="loading" />
    <div
      v-else
      class="flex flex-1"
    >
      <drug-selection
        v-if="selectProduct"
        :consumer-key="consumerKey"
        @launchAnalysis="getInteractionWebsite"
      />
      <drug-interaction
        v-else
        :link="webpage"
      />
    </div>
  </div>
</template>

<script>
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import { drugInteractionApi } from '@/renderer/container'
import DrugInteraction from '@/renderer/app/drug-interaction/components/DrugInteraction.vue'
import DrugSelection from '@/renderer/app/drug-interaction/components/DrugSelection.vue'

export default {
  name: 'DrugInteractionScreen',
  components: { DrugSelection, DrugInteraction, BaseSpinner },
  props: {
    consumerKey: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      selectProduct: true,
      loading: false,
      webpage: null,
      selected: []
    }
  },
  methods: {
    getInteractionWebsite (cips) {
      this.selectProduct = false
      this.loading = true
      drugInteractionApi()
        .read(cips)
        .then(response => {
          this.webpage = response.prisca_path
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}

</script>
