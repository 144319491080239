<template>
  <div
    ref="notification"
    class="flex flex-1"
  >
    <component
      :is="component"
      v-if="component"
      :item="item"
    />
  </div>
</template>

<script>
import {
  detectionResource,
  environmentResource,
  notificationService
} from '@/renderer/container'

import NotificationDetection
from '@/renderer/components/views/notification/notification-detection/NotificationDetection.vue'
import NotificationBroadcast, {
  BROADCAST_NOTIFICATION_HEIGHT
} from '@/renderer/components/views/notification/notification-broadcast/NotificationBroadcast.vue'

export default {
  data () {
    return {
      item: null,
      component: null
    }
  },

  mounted () {
    window.ipcRenderer.on('experience-events-dispatched', (event, experienceEvents) => {
      this.$store.commit('experience/storeEvents', experienceEvents)
    })

    window.ipcRenderer.on('extract-patient', (event, patient, settings) => {
      this.$analytics.logEvent('read_vitale', patient)

      window.ipcRenderer.send('detection-start')

      detectionResource()
        .create(window.hostname, patient, true)
        .then(detection => {
          const item = {
            patient,
            customer: null,
            loyalty_cards: [],
            settings,
            certified: null
          }

          if (detection.customer) {
            return detectionResource()
              .read(detection.customer.id)
              .then((data) => {
                const consumers = data.consumers
                const customer = consumers.find((consumer) => consumer.type === 'customer')
                customer.beneficiaries = consumers.filter((consumer) => consumer.type === 'beneficiary')

                item.customer = customer
                item.loyalty_cards = data.loyalty_cards
                item.certified = data.certified
              })
              .finally(() => {
                this.item = item
                this.component = NotificationDetection
              })
          }

          this.item = item
          this.component = NotificationDetection

          return Promise.resolve(true)
        })
        .finally(() => {
          window.ipcRenderer.send('detection-completed')
          this.showNotificationAfterTick()
        })
    })

    window.ipcRenderer.on('reset-patient', (event, patient) => {
      detectionResource().create(window.hostname, patient, false)
    })

    window.ipcRenderer.on('preview-detection', this.preview)
    this.subscribe()
  },

  methods: {
    /**
     * Subscribe for incoming broadcast notification.
     *
     * @return {Promise<void>}
     */
    subscribe () {
      const channel = 'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated'
      return environmentResource()
        .read()
        .then(environment => {
          window.ipcRenderer.send('socket:subscribe', `private-user.${environment.userId}`)
          window.ipcRenderer.send('socket:listen', channel)
          window.ipcRenderer.on('socket:event-received.' + channel, async (event, data) => {
            this.component = NotificationBroadcast
            this.item = data
            this.showNotificationAfterTick(BROADCAST_NOTIFICATION_HEIGHT)
          })
        })
    },

    preview (event, settings) {
      const patient = {
        nir: '170000000000000',
        lastName: 'au comptoir',
        firstName: 'Patient',
        birthDate: '01011980'
      }

      const exampleProgram = {
        id: 1,
        name: 'Programme exemple',
        teaser: 'Recommandations du programme'
      }

      const detection = {
        customer: {
          key: 'c_1',
          certified: false,
          programs: [
            exampleProgram,
            {
              ...exampleProgram,
              id: 2
            },
            {
              ...exampleProgram,
              id: 3
            }
          ],
          beneficiaries: []
        }
      }

      this.component = NotificationDetection

      this.item = {
        patient,
        customer: detection.customer,
        settings
      }

      this.showNotificationAfterTick()
    },

    async showNotificationAfterTick (height = null) {
      await this.$nextTick() // needed for window size calculation (this.$refs.notification.clientHeight)

      notificationService()
        .showNotification(height || (this.$refs.notification && this.$refs.notification.clientHeight))
    }
  }
}
</script>
