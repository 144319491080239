<template>
  <div class="flex flex-row flex-wrap p-3">
    <div
      v-for="(filter, index) in filters"
      :key="index"
      class="hover:bg-secondary hover:text-primary"
      :class="isSelected(filter) ? 'm-2 px-4 py-2 cursor-pointer rounded-xl shadow bg-secondary' : 'm-2 px-4 py-2 cursor-pointer rounded-xl bg-white shadow'"
      @click="updateSelectedFilters(filter)"
    >
      <p :class="isSelected(filter) ? 'font-content center text-primary' : 'font-content'">
        {{ filter.name }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Filters',
  props: {
    filters: {
      type: Array,
      required: true
    },
    selectedFilters: {
      type: Array,
      required: true
    }
  },
  methods: {
    /**
     * If the filter is selected.
     *
     * @param {Object} filter Filter object.
     *
     * @returns {boolean}
     */
    isSelected (filter) {
      return this.selectedFilters.includes(filter.ids[0])
    },
    /**
     * Emit event, to tell parent comment that a filter has been updated.
     *
     * @param {Object} filter Filter object.
     */
    updateSelectedFilters (filter) {
      this.$emit('onFilterChange', filter)
    }
  }
}
</script>
