<template>
  <div class="mx-6">
    <BaseSpinner v-if="isLoading" />
    <div v-else>
      <BaseCard>
        <BaseSection>
          <div class="p-5 divide-y">
            <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div class="sm:col-span-2">
                <dt class="text-xl font-bold text-gray-900">
                  Description
                </dt>

                <dd class="mt-1 text-sm text-gray-900">
                  {{ instantDiscountVoucher.description }}
                </dd>
              </div>
            </dl>
          </div>

          <hr>

          <div class="p-5">
            <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div class="sm:col-span-2">
                <dt class="text-xl font-bold text-gray-900">
                  Règles
                </dt>
              </div>
            </dl>
            <ul class="list-disc list-inside flex flex-col items-center">
              <div class="flex flex-col inline-block text-left w-120">
                <span
                  v-for="reward in instantDiscountVoucher.rewards"
                  :key="reward.id"
                  class="text-sm"
                >
                  {{ reward.teaser }}
                </span>
              </div>
            </ul>

            <div class="mt-4">
              <h3 class="text-sm mb-1 font-bold">
                Description du fonctionnement
              </h3>

              <p class="text-sm">
                Enregistrez la vente comme d’habitude dans votre LGO avec la réduction correspondante,
                Apodis récupèrera la vente et la transmettra au laboratoire afin qu’il puisse vous
                rembourser les montants concernés.
              </p>
            </div>
          </div>

          <hr>

          <div class="p-5">
            <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div class="sm:col-span-2">
                <dt class="text-xl font-bold text-gray-900">
                  Produits ciblés par l’offre
                </dt>
              </div>
            </dl>

            <ul
              role="list"
              class="divide-y divide-gray-200"
            >
              <li
                v-for="product in instantDiscountVoucher.productsTrigger"
                :key="product.id"
              >
                <div class="flex items-center px-4 py-4 sm:px-6">
                  <div class="min-w-0 flex-1 flex items-center">
                    <div class="flex-shrink-0">
                      <BaseImage
                        v-if="!!product.fullPhotoPath"
                        rendered
                        class="h-20 w-20 rounded-lg"
                        :text="product.name"
                        :path="product.fullPhotoPath"
                      />
                      <BaseImage
                        v-else
                        rendered
                        class="h-20 w-20 rounded-lg"
                        :text="product.name"
                        :path="require('@/assets/images/no-product-image.png')"
                      />
                    </div>

                    <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-1 md:gap-4">
                      <div>
                        <p class="text-sm font-medium">
                          {{ product.name }}
                        </p>

                        <p class="mt-1 flex items-center text-sm text-gray-500">
                          {{ product.code }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </BaseSection>
      </BaseCard>
    </div>
  </div>
</template>

<script>
import {
  instantDiscountVoucherResource
} from '@/renderer/container'

import BaseImage from '@/renderer/components/base/BaseImage.vue'
import BaseSection from '@/renderer/components/base/BaseSection.vue'
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'

export default {
  components: {
    BaseSpinner,
    BaseCard,
    BaseImage,
    BaseSection
  },

  props: {
    discountId: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      instantDiscountVoucher: null,
      isLoading: true
    }
  },

  mounted () {
    const id = this.discountId || this.$route.params.discountId
    instantDiscountVoucherResource()
      .read(id)
      .then(instantDiscountVoucher => {
        this.instantDiscountVoucher = instantDiscountVoucher
      }).finally(() => {
        this.isLoading = false
      })
  }
}
</script>
