/**
 * The laboratory franco resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Read a laboratory franco.
     * @param {number} laboratoryId - The ID of the laboratory.
     * @param {number} healthcenterId - The ID of the healthcenter.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    read (laboratoryId, healthcenterId) {
      return client
        .post(`/laboratories/${laboratoryId}/francos/healthcenters/${healthcenterId}`)
        .then(response => response.data.data)
    }
  }
}
