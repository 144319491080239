var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex w-full items-center justify-center"},[_c('form',{staticClass:"container max-h-screen overflow-y-auto mx-auto px-4 sm:px-6 lg:px-8"},[(_vm.registeredUser === null)?_c('div',[_c('div',{staticClass:"md:grid md:grid-cols-2"},[_c('div',{staticClass:"invisible md:visible h-0 w-0 md:h-full md:w-[384px]"},[_c('lottie-animation',{attrs:{"path":"animations/73812-cloud-computing-security.json","width":384,"height":384}})],1),(_vm.loading)?_c('div',{staticClass:"text-white"},[_vm._v(" Chargement de l'utilisateur ")]):(_vm.guestId === null)?_c('div',{staticClass:"text-white"},[_vm._v(" Invitation expirée ")]):_c('div',{staticClass:"max-w-md"},[_c('div',{staticClass:"grid grid-cols-2 gap-2"},[_c('RegisterInput',{attrs:{"error":_vm.errors.firstname,"type":"text","placeholder":"Prénom"},model:{value:(_vm.firstname),callback:function ($$v) {_vm.firstname=$$v},expression:"firstname"}}),_c('RegisterInput',{attrs:{"error":_vm.errors.lastname,"type":"text","placeholder":"Nom"},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}})],1),_c('div',{staticClass:"flex flex-row flex-wrap mt-4 mb-2 gap-4 text-sm text-white"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.gender),expression:"gender"}],staticClass:"hidden",attrs:{"id":"man","value":"0","type":"radio"},domProps:{"checked":_vm._q(_vm.gender,"0")},on:{"change":function($event){_vm.gender="0"}}}),_c('label',{class:['flex flex-row items-center', (_vm.gender == 0)
                ? 'opacity-100'
                : 'opacity-60'
              ],attrs:{"for":"man"}},[_c('img',{class:['h-16 mr-2', _vm.gender == 0
                  ? 'rounded-full border-white border-2'
                  : 'border-0'
                ],attrs:{"src":require('@/assets/images/icons/profile-male.png')}}),_vm._v(" Homme ")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.gender),expression:"gender"}],staticClass:"hidden",attrs:{"id":"woman","value":"1","type":"radio"},domProps:{"checked":_vm._q(_vm.gender,"1")},on:{"change":function($event){_vm.gender="1"}}}),_c('label',{class:['flex flex-row items-center', (_vm.gender == 1)
                ? 'opacity-100'
                : 'opacity-60'
              ],attrs:{"for":"woman"}},[_c('img',{class:['h-16 mr-2', _vm.gender == 1
                  ? 'rounded-full border-white border-2'
                  : 'border-0'
                ],attrs:{"src":require('@/assets/images/icons/profile-female.png')}}),_vm._v(" Femme ")])]),(_vm.errors.gender)?_c('div',{staticClass:"mt-2 text-sm text-orange-light"},[_vm._v(" "+_vm._s(_vm.errors.gender[0])+" ")]):_vm._e(),_c('div',{class:['mt-1 relative border-b-2', _vm.errors.rank
              ? 'mb-0 border-orange-light'
              : 'mb-8'
            ]},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.rankId),expression:"rankId"}],staticClass:"appearance-none w-full px-3 py-2 h-12 text-white border-0 focus:border-0 focus:ring-0 sm:text-sm bg-transparent",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.rankId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":"","hidden":""},domProps:{"value":null}},[_vm._v(" Sélectionnez votre poste ")]),_vm._l((_vm.ranks),function(name,value){return _c('option',{key:value,domProps:{"value":value}},[_vm._v(" "+_vm._s(name)+" ")])})],2)]),_c('RegisterInput',{attrs:{"error":_vm.errors.email,"type":"email","placeholder":"Email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('RegisterInput',{attrs:{"type":"password","placeholder":"Mot de passe"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('RegisterInput',{attrs:{"error":_vm.errors.passwordConfirmation,"type":"password","placeholder":"Confirmez votre mot de passe"},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}}),_c('div',{class:['mt-2 text-xs', _vm.errors.password
              ? 'text-orange-light'
              : 'text-gray-300'
            ]},[_vm._v(" "+_vm._s(_vm.errors.password || _vm.passwordInfo)+" ")])],1)]),(_vm.errors.page)?_c('BaseAlert',{attrs:{"type":"error","title":"Erreur"}},[_vm._v(" "+_vm._s(_vm.errors.page)+" ")]):_vm._e(),_c('button',{staticClass:"flex justify-center mx-auto py-3 px-7 mt-8 text-xl text-primary-dark rounded-full bg-secondary",attrs:{"type":"button"},on:{"click":_vm.register}},[_vm._v(" Créer mon compte ")])],1):_c('div',[_c('RegisterSuccess',{attrs:{"user":_vm.registeredUser}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }