<template>
  <div>
    <p class="font-bold text-primary text-xl pb-4">
      Identifiez-vous
    </p>
    <div class="border-lightPurple-darkest border rounded-xl p-4 bg-white">
      <p class="pr-4">
        Sélectionnez votre code vendeur
      </p>
      <v-chip-group
        active-class="primary--text"
        column
      >
        <v-chip
          v-for="(code, index) in operatorCodes"
          :key="index"
          @click="onUpdate(code)"
        >
          <span>{{ itemLabel(code) }}</span>
        </v-chip>
      </v-chip-group>
    </div>
    <div class="flex flex-row justify-end py-4">
      <base-button
        primary
        :disabled="selectedOperatorCode===null"
        @click="onPress"
      >
        Continuer
      </base-button>
    </div>
  </div>
</template>

<script>

import BaseButton from '@/renderer/components/base/BaseButton.vue'

export default {
  name: 'PrescriptionRenewalIdentifier',
  components: { BaseButton },
  props: {
    operatorCodes: {
      type: Array,
      required: true
    },
    selectedOperatorCode: {
      type: String,
      default: null
    },
    teamAccess: {
      type: Array,
      required: true
    }
  },
  methods: {
    /**
     * Get item label.
     *
     * @param {string} code - The operator code.
     * @return {string}
     */
    itemLabel (code) {
      const access = this.teamAccess?.find(access => access.operatorCode === code)

      if (!access) {
        return code
      }

      return `${access.user.name} (${code})`
    },
    onPress () {
      this.$emit('next-step')
    },
    onUpdate (code) {
      this.$emit('on-code-update', this.selectedOperatorCode === code ? null : code)
    }
  }
}
</script>
