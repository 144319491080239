<template>
  <base-modal
    v-if="visible"
    title="Patients manuellement exclus du programme"
    @close="$emit('close')"
  >
    <base-container class="mt-4 space-y-6">
      <program-excluded-consumer-row
        v-for="consumer in consumers"
        :key="consumer.key"
        :support-program-id="supportProgramId"
        :consumer="consumer"
      />
    </base-container>
  </base-modal>
</template>

<script>
import BaseModal from '@/renderer/components/base/BaseModal.vue'
import BaseContainer from '@/renderer/components/base/BaseContainer.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import ProgramExcludedConsumerRow
from '@/renderer/app/program/components/program-consumers/ProgramExcludedConsumerRow.vue'

export default {
  name: 'ProgramExcludedConsumersModal',
  components: { ProgramExcludedConsumerRow, BaseButton, BaseContainer, BaseModal },
  props: {
    consumers: {
      type: Array,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    },
    supportProgramId: {
      type: Number,
      required: true
    }
  }
}
</script>
