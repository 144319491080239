import { guidanceApi, pathwayApi } from '@/renderer/container'
import store from '@/renderer/stores/store'
import SurveyService from '@/renderer/app/survey/services/SurveyService'

/**
 * The survey service.
 *
 * @author Pierre-Gérard David <pg.david@apodispharma.com>
 * @returns {Object} The public object.
 */
export default function () {
  return {
    /**
     * Start a pathway step.
     *
     * @param {Object} pathway The pathway.
     * @param {Object} step The pathway step.
     * @param {string} consumerKey The consumer key.
     *
     * @returns {*}
     */
    start (pathway, step, consumerKey) {
      return this.call('start', pathway, step, consumerKey)
    },
    /**
     * Finish a pathway step.
     *
     * @param {Object} pathway The pathway.
     * @param {Object} step The pathway step object.
     * @param {string} consumerKey The consumer key.
     * @param {Object} data Optional consumer steppable data to store.
     *
     * @returns {*}
     */
    finish (pathway, step, consumerKey, data = null) {
      return this.call('finish', pathway, step, consumerKey, data)
    },
    /**
     * call a step dynamic method.
     *
     * @param name The method name.
     * @param {Object} pathway The pathway.
     * @param {Object} step The pathway step.
     * @param {string} consumerKey The consumer key.
     * @param {Object} data Optional consumer steppable data to store.
     */
    call (name, pathway, step, consumerKey, data) {
      const rawMethod = step.type
        ? name + step.type.charAt(0).toUpperCase() + step.type.slice(1)
        : 'pass'
      const method = rawMethod.replace(/(-)(.)/g, (match, p1, p2) => p2.toUpperCase())

      if (typeof this[method] === 'function') {
        return this[method](pathway, step, consumerKey, data)
      } else {
        throw new Error('Unhandled step type.')
      }
    },

    /**
     * Start a survey step.
     *
     * @param {Object} pathway The pathway.
     * @param {Object} step The pathway step object.
     * @param {string} consumerKey The consumer key.
     */
    startSurvey (pathway, step, consumerKey) {
      SurveyService.startSurvey(
        step.steppable.id,
        consumerKey,
        step.id,
        pathway.support_program_id,
        'survey'
      )

      return Promise.resolve(true)
    },

    /**
     * Start a survey step.
     *
     * @param {Object} pathway The pathway.
     * @param {Object} step The pathway step object.
     * @param {string} consumerKey The consumer key.
     */
    startSurveyBundle (pathway, step, consumerKey) {
      SurveyService.startSurvey(
        step.steppable.id,
        consumerKey,
        step.id,
        pathway.support_program_id,
        'survey-bundle'
      )

      return Promise.resolve(true)
    },

    /**
     * Print guidance document.
     *
     * @param {Object} pathway The pathway.
     * @param {Object} step The pathway step object.
     * @param {string} consumerKey The consumer key.
     */
    startGuidance (pathway, step, consumerKey) {
      return guidanceApi()
        .document(step.steppable.document_route)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response], { type: response.type }))
          const printWindow = window.open(url)
          printWindow.print()
        })
        .finally(() => this.finishGuidance(pathway, step, consumerKey, null) /** Auto-finish step when print clicked **/)
    },
    /**
     * Finish a guidance step.
     *
     * @param {Object} pathway The pathway.
     * @param {Object} step The pathway step object.
     * @param {string} consumerKey The consumer key.
     * @param {Object} data Optional consumer steppable data to store.
     *
     * @returns {Promise}
     */
    finishGuidance (pathway, step, consumerKey, data) {
      return pathwayApi()
        .storeConsumerStep(step.id, consumerKey)
        .then(() => this.updatePathway(pathway, consumerKey))
    },
    /**
     * Start an aftercare step.
     *
     * @param {Object} pathway The pathway.
     * @param {Object} step The pathway step object.
     * @param {string} consumerKey The consumer key.
     * @param {Object} data Optional consumer steppable data to store.
     */
    startAftercare (pathway, step, consumerKey, data) {
      return pathwayApi()
        .storeConsumerStep(step.id, consumerKey)
        .then(() => this.updatePathway(pathway, consumerKey))
    },

    /**
     * Pass the current step and go to next.
     *
     * @param {Object} pathway The pathway.
     * @param {Object} step The pathway step object.
     * @param {string} consumerKey The consumer key.
     * @param {Object} data Optional consumer steppable data to store.
     */
    pass (pathway, step, consumerKey, data) {
      return pathwayApi()
        .storeConsumerStep(step.id, consumerKey)
        .then(() => this.updatePathway(pathway, consumerKey))
    },

    /**
     * Start an onboarding step.
     *
     * @param {Object} pathway The pathway.
     * @param {Object} step The pathway step object.
     * @param {string} consumerKey The consumer key.
     * @param {Object} data Optional consumer steppable data to store.
     *
     * @returns {Promise<unknown>}
     */
    startOnboarding (pathway, step, consumerKey, data) {
      return new Promise(resolve => {
        resolve(true)
      })
    },

    /**
     * Finish an onboarding step.
     *
     * @param {Object} pathway The pathway.
     * @param {Object} step The pathway step object.
     * @param {string} consumerKey The consumer key.
     * @param {Object} data Optional consumer steppable data to store.
     *
     * @returns {Promise<unknown>}
     */
    finishOnboarding (pathway, step, consumerKey, data) {
      return pathwayApi()
        .storeConsumerStep(step.id, consumerKey)
        .then(() => this.updatePathway(pathway, consumerKey))
    },
    /**
     * Update pathway after a step.
     *
     * @param {Object} pathway The pathway.
     * @param {string} consumerKey The consumer key.
     * @returns {Promise<any>}
     */
    updatePathway (pathway, consumerKey) {
      return store.dispatch('patient/loadPathway', {
        supportProgramId: pathway.support_program_id,
        consumerKey: consumerKey
      })
    }
  }
}
