<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>
    <survey-modal />
    <prescriptor-map-modal />
    <interview-instance-modal />
  </v-app>
</template>

<script>
import TheEmptyLayout from '@/renderer/components/layouts/TheEmptyLayout.vue'
import TheWebsiteLayout from '@/renderer/components/layouts/TheWebsiteLayout.vue'
import TheApplicationLayout from '@/renderer/components/layouts/TheApplicationLayout.vue'
import TheNotificationLayout from '@/renderer/components/layouts/TheNotificationLayout.vue'
import TheAuthenticationLayout from '@/renderer/components/layouts/TheAuthenticationLayout.vue'
import Vue from 'vue'
import SupportProgramAdvice from '@/renderer/app/program/components/support-program/SupportProgramAdvice.vue'
import Unicon from 'vue-unicons/dist/vue-unicons-vue2.umd'
import {
  uniCheck,
  uniMultiply,
  uniClock,
  uniAndroidPhoneSlash,
  uniPrint,
  uniPhone,
  uniSync,
  uniArrowLeft,
  uniAngleRight,
  uniBook,
  uniFileAlt,
  uniCapsule,
  uniShoppingCart,
  uniArrowRight,
  uniLeftArrowFromLeft,
  uniArrowFromRight,
  uniMicrophone,
  uniPlay,
  uniPause,
  uniUser,
  uniMedkit,
  uniSchedule,
  uniInfoCircle,
  uniFileBlank,
  uniVolume,
  uniSkipForward,
  uniPlus,
  uniTruck,
  uniTimes,
  uniUsersAlt,
  uniSetting,
  uniTrashAlt
} from 'vue-unicons/dist/icons'
import { environmentResource } from '@/renderer/container'

Unicon.add([
  uniAngleRight, uniCapsule, uniShoppingCart, uniCheck, uniClock, uniFileAlt, uniMultiply,
  uniAndroidPhoneSlash, uniPrint, uniPhone, uniSync, uniArrowLeft, uniArrowRight, uniBook, uniLeftArrowFromLeft,
  uniArrowFromRight, uniMicrophone, uniPlay, uniPause, uniInfoCircle, uniFileBlank, uniVolume, uniSkipForward, uniPlus,
  uniTruck, uniTimes, uniUsersAlt, uniSetting, uniTrashAlt, uniUser, uniMedkit, uniSchedule, uniAngleRight
])

Vue.use(Unicon)

Vue.component('support-program-advice', SupportProgramAdvice)

export default {
  components: {
    TheEmptyLayout,
    TheWebsiteLayout,
    TheApplicationLayout,
    TheNotificationLayout,
    TheAuthenticationLayout
  },

  computed: {
    layout () {
      return this.$route.meta.layout || 'TheApplicationLayout'
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (to, from) {
        document.title = to.meta.title || 'Apodis Connect'
      }
    }
  },

  mounted () {
    const channel = 'order-created'
    window.ipcRenderer.on('socket:connected', () => {
      environmentResource()
        .read()
        .then(environment => {
          window.ipcRenderer.send('socket:subscribe', 'private-organization.' + environment.organizationId)
          window.ipcRenderer.send('socket:listen', channel)
          window.ipcRenderer.on('socket:event-received.' + channel, (event, data) => {
            this.$store.commit('live/newItems', data)
          })
        })
    })
  },
  destroyed () {
    const organizationId = this.$store.state.system.environment.organizationId
    window.ipcRenderer.send('socket:removeListener', 'order-created')
    window.ipcRenderer.send('socket:unsubscribe', 'private-organization.' + organizationId)
  }
}
</script>

<style>
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
  url(../fonts/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins-SemiBold"),
  url(../fonts/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
  url(../fonts/Poppins-Bold.ttf) format("truetype");
}

button:focus {
  outline: none;
  box-shadow: none;
}

/* Scroll bar stylings */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cbcbcb;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #adadad;
}

.gradient {
  @apply bg-gradient-to-r from-darkPurple-default to-darkBlue-default
}
</style>
