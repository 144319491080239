<template>
  <div class="flex flex-col container mx-auto">
    <div class="flex flex-row px-4 items-end justify-between relative">
      <div class="relative -bottom-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="w-14"
        >
          <path
            d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5H5.625z"
          />
          <path
            d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z"
          />
        </svg>
        <div class="absolute bottom-3 w-full text-center">
          <span
            class="text-white text-xs"
          >{{ file.meta.extension }}</span>
        </div>
      </div>
      <span class="text-sm">{{ this.createdAt }}</span>
    </div>
    <base-card class="card bg-white z-10 flex flex-col p-2">
      <span>{{ file.type.name }}</span>
      <button
        class="text-end self-end text-sm mt-2 text-primary"
        @click="onClick"
      >
        Voir le document
      </button>
    </base-card>
  </div>
</template>

<script>
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import dateHelper from '@/renderer/helpers/dateHelper'
import BaseButton from '@/renderer/components/base/BaseButton.vue'

export default {
  name: 'CustomerHospitalFileCard',
  components: { BaseButton, BaseCard },
  props: {
    file: {
      type: Object,
      required: true
    }
  },
  computed: {
    /**
     * Return the short format of the created_at date
     *
     * @return {string}
     */
    createdAt () {
      return dateHelper.shortFormat(this.file.created_at)
    }
  },
  methods: {
    /**
     * Emit the click event
     */
    onClick () {
      this.$emit('click', this.file)
    }
  }
}
</script>
