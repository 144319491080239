<template>
  <div class="flex flex-1 flex-col justify-center container mx-auto">
    <p class="text-center text-white font-bold mb-5">
      {{ title }}
    </p>
    <div class="bg-white rounded-md full-width p-5 mt-4">
      <p class="font-content">
        {{ question.currentStep }} {{ question.title }} {{ question.description }}
      </p>
      <div class="flex flex-1 justify-center mt-2">
        <survey-answer
          :question="question"
          @onAnswerPress="onPress"
        />
      </div>
    </div>
    <span
      v-if="question.optional"
      class="text-white italic text-xs mt-2"
    >
      Question facultative : vous pouvez passer à la suite en cliquant sur Suivant
    </span>
  </div>
</template>

<script>
import SurveyAnswer from '@/renderer/app/survey/components/SurveyAnswer.vue'

export default {
  name: 'SurveyQuestion',
  components: { SurveyAnswer },
  props: {
    question: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    /**
     * Emit onPress event when an answer is pressed.
     *
     * @param answer
     */
    onPress (answer) {
      this.$emit('onPress', answer)
    }
  }
}
</script>
