<template>
  <div class="flex flex-row items-end gap-1 flex-wrap">
    <base-button
      :v-bind="extractedButtonProps"
      :primary="primary"
      :class="experienceEvent ? 'shiny' : ''"
      :style="buttonStyle"
      :disabled="disabled"
      @click="handleClick"
    >
      <slot />
    </base-button>
    <div
      v-if="experienceEvent"
      class="flex items-end grow"
    >
      <img
        alt="coin"
        src="../../../../assets/images/apodis-coin.png"
        class="w-4 aspect-square"
      >
      <span class="text-xs font-semibold leading-none text-primary">
        +{{ experienceEvent.value }}
      </span>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/renderer/components/base/BaseButton.vue'

export default {
  name: 'ExperienceButton',
  components: { BaseButton },
  props: {
    ...BaseButton.props,
    primary: {
      type: Boolean,
      required: false,
      default: false
    },
    buttonStyle: {
      type: String,
      required: false,
      default: ''
    },
    eventName: {
      type: String,
      required: true
    }
  },
  computed: {
    /**
     * The button props extracted from the props.
     *
     * @return {Omit<{readonly eventName: string, readonly disabled: boolean, readonly primary: boolean}, "eventName">}
     */
    extractedButtonProps () {
      const { eventName, ...props } = this.$props
      return props
    },
    /**
     * The experience event extracted from the store.
     *
     * @return {Object | undefined}
     */
    experienceEvent () {
      return this.$store.getters['experience/getEventByName'](this.eventName)
    }
  },
  methods: {
    handleClick (event) {
      this.$emit('click', event)
    }
  }
}
</script>

<style scoped>

.shiny {
  border: none;
  overflow: hidden;
}

.shiny:before {
  position: absolute;
  content: '';
  display: inline-block;
  top: -180px;
  left: 0;
  width: 30px;
  height: 100%;
  background-color: #fff;
  animation: shiny-btn1 3s ease-in-out infinite;
}

.shiny:hover {
  opacity: .7;
}

.shiny:active {
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, .3),
  -4px -4px 6px 0 rgba(116, 125, 136, .2),
  inset -4px -4px 6px 0 rgba(255, 255, 255, .2),
  inset 4px 4px 6px 0 rgba(0, 0, 0, .2);
}

@-webkit-keyframes shiny-btn1 {
  0% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0;
  }
  80% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }
  81% {
    -webkit-transform: scale(4) rotate(45deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(50) rotate(45deg);
    opacity: 0;
  }
}
</style>
