<template>
  <span class="rounded-full inline-flex flex items-center align-middle justify-center w-full">
    <svg
      v-if="size !== 'small'"
      id="Calque_1"
      data-name="Calque 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      :class="`fill-current text-${color} w-${iconDimension} animate-spin`"
    ><path
      class="cls-1"
      d="M9.94,7.36A2.15,2.15,0,1,1,12.08,5.2,2.13,2.13,0,0,1,9.94,7.36Z"
    /><path
      class="cls-1"
      d="M5.93,7.24a2,2,0,1,1-2,2A2,2,0,0,1,5.93,7.24Z"
    /><path
      class="cls-1"
      d="M4.49,12.87a1.8,1.8,0,0,1,1.8,1.81,1.82,1.82,0,1,1-3.64,0A1.81,1.81,0,0,1,4.49,12.87Z"
    /><path
      class="cls-1"
      d="M7.58,20.14a1.65,1.65,0,1,1-3.3,0,1.65,1.65,0,1,1,3.3,0Z"
    /><path
      class="cls-1"
      d="M11.42,24.15a1.49,1.49,0,1,1-3,0A1.51,1.51,0,0,1,10,22.65,1.49,1.49,0,0,1,11.42,24.15Z"
    /><path
      class="cls-1"
      d="M15.4,24.28a1.33,1.33,0,1,1,0,2.65,1.33,1.33,0,0,1,0-2.65Z"
    /><path
      class="cls-1"
      d="M22,24.13a1.14,1.14,0,0,1-1.16,1.17A1.16,1.16,0,1,1,22,24.13Z"
    /><path
      class="cls-1"
      d="M24.86,21.13a1,1,0,1,1,1-1A1,1,0,0,1,24.86,21.13Z"
    /><path
      class="cls-1"
      d="M26.32,15.5a.82.82,0,0,1-.83-.82.83.83,0,0,1,1.66,0A.82.82,0,0,1,26.32,15.5Z"
    /><path
      class="cls-1"
      d="M25.52,9.21a.67.67,0,0,1-1.33,0,.68.68,0,0,1,.65-.67A.66.66,0,0,1,25.52,9.21Z"
    /><path
      class="cls-1"
      d="M20.86,4.74a.49.49,0,0,1,.5.49.52.52,0,0,1-.51.49.5.5,0,0,1-.48-.49A.47.47,0,0,1,20.86,4.74Z"
    /><path
      class="cls-1"
      d="M15.39,4.09c-.19,0-.32-.13-.32-.33a.3.3,0,0,1,.31-.33.32.32,0,0,1,.34.34C15.71,4,15.59,4.06,15.39,4.09Z"
    />
    </svg>
    <img
      v-if="size === 'small'"
      :src="require('@/assets/loader.gif')"
      alt="Loading"
    >
  </span>
</template>

<script>
/**
 * The base spinner.
 */
export default {
  name: 'BaseSpinner',

  props: {
    /**
     * Icon size.
     * @values small, medium, large
     */
    size: {
      type: String,
      default: 'medium',
      validator (value) {
        return ['button', 'small', 'medium', 'medium-small', 'large'].includes(value)
      }
    },
    color: {
      type: String,
      default: 'primary'
    }
  },

  computed: {
    /**
     * Get icon dimension depending size props.
     *
     * @return {number}
     */
    iconDimension () {
      const defaultSize = 20

      switch (this.size) {
        case 'button':
          return 5
        case 'small':
          return 10
        case 'medium-small':
          return 14
        case 'medium':
          return defaultSize
        case 'large':
          return 30
        default:
          return defaultSize
      }
    }
  }
}
</script>
