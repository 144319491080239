<template>
  <div>
    <h2 class="text-lg">
      Entretiens disponibles
    </h2>
    <div
      v-if="interviews.length"
      class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2"
    >
      <button
        v-for="interview in interviews"
        :key="interview.id"
        class="bg-white shadow-md py-4 rounded-md flex items-center justify-center"
        @click="startInterview(interview)"
      >
        <icon
          name="file-alt"
          :size="30"
          class="mr-2"
        />
        <span>{{ interview.name }}</span>
      </button>
    </div>
    <div v-else>
      <p>Aucun entretien n'est disponible pour le moment.</p>
    </div>
  </div>
</template>

<script>
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import InterviewInstanceService from '@/renderer/app/interview/services/InterviewInstanceService'
import Icon from '@/renderer/app/core/icon/Icon.vue'

export default {
  name: 'InterviewGrid',
  components: { Icon, BaseSpinner },
  props: {
    interviews: {
      type: Array,
      required: true
    },
    consumerName: {
      type: String,
      required: true
    },
    consumerKey: {
      type: String,
      required: true
    }
  },
  methods: {
    /**
     * Start a new interview instance.
     *
     * @param {Object} interview The interview.
     */
    startInterview (interview) {
      InterviewInstanceService.start(
        interview.id,
        this.consumerName,
        this.consumerKey,
        interview.name,
        this.onInterviewFinished
      )
    },
    /**
     * Callback when the interview is finished.
     */
    onInterviewFinished () {
      this.$emit('interview-finished')
    }
  }
}
</script>

<style scoped>

</style>
