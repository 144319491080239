<template>
  <BaseCard class="relative overflow-visible">
    <BaseCardBody style="padding: 14px 8px 14px 75px;">
      <ProgramLogo
        :program="program"
        :size="75"
        class="absolute"
        style="top: -3px; left: -10px"
      />
      <h1 class="text-xs font-content-bold">
        {{ program.name }}
      </h1>
      <div class="text-tiny text-content">
        <div>{{ program.short_description }}</div>
        <div class="flex flex-row justify-end text-primary">
          <router-link :to="{ name: 'program.detail.content', params: { programId: program.id } }">
            En savoir plus
          </router-link>
        </div>
      </div>
    </BaseCardBody>
  </BaseCard>
</template>

<script>
import BaseCardBody from '@/renderer/components/base/BaseCardBody.vue'
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import ProgramLogo from '@/renderer/app/program/components/program-logo/ProgramLogo.vue'
import dateHelper from '@/renderer/helpers/dateHelper'

export default {
  components: { ProgramLogo, BaseCard, BaseCardBody },

  props: {
    program: {
      type: Object,
      required: true
    }
  },

  methods: {
    localeDate (dateString) {
      return dateHelper.shortFormat(new Date(dateString))
    }
  }

}
</script>
