<template>
  <interview-short-cut-container title="Notes internes">
    <div>
      <div
        v-if="loading"
        class="mx-auto"
      >
        <base-spinner size="medium-small" />
      </div>
      <div v-else-if="notes">
        <p
          v-if="!notes.length"
          class="text-center"
        >
          Aucune note interne n'a été enregistrée pour ce patient
        </p>
        <div
          v-else
          class="flex flex-col gap-3"
        >
          <div
            v-for="note in notes"
            class="bg-white rounded-lg px-3 py-2 shadow-md"
          >
            <p
              class="text-sm"
              v-html="note.content.replace(/\n/g, '<br>')"
            />
            <p class="text-end text-xs mt-1">
              {{ note.created_at | date }}
            </p>
          </div>
          <base-button
            v-if="meta?.current_page < meta?.last_page"
            class="font-medium"
            :disabled="loading"
            @click="loadMore"
          >
            <base-spinner
              v-if="loading"
              size="button"
            />
            <span v-else>Voir plus</span>
          </base-button>
        </div>
      </div>
      <div v-else>
        <p class="text-center">
          Une erreur s'est produite lors du chargement des données.
        </p>
      </div>
    </div>
  </interview-short-cut-container>
</template>

<script>
import InterviewShortCutContainer
from '@/renderer/app/interview/components/instance-modal/short-cuts/InterviewShortCutContainer.vue'
import { interviewApi } from '@/renderer/container'
import { createNamespacedHelpers } from 'vuex'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'

const { mapState } = createNamespacedHelpers('interview')

export default {
  name: 'InterviewConsumerNotes',
  components: { BaseButton, BaseSpinner, InterviewShortCutContainer },
  data () {
    return {
      notes: [],
      loading: false,
      loadingMore: false,
      meta: null
    }
  },
  mounted () {
    this.loading = true
    this.loadConsumerNotes()
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    /**
     * Load consumer notes
     *
     * @param page
     * @returns {Promise<void>}
     */
    loadConsumerNotes (page = 1) {
      return interviewApi()
        .indexNotes(this.consumerKey, page)
        .then((response) => {
          this.meta = response.meta
          this.notes = [...(this.notes ?? []), ...response.data]
        })
    },
    /**
     * Load more notes.
     */
    loadMore () {
      if (this.meta && this.meta.current_page < this.meta.last_page) {
        this.loadingMore = true
        this.loadConsumerNotes(this.meta.current_page + 1)
          .finally(() => {
            this.loadingMore = false
          })
      }
    }
  },
  computed: {
    ...mapState(['consumerKey'])
  }
}
</script>

<style scoped>

</style>
