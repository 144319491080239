<template>
  <BaseCard class="container">
    <div class="image">
      <img
        :src="advice.image"
        :alt="advice.name"
        width="120"
      >
    </div>
    <div
      class="flex flex-1 flex-col font-content px-5 py-3 text-content text-sm"
      :style="{'background-color': advice.background_color}"
    >
      <div class="flex justify-between mb-2">
        <h3>
          {{ advice.name }}
        </h3>
        <div
          v-if="!!purchasedAt"
          class="text-tiny text-sans rounded-2xl bg-white px-2 flex flex-col justify-center"
        >
          {{ purchasedAt }}
        </div>
      </div>
      <div class="flex-1 text-xs pb-2">
        <strong>Conseils</strong> à prodiguer au patient:
        <ul class="pl-4 pt-1">
          <li
            v-for="(item, index) in advice.advices"
            :key="index"
            class="list-disc leading-5 break-normal"
            v-html="stylized(item, advice.color)"
          />
        </ul>
      </div>
      <div class="flex justify-end text-tiny h-6">
        {{ advice.description }}
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import dateHelper from '@/renderer/helpers/dateHelper'

export default {
  name: 'SupportProgramAdvice',
  components: { BaseCard },
  props: {
    advice: {
      type: Object,
      required: true
    }
  },
  computed: {
    purchasedAt () {
      return this.advice.purchased_at ? 'Acheté le ' + dateHelper.shortFormat(this.advice.purchased_at) : null
    }
  },
  methods: {
    stylized (content, color) {
      const bold = /\*\*(.*?)\*\*/gm

      return content.replace(bold, `<strong style="color:${color}">$1</strong>`)
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  height: 200px;
  width: 410px;
}

.image {
  width: 120px;
  display: flex;
  align-items: center;
}
</style>
