<template>
  <label class="font-medium text-xs flex items-center">
    <input
      type="radio"
      style="border: 1px solid !important"
      :value="value"
      :checked="value === model"
      @change="$emit('change', value)"
    >

    <span class="ml-3">
      {{ label }}
    </span>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'model',
    event: 'change'
  },

  props: {
    label: {
      type: String,
      required: true
    },

    value: {
      type: [
        String,
        Number
      ],

      required: true
    },

    model: {
      type: null,
      required: true
    }
  }
}
</script>
