<template>
  <transition
    name="expand"
    @before-enter="beforeEnter"
    @enter="enter"
    @before-leave="beforeLeave"
    @leave="leave"
  >
    <div
      v-if="display"
      id="exlusion"
      ref="exclusion"
      class="text-xs bg-gray-200 w-full rounded-b-md -z-10"
    >
      <div :class="{ 'h-6': hasAction, 'h-2': !hasAction }" />
      <div v-if="nextStep && nextStep.steppable?.actions">
        <button
          v-for="action in nextStep.steppable.actions"
          :key="action.id"
          class="text-primary underline mx-2 flex gap-2 items-center mb-1"
          @click="storeCheckupAction(action, nextStep)"
        >
          <label>{{ action.name }}</label>
        </button>
      </div>
      <button
        v-if="isExcluded === false"
        class="text-primary underline mx-2 flex gap-2 items-center mb-1"
        @click.stop="toggleExclusion"
      >
        <label>Retirer le patient du programme</label>
        <span v-if="loadingExclude">
          <base-spinner size="button" />
        </span>
      </button>
      <div
        v-else
        class="mx-2 mb-1"
      >
        ✅ Patient retiré, il ne sera plus notifié pour ce programme.
        <button
          class="text-primary underline gap-2 items-center inline-block"
          @click.stop="toggleExclusion"
        >
          <label>Annuler</label>
          <span
            v-if="loadingExclude"
            class="inline-block ml-1"
          >
            <base-spinner size="button" />
          </span>
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import { pathwayApi, programResource } from '@/renderer/container'

export default {
  name: 'NotificationSupportProgramSettingExclusionToggler',
  components: { BaseSpinner },
  props: {
    /**
     * Determine if the toggler should be displayed.
     */
    display: {
      type: Boolean,
      default: false
    },
    /**
     * The support program ID.
     */
    supportProgramId: {
      type: Number,
      required: true
    },
    /**
     * The support program ID.
     */
    nextStep: {
      type: Object,
      default: null
    },
    /**
     * The consumer key.
     */
    consumerKey: {
      type: String,
      required: true
    },
    /**
     * Whether the program have an action attached.
     */
    hasAction: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loadingExclude: false,
      isExcluded: false
    }
  },
  methods: {
    /**
     * Store a checkup action.
     */
    storeCheckupAction (action, step) {
      return pathwayApi()
        .storeConsumerStep(
          step.id,
          this.consumerKey,
          action.id,
          'checkup-action',
          !!action.is_terminal
        )
    },
    /**
     * Toggle the exclusion of the consumer from the program.
     */
    toggleExclusion () {
      this.loadingExclude = true
      programResource()
        .toggleConsumerSettings(this.supportProgramId, this.consumerKey)
        .then(() => {
          this.isExcluded = !this.isExcluded
        })
        .finally(() => {
          this.loadingExclude = false
          this.contentChanged()
        })
    },
    /**
     * Prepare the element for the animation.
     *
     * @param {Object} element
     */
    beforeEnter (element) {
      element.style.height = '0'
      element.style.opacity = '0'
    },
    /**
     * On animation enter, set the height to the natural height of the element.
     *
     * @param {Object} element
     * @param {Function} done
     */
    enter (element, done) {
      element.style.height = `${element.scrollHeight}px`
      element.style.opacity = '1'

      element.addEventListener('transitionend', done)
    },
    /**
     * Prepare the element before leaving the animation.
     *
     * @param {Object} element
     */
    beforeLeave (element) {
      element.style.height = `${element.scrollHeight}px`
      element.style.opacity = '1'
    },
    /**
     * On animation leave, set the height to 0 and the opacity to 0.
     *
     * @param {Object} element
     * @param {Function} done
     */
    leave (element, done) {
      element.style.transition = 'opacity 0.5s ease, height 0.5s ease'
      element.style.height = '0'
      element.style.opacity = '0'
      element.addEventListener('transitionend', done)
    },
    /**
     * Update the height of the exclusion element after the content has changed.
     */
    contentChanged () {
      this.$nextTick(() => {
        const element = this.$refs.exclusion

        if (element) {
          element.style.height = `${element.scrollHeight}px`
        }
      })
    }
  }
}
</script>

<style scoped>
.expand-enter-active, .expand-leave-active {
  transition: opacity 0.5s, height 0.5s ease;
  overflow: hidden;
}

.expand-enter, .expand-leave-to /* Starting and ending states */
{
  opacity: 0;
  height: 0;
}
</style>
