<template>
  <BaseContainer>
    <div class="mb-8">
      <div class="sm:hidden">
        <select
          v-model="selectedType"
          class="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
        >
          <option :value="null">
            Tous les types
          </option>

          <option
            v-for="type in types"
            :key="type"
          >
            {{ type }}
          </option>
        </select>
      </div>

      <div class="hidden sm:block">
        <nav aria-label="Types">
          <button
            v-for="type in types"
            :key="type"
            class="px-3 py-2 font-medium text-sm rounded-full mr-2 mt-2 focus:outline-none"
            :class="[ type === selectedType
              ? 'bg-indigo-100 text-indigo-700'
              : 'bg-white text-gray-600 hover:text-gray-800'
            ]"
            @click="selectType(type)"
          >
            {{ type }}
          </button>
        </nav>
      </div>
    </div>

    <BaseSpinner
      v-if="loading"
    />

    <div v-else>
      <div v-if="partners.length === 0">
        <div class="text-primary flex flex-row py-10 justify-center items-center font-bold">
          <StoreIcon />
          <p class="ml-4">
            Parcourez le store depuis Apodis PRO afin d'activer de nouveaux partenaires !
          </p>
        </div>
      </div>
      <ul
        role="list"
        class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3"
      >
        <li
          v-for="partner in partners"
          :key="partner.id"
          class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
        >
          <div class="flex-1 flex flex-col p-8">
            <img
              class="flex-shrink-0 mx-auto"
              :src="`${partner.branding.logo.renderPath}&h=100`"
              :alt="partner.name"
            >

            <h3 class="mt-6 text-gray-900 text-sm font-medium">
              {{ partner.name }}
            </h3>

            <dl class="mt-1 flex-grow flex flex-col justify-between">
              <dt class="sr-only">
                Type
              </dt>

              <dd class="text-gray-500 text-sm">
                {{ partner.laboratoryTypes.name }}
              </dd>
            </dl>
          </div>

          <div>
            <div class="-mt-px flex divide-x divide-gray-200">
              <div class="w-0 flex-1 flex">
                <a
                  class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 cursor-pointer"
                  @click="navigate(partner)"
                >
                  <svg
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 12a2 2 0 100-4 2 2 0 000 4z"
                    />

                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                    />
                  </svg>

                  <span class="ml-3">
                    Afficher
                  </span>
                </a>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </BaseContainer>
</template>

<script>
import {
  partnerResource,
  laboratoryBrandingResource
} from '@/renderer/container'

import BaseContainer from '@/renderer/components/base/BaseContainer.vue'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import StoreIcon from '@/renderer/app/core/components/store-icon/StoreIcon.vue'

export default {
  components: {
    StoreIcon,
    BaseSpinner,
    BaseContainer
  },

  data () {
    return {
      types: [],
      partners: [],
      selectedType: null,
      loading: true
    }
  },

  watch: {
    selectedType: {
      immediate: true,

      handler (type) {
        this.loading = true
        partnerResource().get().then(partners => {
          const filteredPartners = partners.filter(
            partner => Object.prototype.hasOwnProperty.call(partner, 'laboratoryTypes')
          )

          this.types = filteredPartners
            .map(partner => partner.laboratoryTypes.name)
            .filter((type, index, self) => self.indexOf(type) === index)
            .sort()

          if (this.selectedType) {
            this.partners = filteredPartners.filter(
              partner => partner.laboratoryTypes.name === type
            )
          } else {
            this.partners = filteredPartners
          }
        }).finally(() => {
          this.loading = false
        })
      }
    }
  },

  methods: {
    selectType (type) {
      if (type === this.selectedType) {
        this.selectedType = null
      } else {
        this.selectedType = type
      }
    },

    navigate (partner) {
      laboratoryBrandingResource()
        .read(partner.id)
        .then(branding => {
          this.$store.commit('setBranding', branding)

          this.$router.push({
            name: 'brand.range.list',

            params: {
              laboratoryId: partner.id
            }
          })
        })
    }
  }
}
</script>
