<template>
  <div>
    <fieldset class="mb-8">
      <legend class="sr-only">
        Types
      </legend>

      <div class="flex flex-col space-y-3 md:flex-row md:space-x-8 md:space-y-0">
        <div
          v-for="type in types"
          :key="type.name"
          class="flex items-center"
        >
          <div class="flex items-center h-5">
            <input
              :id="`types-${type.name}`"
              v-model="selectedTypes"
              :value="type"
              type="checkbox"
              class="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300 rounded"
            >
          </div>

          <div class="ml-3 text-sm">
            <label
              :for="`types-${type.name}`"
              class="flex font-medium text-gray-700 items-center"
            >
              <component
                :is="type.icon"
                :size="4"
              />

              <span class="ml-3">
                {{ type.label }}
              </span>
            </label>
          </div>
        </div>
      </div>
    </fieldset>

    <ul
      v-if="events.length"
      role="list"
      class="space-y-6"
    >
      <li
        v-for="event in events"
        :key="event.id"
      >
        <div class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
          <div>
            <BaseImage
              :path="event.imagePath"
              :rendered="offer.file"
              class="w-full h-96"
              :text="event.name"
            />
          </div>

          <div class="px-4 py-4 sm:px-6 flex justify-between">
            <div class="flex space-x-4">
              <component
                :is="event.icon"
                :size="6"
              />

              <div>
                <h2 class="font-medium text-lg">
                  {{ event.name }}
                </h2>

                <p class="text-sm text-gray-500">
                  Offre valable

                  du {{ event.formattedStart }}
                  au {{ event.formattedEnd }}
                </p>
              </div>
            </div>

            <router-link
              v-if="event.route"
              :to="event.route"
              class="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <svg
                viewBox="0 0 20 20"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                class="-ml-1 mr-2 h-5 w-5 text-gray-400"
              >
                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />

                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>

              <span>
                Afficher
              </span>
            </router-link>
          </div>
        </div>
      </li>
    </ul>

    <BaseEmpty
      v-else
      title="Aucune offre commerciale"
      description="Vous n’avez pas encore d’offres commerciales disponibles"
    />
  </div>
</template>

<script>
import {
  laboratoryEventResource
} from '@/renderer/container'

import eventHelper from '@/renderer/helpers/eventHelper'

import BaseEmpty from '@/renderer/components/base/BaseEmpty.vue'
import BaseImage from '@/renderer/components/base/BaseImage.vue'

import SellinIcon from '@/renderer/components/icons/Sellin.vue'
import SelloutIcon from '@/renderer/components/icons/Sellout.vue'
import DiscountIcon from '@/renderer/components/icons/Discount.vue'
import PrestationIcon from '@/renderer/components/icons/Prestation.vue'

export default {
  components: {
    BaseImage,
    BaseEmpty,
    SellinIcon,
    SelloutIcon,
    DiscountIcon,
    PrestationIcon
  },

  data () {
    return {
      types: [
        {
          name: 'sellout',
          icon: 'SelloutIcon',
          label: 'Challenge équipe',

          filters: [
            'sellout-participation'
          ]
        },

        {
          name: 'prestation',
          icon: 'PrestationIcon',
          label: 'Mise en avant',

          filters: [
            'prestation-participation'
          ]
        },

        {
          name: 'sellin',
          icon: 'SellinIcon',
          label: 'Promotion',

          filters: [
            'sellin-offer-participation',
            'sellin-challenge-participation'
          ]
        },

        {
          name: 'discount',
          icon: 'DiscountIcon',
          label: 'Bon de réduction immédiat',

          filters: [
            'instant-discount-voucher'
          ]
        }
      ],

      events: [],
      selectedTypes: []
    }
  },

  watch: {
    selectedTypes (selectedTypes) {
      const types = selectedTypes
        .map(type => type.filters)
        .flat()

      laboratoryEventResource()
        .get(this.$route.params.laboratoryId, types)
        .then(events => {
          this.events = events.map(event => {
            event.formattedStart = new Date(event.start)
              .toLocaleDateString()

            event.formattedEnd = new Date(event.end)
              .toLocaleDateString()

            const type = this.types
              .filter(type => type.filters.includes(event.type))
              .shift()

            event.icon = type.icon
            event.route = eventHelper.generateRoute(event)

            if (event.file) {
              event.imagePath = event.file.render_path
            }

            return event
          })
        })
    }
  },

  mounted () {
    this.selectedTypes = this.types
  }
}
</script>
