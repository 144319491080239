<template>
  <base-container class="p-6">
    <div
      v-if="loading"
      class="flex flex-1"
    >
      <BaseSpinner size="medium-small" />
    </div>
    <div
      v-else
    >
      <div class="space-y-4">
        <h2 class="text-lg">
          Questionnaires disponibles
        </h2>
        <p class="text-sm">
          Remplissez un questionnaire avec votre patient pour mieux l'accompagner
        </p>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-4">
          <survey-card
            v-for="survey in surveys"
            :key="`survey-${survey.id}`"
            :survey="survey"
            :consumer-key="customer.consumer_key"
            @survey-completed="refreshInstances"
          />
        </div>
      </div>
      <div class="flex flex-1 flex-col mt-4 space-y-4">
        <div class="flex items-center">
          <h2 class="text-lg">
            Résultats
          </h2>
          <div
            v-if="refreshing"
            class="ml-2"
          >
            <base-spinner size="button" />
          </div>
        </div>
        <div
          v-if="instances.length > 0"
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 flex-1 gap-5 pb-2"
        >
          <customer-survey-card
            v-for="instance in instances"
            :key="`instance-${instance.id}`"
            :instance="instance"
          />
        </div>
        <div
          v-else
          class="text-xs ml-6"
        >
          Aucun résultat disponible pour le moment.
        </div>
      </div>
    </div>
  </base-container>
</template>

<script>
import { surveyAnswerInstanceApi, surveyApi } from '@/renderer/container'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import CustomerSurveyCard from '@/renderer/app/customer/components/customer-surveys/CustomerSurveyCard.vue'
import SurveyCard from '@/renderer/app/survey/components/SurveyCard.vue'
import BaseContainer from '@/renderer/components/base/BaseContainer.vue'

export default {
  name: 'CustomerSurveys',
  components: { BaseContainer, SurveyCard, CustomerSurveyCard, BaseSpinner },
  data () {
    return {
      /** The available surveys. */
      surveys: [],
      /** The answered instances. */
      instances: null,
      /** The instance meta data */
      meta: null,
      /** The loading state. */
      loading: true,
      /** The refreshing state. */
      refreshing: false
    }
  },
  computed: {
    customer () {
      return this.$store.getters['patient/customer']()
    }
  },
  /**
   * Load the instances and the surveys when the component is mounted.
   */
  mounted () {
    this.loading = true
    Promise.all([this.loadInstances(), this.loadSurveys()])
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    /**
     * Load the survey answer instances of the customer.
     *
     * @param {Number} page The page number.
     *
     * @return {Promise<void>}
     */
    loadInstances (page = 1) {
      if (this.customer.consumer_key) {
        return surveyAnswerInstanceApi()
          .answeredInstance({ consumer_key: this.customer.consumer_key, page })
          .then(response => {
            this.meta = response.meta
            this.instances = (!this.instances || page === 1)
              ? response.data
              : [...this.instances, ...response.data]
          })
      } else {
        return Promise.resolve()
      }
    },
    /**
     * Load all the survey available that are not linked to a pathway.
     *
     * @returns {Promise<void>}
     */
    loadSurveys () {
      return surveyApi()
        .index({ pathway: false })
        .then(response => {
          this.surveys = response
        })
    },
    /**
     * Refresh the consumer instances.
     */
    refreshInstances () {
      this.refreshing = true
      this.loadInstances()
        .finally(() => {
          this.refreshing = false
        })
    }
  }
}
</script>
