<template>
  <div class="flex flex-row justify-space-between items-center text-primary font-bold mt-5">
    <p class="w-60 flex">
      Coût: 1
    </p>
    <base-button
      v-if="notification"
      primary
      @click="onSend"
    >
      <div class="flex flex-row gap-x-2">
        Envoyer au {{ certification.phone | phoneFormat }}
        <Sms :size="5" />
      </div>
    </base-button>
    <p class="w-60 justify-end flex">
      Restera : {{ (balance - cost) | numberFormat(0) }} {{ 'crédit' | pluralize(balance - cost) }}
    </p>
  </div>
</template>

<script>
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import Sms from '@/renderer/components/icons/Sms.vue'

export default {
  name: 'CertificationNotificationFooter',
  components: { Sms, BaseButton },
  props: {
    /**
     * The organization credit balance
     */
    balance: {
      type: Number,
      required: true
    },
    /**
     * The certification object.
     */
    certification: {
      type: Object,
      required: true
    },
    /**
     * The message object.
     */
    notification: {
      type: Object,
      default: null
    },
    /**
     * On send callback.
     */
    onSend: {
      type: Function,
      required: true
    }
  },
  computed: {
    cost () {
      return 1
    }
  }
}
</script>

<style scoped>

</style>
