<template>
  <div class="flex h-screen w-full">
    <GmapMap
      ref="mapRef"
      :center="center"
      :zoom="16"
      :options="gmapOptions"
      style="width: 100%; height: inherit;"
    >
      <GmapMarker
        v-for="(m, index) in healthcenters"
        :key="index"
        :position="{ lat: m.geo.location.lat, lng: m.geo.location.lon }"
        :clickable="true"
        :draggable="false"
        :icon="{ url: (selectedHealtcenter && (selectedHealtcenter.cip === m.cip)) ? require('@/assets/images/icons/healthcenter_placemark_selected.svg') : require('@/assets/images/icons/healthcenter_placemark.svg')}"
        @click="selectedHealtcenter=m"
      />
    </GmapMap>
    <div class="absolute top-10 left-1/2 transform -translate-x-1/2 z-10">
      <div class="px-5 py-2 bg-white rounded-lg text-black">
        Recherche par code postal :
        <input
          v-model="postalCodeMutable"
          type="text"
          class="border-transparent rounded-lg text-primary w-24 border-primary"
          @keyup.enter="updatePostalCode"
          @blur="formatZipcode"
          @input="() => debounceFormatZipCode(10000)"
        >
        <button
          class="shadow p-2 ml-2 rounded"
          @click="updatePostalCode"
        >
          Rechercher
        </button>
      </div>
    </div>
    <div class="absolute bottom-10 right-20 z-10">
      <RegisterHealthcenterView
        :healthcenter="selectedHealtcenter"
        @validate="validateHealthcenter"
      />
    </div>
  </div>
</template>

<script>
import { healthcenterSearchResource } from '@/renderer/container'
import { gmapApi } from 'vue2-google-maps'
import RegisterHealthcenterView from '@/renderer/components/views/register/RegisterHealthcenterView.vue'
import formatHelper from '@/renderer/helpers/formatHelper'

export default {
  name: 'RegisterMapView',

  components: {
    RegisterHealthcenterView
  },

  props: {
    postalCode: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      postalCodeMutable: this.postalCode,
      timer: null,
      gmapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        clickableIcons: false,
        disableDefaultUI: true,
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }]
          }
        ]
      },
      google: null,
      center: { lat: 48.856613, lng: 2.352222 },
      healthcenters: [],
      selectedHealtcenter: null
    }
  },

  mounted () {
    this.updatePostalCode()
    this.google = gmapApi()
    this.centerMapOnPostalCode()
  },

  methods: {
    updatePostalCode () {
      healthcenterSearchResource()
        .read({ postalCode: [this.postalCodeMutable] })
        .then(healthcenters => {
          this.healthcenters = healthcenters.hits.hits.map(h => h._source)
          this.centerMapOnPostalCode()
        })
    },

    centerMapOnPostalCode () {
      if (this.google) {
        var geocoder = new this.google.maps.Geocoder()
        geocoder.geocode({
          componentRestrictions: {
            country: 'FR',
            postalCode: this.postalCodeMutable
          }
        }, (results, status) => {
          if (status === this.google.maps.GeocoderStatus.OK) {
            var bounds = new this.google.maps.LatLngBounds()
            this.healthcenters.forEach(hc => {
              bounds.extend({
                lat: hc.geo.location.lat,
                lng: hc.geo.location.lon
              })
            })
            this.$refs.mapRef.fitBounds(bounds)
          }
        })
      } else {
        this.google = gmapApi() // reload gmap ()
        setTimeout(this.centerMapOnPostalCode, 1000)
      }
    },

    validateHealthcenter (healthcenter) {
      this.$emit('validate', healthcenter)
    },

    centerOnAddress (address) {
      this.geocoder.geocode({ address }, (results, status) => {
        if (status === this.google.maps.GeocoderStatus.OK) {
          this.center = results[0].geometry.location
        } else {
          alert('Geocode was not successful for the following reason: ' + status)
        }
      })
    },
    debounceFormatZipCode (timeout) {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.formatZipcode()
      }, timeout)
    },
    formatZipcode () {
      this.postalCodeMutable = formatHelper.formatZipCode(this.postalCodeMutable)
    }
  }
}
</script>
