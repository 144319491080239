import axios from 'axios'
import requestInterceptor from '@/renderer/http/interceptors/requestInterceptor'
import responseInterceptor from '@/renderer/http/interceptors/responseInterceptor'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

apiClient.interceptors.request.use(
  requestInterceptor.success
)

apiClient.interceptors.response.use(
  responseInterceptor.success,
  responseInterceptor.failure
)

export default apiClient
