/**
 * Enum for order status.
 */
const OrderStatus = Object.freeze({
  DELIVERED: 'delivered',
  AVAILABLE: 'available',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  PENDING: 'pending'
})

export default OrderStatus
