<template>
  <div
    class="flex flex-col cursor-pointer"
    @click="navigateToBuiltInstance"
  >
    <div class="text-xs">
      {{ formatHelper.readableDate(instance.created_at) }}
    </div>
    <base-card class="flex flex-1">
      <base-card-body>
        <div class="flex items-start">
          <img
            alt="Survey"
            width="30"
            :src="require('@/assets/images/icons/survey.svg')"
            class="cursor-pointer mr-5"
          >
          <div>
            <strong>{{ instance.survey.name }}</strong>
            <div
              v-if="instance.verdict"
              class="flex flex-row items-start mt-2"
            >
              <img
                alt="Verdict"
                :src="require('@/assets/images/verdict-chevrons.png')"
                class="mr-3 w-8 object-contain mt-1"
              >
              <p class="text-sm">
                {{ instance.verdict.title }}
              </p>
            </div>
          </div>
        </div>
      </base-card-body>
    </base-card>
  </div>
</template>

<script>
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import UserIcon from '@/renderer/components/icons/User.vue'
import ProgramTeaser from '@/renderer/app/program/components/program-teaser/ProgramTeaser.vue'
import ProgramLogo from '@/renderer/app/program/components/program-logo/ProgramLogo.vue'
import formatHelper from '../../../../helpers/formatHelper'
import Icon from '@/renderer/app/core/icon/Icon.vue'
import BaseCardBody from '@/renderer/components/base/BaseCardBody.vue'

export default {
  name: 'CustomerSurveyCard',
  components: { BaseCardBody, Icon, ProgramLogo, ProgramTeaser, UserIcon, BaseCard },
  props: {
    instance: {
      type: Object,
      required: true
    }
  },
  computed: {
    formatHelper () {
      return formatHelper
    }
  },
  methods: {
    navigateToBuiltInstance () {
      this.$router.push({
        name: 'customer.survey',
        params: { instanceId: this.instance.id, verdict: this.instance.verdict }
      })
    }
  }
}
</script>
