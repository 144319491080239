<template>
  <div>
    <base-spinner v-if="loading" />
    <div
      v-else
      class="mx-6"
    >
      <div v-if="orders.length">
        <base-card
          v-for="order in orders"
          :key="order.id"
          class="relative overflow-visible my-5 cursor-pointer"
        >
          <base-card-body>
            <div
              class="flex flex-row"
              @click="setOrderId(order.id)"
            >
              <div
                v-if="order.content_type !== OrderContentType.CART"
                class="w-24 h-24"
              >
                <secure-image
                  :urls="order.content.prescription.files"
                  :size="24"
                />
              </div>
              <div
                v-else
                class="w-24 h-24"
              >
                <img
                  :src="require('@/assets/images/icons/cart-bubble.svg')"
                  class="h-24 w-24"
                >
              </div>
              <div class="flex justify-between align-center ml-4">
                <div>
                  <p>{{ order.content_type === 'cart' ? 'Click & Collect' : 'Ordonnance' }}</p>
                  <p class="text-sm">
                    {{ subtitle(order) }}
                  </p>
                  <v-chip
                    class="mt-2"
                    :color="OrderService.statusColor(order)"
                    small
                  >
                    <p class="text-sm">
                      {{ OrderService.statusLabel(order) }}
                    </p>
                  </v-chip>
                </div>
              </div>
            </div>
          </base-card-body>
        </base-card>
        <div class="text-center mb-4">
          <v-pagination
            v-model="page"
            :length="totalPage"
            :total-visible="7"
            circle
          />
        </div>
        <action-modal
          :order-id="orderId"
          :opened="showModal"
          @closeModal="closeModal"
        />
      </div>
      <div
        v-else
        class="my-4 p-4 bg-white rounded-lg"
      >
        <span>Ce patient n’a passé aucune commande Click & collect.</span>
      </div>
    </div>
  </div>
</template>

<script>

import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import { orderApi } from '@/renderer/container'
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import dateHelper from '../../../../helpers/dateHelper'
import BaseCardBody from '@/renderer/components/base/BaseCardBody.vue'
import OrderService from '../../../order/services/OrderService'
import SecureImage from '@/renderer/app/core/components/secure-image/SecureImage.vue'
import { OrderContentType } from '../../../../enum'
import dayjs from 'dayjs'
import ActionModal from '@/renderer/app/order/components/ActionModal.vue'

export default {
  name: 'CustomerClickCollect',
  components: {
    ActionModal,
    SecureImage,
    BaseCardBody,
    BaseCard,
    BaseSpinner
  },
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      orders: [],
      loading: false,
      showModal: false,
      orderId: null,
      page: 1,
      totalPage: 1
    }
  },
  computed: {
    dayjs () {
      return dayjs
    },
    OrderContentType () {
      return OrderContentType
    },
    OrderService () {
      return OrderService
    },
    dateHelper () {
      return dateHelper
    }
  },
  watch: {
    page: {
      immediate: true,

      handler () {
        this.loading = true
        this.loadClickCollect(this.customer.id)
          .finally(() => {
            this.loading = false
          })
      }
    }
  },
  methods: {
    /**
     * Load the patient orders.
     *
     * @param consumerId
     * @return {Promise<unknown>}
     */
    loadClickCollect (consumerId) {
      return orderApi().patientIndex(consumerId, { page: this.page })
        .then((response) => {
          this.orders = response.data
          this.totalPage = response.meta.last_page
        })
    },
    /**
     * Subtitle of the order card.
     *
     * @param order
     * @return {string}
     */
    subtitle (order) {
      const orderDate = `Le ${dayjs(order.created_at).format('DD/MM/YYYY')}`

      if (order.content_type !== OrderContentType.CART) {
        let subtitle = orderDate + ' - Ordonnance'
        if (order.content.prescription?.professional?.last_name) {
          subtitle += ` du DR. ${order.content.prescription?.professional?.last_name}`
        }
        if (order.content_type !== OrderContentType.RENEWAL) {
          subtitle += ' envoyée pour renouvellement'
        }

        return subtitle
      }

      const count = order.summary?.product_count

      return count === 0 || count ? orderDate + ` - ${count} produits` : orderDate
    },
    /**
     * Close the modal and reset order id.
     */
    closeModal () {
      orderApi().read(this.orderId)
        .then(response => {
          const index = this.orders.findIndex(order => order.id === this.orderId)

          if (index !== -1) {
            this.orders[index] = response
          }
        })
        .finally(() => {
          this.orderId = null
          this.showModal = false
        })
    },
    /**
     * Open the modal and set order id.
     *
     * @param orderId
     */
    setOrderId (orderId) {
      this.orderId = orderId
      this.showModal = true
    }
  }
}
</script>
