<template>
  <div
    class="flex w-full"
    style="height: 600px"
  >
    <gmap-map
      ref="mapRef"
      :class="{
        'filter grayscale': loadingData
      }"
      class="disruption-logistic-map"
      :center="center"
      :zoom="12"
      :options="mapOptions"
      style="width: 100%; height: inherit;"
    >
      <slot />
    </gmap-map>
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'

export default {
  name: 'DisruptionLogisticsMap',
  props: {
    loadingData: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      center: { lat: 48.856613, lng: 2.352222 },
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        clickableIcons: false,
        disableDefaultUI: true,
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }]
          }
        ]
      }
    }
  },
  computed: {
    /**
     * Get the current user entity address
     *
     * @return {Address}
     */
    entityAddress () {
      return this.$store.state.system.environment?.entity?.address
    },
    /**
     * Get the google maps api.
     *
     * @return {Object|null}
     */
    google () {
      return gmapApi()
    }
  },
  mounted () {
    if (this.entityAddress && this.entityAddress.latitude && this.entityAddress.longitude) {
      this.center = {
        lat: this.entityAddress.latitude,
        lng: this.entityAddress.longitude
      }
    } else {
      this.center = { lat: 48.856613, lng: 2.352222 }
    }
  },
  methods: {
    /**
     * Center the map on the entity address if available
     *
     * @param {{lat: number; lng: number}[]} bounds
     * @param padding
     */
    fitMapToBounds (bounds, padding = null) {
      if (this.$refs.mapRef?.$mapObject && this.google) {
        const map = this.$refs.mapRef.$mapObject
        const google = this.google
        const boundsObj = new google.maps.LatLngBounds()
        bounds.filter((bound) => bound.lat && bound.lng)
          .forEach(bound => boundsObj.extend(bound))

        return map.fitBounds(boundsObj, padding)
      }
    }
  }
}
</script>

<style>
.disruption-logistic-map .gm-style-iw-chr span {
  margin: 0 !important;
}

.disruption-logistic-map .gm-style-iw-chr button {
  width: 35px !important;
  height: 30px !important;
}
</style>
