<template>
  <div class="mt-2">
    <BaseAutoComplete :placeholder="'Produits, laboratoires, catégories'" />
    <div class="grids gap-2">
      <BaseSelect :options="wholesalers" />
      <BaseSelect :options="months" />
    </div>
    <div class="mt-2">
      <ul class="space-y-3">
        <li class="bg-white shadow overflow-hidden rounded-md px-3 py-2">
          <table class="min-w-full">
            <thead>
              <tr class="text-left text-xs text-gray-500 tracking-wider">
                <th>Laboratoire</th>
                <th>Taux de disponibilité</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Zentiva</td>
                <td>99%</td>
              </tr>
            </tbody>
          </table>
        </li>
        <li class="bg-white shadow overflow-hidden rounded-md px-3 py-2">
          <table class="min-w-full">
            <thead>
              <tr class="text-left text-xs text-gray-500 tracking-wider">
                <th>Laboratoire</th>
                <th>Taux de disponibilité</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Zentiva</td>
                <td>99%</td>
              </tr>
            </tbody>
          </table>
        </li>
        <li class="bg-white shadow overflow-hidden rounded-md px-3 py-2">
          <table class="min-w-full">
            <thead>
              <tr class="text-left text-xs text-gray-500 tracking-wider">
                <th>Laboratoire</th>
                <th>Taux de disponibilité</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Zentiva</td>
                <td>99%</td>
              </tr>
            </tbody>
          </table>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import BaseSelect from '@/renderer/components/base/BaseSelect.vue'
import BaseAutoComplete from '@/renderer/components/base/BaseAutoComplete.vue'

export default {
  components: {
    BaseSelect,
    BaseAutoComplete
  },

  data () {
    return {
      months: [],
      wholesalers: []
    }
  },

  mounted () {
    this.wholesalers = [
      {
        id: 1,
        title: 'Sagitta Pharma'
      }
    ]

    this.months = [
      {
        id: 1,
        title: 'Janvier'
      }
    ]
  }
}
</script>
