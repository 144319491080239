/**
 * The sellout teaser service.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} dayjs - The injected dayjs.
 * @returns {Object} The public object.
 */
export default function (dayjs) {
  return {
    /**
     * Generate a unit reward teaser.
     * @param {number} gain - The gain amount.
     * @param {Object} remuneration - The remuneration object.
     * @returns {string} The generated teaser.
     */
    generateUnitTeaser (gain, remuneration) {
      return 'Gagnez ' + gain + ' ' + remuneration.label
    },

    /**
     * Generate an objective reward teaser.
     * @param {number} gain - The gain amount.
     * @param {Object} remuneration - The remuneration object.
     * @param {number} goal - The goal amount.
     * @param {Object} objective - The objective object.
     * @param {string} startDate - The objective start date.
     * @param {string} endDate - The objective end date.
     * @param {Object} mode - The objective mode.
     * @returns {string} teaser - The generated teaser.
     */
    generateObjectiveTeaser (
      gain,
      remuneration,
      goal,
      objective,
      startDate,
      endDate,
      mode
    ) {
      let teaser = ''

      teaser += 'Gagnez ' + gain + ' ' + remuneration.label
      teaser += ' dès'
      teaser += ' ' + goal
      teaser += ' '
      teaser += objective.label

      if (mode) {
        switch (mode.id) {
          case 1:
            teaser += ' par l’ensemble des pharmacies de votre groupement'
            break

          case 2:
            teaser += ' par votre pharmacie'
            break
        }
      }

      if (objective.isGrowth) {
        teaser += ' sur une période de référence'
        teaser += ' du '
        teaser += dayjs(startDate).format('DD/MM/YYYY')
        teaser += ' au '
        teaser += dayjs(endDate).format('DD/MM/YYYY')
      }

      return teaser
    }
  }
}
