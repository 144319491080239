<template>
  <div>
    <div
      v-for="section in survey.sections"
      :key="`section-${section.id}`"
    >
      <h2 class="text-lg font-bold mb-4">
        {{ section.title }}
      </h2>
      <base-container class="gap-y-6">
        <div v-for="question in section.questions">
          <div class="flex flex-col">
            <p>
              {{ question.title }}
            </p>
            <p
              v-if="!!errors[question.id]"
              class="text-sm text-red-500"
            >
              {{ errors[question.id] }}
            </p>
          </div>
          <label
            v-if="question.description?.length"
            class="text-sm mt-1"
          >
            {{ question.description }}
          </label>
          <survey-built-answer
            :question="question"
            :survey-id="survey.id"
            :editable="true"
            :value="getAnswerValue(question.id)"
            class="mt-4"
            @change="(change) => onChange(change, question.id)"
          />
          <p
            v-if="getReasonForQuestion(question.id)"
            class="mt-3 italic text-sm flex items-center"
          >
            <icon
              name="info-circle"
              class="mr-2"
            />
            {{ getReasonForQuestion(question.id) }}
          </p>
          <v-divider class="mt-4" />
        </div>
      </base-container>
    </div>
  </div>
</template>

<script>
import SurveyBuiltAnswer from '@/renderer/app/survey/components/SurveyBuiltAnswer.vue'
import BaseContainer from '@/renderer/components/base/BaseContainer.vue'
import { createNamespacedHelpers } from 'vuex'
import Icon from '@/renderer/app/core/icon/Icon.vue'

const { mapState } = createNamespacedHelpers('interview')

export default {
  name: 'InterviewSurveyBuilt',
  components: { Icon, BaseContainer, SurveyBuiltAnswer },
  props: {
    survey: {
      type: Object,
      required: true
    },
    answers: {
      type: Object,
      default: {}
    },
    errors: {
      type: Object,
      default: {}
    }
  },
  methods: {
    /**
     * Update the answer value.
     *
     * @param value
     * @param questionId
     */
    onChange (value, questionId) {
      this.$emit('change', { value, questionId })
    },
    /**
     * Get the answer value for a question.
     *
     * @param {Number} questionId The question id.
     *
     * @return {Array}
     */
    getAnswerValue (questionId) {
      return this.answers[questionId] || []
    },
    /**
     * Get the LLM answer reason for a question.
     *
     * @param {Number} questionId The question id.
     *
     * @returns {string|null}
     */
    getReasonForQuestion (questionId) {
      if (!this.llmAnswerReasons) {
        return null
      }

      return this.llmAnswerReasons[questionId] ?? null
    }
  },
  computed: {
    ...mapState({
      llmAnswerReasons: state => state.llmSurveyAnswers?.reduce((acc, answer) => {
        acc[answer.question_id] = answer.reason ?? null

        return acc
      }, {})
    })
  }
}
</script>

<style scoped>

</style>
