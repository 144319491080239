<template>
  <div class="flex flex-col flex-1">
    <div class="text-sm">
      {{ data.label }}
    </div>
    <div>
      <Pie
        :id="data.label"
        :chart-data="chartData"
        :chart-options="chartOptions"
      />
    </div>
  </div>
</template>

<script>
import ShopBagIcon from '@/assets/images/icons-vue/shopbag-solid.vue'
import UserIcon from '@/assets/images/icons-vue/user-solid.vue'
import { Pie } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js'
import { maxHeight } from 'tailwindcss/lib/plugins'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)

export default {
  name: 'ProgramPieChartStatisticsCard',
  components: {
    ShopBagIcon,
    UserIcon,
    Pie
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    chartData () {
      return {
        labels: this.data.value.map(item => item.name),
        datasets: [{
          backgroundColor: [
            '#41b8a6',
            '#E46651',
            '#00D8FF',
            '#DD1B16',
            '#7741b8',
            '#e451d0',
            '#457031',
            '#1637dd',
            '#c9a779',
            '#3e80c2',
            '#621a1a',
            '#62ce3e'
          ],
          data: this.data.value.map(item => item.count)
        }]
      }
    },
    chartOptions () {
      return {
        responsive: true,
        elements:
          {
            arc: {
              borderWidth: 0
            }
          },
        plugins: {
          legend: {
            position: 'right',
            labels: {
              padding: 4,
              font: {
                size: 9
              },
              boxWidth: 8
            }
          }
        }
      }
    }
  },
  methods: {
    maxHeight () {
      return maxHeight
    }
  }
}
</script>
