<template>
  <div class="w-full">
    <component
      :is="answerComponent"
      :question="question"
      :survey-id="surveyId"
      :editable="editable"
      :value="value"
      @onPress=""
      @change="(data) => $emit('change', data)"
    />
  </div>
</template>

<script>
import { SurveyQuestionTypeSlug } from '@/renderer/enum'
import SurveyBuiltChoices from '@/renderer/app/survey/components/SurveyBuiltChoices.vue'
import SurveyBuiltInput from '@/renderer/app/survey/components/SurveyBuiltInput.vue'
import SurveyBuiltDate from '@/renderer/app/survey/components/SurveyBuiltDate.vue'

export default {
  name: 'SurveyBuiltAnswer',
  components: {
    SurveyBuiltChoices,
    SurveyBuiltInput,
    SurveyBuiltDate
  },
  props: {
    surveyId: {
      type: Number,
      required: true
    },
    question: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array],
      default: null
    }
  },
  computed: {
    /**
     * Return the answer content.
     *
     * @returns {string}
     */
    answerComponent () {
      switch (this.question.type.slug) {
        case SurveyQuestionTypeSlug.YES_NO:
        case SurveyQuestionTypeSlug.SINGLE_CHOICE:
        case SurveyQuestionTypeSlug.MULTIPLE_CHOICE:
          return 'SurveyBuiltChoices'
        case SurveyQuestionTypeSlug.TEXT_INPUT:
          return 'SurveyBuiltInput'
        case SurveyQuestionTypeSlug.DATE:
          return 'SurveyBuiltDate'
        default:
          return 'SurveyBuiltChoices'
      }
    }
  }
}
</script>
