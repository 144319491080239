<template>
  <form
    novalidate
    @submit.prevent="handleSubmit"
  >
    <div class="flex flex-col gap-4">
      <base-input
        :value="formData.name"
        type="text"
        label="Nom du groupe"
        :errors="errors.name ? [errors.name] : []"
        @input="(value) => formData.name = value"
      />
      <base-input
        :value="formData.description"
        type="textarea"
        label="Description (sera affichée aux membres du groupe)"
        :errors="errors.description ? [errors.description] : []"
        @input="(value) => formData.description = value"
      />
      <base-button
        :disabled="loading"
        type="submit"
        class="font-bold flex self-center"
        primary
      >
        <div class="flex gap-2 items-center">
          <span>{{ editMode ? 'Modifier' : 'Créer' }}</span>
          <div>
            <base-spinner
              v-if="loading"
              size="button"
              color="white"
            />
          </div>
        </div>
      </base-button>
    </div>
  </form>
</template>

<script>
import BaseInput from '@/renderer/components/base/BaseInput.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'

export default {
  name: 'CreateHealthcenterGroupForm',
  components: { BaseSpinner, BaseButton, BaseInput },
  props: {
    name: {
      type: String,
      required: false,
      default: ''
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      formData: {
        name: this.name,
        description: this.description
      },
      errors: {
        name: '',
        description: ''
      }
    }
  },
  methods: {
    /**
     * Validate the form and submit.
     */
    handleSubmit () {
      const isValid = this.validateForm()

      if (!isValid) {
        return
      }

      const data = {
        name: this.formData.name,
        description: this.formData.description
      }

      this.$emit('submit', data)
    },
    /**
     * Validate the form.
     *
     * @return {boolean}
     */
    validateForm () {
      this.errors = {
        name: '',
        description: ''
      }

      if (this.nullOrEmpty(this.formData.name)) {
        this.errors.name = 'Le nom du groupe est requis'
      }

      if (this.nullOrEmpty(this.formData.description)) {
        this.errors.description = 'La description du groupe est requise'
      }

      return Object.values(this.errors).every(error => {
        if (Array.isArray(error)) {
          return error.every(err => this.nullOrEmpty(err))
        }

        return !Array.isArray(error) && this.nullOrEmpty(error)
      })
    },
    /**
     * Check if the value is null or empty.
     *
     * @param {String} value
     * @return {boolean}
     */
    nullOrEmpty (value) {
      return value === null || value.trim() === ''
    }
  }
}
</script>
