<template>
  <span class="rounded-full inline-flex p-3 bg-green-50 text-green-700 ring-4 ring-white">
    <svg
      width="512"
      height="512"
      viewBox="0 0 510 510"
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 510 510"
      :class="`fill-current h-${size} w-${size}`"
    >
      <path
        d="m454.191 124h-8.191v-31.724c0-17.797-14.479-32.276-32.276-32.276h-37.447c-17.798 0-32.277 14.479-32.277
      32.276v31.724h-98v-73.425c0-16.859-13.716-30.575-30.574-30.575h-96.852c-16.858 0-30.574 13.716-30.574
      30.575v73.425h-32.191c-30.774 0-55.809 25.036-55.809 55.809v254.383c0 30.772 25.035 55.808 55.809
      55.808h398.383c30.773 0 55.808-25.036 55.808-55.809 0-27.065 0-226.893 0-254.383
      0-30.772-25.035-55.808-55.809-55.808zm-80.191-31.724c0-1.255 1.021-2.276 2.276-2.276h37.447c1.255
      0 2.276 1.021 2.276 2.276v31.724h-42v-31.724zm-256-41.701h98v73.425h-98zm-88 231.425h36.651c-3.532
      16.453-3.538 33.521 0 50h-36.651zm450 152.191c0 14.231-11.578 25.809-25.809 25.809h-398.382c-14.231
      0-25.809-11.578-25.809-25.809v-72.191h47.499c44.621 85.245 166.392 85.227 211.003 0h191.498zm-386-127.191c0-49.075
      39.925-89 89-89s89 39.925 89 89-39.925 89-89 89-89-39.925-89-89zm386 25h-180.651c3.532-16.453 3.538-33.521
      0-50h180.651zm0-80h-191.499c-44.621-85.246-166.392-85.227-211.003 0h-47.498v-72.191c0-14.231 11.578-25.809
      25.809-25.809h398.383c14.23 0 25.809 11.578 25.809 25.809v72.191z"
      />

      <path d="m431 188h-56c-8.284 0-15 6.716-15 15s6.716 15 15 15h56c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />

      <path
        d="m183 362c30.327 0 55-24.673 55-55s-24.673-55-55-55-55 24.673-55 55 24.673 55 55 55zm0-80c13.785 0 25
      11.215 25 25s-11.215 25-25 25-25-11.215-25-25 11.215-25 25-25z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      required: true
    }
  }
}
</script>
