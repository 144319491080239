<template>
  <div>
    <header-back-button title="Vos agences" />
    <div class="p-4 flex flex-col gap-3">
      <div>
        <h1 class="text-xl font-content-bold">
          Vos agences
        </h1>
        <p>
          Sélectionnez vos agences #1 et #2 et validez vos accès PharmaML
        </p>
      </div>
      <div
        v-if="!loading"
        class="flex gap-3"
      >
        <select-wholesaler-expansion-panel
          class="flex-1"
          :rank="1"
          :loading="loading"
          :warehouses="warehouses"
          :healthcenter-warehouse="firstWarehouse"
          :healthcenter-warehouses="healthcenterWarehouses"
          :refreshing="refreshing['0']"
          @save="saveHealthcenterWarehouse"
        />
        <select-wholesaler-expansion-panel
          class="flex-1"
          :rank="2"
          :loading="loading"
          :warehouses="warehouses"
          :healthcenter-warehouse="secondWarehouse"
          :healthcenter-warehouses="healthcenterWarehouses"
          :refreshing="refreshing['1']"
          @save="saveHealthcenterWarehouse"
        />
      </div>
      <div v-else>
        <base-spinner />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBackButton from '@/renderer/app/core/components/header-back-button/HeaderBackButton.vue'
import { wholesalerApi } from '@/renderer/container'
import ToastService from '@/renderer/services/ToastService'
import SelectWholesalerExpansionPanel
from '@/renderer/app/disruption-logistics/components/select-wholesaler/SelectWholesalerExpansionPanel.vue'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'

export default {
  name: 'WholesalerWarehouseHealthcenterPage',
  components: { BaseSpinner, SelectWholesalerExpansionPanel, HeaderBackButton },
  data () {
    return {
      warehouses: null,
      healthcenterWarehouses: null,
      loading: true,
      refreshing: {
        0: false,
        1: false
      }
    }
  },
  computed: {
    firstWarehouse () {
      if (!this.healthcenterWarehouses) {
        return null
      }

      return this.healthcenterWarehouses.find(warehouse => warehouse.rank === 1)
    },

    secondWarehouse () {
      if (!this.healthcenterWarehouses) {
        return null
      }

      return this.healthcenterWarehouses.find(warehouse => warehouse.rank === 2)
    }
  },
  mounted () {
    this.loading = true
    Promise.all([
      this.fetchWholesalerWarehouses(),
      this.fetchHealthcenterWarehouses()
    ])
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    /**
     * Fetch the wholesaler warehouses
     */
    fetchWholesalerWarehouses () {
      return wholesalerApi()
        .getWholesalerWarehouses({ include: 'wholesaler' })
        .then((wholesalerWarehouses) => {
          this.warehouses = wholesalerWarehouses
        })
    },
    /**
     * Fetch the healthcenter warehouses
     *
     * @return {Promise<void>}
     */
    fetchHealthcenterWarehouses () {
      return wholesalerApi()
        .getCurrentHealthcenterWarehouses({ include: 'wholesaler' })
        .then((healthcenterWarehouses) => {
          this.healthcenterWarehouses = this.sortHealthcenterWarehouses(healthcenterWarehouses)
        })
    },
    /**
     * Sort the healthcenter warehouses by rank
     */
    sortHealthcenterWarehouses (healthcenterWarehouses) {
      return healthcenterWarehouses.sort((a, b) => {
        return (a.rank || Infinity) - (b.rank || Infinity)
      })
    },

    /**
     * Save the healthcenter warehouse
     *
     * @param healthcenterWarehouse
     * @param wholesalerWarehouse
     * @param rank
     * @return {Promise<void>}
     */
    saveHealthcenterWarehouse ({ healthcenterWarehouseId, warehouseId, wholesalerId, rank }) {
      this.refreshing[rank - 1] = true
      return wholesalerApi()
        .patchHealthcenterWarehouse(
          healthcenterWarehouseId,
          wholesalerId,
          warehouseId,
          rank
        )
        .then(() => {
          ToastService().success('Les agences principales ont été enregistrées avec succès')

          return this.fetchHealthcenterWarehouses()
        })
        .finally(() => {
          this.refreshing[rank - 1] = false
        })
    }
  }
}
</script>

<style scoped>

</style>
