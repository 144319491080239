<template>
  <div
    v-if="visible"
    class="modal-wrapper gradient flex-col flex flex-1 h-full w-full"
  >
    <div
      v-if="total"
      class="p-5 flex flex-row"
    >
      <div class="modal-header">
        <span>
          <span class="font-weight-bold">{{ title }}</span>
          <span v-if="!surveyVerdict"> - {{ current }}/{{ total }}</span>
          <span v-else> - résultat</span>
        </span>
        <CloseIcon @click.native="dismiss" />
      </div>
    </div>
    <div
      v-if="!loading"
      class="p-5 flex flex-row flex-1 justify-center"
    >
      <div
        v-if="!surveyDone"
        class="flex flex-col flex-1 mb-24"
      >
        <SurveyQuestion
          v-if="section"
          :question="section.questions[0]"
          :title="section.title"
          @onPress="answer"
        />
      </div>
      <div
        v-else-if="surveyDone && showVerdict"
        class="flex flex-col flex-1 mb-24 align-center"
      >
        <SurveyVerdict
          v-if="type === 'survey'"
          :survey-verdict="surveyVerdict"
        />
        <div
          v-for="surveyBundleVerdict in surveyBundleVerdicts"
          v-else
          :key="surveyBundleVerdict.id"
        >
          <SurveyVerdict :survey-verdict="surveyBundleVerdict" />
        </div>
        <BaseButton
          class="w-64 justify-center font-content-bold text-primary"
          @click="close"
        >
          Revenir au patient
        </BaseButton>
      </div>
    </div>
    <div
      v-else
      class="p-5 flex flex-row flex-1 align-center justify-center"
    >
      <v-progress-circular
        :size="100"
        :width="15"
        :value="progress"
        color="white"
      >
        {{ progress }}%
      </v-progress-circular>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/assets/images/icons-vue/close-solid.vue'
import SurveyModal from '@/renderer/plugins/survey-modal'
import { pathwayApi, surveyApi, surveyBundleApi } from '@/renderer/container'
import SurveyQuestion from '@/renderer/app/survey/components/SurveyQuestion.vue'
import SurveyVerdict from '@/renderer/app/survey/components/SurveyVerdict.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'

/**
 * Survey modal component.
 */
export default {
  components: {
    BaseButton,
    SurveyVerdict,
    SurveyQuestion,
    CloseIcon
  },
  data () {
    return {
      /** The loading state. */
      loading: true,
      /** The progress value. */
      progress: 0,
      /** The current question. */
      current: 1,
      /** The total number of questions. */
      total: null,
      /** The answered section. */
      section: null,
      /** The modal visibility. */
      visible: false,
      /** The survey title. */
      title: '',
      /** The consumer key for whom the answer is. */
      consumerKey: null,
      /** The answered survey id. */
      surveyId: null,
      /** The answered survey instance id. */
      instanceId: null,
      /**
       *  The pathway step id linked to the survey if any.
       *  If this value is set, it'll try to store the consumer step after the survey is done.
       */
      pathwayStepId: null,
      /** The support program id linked to the survey if any. */
      supportProgramId: null,
      /** The callback to execute when the survey is done. */
      onClose: {},
      /** The survey verdict. */
      surveyVerdict: null,
      /** The survey verdicts. */
      surveyBundleVerdicts: null,
      /** Whether the survey is done. */
      surveyDone: false,
      /** Whether the verdict is displayed. */
      showVerdict: false,
      /** Determine if the verdict should be displayed. */
      shouldShowVerdict: false,
      /** Type of survey. Either survey or survey-bundle. */
      type: null
    }
  },
  beforeCreate () {
    SurveyModal.EventBus.$off('start')
    SurveyModal.EventBus.$on('start', (params) => this.start(params))
  },
  methods: {
    /**
     * Show the modal.
     * @param {Object} params The parameters.
     */
    start (params) {
      if (this.visible) {
        return
      }

      this.section = null
      this.surveyVerdict = null
      this.visible = true
      this.consumerKey = params.consumerKey
      this.surveyId = params.surveyId
      this.pathwayStepId = params.pathwayStepId ?? null
      this.supportProgramId = params.supportProgramId ?? null
      this.onClose = params.onClose ?? null
      this.shouldShowVerdict = params.showVerdict ?? false
      this.surveyDone = false
      this.type = params.type ?? 'survey'

      this.showLoader()

      const method = this.type === 'survey-bundle' ? surveyBundleApi() : surveyApi()

      return method.createInstance(this.surveyId, this.consumerKey)
        .then((instance) => {
          if (this.type === 'survey-bundle') {
            this.title = instance.bundle.name
          } else {
            this.title = instance.survey.name
          }
          this.instanceId = instance.id

          return this.loadFirstQuestion(this.surveyId, this.instanceId)
        })
        .finally(this.hideLoader)
    },
    /**
     * Get the next question to display in the survey, if there is none we go back to the survey entry point.
     *
     * @param surveyId Survey id.
     * @param instanceId Survey instance id.
     */
    loadFirstQuestion (surveyId, instanceId) {
      const method = this.type === 'survey-bundle' ? surveyBundleApi() : surveyApi()

      return method.next(surveyId, instanceId)
        .then(this.setQuestion)
    },
    /**
     * Display next question or verdict depending on the api response.
     * @param response
     */
    setQuestion (response) {
      if (response.data.data) {
        this.section = response.data.data
        this.current = response.data.meta.current
        this.total = response.data.meta.total
        return Promise.resolve(true)
      } else if (response.status === 204) {
        return Promise.resolve(false)
      }
    },
    /**
     * Answer a survey question.
     *
     * @param answer The answer to register.
     */
    answer (answer) {
      this.showLoader()
      const method = this.type === 'survey-bundle' ? surveyBundleApi() : surveyApi()

      return method.answer(
        this.surveyId,
        this.instanceId,
        [answer]
      )
        .then(this.setQuestion)
        .then((hasNextQuestion) => {
          if (hasNextQuestion) {
            return Promise.resolve()
          } else {
            // TODO: Ajouter une colonne en bdd pour déterminer l'affichage du verdict pour chaque programme.
            return this.goToSurveyVerdict(this.shouldShowVerdict)
          }
        })
        .finally(this.hideLoader)
    },
    /**
     * Get the survey verdict of the current survey instance.
     */
    verdict () {
      const method = this.type === 'survey-bundle' ? surveyBundleApi() : surveyApi()

      return method.verdict(this.surveyId, this.instanceId)
        .then(response => {
          if (this.type === 'survey-bundle') {
            this.surveyBundleVerdicts = response.data
          } else {
            this.surveyVerdict = response.data
          }
        })
    },
    /**
     * Determine the action that should be done when the survey is completed.
     * Either close the survey modal or display the survey result.
     *
     * @param {boolean} showVerdict If the survey verdict should be displayed.
     */
    goToSurveyVerdict (showVerdict) {
      this.surveyDone = true
      if (showVerdict) {
        this.verdict()
        this.showVerdict = true

        return Promise.resolve()
      } else {
        return this.close()
      }
    },
    /**
     * Store the pathway step if a pathway step id is set and the survey is done.
     *
     * @returns {Promise<any>|Promise<void>}
     */
    storePathwayStep () {
      if (this.pathwayStepId && this.surveyDone) {
        const steppableType = this.type === 'survey-bundle' ? 'survey-bundle-instance' : 'survey-answer'

        return pathwayApi()
          .storeConsumerStep(this.pathwayStepId, this.consumerKey, this.instanceId, steppableType)
          .then(() => this.$store.dispatch('patient/loadPathway', {
            supportProgramId: this.supportProgramId,
            consumerKey: this.consumerKey
          }))
      }

      return Promise.resolve()
    },
    /**
     * Close the modal and execute callback if set.
     */
    close () {
      return this.storePathwayStep()
        .then(() => {
          if (typeof this.onClose === 'function') {
            return this.onClose()
          } else {
            return Promise.resolve()
          }
        })
        .finally(() => {
          this.visible = false
        })
    },
    /**
     * Dismiss the survey modal.
     */
    dismiss () {
      this.visible = false
    },
    /**
     * Show the loader.
     *
     * @param value
     */
    showLoader (value = 100) {
      this.loading = true
      this.progress = 0
      setTimeout(() => {
        this.progress = value
      }, 100)
      const interval = setInterval(() => {
        if (this.progress < 100) {
          this.progress += 25
        } else {
          this.progress = 100
          clearInterval(interval)
        }
      }, 1000)
    },
    hideLoader () {
      this.loading = false
    }
  }
}
</script>

<style scoped>
.modal-wrapper {
  position: fixed;
  z-index: 1000;
}

.modal-header {
  font-size: 1.2rem;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}
</style>
