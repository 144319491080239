/**
 * The customer resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Get all customers.
     *
     * @param {{ page?: number, certified?: boolean }} params The params used to get customers list.
     *
     * @returns {Promise} Promise object represents the HTTP request.
     */
    get (params = {}) {
      return client.get('/customers', { params })
        .then(response => response.data)
        .catch(() => [])
    },

    /**
     * Read a customer.
     * @param {number} id - The ID of the customer.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    read (id) {
      return client.get(`/customers/${id}`)
        .then(response => response.data.data)
        .catch(() => null)
    },

    /**
     * Search customers with keywords.
     * @param {string} keywords - Searching keywords.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    search (keywords) {
      return client
        .get('/customers?no-stats', {
          params: {
            limit: 50,
            search: keywords
          }
        })
        .then(response => response.data.data)
        .catch(() => [])
    }
  }
}
