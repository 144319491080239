import Modal from '@/renderer/app/survey/views/SurveyModal.vue'

const SurveyModal = {
  install (Vue, options) {
    this.EventBus = new Vue()

    Vue.component('survey-modal', Modal)

    Vue.prototype.$survey = {
      start (params) {
        SurveyModal.EventBus.$emit('start', params)
      }
    }
  }
}

export default SurveyModal
