<template>
  <interview-short-cut-container
    title="Interaction médicamenteuse"
    class="flex flex-1 flex-col"
    :style="'background-color: #ffffff'"
  >
    <drug-interaction-screen :consumer-key="consumerKey" />
  </interview-short-cut-container>
</template>

<script>

import { defineComponent } from 'vue'
import InterviewShortCutContainer
from '@/renderer/app/interview/components/instance-modal/short-cuts/InterviewShortCutContainer.vue'
import DrugInteractionScreen from '@/renderer/app/drug-interaction/screens/DrugInteractionScreen.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('interview')

export default defineComponent({
  name: 'InterviewDrugInteraction',
  components: { DrugInteractionScreen, InterviewShortCutContainer },
  computed: {
    ...mapState({
      consumerKey: state => state.consumerKey
    })
  }
})
</script>
