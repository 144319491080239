<template>
  <div>
    <div
      v-if="loyaltyCard.loyalty_card.file.url"
      class="flex align-center gap-4"
    >
      <img
        :src="`${loyaltyCard.loyalty_card.file.url}&h=75`"
        :alt="loyaltyCard.name"
        class="shadow-custom"
        style="height:75px; width:112.5px; border-radius:10px;"
      >
      <p
        class="text-md"
      >
        <loyalty-card-membership-point-balance
          class="text-sm"
          :formatted_point_balance="loyaltyCard.formatted.point_balance"
          :point_balance="loyaltyCard.point_balance"
          :rate="loyaltyCard.loyalty_card.point.rate"
        />
      </p>
    </div>
    <div
      v-else
      class="items-center justify-center flex bg-gray-200 shadow custom"
      style="height:50px; width:50px; border-radius:10px;"
    >
      {{ loyaltyCard.id }}
    </div>
  </div>
</template>

<script>
import LoyaltyCardMembershipPointBalance
from '@/renderer/app/loyalty-card-membership/components/loyalty-card-membership-point-balance/LoyaltyCardMembershipPointBalance.vue'

export default {
  name: 'NotificationLoyaltyCardSimple',
  components: { LoyaltyCardMembershipPointBalance },

  props: {
    loyaltyCard: {
      type: Object,
      required: true
    }
  },
  computed: {
    points () {
      return this.loyaltyCard.point_balance
    },
    units () {
      return 'points'
    }
  }
}
</script>
