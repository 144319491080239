<template>
  <div class="normal-case whitespace-pre-line leading-4">
    {{ teaser || 'Ce patient est ciblé par le programme ' + program.name }}
  </div>
</template>

<script>
export default {
  name: 'ProgramTeaserText',
  props: {
    /**
     * The program to get the teaser from.
     */
    program: {
      type: Object,
      required: true
    }
  },
  computed: {
    /**
     * Get the program next step
     *
     * @return {Object|null}
     */
    step () {
      return this.program.next_step
    },
    /**
     * Get the teaser from the program or the step
     *
     * @return {string}
     */
    teaser () {
      if (this.step) {
        return this.step.type === 'checkup' ? this.program.teaser : this.step.resolved.lead
      }

      return this.program.teaser
    }
  }
}
</script>
