<template>
  <div>
    <div
      v-for="parentRange in branding.parentRanges"
      :key="parentRange.id"
      class="mb-8"
    >
      <div class="pb-5 border-b border-gray-200">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{ parentRange.name }}
        </h3>
      </div>

      <ul
        role="list"
        class="grid grid-cols-1 gap-6 mt-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3"
      >
        <li
          v-for="childrenRange in parentRange.childrenRanges"
          :key="childrenRange.id"
          class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200 cursor-pointer"
          @click="navigate(childrenRange)"
        >
          <div class="flex-1 flex flex-col">
            <div class="border-b border-gray-200 h-36 overflow-hidden">
              <img
                v-if="childrenRange.branding.background"
                class="w-full flex-shrink-0 rounded-t-lg"
                :src="`${childrenRange.branding.background.renderPath}&w=350&h=200&fit=crop&sharp=10`"
                :alt="childrenRange.name"
              >
            </div>

            <div class="p-6 text-sm">
              {{ childrenRange.name }}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  laboratoryBrandingResource
} from '@/renderer/container'

export default {
  data () {
    return {
      branding: {
        parentRanges: []
      }
    }
  },

  mounted () {
    laboratoryBrandingResource()
      .read(this.$route.params.laboratoryId)
      .then(branding => {
        this.branding = branding
      })
  },

  methods: {
    navigate (range) {
      this.$router.push({
        name: 'brand.range.product.list',

        params: {
          laboratoryId: this.$route.params.laboratoryId,
          range
        }
      })
    }
  }
}
</script>
