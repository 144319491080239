import { experienceResource } from '@/renderer/container'

export default {
  namespaced: true,
  state: {
    events: []
  },
  getters: {
    /**
     * Get an event by name.
     */
    getEventByName: (state) => (name) => {
      return state.events.find(event => event.name === name)
    }
  },
  setters: {
    /**
     * Set the events.
     *
     * @param {Object} state - The state.
     * @param {Array} events - The events.
     */
    setEvents (state, events) {
      state.events = events
    }
  },
  mutations: {
    storeEvents (state, events) {
      state.events = events
    }
  },
  actions: {
    fetchEvents ({ commit }) {
      return experienceResource().indexEvents()
        .then(events => {
          commit('storeEvents', events)
          window.ipcRenderer.send('dispatch-experience-events', events)
        })
    }
  }
}
