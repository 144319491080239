<template>
  <transition name="slide">
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'SlideRightToLeft'
}
</script>

<style scoped>
.slide-enter-active, .slide-leave-active {
  transition: transform 0.5s ease;
}

.slide-enter, .slide-leave-to /* .slide-leave-active in <2.1.8 */
{
  transform: translateX(100%);
}
</style>
