<template>
  <div class="mt-5 grid grid-cols-1 gap-5 md:grid-cols-1">
    <div class="bg-white overflow-hidden shadow rounded-lg">
      <div class="p-5">
        <div class="flex items-center">
          <div class="flex-shrink-0">
            <svg
              fill="none"
              viewBox="0 0 24 24"
              aria-hidden="true"
              stroke="currentColor"
              class="h-8 w-8 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
              />
            </svg>
          </div>

          <div class="ml-5 w-0 flex-1">
            <dl>
              <dt class="text-sm font-medium text-gray-500 truncate">
                Solde
              </dt>

              <dd class="text-lg font-medium text-gray-900">
                <loyalty-card-membership-point-balance
                  :formatted_point_balance="membership.formatted.point_balance"
                  :point_balance="membership.point_balance"
                  :rate="membership.loyalty_card.point.rate"
                />
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoyaltyCardMembershipPointBalance
from '@/renderer/app/loyalty-card-membership/components/loyalty-card-membership-point-balance/LoyaltyCardMembershipPointBalance.vue'

export default {
  components: { LoyaltyCardMembershipPointBalance },
  props: {
    /**
     * The membership to display.
     *
     * @type {Object}
     */
    membership: {
      type: Object,
      required: true
    }
  }
}
</script>
