<template>
  <form class="w-full flex flex-row">
    <div class="border-b-2 border-lightPurple-darkest pb-1 mb-1">
      <input
        v-model="value"
        class="w-36 font-heading p-3 pb-0 pl-0 mr-0 pr-0 text-sm text-gray-800 bg-white"
        :placeholder="placeholder"
        @input="formatInput"
      >
    </div>
    <button
      class="flex items-center justify-center w-22 text-xs px-2 rounded-lg text-primary font-heading font-bold uppercase overflow-hidden"
      :disabled="loading || !canSubmit"
      @click="onSubmit"
    >
      <img
        v-if="!!loading"
        :src="require('@/assets/loader.gif')"
        alt="Loading"
        width="24"
      >
      <p v-if="!loading">
        {{ action }}
      </p>
    </button>
  </form>
</template>

<script>
import formatHelper from '@/renderer/helpers/formatHelper'

export default {
  props: {
    placeholder: {
      type: String,
      default: '...'
    },
    action: {
      type: String,
      default: 'OK'
    },
    format: {
      type: String,
      default: 'text'
    },
    loading: {
      type: Boolean,
      default: false
    },
    input: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      value: this.input,
      canSubmit: {
        type: Boolean,
        default: false
      }
    }
  },
  watch: {
    input: {
      immediate: true,

      handler (value) {
        this.value = value
      }
    }
  },
  methods: {
    formatInput () {
      const phoneLenghtWithSpaces = 14
      switch (this.format) {
        case 'phone':
          this.value = formatHelper.readablePhone(this.value)
          break
        default:
          return this.value
      }
      this.canSubmit = this.value.length === phoneLenghtWithSpaces
    },
    onSubmit () {
      this.$emit('submit', this.value)
    }
  }
}
</script>
