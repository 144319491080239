<template>
  <div>
    <div
      v-if="pharmaMls.length > 0"
      class="mt-2"
    >
      <BaseAutoComplete :placeholder="'Produits, laboratoires, catégories'" />
      <div class="grids gap-2">
        <BaseSelect :options="[{id: 1, label:'Tous'}]" />
      </div>
      <div class="mt-2">
        <ul class="space-y-3">
          <li
            v-for="(result, index) in pharmaMls"
            :key="index"
            class="bg-white shadow overflow-hidden rounded-md px-3 py-2"
          >
            <table class="min-w-full">
              <thead>
                <tr class="text-left text-xs text-gray-500 tracking-wider">
                  <th>Laboratoire</th>
                  <th>Taux de disponibilité</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ result.laboratory.name }}</td>
                  <td>99%</td>
                </tr>
              </tbody>
            </table>
          </li>
        </ul>
      </div>
    </div>
    <div
      v-else
      class="sm:p-6 mt-2"
    >
      <div class="rounded-md bg-blue-50 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <!-- Heroicon name: information-circle -->
            <svg
              class="h-5 w-5 text-blue-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div class="ml-3 flex-1 flex justify-between">
            <p class="text-sm text-blue-700">
              Aucune donnée compatible avec la pharmacie sélectionnée.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  pharmamlResource
} from '@/renderer/container'

import BaseAutoComplete from '@/renderer/components/base/BaseAutoComplete.vue'
import BaseSelect from '@/renderer/components/base/BaseSelect.vue'

export default {
  components: {
    BaseSelect,
    BaseAutoComplete
  },

  data () {
    return {
      pharmaMls: {}
    }
  },

  mounted () {
    pharmamlResource().get().then((response) => {
      this.pharmaMls = response
    })
  }
}
</script>
