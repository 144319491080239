/**
 * The sellin challenge resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Read a sellin challenge.
     * @param {number} id - The ID of the sellin challenge.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    read (id) {
      return client
        .get(`/sellin-challenges/${id}`, {
          params: {
            include: 'organization.morphable'
          }
        })
        .then(response => response.data.data)
    }
  }
}
