<template>
  <div v-if="!healthcenter" />
  <div
    v-else
    class="flex flex-col gap-2"
  >
    <div class="flex flex-col gap-1">
      <p class="font-bold">
        {{ healthcenter.name }}
      </p>
      <template
        v-if="healthcenter.address"
        class="flex flex-col"
      >
        <span>{{ healthcenter.address.street }}</span>
        <span>{{ healthcenter.address.postal_code }} {{ healthcenter.address.city }}</span>
      </template>
      <p
        v-for="contact in healthcenter.contacts"
        :key="contact.type.slug"
      >
        {{ contact.type.name }}: {{ contact.value }}
      </p>
    </div>
    <div
      v-for="group in content.groups"
      :key="group.id"
      class="flex gap-1 items-center"
    >
      <healthcenter-group-icon :size="20" />
      <p>{{ group.name }}</p>
    </div>
    <span><strong>{{ stockQuantity }}</strong> stock</span>
  </div>
</template>

<script>
import HealthcenterGroupIcon
from '@/renderer/app/disruption-logistics/components/healthcenter-groups/HealthcenterGroupIcon.vue'

export default {
  name: 'HealthcenterGroupAvailabilityInfoWindow',
  components: { HealthcenterGroupIcon },
  props: {
    /**
     * The content to display
     * It should contain the healthcenter, and the groups of the healthcenter.
     */
    content: {
      type: Object,
      required: false,
      default: null
    }
  },
  computed: {
    /**
     * Get the healthcenter from the content.
     *
     * @return {HealthcenterGroupMemberWithProductsStock}
     */
    healthcenter () {
      return this.content?.healthcenter
    },
    /**
     * Get the stock quantity of the healthcenter.
     *
     * @return {number}
     */
    stockQuantity () {
      if (this.healthcenter.stocks.length) {
        return this.healthcenter.stocks[0].quantity
      }

      return 0
    }
  }
}
</script>

<style scoped>

</style>
