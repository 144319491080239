<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    class="stroke-current"
    fill="currentColor"
    viewBox="0 0 16 16"
  >
    <path
      stroke-width="1"
      stroke="currentColor"
      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 32
    }
  }
}
</script>

<style scoped>

</style>
