<template>
  <product-availabity-card
    :title="data.availability === null
      ? 'Dépositaire non joignable'
      : `${availabilityLabel(data)} en direct`
    "
    loading-message="Interrogation du dépositaire"
    :loading="data.loading"
    :is-available="data.availability"
    :messages="data.messages"
  >
    <template #icon>
      <icon
        name="truck"
        :size="40"
        color="black"
      />
    </template>
  </product-availabity-card>
</template>

<script>
import ProductAvailabityCard
from '@/renderer/app/disruption-logistics/components/product-availability-card/ProductAvailabityCard.vue'
import Icon from '@/renderer/app/core/icon/Icon.vue'

export default {
  name: 'DirectProductAvailabilityCard',
  components: { Icon, ProductAvailabityCard },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    /**
     * Get the availability label.
     *
     * @param {Object} provider
     *
     * @return {string}
     */
    availabilityLabel (provider) {
      if (provider.availability) {
        return 'Disponible'
      } else if (!provider.availability) {
        return 'Indisponible'
      }
    }
  }
}
</script>

<style scoped>

</style>
