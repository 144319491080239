export default function () {
  return {
    /**
     * Determine if the given consumer program has an action linked.
     *
     * @param {Object} program - The consumer program.
     * @param {boolean} shouldBeReady - Determine if the program should be ready (elapsed > delay).
     *
     * @return {boolean} True if the program has an action, false otherwise.
     */
    hasAction (program, shouldBeReady = true) {
      const step = program.next_step ?? null

      if (step === null) {
        return false
      }

      if (shouldBeReady) {
        return step.action !== null && step.elapsed > step.delay
      }

      return step.action !== null
    }
  }
}
