<template>
  <div class="flex flex-col gap-6">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <direct-product-availability-card
        :data="directAvailability"
        class="md:col-span-2"
      />
      <healthcenter-group-product-availability-card :data="groups" />
      <healthcenter-group-warehouse-product-availability-card
        :data="groupWarehouses"
      />
    </div>
    <div class="flex flex-col md:flex-row items-start md:items-center gap-4 text-lg flex-wrap">
      <div class="flex gap-3">
        <v-icon
          size="30"
          color="black"
        >
          mdi-cart
        </v-icon>
        <label>
          Marketplaces
        </label>
      </div>
      <div class="flex bg-white rounded-md px-4 py-2 items-center gap-4">
        <img
          :src="require('@/assets/images/medidestock.png')"
          alt="medidestock"
          style="height: 35px"
        >
        <div class="flex flex-col justify-center relative top-0.5">
          <p class="text-sm leading-none">
            {{
              medidestock.loading ? 'Interrogation de Medidestock' : `${availabilityLabel(medidestock)} chez Medidestock`
            }}
          </p>
          <div class="text-primary">
            <a
              class="text-sm"
              target="_blank"
              href="https://medi-destock.com/"
            >
              Devenir client
            </a>
          </div>
        </div>
      </div>
      <div class="flex bg-white rounded-md px-4 py-2 items-center gap-4">
        <le-comptoir-des-pharmacies />
        <div class="relative top-0.5">
          <p class="text-sm leading-none">
            {{
              comptoirDesPharmacies.loading ? 'Interrogation de Le comptoir des pharmacies' : `${availabilityLabel(medidestock)} chez Le comptoir des pharmacies`
            }}
          </p>
          <div class="text-primary">
            <a
              class="text-sm"
              target="_blank"
              href="https://medi-destock.com/"
            >
              Devenir client
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { disruptionLogisticsApi } from '@/renderer/container'
import LeComptoirDesPharmacies from '@/assets/images/icons-vue/LeComptoirDesPharmacies.vue'
import { AVAILABILITY_PROVIDERS } from '@/renderer/http/resources/DisruptionLogisticsResource'
import HealthcenterGroupProductAvailabilityCard
from '@/renderer/app/disruption-logistics/components/product-availability-card/HealthcenterGroupProductAvailabilityCard.vue'
import HealthcenterGroupWarehouseProductAvailabilityCard
from '@/renderer/app/disruption-logistics/components/product-availability-card/HealthcenterGroupWarehouseProductAvailabilityCard.vue'
import DirectProductAvailabilityCard
from '@/renderer/app/disruption-logistics/components/product-availability-card/DirectProductAvailabilityCard.vue'

export default {
  name: 'ProductAvailabilityCardGrid',
  components: {
    DirectProductAvailabilityCard,
    HealthcenterGroupWarehouseProductAvailabilityCard,
    HealthcenterGroupProductAvailabilityCard,
    LeComptoirDesPharmacies
  },
  props: {
    productId: {
      type: Number,
      required: true
    },
    requestToken: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      medidestock: {
        loading: true,
        availability: null,
        messages: null,
        details: null
      },
      groups: {
        loading: true,
        availability: null,
        messages: null,
        details: null
      },
      directAvailability: {
        loading: true,
        availability: null,
        messages: null,
        details: null
      },
      comptoirDesPharmacies: {
        loading: true,
        availability: null,
        messages: null,
        details: null
      },
      groupWarehouses: {
        loading: true,
        availability: null,
        messages: null,
        details: null
      }
    }
  },
  watch: {
    productId: {
      immediate: true,
      handler (productId) {
        this.loadAvailabilityAndEmit(AVAILABILITY_PROVIDERS.medidestock, productId)
        this.loadAvailabilityAndEmit(AVAILABILITY_PROVIDERS.leComptoirDesPharmacies, productId, 'comptoirDesPharmacies')
        this.loadAvailabilityAndEmit(AVAILABILITY_PROVIDERS.healthcenterGroups, productId, 'groups')
        this.loadAvailabilityAndEmit(AVAILABILITY_PROVIDERS.direct, productId, 'directAvailability')
        this.loadAvailabilityAndEmit(AVAILABILITY_PROVIDERS.healthcenterGroupsDistributor, productId, 'groupWarehouses')
      }
    }
  },
  methods: {
    loadAvailabilityAndEmit (provider, productId, alias = null) {
      return this.loadAvailability(provider, productId, alias)
        .then((data) => {
          this.$emit('availability-loaded', { provider, data })
        })
    },

    /**
     * Load the availability of the product from the provider.
     *
     * @param {string} provider - The provider name
     * @param {Number} productId - The product id
     * @param {String} alias - The state alias
     *
     * @returns {Promise}
     */
    loadAvailability (provider, productId, alias = null) {
      const key = alias ?? provider
      this[key].loading = true
      return disruptionLogisticsApi()
        .productAvailability(provider, productId, this.requestToken)
        .then((availability) => {
          this[key].availability = availability.is_available
          this[key].messages = availability.messages ?? null
          this[key].details = availability.details ?? null

          return availability
        })
        .finally(() => {
          this[key].loading = false
        })
    },
    /**
     * Get the availability label.
     *
     * @param {Object} provider
     *
     * @return {string}
     */
    availabilityLabel (provider) {
      if (provider.availability) {
        return 'Disponible'
      } else if (!provider.availability) {
        return 'Indisponible'
      }
    }
  }
}
</script>
