<template>
  <BaseCard>
    <BaseCardBody>
      <p class="font-bold">
        {{ title }}
      </p>
      <p class="text-sm">
        {{ description }}
      </p>
      <program-attachment-grid
        :attachments="attachments"
        class="p-5"
        :attachable-id="$route.params.programId"
        attachable-type="programs"
      />
    </BaseCardBody>
  </BaseCard>
</template>

<script>
import ProgramAttachmentThumbnail
from '@/renderer/app/program/components/program-attachment/ProgramAttachmentThumbnail.vue'
import ProgramAttachmentService from '@/renderer/app/program/services/ProgramAttachmentService'
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import BaseCardBody from '@/renderer/components/base/BaseCardBody.vue'
import ProgramAttachmentGrid from '@/renderer/app/program/components/program-attachment/ProgramAttachmentGrid.vue'

export default {
  name: 'SupportProgramDocumentGrid',
  components: {
    ProgramAttachmentGrid,
    ProgramAttachmentThumbnail,
    BaseCard,
    BaseCardBody
  },
  props: {
    attachments: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: 'Fiches conseils'
    },
    description: {
      type: String,
      default: 'Plusieurs documents informatifs sont à votre disposition ci-dessous.' +
        'Imprimez-les et remettez les à vos patients'
    }
  },
  methods: {
    /**
     * Download and open the attachment in a new browser window.
     *
     * @param {Object} attachment - The attachment to download and open.
     */
    downloadAndOpen (attachment) {
      ProgramAttachmentService.downloadAttachmentAndOpen(
        'programs',
        Number(this.$route.params.programId),
        attachment.id
      )
    }
  }
}
</script>

<style scoped>

</style>
