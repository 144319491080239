<template>
  <nav
    v-if="pagination.total_pages > 1"
    class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0"
  >
    <div class="-mt-px w-0 flex-1 flex">
      <a
        v-if="page > 1"
        href="#"
        class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
        @click="select(page - 1)"
      >
        <svg
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          class="mr-3 h-5 w-5 text-gray-400"
        >
          <path
            fill-rule="evenodd"
            d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
            clip-rule="evenodd"
          />
        </svg>

        Précédent
      </a>
    </div>

    <div class="hidden md:-mt-px md:flex">
      <template v-for="index in pagination.total_pages">
        <a
          v-if="index >= (page - limit) && index <= (page + limit)"
          :key="index"
          href="#"
          class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
          :class="[ page == index
            ? 'border-indigo-500 text-indigo-600'
            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
          ]"
          @click="select(index)"
        >
          {{ index }}
        </a>
      </template>
    </div>

    <div class="-mt-px w-0 flex-1 flex justify-end">
      <a
        v-if="page < pagination.total_pages"
        href="#"
        class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
        @click="select(page + 1)"
      >
        Suivant

        <svg
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          class="ml-3 h-5 w-5 text-gray-400"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
          />
        </svg>
      </a>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    pagination: {
      type: Object,
      required: true
    },

    page: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      limit: 6
    }
  },

  methods: {
    select (page) {
      this.$emit('change', page)
    }
  }
}
</script>
