import {
  customerProgramResource,
  customerResource,
  loyaltyCardMembershipResource,
  programResource
} from '@/renderer/container'

export default {
  namespaced: true,
  state: {
    customer: null,
    pathways: [],
    memberships: null
  },
  getters: {
    pathway: (state) => (supportProgramId) => {
      return state.pathways.find(pathway => pathway.support_program_id === supportProgramId)
    },
    customer: (state) => () => {
      return state.customer
    },
    memberships: (state) => () => {
      return state.memberships
    }
  },
  mutations: {
    storePathway (state, pathway) {
      const pathways = state.pathways.filter(storedPathway => storedPathway.id !== pathway.id)
      state.pathways = [...pathways, pathway]
    },
    store (state, customer) {
      state.customer = customer
    },
    storeMemberships (state, memberships) {
      state.memberships = memberships
    },
    reset (state) {
      state.pathways = []
      state.customer = null
      state.memberships = null
    }
  },
  actions: {
    loadPathway ({ commit, state }, { supportProgramId, consumerKey, skipDelay = false }) {
      return programResource()
        .pathway(supportProgramId, consumerKey, skipDelay)
        .then(pathway => commit('storePathway', pathway))
    },
    loadMemberships ({ commit, state }, { customerId }) {
      return loyaltyCardMembershipResource()
        .get(customerId)
        .then(memberships => commit('storeMemberships', memberships))
        .catch()
    },
    load ({ commit, state }, { customerId }) {
      commit('reset')
      let customer

      return customerResource()
        .read(customerId)
        .then(customerResponse => {
          customer = { ...customerResponse }
        })
        .then(() => customerProgramResource().get('c_' + customerId))
        .then(programs => {
          customer.programs = programs
          return Promise.all(customer.beneficiaries.map(beneficiary => {
            return customerProgramResource().get('b_' + beneficiary.id)
          }))
        })
        .then(programs => {
          customer.beneficiaries.forEach((beneficiary, index) => {
            beneficiary.programs = programs[index]
          })
        })
        .then(() => commit('store', customer))
    }
  }
}
