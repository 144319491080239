<template>
  <div class="grid md:grid-cols-2 gap-2 justify-evenly items-center mb-10">
    <div
      class="flex items-center bg-apodis p-5 rounded-lg border-4 border-apodis text-white gap-5 text-wrap md:mx-5 xl:mx-10"
    >
      <div class="flex flex-col justify-center items-center">
        <span class="text-3xl font-bold">{{ calculateStatsExcludedRatio }}%</span>
        <span class="text-md">{{ statisticsExcluded.intersection }}</span>
      </div>
      <span class="font-custom text-md">{{ statisticsExcluded.label }}</span>
    </div>
    <div class="flex items-center p-5 rounded-lg text-blue-900 border-4 border-apodis gap-5 text-wrap md:mx-5 xl:mx-10">
      <div class="flex flex-col justify-center items-center">
        <span class="text-3xl font-bold">{{ calculateStatsIncludedRatio }}%</span>
        <span class="text-md">{{ statisticsIncluded.intersection }}</span>
      </div>
      <span class="font-extrabold text-md">{{ statisticsIncluded.label }}</span>
    </div>
  </div>
</template>
<script>

export default {
  name: 'ProgramDigestCards',
  props: {
    statisticsIncluded: {
      type: Object,
      required: true
    },
    statisticsExcluded: {
      type: Object,
      required: true
    }
  },
  computed: {
    calculateStatsExcludedRatio () {
      const total = this.statisticsExcluded.intersection + this.statisticsIncluded.intersection

      return this.statisticsExcluded.intersection ? Math.round(this.statisticsExcluded.intersection / total * 100).toFixed(0) : 0
    },
    calculateStatsIncludedRatio () {
      const total = this.statisticsExcluded.intersection + this.statisticsIncluded.intersection

      return this.statisticsIncluded.intersection ? Math.round(this.statisticsIncluded.intersection / total * 100).toFixed(0) : 0
    }
  }
}
</script>
<style scoped>
.bg-apodis {
  background: #3A10EB;
}

.font-custom {
  text-shadow: 1px 0px #fff;
}

.border-apodis {
  border-color: #3A10EB;
}
</style>
