export default {
  state: {
    host: null,
    quantity: 0,
    branding: null,
    /** @type {null | UserAccessPoint} */
    environment: null,
    notificationCount: null
  },

  mutations: {
    setHost (state, host) {
      state.host = host
    },

    setEnvironment (state, environment) {
      state.environment = environment
    },

    setBranding (state, branding) {
      state.branding = branding
    },

    setQuantity (state, quantity) {
      state.quantity = quantity
    },

    setNotificationCount (state, notificationCount) {
      state.notificationCount = notificationCount
    }
  }
}
