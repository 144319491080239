<template>
  <div>
    <div class="mb-8">
      <h1 class="text-2xl font-bold text-gray-900">
        {{ event.title }}
      </h1>

      <p class="text-sm font-medium text-gray-500">
        Du {{ event.formattedStartDate }}
        au {{ event.formattedEndDate }}
      </p>
    </div>

    <BaseSection>
      <BaseCard>
        <BaseImage
          v-if="event.custom.image_path"
          rendered
          :text="event.title"
          :path="event.custom.image_path"
          class="w-full h-96 border-b border-gray-200"
        />

        <BaseCardBody>
          <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div class="sm:col-span-1">
              <dt class="text-sm font-medium text-gray-500">
                Description
              </dt>

              <dd class="mt-1 text-sm text-gray-900">
                {{ event.description }}
              </dd>
            </div>

            <div class="sm:col-span-1">
              <dt class="text-sm font-medium text-gray-500">
                Type d’événement
              </dt>

              <dd class="mt-1 text-sm text-gray-900">
                {{ event.eventType.name }}
              </dd>
            </div>

            <div
              v-if="event.custom.file_name"
              class="sm:col-span-2"
            >
              <dt class="text-sm font-medium text-gray-500">
                Fichier
              </dt>

              <dd class="mt-1 text-sm text-gray-900">
                <ul
                  role="list"
                  class="border border-gray-200 rounded-md divide-y divide-gray-200 bg-gray-50"
                >
                  <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div class="w-0 flex-1 flex items-center">
                      <svg
                        aria-hidden="true"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        class="flex-shrink-0 h-5 w-5 text-gray-400"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7
                          7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012
                          0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                        />
                      </svg>

                      <span class="ml-2 flex-1 w-0 truncate">
                        {{ event.custom.file_name }}
                      </span>
                    </div>

                    <div
                      v-if="event.custom.file_path"
                      class="ml-4 flex-shrink-0"
                    >
                      <a
                        :href="event.custom.file_path"
                        class="font-medium text-primary hover:text-primary"
                      >
                        Télécharger
                      </a>
                    </div>
                  </li>
                </ul>
              </dd>
            </div>
          </dl>
        </BaseCardBody>
      </BaseCard>

      <BaseCard v-if="event.custom.video_reference">
        <BaseCardHeader>
          Vidéo
        </BaseCardHeader>

        <BaseCardBody>
          <BaseVideo :reference="event.custom.video_reference" />
        </BaseCardBody>
      </BaseCard>

      <BaseCard v-if="event.custom.products.length">
        <BaseCardHeader>
          Produits
        </BaseCardHeader>

        <ul
          role="list"
          class="divide-y divide-gray-200"
        >
          <li
            v-for="product in event.custom.products"
            :key="product.id"
          >
            <div class="flex flex-col space-y-4 px-6 py-4 space-y-4 px-6 py-4 sm:flex-row sm:space-x-8 sm:space-y-0">
              <div>
                <BaseImage
                  class="rounded-lg w-full h-32 sm:h-20 sm:w-20"
                  :text="product.name"
                  :path="product.photoPath"
                />
              </div>

              <div class="flex-1">
                <p class="text-sm font-medium">
                  {{ product.name }}
                </p>

                <p class="mt-1 flex items-center text-sm text-gray-500">
                  {{ product.code }}
                </p>
              </div>

              <div>
                <ProductPurchase
                  v-model="product.quantity"
                  :product="product"
                  class="w-full sm:w-48"
                />
              </div>
            </div>
          </li>
        </ul>
      </BaseCard>
    </BaseSection>
  </div>
</template>

<script>
import {
  eventResource,
  shopCartResource
} from '@/renderer/container'

import BaseCard from '@/renderer/components/base/BaseCard.vue'
import BaseImage from '@/renderer/components/base/BaseImage.vue'
import BaseVideo from '@/renderer/components/base/BaseVideo.vue'
import BaseSection from '@/renderer/components/base/BaseSection.vue'
import BaseCardBody from '@/renderer/components/base/BaseCardBody.vue'
import BaseCardHeader from '@/renderer/components/base/BaseCardHeader.vue'
import ProductPurchase from '@/renderer/components/brand/ProductPurchase.vue'

export default {
  components: {
    BaseCard,
    BaseImage,
    BaseVideo,
    BaseSection,
    BaseCardBody,
    BaseCardHeader,
    ProductPurchase
  },

  data () {
    return {
      event: {
        title: null,
        startDate: null,
        endDate: null,

        eventType: {
          name: null
        },

        custom: {
          products: [],
          file_path: null,
          file_name: null,
          image_path: null
        }
      }
    }
  },

  mounted () {
    shopCartResource()
      .read(
        this.$route.params.laboratoryId,
        this.$store.state.system.environment.entityId
      )
      .then(cart => {
        eventResource()
          .read(this.$route.params.eventId)
          .then(event => {
            const date = {
              month: 'long',
              day: 'numeric',
              weekday: 'long',
              year: 'numeric'
            }

            event.formattedStartDate = new Date(event.startDate)
              .toLocaleDateString(undefined, date)

            event.formattedEndDate = new Date(event.endDate)
              .toLocaleDateString(undefined, date)

            event.custom.products
              .map(product => {
                const line = cart.lines
                  .find(line => line.productId === product.id)

                const quantity = line
                  ? line.quantity
                  : 0

                product.quantity = quantity

                return product
              })

            this.event = event
          })
      })
  }
}
</script>
