export default function (client) {
  return {
    /**
     * Get programs for a given consumer.
     *
     * @param consumerKey The consumer key.
     *
     * @return {*}
     */
    get (consumerKey) {
      return client.get(`consumers/${consumerKey}/programs`)
        .then(response => response.data.data)
    }
  }
}
