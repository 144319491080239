<template>
  <span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800">
    <svg
      v-if="dot"
      viewBox="0 0 8 8"
      fill="currentColor"
      class="-ml-1 mr-1.5 h-2 w-2 text-indigo-400"
    >
      <circle
        r="3"
        cx="4"
        cy="4"
      />
    </svg>

    <slot />
  </span>
</template>

<script>
export default {
  props: {
    dot: {
      type: Boolean,
      default: false
    }
  }
}
</script>
