<template>
  <div class="px-4 flex flex-col">
    <span class="text-xl text-primary font-bold">Environnements</span>
    <div class="flex flex-row gap-4 flex-wrap my-2">
      <program-category-card
        v-for="category in bundle.categories"
        :key="category.id"
        :patient-count="countPatients(category)"
        :category="category"
        @click.native="navigateToCategory(category)"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import ProgramCategoryCard from '@/renderer/app/program/components/program-category-card/ProgramCategoryCard.vue'

/**
 * Display program categories for a bundle
 */
export default defineComponent({
  name: 'ProgramBundleCategories',
  components: { ProgramCategoryCard },
  props: {
    /**
     * The bundle to get the categories from.
     */
    bundle: {
      type: Object,
      required: true
    }
  },
  methods: {
    /**
     * Count the number of patients included in a category.
     *
     * @param {Object} category
     *
     * @return {Number}
     */
    countPatients (category) {
      return this.bundle.programs.filter(program => {
        return program.categories.map(category => category.id).includes(category.id)
      })
        .reduce((acc, program) => acc + program.consumer_count ?? 0, 0)
    },
    /**
     * Navigate to the category details.
     *
     * @param {Object} category - The category to navigate to.
     *
     * @return {void}
     */
    navigateToCategory (category) {
      this.$router.push({
        name: 'program-bundle.detail.category.attachments',
        params: {
          programBundleId: this.bundle.id,
          programCategoryId: category.id
        }
      })
    }
  }
})
</script>
