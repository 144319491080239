<template>
  <button
    class="flex card shadow-md p-4 bg-white gap-4 rounded-md items-center"
    @click="$router.push({name: 'disruption-logistics.wholesaler-warehouse-healthcenter'})"
  >
    <v-icon
      size="40"
      color="black"
    >
      mdi-warehouse
    </v-icon>
    <span class="flex flex-col justify-start text-start w-full">
      <span class="text-lg">
        Gérer mes agences
      </span>
      <span v-if="loading">
        <v-skeleton-loader
          class="w-full max-w-lg top-1"
          height="20"
          type="text"
        />
      </span>
      <span
        v-else-if="!firstWarehouse || !secondWarehouse"
        class="text-primary"
      >
        Pensez à sélectionner vos agences 😇
      </span>
      <span
        v-else
        class="text-primary"
      >
        #1 {{ firstWarehouse.name }}, #2 {{ secondWarehouse.name }}
      </span>
    </span>
  </button>
</template>

<script>
import { wholesalerApi } from '@/renderer/container'

export default {
  name: 'HealthcenterWarehousesCard',
  data () {
    return {
      healthcenterWarehouses: null,
      loading: false
    }
  },
  computed: {
    /**
     * Get the healthcenter warehouse rank 1.
     *
     * @return {Object|null}
     */
    firstWarehouse () {
      if (!this.healthcenterWarehouses) {
        return null
      }

      return this.healthcenterWarehouses.find(warehouse => warehouse.rank === 1)
    },
    /**
     * Get the healthcenter warehouse rank 2.
     *
     * @return {Object|null}
     */
    secondWarehouse () {
      if (!this.healthcenterWarehouses) {
        return null
      }

      return this.healthcenterWarehouses.find(warehouse => warehouse.rank === 2)
    }
  },
  mounted () {
    this.loading = true
    wholesalerApi()
      .getCurrentHealthcenterWarehouses()
      .then(healthcenterWarehouses => {
        this.healthcenterWarehouses = healthcenterWarehouses
      })
      .finally(() => {
        this.loading = false
      })
  }
}
</script>
