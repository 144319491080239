<template>
  <base-modal
    v-if="visible"
    :title="extension.name"
    @close="$emit('close')"
  >
    <base-flex-spinner v-if="loading" />
    <div
      v-else
      class="flex flex-col gap-3"
    >
      <div class="flex justify-between flex-col md:flex-row gap-4">
        <div class="flex gap-2">
          <program-logo :program="detailedExtension" />
          <div>
            <h3 class="font-bold text-xl">
              {{ detailedExtension.name }}
            </h3>
            <label>{{ detailedExtension.organization.entity_name }}</label>
          </div>
        </div>
        <div class="self-start">
          <p class="font-content text-primary">
            Rendez-vous sur votre application Apodis Pro pour obtenir cette extension !
          </p>
        </div>
      </div>
      <v-carousel
        v-if="detailedExtension.previews?.length"
        :hide-delimiters="detailedExtension.previews === 1"
      >
        <v-carousel-item
          v-for="(preview) in detailedExtension.previews"
          :key="preview.id"
          :src="preview.url"
        />
      </v-carousel>
      <markdown
        :content="detailedExtension.description"
      />
    </div>
  </base-modal>
</template>

<script>
import BaseModal from '@/renderer/components/base/BaseModal.vue'
import BaseFlexSpinner from '@/renderer/components/base/spinner/BaseFlexSpinner.vue'
import { extensionResource } from '@/renderer/container'
import ProgramLogo from '@/renderer/app/program/components/program-logo/ProgramLogo.vue'
import Markdown from '@/renderer/app/core/components/markdown/Markdown.vue'

export default {
  name: 'ExtensionDetailsModal',
  components: { Markdown, ProgramLogo, BaseFlexSpinner, BaseModal },
  props: {
    extension: {
      type: Object,
      required: false,
      default: () => null
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      detailedExtension: null,
      loading: false
    }
  },
  watch: {
    visible: {
      immediate: true,
      handler () {
        if (!this.extension) {
          return
        }

        this.loading = true
        extensionResource()
          .read(this.extension.id)
          .then(response => {
            this.detailedExtension = response
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
}
</script>

<style scoped>

</style>
