<template>
  <div>
    <program-teaser-text :program="program" />
    <program-teaser-action
      :program="program"
      @action.stop="clicked"
    />
  </div>
</template>

<script>

import ProgramTeaserText from '@/renderer/app/program/components/program-teaser/ProgramTeaserText.vue'
import ProgramTeaserAction from '@/renderer/app/program/components/program-teaser/ProgramTeaserAction.vue'

export default {
  components: { ProgramTeaserAction, ProgramTeaserText },
  props: {
    program: {
      type: Object,
      required: true
    }
  },
  computed: {
    /**
     * Get the program next step
     *
     * @return {Object|null}
     */
    step () {
      return this.program.next_step
    },
    /**
     * Whether the next step is locked
     *
     * @return {boolean}
     *
     */
    isLocked () {
      if (!this.step) {
        return true
      }

      return this.step.type === 'aftercare' &&
        this.step.steppable?.verdict?.verdict &&
        !this.step.consumer_step?.steppable?.id
    }
  },
  methods: {
    /**
     * Handle the click event on the action button.
     *
     * @param event
     * @return {*}
     */
    clicked (event) {
      if (!this.isLocked && this.$listeners.action) {
        return this.$listeners.action(event)
      }
    }
  }
}
</script>
