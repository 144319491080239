<template>
  <div class="w-full">
    <notification-detection-customer-header
      :item="item"
      @show-customer="showCustomer"
      @navigate-qr-code="navigateCertification"
      @navigate-sms="navigateCertification"
      @navigate-surveys="navigateSurveys"
    />
    <notification-detection-programs
      v-if="item.customer"
      :item="item"
    />
    <notification-detection-memberships
      v-if="item.customer"
      :item="item"
      @link-membership="attachLoyaltyCard"
      @show-membership="showMembership"
    />
  </div>
</template>

<script>
import BaseTag from '@/renderer/components/base/BaseTag.vue'
import '@/renderer/components/views/notification/notification-detection/notification-detection.css'
import NotificationLoyaltyCardSimpleVue
from '@/renderer/app/program/components/loyalty-card-notification/NotificationLoyaltyCardSimple.vue'
import { customerCertificationResource, loyaltyCardMembershipResource, storageService } from '@/renderer/container'
import NotificationDetectionPrograms
from '@/renderer/components/views/notification/notification-detection/programs/NotificationDetectionPrograms.vue'
import NotificationDetectionMemberships
from '@/renderer/components/views/notification/notification-detection/loyalty-cards/NotificationDetectionMemberships.vue'
import NotificationDetectionCustomerHeader
from '@/renderer/components/views/notification/notification-detection/NotificationDetectionCustomerHeader.vue'

export const DETECTION_HEADER_HEIGHT = 80

export default {
  components: {
    NotificationDetectionCustomerHeader,
    NotificationDetectionMemberships,
    NotificationDetectionPrograms,
    BaseTag,
    NotificationLoyaltyCardSimpleVue
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      headerHeight: DETECTION_HEADER_HEIGHT,
      printing: false
    }
  },
  methods: {
    /**
     * Navigate to the customer page.
     *
     * @param customer
     */
    showCustomer (customer) {
      if (customer && customer.id) {
        window.ipcRenderer.send(
          'navigate',
          `/customer/${customer.id}/home`,
          true
        )
      }
    },
    /**
     * Hide the notification
     */
    ignore () {
      window.ipcRenderer.send('hide-notification')
    },
    /**
     * Open the membership page.
     *
     * @param {Object} loyaltyCard
     */
    showMembership (loyaltyCard) {
      if (loyaltyCard.membership) {
        window.ipcRenderer.send(
          'navigate',
          `/customer/${this.item.customer.id}/loyalty/membership/${loyaltyCard.membership.id}`,
          true
        )
      }
    },
    /**
     * Attach a loyalty card to the customer.
     *
     * @param {Object} loyaltyCard The loyalty card.
     *
     * @return {Promise<void>}
     */
    attachLoyaltyCard (loyaltyCard) {
      return loyaltyCardMembershipResource()
        .create(loyaltyCard.id, this.item.customer.id)
        .then((membership) => {
          const card = this.item.loyalty_cards.find((card) => card.id === loyaltyCard.id)

          if (card) {
            card.membership = membership
          }
        })
    },
    /**
     * Navigate to the certification page.
     *
     * @param {Object} customer The customer.
     */
    navigateCertification (customer) {
      if (!customer || !customer.id || this.printing) {
        return
      }

      this.printing = true
      window.ipcRenderer
        .invoke('print', {
          url: customerCertificationResource().pdfUrl(customer.id, 'qrcode'),
          token: storageService().read('token')
        })
        .finally(() => {
          this.printing = false
        })
    },
    /**
     * Navigate to the customer survey page.
     *
     * @param {Object} customer
     */
    navigateSurveys (customer) {
      if (!customer || !customer.id) {
        return
      }

      window.ipcRenderer.send(
        'navigate',
        `/customer/${this.item.customer.id}/survey-instances`,
        true
      )
    }
  }
}
</script>
