/**
 * The survey resource.
 *
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Get surveys.
     *
     * @param {Object} params - The query parameters.
     *
     * @returns {Promise} Promise object represents the HTTP request.
     */
    index (params = {}) {
      return client.get('/surveys', { params })
        .then(response => response.data.data)
    },

    /**
     * Read a complete survey with all its questions and answers.
     *
     * @param {Number} surveyId
     * @returns {Promise<Object>}
     */
    readBuiltSurvey (surveyId) {
      return client.get(`/surveys/${surveyId}/built`)
        .then(response => response.data.data)
    },

    /**
     * Read a survey question.
     *
     * @param surveyId The survey id.
     * @param questionId The question id of the survey.
     *
     * @returns {Promise}
     */
    readQuestion (surveyId, questionId) {
      return client.get(`/surveys/${surveyId}/questions/${questionId}`)
        .then(response => response.data)
    },

    /**
     * Create a new survey instance for a consumer.
     *
     * @param surveyId The survey id used for created the survey instance.
     * @param consumerKey The consumer key.
     *
     * @returns {Promise}
     */
    createInstance (surveyId, consumerKey) {
      return client.post(`/surveys/${surveyId}/answer-instances`, { consumer_key: consumerKey })
        .then(response => response.data.data)
    },

    /**
     * Read next question.
     *
     * @param surveyId The survey id.
     * @param instanceId The survey instance id.
     *
     * @returns {Promise}
     */
    next (surveyId, instanceId) {
      return client.get(`/surveys/${surveyId}/instances/${instanceId}/next`)
        .then(response => response)
    },

    /**
     * Answer a question of a survey instance.
     *
     * @param surveyId The survey id.
     * @param instanceId The survey instance id.
     * @param answers The answers to the current question in the instance (must be an array).
     *
     * @returns {Promise}
     */
    answer (surveyId, instanceId, answers) {
      return client.post(`/surveys/${surveyId}/instances/${instanceId}/answers`, { value: answers })
        .then(response => response)
    },

    /**
     * Get the verdict of a survey instance.
     *
     * @param surveyId Survey id.
     * @param instanceId Instance id.
     *
     * @returns {Promise}
     */
    verdict (surveyId, instanceId) {
      return client.get(`/surveys/${surveyId}/instances/${instanceId}/verdict`)
        .then(response => response.data)
    },

    /**
     * Get built survey answered instance.
     *
     * @param {number} instanceId
     *
     * @return {Promise}
     */
    builtAnsweredInstance (instanceId) {
      return client.get(`survey-answer-instances/${instanceId}/built`)
        .then(response => response.data.data)
    },

    /**
     * Get an answer image.
     *
     * @param {number} surveyId
     * @param {number} questionId
     * @param {number} answerId
     *
     * @return {Promise<Object>}
     */
    answerImage (surveyId, questionId, answerId) {
      return client.get(`surveys/${surveyId}/questions/${questionId}/answers/${answerId}/image`, { responseType: 'blob' })
        .then(response => response.data)
    },

    /**
     * Get a question image.
     *
     * @param {number} surveyId
     * @param {number} questionId
     *
     * @return {Promise<Object>}
     */
    questionImage (surveyId, questionId) {
      return client.get(`surveys/${surveyId}/questions/${questionId}/image`, { responseType: 'blob' })
        .then(response => response.data)
    }
  }
}
