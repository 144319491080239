<template>
  <div class="flex flex-column">
    <div class="flex flex-row items-center">
      <Icon
        name="phone"
        :size="30"
        color="#000000"
        class="mr-2 self-start"
      />
      <div>
        <BaseSingleInput
          action="Certifier"
          placeholder="06 __ __ __ __"
          format="phone"
          :input="value"
          :loading="loading"
          @submit="certifyPhone"
        />
        <p>
          <span
            v-if="error"
            class="text-xs text-red-500"
          >
            {{ error }}
          </span>
        </p>
        <div
          v-if="customer.information.phone"
          class="flex flex-wrap w-52"
        >
          <p class="text-xs text-gray">
            Numéro enregistré dans votre base : {{ formattedCustomerPhone }}
          </p>
          <p
            class="text-xs text-primary underline cursor-pointer"
            @click="usePhone"
          >
            Utiliser ce numéro
          </p>
        </div>
      </div>
    </div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div
          class="my-5 flex justify-center"
          v-bind="attrs"
          v-on="on"
        >
          <BaseButton disabled>
            Envoyer un sms
          </BaseButton>
        </div>
      </template>
      <span>Veuillez certifier un numéro</span>
    </v-tooltip>
  </div>
</template>

<script>
import BaseSingleInput from '@/renderer/components/base/BaseSingleInput.vue'
import formatHelper from '@/renderer/helpers/formatHelper'
import { customerCertificationResource } from '@/renderer/container'
import Icon from '@/renderer/app/core/icon/Icon.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'

export default {
  components: {
    BaseButton,
    Icon,
    BaseSingleInput
  },
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      value: '',
      error: null
    }
  },
  computed: {
    /**
     * Check if the customer is already certified.
     *
     * @returns {boolean}
     */
    formattedCustomerPhone () {
      return formatHelper.readablePhone(this.customer.information.phone)
    }
  },
  methods: {
    /**
     * Certify a phone number for the current customer.
     *
     * @param {string} phone - The phone number.
     *
     * @param phone
     */
    certifyPhone (phone) {
      if (!formatHelper.phoneIsValid(phone)) {
        this.error = 'Le numéro doit correspondre au format 06 XX XX XX XX ou 07 XX XX XX XX'
        return
      } else {
        this.error = null
      }

      this.loading = true

      customerCertificationResource()
        .certifyPhone(this.customer.id, formatHelper.apiPhone(phone))
        .then(certification => {
          this.$emit('certified', certification)
        })
        .catch(error => {
          if (error.response.status === 409) {
            this.$emit('conflict', error.response.data.conflict)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    usePhone () {
      this.value = formatHelper.readablePhone(this.customer.information.phone)
    },
    /**
     * Clear input.
     */
    clear () {
      this.value = null
    }
  }
}
</script>
