/**
 * The guidance resource.
 *
 * @param {Object} apiClient - The injected client.
 * @returns {Object} The public object.
 */
export default function (apiClient, hdsClient) {
  return {
    /**
     * Read guidance.
     *
     * @returns {Promise} Promise object represents the HTTP request.
     */
    read (guidanceId, consumerKey) {
      return apiClient.get(`guidances/${guidanceId}`, { params: { consumer_key: consumerKey } })
        .then(response => response.data.data)
    },
    /**
     * Get guidance document.
     *
     * @returns {Promise} Promise object represents the HTTP request.
     */
    document (route) {
      if (route.includes('hds=1')) {
        return hdsClient
          .get(route, { responseType: 'blob' })
          .then(response => response.data)
      }

      return apiClient
        .get(route, { responseType: 'blob' })
        .then(response => response.data)
    }
  }
}
