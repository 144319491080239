<template>
  <BaseContainer>
    <BaseSpinner v-if="isLoading" />
    <div
      v-else
      class="bg-white overflow-hidden shadow rounded-lg mx-5"
    >
      <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Historique
        </h3>
      </div>

      <div class="px-4 py-5 sm:p-6">
        <span class="relative z-0 inline-flex shadow-sm rounded-md">
          <button
            v-for="(type, index) in types"
            :key="type.value"
            type="button"
            class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            :class="{
              'relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500': index === 0,
              '-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500': index === types.length - 1,
            }"
            @click="onTypeSelected(type.value)"
          >
            {{ type.name }}
          </button>
        </span>

        <div
          v-if="transactions.length"
          class="flow-root mt-6"
        >
          <ul class="-mb-8">
            <li
              v-for="(transaction, index) in transactions"
              :key="transaction.id"
            >
              <div class="relative pb-8">
                <span
                  v-if="index !== transactions.length - 1"
                  class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />

                <div class="relative flex items-start space-x-3">
                  <div class="relative">
                    <span
                      v-if="transaction.sign === '+'"
                      class="h-10 w-10 rounded-full bg-green-500 flex items-center justify-center ring-8 ring-white"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M7 11l5-5m0 0l5 5m-5-5v12"
                        />
                      </svg>
                    </span>

                    <span
                      v-else
                      class="h-10 w-10 rounded-full bg-red-500 flex items-center justify-center ring-8 ring-white"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17 13l-5 5m0 0l-5-5m5 5V6"
                        />
                      </svg>
                    </span>
                  </div>

                  <div class="min-w-0 flex-1">
                    <div>
                      <span class="font-medium text-gray-900">
                        {{ transaction.customer.information.name }}
                      </span>
                      <div class="text-sm">
                        <span class="font-medium text-gray-900">
                          {{ transaction.title }}
                        </span>
                      </div>

                      <div class="min-w-0 flex-1 py-0">
                        <div class="text-sm leading-8 text-gray-500">
                          <span class="mr-0.5">
                            Le {{ transaction.formatted.created_at }}
                          </span>

                          <span class="ml-2">
                            <span
                              class="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm"
                            >
                              <span class="absolute flex-shrink-0 flex items-center justify-center">
                                <span
                                  v-if="transaction.sign === '+'"
                                  class="h-1.5 w-1.5 rounded-full bg-green-500"
                                  aria-hidden="true"
                                />

                                <span
                                  v-else
                                  class="h-1.5 w-1.5 rounded-full bg-red-500"
                                  aria-hidden="true"
                                />
                              </span>

                              <span class="ml-3.5 font-medium text-gray-900">
                                {{ transaction.formatted.value }}
                              </span>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="text-sm text-gray-700">
                      <ul
                        v-if="transaction.purchases"
                        class="divide-y divide-gray-200"
                      >
                        <li
                          v-for="purchase in transaction.purchases"
                          :key="purchase.id"
                          class="py-3 flex"
                        >
                          <div>
                            <p class="text-sm font-medium text-gray-900">
                              {{ purchase.quantity }} x {{ purchase.product.name }}
                            </p>

                            <p class="text-sm text-gray-500">
                              {{ purchase.product.code }} - {{ purchase.formatted.price }}
                            </p>
                          </div>
                          <div
                            v-if="purchase.animation"
                            class="flex flex-row bg-primary px-2 rounded-xl ml-10 align-center"
                          >
                            <lottie-animation
                              :width="50"
                              :height="50"
                              style="margin: 0"
                              path="animations/star.json"
                            />
                            <p class="text-sm text-white pr-2">
                              x{{ parseFloat(purchase.animation.coefficient) }} {{ purchase.animation.name }}
                            </p>
                          </div>
                        </li>
                      </ul>

                      <p
                        v-else
                        class="mt-1"
                      >
                        {{ transaction.description }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div v-else>
          <p class="text-sm font-medium text-gray-500 mt-6">
            Pas d’historique disponible.
          </p>
        </div>
      </div>
    </div>
  </BaseContainer>
</template>

<script>
import dateHelper from '@/renderer/helpers/dateHelper'
import { loyaltyCardResource } from '@/renderer/container'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import BaseContainer from '@/renderer/components/base/BaseContainer.vue'

export default {
  components: { BaseContainer, BaseSpinner, LottieAnimation },
  data () {
    return {
      isLoading: true,
      transactions: null
    }
  },
  computed: {
    dateHelper () {
      return dateHelper
    }
  },
  watch: {
    '$route.params': {
      immediate: true,

      handler (value) {
        this.index(value.animation)
      }
    }
  },
  methods: {
    /**
     * Action when see sales button is pressed.
     *
     * @param animation
     */
    index (animation) {
      return loyaltyCardResource().animationTransactions(animation.id)
        .then(response => {
          this.transactions = response
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
