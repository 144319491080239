<template>
  <div class="flex w-full items-center justify-center">
    <form class="container max-h-screen overflow-y-auto mx-auto px-4 sm:px-6 lg:px-8">
      <div
        v-if="registeredUser === null"
      >
        <div class="md:grid md:grid-cols-2">
          <div class="invisible md:visible h-0 w-0 md:h-full md:w-[384px]">
            <lottie-animation
              path="animations/73812-cloud-computing-security.json"
              :width="384"
              :height="384"
            />
          </div>
          <div
            v-if="loading"
            class="text-white"
          >
            Chargement de l'utilisateur
          </div>
          <div
            v-else-if="guestId === null"
            class="text-white"
          >
            Invitation expirée
          </div>
          <div
            v-else
            class="max-w-md"
          >
            <div class="grid grid-cols-2 gap-2">
              <RegisterInput
                v-model="firstname"
                :error="errors.firstname"
                type="text"
                placeholder="Prénom"
              />
              <RegisterInput
                v-model="lastname"
                :error="errors.lastname"
                type="text"
                placeholder="Nom"
              />
            </div>
            <div class="flex flex-row flex-wrap mt-4 mb-2 gap-4 text-sm text-white">
              <input
                id="man"
                v-model="gender"
                value="0"
                type="radio"
                class="hidden"
              >
              <label
                for="man"
                :class="['flex flex-row items-center', (gender == 0)
                  ? 'opacity-100'
                  : 'opacity-60'
                ]"
              >
                <img
                  :class="['h-16 mr-2', gender == 0
                    ? 'rounded-full border-white border-2'
                    : 'border-0'
                  ]"
                  :src="require('@/assets/images/icons/profile-male.png')"
                >
                Homme
              </label>
              <br>
              <input
                id="woman"
                v-model="gender"
                value="1"
                type="radio"
                class="hidden"
              >
              <label
                for="woman"
                :class="['flex flex-row items-center', (gender == 1)
                  ? 'opacity-100'
                  : 'opacity-60'
                ]"
              >
                <img
                  :class="['h-16 mr-2', gender == 1
                    ? 'rounded-full border-white border-2'
                    : 'border-0'
                  ]"
                  :src="require('@/assets/images/icons/profile-female.png')"
                >
                Femme
              </label>
            </div>
            <div
              v-if="errors.gender"
              class="mt-2 text-sm text-orange-light"
            >
              {{ errors.gender[0] }}
            </div>

            <div
              :class="['mt-1 relative border-b-2', errors.rank
                ? 'mb-0 border-orange-light'
                : 'mb-8'
              ]"
            >
              <select
                v-model="rankId"
                class="appearance-none w-full px-3 py-2 h-12 text-white border-0 focus:border-0 focus:ring-0 sm:text-sm bg-transparent"
              >
                <option
                  :value="null"
                  disabled
                  hidden
                >
                  Sélectionnez votre poste
                </option>
                <option
                  v-for="(name, value) in ranks"
                  :key="value"
                  :value="value"
                >
                  {{ name }}
                </option>
              </select>
            </div>

            <RegisterInput
              v-model="email"
              :error="errors.email"
              type="email"
              placeholder="Email"
            />

            <RegisterInput
              v-model="password"
              type="password"
              placeholder="Mot de passe"
            />
            <RegisterInput
              v-model="passwordConfirmation"
              :error="errors.passwordConfirmation"
              type="password"
              placeholder="Confirmez votre mot de passe"
            />
            <div
              :class="['mt-2 text-xs', errors.password
                ? 'text-orange-light'
                : 'text-gray-300'
              ]"
            >
              {{ errors.password || passwordInfo }}
            </div>
          </div>
        </div>
        <BaseAlert
          v-if="errors.page"
          type="error"
          title="Erreur"
        >
          {{ errors.page }}
        </BaseAlert>
        <button
          type="button"
          class="flex justify-center mx-auto py-3 px-7 mt-8 text-xl text-primary-dark rounded-full bg-secondary"
          @click="register"
        >
          Créer mon compte
        </button>
      </div>
      <div v-else>
        <RegisterSuccess
          :user="registeredUser"
        />
      </div>
    </form>
  </div>
</template>

<script>

import { usersResource } from '@/renderer/container'
import BaseAlert from '@/renderer/components/base/BaseAlert.vue'
import RegisterInput from '@/renderer/components/views/register/RegisterInput.vue'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import passwordPolicyHelper from '@/renderer/helpers/passwordPolicyHelper'
import RegisterSuccess from '@/renderer/components/views/register/RegisterSuccess.vue'

export default {
  name: 'RegisterGuest',

  components: {
    BaseAlert,
    RegisterInput,
    LottieAnimation,
    RegisterSuccess
  },

  data () {
    return {
      errors: [],
      loading: true,
      guestId: null,
      firstname: null,
      lastname: null,
      gender: null,
      email: null,
      password: null,
      passwordConfirmation: null,
      registeredUser: null,
      rankId: null,
      ranks: {
        3: 'Préparateurs',
        6: 'Pharmacien assistant',
        7: 'Esthéticienne',
        8: 'Rayonniste',
        9: 'Apprenti',
        10: 'Étudiant en pharmacie'
      }
    }
  },

  computed: {
    isEmailValid () {
      return this.email && this.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    },

    submitEnabled () {
      return (this.firstname !== null && this.firstname.length > 0) &&
        (this.lastname !== null && this.firstname.length > 0) &&
        (this.gender !== null) &&
        this.isEmailValid &&
        (this.rankId !== null) &&
        (this.password === this.passwordConfirmation) &&
        passwordPolicyHelper.isValid(this.password)
    },

    passwordInfo () {
      if (passwordPolicyHelper.isValid(this.password)) {
        return 'Votre mot de passe est sécurisé 👍'
      } else {
        var info = 'Votre mot de passe doit contenir'
        if (!passwordPolicyHelper.hasMinimumLength(this.password)) {
          info += ' au moins ' + String(passwordPolicyHelper.minLength) + ' caractères,'
        }
        if (!passwordPolicyHelper.hasNumber(this.password)) {
          info += ' un nombre,'
        }
        if (!passwordPolicyHelper.hasLowercase(this.password)) {
          info += ' un caractère minuscule,'
        }
        if (!passwordPolicyHelper.hasUppercase(this.password)) {
          info += ' un caractère majuscule,'
        }
        if (!passwordPolicyHelper.hasSpecial(this.password)) {
          info += ' un caractère spécial,'
        }
        return info.replace(/.$/, '.')
      }
    }
  },

  mounted () {
    usersResource()
      .getByInvitationHash(this.$route.params.hash)
      .then((guest) => {
        this.email = guest.email
        this.guestId = guest.id
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  },

  methods: {
    register () {
      if (this.submitEnabled) {
        const user = {
          given: this.firstname,
          familyBirth: this.lastname,
          gender: this.gender,
          email: this.email,
          password: this.password,
          guestId: this.guestId,
          rankId: this.rankId
        }
        usersResource()
          .registerFromInvitation(user)
          .then((response) => {
            this.registeredUser = response.user
          })
          .catch(() => {
            this.errors = { page: 'Un utilisateur existe déjà avec cet email' }
          })
      } else {
        const errorsToDisplay = []
        if (this.firstname === null || this.firstname.length === 0) {
          errorsToDisplay.firstname = 'Prénom requis'
        }
        if (this.lastname === null || this.lastname.length === 0) {
          errorsToDisplay.lastname = 'Nom requis'
        }
        if (this.gender === null) {
          errorsToDisplay.gender = 'Genre requis'
        }
        if (!this.isEmailValid) {
          errorsToDisplay.email = 'Email non valide'
        }
        if (this.rankId === null) {
          errorsToDisplay.rank = 'Poste non valide'
        }

        if (!passwordPolicyHelper.isValid(this.password)) {
          errorsToDisplay.password = this.passwordInfo
        } else if (this.password !== this.passwordConfirmation) {
          errorsToDisplay.password = 'Les 2 mots de passe entrés ne correspondent pas'
        }

        this.errors = errorsToDisplay
      }
    }
  }
}
</script>
