<template>
  <div
    v-show="visible"
    class="modal-wrapper gradient flex-col flex flex-1 h-full w-full"
  >
    <div class="modal-header flex flex-row justify-around p-4">
      <h1 class="text-3xl text-white font-bold">
        Rechercher un professionnel de santé
      </h1>
      <CloseIcon
        :color="white"
        @click.native="close"
      />
    </div>
    <div class="flex flex-1 flex-col">
      <PrescriptorSearchBar
        :selected-filter="selectedFilters"
        :processing="isLoading"
        :on-press="select"
      />
      <Filters
        :filters="specialityFilters"
        :selected-filters="selectedFilters"
        @onFilterChange="filtersUpdate"
      />
      <PrescriptorPopupInformation
        v-if="prescriptorPopup"
        :is-visible="popupVisible"
        :prescriptor="prescriptorPopup"
      />
      <gmap-map
        ref="mapRef"
        :center="region"
        :zoom="12"
        :options="{ streetViewControl: false, mapTypeControl: false, fullscreenControl: false, clickableIcons: false}"
        map-type-id="roadmap"
        street-view-control="false"
        style="flex: 1"
        @click="popupVisible=false"
      >
        <gmap-marker
          v-for="(prescriptor, index) in prescriptors"
          :key="index"
          :position="{lat: prescriptor.address.latitude, lng: prescriptor.address.longitude}"
          :clickable="true"
          style="color: #3513e2"
          @click="displayPopup(prescriptor)"
        />
      </gmap-map>
    </div>
  </div>
</template>

<script>

import { prescriptorResource } from '@/renderer/container'
import PrescriptorSearchBar from '@/renderer/app/prescriptor/components/PrescriptorSearchBar.vue'
import Filters from '@/renderer/app/core/components/Filters.vue'
import PrescriptorPopupInformation from '@/renderer/app/prescriptor/components/PrescriptorPopupInformation.vue'
import PrescriptorMapModal from '@/renderer/plugins/prescriptor-map-modal'
import CloseIcon from '@/assets/images/icons-vue/close-solid.vue'
import { white } from 'tailwindcss/colors'

export default {
  name: 'PrescriptorMapSearch',
  components: {
    PrescriptorPopupInformation,
    Filters,
    PrescriptorSearchBar,
    CloseIcon
  },
  data () {
    return {
      visible: false,
      region: {
        lat: 48.856578,
        lng: 2.351828
      },
      isLoading: true,
      selectedFilters: [],
      specialityFilters: [
        {
          name: 'Généraliste',
          ids: [3, 5, 21]
        },
        {
          name: 'Dermatologue',
          ids: [28]
        },
        {
          name: 'Cardiologue',
          ids: [15, 16]
        },
        {
          name: 'Ophtalmologue',
          ids: [8]
        },
        {
          name: 'Pédiatre',
          ids: [14]
        },
        {
          name: 'Dentiste',
          ids: [9, 24]
        }
      ],
      prescriptors: [],
      prescriptorPopup: null,
      popupVisible: false,
      markerOptions: {
        url: require('../../../../assets/images/icons/marker-filled.svg'),
        size: {
          width: 40,
          height: 70,
          f: 'px',
          b: 'px'
        },
        scaledSize: {
          width: 20,
          height: 35,
          f: 'px',
          b: 'px'
        }
      }
    }
  },
  beforeCreate () {
    PrescriptorMapModal.EventBus.$off('start')
    PrescriptorMapModal.EventBus.$on('start', (params) => this.start(params))
  },
  methods: {
    white () {
      return white
    },
    /**
     * Show the modal.
     * @param {Object} params The parameters.
     */
    start (params) {
      this.selectedFilters = params.specialityIds
      this.visible = true
      this.initiateListener()
      this.setLocation(
        this.$store.state.system.environment.entity.address.latitude,
        this.$store.state.system.environment.entity.address.longitude
      )
    },
    /**
     * Close the modal.
     */
    close () {
      this.visible = false
    },
    /**
     * We need to 'initialize' the map with the $mapPromise.then, when done we add two listener to dragend and
     * zoom_changed that will then update the location.
     */
    initiateListener () {
      if (this.$refs.mapRef) {
        this.$refs.mapRef.$mapPromise.then((map) => {
          map.addListener('dragend', () => this.setLocation(map.center.lat(), map.center.lng()))
          map.addListener('zoom_changed', () => this.setLocation(map.center.lat(), map.center.lng()))
        })
      }
    },
    /**
     * Set new location latitude and longitude, and load prescriptor accordingly.
     *
     * @param {number} lat Latitude
     * @param {number} lng Longitude
     */
    setLocation (lat, lng) {
      this.region = {
        lat,
        lng
      }
      this.load()
    },
    /**
     * Load new prescriptors based on the provided params.
     */
    load () {
      this.isLoading = true
      prescriptorResource().search({
        lat: this.region.lat,
        lon: this.region.lng,
        speciality_ids: this.selectedFilters
      })
        .then(response => {
          this.prescriptors = response
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    /**
     * Display basic prescriptor information.
     *
     * @param {Object} prescriptor Prescriptor information.
     */
    displayPopup (prescriptor) {
      this.prescriptorPopup = prescriptor
      this.popupVisible = true
    },
    /**
     * Action when a prescriptor is selected.
     *
     * @param {Object} prescriptor Prescriptor object.
     */
    select (prescriptor) {
      this.displayPopup(prescriptor)
    },
    /**
     * Update the selected filters, and call onFilterUpdate then.
     *
     * @param {Object} filter Filter object.
     */
    filtersUpdate (filter) {
      const filterIndex = this.selectedFilters.findIndex(selected => filter.ids.includes(selected))
      if (filterIndex === -1) {
        this.selectedFilters.push(...filter.ids)
      } else {
        this.selectedFilters.splice(filterIndex, filter.ids.length)
      }

      this.load()
    }
  }
}
</script>

<style scoped>
.modal-wrapper {
  position: fixed;
  z-index: 1000;
}

.modal-header {
  font-size: 1.2rem;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}
</style>
