<template>
  <product-availabity-card
    :title="title"
    class="cursor-pointer"
    loading-message="Interrogation des stocks des pharmacies partenaires"
    :loading="data.loading"
    :is-available="data.availability"
    :messages="message"
    :success="!!memberCounts"
    @click.native="scrollToGroupMap"
  >
    <template #icon>
      <icon
        name="users-alt"
        :size="40"
        color="black"
      />
    </template>
  </product-availabity-card>
</template>

<script>
import Icon from '@/renderer/app/core/icon/Icon.vue'
import ProductAvailabityCard
from '@/renderer/app/disruption-logistics/components/product-availability-card/ProductAvailabityCard.vue'

export default {
  name: 'HealthcenterGroupProductAvailabilityCard',
  components: { ProductAvailabityCard, Icon },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    /**
     * Get the title.
     *
     * @return {string}
     */
    title () {
      if (!this.memberCounts) {
        return 'Vous n\'avez pas de pharmacie partenaire'
      }

      return `${this.availabilityLabel(this.data)} dans une pharmacie partenaire`
    },
    /**
     * Get the message.
     *
     * @return {string[]}
     */
    message () {
      if (!this.memberCounts) {
        return ['Invitez des pharmacies et accédez à leurs stock ainsi qu\'à leurs disponibilité en agences']
      }

      return []
    },
    /**
     * Get the member counts.
     *
     * @return {number}
     */
    memberCounts () {
      return this.data?.details?.length ?? 0
    }
  },
  methods: {
    /**
     * Get the availability label.
     *
     * @param {Object} provider
     *
     * @return {string}
     */
    availabilityLabel (provider) {
      if (provider.availability) {
        return 'Disponible'
      } else if (!provider.availability) {
        return 'Indisponible'
      }
    },
    /**
     * Scroll to the group map.
     */
    scrollToGroupMap () {
      const element = document.getElementById('group-map')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }
}
</script>
