<template>
  <div>
    <base-spinner v-if="loading" />
    <div v-else>
      <div v-if="customers.length">
        <information-panel>
          <div class="flex flex-row align-center">
            <img
              alt="Certifié"
              class="rounded-xl bg-white p-2 cursor-pointer mr-5"
              :src="require('@/assets/images/icons/certified.svg')"
            >
            <p class="font-bold text-white">
              Vous avez {{ meta.total }} {{ 'patient' | pluralize(meta.total) }}
              {{ 'certifié' | pluralize(meta.total) }} dans votre pharmacie
            </p>
          </div>
        </information-panel>
        <div class="px-4 pb-4">
          <v-expansion-panels
            v-model="panel"
          >
            <v-expansion-panel
              v-if="uncertifiedCustomers.length"
              class="rounded-lg border-orange-default border-2"
            >
              <v-expansion-panel-header>
                Vous avez {{ uncertifiedCustomers.length }} {{ 'patient' | pluralize(uncertifiedCustomers.length) }}
                en attente de certification
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p class="text-primary">
                  Ces patients se sont inscrits et ont choisi votre pharmacie. En certifiant leur compte, ils pourront
                  accéder à leurs données (ordonnances, etc.) et bénéficier des services Apodis.
                </p>
                <div
                  v-for="(uncertifiedCustomer, index) in uncertifiedCustomers"
                  :key="index"
                  class="flex flex-row p-2 align-center justify-space-between"
                >
                  <div class="flex flex-1 flex-col">
                    <span class="font-bold">
                      {{ userName(uncertifiedCustomer) }}
                    </span>
                    <span>{{ uncertifiedCustomer.phone | phoneFormat }}</span>
                  </div>
                  <div class="flex flex-grow">
                    <span>s'est inscrit le {{ uncertifiedCustomer.created_at | date('short') }}</span>
                  </div>
                  <div class="flex flex-2 justify-end ">
                    <base-button
                      primary
                      @click="onCertifyPress(uncertifiedCustomer)"
                    >
                      Certifier
                    </base-button>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <customer-search
          class="mx-4"
          :input="searchInput"
        />
        <div v-if="loadingMore">
          <base-spinner size="small" />
        </div>
        <div v-else>
          <div class="flex flex-row px-10 pt-4">
            <span class="flex flex-1">Nom</span>
            <span class="flex flex-1">Date de certification</span>
            <span class="flex flex-1">Dernière visite</span>
          </div>
          <base-card
            v-for="customer in customers"
            :key="customer.id"
            class="m-4 hover:opacity-50 cursor-pointer"
          >
            <base-card-body>
              <div
                class="flex flex-row"
                @click="goToPatient(customer)"
              >
                <span class="flex flex-1">{{ customer.information.name }}</span>
                <span class="flex flex-1">{{ customer.validated_at | date('short') }}</span>
                <span
                  v-if="customer.last_visit?.date"
                  class="flex flex-1"
                >
                  {{ customer.last_visit.date | date('short') }}
                </span>
                <span
                  v-else
                  class="flex flex-1"
                >-</span>
              </div>
            </base-card-body>
          </base-card>
        </div>
        <customer-remote-certification
          v-if="modalVisible"
          :customer="customerToCertify"
          @close="modalVisible=false;customerToCertify=null"
        />
        <div
          v-if="meta.last_page !== 1"
          class="text-center mb-4"
        >
          <v-pagination
            v-model="page"
            :length="meta.last_page"
            :total-visible="7"
            circle
          />
        </div>
      </div>
      <div v-else>
        <span>Aucun patients certifiés</span>
      </div>
    </div>
  </div>
</template>

<script>
import { customerResource, healthcenterUserApi } from '@/renderer/container'
import BaseSpinner from '@/renderer/components/base/spinner/BaseSpinner.vue'
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import BaseCardBody from '@/renderer/components/base/BaseCardBody.vue'
import InformationPanel from '@/renderer/app/core/components/InformationPanel.vue'
import CustomerSearch from '@/renderer/app/customer/components/customer-search/CustomerSearch.vue'
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import CustomerRemoteCertification
from '@/renderer/app/customer/components/customer-remote-certification/CustomerRemoteCertification.vue'

export default {
  name: 'CustomersListScreen',
  components: {
    CustomerRemoteCertification,
    BaseButton,
    CustomerSearch,
    InformationPanel,
    BaseCardBody,
    BaseCard,
    BaseSpinner
  },
  data () {
    return {
      customers: [],
      uncertifiedCustomers: [],
      searchInput: '',
      customerToCertify: null,
      loading: false,
      loadingMore: false,
      meta: null,
      page: 1,
      panel: [0, 1],
      modalVisible: false
    }
  },
  watch: {
    page: {
      handler () {
        this.loadingMore = true
        this.getCustomers()
          .finally(() => {
            this.loadingMore = false
          })
      }
    }
  },
  mounted () {
    this.loading = true
    this.getCustomers()
      .then(() => this.getPendingCertificationUsers())
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    /**
     * Get certified customers.
     *
     * @return {Promise}
     */
    getCustomers () {
      return customerResource().get({ certified: true, page: this.page })
        .then(response => {
          this.customers = response.data
          this.meta = response.meta
        })
    },
    /**
     * Get user which have a pending certification.
     *
     * @return {Promise}
     */
    getPendingCertificationUsers () {
      return healthcenterUserApi().index({ certification_status: 'pending' })
        .then(response => {
          this.uncertifiedCustomers = response
        })
    },
    /**
     * Get user full name.
     *
     * @param user
     *
     * @return {string}
     */
    userName (user) {
      return `${user.first_name} ${user.last_name}`
    },
    /**
     * Go to patient details page.
     *
     * @param customer
     */
    goToPatient (customer) {
      this.$router.push({
        name: 'customer.home',
        params: {
          customerId: customer.id
        }
      })
    },
    /**
     * On certify, show modal with customer name passed as an argument. Also clear; some eventual; previously searched
     * customers to avoid display issues.
     *
     * @param uncertifiedCustomer
     */
    onCertifyPress (uncertifiedCustomer) {
      this.searchInput = ''
      this.customerToCertify = uncertifiedCustomer
      this.modalVisible = true
    }
  }
}
</script>
