/**
 * The shop cart resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Read a shop cart.
     * @param {number} laboratoryId - The ID of the laboratory.
     * @param {number} healthcenterId - The ID of the healthcenter.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    read (laboratoryId, healthcenterId) {
      return client
        .get(`/shop/laboratories/${laboratoryId}/healthcenters/${healthcenterId}/cart`, {
          params: {
            include: 'lines.product.laboratoryPriceRanges.laboratoryPrice'
          }
        })
        .then(response => response.data.data)
    },

    /**
     * Update a shop cart.
     * @param {number} laboratoryId - The ID of the laboratory.
     * @param {number} healthcenterId - The ID of the healthcenter.
     * @param {Object} products - The product container with quantities.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    update (laboratoryId, healthcenterId, products) {
      return client
        .post(`/shop/laboratories/${laboratoryId}/healthcenters/${healthcenterId}/manageCart`, {
          products
        })
        .then(response => response.data.data)
    },

    /**
     * Validate a shop cart.
     * @param {number} laboratoryId - The ID of the laboratory.
     * @param {number} healthcenterId - The ID of the healthcenter.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    validate (laboratoryId, healthcenterId) {
      return client
        .get(`/shop/laboratories/${laboratoryId}/healthcenters/${healthcenterId}/validateCart`)
        .then(response => response.data.data)
    },

    /**
     * Empty a shop cart.
     * @param {number} laboratoryId - The ID of the laboratory.
     * @param {number} healthcenterId - The ID of the healthcenter.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    empty (laboratoryId, healthcenterId) {
      return client
        .get(`/shop/laboratories/${laboratoryId}/healthcenters/${healthcenterId}/emptyCart`)
        .then(response => response.data.data)
    }
  }
}
