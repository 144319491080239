<template>
  <button
    class="flex card shadow-md p-4 bg-white gap-4 rounded-md items-center relative"
    @click="$router.push({name: 'disruption-logistics.healthcenter-groups'})"
  >
    <healthcenter-group-icon />
    <span class="flex flex-col justify-start text-start w-full">
      <span class="text-lg">
        Gérer mes pharmacies partenaires
      </span>
      <span v-if="loading">
        <v-skeleton-loader
          class="w-full max-w-lg top-1"
          height="20"
          type="text"
        />
      </span>
      <span
        v-else-if="!invitations?.length"
        class="text-primary"
      >
        Invitez des pharmacies à vous rejoindre pour combattre les ruptures 👊
      </span>
      <span
        v-else
        class="text-primary"
      >
        Vous avez {{ invitations.length }} {{ 'invitation' | pluralize(invitations.length) }} !
      </span>
    </span>
    <span
      v-if="invitations?.length"
      class="w-6 h-6 text-sm flex items-center justify-center font-content-bold rounded-full bg-primary text-white absolute -top-3 -right-3 truncate"
    >
      {{ invitations.length }}
    </span>
  </button>
</template>

<script>
import { disruptionLogisticsApi } from '@/renderer/container'
import HealthcenterGroupIcon
from '@/renderer/app/disruption-logistics/components/healthcenter-groups/HealthcenterGroupIcon.vue'

export default {
  name: 'HealthcenterGroupCard',
  components: { HealthcenterGroupIcon },
  data () {
    return {
      invitations: null,
      loading: false
    }
  },
  mounted () {
    this.loading = true
    disruptionLogisticsApi()
      .receivedHealthcenterGroupInvitations('pending')
      .then(invitations => {
        this.invitations = invitations
      })
      .finally(() => {
        this.loading = false
      })
  }
}
</script>
