<template>
  <div>
    <fade>
      <div
        v-if="componentConfig"
        class="fixed inset-0 bg-gray-200 z-50 bg-opacity-60 cursor-pointer"
        @click="close"
      />
    </fade>
    <slide-right-to-left>
      <div
        v-if="componentConfig"
        class="fixed right-0 top-0 h-full bg-white shadow-md cursor-default flex flex-col overflow-y-scroll"
        style="z-index: 55; transition: width 0.5s ease, transform 0.5s ease;"
        :style="{
          width: expanded ? '100%' : componentConfig.minWidth
        }"
      >
        <div class="flex justify-between p-3 items-center">
          <button
            class="mt-2"
            @click="expanded = !expanded"
          >
            <icon
              :name="expanded ? 'arrow-from-right' : 'left-arrow-from-left'"
              :size="25"
            />
          </button>
          <button
            class="font-bold"
            @click="close"
          >
            Fermer
          </button>
        </div>
        <div class="flex-1">
          <component :is="componentConfig.component" />
        </div>
      </div>
    </slide-right-to-left>
  </div>
</template>

<script>
import Fade from '@/renderer/app/core/components/transitions/Fade.vue'
import SlideRightToLeft from '@/renderer/app/core/components/transitions/SlideRightToLeft.vue'
import Icon from '@/renderer/app/core/icon/Icon.vue'

export default {
  name: 'InterviewShortCutDrawerElement',
  components: { Icon, SlideRightToLeft, Fade },
  props: {
    componentConfig: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      expanded: false
    }
  },
  methods: {
    close () {
      this.$emit('close')
      this.expanded = false
    }
  }
}
</script>
