<template>
  <div class="flex flex-row my-3">
    <div class="border-b border-gray-200 flex-1 mb-2 mr-3" />
    <p class="text-tiny">
      {{ label }}
    </p>
    <div class="border-b border-gray-200 flex-1 mb-2 ml-3" />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    }
  }
}
</script>
