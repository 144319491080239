<template>
  <div>
    <div class="flex mb-1 items-center justify-between">
      <div>
        <span class="text-xs font-semibold inline-block py-1 uppercase rounded-full text-gray-600">
          Franco : {{ franco.francoValue }}€ HT
        </span>
      </div>

      <div class="text-right">
        <span class="text-xs font-semibold inline-block text-gray-900">
          {{ progress }}
        </span>
      </div>
    </div>

    <div class="overflow-hidden h-2 text-xs flex rounded bg-gray-200">
      <div
        :style="`width:${progress}; ${branding.backgroundColor}`"
        class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-gray-500"
      />
    </div>
  </div>
</template>

<script>
import {
  shopCartResource,
  laboratoryFrancoResource
} from '@/renderer/container'

import brandingMixin from '@/renderer/mixins/brandingMixin'

export default {
  mixins: [
    brandingMixin
  ],

  props: {
    laboratoryId: {
      type: [
        String,
        Number
      ],

      required: true
    }
  },

  data () {
    return {
      cart: {
        lines: []
      },

      franco: {
        francoValue: null
      },

      progress: 0
    }
  },

  watch: {
    '$store.state.quantity': {
      immediate: true,

      async handler (value) {
        const cart = await shopCartResource()
          .read(
            this.laboratoryId,
            this.$store.state.system.environment.entityId
          )

        const cartTotal = cart.lines.reduce((total, line) => {
          const amount = this.franco.laboratoryFrancoTypeId === 1
            ? line.quantity
            : line.totalDiscountedWithoutVat

          return total + amount
        }, 0)

        const percent = this.franco.francoValue > 0
          ? cartTotal / this.franco.francoValue * 100
          : 0

        const progress = Math.min(percent, 100).toFixed() + '%'

        this.progress = progress
        this.$emit('progress', progress)
      }
    }
  },

  async mounted () {
    this.franco = await laboratoryFrancoResource()
      .read(
        this.laboratoryId,
        this.$store.state.system.environment.entityId
      )
  }
}
</script>
