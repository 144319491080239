/**
 * The notification resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    /**
     * Get all notifications.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    get (page) {
      return client
        .get('/notifications', {
          params: {
            page,
            pagination: 20,
            not_legacy: true
          }
        })
        .then(response => response.data)
    },

    /**
     * Mark a notification as read.
     * @param {Object} notification - The notification to mark.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    mark (notification) {
      return client
        .patch(`/notifications/${notification.id}`)
        .then(response => response.data.data)
    },

    /**
     * Count unread notifications.
     *
     * @return {Promise}
     */
    count () {
      return client
        .get('/notifications/count')
        .then(response => response.data.data)
    },

    /**
     * Mark all notifications as read.
     *
     * @returns {Promise}
     */
    markAll () {
      return client
        .patch('/notifications')
        .then(response => response.data)
    }
  }
}
