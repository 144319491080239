export default function (apiClient, hdsClient) {
  return {
    /** Get all interviews.
     *
     * @returns {Promise<Object>}
     */
    index () {
      return apiClient.get('interviews')
        .then(response => response.data.data)
    },

    /** Get an interview by its id.
     *
     * @param {Number} interviewId The interview id.
     *
     * @returns {Promise<Object>}
     */
    read (interviewId) {
      return apiClient.get(`interviews/${interviewId}`)
        .then(response => response.data.data)
    },
    /**
     * Store a new interview instance.
     *
     * @param {Number} interviewId The interview ID.
     * @param {String} consumerKey The consumer key for which the interview instance is being created.
     * @param {{
     *   survey_answers: Array<{
     *     question_id: Number,
     *     answer: Array
     *   }>
     * }} params The interview instance parameters.
     *
     * @returns {Promise<Object>}
     */
    storeInstance (interviewId, consumerKey, params) {
      return hdsClient.post(`consumers/${consumerKey}/interviews/${interviewId}/instances`, params)
        .then(response => response.data.data)
    },
    /**
     * Get the resources for an interview.
     *
     * @param {Number} interviewId The interview ID.
     */
    attachments (interviewId) {
      return apiClient.get(`interviews/${interviewId}/attachments`)
        .then(response => response.data.data)
    },

    /**
     * Get the interview questions.
     *
     * @param {Number} interviewId The interview ID.
     * @param {Blob} audio The interview ID.
     * @param {String} consumerKey The consumer key for which the interview is being transcript.
     *
     * @returns {Promise<Object>}
     */
    transcript (interviewId, audio, consumerKey) {
      const formData = new FormData()
      formData.append('audio', audio)

      return hdsClient.post(`consumers/${consumerKey}/interviews/${interviewId}/transcripts`, formData)
        .then(response => response.data.data)
    },
    /**
     * Get the interview survey answers from the transcript filled with LLM service.
     *
     * @param {Number} interviewId - The interview ID.
     * @param {String} transcriptId - The transcript ID to use for prefilling the survey.
     * @param {String} transcriptText - The transcript text.
     *
     * @returns {Promise<Object>}
     */
    prefillInterviewSurvey (interviewId, transcriptId, transcriptText) {
      return apiClient.post(`interviews/${interviewId}/transcripts/${transcriptId}/survey/prefill`, {
        data: {
          transcript: transcriptText
        }
      })
        .then(response => response.data.data)
    },

    /**
     * Get the interview instances for a consumer.
     * @param consumerKey
     *
     * @returns {Promise<Object>}
     */
    indexConsumerInstances (consumerKey) {
      return hdsClient.get(`consumers/${consumerKey}/interview-instances`)
        .then(response => response.data.data)
    },

    /**
     * Create a note for the consumer and the interview.
     *
     * @param {String} consumerKey The consumer key
     * @param {Number} interviewId The interview ID
     * @param {String} content The content of the note
     *
     * @returns {Promise<Object>}
     */
    createNote (consumerKey, interviewId, content) {
      const data = {
        data: {
          content
        }
      }

      return hdsClient.post(`consumers/${consumerKey}/interviews/${interviewId}/notes`, data)
        .then(response => response.data.data)
    },

    /**
     * Get the notes for the consumer.
     *
     * @param {String} consumerKey The consumer key
     * @param {Number} page The page number
     * @param {Number} limit The limit of notes per page
     *
     * @returns {Promise<Object>}
     */
    indexNotes (consumerKey, page = 1, limit = 10) {
      const params = {
        page,
        limit
      }

      return hdsClient.get(`consumers/${consumerKey}/interview-notes`, { params })
        .then(response => response.data)
    }
  }
}
