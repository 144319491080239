export default {
  data () {
    return {
      opened: false
    }
  },

  methods: {
    open () {
      this.opened = true
    },

    close () {
      this.opened = false
    }
  }
}
