<template>
  <div class="flex flex-row justify-between">
    <div class="flex flex-row items-center">
      <img
        class="h-7 md:h-14 w-auto shadow"
        src="@/assets/images/icons/logo-apodis.png"
        alt="Apodis"
      >
      <div class="flex flex-col ml-5">
        <span class="text-2xl text-primary font-heading font-bold">Equiper le patient de l'application Apodis</span>
        <span class="text-xl font-content">Gérer la relation avec la patientèle</span>
      </div>
    </div>
    <span
      class="relative z-3 text-primary text-sm underline cursor-pointer"
      @click="open"
    >
      Qu'est-ce que c'est ?
    </span>
    <BaseModal
      v-show="isModalVisible"
      ref="modal"
      name="toto"
      @close="isModalVisible = false"
    >
      <BaseVideo reference="F3yA79GfLFA" />
    </BaseModal>
  </div>
</template>

<script>
import BaseVideo from '@/renderer/components/base/BaseVideo.vue'
import BaseModal from '@/renderer/components/base/BaseModal.vue'

export default {
  name: 'CustomerOnboardingHeader',
  components: {
    BaseVideo,
    BaseModal
  },
  data () {
    return {
      isModalVisible: false
    }
  },
  methods: {
    open () {
      this.isModalVisible = true
    }
  }
}
</script>
