<template>
  <div>
    <CustomerMedicineCabinet
      v-if="medicineCabinetProgram"
      :program="medicineCabinetProgram"
      :customer="customer"
      class="flex"
    />
  </div>
</template>

<script>
import SupportProgramDescription from '@/renderer/app/program/components/support-program/SupportProgramDescription.vue'
import { programResource } from '@/renderer/container'
import CustomerMedicineCabinet from '@/renderer/app/customer/components/customer-medicine-cabinet/CustomerMedicineCabinet.vue'
import BaseFlexSpinner from '@/renderer/components/base/spinner/BaseFlexSpinner.vue'

export default {
  name: 'CustomerFeatureProgram',
  components: { BaseFlexSpinner, CustomerMedicineCabinet, SupportProgramDescription },
  data () {
    return {
      program: null,
      customer: null
    }
  },
  computed: {
    medicineCabinetProgram () {
      if (this.program && this.program.programmable_type === 'feature') {
        return this.program
      }
    }
  },
  watch: {
    '$route.params': {
      immediate: true,

      handler (params) {
        if (params.program) {
          this.load(params)
        } else if (params.programId) {
          programResource()
            .read(params.programId)
            .then((program) => this.load({
              program,
              customer: this.$store.getters['patient/customer']()
            }))
        }
      }
    }
  },
  methods: {
    load (params) {
      this.program = params.program
      this.customer = params.customer
    }
  }
}
</script>
