/**
 * The loyalty card resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @param {Object} hdsClient - The injected client.
 * @returns {Object} The public object.
 */
export default function (client, hdsClient) {
  return {
    /**
     * Get all loyalty cards.
     * @returns {Promise} Promise object represents the HTTP request.
     */
    get () {
      return client.get('/loyalty-cards', {
        params: {
          include: 'organization.morphable,file,objectives.type,objectives.reward.type'
        }
      })
        .then(response => response.data.data)
        .catch(() => [])
    },

    getLoyalcardStatistics (id) {
      return hdsClient.get(`loyalty-cards/${id}/healthcenter/analysis`)
        .then(response => response.data.data)
        .catch(() => [])
    },

    /**
     * Get the loyalty card membership analysis.
     *
     * @param {Number} id - The loyalty card id.
     * @param {Number} page - The page number to get.
     * @param {Number} limit - The limit par page.
     *
     * @return {Promise<Object>}
     */
    getMembership (id, page = 1, limit = 10) {
      const params = {
        page,
        limit
      }

      return hdsClient.get(`loyalty-cards/${id}/memberships/analysis`, { params })
        .then(response => response.data)
        .catch(() => [])
    },

    /**
     * List animation belonging to a loyalty card that were created by the provided healthcenter.
     *
     * @param {number} healthcenterId
     * @param {number} loyaltyCardId
     *
     * @return {Promise}
     */
    animations (healthcenterId, loyaltyCardId) {
      return client.get(`/healthcenters/${healthcenterId}/loyalty-cards/${loyaltyCardId}/animations`)
        .then(response => response.data.data)
    },

    /**
     * List animation transactions.
     *
     * @param {number} animationId
     *
     * @return {Promise}
     */
    animationTransactions (animationId) {
      return hdsClient.get(`loyalty-card-animations/${animationId}/transactions`)
        .then(response => response.data.data)
    }
  }
}
