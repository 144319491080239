<template>
  <div class="flex w-full items-center justify-center">
    <div
      v-if="step === 'authorizing'"
      class="text-white text-xl"
    >
      Authentification e-CPS...
    </div>
    <div
      v-else-if="step === 'loading'"
      class="text-white text-xl"
    >
      Récupération des infos e-CPS...
    </div>
    <div
      v-else-if="step === 'form'"
    >
      <RegisterForm
        :ecps="ecpsInfos"
        @validate="setUserInfos($event)"
      />
    </div>
    <div
      v-else-if="step === 'map'"
      class="flex h-screen w-full"
    >
      <RegisterMapView
        :postal-code="postalCode"
        @validate="healthcenterSelected"
      />
    </div>
    <div
      v-else-if="step === 'success'"
      class="text-white text-xl"
    >
      <RegisterSuccess
        :user="user"
        :healthcenter="healthcenter"
        @login="login"
      />
    </div>
    <div v-else>
      <RegisterBefore />
    </div>
  </div>
</template>

<script>
import { proSanteConnectResource, authenticationService, storageService, registrationService } from '@/renderer/container'
import RegisterBefore from '@/renderer/components/views/register/RegisterBefore.vue'
import RegisterForm from '@/renderer/components/views/register/RegisterForm.vue'
import RegisterMapView from '@/renderer/components/views/register/RegisterMapView.vue'
import RegisterSuccess from '@/renderer/components/views/register/RegisterSuccess.vue'
import electronHelper from '@/main/utils/electronHelper'
import { PSC_STATES } from '@/renderer/helpers/pscHelper'
import jwtDecode from 'jwt-decode'

const PHARMACIST_CODE = '21'

export default {
  name: 'Register',

  components: {
    RegisterBefore,
    RegisterForm,
    RegisterMapView,
    RegisterSuccess
  },

  data () {
    return {
      step: '',
      ecpsInfos: null,
      user: null,
      healthcenter: null
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler () {
        this.handlePCSAuth()
      }
    }
  },

  methods: {
    /**
     * Handle action after pcs authentication.
     */
    handlePCSAuth () {
      if (this.$route.query && this.$route.query.code) {
        const redirect = process.env.VUE_APP_PROSANTECONNECT_CALLBACK_URL
        this.step = 'authorizing'

        if (this.$route.query.state === 'chu_login') {
          window.location.href = process.env.VUE_APP_CHU_PSC_CALLBACK_URL + '?code=' + this.$route.query.code
          return
        } else if (this.$route.query.state === PSC_STATES.deciphera) {
          window.location.href = process.env.VUE_APP_DECIPHERA_REGISTER_URL + '?code=' + this.$route.query.code
          return
        }

        if (this.$route.query.state === PSC_STATES.linkcps) {
          this.linkPSC(redirect)
        } else {
          this.loginRegisterPSC(redirect)
        }
      }
    },

    setUserInfos (event) {
      this.user = event
      this.postalCode = event.postalCode
      this.step = 'map'
    },

    healthcenterSelected (healthcenter) {
      this.healthcenter = healthcenter
      const healthceterAddress = healthcenter.geo.city.address1 + ' ' + healthcenter.geo.city.postal_code + ' ' + healthcenter.geo.city.name

      const registerUser = {
        access: {
          accessPointType: 2,
          active: 1,
          address: healthceterAddress,
          entityId: healthcenter.id,
          name: healthcenter.name,
          rankId: 1,
          cps: this.ecpsInfos && this.ecpsInfos.sub
        },
        email: this.user.email,
        firstName: this.user.firstname,
        gender: this.user.gender,
        lgo: this.user.lgo,
        name: this.user.lastname,
        parrainage: this.user.referral,
        pwd: this.user.password,
        groupmentId: this.user.groupingId,
        type: 'pharmacist'
      }
      registrationService()
        .register(registerUser)
        .then(user => {
          this.step = 'success'
          this.$analytics.logEvent('sign_up', {
            method: 'cps',
            email: user.email
          })
        })
    },

    login () {
      if (this.user) {
        authenticationService()
          .login(this.user.email, this.user.password)
          .then(organization => {
            this.start()
          })
          .catch(error => {
            this.error = error.response
              ? error.response.data.message
              : error
          })
      }
    },

    start () {
      this.$router.push({
        name: electronHelper.isElectron() ? 'home' : 'onboarding'
      })

      if (window.ipcRenderer) {
        window.ipcRenderer.send('authenticated', storageService().loadSettings())
      }
    },
    /**
     * Function called to handle the link cps after going through the psc dedicated authentication window.
     *
     * @param redirect
     */
    linkPSC (redirect) {
      setTimeout(() => { // apparently PSC is not fast enough to handle code directly
        proSanteConnectResource()
          .linkCPS(this.$route.query.code, redirect)
          .then(response => {
            if (response.token) {
              storageService().store('token', response.token)
              this.$analytics.logEvent('linked_cps', { email: jwtDecode(response.token)?.user?.email || 'unknown' })
              this.start()
            } else {
              this.$router.push({
                name: 'login',
                query: { unknownECPS: true }
              })
            }
          })
          .catch(() => {
            this.$router.push({
              name: 'login',
              query: { unknownECPS: true }
            })
          })
      }, 800)
    },
    /**
     * Function called to handle login and register process after going through the psc dedicated authentication window.
     *
     * @param redirect
     */
    loginRegisterPSC (redirect) {
      setTimeout(() => { // apparently PSC is not fast enough to handle code directly
        this.step = 'loading'
        proSanteConnectResource()
          .getToken(this.$route.query.code, redirect)
          .then(response => {
            if (response.access_token) {
              proSanteConnectResource()
                .authenticate(response.access_token)
                .then(response => {
                  storageService().store('token', response.token)
                  this.$analytics.logEvent('login', {
                    method: 'cps',
                    email: jwtDecode(response.token)?.user?.email || 'unknown'
                  })
                  this.start()
                })
                .catch(() => {
                  proSanteConnectResource()
                    .getUserInfo(response.access_token)
                    .then(infos => {
                      this.ecpsInfos = infos
                      if (this.ecpsInfos.SubjectRefPro.exercices[0].codeProfession !== PHARMACIST_CODE) {
                        this.$router.push({
                          name: 'login',
                          query: {
                            noAccount: true,
                            noPharmacist: true
                          }
                        })
                      }
                    })
                    .catch(error => {
                      this.error = error
                    })
                    .finally(() => {
                      this.step = 'form'
                    })
                })
            }
          })
          .catch(() => {
            this.backToHome()
          })
      }, 800)
    },
    backToHome () {
      storageService().destroy('token')
      this.$router.push({ name: 'login' })
    }
  }
}
</script>
