<template>
  <div
    class="relative rounded-r-md overflow-hidden h-5"
    style="background: linear-gradient(to right, orange 3%, green 100%)"
  >
    <div
      class="gradient-bar right-0 absolute bg-gray-300 rounded-r-md"
      :style="{width: `${100 - barWidth}%`}"
    />
  </div>
</template>

<script>
export default {
  name: 'AudioVizualizer',
  props: {
    /** The audio stream to visualize */
    stream: {
      type: MediaStream,
      required: false,
      default: null
    }
  },
  data () {
    return {
      audioContext: null,
      analyser: null,
      dataArray: null,
      barWidth: 0
    }
  },
  watch: {
    stream: {
      handler (newValue) {
        if (newValue) {
          this.setupAudioContext()
        } else {
          this.stopAudioContext()
        }
      },
      immediate: true
    }
  },
  beforeDestroy () {
    this.stopAudioContext()
  },
  methods: {
    /**
     * Setup the audio context.
     */
    setupAudioContext () {
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)()
      const source = this.audioContext.createMediaStreamSource(this.stream)
      this.analyser = this.audioContext.createAnalyser()
      source.connect(this.analyser)
      this.analyser.fftSize = 256
      const bufferLength = this.analyser.frequencyBinCount
      this.dataArray = new Uint8Array(bufferLength)
      this.audioContext.createMediaStreamSource(this.stream).connect(this.analyser)
      this.updateBar()
    },
    /**
     * Update the bar width.
     */
    updateBar () {
      if (!this.analyser) {
        return
      }

      requestAnimationFrame(this.updateBar)
      this.analyser.getByteFrequencyData(this.dataArray)
      const sum = this.dataArray.reduce((a, b) => a + b, 0)
      const average = sum / this.dataArray.length

      this.barWidth = (average / 255) * 100
    },
    /**
     * Stop the audio context.
     */
    stopAudioContext () {
      if (this.audioContext) {
        if (this.analyser) {
          this.analyser.disconnect()
          this.analyser = null
        }
        this.dataArray = null
        this.barWidth = 0
        this.audioContext.close()
        this.audioContext = null
      }
    }
  }
}
</script>

<style scoped>
.gradient-bar {
  height: inherit;
  transition: width 0.1s ease-in-out, background-color 0.1s ease-in-out;
}
</style>
