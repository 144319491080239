<template>
  <div class="flex gap-4">
    <img
      v-if="loyaltyCard.file"
      :src="loyaltyCard.file?.url"
      class="self-start"
      alt="Loyalty card"
      style="aspect-ratio: 1.5; width: 45%; object-fit: cover; border-radius: 10px;"
    >
    <div class="flex flex-col justify-between w-full gap-2">
      <label class="text-sm line-clamp-2">{{ loyaltyCard.name }}</label>
      <base-button
        v-if="!membership"
        :primary="true"
        style="padding: 5px 8px; min-width: 100%"
        @click.stop="attach"
      >
        <p class="text-sm font-semibold">
          Activer
        </p>
      </base-button>
      <div v-else>
        <loyalty-card-membership-point-balance
          class="text-xs"
          :formatted_point_balance="membership.formatted.point_balance"
          :point_balance="membership.point_balance"
          :rate="loyaltyCard.point?.rate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import LoyaltyCardMembershipPointBalance
from '@/renderer/app/loyalty-card-membership/components/loyalty-card-membership-point-balance/LoyaltyCardMembershipPointBalance.vue'

export default {
  name: 'NotificationDetectionLoyaltyCardItem',
  components: { LoyaltyCardMembershipPointBalance, BaseButton },
  props: {
    /**
     * The loyalty card item
     *
     * @type {Object}
     */
    loyaltyCard: {
      type: Object,
      required: true
    }
  },
  computed: {
    /**
     * Determine if the loyalty card has a membership.
     *
     * @return {Object|null}
     */
    membership () {
      return this.loyaltyCard.membership
    }
  },
  methods: {
    /**
     * Forward the link membership event to parent component.
     *
     * @return {*}
     */
    attach () {
      return this.$listeners['link-membership'](this.loyaltyCard)
    }
  }
}
</script>
