<template>
  <div
    class="relative flex flex-col gap-2 cursor-pointer"
    @click="navigateToProgram"
  >
    <div class="text-xs ml-14">
      {{ program.name }}
    </div>
    <div class="text-xs ml-14 text-right">
      {{ consumer.information.name }}
    </div>
    <BaseCard class="ml-12 p-3 pl-12 items-center content-center shadow-deep w-64 flex flex-1">
      <div
        class="px-1 flex flex-1 flex-row items-center program-card-teaser"
        style="min-height: 70px;"
      >
        <ProgramTeaser
          :program="program"
          class="flex-1 text-xs"
          @action.stop="navigateToProgramWithAction"
        />
      </div>
    </BaseCard>
    <div class="ml-12 flex flex-row pt-2 w-64 whitespace-nowrap overflow-hidden">
      <div
        v-for="(patient, index) in program.patients"
        :key="index"
        class="flex text-sm text-gray-dark pr-1 mr-1 items-center border-gray-dark"
        :class="{ 'font-content-bold': !patient.beneficiary, 'border-r-2': index < program.patients.length - 1}"
      >
        <UserIcon
          v-if="patient.beneficiary"
          class="rounded-full border mr-2 bg-transparent border-gray-dark"
          :size="4"
        />
        {{ patient.name }}
      </div>
    </div>
    <ProgramLogo
      :program="program"
      :size="75"
      :shadow="false"
      class="absolute left-4 top-9"
    />
  </div>
</template>

<script>
import BaseCard from '@/renderer/components/base/BaseCard.vue'
import programHelper from '@/renderer/helpers/programHelper'
import ProgramTeaser from '@/renderer/app/program/components/program-teaser/ProgramTeaser.vue'
import ProgramLogo from '@/renderer/app/program/components/program-logo/ProgramLogo.vue'
import UserIcon from '@/renderer/components/icons/User.vue'
import { pathwayService } from '@/renderer/container'

export default {
  components: {
    ProgramLogo,
    BaseCard,
    ProgramTeaser,
    UserIcon
  },
  props: {
    program: {
      type: Object,
      required: true
    },
    consumer: {
      type: Object,
      required: true
    },
    customer: {
      type: Object,
      required: true
    }
  },
  methods: {
    navigateToProgram (event) {
      const route = programHelper.generateRoute(this.program, this.consumer, this.customer)

      if (route) {
        this.$router.push(route)
      }
    },
    navigateToProgramWithAction (event) {
      const pathway = {
        id: this.program.next_step.pathway_id,
        support_program_id: this.program.programmable_id
      }

      return pathwayService()
        .start(pathway, this.program.next_step, this.consumer.consumer_key)
        .then(() => {
          this.navigateToProgram()
        })
    }
  }
}
</script>
