/**
 * The document resource.
 *
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
  return {
    get (url) {
      url = url.replace(process.env.VUE_APP_HDS_URL, '')

      return client.get(url, { responseType: 'blob' })
        .then(response => response.data)
    }
  }
}
