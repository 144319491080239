<template>
  <product-availabity-card
    :title="`${availabilityLabel(data)} chez un grossiste d'une pharmacie partenaire`"
    loading-message="Interrogation des grossistes"
    :loading="data.loading"
    :is-available="data.availability"
    class="cursor-pointer"
    @click.native="scrollToDistributorMap"
  >
    <template #icon>
      <v-icon
        color="black"
        size="40"
        class="w-full"
      >
        mdi-human-dolly
      </v-icon>
    </template>
  </product-availabity-card>
</template>

<script>
import ProductAvailabityCard
from '@/renderer/app/disruption-logistics/components/product-availability-card/ProductAvailabityCard.vue'

export default {
  name: 'HealthcenterGroupWarehouseProductAvailabilityCard',
  components: { ProductAvailabityCard },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    /**
     * Get the availability label.
     *
     * @param {Object} provider
     *
     * @return {string}
     */
    availabilityLabel (provider) {
      if (provider.availability) {
        return 'Disponible'
      } else if (!provider.availability) {
        return 'Indisponible'
      }
    },
    /**
     * Scroll to the distributor map.
     */
    scrollToDistributorMap () {
      const element = document.getElementById('distributor-map')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }
}
</script>
