<template>
  <base-container>
    <header-back-button :title="group?.name ?? $route.query.name ?? 'Détails du groupe'" />
    <div
      v-if="!loading"
      class="flex flex-1 flex-col p-6 gap-6"
    >
      <template v-if="group">
        <div class="flex gap-3">
          <p
            v-if="!editMode"
            class="text-center text-primary flex-1"
          >
            {{ group.description }}
          </p>
          <div
            v-if="editMode"
            class="flex-1"
          />
          <base-button
            v-if="isOwner"
            class="flex items-center gap-2 bg-white self-start"
            @click="editMode = !editMode"
          >
            <v-icon
              :size="20"
              color="black"
            >
              mdi-pencil
            </v-icon>
            <span>
              Modifier le groupe
            </span>
          </base-button>
        </div>
        <healthcenter-group-edit-form
          v-if="editMode"
          :group="group"
          @saved="handleGroupUpdated"
        />
        <invite-healthcenter-card
          v-if="isOwner"
          :healthcenter-group-id="group.id"
          @invitationSent="handleInvitationSent"
        />
        <healthcenter-group-sent-invitation-card
          v-if="!!pendingInvitations?.length && isOwner"
          :invitations="pendingInvitations"
          @cancel-invitation="deleteInvitation"
        />
        <healthcenter-group-healthcenters
          :owner-access-id="group.creator_access_id"
          :healthcenters="group.healthcenters"
          :group-id="group.id"
          @member-deleted="handleMemberDeleted"
        />
        <base-button
          v-if="isOwner"
          class="bg-red-500 hover:bg-red-300 group"
          @click="handleDeleteGroup"
        >
          <span class="text-white font-bold group-hover:text-black">Supprimer le groupe</span>
        </base-button>
      </template>
      <div v-else>
        <p class="text-center">
          Une erreur s'est produite lors du chargement des données.
        </p>
      </div>
    </div>
    <base-flex-spinner v-else />
  </base-container>
</template>

<script>
import BaseContainer from '@/renderer/components/base/BaseContainer.vue'
import { disruptionLogisticsApi } from '@/renderer/container'
import HealthcenterGroupHealthcenters
from '@/renderer/app/disruption-logistics/components/healthcenter-groups/HealthcenterGroupHealthcenters.vue'
import BaseFlexSpinner from '@/renderer/components/base/spinner/BaseFlexSpinner.vue'
import InviteHealthcenterCard
from '@/renderer/app/disruption-logistics/components/healthcenter-groups/InviteHealthcenterCard.vue'
import HeaderBackButton from '@/renderer/app/core/components/header-back-button/HeaderBackButton.vue'
import HealthcenterGroupSentInvitationCard
from '@/renderer/app/disruption-logistics/components/healthcenter-groups/HealthcenterGroupSentInvitationCard.vue'
import HealthcenterGroupService from '@/renderer/app/disruption-logistics/services/HealthcenterGroupService'
import BaseButton from '@/renderer/components/base/BaseButton.vue'
import ToastService from '@/renderer/services/ToastService'
import HealthcenterGroupEditForm
from '@/renderer/app/disruption-logistics/components/healthcenter-groups/HealthcenterGroupEditForm.vue'

export default {
  name: 'HealthcenterGroupDetailsPage',
  components: {
    HealthcenterGroupEditForm,
    BaseButton,
    HealthcenterGroupSentInvitationCard,
    HeaderBackButton,
    InviteHealthcenterCard,
    BaseFlexSpinner,
    HealthcenterGroupHealthcenters,
    BaseContainer
  },
  data () {
    return {
      /** @type {HealthcenterGroupWithMembers|null} */
      group: null,
      pendingInvitations: null,
      loading: true,
      editMode: false
    }
  },
  computed: {
    isOwner () {
      if (!this.group) {
        return false
      }

      return HealthcenterGroupService.isOwner(this.group.creator_access_id)
    }
  },
  watch: {
    '$route.params.healthcenterGroupId': {
      handler: function (value) {
        this.loading = true
        this.load(value)
          .finally(() => {
            this.loading = false
          })
      },
      immediate: true
    }
  },
  methods: {
    /**
     * Load the page data.
     *
     * @param groupId
     * @returns {Promise<void>}
     */
    load (groupId) {
      return this.readGroup(groupId)
        .then(() => {
          if (this.isOwner) {
            return this.invitationsSent(groupId)
          }

          return Promise.resolve()
        })
    },
    /**
     * Read a healthcenter group.
     *
     * @param {Number} groupId - The healthcenter group id
     */
    readGroup (groupId) {
      return disruptionLogisticsApi()
        .readHealthcenterGroup(groupId)
        .then(group => {
          this.group = group
        })
    },
    /**
     * Get the invitations sent by the healthcenter group.
     *
     * @param {Number} groupId - The healthcenter group id
     */
    invitationsSent (groupId) {
      return disruptionLogisticsApi()
        .sentHealthcenterGroupInvitations(groupId, 'pending')
        .then(invitations => {
          this.pendingInvitations = invitations
        })
    },
    /**
     * Delete an invitation.
     *
     * @param {Object} invitation - The invitation to delete
     */
    deleteInvitation (invitation) {
      return disruptionLogisticsApi()
        .deleteHealthcenterGroupInvitation(this.group.id, invitation.id)
        .then(() => {
          this.pendingInvitations = this.pendingInvitations.filter(i => i.id !== invitation.id)
        })
    },
    /**
     * Add the sent invitation to the pending invitations.
     *
     * @param {Object} invitation
     */
    handleInvitationSent (invitation) {
      const pendingIds = this.pendingInvitations.map(_invitation => _invitation.id)

      if (!pendingIds.includes(invitation.id)) {
        this.pendingInvitations.push(invitation)
      }
    },
    /**
     * Remove the healthcenter from the group, and update the member count var.
     *
     * @param {Number} healthcenterId The healthcenter id to remove
     */
    handleMemberDeleted (healthcenterId) {
      this.group.healthcenters = this.group.healthcenters.filter(healthcenter => healthcenter.id !== healthcenterId)
      this.group.member_count -= 1
    },

    /**
     * Ask for confirmation before deleting the group.
     */
    handleDeleteGroup () {
      this.$confirmationModal.show(
        'Suppression du groupe',
        'Êtes-vous sûr de vouloir supprimer ce groupe ? Les membres ne pourront plus accéder aux informations du groupe ni aux données partagées.',
        this.deleteGroup
      )
    },

    /**
     * Delete the group.
     *
     * @return {Promise<void>}
     */
    deleteGroup () {
      return disruptionLogisticsApi()
        .deleteHealthcenterGroup(this.group.id)
        .then(() => {
          ToastService().success('Le groupe a bien été supprimé')
          this.$router.go(-1)
        })
    },

    /**
     * Handle the group updated event.
     *
     * @param name
     * @param description
     */
    handleGroupUpdated ({ name, description }) {
      this.group.name = name
      this.group.description = description

      this.editMode = false

      ToastService()
        .success('Le groupe a bien été mis à jour')
    }
  }
}
</script>
