<template>
  <div>
    <div>
      <div class="flex rounded-md shadow-sm">
        <button
          type="button"
          class="inline-flex items-center space-x-2 px-4 py-2 border-t border-l border-b border-gray-300 text-sm font-medium text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none rounded-tl-md"
          @click="decrease()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            />
          </svg>
        </button>

        <div class="flex items-stretch flex-grow focus-within:z-10">
          <input
            v-model="value"
            type="text"
            class="block w-full rounded-none sm:text-sm border-gray-300 text-center"
          >
        </div>

        <button
          type="button"
          class="inline-flex items-center space-x-2 px-4 py-2 border-t border-r border-b border-gray-300 text-sm font-medium text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none rounded-tr-md"
          @click="increase()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>

    <div>
      <button
        type="button"
        class="inline-flex items-center space-x-2 px-4 py-2 border-b border-l border-r border-gray-300 text-sm font-medium text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none rounded-b-md w-full justify-center"
        @click="update()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="-ml-0.5 mr-2 h-4 w-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
        </svg>

        Modifier le panier
      </button>
    </div>
  </div>
</template>

<script>
import {
  shopCartResource
} from '@/renderer/container'

export default {
  props: {
    product: {
      type: Object,
      required: true
    },

    value: {
      type: Number,
      required: true
    }
  },

  methods: {
    increase () {
      ++this.value
    },

    decrease () {
      if (this.value > 0) {
        --this.value
      }
    },

    update () {
      const products = {
        [this.product.id]: this.value
      }

      shopCartResource()
        .update(
          this.$route.params.laboratoryId,
          this.$store.state.system.environment.entityId,
          products
        )
        .then(cart => {
          this.$store.commit('setQuantity', this.value)
        })
    }
  }
}
</script>
