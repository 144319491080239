<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="consumers.length"
    class="elevation-1 cursor-pointer"
    hide-default-footer
    @click:row="click"
  />
</template>

<script>
import dateHelper from '@/renderer/helpers/dateHelper'

export default {
  name: 'ProgramPathwayConsumersTable',
  props: {
    consumers: {
      type: Array,
      required: true
    },
    meta: {
      type: Object,
      required: true
    }
  },
  computed: {
    headers () {
      return [
        { text: `Patients (${this.consumers.length})`, value: 'name' },
        { text: 'Dernière visite', value: 'lastVisit' },
        ...this.meta.progress.map((progress, index) => ({
          text: progress,
          value: 'progress' + index,
          align: 'center'
        }))
      ]
    },
    items () {
      return this.consumers.map((consumer) => ({
        consumer,
        name: consumer.name,
        lastVisit: this.lastVisit(consumer),
        ...this.progressColumns(consumer)
      }))
    }
  },
  methods: {
    lastVisit (consumer) {
      const dates = []

      Object.keys(consumer.last_visits).forEach((key) => {
        if (consumer.last_visits[key]) {
          dates.push(new Date(consumer.last_visits[key]))
        }
      })

      return dates.length ? dateHelper.shortFormat(new Date(Math.max.apply(null, dates))) : '-'
    },
    progressColumns (consumer) {
      const progresses = {}

      this.meta.progress.forEach((progress, index) => {
        progresses['progress' + index] = consumer.pathway?.progress[index]?.toString() || '-'
      })

      return progresses
    },
    click (row) {
      this.$emit('click', row.consumer)
    }
  }
}
</script>
