<template>
  <BaseContainer class="relative p-6">
    <customer-search />
    <home-live-actions @onPatientPress="show" />
    <enabled-program-grid />
  </BaseContainer>
</template>

<script>
import BaseContainer from '@/renderer/components/base/BaseContainer.vue'
import EnabledProgramGrid from '@/renderer/app/program/components/enabled-program-grid/EnabledProgramGrid.vue'
import HomeLiveActions from '@/renderer/app/home/components/HomeLiveActions.vue'
import CustomerSearch from '@/renderer/app/customer/components/customer-search/CustomerSearch.vue'

export default {
  components: {
    CustomerSearch,
    HomeLiveActions,
    BaseContainer,
    EnabledProgramGrid
  },
  mounted () {
    this.$store.dispatch('experience/fetchEvents')
  },
  methods: {
    show (customer) {
      this.$router.push({
        name: 'customer.home',
        params: {
          customerId: customer.id
        }
      })
    }
  }
}
</script>
