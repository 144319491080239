import ConfirmationModal from '@/renderer/app/modal/components/ConfirmationModal.vue'

const modalPlugin = {
  install (Vue) {
    const ModalConstructor = Vue.extend(ConfirmationModal)
    const modalInstance = new ModalConstructor()

    Vue.prototype.$confirmationModal = {
      /**
       * Show the confirmation modal.
       *
       * @param {String} title The title of the modal.
       * @param {String} message The message of the modal.
       * @param {() => any | Promise<any>} confirmationCallback The callback to execute when the user confirms the modal.
       *
       * @return {*}
       */
      show (title, message, confirmationCallback) {
        if (!modalInstance.$el) {
          modalInstance.$mount(document.createElement('div'))
          document.body.appendChild(modalInstance.$el)
        }

        return modalInstance.show(title, message, confirmationCallback)
      }
    }
  }
}

export default modalPlugin
